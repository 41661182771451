import { IonFooter, useIonViewWillEnter, useIonViewWillLeave } from "@ionic/react";
import "../../../css/App.css";
import "../../../css/HeroChat.css";

import { useEffect, useState } from "react";
import { duelAPIMessagesKey, duelDisplayMessagesKey, DuelStatus } from "../../../models/duelsModels";
import { DuelDisplayMessage } from "../../../models/messageModels";
import { StorageService } from "../../../services/StorageService";
import DuelsChatInput from "./DuelsChatInput";
import DuelsRenderComponent from "./DuelsRenderComponent";
import { Steps } from "intro.js-react";
import { coachMarkObjectKey } from "../../../models/userModel";
import { hasCoachMarkBeenShown, shouldShowCoachmark, updateCoachMark } from "../../../services/HelperService";
import { TourComponent } from "../../Tour/StepsComponent";
import { DuelChatSteps } from "../../Tour/Steps";
const storageService = new StorageService();

const DuelsMessages = (props: { duelStatus: DuelStatus; setDuelUpdatesCoachMarkEnabled: React.Dispatch<React.SetStateAction<boolean>> }) => {
	const [messagesForDisplay, setMessagesForDisplay] = useState<DuelDisplayMessage[]>([]);
	const [processing, setProcessing] = useState<boolean>(false);
	const [scroller, setScroller] = useState<number>(0); // used to simply trigger a rerender without more info
	const [stepsEnabled, setStepsEnabled] = useState<boolean>(false);

	// useEffect(() => {
	// 	hideTab();
	// 	return () => {
	// 		showTab();
	// 	};
	// }, []);

	useEffect(() => {
		shouldShowCoachmark("duelDetails").then((result: boolean) => {
			setStepsEnabled(result);
		});
	}, []);

	useEffect(() => {
		showNextMark();
	}, [messagesForDisplay]);

	async function showNextMark() {
		// log.debug("Messages for display length: ", messagesForDisplay.length);
		// log.debug("Messages for display: ", messagesForDisplay);
		let nextMarkShown = await hasCoachMarkBeenShown("duelUpdates");
		if (messagesForDisplay.length > 2 && nextMarkShown === false) {
			props.setDuelUpdatesCoachMarkEnabled(true);
		}
	}

	async function clearChatHistory() {
		await storageService.removeItem(duelAPIMessagesKey);
		await storageService.removeItem(duelDisplayMessagesKey);

		setMessagesForDisplay([]);
	}

	// function hideTab() {
	// 	const tabBar = document.getElementById("tabBar");
	// 	if (tabBar !== null) {
	// 		tabBar.style.display = "none";
	// 	}
	// }

	// function showTab() {
	// 	// log.debug("Showing tabs");

	// 	const tabBar = document.getElementById("tabBar");
	// 	if (tabBar !== null) {
	// 		tabBar.style.display = "flex";
	// 	}
	// }

	return (
		<>
			<DuelsRenderComponent
				duelStatus={props.duelStatus}
				scroller={scroller}
				setScroller={setScroller}
				messages={messagesForDisplay}
				setMessages={setMessagesForDisplay}
				processing={processing}
				setProcessing={setProcessing}
			/>
			<TourComponent coachMarkName="duelChat" steps={DuelChatSteps} modalShowing={false} />
			{/* <div className="showHint">
				{props.duelStatus.complete === true && (
					<>
						<IonFooter>
							<div className="duel-completed-footer">
								<p>This duel is complete</p>
							</div>
						</IonFooter>
					</>
				)}
			</div> */}
		</>
	);
};

export default DuelsMessages;
