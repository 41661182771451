import { createClient } from "altogic";
import { ALTOGIC_CLIENT_KEY, ALTOGIC_ENVURL } from "../models/endPoints";
import { Villain, villainsObjectKey } from "../models/villainModels";
import { StorageService } from "./StorageService";
import Backendless from 'backendless'
import { calculateAveragePoints, getBaselineStats, getHero, getUser_id } from "./UserService";
import { isArray } from "lodash";
import { DuelStatus, VillainDifficulty, VillainGroupInstance, VillainGroupInstanceNode, VillainGroups, activityMatrixKey, villainGroupsKey } from "../models/duelsModels";
import { AttributeMatrix, HeroObject, heroObjectKey } from "../models/userModel";
import { OddsPredictionNumber, simulateBattleByVillain, simulateBattleByVillainNew, simulateBattleServer } from "./DuelsServics";
import { getAttributeMatrix } from "./HelperService";
import { VILLAINS_TABLE, VILLAIN_GROUP_INSTANCE_TABLE } from "../models/databaseModels";
import { sendSlackErrorNotification } from "./NotificationService";
import log from "loglevel";
let storageService = new StorageService();
const Parse = require('parse');

export async function getVillains(withBoss: boolean = true, sortCriteria: string = "name"): Promise<Villain[]> {
    const query = new Parse.Query(VILLAINS_TABLE);
    if (withBoss === false) {
        query.equalTo('boss', false);
    }
    query.equalTo('enabled', true);
    const parseObjects = (await query.find())
    let villains: Villain[] = []
    parseObjects.forEach((parseObject: any) => {
        let villain: Villain = parseObject.toJSON() as Villain
        if (villain.villainImage && villain.villainImage.url && villain.villainImage.url != villain.imageURL) {
            villain.imageURL = villain.villainImage.url;
        }
        villains.push(villain)
    })
    // log.debug(`[getVillain] villains: `, villains);
    await storageService.setObject(villainsObjectKey, villains);
    switch (sortCriteria) {
        case "name":
            return villains.sort((a, b) => {
                if (a.name < b.name) { return -1; }
                if (a.name > b.name) { return 1; }
                return 0;
            });
        case "newest":
            return villains.sort((a, b) => {
                if (a.createdAt < b.createdAt) { return -1; }
                if (a.createdAt > b.createdAt) { return 1; }
                return 0;
            });
        default:
            return villains.sort((a, b) => {
                if (a.level < b.level) { return -1; }
                if (a.level > b.level) { return 1; }
                return 0;
            });
    }

}

export async function getVillain(villainID: string, forceRefresh: boolean = false): Promise<Villain> {
    let villains = await storageService.getObject(villainsObjectKey);
    if (forceRefresh === false && villains !== null) {
        let villain = villains.find((v: Villain) => v.villainID === villainID);
        if (villain && villain.strengthImp) { // if the strengthImp is not set, then it's a new villain and we need to pull from DB
            if (villain.villainImage && villain.villainImage.url && villain.villainImage.url !== villain.imageURL) {
                villain.imageURL = villain.villainImage.url;
            }
            return villain;
        }
    }
    const query = new Parse.Query(VILLAINS_TABLE);
    query.equalTo('villainID', villainID);
    let villain: Villain = (await query.find())[0].toJSON();
    // log.debug(`[getVillain] villain: `, villain);
    if (villain.villainImage && villain.villainImage.url && villain.villainImage.url !== villain.imageURL) {
        villain.imageURL = villain.villainImage.url;
    }
    // get the villains from local storage and update the villain
    villains = await storageService.getObject(villainsObjectKey);
    if (villains) {
        let index = villains.findIndex((v: Villain) => v.villainID === villainID);
        if (index >= 0) {
            villains[index] = villain;
            await storageService.setObject(villainsObjectKey, villains);
        } else {
            await storageService.setObject(villainsObjectKey, [...villains, villain]);
        }
    } else {
        await storageService.setObject(villainsObjectKey, [villain]);
    }
    return villain;
}

export async function getVillainFromDuelStatus(duelStatus: DuelStatus): Promise<Villain> {
    const query = new Parse.Query(VILLAINS_TABLE);
    query.equalTo('villainID', duelStatus.villainID);
    let villain: Villain = (await query.find())[0].toJSON();
    // log.debug(`[getVillain] villain: `, villain);
    villain.toHit = duelStatus.villainToHit;
    villain.damage = duelStatus.villainDamage;
    villain.health = duelStatus.villainHealth;
    villain.dodge = duelStatus.villainDodge;
    if (duelStatus.villainStrength) villain.strength = duelStatus.villainStrength;
    if (duelStatus.villainCardio) villain.cardio = duelStatus.villainCardio;
    if (duelStatus.villainMobility) villain.mobility = duelStatus.villainMobility;
    if (duelStatus.villainMindfulness) villain.mindfulness = duelStatus.villainMindfulness;
    if (duelStatus.villainLevel) villain.level = duelStatus.villainLevel;
    return villain;
}

export async function getVillainFromGroupInstance(groupInstanceId: string, villainId: string): Promise<Villain> {
    const query = new Parse.Query(VILLAIN_GROUP_INSTANCE_TABLE);
    query.equalTo('villainGroupInstanceId', groupInstanceId);
    let villainGroupInstance: VillainGroupInstance = (await query.first()).toJSON();
    let villainInGroupInstance: VillainGroupInstanceNode | undefined = villainGroupInstance.villains.find((v) => v.villainId === villainId);
    if (!villainInGroupInstance) {
        throw new Error("Villain not found in group instance")
    }
    log.debug(`[getVillainFromGroupInstance] villainInGroupInstance: `, villainInGroupInstance);
    let villain = await getVillain(villainId)
    log.debug(`[getVillainFromGroupInstance] initial villain: `, villain);
    villain.toHit = villainInGroupInstance.villainStats.toHit;
    villain.damage = villainInGroupInstance.villainStats.damage;
    villain.health = villainInGroupInstance.villainStats.health;
    villain.dodge = villainInGroupInstance.villainStats.dodge;
    villain.strength = villainInGroupInstance.villainStats.strength
    villain.cardio = villainInGroupInstance.villainStats.cardio
    villain.mobility = villainInGroupInstance.villainStats.mobility
    villain.mindfulness = villainInGroupInstance.villainStats.mindfulness
    villain.level = villainInGroupInstance.villainStats.level
    log.debug(`[getVillainFromGroupInstance] updated villain: `, villain);

    return villain;
}

interface DefeatedVillains {
    villainID: string;
    defeatedDate: string;
}

export async function getDefeatedVillains(): Promise<DefeatedVillains[]> {
    let userID = await getUser_id()
    let query = new Parse.Query("duel_status");
    query.equalTo('user_id', userID);
    query.equalTo('complete', true);
    query.equalTo('winner', 'hero');
    let results = await query.find()
    let villainIDs: DefeatedVillains[] = []
    if (!results) return villainIDs
    results.forEach((result: any) => {
        villainIDs.push({
            villainID: result.get('villainID'),
            defeatedDate: result.updatedAt,
        })
    })
    return villainIDs
}

interface RecordVsVillain {
    wins: number;
    losses: number;
}

export async function getRecordVsVillain(villainID: string): Promise<RecordVsVillain> {
    let userID = await getUser_id()
    let query = new Parse.Query("duel_status");
    query.equalTo('user_id', userID);
    query.equalTo('villainID', villainID);
    query.equalTo('complete', true);
    let results = await query.find()
    let record: RecordVsVillain = {
        wins: 0,
        losses: 0,
    }
    if (!results) return record
    results.forEach((result: any) => {
        if (result.get('winner') === 'hero') {
            record.wins += 1
        } else {
            record.losses += 1
        }
    })
    return record
}

async function calculateStats(character: Villain) {
    let matrix = await getAttributeMatrix()
    let baselineStatsType1 = await getBaselineStats()
    let accuracy, damage, health, dodge, level;

    level = character.strength + character.cardio + character.mobility + character.mindfulness;
    accuracy = Math.floor(
        character.strength * matrix.strengthAccuracy +
        character.cardio * matrix.cardioAccuracy +
        character.mobility * matrix.mobilityAccuracy +
        character.mindfulness * matrix.mindfulnessAccuracy +
        level * matrix.levelAccuracy +
        baselineStatsType1.accuracy
    );
    damage = Math.floor(
        character.strength * matrix.strengthDamage +
        character.cardio * matrix.cardioDamage +
        character.mobility * matrix.mobilityDamage +
        character.mindfulness * matrix.mindfulnessDamage +
        level * matrix.levelDamage +
        baselineStatsType1.damage
    );
    health = Math.floor(
        character.strength * matrix.strengthHealth +
        character.cardio * matrix.cardioHealth +
        character.mobility * matrix.mobilityHealth +
        character.mindfulness * matrix.mindfulnessHealth +
        level * matrix.levelHealth +
        baselineStatsType1.health
    );
    dodge = Math.floor(
        character.strength * matrix.strengthDodge +
        character.cardio * matrix.cardioDodge +
        character.mobility * matrix.mobilityDodge +
        character.mindfulness * matrix.mindfulnessDodge +
        level * matrix.levelDodge +
        baselineStatsType1.dodge
    );
    return {
        accuracy: accuracy,
        damage: damage,
        dodge: dodge,
        health: health,
        level: level,
    };
};

export async function scaleVillainToLevelOne(villain: Villain): Promise<Villain> {
    villain.strength = 1;
    villain.cardio = 1;
    villain.mobility = 1;
    villain.mindfulness = 1;
    villain.level = villain.strength + villain.cardio + villain.mobility + villain.mindfulness;
    // const stats = await calculateStats(villain);
    villain.toHit = 30;
    villain.damage = 5;
    villain.dodge = 1;
    // villain.health = stats.health;
    // villain.health = 60; // hard coded for now
    // villain.level = stats.level;
    return villain;
}

export async function scaleVillainBasic(villainPassed: Villain, difficulty: string, hero?: HeroObject): Promise<{ scaledVillain: Villain, oddsObj: OddsPredictionNumber }> {
    let villain = await getVillain(villainPassed.villainID, true)
    if (!hero) {
        hero = await getHero();
    }
    log.debug("Original villain: ", villain)
    log.debug("selectedHero: ", hero)

    // Update to set base level for villain and then change attributes
    let heroOdds = 0;
    let villianMultiplier = 1;
    let oddsObj: OddsPredictionNumber
    let villainTrueLevel = villain.strength + villain.cardio + villain.mobility + villain.mindfulness

    let strengthRatio = villain.strength / villainTrueLevel
    let cardioRatio = villain.cardio / villainTrueLevel
    let mobilityRatio = villain.mobility / villainTrueLevel
    let mindfulnessRatio = villain.mindfulness / villainTrueLevel

    // log.debug("strengthRatio: ", strengthRatio)
    // log.debug("cardioRatio: ", cardioRatio)
    // log.debug("mobilityRatio: ", mobilityRatio)
    // log.debug("mindfulnessRatio: ", mindfulnessRatio)


    switch (difficulty) {
        case 'easy':
            villianMultiplier = 0.7
            break;
        case 'medium':
            villianMultiplier = 1.0
            break;
        case 'hard':
            villianMultiplier = 1.5
            break;
        case 'boss':
            villianMultiplier = 2.0
            break;
        default:
            villianMultiplier = 1
            break;
    }
    let newVillainLevel = Math.floor(hero.overallLevel * villianMultiplier)
    log.debug("newVillainLevel: ", newVillainLevel)
    villain.strength = Math.max(Math.floor(newVillainLevel * strengthRatio), 1);
    villain.cardio = Math.max(Math.floor(newVillainLevel * cardioRatio), 1);
    villain.mobility = Math.max(Math.floor(newVillainLevel * mobilityRatio), 1);
    villain.mindfulness = Math.max(Math.floor(newVillainLevel * mindfulnessRatio), 1);
    villain.level = newVillainLevel
    const stats = await calculateStats(villain);
    villain.toHit = stats.accuracy;
    villain.damage = stats.damage;
    villain.dodge = stats.dodge;
    villain.health = stats.health;
    villain.level = stats.level;
    log.debug("New Villain: ", villain)
    // oddsObj = (await simulateBattleByVillainNew(hero, villain, 100, false));
    oddsObj = await simulateBattleServer(hero, villain);
    log.debug('oddsObj: ', oddsObj);
    log.debug('logString: ', oddsObj.logString);

    heroOdds = oddsObj.heroOdds;
    log.debug('heroOdds: ', heroOdds);
    return {
        scaledVillain: villain,
        oddsObj: oddsObj!,
    };
}

export async function scaleVillainBasicNoSim(villainPassed: Villain, difficulty: string, hero?: HeroObject): Promise<Villain> {
    let villain = await getVillain(villainPassed.villainID, true)
    if (!hero) {
        hero = await getHero();
    }
    log.debug("Original villain: ", villain)
    log.debug("selectedHero: ", hero)

    // Update to set base level for villain and then change attributes
    let heroOdds = 0;
    let villianMultiplier = 1;
    let oddsObj: OddsPredictionNumber
    let villainTrueLevel = villain.strength + villain.cardio + villain.mobility + villain.mindfulness

    let strengthRatio = villain.strength / villainTrueLevel
    let cardioRatio = villain.cardio / villainTrueLevel
    let mobilityRatio = villain.mobility / villainTrueLevel
    let mindfulnessRatio = villain.mindfulness / villainTrueLevel

    // log.debug("strengthRatio: ", strengthRatio)
    // log.debug("cardioRatio: ", cardioRatio)
    // log.debug("mobilityRatio: ", mobilityRatio)
    // log.debug("mindfulnessRatio: ", mindfulnessRatio)


    switch (difficulty) {
        case 'easy':
            villianMultiplier = 0.7
            break;
        case 'medium':
            villianMultiplier = 0.8
            break;
        case 'hard':
            villianMultiplier = 0.9
            break;
        case 'boss':
            villianMultiplier = 1.0
            break;
        default:
            villianMultiplier = 1
            break;
    }
    let newVillainLevel = Math.floor(hero.overallLevel * villianMultiplier)
    log.debug("newVillainLevel: ", newVillainLevel)
    villain.strength = Math.max(Math.floor(newVillainLevel * strengthRatio), 1);
    villain.cardio = Math.max(Math.floor(newVillainLevel * cardioRatio), 1);
    villain.mobility = Math.max(Math.floor(newVillainLevel * mobilityRatio), 1);
    villain.mindfulness = Math.max(Math.floor(newVillainLevel * mindfulnessRatio), 1);
    villain.level = newVillainLevel
    const stats = await calculateStats(villain);
    villain.toHit = stats.accuracy;
    villain.damage = stats.damage;
    villain.dodge = stats.dodge;
    villain.health = stats.health;
    villain.level = stats.level;
    log.debug("New Villain: ", villain)
    return villain;
}

interface VillainStats {
    strength: number;
    cardio: number;
    mobility: number;
    mindfulness: number;
    level: number;
    toHit: number;
    damage: number;
    dodge: number;
    health: number;
}

interface IterationResult {
    villainStats: VillainStats;
    heroOdds: number;
    expectedDuration: number;
}

let closestResult: IterationResult | null = null;

export async function scaleVillainServer(villain: Villain, difficulty: string): Promise<{ scaledVillain: Villain, oddsObj: OddsPredictionNumber }> {
    let hero = await getHero();
    let params = {
        villainId: villain.villainID,
        difficulty: difficulty,
        heroId: hero.heroID
    }
    log.debug("[scaleVillainServer] params: ", params)
    let scaledObject: {
        scaledVillain: Villain,
        oddsObj: OddsPredictionNumber
    }
    scaledObject = await Parse.Cloud.run('scaleVillain', params).catch((error: any) => {
        log.error("Error scaling villain: ", error)
        sendSlackErrorNotification("Error scaling villain", error)
    })
    log.debug("[scaleVillainServer] scaledObject: ", scaledObject)
    return {
        scaledVillain: scaledObject.scaledVillain,
        oddsObj: scaledObject.oddsObj
    }
}


export async function scaleVillain(villain: Villain, difficulty: string): Promise<{ scaledVillain: Villain, oddsObj: OddsPredictionNumber }> {
    let hero = await getHero();
    let trueLevel = villain.level + 4;
    // let scalingFactor = (selectedHero!.overallLevel / trueLevel);
    let scalingFactor = 1;
    // log.debug("base scalingFactor: ", scalingFactor)
    // log.debug(`villain: ${JSON.stringify(villain, null, 2)}`);
    // log.debug('hero: ', hero);
    // log.debug('base scalingFactor: ', scalingFactor);
    let baseVillain = await scaleVillainBasicNoSim(villain, difficulty, hero)
    // log.debug(`baseVillain: ${baseVillain}`);

    // After scaling, this is the desired probability of winning for the user
    let targetWinRate = 0.5;
    let winRateSpread = 0.09;
    if (difficulty === 'easy') {
        winRateSpread = 0.1;
        targetWinRate = 0.70;
    } else if (difficulty === 'medium') {
        winRateSpread = 0.15;
        targetWinRate = 0.5;
    } else if (difficulty === 'hard') {
        winRateSpread = 0.15;
        targetWinRate = 0.3;
    } else if (difficulty === 'boss') {
        winRateSpread = 0.09;
        targetWinRate = 0.1;
    }

    // Update to set base level for villain and then change attributes
    let heroOdds = 0;
    let oddsObj: OddsPredictionNumber
    let checks = 0;
    let maxChecks = 10;
    let villainScaled = false

    const lowEndHeroOdds = targetWinRate - winRateSpread;
    const highEndHeroOdds = targetWinRate + winRateSpread;
    const ADJUSTMENT_FACTOR = 0.10; // you can tune this value
    log.debug("lowEndHeroOdds: ", lowEndHeroOdds)
    log.debug("highEndHeroOdds: ", highEndHeroOdds)

    // Function to calculate the dynamic adjustment factor

    function calculateAdjustmentFactor(heroOdds: number): number {
        if (heroOdds < lowEndHeroOdds) {
            const deviation: number = lowEndHeroOdds - heroOdds;
            return ADJUSTMENT_FACTOR * (deviation / lowEndHeroOdds);
        } else if (heroOdds > highEndHeroOdds) {
            const deviation: number = heroOdds - highEndHeroOdds;
            return ADJUSTMENT_FACTOR * (deviation / (1 - highEndHeroOdds));
        } else {
            return 0;  // No adjustment needed if within range
        }
    }
    while ((heroOdds <= lowEndHeroOdds || heroOdds >= highEndHeroOdds) && checks < maxChecks) {
        villain.strength = Math.max(Math.round(baseVillain.strength * scalingFactor), 1);
        villain.cardio = Math.max(Math.round(baseVillain.cardio * scalingFactor), 1);
        villain.mobility = Math.max(Math.round(baseVillain.mobility * scalingFactor), 1);
        villain.mindfulness = Math.max(Math.round(baseVillain.mindfulness * scalingFactor), 1);
        villain.level = baseVillain.strength + baseVillain.cardio + baseVillain.mobility + baseVillain.mindfulness - 4;

        // eslint-disable-next-line no-await-in-loop
        const stats = await calculateStats(villain);

        villain.toHit = stats.accuracy;
        villain.damage = stats.damage;
        villain.dodge = stats.dodge;
        villain.health = stats.health;
        villain.level = stats.level;
        log.debug('villain strength: ', villain.strength, 'villain cardio: ', villain.cardio, 'villain mobility: ', villain.mobility, 'villain mindfulness: ', villain.mindfulness, 'villain level: ', villain.level);

        // eslint-disable-next-line no-await-in-loop
        oddsObj = await simulateBattleServer(hero, villain);
        log.debug('oddsObj: ', oddsObj);
        log.debug('logString: ', oddsObj.logString);
        heroOdds = oddsObj.heroOdds;
        log.debug('heroOdds: ', heroOdds);
        let adjustmentFactor: number = calculateAdjustmentFactor(heroOdds);

        // Store the result of this iteration
        let currentResult: IterationResult = {
            villainStats: { ...villain },  // Clone the villain object to store its current state
            heroOdds: oddsObj.heroOdds,
            expectedDuration: oddsObj.expectedDuration,
        };

        // Check if this is the closest result so far
        if (closestResult === null ||
            Math.abs(closestResult.heroOdds - (lowEndHeroOdds + highEndHeroOdds) / 2) >
            Math.abs(heroOdds - (lowEndHeroOdds + highEndHeroOdds) / 2)) {
            closestResult = currentResult;
        }


        if (
            heroOdds <= highEndHeroOdds
            && heroOdds >= lowEndHeroOdds
        ) {
            log.debug('*** VILLAIN IS JUST RIGHT ***');
            log.debug('Villain: ', villain);
            checks = maxChecks;
            villainScaled = true
        } else if (heroOdds < lowEndHeroOdds) { // Villain is too hard
            log.debug('*** VILLAIN IS TOO HARD *** ');
            scalingFactor *= (1 - adjustmentFactor);
            log.debug('updated scalingFactor: ', scalingFactor);
        } else if (heroOdds > highEndHeroOdds) { // Villain is too easy
            log.debug('*** VILLAIN IS TOO EASY ***');
            scalingFactor *= (1 + adjustmentFactor);
            log.debug('updated scalingFactor: ', scalingFactor);
        }
        checks += 1;
    }

    // If the loop ended due to reaching maxChecks, use the closest result
    if (checks >= maxChecks && closestResult !== null && !villainScaled) {
        villain = {
            ...baseVillain,
            ...closestResult.villainStats
        }
        oddsObj = {
            heroOdds: closestResult.heroOdds,
            expectedDuration: closestResult.expectedDuration,
        }
        // Optionally log that the closest result was used
        log.debug('Max checks reached. Using basic scaling');
        log.debug('Scaled villain: ', villain);
        log.debug('oddsObj: ', oddsObj);
    }


    // if (checks >= maxChecks && closestResult !== null) {
    //     let scaledVillain = await scaleVillainBasic(villain, difficulty, selectedHero)
    //     villain = scaledVillain.scaledVillain
    //     oddsObj = scaledVillain.oddsObj
    //     log.debug('Max checks reached. Using basic scaling');
    //     log.debug('Scaled villain: ', scaledVillain.scaledVillain);
    //     log.debug('oddsObj: ', scaledVillain.oddsObj);
    // }

    return {
        scaledVillain: villain,
        oddsObj: oddsObj!,
    };
}

export async function getAllVillainGroupsFromDB() {
    let query = new Parse.Query("villain_groups");
    query.equalTo('active', true);
    query.ascending('groupId')
    let results = await query.find()
    let villainGroups: VillainGroups[] = []
    results.forEach((result: any) => {
        villainGroups.push(result.toJSON())
    })
    await storageService.setObject(villainGroupsKey, villainGroups);
    // log.debug("villainGroups: ", villainGroups)
    return villainGroups
}

export async function getAllVillainGroups() {
    let storedVillainGroups = await storageService.getObject("villainGroups");
    if (storedVillainGroups) {
        return storedVillainGroups
    }
    let villainGroups = await getAllVillainGroupsFromDB()
    return villainGroups
}

export async function getVillainGroupByGroupId(groupId: number) {
    let query = new Parse.Query("villain_groups");
    query.equalTo('groupId', groupId);
    query.equalTo('active', true);
    let results: VillainGroups = (await query.first()).toJSON();
    // log.debug("villainGroups: ", villainGroups)
    return results
}

export interface ActivityMatrix {
    "key": string,
    "impact": number,
    "description": string
}

export async function getActivityImpactMatrixFromDB() {
    const query = new Parse.Query('activity_impact');
    const parseObjects = await query.find();
    let matrix: ActivityMatrix[] = [];
    parseObjects.forEach((parseObject: any) => {
        matrix.push(parseObject.toJSON());
    });
    await storageService.setObject(activityMatrixKey, matrix);
    return matrix;
}

//TODO: At some point this should be stored locally and updated periodically
export async function getActivityImpactMatrix(): Promise<ActivityMatrix[]> {
    let storedActivityMatrix = await storageService.getObject(activityMatrixKey);
    if (storedActivityMatrix) {
        getActivityImpactMatrixFromDB();
        return storedActivityMatrix
    } else {
        return await getActivityImpactMatrixFromDB();
    }
}

function convertActivityMatrixKeyToText(impact: string, activityMatrix: ActivityMatrix[]) {
    let impactAbbreviation = activityMatrix.find((activity) => activity.key === impact)?.key;
    let impactText = ""
    switch (impactAbbreviation) {
        case "imm":
            impactText = "no effect";
            break;
        case "hp":
            impactText = "a much higher effect";
            break;
        case "mp":
            impactText = "a moderately higher effect";
            break;
        case "lp":
            impactText = "a slightly higher effect";
            break;
        case "hn":
            impactText = "a much lower effect";
            break;
        case "mn":
            impactText = "a moderately lower effect";
            break;
        case "ln":
            impactText = "a slightly lower effect";
            break;
        case "base":
            impactText = "the same effect";
            break;
        default:
            break;
    }
    return impactText
}

function convertActivityMatrixKeyToTextV2(impact: string, activityMatrix: ActivityMatrix[]) {
    let impactAbbreviation = activityMatrix.find((activity) => activity.key === impact)?.key;
    let impactText = ""
    switch (impactAbbreviation) {
        case "imm":
            impactText = "no effect";
            break;
        case "hp":
            impactText = "🟢 Extremely vulnerable to";
            break;
        case "mp":
            impactText = "🟢 Moderately more vulnerable to";
            break;
        case "lp":
            impactText = "🟢 Slighly more vulnerable to";
            break;
        case "hn":
            impactText = "🔻 Extremely resistant to";
            break;
        case "mn":
            impactText = "🔻 Moderately resistant to";
            break;
        case "ln":
            impactText = "🔻 Slightly resistant to";
            break;
        case "base":
            impactText = "the same effect";
            break;
        default:
            break;
    }
    return impactText
}


export function convertImpactToText(villain: Villain, activityMatrix: ActivityMatrix[]) {
    let impactTextArray: string[] = []
    if (villain.strengthImp && villain.strengthImp !== "base") {
        impactTextArray.push(`Strength activities have ${convertActivityMatrixKeyToText(villain.strengthImp, activityMatrix)} `)
    }
    if (villain.cardioImp && villain.cardioImp !== "base") {
        impactTextArray.push(`💚 Cardio activities have ${convertActivityMatrixKeyToText(villain.cardioImp, activityMatrix)}`)
    }
    if (villain.mobilityImp && villain.mobilityImp !== "base") {
        impactTextArray.push(`🧘‍♀️ Mobility activities have ${convertActivityMatrixKeyToText(villain.mobilityImp, activityMatrix)}`)
    }
    if (villain.mindfulnessImp && villain.mindfulnessImp !== "base") {
        impactTextArray.push(`🙏 Mindfulness activities have ${convertActivityMatrixKeyToText(villain.mindfulnessImp, activityMatrix)}`)
    }
    return impactTextArray
}

export function convertImpactToTextV2(villain: Villain, activityMatrix: ActivityMatrix[]) {
    let impactTextArray: string[] = []
    if (villain.strengthImp && villain.strengthImp !== "base") {
        impactTextArray.push(`${convertActivityMatrixKeyToTextV2(villain.strengthImp, activityMatrix)} 💪 Strength activities.`)
    }
    if (villain.cardioImp && villain.cardioImp !== "base") {
        impactTextArray.push(`${convertActivityMatrixKeyToTextV2(villain.cardioImp, activityMatrix)} 💚 Cardio activities.`)
    }
    if (villain.mobilityImp && villain.mobilityImp !== "base") {
        impactTextArray.push(`${convertActivityMatrixKeyToTextV2(villain.mobilityImp, activityMatrix)} 🧘‍♀️ Mobility activities.`)
    }
    if (villain.mindfulnessImp && villain.mindfulnessImp !== "base") {
        impactTextArray.push(`${convertActivityMatrixKeyToTextV2(villain.mindfulnessImp, activityMatrix)} 🙏 Mindfulness activities.`)
    }
    return impactTextArray
}

export const vulnerableKeys = ["hp", "mp", "lp"];
export const resistantKeys = ["hn", "mn", "ln"];

function convertActivityMatrixKeyToTextV3(impact: string, activityMatrix: ActivityMatrix[]) {
    let impactAbbreviation = activityMatrix.find((activity) => activity.key === impact)?.key;
    let impactText = ""
    switch (impactAbbreviation) {
        case "imm":
            impactText = "no effect";
            break;
        case "hp":
            impactText = "extreme";
            break;
        case "mp":
            impactText = "moderate";
            break;
        case "lp":
            impactText = "slight";
            break;
        case "hn":
            impactText = "extreme";
            break;
        case "mn":
            impactText = "moderate";
            break;
        case "ln":
            impactText = "slight";
            break;
        case "base":
            impactText = "the same effect";
            break;
        default:
            break;
    }
    return impactText
}

export function convertVulnerableImpactToText(villain: Villain, activityMatrix: ActivityMatrix[]): string[] {
    let impactTextArray: string[] = []
    if (villain.strengthImp && vulnerableKeys.includes(villain.strengthImp)) {
        impactTextArray.push(`💪 Strength activities (${convertActivityMatrixKeyToTextV3(villain.strengthImp, activityMatrix)}) `)
    }
    if (villain.cardioImp && vulnerableKeys.includes(villain.cardioImp)) {
        impactTextArray.push(`💚 Cardio activities (${convertActivityMatrixKeyToTextV3(villain.cardioImp, activityMatrix)})`)
    }
    if (villain.mobilityImp && vulnerableKeys.includes(villain.mobilityImp)) {
        impactTextArray.push(`🧘‍♀️ Mobility activities (${convertActivityMatrixKeyToTextV3(villain.mobilityImp, activityMatrix)})`)
    }
    if (villain.mindfulnessImp && vulnerableKeys.includes(villain.mindfulnessImp)) {
        impactTextArray.push(`🙏 Mindfulness activities (${convertActivityMatrixKeyToTextV3(villain.mindfulnessImp, activityMatrix)})`)
    }
    return impactTextArray
}

export function convertResistantImpactToText(villain: Villain, activityMatrix: ActivityMatrix[]) {
    let impactTextArray: string[] = []
    if (villain.strengthImp && resistantKeys.includes(villain.strengthImp)) {
        impactTextArray.push(`💪 Strength activities (${convertActivityMatrixKeyToTextV3(villain.strengthImp, activityMatrix)}) `)
    }
    if (villain.cardioImp && resistantKeys.includes(villain.cardioImp)) {
        impactTextArray.push(`💚 Cardio activities (${convertActivityMatrixKeyToTextV3(villain.cardioImp, activityMatrix)})`)
    }
    if (villain.mobilityImp && resistantKeys.includes(villain.mobilityImp)) {
        impactTextArray.push(`🧘‍♀️ Mobility activities (${convertActivityMatrixKeyToTextV3(villain.mobilityImp, activityMatrix)})`)
    }
    if (villain.mindfulnessImp && resistantKeys.includes(villain.mindfulnessImp)) {
        impactTextArray.push(`🙏 Mindfulness activities (${convertActivityMatrixKeyToTextV3(villain.mindfulnessImp, activityMatrix)})`)
    }
    return impactTextArray
}