import React from 'react';
import "../css/Celebrations.css";
import "../css/Duels.css";


const DefeatAnimation = (props: { bossImage: string }) => {
    // You might use a piece of state to control whether the boss has been defeated
    const [defeated, setDefeated] = React.useState(false);

    // Function to handle the defeat action
    const handleDefeat = () => {
        setDefeated(true);
        // You may want to perform additional logic when a boss is defeated
    };

    // Assume the boss is defeated for demonstration purposes
    React.useEffect(() => {
        handleDefeat();
    }, []);

    return (
        <div className={`image-container ${defeated ? 'defeated' : ''}`}>
            <img src={props.bossImage} alt="Boss" className="villain-avatar boss-image" />
            <img src="/assets/images/red-x.png" alt="Defeated" className="x-image" />
        </div>
    );
};

export default DefeatAnimation;
