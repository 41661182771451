import React from 'react';
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonAvatar,
  IonList,
  IonItem,
  IonLabel
} from '@ionic/react';

import './testProfile.css';  // Importing CSS file

interface Match {
  hero: string;
  result: string;
  stats: string;
  time: string;
}

interface ProfileProps { }

const TestProfile: React.FC<ProfileProps> = () => {
  const winRate = '56.73%';
  const lastPlayed = '17 hours ago';
  const record = '961-724-7';

  const matches: Match[] = [
    { hero: 'Underlord', result: 'Won Match', stats: '4/3/27', time: '19 hours ago' },
    { hero: 'Monkey King', result: 'Lost Match', stats: '12/2/7', time: '20 hours ago' },
    // ... add other matches
  ];

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Profile</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="ion-padding profile-container">
        <div className="header-section">
          <h2 className="winrate">Winrate: {winRate}</h2>
          <IonAvatar className="avatar">
            {/* Placeholder Image */}
            <img src="https://c5-na.altogic.com/_storage/645ad37d7ea57f56fe00c007/649dbd1e63cfc1b442f71b94/649dbde463cfc1b442f71b95" alt="avatar" />
          </IonAvatar>
          <p className="last-played">Last Played: {lastPlayed}</p>
          <p className="record">Record: {record}</p>
        </div>
        <IonList className="matches-list">
          {matches.map((match, index) => (
            <IonItem key={index} lines="none" style={{ background: '#333' }}>
              <img src={`https://c5-na.altogic.com/_storage/645ad37d7ea57f56fe00c007/649dbd1e63cfc1b442f71b94/649dbde463cfc1b442f71b95`} alt={match.hero} className="match-hero-avatar" />
              <IonLabel>
                <h2 className="hero-name">{match.hero}</h2>
                <p className="match-result">{match.result}</p>
                <p className="match-stats">{match.stats}</p>
                <p className="match-time">{match.time}</p>
              </IonLabel>
            </IonItem>
          ))}
        </IonList>
      </IonContent>
    </IonPage>
  );
}

export default TestProfile;