/* eslint-disable no-template-curly-in-string */
import log from "loglevel";
import Parse from 'parse';
import { SIDEKICK_MESSAGES_TABLE, SIDEKICK_TABLE, SIDEKICK_TYPES_TABLE } from "../models/databaseModels";
import { SidekickMessage } from "../models/messageModels";
import { RunObject, SidekickDBObject, SidekickObject, SidekickThreadObject, SidekickTypeObject, assistantDBObjectKey, assistantDisplayMessagesKey, assistantIntroMessage, sidekickDBObjectKey, sidekickDisplayMessagesKey, sidekickIntroMessage } from "../models/sideKickModels";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";
import { StorageService } from "./StorageService";
import { getHero, getUser_id } from "./UserService";
import { SidekickMessagesResponse } from "./SideKickService";
const storageService = new StorageService();

export async function disableAssistant(): Promise<void> {
    log.debug(`[disableAssistant] running function`)
    let userId = await getUser_id();
    let query = new Parse.Query(SIDEKICK_TABLE);
    query.equalTo('userId', userId);
    query.equalTo('isAssistant', true);
    let parseObject = await query.first().catch((error) => {
        sendSlackErrorNotification(`[disableAssistant] query Full Error Obj`, "disableAssistant", `${error}`);
        log.error(`[disableAssistant] Error Message: ${error}`);
        log.error(`[disableAssistant] Full Error Obj: `, error);
    });
    if (!parseObject) {
        log.debug(`[disableAssistant] No assistant found for userId ${userId}`);
        return
    };
    parseObject.set({ active: false });
    let result = await parseObject.save().catch((error) => {
        sendSlackErrorNotification(`[disableAssistant] save Full Error Obj`, "disableAssistant", `${error}`);
        console.error(`[disableAssistant] Error Message: ${error}`);
        console.error(`[disableAssistant] Full Error Obj: `, error);
        throw error;
    });
    if (!result) {
        log.error(`[disableAssistant] Error deleting assistant for userId ${userId}`);
        throw new Error(`[disableAssistant] Error deleting assistant for userId ${userId}`);
    }
    let resultJSON = result.toJSON();
    log.debug("[disableAssistant] DB result: ", resultJSON);
    log.debug(`[disableAssistant] Sidekick disabled`);
}

export async function createAssistantInDB(sidekickObject: SidekickThreadObject): Promise<SidekickDBObject> {
    let userId = await getUser_id();
    let hero = await getHero();
    let sidekickObj = new Parse.Object(SIDEKICK_TABLE);
    // Capital K's used because of the DB
    let updateSidekickObject: SidekickDBObject = {
        userId: userId,
        heroName: hero.heroName,
        sideKickName: sidekickObject.metadata.sideKickName,
        sideKickId: sidekickObject.metadata.sideKickId,
        sideKickThreadId: sidekickObject.id,
        sideKickImage: sidekickObject.metadata.imageUrl!,
        active: true,
        isAssistant: true,
    }
    sidekickObj.set(updateSidekickObject)
    let result = await sidekickObj.save().catch(function (error) {
        log.debug("Error: " + error.code + " " + error.message);
        log.debug("Error: " + error);
        sendSlackErrorNotification(`[storeSidekick] Full Error Obj`, "storeSidekick", `${error}`);
        throw error;
    });
    return result.toJSON() as unknown as SidekickDBObject;
}

export async function initiateAssistant(): Promise<SidekickObject> {
    let introMessage = assistantIntroMessage;
    let userId = await getUser_id();
    const params = {
        userId: userId,
        assistantName: 'SportsScienceAI',
        message: introMessage,
        imageUrl: 'https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/01cd3e33aa221ca06e8af8166aa73421_avatar.webp',
    }
    log.debug(`[initiateAssistant] running function`)
    let sidekickObject: SidekickObject = await Parse.Cloud.run('initiateAssistant', params).catch((error) => {
        sendSlackErrorNotification(`[initiateAssistant] Full Error Obj`, "initiateAssistant", `${error}`);
        console.error(`[initiateAssistant] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[initiateAssistant] sidekickObject: `, sidekickObject)
    if (sidekickObject) {
        let newSidekickDBObject = await createAssistantInDB(sidekickObject.threadObject).catch((error) => {
            sendSlackErrorNotification(`[initiateAssistant] createSidekickInDB`, "intiateSidekick", `${error}`);
            console.error(`[initiateAssistant] Full Error Obj: `, error);
            throw error;
        });
        await storageService.setObject(assistantDisplayMessagesKey, [sidekickObject.message]);
        await storageService.setObject(assistantDBObjectKey, newSidekickDBObject);
        // log.info(`[intiateSidekick] sidekickObject: `, sidekickObject);
    }
    return sidekickObject;
}

export async function getAssistantMessagesFromDB(threadId: string): Promise<SidekickMessagesResponse> {
    let displayMessagesKey = assistantDisplayMessagesKey;
    // Get all sidekick messages
    log.debug("[getAssistantMessagesFromDB] Fetching sidekick messages for threadId: ", threadId);
    let existingMessages = await storageService.getObject(displayMessagesKey);
    let allMessages: SidekickMessage[] = [];
    let query = new Parse.Query(SIDEKICK_MESSAGES_TABLE);
    query.equalTo("threadId", threadId);
    query.limit(1000);
    query.descending("createdAt");
    let results = await query.find();
    results.forEach((result) => {
        allMessages.push(result.toJSON() as unknown as SidekickMessage);
    });
    log.debug("[getAssistantMessagesFromDB] Messages: ", allMessages);
    let newMessages = false;
    let numberOfNewMessages = 0;
    if (existingMessages && existingMessages.length < allMessages.length) {
        newMessages = true;
        numberOfNewMessages = allMessages.length - existingMessages.length;
    }

    await storageService.setObject(displayMessagesKey, allMessages.reverse());
    return {
        messages: allMessages,
        newMessages: newMessages,
        numberOfNewMessages: numberOfNewMessages,
    }
}

export async function getAssistantMessages(threadId: string): Promise<SidekickMessage[]> {
    let messages: SidekickMessage[] = await storageService.getObject(assistantDisplayMessagesKey);
    getAssistantMessagesFromDB(threadId).then((dbMessages: SidekickMessagesResponse) => {
        // if dbMessages is different than messages, update messages
        if (dbMessages.messages.length !== messages.length) {
            return dbMessages.messages;
        }
    });
    if (messages) {
        return messages;
    } else {
        return [] as SidekickMessage[]
    }
}

export async function getAssistant(): Promise<SidekickDBObject | undefined> {
    let sidekickObject = await storageService.getObject(assistantDBObjectKey);
    if (sidekickObject) {
        return sidekickObject;
    }
    let userId = await getUser_id();
    let query = new Parse.Query(SIDEKICK_TABLE);
    query.equalTo("userId", userId);
    query.equalTo("active", true);
    query.equalTo("isAssistant", true);
    let result = await query.first();
    if (result) {
        let sidekickObject = result.toJSON() as unknown as SidekickDBObject;
        return sidekickObject;
    } else {
        // sendSlackErrorNotification(`[getSidekick] Full Error Obj`, "getSidekick", `No sidekick found for userId: ${userId}`);
        return undefined;
    }
}

export async function sendAssistantMessage(message: string, userId?: string): Promise<SidekickMessage> {
    if (!userId) {
        userId = await getUser_id();
    }
    // let user = await getUser();
    let hero = await getHero();
    let sidekick: SidekickDBObject | undefined = await getAssistant()
    if (!userId || !sidekick) {
        throw new Error(`[sendSidekickMessage] user is null`);
    }
    const params = {
        userId: userId,
        sideKickId: sidekick.sideKickId,
        threadId: sidekick.sideKickThreadId,
        message: message,
        name: sidekick.sideKickName,
        sideKickImageUrl: sidekick.sideKickImage,
    }
    log.debug(`[sendSidekickMessage] running function`)
    let sidekickMessage: SidekickMessage = await Parse.Cloud.run('createAssistantMessage', params).catch((error) => {
        sendSlackErrorNotification(`[sendSidekickMessage] Full Error Obj`, "sendSidekickMessage", `${error}`);
        console.error(`[sendSidekickMessage] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[sendSidekickMessage] sidekickMessage: `, sidekickMessage)
    if (!sidekickMessage) {
        throw new Error(`[sendSidekickMessage] sidekickMessage is null`);
    }
    return sidekickMessage;
}

export async function sendAssistantMessageWithRun(message: string, userId?: string): Promise<RunObject> {
    if (!userId) {
        userId = await getUser_id();
    }
    let sidekick: SidekickDBObject | undefined = await getAssistant()
    if (!userId || !sidekick) {
        throw new Error(`[sendAssistantMessageWithRun] user is null`);
    }
    const params = {
        userId: userId,
        sideKickId: sidekick.sideKickId,
        threadId: sidekick.sideKickThreadId,
        message: message,
    }
    log.debug(`[sendAssistantMessageWithRun] running function. Params: `, params)
    let runObject: RunObject = await Parse.Cloud.run('createAssistantMessageWithRun', params).catch((error) => {
        sendSlackErrorNotification(`[sendAssistantMessageWithRun] Full Error Obj`, "sendSidekickMessage", `${error}`);
        console.error(`[sendAssistantMessageWithRun] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[sendAssistantMessageWithRun] runObject: `, runObject)
    if (!runObject) {
        throw new Error(`[sendAssistantMessageWithRun] runObject is null`);
    }
    return runObject;
}

export async function processAssistantResponse(sidekickMessage: SidekickMessage, userInput: string): Promise<SidekickMessage[]> {
    // Initialize variables
    let existingDisplayMessages: SidekickMessage[] = [];

    existingDisplayMessages = await storageService.getObject(assistantDisplayMessagesKey);
    log.debug(`[processSidekickResponse] existingDisplayMessages: `, existingDisplayMessages);
    if (existingDisplayMessages === null) existingDisplayMessages = [];

    let userMessage: SidekickMessage = {
        source: "user",
        message: userInput,
    };
    let updatedDisplayResponse: SidekickMessage[] = [];
    updatedDisplayResponse = [...existingDisplayMessages, userMessage];
    updatedDisplayResponse = [...updatedDisplayResponse, sidekickMessage];
    log.debug(`[processSidekickResponse] updatedDisplayResponse: `, updatedDisplayResponse);
    await storageService.setObject(assistantDisplayMessagesKey, updatedDisplayResponse);
    return updatedDisplayResponse;
}

export async function getRunStatus(threadId: string, runId: string) {
    const params = {
        runId: runId,
        threadId: threadId,
    }
    let runStatus = await Parse.Cloud.run('createAssistantMessageCheckRunStatus', params).catch((error) => {
        sendSlackErrorNotification(`[getRunStatus] Full Error Obj`, "getRunStatus", `${error}`);
        console.error(`[getRunStatus] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[getRunStatus] runStatus: `, runStatus);
    return runStatus;
}

export async function processEndOfRun(threadId: string, sideKickId: string) {
    const userId = await getUser_id();
    if (!userId) {
        throw new Error(`[processEndOfRun] user is null`);
    }
    const params = {
        userId: userId,
        sideKickId: sideKickId,
        threadId: threadId,
    }
    log.debug(`[processEndOfRun] running function`)
    let sidekickMessage: SidekickMessage = await Parse.Cloud.run('createAssistantMessageRunCompleted', params).catch((error) => {
        sendSlackErrorNotification(`[processEndOfRun] Full Error Obj`, "sendSidekickMessage", `${error}`);
        console.error(`[processEndOfRun] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[processEndOfRun] sidekickMessage: `, sidekickMessage)
    if (!sidekickMessage) {
        throw new Error(`[processEndOfRun] sidekickMessage is null`);
    }
    return sidekickMessage;
}
