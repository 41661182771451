import { IonCol, IonContent, IonRow } from '@ionic/react';
import log from 'loglevel';
import React, { useContext, useEffect } from 'react';
import GlobalState from '../../contexts/GlobalState';
import { accuracyIcon, cardioIcon, damageIcon, dodgeIcon } from '../../models/exerciseModels';
import { Achievement, getAllAchievements } from '../../services/AchievementsService';
import { StatsTour } from '../Tour/Steps';
import { TourComponent } from '../Tour/StepsComponent';
import HeroStatsCircularComponent from './HeroStatsCircularComponent';

export const ProfileStats = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject } = globalState;
    const [achievements, setAchievements] = React.useState<Achievement[]>([]);

    useEffect(() => {
        // getAchievements();
    }, []);

    async function getAchievements() {
        let allAchievements: Achievement[] | null = await getAllAchievements();
        if (allAchievements) {
            log.debug("Achievements: ", allAchievements);
            setAchievements(allAchievements);
        }
    }

    return (
        <>
            <IonContent>
                <HeroStatsCircularComponent showCoachMark={true} showSkills={true} />
                <IonRow>
                    <IonCol className='damage-tour-hint secondary-box' style={{ border: "1px solid var(--strength-color)" }}>
                        {damageIcon}<br />
                        Damage<br />
                        <div>
                            <strong>{heroObject?.heroDamage}</strong>
                        </div>
                    </IonCol>
                    <IonCol className='health-tour-hint secondary-box' style={{ border: "1px solid var(--cardio-color)" }}>
                        {cardioIcon}<br />
                        Health<br />
                        <div>
                            <strong>{heroObject?.heroHealth}</strong>
                        </div>
                    </IonCol>
                    <IonCol className='dodge-tour-hint secondary-box' style={{ border: "1px solid var(--mobility-color)" }}>
                        {dodgeIcon}<br />
                        Dodge<br />
                        <div>
                            <strong>{heroObject?.heroDodge}</strong>
                        </div>
                    </IonCol>
                    <IonCol className='accuracy-tour-hint secondary-box' style={{ border: "1px solid var(--mindfulness-color)" }}>
                        {accuracyIcon}<br />
                        Accuracy<br />
                        <div>
                            <strong>{heroObject?.heroToHit}</strong>
                        </div>
                    </IonCol>
                </IonRow>

                {/* <IonRow className='profile-level-container'>
                    <IonCol size='2'>
                        💪<br />Strength
                    </IonCol>
                    <IonCol size='8' className='profile-level-bar-container'>
                        <ProgressBar
                            className='goal-progress-bar'
                            completed={`${heroObject?.strengthPoints}`}
                            maxCompleted={heroObject?.strengthLevel! * HeroPointsPerLevel}
                            bgColor='var(--strength-color)' />
                    </IonCol>
                    <IonCol size='2'>
                        <p>Level<br />{heroObject?.strengthLevel!}</p>
                    </IonCol>
                </IonRow>
                <IonRow className='profile-level-container'>
                    <IonCol size='2'>
                        💚<br />Cardio
                    </IonCol>
                    <IonCol size='8' className='profile-level-bar-container'>
                        <ProgressBar
                            className='goal-progress-bar'
                            completed={`${heroObject?.cardioPoints}`}
                            maxCompleted={heroObject?.cardioLevel! * HeroPointsPerLevel}
                            bgColor='var(--cardio-color)' />
                    </IonCol>
                    <IonCol size='2'>
                        <p>Level<br />{heroObject?.cardioLevel!}</p>
                    </IonCol>
                </IonRow>
                <IonRow className='profile-level-container'>
                    <IonCol size='2'>
                        🧘‍♀️<br />Mobility
                    </IonCol>
                    <IonCol size='8' className='profile-level-bar-container'>
                        <ProgressBar
                            className='goal-progress-bar'
                            completed={`${heroObject?.mobilityPoints}`}
                            maxCompleted={heroObject?.mobilityLevel! * HeroPointsPerLevel}
                            bgColor='var(--mobility-color)' />
                    </IonCol>
                    <IonCol size='2'>
                        <p>Level<br />{heroObject?.mobilityLevel!}</p>
                    </IonCol>
                </IonRow>
                <IonRow className='profile-level-container'>
                    <IonCol size='2'>
                        🙏<br />Mindfulness
                    </IonCol>
                    <IonCol size='8' className='profile-level-bar-container'>
                        <ProgressBar
                            className='goal-progress-bar'
                            completed={`${heroObject?.mindfulnessPoints}`}
                            maxCompleted={heroObject?.mindfulnessLevel! * HeroPointsPerLevel}
                            bgColor='var(--mindfulness-color)' />
                    </IonCol>
                    <IonCol size='2'>
                        <p>Level<br />{heroObject?.mindfulnessLevel!}</p>
                    </IonCol>
                </IonRow> */}
                {/* <div>
                    <h1>Records</h1>
                    <IonGrid>
                        <IonRow>
                            <IonCol>
                                Record
                            </IonCol>
                            <IonCol>
                                Value
                            </IonCol>
                            <IonCol>
                                Date
                            </IonCol>
                        </IonRow>
                        {achievements && achievements.map((achievement: Achievement, index: number) => {
                            return (
                                <IonRow key={index}>
                                    <IonCol>
                                        {achievement.achievement}
                                    </IonCol>
                                    <IonCol>
                                        {achievement.value}
                                    </IonCol>
                                    <IonCol>
                                        {achievement.date}
                                    </IonCol>
                                </IonRow>
                            )
                        })}
                    </IonGrid>
                </div> */}
                <TourComponent coachMarkName='profileStats' steps={StatsTour} modalShowing={false} link={`/skills/cardio`} />
            </IonContent>
        </>
    )
}
