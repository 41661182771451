import { IonButton, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar } from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { ReactNode, useEffect, useState } from 'react'
import '../css/Updates.css';
import { VersionInfo, versionUpdateKey } from '../models/userModel';
import { StorageService } from "../services/StorageService";
import log from 'loglevel';
const storageService = new StorageService();


const ShowVersionUpdate = (props: { updateString: string, showUpdate: React.Dispatch<React.SetStateAction<any>> }) => {
    let parsedData;

    useEffect(() => {
        async function updateVersionInfo() {
            let versionInfo: VersionInfo | null = await storageService.getObject(versionUpdateKey)
            if (versionInfo !== null) {
                versionInfo = {
                    ...versionInfo,
                    updateShown: true
                }
                await storageService.setObject(versionUpdateKey, versionInfo)
            }
        }
        updateVersionInfo()
    }, [])


    const closeModal = async () => {
        props.showUpdate({ show: false, update: "" });
    };

    try {
        parsedData = JSON.parse(props.updateString);
    } catch (error) {
        log.error("Error parsing update string: " + error)
        return (
            <>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Recent Updates</IonTitle>
                        <IonButton color="light" slot="end" onClick={closeModal}>
                            <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                        </IonButton>
                    </IonToolbar>
                </IonHeader>
                <IonContent>

                </IonContent>
            </>
        )
    }


    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Recent Updates</IonTitle>
                    <IonButton color="light" slot="end" onClick={closeModal}>
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{ margin: "10px" }}>
                    {Object.entries(parsedData).map(([key, value]) => (
                        <div key={key}>
                            <div className='update-header' >{key}</div>
                            <div className='update-body'>{value as ReactNode}</div>
                        </div>
                    ))}
                </div>
            </IonContent>
        </>
    )
}

export default ShowVersionUpdate