import { getPlatforms, IonButton, IonContent, IonHeader, IonPage, IonTextarea, IonTitle, IonToolbar } from "@ionic/react";
import React, { useState } from "react";
import { useHistory } from "react-router";
import {
	enableNotifications,
	registerActionTypes,
	scheduleChallengeNotifications,
	sendSlackNotification,
	setupNotificationListener,
	storeDeliveredNotifications,
} from "../services/NotificationService";
import { StorageService } from "../services/StorageService";
import { LocalNotifications } from "@capacitor/local-notifications";
// import { PushNotifications } from "@capacitor/push-notifications";
import moment from "moment";
import { random } from "lodash";

function NotificationPlayGround() {
	const [log, setLog] = useState("");
	const history = useHistory();
	const storageService = new StorageService();
	const messageBreak = "<br>*********************<br>";

	const showRecentPushes = async () => {
		log.debug("Pulling recent notifications");
		let recentNotifications = await storageService.getObject("deliveredNotifications");
		setLog((log) => [...log, messageBreak, JSON.stringify(recentNotifications)]);
	};

	const showLog = (message) => {
		log.debug(message);
		setLog((log) => [...log, , messageBreak, "<br><br>" + message]);
	};

	async function setupPush() {
		setLog((log) => [...log, messageBreak, "Setting up push"]);
		await enableNotifications();
	}

	const scheduleDailyNotification1 = () => {
		setLog((log) => [...log, messageBreak, "Scheduling morning update<br>"]);
		//setLog((log) => [...log, '<br>Date of next morning update: '+morningUpdateSchedule.format("MM/DD/YY h:mm:ss a")]);
		LocalNotifications.schedule({
			notifications: [
				{
					title: "Daily Notification 'on'",
					body: "Here is your daily update on your FitChallenge Progress",
					id: 1,
					schedule: {
						on: {
							year: 2020,
							month: 11,
							day: 10,
							hour: 14,
							minute: 10,
							second: 0,
						},
						repeats: true,
						every: "minute",
						count: 5,
					},
					extra: {
						destination: "settings",
					},
				},
			],
		});
	};

	const scheduleDailyNotification2 = () => {
		setLog((log) => [...log, messageBreak, "Scheduling morning update<br>"]);
		//let newDate = new Date(2020, 11, 10, 14, 40, 5)
		showLog("Show every day at 7am");
		LocalNotifications.schedule({
			notifications: [
				{
					title: "Daily Notification",
					body: "7am",
					id: 2,
					schedule: {
						repeats: true,
						every: "day",
						on: {
							hour: 7,
							minute: 0,
						},
						count: 1,
					},
					actionTypeId: "sendNumbers",
					extra: {
						destination: "dailyupdate",
					},
				},
			],
		});
	};

	const scheduleDailyNotification3 = () => {
		setLog((log) => [...log, messageBreak, "Scheduling morning update<br>"]);
		LocalNotifications.schedule({
			notifications: [
				{
					title: "Daily Notification every 30 mins",
					body: "Here is your daily update on your FitChallenge Progress",
					id: 3,
					schedule: {
						every: "minute",
						count: 30,
						repeats: true,
					},
					extra: {
						destination: "settings",
					},
				},
			],
		});
	};

	const setupListeners = () => {
		LocalNotifications.addListener("localNotificationActionPerformed", (notification) => {
			showLog("Result from localNotificationActionPerformed: " + JSON.stringify(notification));
			history.push("/" + notification.notification.extra.destination);
		});
		LocalNotifications.addListener("localNotificationReceived", async (notification) => {
			showLog("Result from localNotificationActionReceived" + JSON.stringify(notification));
			if (notification.actionTypeId === "sendNumbers") {
				showLog("Sending numbers");
				sendImmediateNotification();
			}
		});
		log.debug("localNotificationActionPerformed & localNotificationActionReceived listener added");
	};

	const scheduleRepeatNotification = async () => {
		let mins = 10;
		let date = moment().add(mins, "minutes");
		setLog((log) => [...log, messageBreak + `Scheduling 3 updates 10 seconds apart<br>`]);
		for (let i = 1; i <= 3; i++) {
			log.debug(`Scheduling update  ${i} at ${date.format()}`);
			// setLog((log) => [...log, `Scheduling update  ${i} at ${date.format()}<br>`]);
			await LocalNotifications.schedule({
				notifications: [
					{
						title: `Update`,
						body: `This will fire every ${mins} minutes`,
						id: random(0, 1000),
						schedule: {
							at: date.toDate(),
							// repeats: true,
							// every: "second",
							// count: 60,
						},
					},
				],
			});
			date = moment(date).add(mins, "minutes");
		}
	};

	const sendImmediateNotification = async () => {
		showLog("This will send 10 seconds into the future");
		LocalNotifications.schedule({
			notifications: [
				{
					title: "IMMEDIATE",
					body: "You have points",
					// id: "workout-4",
					id: 333,
					schedule: {
						on: new Date(Date.now() + 10000),
					},
					extra: {
						destination: "dailyUpdate",
					},
					actionTypeId: "workout-4",
				},
			],
		});
	};

	const removeListeners = () => {
		LocalNotifications.removeAllListeners();
		setLog((log) => [...log, messageBreak + "All listeners removed"]);
	};

	const listAllNotifications = async () => {
		let pending = await LocalNotifications.getPending();
		log.debug("Pending: " + JSON.stringify(pending));
		setLog((log) => [...log, messageBreak + "Pending: " + JSON.stringify(pending)]);
	};

	const clearAllNotifications = async () => {
		setLog((log) => [...log, messageBreak, "Clearing all notifications..."]);
		let pending = await LocalNotifications.getPending();
		pending.notifications.forEach((element) => {
			log.debug("Element: " + JSON.stringify(element));
			let result = LocalNotifications.cancel({
				notifications: [{ id: element.id }],
			});
			setLog((log) => [...log, messageBreak + "ID " + element.id + " cleared"]);
			setLog((log) => [...log, "Result: " + JSON.stringify(result)]);
		});
	};

	const showToken = async () => {
		let pushToken = await storageService.getItem("pushToken");
		if (pushToken !== "") {
			showLog("<br><b>Your pushToken:</b><br>" + pushToken);
		} else {
			showLog("<br><b>No push token stored</b><br>");
		}
	};
	const setupPushListeners = async () => {
		setupNotificationListener();
	};

	// const getDeliveredNotifications = async () => {
	// 	setLog((log) => [...log, messageBreak + "notifications saved to localstorage"]);
	// 	await storeDeliveredNotifications();
	// };

	// const clearDeliveredNotifications = async () => {
	// 	PushNotifications.removeAllDeliveredNotifications();
	// };

	const sendLogsToSlack = async () => {
		setLog((log) => [...log, messageBreak + "sending to slack"]);
		sendSlackNotification(log.toString(), "notificationtesting", true);
	};

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Settings</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<h2>Setup notifications</h2>
				{/* <IonButton onClick={setupListeners}>setupListeners</IonButton> */}
				<IonButton size="small" onClick={() => registerActionTypes()}>
					Register Action types
				</IonButton>
				<IonButton size="small" onClick={setupPushListeners}>
					setupPushListeners
				</IonButton>
				<IonButton size="small" onClick={removeListeners}>
					removeListeners
				</IonButton>
				<IonButton size="small" onClick={setupPush}>
					Setup Push
				</IonButton>
				<h2>Show notifications</h2>
				<IonButton size="small" onClick={showRecentPushes}>
					Show Delivered Notifications
				</IonButton>
				<IonButton size="small" onClick={listAllNotifications}>
					Show pending notifications{" "}
				</IonButton>
				{/* <IonButton size="small" onClick={getDeliveredNotifications}>
					Save Delivered Notifications
				</IonButton> */}
				<IonButton size="small" onClick={showToken}>
					Show Token
				</IonButton>
				<h2>Schedule notifications</h2>
				<IonButton size="small" onClick={scheduleRepeatNotification}>
					Schedule repeat notification
				</IonButton>
				<IonButton size="small" onClick={sendImmediateNotification}>
					Send Immediate Notification
				</IonButton>
				<h2>Clear notifications</h2>
				{/* <IonButton size="small" onClick={clearDeliveredNotifications}>
					Clear Delivered Notifications
				</IonButton> */}
				<IonButton size="small" onClick={clearAllNotifications}>
					Clear Pending Notifications
				</IonButton>
				<h2>Logs</h2>
				<IonButton size="small" onClick={sendLogsToSlack}>
					Send logs to Slack
				</IonButton>
				<IonButton size="small" onClick={() => setLog("")}>
					Clear logs
				</IonButton>
			</IonContent>
			<IonContent>
				<div dangerouslySetInnerHTML={{ __html: log }} autoGrow="true" readonly="true" />
			</IonContent>
		</IonPage>
	);
}

export default NotificationPlayGround;
