/* eslint-disable react-hooks/rules-of-hooks */
import { IonCol, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { cardioColor, mindfulnessColor, mobilityColor, strengthColor } from "../../../constants/theme";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Badge.css";
import "../../../css/HeroHealthComponent.css";
import { Villain } from "../../../models/villainModels";
import { calculateRelativeStat, calculateRelativeStats } from "../../../services/DuelsServics";
import { accuracyIcon, damageIcon, dodgeIcon, healthIcon } from "../../../models/exerciseModels";
import log from "loglevel";

export default function VillainAttributesCircularComponent(props: { villain: Villain, showLoading: boolean }) {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, previousHeroObject, activityLedger } = globalState;

    interface VillainStats {
        strengthLevel: number;
        cardioLevel: number;
        mindfulnessLevel: number;
        mobilityLevel: number;
    }

    const [progressBar, setProgressBar] = useState<VillainStats>();
    const [strengthLevel, setStrengthLevel] = useState<number>(0);
    const [cardioLevel, setCardioLevel] = useState<number>(0);
    const [mindfulnessLevel, setMindfulnessLevel] = useState<number>(0);
    const [mobilityLevel, setMobilityLevel] = useState<number>(0);

    useEffect(() => {
        if (props.villain) {
            setProgressBar({
                strengthLevel: props.villain.strength,
                cardioLevel: props.villain.cardio,
                mindfulnessLevel: props.villain.mindfulness,
                mobilityLevel: props.villain.mobility,
            });
            setStrengthLevel(props.villain.strength);
            setCardioLevel(props.villain.cardio);
            setMindfulnessLevel(props.villain.mindfulness);
            setMobilityLevel(props.villain.mobility);
        }
    }, [props.villain, props.showLoading]);

    const attributes = ["strength", "cardio", "mobility", "mindfulness"] as const;
    type AttributeType = (typeof attributes)[number];

    function attributeIcon(attribute: AttributeType) {
        switch (attribute) {
            case "strength":
                return "💪";
            case "cardio":
                return "💚"
            case "mobility":
                return "🧘"
            case "mindfulness":
                return "🙏"
        }
    }

    function showRelativeStat(statDescription: string) {
        switch (statDescription) {
            case "low":
                return (
                    <span style={{ color: "green" }}>{statDescription}</span>
                )
            case "high":
                return (
                    <span style={{ color: "red" }}>{statDescription}</span>
                )
            case "very high":
                return (
                    <span style={{ color: "orange" }}>{statDescription}</span>
                )
            default:
                return (
                    <span style={{ color: "var(--ion-color-primary)" }}>{statDescription}</span>
                )
        }
    }


    return (
        <>
            {heroObject && progressBar ? (
                <div className="progress-bar-container" style={{ marginTop: "5%" }}>
                    <IonRow className="attribute-container" style={{ width: "100%" }}>
                        {attributes.map((attribute: AttributeType) => {
                            let level = progressBar[`${attribute}Level` as keyof VillainStats];
                            let barColor = "";
                            let stat = "";
                            let levelNumber = 0;
                            let icon = "";
                            switch (attribute) {
                                case "strength":
                                    barColor = `${strengthColor}`;
                                    stat = "Damage";
                                    levelNumber = props.villain.strength;
                                    icon = damageIcon;
                                    break;
                                case "cardio":
                                    barColor = `${cardioColor}`;
                                    stat = "Health";
                                    levelNumber = props.villain.cardio;
                                    icon = healthIcon;
                                    break;
                                case "mobility":
                                    barColor = `${mobilityColor}`;
                                    stat = "Dodge";
                                    levelNumber = props.villain.mobility;
                                    icon = dodgeIcon;
                                    break;
                                case "mindfulness":
                                    barColor = `${mindfulnessColor}`;
                                    stat = "Accuracy";
                                    levelNumber = props.villain.mindfulness;
                                    icon = accuracyIcon;
                                    break;
                            }
                            // return (
                            //     <IonCol key={`${attribute}`} size="2.5" className="circular-progress-col">
                            //         <div className="attribute-title">{attributeIcon(attribute)}<br />{attribute}</div>
                            //         <CircularProgressbar
                            //             styles={buildStyles({
                            //                 pathColor: `${barColor}`,
                            //                 textColor: "white",
                            //                 textSize: "30px",
                            //             })}
                            //             value={100}
                            //             text={props.showLoading === true ? `...` : `${level}`}

                            //         />
                            //     </IonCol>
                            // );
                            return (
                                <IonCol key={`${attribute}`} size="2.5" className="circular-progress-col-villain">
                                    <div className="stat-icon-profile" style={{ background: `${barColor}` }}>{icon}</div><br />
                                    {stat}<br />
                                    <div style={{ fontSize: "20px" }}>{showRelativeStat(calculateRelativeStat(levelNumber, props.villain.level))}</div>
                                </IonCol>
                            );
                        })}
                    </IonRow>
                </div>
            ) : (
                ""
            )}
        </>
    );
}
