import { IonAvatar, IonButton } from "@ionic/react";
import log from "loglevel";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/HeroChatComponent.css";
import { DuelStatus } from "../../../models/duelsModels";
import { DuelDisplayMessage } from "../../../models/messageModels";
import { insertNewLines } from "../../../services/HelperService";
import { button } from "@nextui-org/react";

const DuelsRenderComponentCombined = (props: {
	duelStatus: DuelStatus;
	messages: DuelDisplayMessage[];
}) => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { heroObject } = globalState;
	const [showAllMessages, setShowAllMessages] = useState<boolean>(false);
	const [messages, setMessages] = useState<DuelDisplayMessage[]>([]);
	let buttonShown = false;

	useEffect(() => {
		if (showAllMessages === false) {
			if (props.messages && props.messages.length > 0) {
				setMessages([props.messages[0]]);
			}
		} else {
			setMessages(props.messages);
		}
	}, [props.messages, showAllMessages]);


	return (
		<>
			{messages &&
				messages.length > 0 &&
				messages.map((message: DuelDisplayMessage, index: number) => {
					if (message.content.indexOf("###") === -1) {
						// const cleanedContent = message.content.replace(/\n+/g, "\n"); // Replace consecutive newline characters with a single newline
						// const splitContent = cleanedContent.split("\n");
						let cleanedContent = insertNewLines(message.content); // this is still needed for re-renders
						const splitContent = cleanedContent.split("\n");
						if (message.role === "assistant" || message.role === "villain") {
							return (
								<>
									<div key={Math.random()} className="assistant-message-div">
										<IonAvatar className="assistant-message-avatar">
											<img
												className="user-avatar"
												style={{ width: "100%", height: "100%", objectFit: "cover" }}
												src={props.duelStatus.villainImageURL}
												alt=""
											/>
										</IonAvatar>
										<div className={"assistant-message-container"}>
											{splitContent.map((content: string, contentIndex: number) => (
												<div key={Math.random()} className={`assistant-message-bubble ${contentIndex > 0 ? "bubble-pad" : ''}`}>
													{content}
												</div>
											))}
										</div>
									</div>
									{/* {message.timestamp && (
										<div key={Math.random()} className="message-timestamp bot">
											<span>
												<>{formatMomentDate(message.timestamp)}</>
											</span>
										</div>
									)} */}
									{message.imageUrl && message.imageUrl !== "" && (
										<>
											<div key={Math.random()} className="assistant-message-div">
												<IonAvatar className="assistant-message-avatar">
													<img
														className="user-avatar"
														style={{ width: "100%", height: "100%", objectFit: "cover" }}
														src={props.duelStatus.villainImageURL}
														alt=""
													/>
												</IonAvatar>
												<div className={"assistant-message-container"}>
													<div className={"assistant-message-bubble"}>
														<img src={message.imageUrl} alt="" />
													</div>
												</div>
											</div>
										</>
									)}
								</>
							);
						} else {
							return (
								<>
									<div key={Math.random()} className="hero-message-div">
										<div className={"hero-message-container"}>
											{splitContent.map((content: string, contentIndex: number) => (
												<div key={Math.random()} className={`hero-message-bubble ${contentIndex > 0 ? "bubble-pad" : ''}`}>
													{content}
												</div>
											))}
										</div>
										<IonAvatar className="hero-message-avatar">
											<img
												className="user-avatar"
												style={{ width: "100%", height: "100%", objectFit: "cover" }}
												src={heroObject?.heroImageURL ? `${heroObject?.heroImageURL}` : `assets/images/onboarding/unknown-person.jpg`}
												alt=""
											/>
										</IonAvatar>
									</div>
									{/* {message.timestamp && (
										<div key={Math.random()} className="message-timestamp hero">
											<span>
												<>{formatMomentDate(message.timestamp)}</>
											</span>
										</div>
									)} */}
									{message.imageUrl && message.imageUrl !== "" && (
										<>
											<div key={Math.random()} className="hero-message-div">
												<IonAvatar className="hero-message-avatar">
													<img
														className="user-avatar"
														style={{ width: "100%", height: "100%", objectFit: "cover" }}
														src={heroObject?.heroImageURL ? `${heroObject?.heroImageURL}` : `assets/images/onboarding/unknown-person.jpg`}
														alt=""
													/>
												</IonAvatar>
												<div className={"hero-message-container"}>
													<div className={"hero-message-bubble"}>
														<img src={message.imageUrl} alt="" />
													</div>
												</div>
											</div>
										</>
									)}
								</>
							);
						}
					}
				})}
			{messages &&
				messages.length > 0 && (
					<div key={Math.random()} className="duels-show-all-messages-button" onClick={() => setShowAllMessages(!showAllMessages)}>
						{showAllMessages ? (
							<>
								Show Less<span className="caret"></span>
							</>
						) : (
							<>
								Read More <span className="caret">▶</span>
							</>
						)
						}
					</div>
				)}

		</>
	);
};

export default DuelsRenderComponentCombined;
