import { DuelStatus } from "./duelsModels";

export const activityCelebrationImages = [
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/36a7b121c26d37cefcd2c1bb389ac163_DreamShaper_v7_In_this_captivating_image_a_triumphant_superher_3.png",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/8774dda2f5736068eaf0205c10324841_PhotoReal_A_group_of_diverse_super_heroes_gathered_around_a_ta_2.png",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/0205eb6515621e984898b7296a99308e_woman-growing.jpg",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/f43c627dfd65f7210399b485f38092b1_long-haired-hero-celebration.jpg",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/a7d4f4202651f8977a6c0285c8fe49dc_hero-staring-off.jpg",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/a958bfa26e7afe7f536f4603d0c3ca59_heroes-improving.jpg",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/8d953006e293dc06a5b53ed5de58e539_heroes-celebrating-2.jpg",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/fe668ed17c5ad3d4aa526ad4e0eb819e_heroes-celebrating.jpg",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/1cf798e9becfb0a61bb85845851bcea7_hero-celebrating.jpg",
    "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/e184324b12310b735cb9409e79fa93b2_becoming-stronger.jpg",
]

export enum NotificationType {
    "heroStatsUpdate",
    "duelWin",
    "noCurrentDuel",
    "chooseSpecialization",
    "strengthGoalAccomplished",
    "cardioGoalAccomplished",
    "mobilityGoalAccomplished",
    "mindfulnessGoalAccomplished",
}

export type NotificationObject = {
    type: NotificationType,
    updateObject?: any
}

export const SampleDuelStatus: DuelStatus = {
    "villainID": "64de715a526099f915d92a9f",
    "user_id": "649dbe6059a3f9541569c6b4",
    "heroName": "Bigg Rigg",
    "heroToHit": 106,
    "heroDamage": 49,
    "villainName": "Lilith Luna",
    "villainImageURL": "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/8c22799eca84b8511f3ea4d286645219_lillith-luna.jpeg",
    "heroMaxHealth": 402,
    "villainMaxHealth": 488,
    "complete": true,
    "winner": "hero",
    "villainCombatPrompt": "",
    "villainGeneralPrompt": "",
    "villainStrength": 4,
    "villainCardio": 19,
    "villainMobility": 34,
    "villainMindfulness": 19,
    "villainLevel": 72,
    "heroDodge": 52.5,
    "heroHealth": 3,
    "villainToHit": 105,
    "villainDamage": 46.4,
    "villainDodge": 46.5,
    "villainHealth": 0,
    "statusId": "c648bd85-47ee-4a22-89cd-199e1689bf89",
    "createdAt": "2023-11-05T23:11:11.746Z",
    "updatedAt": "2023-11-07T02:00:49.751Z",
    "villainGroupInstanceId": "2e2aa305-4bf4-4f0b-a829-c9d46501c9ad",
    "difficulty": "medium"
}

export const SampleUpdateObject: HeroStatsUpdate = {
    "summary": {
        "strength": {
            "points": {
                "change": "increase",
                "value": 102
            },
            "level": {
                "change": "increase",
                "value": 1,
                "current": 25
            },
            goalJustAccomplished: true,
        },
        "cardio": {
            "points": {
                "change": "increase",
                "value": 102
            },
            "level": {
                "change": "increase",
                "value": 1,
                "current": 25
            },
            goalJustAccomplished: true,
        },
        "level": {
            "change": "increase",
            "value": 1,
            "current": 84
        },
        "statsSummary": {
            "damage": {
                "change": "increase",
                "value": 1,
                "current": 54
            },
            "health": {
                "change": "increase",
                "value": 4,
                "current": 435
            }
        }
    },
    "date": "2023-10-24T05:12:35+00:00",
    "reset": true
}

type ChangeTypes = "increase" | "decrease" | "none";

export type HeroStatsUpdate = {
    summary?: {
        mindfulness?: {
            points?: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            level: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            goalJustAccomplished?: boolean,
            message?: string
        },
        strength?: {
            points?: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            level: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            goalJustAccomplished?: boolean,
            message?: string
        },
        cardio?: {
            points?: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            level: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            goalJustAccomplished?: boolean,
            message?: string
        },
        mobility?: {
            points?: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            level: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            goalJustAccomplished?: boolean,
            message?: string
        },
        level?: {
            change?: ChangeTypes,
            value?: number,
            current?: number,
        },
        statsSummary?: {
            accuracy?: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            damage?: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            dodge?: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            },
            health?: {
                change?: ChangeTypes,
                value?: number,
                current?: number,
            }
        }
    },
    date?: string,
    reset?: boolean,
}