import { IonCard, IonCardContent, IonCardTitle, IonCol, IonIcon, IonRow } from '@ionic/react';
import ProgressBar from "@ramonak/react-progress-bar";
import { checkmarkDoneCircle, radioButtonOffOutline } from 'ionicons/icons';
import moment from 'moment';
import { useEffect, useState } from 'react';
import "../../../css/Goals.css";
import { Goal } from '../../../models/goalsModels';

export const GoalCard = (props: { goal: Goal }) => {
    const [goalsAccomplished, setGoalsAccomplished] = useState<any>({ strength: false, cardio: false, mobility: false, mindfulness: false });

    useEffect(() => {
        setGoalsAccomplished({
            strength: props.goal.strengthPoints > 0 ? props.goal.strengthPoints >= props.goal.strengthGoalPoints : false,
            cardio: props.goal.cardioPoints > 0 ? props.goal.cardioPoints >= props.goal.cardioGoalPoints : false,
            mobility: props.goal.mobilityPoints > 0 ? props.goal.mobilityPoints >= props.goal.mobilityGoalPoints : false,
            mindfulness: props.goal.mindfulnessGoalPoints > 0 ? props.goal.mindfulnessPoints >= props.goal.mindfulnessGoalPoints : false
        })
    }, [props.goal])
    // note: This whole component should not render unless goal exists
    return (
        <>
            {props.goal && (
                <IonCard className='current-goal-hint goal-background-past'>
                    <IonCardTitle style={{ textAlign: "center", color: "white", marginTop: "10px", fontSize: "20px" }}>{moment(props.goal.startDate).format("MM/DD")} - {moment(props.goal.endDate).format("MM/DD")}</IonCardTitle>
                    <IonCardContent>
                        {renderGoalProgressBar("💪", props.goal.strengthPoints, props.goal.strengthGoalPoints, "var(--strength-color)", goalsAccomplished.strength)}
                        {renderGoalProgressBar("💚", props.goal.cardioPoints, props.goal.cardioGoalPoints, "var(--cardio-color)", goalsAccomplished.cardio)}
                        {renderGoalProgressBar("🧘‍♀️", props.goal.mobilityPoints, props.goal.mobilityGoalPoints, "var(--mobility-color)", goalsAccomplished.mobility)}
                        {renderGoalProgressBar("🙏", props.goal.mindfulnessPoints, props.goal.mindfulnessGoalPoints, "var(--mindfulness-color)", goalsAccomplished.mindfulness)}
                    </IonCardContent>
                    <div className='goal-bonus-div'>
                        {renderGoalBonus("💪", goalsAccomplished.strength, "+20% damage for the week")}
                        {renderGoalBonus("💚", goalsAccomplished.cardio, "+20% health for the week")}
                        {renderGoalBonus("🧘‍♀️", goalsAccomplished.mobility, "+20% dodge for the week")}
                        {renderGoalBonus("🙏", goalsAccomplished.mindfulness, "+20% accuracy for the week")}
                    </div>
                </IonCard>
            )}
        </>
    );

    function renderGoalProgressBar(icon: string, points: number, goalPoints: number, bgColor: string, accomplished: boolean) {
        return (
            <IonRow className='goal-bar-container'>
                <IonCol size="1">
                    {icon}
                </IonCol>
                <IonCol size="8.5" className='goal-progress-bar-container'>
                    <ProgressBar
                        className='goal-progress-bar'
                        completed={`${points}`}
                        maxCompleted={goalPoints}
                        bgColor={bgColor} />
                </IonCol>
                <IonCol size="2" className='goal-cell' style={{ color: "white" }}>
                    {goalPoints} {goalPoints > 0 && points >= goalPoints && "🏆"}
                </IonCol>
            </IonRow>
        );
    }

    function renderGoalBonus(icon: string, accomplished: boolean, text: string) {
        return (
            <>
                <IonIcon style={{ color: accomplished === true && "green" }} icon={accomplished === true ? checkmarkDoneCircle : radioButtonOffOutline} />{text} <br />
            </>
        );
    }
}