import Parse from "parse";
import { sendSlackErrorNotification } from "./NotificationService";
import log from "loglevel";
import { StorageService } from "../services/StorageService";
const storageService = new StorageService();

export const inviteCodeKey = "inviteCode";


export async function createInviteCode(userId: string, heroName: string) {
    const params = {
        userId: userId,
        heroName: heroName,
    }
    const result = await Parse.Cloud.run('createInviteCode', params).catch((error) => {
        sendSlackErrorNotification(`[createInviteCode]`, "createInviteCode", error);
        log.debug(`[createInviteCode] Error Message: ${error}`);
        log.debug(`[createInviteCode] Full Error Obj: `, error);
        throw error;
    });
    log.debug("[createInviteCode] result: ", result);
    return result;
}

export async function getInviteCode(userId: string) {
    let storedCode = await storageService.getObject(inviteCodeKey);
    if (storedCode) {
        return storedCode;
    }
    const params = {
        userId: userId,
    }
    const result = await Parse.Cloud.run('getInviteCode', params).catch((error) => {
        sendSlackErrorNotification(`[getInviteCode]`, "getInviteCode", error);
        log.debug(`[getInviteCode] Error Message: ${error}`);
        log.debug(`[getInviteCode] Full Error Obj: `, error);
        throw error;
    });
    log.debug("[getInviteCode] result: ", result);
    await storageService.setObject(inviteCodeKey, result);
    return result;
}