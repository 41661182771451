import { IonAvatar, IonCol, IonRow } from '@ionic/react'
import React, { useContext, useEffect, useState } from 'react'
import { DuelStatus } from '../../../models/duelsModels'
import GlobalState from '../../../contexts/GlobalState';
import log from 'loglevel';
import { StatDescriptions, calcDifficultyColor, calculateRelativeStats } from '../../../services/DuelsServics';
import { UserAvatar } from '../../../components/UserAvatar';
import { DuelHelperBubbleOnly } from './DuelHelperBubbleOnly';
import CountdownTimer from './CountdownTimer';
import CountdownTimerv2 from './CountdownTimerv2';

const DuelStatusInfo = (props: { duelStatus: DuelStatus, heroOdds?: string, setShowModal?: React.Dispatch<React.SetStateAction<any>>, previousVillainHealth?: number, currentVillainHealth?: number }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject } = globalState;
    const [statDescriptions, setStatDescriptions] = useState<StatDescriptions>({ villainDamageDescription: "average", villainDodgeDescription: "average", villainToHitDescription: "average" });
    const [displayHealth, setDisplayHealth] = useState<number>(props.duelStatus.villainHealth);

    useEffect(() => {
        setStatDescriptions(calculateRelativeStats(props.duelStatus))
    }, [props.duelStatus]);

    useEffect(() => {
        if (props.currentVillainHealth && props.previousVillainHealth) {
            // Assuming the initial value is higher and it decreases to `props.duelStatus.heroHealth`
            let startHealth = props.previousVillainHealth; // Start from the current displayed health
            const endHealth = props.currentVillainHealth;

            const interval = setInterval(() => {
                if (startHealth > endHealth) {
                    startHealth--;
                    setDisplayHealth(startHealth);
                } else {
                    clearInterval(interval);
                }
            }, 1150); // Adjust the interval time as needed

            return () => clearInterval(interval);
        } else {
            setDisplayHealth(props.duelStatus.villainHealth);
        }
    }, []);


    function showHeroOdds() {
        let oddsColor = "white"
        if (props.heroOdds) {
            switch (props.heroOdds) {
                case "very low":
                    oddsColor = "red"
                    break;
                case "low":
                    oddsColor = "orange"
                    break;
                case "even":
                    oddsColor = "lightblue"
                    break;
                case "high":
                    oddsColor = "lightgreen"
                    break;
                case "very high":
                    oddsColor = "green"
                    break;
                default:
                    oddsColor = "white"
                    break;
            }
            return (
                <div className='hero-odds-div' style={{ borderColor: oddsColor }}>Hero odds<br /><span style={{ color: oddsColor }}>{props.heroOdds}</span></div>
            )
        }
    }

    function showRelativeStat(statDescription: string) {
        switch (statDescription) {
            case "low":
                return (
                    <span style={{ color: "green", display: "contents" }}>{statDescription}</span>
                )
            case "high":
                return (
                    <span style={{ color: "red", display: "contents" }}>{statDescription}</span>
                )
            case "very high":
                return (
                    <span style={{ color: "orange", display: "contents" }}>{statDescription}</span>
                )
            default:
                return (
                    <span style={{ color: "var(--ion-color-primary)", display: "contents" }}>{statDescription}</span>
                )
        }
    }

    return (
        <>
            <IonRow>
                <IonCol size="5" style={{ textAlign: "center" }}>
                    <IonAvatar className="member-image" style={{ margin: "auto" }}>
                        <UserAvatar avatarImage={heroObject?.heroImageURL} />
                    </IonAvatar>
                    <div >
                        {heroObject?.heroName}
                    </div>
                    <IonRow className="explanation-hint-stats">
                        <IonCol size="3" className="explanation-hint-health duel-status-info-stats-column">
                            ❤️<br />
                            {Math.round(props.duelStatus.heroHealth)}
                        </IonCol>
                        <IonCol size="3" className="explanation-hint-accuracy">
                            🎯
                            <br />
                            {Math.round(props.duelStatus.heroToHit)}
                        </IonCol>
                        <IonCol size="3" className="explanation-hint-damage duel-status-info-stats-column">
                            💥<br />
                            {Math.round(props.duelStatus.heroDamage)}
                        </IonCol>
                        <IonCol size="3" className="explanation-hint-dodge duel-status-info-stats-column">
                            💨<br />
                            {Math.round(props.duelStatus.heroDodge)}
                        </IonCol>
                    </IonRow>
                </IonCol>

                <IonCol size="2" className="vs">
                    {/* VS. */}

                    {props.heroOdds && props.duelStatus.complete !== true && (
                        <>{showHeroOdds()}</>
                    )}
                </IonCol>

                <IonCol size="5" style={{ textAlign: "center" }}>
                    {props.setShowModal ? (
                        <IonAvatar className="member-image" style={{ margin: "auto", border: `3px solid ${calcDifficultyColor(props.duelStatus.difficulty!)}` }} onClick={() => props.setShowModal!({ show: true, duelStatus: props.duelStatus })}>
                            <img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.duelStatus.villainImageURL ?? "/assets/images/mystery-avatar.png"} alt={props.duelStatus.villainName} />
                        </IonAvatar>
                    ) : (<IonAvatar className="member-image" style={{ margin: "auto" }}>
                        <img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.duelStatus.villainImageURL ?? "/assets/images/mystery-avatar.png"} alt={props.duelStatus.villainName} />
                    </IonAvatar>)}
                    <div >
                        {props.duelStatus.villainName}
                    </div>
                    <IonRow>
                        <IonCol size="3" className="duel-status-info-stats-column explanation-hint-villain-health">
                            <div className="health-animation">
                                <div className="heart-animation">❤️</div>
                                <div>{Math.round(displayHealth)}</div>
                            </div>
                        </IonCol>
                        <IonCol size="3" className="duel-status-info-stats-column explanation-hint-accuracy">
                            <div>
                                <div>🎯</div>
                                <div>{showRelativeStat(statDescriptions.villainToHitDescription)}</div>
                            </div>

                        </IonCol>

                        <IonCol size="3" className="duel-status-info-stats-column">
                            <div>
                                <div>💥</div>
                                <div>{showRelativeStat(statDescriptions.villainDamageDescription)}</div>
                            </div>

                        </IonCol>
                        <IonCol size="3" className="duel-status-info-stats-column">
                            <div>💨</div>
                            <div>{showRelativeStat(statDescriptions.villainDodgeDescription)}</div>
                        </IonCol>
                    </IonRow>
                </IonCol>
            </IonRow>
        </>
    )
}

export default DuelStatusInfo