/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { IonAvatar, IonButton, IonContent, IonFooter, IonInput, IonLoading, IonPage, IonRow } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import HeaderLogo from "../../components/HeaderLogo";
import GlobalState from "../../contexts/GlobalState";
import { HeroObject, OnboardingObject } from "../../models/userModel";
import { StorageService } from "../../services/StorageService";
import { createHero, updateUser } from "../../services/UserService";
import { setProperty } from "../../services/AnalyticsService";
import log from "loglevel";
import { capitalizeFirstLetter } from "../../services/HelperService";
import { Keyboard } from "@capacitor/keyboard";
import { Device } from "@capacitor/device";

const NameUser = () => {
	const nextPage = "/onboarding/connecttracker";
	const history = useHistory();
	const storageService = new StorageService();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { isMobile } = globalState;
	const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
	const [firstName, setFirstName] = useState<string>();
	const [savingHero, setSavingHero] = useState<boolean>(false);
	const [showFooter, setShowFooter] = useState<boolean>(true);


	const firstNameRef = useRef<any>();

	useEffect(() => {
		log.debug("NameUser useEffect");
		updateOnboardingObject();
	}, []);

	const [firstNameError, setFirstNameError] = useState({
		visible: false,
		message: "",
	});

	async function updateOnboardingObject() {
		let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding"); // Should this just include the full heroObject?
		tempOnboardingObject.url = window.location.pathname;
		tempOnboardingObject.completed = false;
		log.debug("tempOnboardingObject", tempOnboardingObject);
		setOnboardingObject(tempOnboardingObject);
		await storageService.setObject("onboarding", tempOnboardingObject);
	}

	const validateFirstName = (firstName: string) => {
		if (!firstName || firstName.length < 2) {
			setFirstNameError({
				visible: true,
				message: "Your name should be at least 2 characters",
			});
			return false;
		}
		if (firstName.length > 16) {
			setFirstNameError({
				visible: true,
				message: "Please keep your name under 16 characters",
			});
			return false;
		}
		if (/[\.,\/#!$%\^&\*;:{}=\-_`~()]/g.test(firstName)) {
			setFirstNameError({
				visible: true,
				message: "No punctuation please",
			});
			return false;
		}
		setFirstNameError({
			visible: false,
			message: "",
		});
		return true;
	};

	async function saveHero() {
		let validName = validateFirstName(firstNameRef?.current?.value);
		if (validName === true) {
			let firstname = capitalizeFirstLetter(firstNameRef?.current?.value);
			setFirstName(firstname);
			setSavingHero(true);
			let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
			tempOnboardingObject.firstName = firstname
			await storageService.setObject("onboarding", tempOnboardingObject);
			await updateUser({ firstName: firstname });
			// await updateUserState();
			setSavingHero(false);
			history.push(nextPage);
		}
	}

	if (isMobile !== false) {
		// get user's platform
		Device.getInfo().then((device) => {
			if (device.platform !== "android") {
				Keyboard.addListener("keyboardWillShow", (info) => {
					setShowFooter(false);
					document.body.classList.add("keyboard-is-open");
					document.documentElement.style.setProperty("--keyboard-height", -info.keyboardHeight + "px");
				}).catch((err) => {
					// log.debug("Keyboard Error: ", err);
				});
				Keyboard.addListener("keyboardDidShow", (info) => {
					document?.activeElement?.scrollIntoView({ behavior: "smooth" });
					document.documentElement.style.setProperty("--keyboard-height", -info.keyboardHeight + "px");
				}).catch((err) => {
					// log.debug("Keyboard Error: ", err);
				});

				Keyboard.addListener("keyboardDidHide", () => {
					setShowFooter(true);
				}).catch((err) => { });
			} else {
				console.log("[Keyboard test] Android device");
			}
		});
	}
	return (
		<IonPage>
			<HeaderLogo checkforUser={false} />
			<IonContent>
				<IonLoading isOpen={savingHero} message={"Saving first name..."} backdropDismiss />
				<div className="onboarding-main-div">
					<div className="villain-chat-container">
						<IonRow style={{ justifyContent: "center" }}>
							<div className="chat-bubble-onboarding">
								<b>{onboardingObject?.hero?.heroName}</b> huh? I like it!<br /><br />What should I call you?
							</div>
						</IonRow>
					</div>
				</div>
				<IonAvatar style={{ margin: "auto", height: "250px", width: "250px" }}>
					<img
						className="user-avatar"
						style={{ width: "100%", height: "100%", objectFit: "cover" }}
						src={onboardingObject?.heroPicture ?? "/assets/images/mystery-avatar.png"}
						alt="your fithero avatar"
					/>
				</IonAvatar>

				<div className="onboarding-firstname-box-div">
					<div>
						My first name is...
					</div>
					{firstName ? (
						<IonInput
							className="onboarding-username-box"
							ref={firstNameRef}
							name="firstName"
							value={firstName}
							type={"text"}
							enterkeyhint="next"
							placeholder="Your hero's name"
							onFocus={() => setFirstNameError({ visible: false, message: "" })}
							required
						></IonInput>
					) : (
						<IonInput
							className="onboarding-username-box"
							ref={firstNameRef}
							name="firstName"
							type={"text"}
							enterkeyhint="next"
							onFocus={() => setFirstNameError({ visible: false, message: "" })}
							required
						></IonInput>
					)}
					{firstNameError.visible === true ? <div className="onboarding-error-message">{firstNameError.message}</div> : ""}
				</div>
			</IonContent>
			{showFooter === true && (
				<IonFooter className="onboarding-footer">
					<IonButton className="onboarding-advance-button" onClick={() => saveHero()}>
						Continue
					</IonButton>
				</IonFooter>
			)}
		</IonPage>
	);
};

export default NameUser;
