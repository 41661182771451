export const REVENUECAT_IOS_KEY = "appl_TDOvtjsuvsTgZMdbMpukWNhVKMw";
export const REVENUECAT_ANDROID_KEY = "goog_fWrSZbFNNijSrAfggnfKyDlUqRy";
export const REVENUECAT_STRIPE_KEY = "strp_MOztsBfhaslRBdAIsBpAUnHBAVS";

export const featuredPricePoint = "com.fitheroai.subscription.1year";
export const annualPricePoints = ["FH_2999_12mo_7day", "price_1OqsN2Cw2t0BcnjLXZjgEc4L", "fithero_powerup_v1:annual-autorenewing"];
export const monthlyPricePoints = ["FH_499_1mo_7day", "prod_PfM14upVELbkq4", "fithero_powerup_v1:monthly-autorenewing-trial"];
export const lifetimePricePoints = ["FH_3999_lifetime", "fithero_powerup_v1_lifetime", "price_1OxcJMCw2t0BcnjL4xdqglmY"];

export type PaywallFeature = {
  icon: string;
  text: string;
}

export type SubscriptionDates = {
  expires_date: string | null;
  renewal_date: string | null;
  purchase_date: string | null;
}

export type WebCustomerInfo = {
  request_date: string;
  request_date_ms: number;
  subscriber?: {
    entitlements?: {
      powerUp?: {
        expires_date: string | null;
        grace_period_expires_date: string | null;
        product_identifier: string | null;
        product_plan_identifier: string | null;
        purchase_date: string | null;
      }
    };
    first_seen: string;
    last_seen: string;
    management_url: string | null;
    non_subscriptions: {};
    original_app_user_id: string;
    original_application_version: string | null;
    original_purchase_date: string | null;
    other_purchases?: {};
    subscriptions?: {};
  }
}


export type Product = {
  identifier: string;
  description: string;
  title: string;
  price: number;
  priceString: string;
  currencyCode: string;
  introPrice: any;
  discounts: any;
  productCategory: any;
  productType: any;
  subscriptionPeriod: string | null;
  defaultOption: any;
  subscriptionOptions: any;
  presentedOfferingIdentifier: any;
}

export type RevenueCatItem = {
  object: string;
  id: string;
  store_identifier: string;
  type: string;
  subscription: {
    duration: string;
    grace_period_duration: string;
    trial_duration: string;
  };
  created_at: number;
  app_id: string;
  app: {
    object: string;
    id: string;
    name: string;
    created_at: number;
    type: string;
    project_id: string;
    amazon: {
      package_name: string;
    };
    app_store: {
      bundle_id: string;
    };
    mac_app_store: {
      bundle_id: string;
    };
    play_store: {
      package_name: string;
    };
  };
  display_name: string;
}

export type ProductList = {
  object: string;
  items: RevenueCatItem[];
  next_page: string;
  url: string;
}

export type IosProductList = {
  "discounts": any[];
  "price": number;
  "priceString": string;
  "currencyCode": string;
  "description": string;
  "productCategory": string;
  "introPrice": {
    "period": string,
    "periodUnit": string,
    "price": number,
    "priceString": string,
    "periodNumberOfUnits": number,
    "cycles": number
  } | null;
  "title": string;
  "subscriptionPeriod": string | null;
  "identifier": string;
  "productType": string;
}

export const sampleProducts: Product[] = [
  {
    identifier: "com.fitheroai.subscription.1month",
    description: "Unlock the full FitHero.ai Experience!",
    title: "1 Month",
    price: 4.99,
    priceString: "$4.99",
    currencyCode: "USD",
    introPrice: null,
    discounts: null,
    productCategory: "subscription",
    productType: "subscription",
    subscriptionPeriod: "P1M",
    defaultOption: null,
    subscriptionOptions: null,
    presentedOfferingIdentifier: null
  },
  {
    identifier: "com.fitheroai.subscription.1year",
    description: "Unlock the full FitHero.ai Experience!",
    title: "12 Months",
    price: 29.99,
    priceString: "$29.99",
    currencyCode: "USD",
    introPrice: null,
    discounts: null,
    productCategory: "subscription",
    productType: "subscription",
    subscriptionPeriod: "P1Y",
    defaultOption: null,
    subscriptionOptions: null,
    presentedOfferingIdentifier: null
  }
]

export const iosProducts: IosProductList[] = [
  {
    "discounts": [

    ],
    "price": 4.99,
    "priceString": "$4.99",
    "currencyCode": "USD",
    "description": "1 month PowerUp Membership",
    "productCategory": "SUBSCRIPTION",
    "introPrice": null,
    "title": "1 month",
    "subscriptionPeriod": "P1M",
    "identifier": "FH_499_1mo_7day",
    "productType": "NON_CONSUMABLE"
  },
  {
    "currencyCode": "USD",
    "description": "",
    "discounts": [

    ],
    "subscriptionPeriod": null,
    "title": "Download",
    "productCategory": "NON_SUBSCRIPTION",
    "productType": "NON_CONSUMABLE",
    "introPrice": null,
    "identifier": "FH_3999_lifetime",
    "priceString": "$39.99",
    "price": 39.99
  },
  {
    "priceString": "$29.99",
    "title": "12 months",
    "description": "12 month PowerUp Membership",
    "introPrice": {
      "period": "P1W",
      "periodUnit": "WEEK",
      "price": 0,
      "priceString": "$0.00",
      "periodNumberOfUnits": 1,
      "cycles": 1
    },
    "discounts": [

    ],
    "productType": "NON_CONSUMABLE",
    "currencyCode": "USD",
    "price": 29.99,
    "identifier": "FH_2999_12mo_7day",
    "productCategory": "SUBSCRIPTION",
    "subscriptionPeriod": "P1Y"
  }
]

export const sampleFeaturesv1: PaywallFeature[] = [
  { icon: "💨", text: "Level up your hero faster" },
  { icon: "😈", text: "Access to bosses" },
  { icon: "🔎", text: "See villian strengths & weaknesses" },
  { icon: "❤", text: "Improved health from activity" },
  { icon: "💫", text: "Special hero customizations" },
  // "Exclusive Challenges & Events",
  // "Something to improve workout effectiveness",
  // "Access to insane mode",
]

export const sampleFeatures: PaywallFeature[] = [
  { icon: "🎮", text: "Transform workouts into a thrilling game against quirky AI villains!" },
  { icon: "📈", text: "Visualize your achievements and growth!" },
  { icon: "🔄", text: "Get credit for ALL your healthy activity, not just workouts!" },
  { icon: "📘", text: "Gain insights to enhance your fitness knowledge!" },
  { icon: "🤝", text: "Connect and compete with fellow FitHero's!" },
  // "Exclusive Challenges & Events",
  // "Something to improve workout effectiveness",
  // "Access to insane mode",
]

export const sampleInfoWithLifetime = {
  "customerInfo": {
    "allPurchasedProductIdentifiers": [
      "FH_3999_lifetime",
      "fithero_powerup_v1:monthly-autorenewing-trial",
      "FH_2999_12mo_7day",
      "FH_499_1mo_7day"
    ],
    "allPurchaseDates": {
      "FH_2999_12mo_7day": "2024-03-09T23:11:51Z",
      "FH_499_1mo_7day": "2024-03-10T01:31:25Z",
      "fithero_powerup_v1:monthly-autorenewing-trial": "2024-03-17T21:13:50Z",
      "FH_3999_lifetime": "2024-03-21T23:21:11Z"
    },
    "activeSubscriptions": [

    ],
    "originalPurchaseDate": "2024-03-21T23:21:11Z",
    "firstSeenMillis": 1710025593000,
    "allExpirationDates": {
      "FH_3999_lifetime": null,
      "FH_499_1mo_7day": "2024-03-10T01:36:25Z",
      "fithero_powerup_v1:monthly-autorenewing-trial": "2024-03-17T21:18:47Z",
      "FH_2999_12mo_7day": "2024-03-10T00:11:51Z"
    },
    "managementURL": null,
    "firstSeen": "2024-03-09T23:06:33Z",
    "originalPurchaseDateMillis": 1711063271000,
    "requestDate": "2024-03-21T23:21:15Z",
    "nonSubscriptionTransactions": [
      {
        "productId": "FH_3999_lifetime",
        "purchaseDateMillis": 1711063271000,
        "revenueCatId": "7363a74228",
        "productIdentifier": "FH_3999_lifetime",
        "transactionIdentifier": "7363a74228",
        "purchaseDate": "2024-03-21T23:21:11Z"
      }
    ],
    "requestDateMillis": 1711063275417,
    "allPurchaseDatesMillis": {
      "FH_2999_12mo_7day": 1710025911000,
      "fithero_powerup_v1:monthly-autorenewing-trial": 1710710030000,
      "FH_3999_lifetime": 1711063271000,
      "FH_499_1mo_7day": 1710034285000
    },
    "latestExpirationDate": "2024-03-17T21:18:47Z",
    "originalAppUserId": "e2361578-a23a-460a-8a0e-6d170ef6864b",
    "latestExpirationDateMillis": 1710710327000,
    "originalApplicationVersion": "1.0",
    "entitlements": {
      "all": {
        "powerUp": {
          "productIdentifier": "FH_3999_lifetime",
          "latestPurchaseDateMillis": 1711063271000,
          "latestPurchaseDate": "2024-03-21T23:21:11Z",
          "ownershipType": "PURCHASED",
          "periodType": "NORMAL",
          "expirationDateMillis": null,
          "billingIssueDetectedAtMillis": null,
          "unsubscribeDetectedAt": null,
          "productPlanIdentifier": null,
          "unsubscribeDetectedAtMillis": null,
          "isSandbox": true,
          "verification": "NOT_REQUESTED",
          "identifier": "powerUp",
          "expirationDate": null,
          "willRenew": false,
          "isActive": true,
          "originalPurchaseDateMillis": 1711063271000,
          "store": "APP_STORE",
          "billingIssueDetectedAt": null,
          "originalPurchaseDate": "2024-03-21T23:21:11Z"
        }
      },
      "active": {
        "powerUp": {
          "willRenew": false,
          "unsubscribeDetectedAtMillis": null,
          "ownershipType": "PURCHASED",
          "originalPurchaseDate": "2024-03-21T23:21:11Z",
          "store": "APP_STORE",
          "unsubscribeDetectedAt": null,
          "isActive": true,
          "expirationDate": null,
          "identifier": "powerUp",
          "originalPurchaseDateMillis": 1711063271000,
          "billingIssueDetectedAt": null,
          "billingIssueDetectedAtMillis": null,
          "periodType": "NORMAL",
          "productIdentifier": "FH_3999_lifetime",
          "isSandbox": true,
          "latestPurchaseDate": "2024-03-21T23:21:11Z",
          "productPlanIdentifier": null,
          "verification": "NOT_REQUESTED",
          "expirationDateMillis": null,
          "latestPurchaseDateMillis": 1711063271000
        }
      },
      "verification": "NOT_REQUESTED"
    },
    "allExpirationDatesMillis": {
      "fithero_powerup_v1:monthly-autorenewing-trial": 1710710327000,
      "FH_3999_lifetime": null,
      "FH_2999_12mo_7day": 1710029511000,
      "FH_499_1mo_7day": 1710034585000
    }
  },
  "transaction": {
    "purchaseDate": "2024-03-21T23:21:11Z",
    "transactionIdentifier": "2000000552354045",
    "revenueCatId": "2000000552354045",
    "productIdentifier": "FH_3999_lifetime",
    "purchaseDateMillis": 1711063271000,
    "productId": "FH_3999_lifetime"
  },
  "productIdentifier": "FH_3999_lifetime"
}
