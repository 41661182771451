import { IonButton, IonInput, IonSpinner } from '@ionic/react'
import log from 'loglevel';
import React, { useContext, useRef, useState } from 'react'
import { checkPromoCode } from '../../services/PaywallService';
import GlobalState from '../../contexts/GlobalState';

export const PromoCodeComponent = (props: { setShowPromoCode: React.Dispatch<React.SetStateAction<any>>, triggerPurchase: any }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { setupRevenueCat } = globalState;

    const codeRef = useRef<HTMLIonInputElement | null>(null);
    const [processing, setProcessing] = useState(false);
    const [codeError, setCodeError] = useState({
        visible: false,
        message: "",
    });

    function handleKeyUp(event: { keyCode: number }) {
        if (event.keyCode === 13) {
            log.debug("enter hit");
            validateCode();
        }
    }

    async function validateCode() {
        setProcessing(true);
        let validData = true;
        if (!codeRef.current?.value) {
            setCodeError({
                visible: true,
                message: "Please enter a promo code",
            })
            setProcessing(false);
            return false;
        }
        await checkPromoCode(codeRef.current?.value!.toString()).catch((error) => {
            log.error("Error checking promo code: ", error);
            setCodeError({
                visible: true,
                message: error.message,
            });
            setProcessing(false);
            return false;
        }).then(async (validCode) => {
            if (validCode === true) {
                setCodeError({
                    visible: false,
                    message: "",
                });
                await setupRevenueCat(); // do this so it pulls in the users' info into the GlobalState
                setProcessing(false);
                props.triggerPurchase(true);
            }
        });
    }
    return (
        <>
            <div className="villain-difficulty-modal-div">
                <div>
                    <IonInput
                        style={{ border: "1px solid", margin: "10px 0px 10px 0px" }}
                        ref={codeRef}
                        onKeyUp={handleKeyUp}
                        name="promoCode"
                        type={"text"}
                        placeholder="Enter Promo Code"
                        enterkeyhint="enter"
                        required
                    ></IonInput>
                    {codeError.visible === true ? <p className="error-message">{codeError.message}</p> : ""}
                </div>
                <div style={{ textAlign: "center", width: "70%", margin: "auto" }}>
                    {processing === true ? <IonSpinner name="crescent" /> :
                        <IonButton color={"success"} onClick={() => validateCode()} style={{ width: "100%" }}>Submit</IonButton>
                    }
                </div>

            </div>
        </>
    )
}
