import React, { useState } from 'react'
import { IonButton, IonContent, IonPage, IonSpinner } from '@ionic/react';
import axios from 'axios';
import log from 'loglevel';

const TestLeap = () => {
    const RealisticVisionv4 = '37d42ae9-5f5f-4399-b60b-014d35e762a5'
    const OpenJourneyv4 = '	1e7737d7-545e-469f-857f-e4b46eaa151d'
    const negativePrompt = '(deformed iris, deformed pupils, semi-realistic, cgi, 3d, render, sketch, cartoon, drawing, anime:1.4), text, close up, cropped, out of frame, worst quality, low quality, jpeg artifacts, ugly, duplicate, morbid, mutilated, extra fingers, mutated hands, poorly drawn hands, poorly drawn face, mutation, deformed, blurry, dehydrated, bad anatomy, bad proportions, extra limbs, cloned face, disfigured, gross proportions, malformed limbs, missing arms, missing legs, extra arms, extra legs, fused fingers, too many fingers, long neck'
    const prompt = "close up of face of beautiful woman fashion model in white feather clothes, official balmain editorial, dramatic lighting highly detailed, with new york city in the background";
    const API_KEY = "245f4915-0cc1-45ac-9204-dadcec06567e";
    const HEADERS = {
        "accept": "application/json",
        "content-type": "application/json",
        "authorization": `Bearer ${API_KEY}`,

    };
    const [images, setImages] = useState<string[]>([]);
    const [generating, setGenerating] = useState<boolean>(false);
    const [formValues, setFormValues] = useState({
        name: "",
        eyeColor: '',
        trait1: '',
        race: '',
        trait2: '',
        trait3: '',
        trait4: '',
        hairstyle: '',
        hairColor: '',
        age: '',
        gender: '',
        build: '',
        accessories: '',
        background: '',
        expression: '',
        attire: ''
    });

    const generateImage = async (modelId: string, prompt: string) => {
        const url = `https://api.tryleap.ai/api/v1/images/models/${RealisticVisionv4}/inferences`;
        const payload = {
            "prompt": prompt,
            "steps": 50,
            "width": 512,
            "height": 512,
            "numberOfImages": 4,
            "promptStrength": 7,
            "enhancePrompt": false,
            "restoreFaces": true,
            "upscaleBy": "x1",
            "negativePrompt": negativePrompt,
        };
        const response = await axios.post(url, payload, { headers: HEADERS });
        const data = response.data;
        log.debug("[generateImage]: ", data);
        setGenerating(true);
        return { inferenceId: data["id"], status: data["status"] };
    };

    const getInferenceJob = async (modelId: string, inferenceId: string) => {
        const url = `https://api.tryleap.ai/api/v1/images/models/${modelId}/inferences/${inferenceId}`;
        const response = await axios.get(url, { headers: HEADERS });
        const data = response.data;
        log.debug("[getInferenceJob]: ", data);
        return { inferenceId: data["id"], state: data["state"], images: data["images"]?.length ? data["images"] : null };
    };

    const runImageGeneration = async (prompt: string) => {
        let { inferenceId, status } = await generateImage(OpenJourneyv4, prompt);
        while (status !== "finished") {
            await new Promise(resolve => setTimeout(resolve, 3000)); // Sleep for 10 seconds
            const result = await getInferenceJob(OpenJourneyv4, inferenceId);
            inferenceId = result.inferenceId;
            status = result.state;
            if (result.images) {
                setGenerating(false);
                setImages(prevImages => [...prevImages, ...result.images.map((image: { uri: any; }) => image.uri)]);
            }
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    };

    const generatePrompt = () => {
        let prompt = `${formValues.gender}, around ${formValues.age} years old, with ${formValues.hairColor} ${formValues.hairstyle} hair, an ${formValues.build}, wearing ${formValues.attire} and a ${formValues.expression} on their face. Place them in a ${formValues.background} background and ${formValues.accessories} should be present. portrait fantasy, cosmic horror, dramatic lighting 8k`
        prompt = `Craft a dynamic and powerful profile image of the superhero ${formValues.name}, inspired by the iconic styles of Marvel and DC comics. 
        As an ${formValues.race} ${formValues.gender} hero approximately ${formValues.age} years old, ${formValues.name} exudes ${formValues.trait1} and ${formValues.trait2}. 
        His ${formValues.build} build should be emphasized, complemented by his ${formValues.hairColor} ${formValues.hairstyle} and ${formValues.eyeColor} eyes, which radiate ${formValues.trait3} and ${formValues.trait4}. 
        He should wear a suit that is both practical and awe-inspiring, signaling his readiness for action. 
        Utilize a Nikon D850 with a Nikkor 70-200mm f/2.8 lens to capture ${formValues.name}'s image in exceptional detail. 
        Use an aperture of f/4, an ISO of 400, and a shutter speed of 1/500 sec to focus on ${formValues.name} and create a stunning depth of field effect, separating them from the backdrop and highlighting his imposing figure. 
        The lighting should be dramatic, highlighting ${formValues.name}'s physique and lending an air of heroic stature. 
        A bold and dynamic background, perhaps a city skyline or a dramatic sky, should act as a backdrop, signaling his commitment to protect and serve. The colors should be vibrant and bold, embodying the spirit of comic book art, and highlighting ${formValues.name}'s heroic nature.`
        runImageGeneration(prompt);
    };

    return (
        <IonPage>
            <IonContent>
                <h1>Test Leap</h1>
                <form style={{ color: "black" }} onSubmit={(event) => {
                    event.preventDefault();
                    generatePrompt()
                }}>
                    <input type="text" name="name" placeholder="Name" onChange={handleInputChange} />
                    <input type="text" name="hairstyle" placeholder="Hair style" onChange={handleInputChange} />
                    <input type="text" name="race" placeholder="Race" onChange={handleInputChange} />
                    <input type="text" name="hairColor" placeholder="Hair color" onChange={handleInputChange} />
                    <input type="text" name="eyeColor" placeholder="Eye color" onChange={handleInputChange} />
                    <input type="text" name="age" placeholder="Age" onChange={handleInputChange} />
                    <input type="text" name="gender" placeholder="Gender" onChange={handleInputChange} />
                    <input type="text" name="build" placeholder="Build" onChange={handleInputChange} />
                    <input type="text" name="trait1" placeholder="Trait (i.e. courage)" onChange={handleInputChange} />
                    <input type="text" name="trait2" placeholder="Trait (i.e. determination)" onChange={handleInputChange} />
                    <input type="text" name="trait3" placeholder="Trait (i.e. strength)" onChange={handleInputChange} />
                    <input type="text" name="trait4" placeholder="Trait (i.e. resilience)" onChange={handleInputChange} />
                    <input type="text" name="background" placeholder="Background" onChange={handleInputChange} />
                    <input type="text" name="expression" placeholder="Facial expression" onChange={handleInputChange} />
                    <input type="text" name="attire" placeholder="Attire" onChange={handleInputChange} />
                    <IonButton type='submit'>Generate Image</IonButton>
                </form>
                {generating && (
                    <>
                        <div>Generating image...</div>
                        <IonSpinner />
                    </>
                )}
                <div>
                    {images.map((imageUri, index) => (
                        <img key={index} src={imageUri} alt={`Generated image ${index}`} />
                    ))}
                </div>
            </IonContent>
        </IonPage>
    )
}

export default TestLeap