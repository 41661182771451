import { App } from "@capacitor/app";
import { IonApp, IonHeader, IonPage, IonSpinner, IonTitle, IonToolbar, setupIonicReact } from "@ionic/react";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Tailwind styles */
import './theme/tailwind.css';

/* TailwindCSS */
import './styles/tailwind.css';

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";

/* Theme variables */
import { IonReactRouter } from "@ionic/react-router";
import { Deploy } from "cordova-plugin-ionic";
import { CheckForUpdateResponse } from "cordova-plugin-ionic/dist/IonicCordova";
import OneSignal from "onesignal-cordova-plugin";
import React, { useContext, useEffect, useState } from "react";
import GlobalState from "./contexts/GlobalState";
import "./css/App.css";
import MainRouting from "./routes/MainRouting";
import { initAnalytics } from "./services/AnalyticsService";
import { getPlatform, isOnboardingCompleted } from "./services/HelperService";
import { sendSlackNotification } from "./services/NotificationService";
import "./theme/variables.css";
import log from "loglevel";
import { smartLog } from "./services/LoggingService";
import { SplashScreen } from '@capacitor/splash-screen';
import { StorageService } from "./services/StorageService";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getUser } from "./services/UserService";
const Parse = require("parse");
const storageService = new StorageService();

SplashScreen.hide().then(() => { console.log("SplashScreen hidden"); });

function initFirebase() {
	const firebaseConfig = {
		apiKey: "AIzaSyDQCeHKehgMjFdohD4bVB96dsEZxAsEkuY",
		authDomain: "fithero-396622.firebaseapp.com",
		projectId: "fithero-396622",
		storageBucket: "fithero-396622.appspot.com",
		messagingSenderId: "893824261218",
		appId: "1:893824261218:web:26906c37bf0ef70fb7853b",
		measurementId: "G-R3D18F2Q3D"
	};
	const app = initializeApp(firebaseConfig);
	const analytics = getAnalytics(app);
}

// Your Parse initialization configuration goes here
async function InitParse() {
	let backend = await storageService.getItem("backend");
	if (backend === "") backend = "Production";
	console.log("Backend: ", backend);
	let PARSE_APPLICATION_ID = "2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91";
	let PARSE_JAVASCRIPT_KEY = "rUzOG70xd5xQKGsTWt3OIGWG4pTrPqeR2NIzGFqf";
	const PARSE_APPLICATION_ID_DEV = "l77tUEqChQOgPeybWFn66GBiQ2uRmeZtxJ0HDR0Q";
	const PARSE_JAVASCRIPT_KEY_DEV = "aa9Ezt7U33oyLvEWqV4W84KJWLDDHrhQJGsWmiLt";
	if (backend === "Development") {
		PARSE_APPLICATION_ID = PARSE_APPLICATION_ID_DEV;
		PARSE_JAVASCRIPT_KEY = PARSE_JAVASCRIPT_KEY_DEV;
	}
	const PARSE_HOST_URL = "https://parseapi.back4app.com/";
	Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
	log.debug("Parse initialized");
	Parse.serverURL = PARSE_HOST_URL;
}

// Call this function when your app starts
function OneSignalInit() {
	try {
		OneSignal.setAppId("3ee016f7-7113-4541-8911-3a7476203554");
	} catch (error) {
		log.debug("Error init OneSignal");
	}
	try {
		OneSignal.setNotificationOpenedHandler(function (jsonData) {
			log.debug("notificationOpenedCallback: " + JSON.stringify(jsonData));
		});
		OneSignal.promptForPushNotificationsWithUserResponse(async function (accepted) {
			log.debug("User accepted notifications: " + accepted);
			let user = await getUser();
			if (!user) {
				sendSlackNotification(`New user accepted notifications: ${accepted}`, "keyEvent", true);
			}
		});
	} catch (error) {
		log.debug("Error init notification prompt");
	}
}
// Uncomment to set OneSignal device logging to VERBOSE
// OneSignal.setLogLevel(6, 0);
// NOTE: Update the setAppId value below with your OneSignal AppId.

setupIonicReact({
	innerHTMLTemplatesEnabled: true,
	scrollAssist: false,
	swipeBackEnabled: false,
	hardwareBackButton: false,
});
InitParse();
OneSignalInit();
initAnalytics();
initFirebase()
// SmartlookInit();
// App.addListener("appUrlOpen", (data) => {
// 	sendSlackNotification(`App opened with URL: ${JSON.stringify(data)}`, "notificationtesting", true);
// 	log.debug("[App] App opened with URL:", data);
// });

App.addListener("appRestoredResult", (data) => {
	sendSlackNotification(`App restored: ${JSON.stringify(data)}`, "notificationtesting", true);
	log.debug("[App] Restored state:", data);
});

const FitHeroApp = () => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { serialStartupFunction, isMobile, userState, loadingStage } = globalState;

	let debugMode: any;
	// debugMode = true; // forces it to stay on the login screen. This was smart! :)
	const [showLoading, setShowLoading] = useState(true);
	const [loadingComplete, setLoadingComplete] = useState(false);
	const [updating, setUpdating] = useState(false);
	const [percentDone, setPercentDone] = useState(0);
	const [refreshOnLoad, setRefeshOnLoad] = useState<boolean>(false);

	log.setDefaultLevel(log.levels.ERROR);
	if (window.location.hostname === 'localhost' && window.location.port >= '8100') {
		// Logic for when the site is accessed locally
		// log.debug('Running on local server');
		// refreshOnLoad = false;
		log.setLevel(log.levels.TRACE, true);
	}

	useEffect(() => {
		startUp();
	}, []);

	useEffect(() => { }, [isMobile]);

	useEffect(() => {
		log.debug("Adding listener");
		App.addListener("appStateChange", async ({ isActive }) => {
			let onboardingCompleted = await isOnboardingCompleted();
			smartLog("[App] App state changed. onboardingCompleted?", onboardingCompleted);
			if (isActive === true && (onboardingCompleted === true || onboardingCompleted === null)) {
				smartLog("[App] Getting Messages & Data from appStateChange");
				// await serialStartupFunction();
				if (refreshOnLoad === true) {
					await startUp();
				} else {
					serialStartupFunction();
				}
			}
		});
	}, []);

	async function startUp() {
		// await SplashScreen.hide().then(() => { console.log("SplashScreen hidden"); });
		log.debug("[App] Checking for update");
		setShowLoading(true);
		await checkForUpdate();
		await startUpWithLoading();
		setLoadingComplete(true);
	}

	async function startUpWithLoading() {
		log.debug("********** Starting startUp function **********");
		// console.time("********** Running startUp function **********");
		await serialStartupFunction();
		setTimeout(() => {
			setShowLoading(false);
			// console.timeEnd("********** Running startUp function **********");
		}, 1000);
	}

	const checkForUpdate = async () => {
		let platform = await getPlatform();
		if (platform === "ios" || platform === "android") {
			let currentVersion = await Deploy.getCurrentVersion();
			let availableVersions = await Deploy.getAvailableVersions();
			let currentConfiguration = await Deploy.getConfiguration();
			log.debug(`currentVersion: `, currentVersion);
			log.debug(`availableVersions: `, availableVersions);
			log.debug(`currentConfiguration: `, currentConfiguration);
			let update: CheckForUpdateResponse = await Deploy.checkForUpdate();
			log.debug(`update object: `, update);
			if (update.available) {
				// We have an update!
				setUpdating(true);
				log.debug("Getting update");
				try {
					const resp: any = await Deploy.sync({ updateMethod: "auto" }, (percentDone) => {
						// log.debug("Percent Done: ");
						// log.debug({ percentDone });
						if (percentDone) setPercentDone(percentDone);
					});
					log.debug({ resp });
				} catch (err) {
					log.debug("Error in updating: " + JSON.stringify(err));
				}
				setUpdating(false);
			}
		}
		if (debugMode === true) {
			setUpdating(true);
			setPercentDone(75);
		}
	};

	return (
		<IonApp>
			{/* Loading screen */}
			{/** If no user object exists, then don't proceed until it's confirmed there isn't an update */}
			{/** If the app is updating show the update */}
			{/** If the user is not authenticated, show the entry screen  */}
			{/** If the user is authenticated, setup the main routing  */}

			{/** IF the user exists and there is an update, show the update screen */}

			{showLoading === true || debugMode === true ? (
				<React.Fragment>
					<IonPage className={isMobile ? "mobile-page" : "desktop-page"}>
						<div className="app-main">
							<div style={{ width: "80%", margin: "auto" }}>
								<img src="/assets/images/fithero-ai-animated-logo.gif" />
							</div>
							{updating === true ? (
								<div style={{ color: "white", textAlign: "center" }}>Updating to latest version... {percentDone}%</div>
							) : (
								<div style={{ textAlign: "center", color: "white" }}>
									{loadingStage}
									<br />
									<IonSpinner />
								</div>
							)}
						</div>
					</IonPage>
				</React.Fragment>
			) : (
				<React.Fragment>
					{isMobile ? (
						<IonPage className={"mobile-page"}>
							<IonReactRouter>
								<MainRouting loadingComplete={loadingComplete} />
							</IonReactRouter>
						</IonPage>
					) : (
						<>
							{userState && userState.authenticated === true && (
								<IonHeader>
									<IonToolbar>
										<IonTitle>FitHero</IonTitle>
									</IonToolbar>
								</IonHeader>
							)}

							<IonPage className={"desktop-page"}>
								<IonReactRouter>
									<MainRouting loadingComplete={loadingComplete} />
								</IonReactRouter>
							</IonPage>
						</>
					)}
				</React.Fragment>
			)}
		</IonApp>
	);
};

export default FitHeroApp;
