/* eslint-disable react-hooks/exhaustive-deps */
import {
	IonModal
} from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import "../css/Celebrations.css";
import { NotificationType, NotificationObject } from "../models/celebrationModels";
import { StorageService } from "../services/StorageService";
import DuelResultCelebration from "./DuelResultCelebration";
import LevelAndStatCelebration from "./LevelAndStatCelebration";
import log from "loglevel";
import { NoCurrentDuel } from "./NoCurrentDuel";
import { ChooseSpecialization } from "./ChooseSpecialization";
import { RateApp } from "capacitor-rate-app";

const InAppNotificationContainer = () => {
	const storageService = new StorageService();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { shouldShowNotificationModal, setShouldShowNotificationModal } = globalState;
	const modal = useRef<HTMLIonModalElement>(null);
	const [currentNotification, setCurrentNotification] = useState<NotificationObject>();


	useEffect(() => {
		log.debug(`Adding notification to queue. ShouldShowNotificationModal: `, shouldShowNotificationModal);
		addNotificationToLocalQueue();
	}, [shouldShowNotificationModal]);

	useEffect(() => {
		log.debug(`currentNotification: `, currentNotification);
	}, [currentNotification]);

	async function addNotificationToLocalQueue() {
		if (shouldShowNotificationModal === true) {
			let existingNotifications = await storageService.getObject("celebrationsInStorage");
			log.debug(`existingNotifications: `, existingNotifications);
			if (!existingNotifications || existingNotifications.length === 0) {
				log.debug(`existingNotifications is null`);
				return false
			}
			setCurrentNotification(existingNotifications.shift());
			await storageService.setObject("celebrationsInStorage", existingNotifications);
			return true
		} else {
			log.debug(`shouldShowNotificationModal is false`);
			return false
		}
	}

	async function clearNotifications() {
		await storageService.setObject("celebrationsInStorage", []);
	}

	async function processOtherNotifications() {
		// let moreNotifications = await addNotificationToLocalQueue()
		// if (moreNotifications === false) {
		// 	log.debug(`no more notifications`);
		// 	setShouldShowNotificationModal(false);
		// 	RateApp.requestReview();
		// }

		// For now only show one notification at a time
		log.debug('clearing other notifications')
		await clearNotifications();
		setShouldShowNotificationModal(false);
		RateApp.requestReview();
	}

	function dismiss() {
		modal.current?.dismiss();
		processOtherNotifications()
	}



	return (
		<>
			{currentNotification && currentNotification.type === NotificationType.duelWin && currentNotification.updateObject && (
				<IonModal ref={modal} onDidDismiss={() => processOtherNotifications()} id="activity-notifications-modal" isOpen={shouldShowNotificationModal} >
					<DuelResultCelebration duelStatus={currentNotification.updateObject} dismissFunction={dismiss} />
				</IonModal>
			)}
			{currentNotification && currentNotification.type === NotificationType.heroStatsUpdate && currentNotification.updateObject && (
				<IonModal ref={modal} onDidDismiss={() => processOtherNotifications()} id="activity-notifications-modal" isOpen={shouldShowNotificationModal} >
					<LevelAndStatCelebration updateObject={currentNotification.updateObject} dismissFunction={dismiss} />
				</IonModal>
			)}

			{currentNotification && currentNotification.type === NotificationType.noCurrentDuel && (
				<IonModal ref={modal} onDidDismiss={() => processOtherNotifications()} id="no-current-duel-notifications-modal" isOpen={shouldShowNotificationModal} >
					<NoCurrentDuel dismissFunction={dismiss} />
				</IonModal>
			)}

			{currentNotification && currentNotification.type === NotificationType.chooseSpecialization && currentNotification.updateObject && (
				<IonModal ref={modal} onDidDismiss={() => processOtherNotifications()} id="activity-notifications-modal" isOpen={shouldShowNotificationModal} >
					<ChooseSpecialization specializations={currentNotification.updateObject.specializations} dismissFunction={dismiss} />
				</IonModal>
			)}

		</>
	);
};

export default InAppNotificationContainer;
