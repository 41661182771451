import { IonContent, IonIcon, IonItem, IonLabel, IonList, IonModal, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import Profile from "../Features/Profile/Profile";
import AddActivity from "../components/AddActivity";

import { addCircle, barChart, calendarClearOutline, calendarNumber, chatboxEllipses, home, shield } from "ionicons/icons";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import InAppNotificationContainer from "../Celebrations/InAppNotificationContainer";
import DuelsDetails from "../Features/Duels/pages/DuelDetails";
import DuelReport from "../Features/Duels/pages/DuelReport";

// Onboarding Imports
// v2 Onboarding
import Intro from "../Onboarding/v2/0-Intro";
import Intro1 from "../Onboarding/v2/1-Intro";
import Intro2 from "../Onboarding/v2/2-Intro2";
import Intro3 from "../Onboarding/v2/3-Intro3";
import CreateAvatar from "../Onboarding/v2/4-CreateAvatar";
import NameHero from "../Onboarding/v2/5-NameHero";
import NameUser from "../Onboarding/v2/6-NameUser";
import ConnectTracker from "../Onboarding/v2/7-ConnectTracker";
import FitBitConnected from "../Onboarding/v2/8-FitBitConnected";
import GoogleFitConnected from "../Onboarding/v2/8-GoogleFitConnected";
import HealthKitConnected from "../Onboarding/v2/8-HealthKitConnected";
import Register from "../Onboarding/v2/9-Register";
import Upgrade from "../Onboarding/v2/10-Upgrade";
import OnboardingComplete from "../Onboarding/v2/11-OnboardingComplete";

// v1 Onboarding
// import Intro from "../Onboarding/v1/1-Intro";
// import Register from "../Onboarding/v1/2-Register";
import PhotoOption from "../Onboarding/v1/3-PhotoOption";
// import CreateAvatar from "../Onboarding/v1/4-CreateAvatar";
// import NameHero from "../Onboarding/v1/5-NameHero";
import DuelsExplained from "../Onboarding/v1/6-DuelsExplained";
import StartFirstDuel from "../Onboarding/v1/6-StartFirstDuel";
import ExplainStats from "../Onboarding/v1/6`2-ExplainStats";
import CongratsOnFirstDuel from "../Onboarding/v1/6`1-CongratsOnFirstDuel";
import ExplainStatsPt2 from "../Onboarding/v1/6`3-ExplainStatsPt2";
import LogFirstActivity from "../Onboarding/v1/7-LogFirstActivity";
import BonusPoints from "../Onboarding/v1/7`1-BonusPoints";
import FirstHit from "../Onboarding/v1/7`1-FirstHit";
import FamousExamples from "../Onboarding/v1/8-FamousExample";
// import ConnectTracker from "../Onboarding/v1/9-ConnectTracker";
// import FitBitConnected from "../Onboarding/v1/10-FitBitConnected";
// import GoogleFitConnected from "../Onboarding/v1/10-GoogleFitConnected";
// import HealthKitConnected from "../Onboarding/v1/10-HealthKitConnected";
// import Upgrade from "../Onboarding/v1/11-Upgrade";
// import OnboardingComplete from "../Onboarding/v1/12-OnboardingComplete";


// Testing imports
import TestImagineDev from "../Onboarding/testImagineDev";
import TestLeap from "../Onboarding/testLeap";
import TestLeonardo from "../Onboarding/testLeonardo";
import TestStabilityAI from "../Onboarding/testStabilityAI";

import Settings from "../Features/Profile/Settings";
import AppURLListener from "../components/AppURLListener";
import ShowVersionUpdate from "../components/ShowUpdate";
import GlobalState from "../contexts/GlobalState";
import "../css/App.css";
import "../css/Celebrations.css";
import "../css/Desktop.css";
import TestEmail from "../misc/testEmail";
import TestProfile from "../misc/testGPTVision";
import { OnboardingObject, UserState, VersionInfo } from "../models/userModel";
// import CoachChat from "../../archive/CoachChat";
import log from "loglevel";
import DuelsHome from "../Features/Duels/pages/DuelsHome";
import VillainGroupHome from "../Features/Villians/pages/VillainGroupHome";
import MainFeedPage from "../Feed/pages/MainFeedPage";

import OtherProfile from "../Features/Profile/otherProfile/OtherProfile";
import { UpdateAvatar } from "../Features/Profile/updateAvatar/UpdateAvatar";
import UpdateAvatarPhotoOption from "../Features/Profile/updateAvatar/UpdateAvatarPhotoOption";
import { SidekickAdmin } from "../SidekickAdmin/Admin";
import SkillTree from "../Skills/pages/SkillTree";
import EntryScreen from "../pages/EntryScreen";
import ForgotPassword from "../pages/ForgotPassword";
import FullWorkoutLog from "../pages/FullWorkoutLog";
import LoginScreen from "../pages/Login";
import Missions from "../pages/Missions";
import NotificationPlayGround from "../pages/NotificationPlayground";
import PullData from "../pages/PullData";
import Simulator from "../pages/Simulator";
import TestParse from "../pages/testParse";
import { TestServerCode } from "../pages/testServerCode";
import { logPageView } from "../services/AnalyticsService";
import { getVersionFromServer } from "../services/HelperService";
import { StorageService } from "../services/StorageService";
import Leaderboard from "../Feed/pages/Leaderboard";
import { PracticeGym } from "../Features/Duels/components/PracticeGym";
import { PaywallClassDojo } from "../Paywall/PaywallClassDoJo";
import TestAnimation from "../misc/testAnimation";
import TestAnimationFramer from "../misc/testAnimationFramer";
import Assistant from "../misc/assistant";
import SidekickV2 from "../Features/Sidekick/pages/Sidekick";
import { ManageSidekicks } from "../Features/Sidekick/pages/ManageSidekicks";
import HeroChat from "../Features/Herochat/pages/Herochat";
import { Dashboard } from "../Features/Dashboard/Dashboard";
import { HeroProfile } from "../Features/Profile/HeroProfile";
import { TourComponent } from "../Features/Tour/StepsComponent";
import { DuelStatusTour } from "../Features/Tour/Steps";
import Feed from "../Features/Feed/pages/Feed";
import { Challenges } from "../Features/Challenges/pages/Challenges";

const storageService = new StorageService();

const MainRouting = (props: { loadingComplete: boolean }) => {
	interface Update {
		show: boolean;
		update: string;
	}

	interface ActivityModalObject {
		visible: boolean;
		duelId?: string | null;
	}
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { userState, updateUserState, isMobile, onboardingCompleted, heroNewMessages, heroMessagesLoadingFinished } = globalState;
	const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
	const [activityModal, showAddActivityModal] = useState<ActivityModalObject>({ visible: false, duelId: null });
	const [update, setUpdate] = useState<Update>({ show: false, update: "" });

	const location = useLocation();
	const regex = /\/dueldetails\/([^\/]+)\/status/;
	const onDuelStatusScreen = regex.exec(location.pathname);
	const id = onDuelStatusScreen ? onDuelStatusScreen[1] : null;  // 'id' will hold the captured ID if the pattern matches


	useEffect(() => {
		// log.debug("Main Routing Use Effect");
		// log.debug(`onboardingCompleted: `, onboardingCompleted);
		// log.debug(`userState: `, userState);
		updateUserState(null);
		// if (userState && userState.authenticated === true && userState.onboarding?.completed === true) {
		// 	shouldShowCoachmark("menu").then((result: boolean) => {
		// 		setShowMenuCoachmarks(result);
		// 	});
		// }

		updateOnboarding();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userState.authenticated, onboardingCompleted]);

	useEffect(() => {
		compareVersions();
	}, []);

	// useEffect(() => {
	// 	log.debug(`[MainRouting] celebrationModal: `, celebrationModal);
	// }, [celebrationModal]);

	const getLinkStyle = (path: string) => {
		return location.pathname === path ? "active" : "";
	};

	// Architected this way because this component gets mounted and unmounted & remounted, setting the state to default levels again
	// With this setup, the only component to change whether an update is shown or not is the showUpdate component
	async function compareVersions() {
		getVersionFromServer()
			.then((update: VersionInfo) => {
				// log.debug(`Update: `, update);
				// update.updateShown = false;
				if (update.updateShown === false) {
					setUpdate({ show: true, update: update.update });
				}
			})
			.catch((error) => {
				log.debug(error);
			});
	}

	async function updateOnboarding() {
		let result = await storageService.getObject("onboarding");
		// log.debug("Main Routing Onboarding Object:");
		// log.debug({ result });
		setOnboardingObject(result);
		if (userState && userState.authenticated !== true && (onboardingCompleted === true || !result)) {
			// log.debug("Updating user state");
			updateUserState(null);
		}
	}

	return (
		<>
			{
				// log.debug(`State: \nuserState.authenticated (${userState.authenticated})\nprops.loadingComplete (${props.loadingComplete})\nuserState.onboarding (${JSON.stringify(userState.onboarding)})`)
			}
			{userState.authenticated === true &&
				props.loadingComplete === true &&
				(userState.onboarding?.completed === true || JSON.stringify(userState.onboarding) === "{}" || userState.onboarding === null) ? (
				<>
					<IonModal isOpen={update.show}>
						<ShowVersionUpdate updateString={update.update} showUpdate={setUpdate} />
					</IonModal>
					<IonModal onDidDismiss={() => showAddActivityModal({ visible: false, duelId: null })} isOpen={activityModal.visible} className={`${isMobile ? "" : "desktop-add-activity-modal"}`}>
						<AddActivity showActivityModal={showAddActivityModal} duelId={activityModal.duelId} />
					</IonModal>

					<InAppNotificationContainer />
					{/* Here we are checking if the app is running on mobile or not */}
					{isMobile ? (
						<>
							<IonTabs>
								<IonRouterOutlet className={isMobile ? "" : "app-centering"} id="main">
									<Switch>
										<Routes userState={userState} loadingComplete={props.loadingComplete} />
									</Switch>
								</IonRouterOutlet>
								<IonTabBar slot="bottom" id="tabBar">
									<IonTabButton tab="home" href="/dashboard" className="dashboard-hint">
										<IonIcon icon={home} />
										<IonLabel>Home</IonLabel>
									</IonTabButton>
									<IonTabButton tab="duels" href="/duels/current" className="duels-hint">
										<IonIcon icon={shield} />
										<IonLabel>Duels</IonLabel>
									</IonTabButton>
									{/* <IonTabButton tab="coach" href="/coach" className="coach-hint">
										<IonIcon aria-hidden="true" icon={chatbubbles} />
										<IonLabel>Coach</IonLabel>
									</IonTabButton> */}
									<IonTabButton tab="add" onClick={() => showAddActivityModal({ visible: true })} className="add-hint">
										<IonIcon icon={addCircle} />
										<IonLabel>Add Activity</IonLabel>
									</IonTabButton>
									{/* <IonTabButton tab="sidekick" href={sidekick ? "/sidekick" : "/manageSidekick"} className="sidekick-hint">
										<IonIcon icon={chatboxEllipses} />{sidekickNewMessages === true && (<div className="new-sidekick-message">🔴</div>)}
										<IonLabel>Sidekick</IonLabel>
									</IonTabButton> */}
									<IonTabButton tab="herochat" href={"/herochat"} className="herochat-hint">
										<IonIcon icon={chatboxEllipses} />{heroNewMessages > 0 && (<div className="new-hero-message-mobile">{heroNewMessages}</div>)}
										<IonLabel>Hero Chat</IonLabel>
									</IonTabButton>
									<IonTabButton tab="profile" href="/profile/goals" className="home-hint">
										<IonIcon icon={barChart} />
										<IonLabel>Goals</IonLabel>
									</IonTabButton>
									{/* <IonTabButton tab="challenges" href="/challenges/explanation" className="challenges-hint">
										<IonIcon icon={calendarNumber} />
										<IonLabel>Challenges</IonLabel>
									</IonTabButton> */}
								</IonTabBar>
							</IonTabs>
						</>
					) : (
						<>
							<div style={{ color: "white", width: "20%", minWidth: "200px" }}>
								<IonContent>
									<IonList className="desktop-menu-list">
										<IonItem routerLink="/dashboard" className={`dashboard-hint desktop-menu-item ${getLinkStyle("/dashboard")}`}>
											<IonIcon icon={home} slot="start" />
											Home
										</IonItem>
										<IonItem routerLink="/duels/current" className={`duels-hint desktop-menu-item ${getLinkStyle("/duels/current")}`}>
											<IonIcon icon={shield} slot="start" />
											Duels
										</IonItem>
										{/* <IonItem routerLink="/coach" className={`coach-hint desktop-menu-item ${getLinkStyle("/coach")}`}>
											<IonIcon icon={chatbubbles} slot="start" />
											Coach
										</IonItem> */}
										<IonItem button onClick={() => showAddActivityModal({ visible: true })} className={`add-hint desktop-menu-item`}>
											<IonIcon icon={addCircle} slot="start" />
											Add Activity
										</IonItem>
										{/* <IonItem routerLink="/sidekick" className={`sidekick-hint desktop-menu-item ${getLinkStyle("/sidekick")}`}>
											<IonIcon icon={chatboxEllipses} slot="start" />{sidekickNewMessages === true && (<div className="new-sidekick-message">🔴</div>)}
											Sidekick
										</IonItem> */}
										<IonItem routerLink="/herochat" className={`herochat-hint desktop-menu-item ${getLinkStyle("/herochat")}`}>
											<IonIcon icon={chatboxEllipses} slot="start" />{heroNewMessages > 0 && (<div className="new-hero-message-desktop">{heroNewMessages}</div>)}
											Hero Chat
										</IonItem>
										<IonItem routerLink="/profile/goals" className={`home-hint desktop-menu-item ${getLinkStyle("/profile/goals")}`}>
											<IonIcon icon={barChart} slot="start" />
											Goals
										</IonItem>
										{/* <IonItem routerLink="/challenges/explanation" className={`home-hint desktop-menu-item ${getLinkStyle("/challenges")}`}>
											<IonIcon icon={calendarNumber} slot="start" />
											Challenges
										</IonItem> */}
									</IonList>
								</IonContent>
							</div>
							<IonRouterOutlet className="app-centering" id="main">
								<Switch>
									<Routes userState={userState} loadingComplete={props.loadingComplete} />
								</Switch>
							</IonRouterOutlet>
						</>
					)}
				</>
			) : (
				<IonRouterOutlet className="app-centering-onboarding" id="main">
					<Switch>
						<Routes userState={userState} loadingComplete={props.loadingComplete} />
					</Switch>
				</IonRouterOutlet>
			)}
			{onDuelStatusScreen && (
				<TourComponent coachMarkName='duelStatus' steps={DuelStatusTour} modalShowing={false} action={() => showAddActivityModal({ visible: true, duelId: id })} />

			)}
		</>
	);
};

const Routes = (props: { userState: UserState; loadingComplete: boolean }) => {
	useEffect(() => {
		logPageView(`${window.location.pathname}`);
	}, [window.location.pathname]);

	// function trackPageView() {
	// 	logPageView(`${window.location.pathname}`);
	// 	log.debug(`Logging page ${window.location.pathname}`);
	// }

	useEffect(() => {
		//log userState
		// log.debug(`[Routes] userState: `, props.userState);
		// log.debug(`[Routes] onboardingObject: `, props.userState.onboarding);
	}, [props.userState]);

	return (
		<>
			<AppURLListener />

			{/*** Onboarding ***/}
			<OnboardingRoutes />

			{/*** Profile ***/}
			<ProfileRoutes />

			{/*** Duels ***/}
			<DuelRoutes />

			{/*** Challenges ***/}
			<ChallengeRoutes />

			{/*** Authentication ***/}
			<AuthenticationRoutes />

			{/*** Paywall ***/}
			<PaywallRoutes />

			{/*** Misc ***/}
			<MiscRoutes />

			{/*** Sidekick ***/}
			<SidekickRoutes />

			{/*** Hero Chat ***/}
			<HerochatRoutes />

			{/*** Testing ***/}
			<TestingRoutes />

			{/* <Route exact path="/manageSidekicks">
				<ManageSidekicks />
			</Route> */}
			{
				// States:
				// State 1: User authenticated, onboarding present and onboarding not completed = resume onboarding
				// State 2: User authenticated, no onboarding object OR onboarding completed = standard log in because user is legit
				// State 3: User NOT authenticad = entryscreen
			}
			{props.userState.authenticated === true && props.userState.onboarding && props.userState.onboarding.completed === false && (
				<>
					{/* {log.debug("[State 1] Redirecting to onboarding url")} */}
					<Redirect path="" to={props.userState.onboarding.url} exact />
				</>
			)}
			{/* Not sure why I need to use JSON.stringify(onboardingObject) === "{}" but it works */}
			{props.userState.authenticated === true &&
				(JSON.stringify(props.userState.onboarding) === "{}" ||
					props.userState.onboarding === null ||
					props.userState.onboarding === undefined ||
					props.userState.onboarding.completed === true) ? (
				<>
					{/* {log.debug("[State 2] Redirecting to home")} */}
					{/* <Redirect path="/" to="/" exact /> */}
				</>
			) : (
				<>
					{/* {log.debug("Check for redirect to home")}
					{props.userState.authenticated === true ? <>{log.debug("Auth is true")}</> : <>{log.debug("Auth is not true")}</>}
					{JSON.stringify(onboardingObject) === "{}" || onboardingObject === null || onboardingObject === undefined || onboardingObject.completed === true ? (
						<>{log.debug("onboardingObject is empty or true")}</>
					) : (
						<>{log.debug("onboardingObject is not empty or is false")}</>
					)}
					{log.debug()} */}
				</>
			)}
			{props.userState.authenticated !== true && props.loadingComplete === true ? (
				<>
					{/* {log.debug("[State 3] userState.authenticated !== true")} */}
					{/* {log.debug(props.userState)} */}
					<Redirect path="" to="/entryscreen" exact />
				</>
			) : (
				""
			)}
		</>
	);
};

export default MainRouting;

const DuelRoutes = () => {
	return (
		<>
			<Route exact path="/">
				<Dashboard />
			</Route>
			<Route exact path="/home">
				<DuelsHome />
			</Route>
			<Route exact path="/duels/:viewParam">
				<DuelsHome />
			</Route>
			<Route path="/dueldetails/:duelID/:viewParam">
				<DuelsDetails />
			</Route>
			<Route path="/duelreport/:duelID">
				<DuelReport />
			</Route>
			<Route exact path="/duels/duelprogression/:villainGroupId">
				<VillainGroupHome />
			</Route>
			<Route exact path="/leaderboard/">
				<Leaderboard />
			</Route>
			<Route exact path="/practicegym/">
				<PracticeGym />
			</Route>
		</>
	)
}

const ChallengeRoutes = () => {
	return (
		<>
			<Route exact path="/challenges/:viewParam">
				<Challenges />
			</Route>
		</>
	)
}

const ProfileRoutes = () => {
	return (
		<>
			<Route exact path="/otherprofile/:heroId">
				<OtherProfile />
			</Route>
			<Route exact path="/profile/:viewParam">
				<Profile />
			</Route>
			<Route exact path="/profile">
				<Profile />
			</Route>
			<Route exact path="/updateAvatar">
				<UpdateAvatarPhotoOption />
			</Route>
			<Route exact path="/profile/uploadphoto">
				<UpdateAvatar upload={true} />
			</Route>
			<Route exact path="/profile/createavatar">
				<UpdateAvatar upload={false} />
			</Route>
			{/* <Route exact path="/profile/hero">
				<HeroProfile />
			</Route> */}
		</>
	)

}

const SidekickRoutes = () => {
	return (
		<>
			<Route exact path="/sidekick">
				<SidekickV2 />
			</Route>
			<Route exact path="/sidekickadmin">
				<SidekickAdmin />
			</Route>
		</>
	)
}

const HerochatRoutes = () => {
	return (
		<>
			<Route exact path="/herochat">
				<HeroChat />
			</Route>
		</>
	)
}

const PaywallRoutes = () => {
	return (
		<>
			<Route exact path="/paywallclassdojo">
				<PaywallClassDojo />
			</Route>
		</>
	)
}

const AuthenticationRoutes = () => {
	return (
		<>
			<Route exact path="/entryscreen">
				<EntryScreen />
			</Route>
			<Route exact path="/login">
				<LoginScreen />
			</Route>
			<Route exact path="/forgotpassword">
				<ForgotPassword />
			</Route>
		</>
	)
}

const OnboardingRoutes = () => {
	return (
		<>
			<Route exact path="/onboarding/intro">
				<Intro />
			</Route>
			<Route exact path="/onboarding/intro1">
				<Intro1 />
			</Route>
			<Route exact path="/onboarding/intro2">
				<Intro2 />
			</Route>
			<Route exact path="/onboarding/intro3">
				<Intro3 />
			</Route>
			<Route exact path="/onboarding/register">
				<Register />
			</Route>
			<Route exact path="/onboarding/photooption">
				<PhotoOption />
			</Route>
			<Route exact path="/onboarding/uploadphoto">
				<CreateAvatar upload={true} />
			</Route>
			<Route exact path="/onboarding/namehero">
				<NameHero />
			</Route>
			<Route exact path="/onboarding/nameuser">
				<NameUser />
			</Route>
			<Route exact path="/onboarding/createavatar">
				<CreateAvatar upload={false} />
			</Route>
			<Route exact path="/onboarding/explainstats">
				<ExplainStats />
			</Route>
			<Route exact path="/onboarding/explainstatspt2">
				<ExplainStatsPt2 />
			</Route>
			<Route exact path="/onboarding/logfirstactivity">
				<LogFirstActivity />
			</Route>
			<Route exact path="/onboarding/famousexamples">
				<FamousExamples />
			</Route>
			<Route exact path="/onboarding/duelsexplained">
				<DuelsExplained />
			</Route>
			<Route exact path="/onboarding/startfirstduel">
				<StartFirstDuel />
			</Route>
			<Route exact path="/onboarding/congratsonfirstduel">
				<CongratsOnFirstDuel />
			</Route>
			<Route exact path="/onboarding/firsthit">
				<FirstHit />
			</Route>
			<Route exact path="/onboarding/connecttracker">
				<ConnectTracker />
			</Route>
			<Route exact path="/onboarding/bonuspoints">
				<BonusPoints />
			</Route>
			<Route exact path="/onboarding/healthkitconfirmation">
				<HealthKitConnected />
			</Route>
			<Route exact path="/onboarding/googlefitconfirmation">
				<GoogleFitConnected />
			</Route>
			<Route exact path="/onboarding/fitbitconfirmation">
				<FitBitConnected />
			</Route>
			<Route exact path="/onboarding/upgrade">
				<Upgrade />
			</Route>
			<Route exact path="/onboarding/onboardingcomplete">
				<OnboardingComplete />
			</Route>
		</>
	)
}

const MiscRoutes = () => {
	return (
		<>
			<Route exact path="/dashboard">
				<Dashboard />
			</Route>
			<Route exact path="/fulllog">
				<FullWorkoutLog />
			</Route>
			<Route exact path="/pulldata">
				<PullData />
			</Route>
			<Route exact path="/missions">
				<Missions />
			</Route>
			<Route exact path="/notificationplayground">
				<NotificationPlayGround />
			</Route>
			<Route exact path="/settings">
				<Settings />
			</Route>
			<Route exact path="/simulator">
				<Simulator />
			</Route>

			<Route exact path="/skills/:attribute">
				<SkillTree />
			</Route>
			<Route exact path="/feed/">
				<Feed />
			</Route>
		</>
	)
}
const TestingRoutes = () => {
	return (
		<>
			<Route exact path="/testanimation">
				<TestAnimation />
			</Route>
			<Route exact path="/testanimationframer">
				<TestAnimationFramer />
			</Route>
			<Route exact path="/assistant">
				<Assistant />
			</Route>
			<Route exact path="/testleap">
				<TestLeap />
			</Route>
			<Route exact path="/testimagine">
				<TestImagineDev />
			</Route>
			<Route exact path="/testsai">
				<TestStabilityAI />
			</Route>
			<Route exact path="/testleonardo">
				<TestLeonardo />
			</Route>
			<Route exact path="/testemail">
				<TestEmail />
			</Route>
			<Route exact path="/testprofile">
				<TestProfile />
			</Route>
			<Route exact path="/testservercode">
				<TestServerCode />
			</Route>
			<Route exact path="/testparse">
				<TestParse />
			</Route>
		</>
	)
}