import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react';
import { useContext, useEffect } from 'react';
import HeaderLogo from '../../components/HeaderLogo';
import "../../css/Onboarding.css";
import { StorageService } from "../../services/StorageService";
import GlobalState from '../../contexts/GlobalState';
const storageService = new StorageService();


const Intro2 = () => {
    const nextPage = "/onboarding/intro3";
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile } = globalState;


    useEffect(() => {
        // initAnalyticsFreshInstall();
        storageService.removeItem("onboarding");
    }, []);
    return (
        <IonPage>
            <HeaderLogo checkforUser={false} />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Step 2: Engage Your Hero in Duels</div>
                    <div className='onboarding-text-area' style={{ textAlign: "center", marginTop: "0px", marginBottom: "20px" }}>
                        Send your hero into battle against villains that represent unhealthy lifestyles.
                    </div>

                    <div>
                        <img className="onboarding-hero-image" src="assets/images/onboarding/villain-battles.jpg" alt="" />
                    </div>
                </div>
            </IonContent>
            <IonFooter className="onboarding-footer">
                <IonButton routerLink={nextPage} className="onboarding-advance-button">
                    Continue
                </IonButton>
            </IonFooter>
        </IonPage>
    )
}

export default Intro2