import React from 'react';

const SkillIcon = (props: { cooldown: number, maxCooldown: number }) => {
    const radius = 20; // radius of the circle
    const stroke = 5; // thickness of the border
    const normalizedRadius = radius - stroke * 2;
    const circumference = normalizedRadius * 2 * Math.PI;

    // Calculate the offset for the stroke dash array
    const strokeDashoffset = circumference - (props.cooldown / props.maxCooldown) * circumference;

    function polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
        var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

        return {
            x: centerX + radius * Math.cos(angleInRadians),
            y: centerY + radius * Math.sin(angleInRadians),
        };
    }

    function describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
        var start = polarToCartesian(x, y, radius, endAngle);
        var end = polarToCartesian(x, y, radius, startAngle);

        var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        var d = [
            "M",
            start.x,
            start.y,
            "A",
            radius,
            radius,
            0,
            largeArcFlag,
            0,
            end.x,
            end.y,
        ].join(" ");

        return d;
    }

    function describeSector(cx: number, cy: number, radius: number, endAngle: number) {
        const startAngle = 0;
        const start = polarToCartesian(cx, cy, radius, endAngle);
        const end = polarToCartesian(cx, cy, radius, startAngle);
        const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

        return [
            "M", cx, cy,
            "L", start.x, start.y,
            "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y,
            "L", cx, cy,
            "Z"
        ].join(" ");
    }

    return (
        <svg className="cooldown-svg" viewBox="0 0 36 36">
            {/* Full Circle for Background */}
            <circle className="circle-bg" cx="18" cy="18" r="15.9155" fill="rgb(120, 246, 255, 0.5)" />

            {/* Overlay Arc for Cooldown */}
            <path
                className="circle"
                fill="rgba(0,0,0, 0.5)"
                d={describeSector(18, 18, 15.9155, (1 - ((props.cooldown) / props.maxCooldown)) * 360)}
            />
            <text
                x="18" y="18"
                className="cooldown-text"
                textAnchor="middle"
                dominantBaseline="central"
                fill="white"
            >
                {props.cooldown}
            </text>
        </svg>
    );
};

export default SkillIcon;