import { ActivitiesTour } from '../Tour/Steps';
import { TourComponent } from '../Tour/StepsComponent';
import ActivityHistory from './ActivityHistory';

export const ProfileActivities = () => {

    return (
        <>
            {/* <IonContent> */}
            <ActivityHistory />

            {/* </IonContent> */}
        </>
    )
}
