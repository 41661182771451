import axios from "axios";
import log from "loglevel";
import moment from "moment";
import Parse from "parse";
import { DUEL_MESSAGES_TABLE } from "../models/databaseModels";
import { duelAPIMessagesKey, duelDisplayMessagesKey, DuelStatus } from "../models/duelsModels";
import { MESSAGE_ENDPOINT } from "../models/endPoints";
import {
	DuelAPIMessage,
	DuelDisplayMessage,
	DuelMessageObject,
	DuelMessageTransformedResponse
} from "../models/messageModels";
import { cleanMessages, cleanString } from "./HelperService";
import { sendSlackErrorNotification } from "./NotificationService";
import { StorageService } from "./StorageService";
import { HeroObject } from "../models/userModel";
import { getHero, getUser, getUser_id } from "./UserService";
const storageService = new StorageService();

export async function processVillainResponse(villainReponse: DuelMessageObject, userInput: string, user_id: string, duelStatus: DuelStatus) {
	// Initialize variables
	let existingDisplayMessages: DuelDisplayMessage[] = [];
	let messagesForDisplay: DuelDisplayMessage[] = [];
	let apiResponseForDisplay: DuelDisplayMessage;

	existingDisplayMessages = await storageService.getObject(duelDisplayMessagesKey + "-" + duelStatus.statusId);
	if (existingDisplayMessages === null) existingDisplayMessages = [];

	// These are segmented in case of the followup text
	let userMessage: DuelDisplayMessage = { role: "user", content: userInput, duel_id: duelStatus.statusId!, timestamp: moment() };
	let updatedDisplayResponse: DuelDisplayMessage[] = [];
	updatedDisplayResponse = [...existingDisplayMessages, userMessage];

	let cleanedMessage = cleanString(villainReponse.message, duelStatus.villainName);
	apiResponseForDisplay = {
		role: "assistant",
		content: cleanedMessage,
		duel_id: duelStatus.statusId!,
		timestamp: moment(),
		imageUrl: villainReponse.imageUrl || "",
	};
	messagesForDisplay = [...updatedDisplayResponse, apiResponseForDisplay];
	await storageService.setObject(duelDisplayMessagesKey + "-" + duelStatus.statusId, messagesForDisplay);

	return messagesForDisplay;
}

// export async function generateWorkoutSummaryPrompt(workoutArray: Array<WorkoutObject>, stats: any): Promise<string> {
// 	let summary = "";
// 	let attributes = ["strength", "cardio", "mobility", "mindfulness"];

// 	let increasedAttributes: Array<string> = [];
// 	attributes.forEach((attribute) => {
// 		if (
// 			stats.currentHeroObject[`${attribute}Level`] > stats.previousHeroObject[`${attribute}Level`] ||
// 			stats.currentHeroObject[`${attribute}Points`] > stats.previousHeroObject[`${attribute}Points`]
// 		) {
// 			increasedAttributes.push(attribute);
// 		}
// 	});

// 	// Check for level or points increase
// 	if (increasedAttributes.length > 0) {
// 		let randomAttribute = increasedAttributes[random(0, increasedAttributes.length - 1)];
// 		summary = `Thanks to my recent effort, your ${randomAttribute} level increased from ${stats.previousStats[`${randomAttribute}Level`]} to ${stats.currentStats[`${randomAttribute}Level`]
// 			}, and points increased from ${stats.previousStats[`${randomAttribute}Points`]} to ${stats.currentStats[`${randomAttribute}Points`]
// 			}. Congratulate me on doing this for you in an extremely over-the-top way. Mention how excited you are to have increased ${randomAttribute}. Keep your responses to a maximum of 2 sentences.###`;
// 	}
// 	// Check for number of activities
// 	else if (workoutArray.length === 1) {
// 		summary = `I recently did ${workoutArray[0].activityType}. Ask me how it went. Keep your responses to a maximum of 2 sentences.###`;
// 	} else if (workoutArray.length > 1) {
// 		let healthGain = attributes.reduce((total, attribute) => total + (stats.currentStats[`${attribute}Points`] - stats.previousStats[`${attribute}Points`]), 0);
// 		summary = `I recently earned you ${healthGain} health. Congratulate me on doing this for you. Keep your responses to a maximum of 2 sentences.###`;
// 	}

// 	// Add more logic as needed to handle additional properties of the workoutArray objects
// 	let user_id = await getUser_id();
// 	getCoachResponse(summary, false, false, user_id);
// 	// getGPTResponse("pet", summary, false, false);
// 	return summary;
// }

export const logMessageInDB = async (message: string, source: string, user_id: string) => {
	await axios
		.post(MESSAGE_ENDPOINT, {
			message: message,
			source: source,
			user_id: user_id,
		})
		.then((result) => {
			// Logging
			// log.debug(`Full API Response: `, result);
		})
		.catch((error) => {
			sendSlackErrorNotification(`Error Message: ${error.response.data.error.message}`, `logMessageInDB`, error);
			log.debug(`Error Message: ${error}`);
			log.debug(`Full Error Obj: `, error);
			if (error.response.status === 429) {
				log.debug("Overloaded Trying again...");
			} else {
				alert("Ooops an error occurred");
			}
		});
};

export async function sendDuelMessage(message: string, source: string, duelID: string, responseType?: string): Promise<DuelMessageObject> {
	const params = {
		duel_id: duelID,
		message: message,
		source: source,
		primaryResponse: responseType
	}
	log.debug(`[sendDuelMessage] Params: `, params);
	const messageResult = await Parse.Cloud.run('createDuelMessage', params).catch((error) => {
		sendSlackErrorNotification(`[logDuelMessage]`, "logDuelMessage", error);
		log.debug(`[logDuelMessage] Error Message: ${error}`);
		log.debug(`[logDuelMessage] Full Error Obj: `, error);
		throw error;
	});
	log.debug(`[logDuelMessage] Message Result: `, messageResult);
	return messageResult.toJSON() as unknown as DuelMessageObject;
}

export async function fetchDuelMessages(duel_id: string): Promise<DuelMessageTransformedResponse> {
	// Get all entries for a specific duel, add new ones to local storage and return all of them for the duel
	log.debug("[fetchDuelMessages] Fetching duel messages for duel: ", duel_id);
	let allMessages: DuelMessageObject[] = [];
	let query = new Parse.Query(DUEL_MESSAGES_TABLE);
	query.equalTo("duel_id", duel_id);
	query.limit(1000);
	query.descending("createdAt");
	let results = await query.find();
	results.forEach((result) => {
		allMessages.push(result.toJSON() as unknown as DuelMessageObject);
	});
	let messages: any = transformDuelApiResponse(allMessages);
	log.debug("[fetchDuelMessages] Messages: ", messages);

	await storageService.setObject(duelDisplayMessagesKey + "-" + duel_id, cleanMessages(messages.displayMessages.reverse()));
	await storageService.setObject(duelAPIMessagesKey + "-" + duel_id, messages.apiMessages.reverse());

	// Only return those that are for the duel_id
	return {
		displayMessages: messages.displayMessages, // they're already reversed
		apiMessages: messages.apiMessages,
	};
}

/**
 *
 * Converts the API response to the format used by the GPTMessage component
 * Specifically, removes the timestamp & duel_id fields from apiMessages
 *
 * @param apiResponse
 * @returns
 */

function transformDuelApiResponse(apiResponse: DuelMessageObject[]): DuelMessageTransformedResponse {
	const displayMessages: DuelDisplayMessage[] = apiResponse.map((item) => ({
		content: item.message,
		role: item.source,
		duel_id: item.duel_id,
		timestamp: moment(item.createdAt),
		imageUrl: item.imageUrl || "",
	}));

	const apiMessages: DuelAPIMessage[] = apiResponse.map((item) => ({
		content: item.message,
		role: item.source === "user" ? "user" : "assistant",
	}));

	return { displayMessages, apiMessages };
}

export async function getLastDuelMessage(duelId: string): Promise<string> {
	let query = new Parse.Query(DUEL_MESSAGES_TABLE);
	query.equalTo("duel_id", duelId);
	query.equalTo("source", "villain");
	query.limit(1);
	query.descending("createdAt");
	let results = await query.first();
	if (results) {
		let message = results.get("message");
		log.debug(`[getLastDuelMessage] Last message: ${message}`);
		return message;
	}
	return ""
}

export async function getHeroTranslation(message: string) {
	let hero = await getHero();
	let params = {
		hero: hero,
		message: message,
	}
	try {
		let response = await Parse.Cloud.run('heroResponse', params);
		log.debug(`[getHeroTranslation] Response: `, response);
		return response.message;
	} catch (error) {
		sendSlackErrorNotification(`[getHeroTranslation]`, "getHeroTranslation", error);
		log.debug(`[getHeroTranslation] Error Message: ${error}`);
		log.debug(`[getHeroTranslation] Full Error Obj: `, error);
		throw error;
	}
}

export async function sendHeroMessageFromButton(responseType: string, duelId: string): Promise<string> {
	let userId = await getUser_id();
	let params = {
		duelId: duelId,
		responseType: responseType,
		userId: userId,
	}
	try {
		let response = await Parse.Cloud.run('heroResponseToVillainButton', params);
		log.debug(`[sendHeroMessageFromButton] Response: `, response);
		return response;
	} catch (error) {
		sendSlackErrorNotification(`[sendHeroMessageFromButton]`, "sendHeroMessageFromButton", error);
		log.debug(`[sendHeroMessageFromButton] Error Message: ${error}`);
		log.debug(`[sendHeroMessageFromButton] Full Error Obj: `, error);
		throw error;
	}
}
