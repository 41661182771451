import log from "loglevel";
import moment from "moment";
import Parse from 'parse';
import { ACTIVITIES_TABLE, DUEL_LEDGER_TABLE, DUEL_POINT_MATRIX_TABLE, DUEL_STATUS_TABLE, USER_VILLAIN_TALLY_TABLE, VILLAIN_GROUP_INSTANCE_TABLE } from "../models/databaseModels";
import { DuelLedger, DuelStatus, VillainGroupInstance, VillainGroups, duelLedgerKey, duelPointMatrixKey, duelStatusKey, duelStatusLastUpdatedKey, userVillainTallyKey, villainGroupInstancesKey } from "../models/duelsModels";
import { DuelMessageObject } from "../models/messageModels";
import { HeroObject, heroObjectKey } from "../models/userModel";
import { Villain } from "../models/villainModels";
import { logGenericEventWithObject } from "./AnalyticsService";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";
import { StorageService } from "./StorageService";
import { getUser, getUserByHeroID, getUser_id } from "./UserService";
import { getVillain, getVillainFromDuelStatus } from "./VillainServices";
import { getAttributeBreakdownByActivity } from "./HealthKitService";
import { getPersonalPronoun, getPossessivePronoun, getRandomValueFromArray, getThirdPersonPronoun } from "./HelperService";
import { LAST_SUGGESTION_COMPLETED_KEY, cardioActivities, mindfulnessExercises, mobilityActivities, strengthActivities } from "../models/helperSuggestions";
import { Skill } from "../models/skills";
let storageService = new StorageService();

export interface DuelLedgerResponse {
	countInfo: {
		count: number;
		totalPages: number;
		currentPage: number;
		pageSize: number;
	};
	result: [DuelLedger];
}

export interface DuelStatusResponse {
	countInfo: {
		count: number;
		totalPages: number;
		currentPage: number;
		pageSize: number;
	};
	result: [DuelStatus];
}

export interface DuelMessageResponse {
	countInfo: {
		count: number;
		totalPages: number;
		currentPage: number;
		pageSize: number;
	};
	result: [DuelMessageObject];
}

export async function getDuelStatus(duel_id: string): Promise<DuelStatus> {
	const query = new Parse.Query(DUEL_STATUS_TABLE);
	query.equalTo('statusId', duel_id);
	const parseObject = await query.first().catch((error) => {
		sendSlackErrorNotification(`[getDuelStatus] Full Error Obj`, "getDuelStatus", `${error}`);
		console.error(`[getDuelStatus] Error Message: ${error}`);
		console.error(`[getDuelStatus] Full Error Obj: `, error);
	});
	if (!parseObject) throw new Error("Duel not found");
	return parseObject.toJSON() as unknown as DuelStatus;
}

export async function getDuelStatusWithInstanceId(villainGroupInstanceId: string, villainID: string): Promise<DuelStatus | null> {
	let userId = await getUser_id();
	const query = new Parse.Query(DUEL_STATUS_TABLE);
	query.equalTo('villainGroupInstanceId', villainGroupInstanceId);
	query.equalTo('user_id', userId);
	query.equalTo('villainID', villainID);
	const parseObject = await query.first().catch((error) => {
		sendSlackErrorNotification(`[getDuelStatusWithInstanceId] Full Error Obj`, "getDuelStatus", `${error}`);
		console.error(`[getDuelStatusWithInstanceId] Error Message: ${error}`);
		console.error(`[getDuelStatusWithInstanceId] Full Error Obj: `, error);
	});
	if (!parseObject) return null
	return parseObject.toJSON() as unknown as DuelStatus;
}

export async function getAllDuels() {
	// log.debug("[getAllDuels] Fetching all duels status");
	let allStatuses: DuelStatus[] = [];
	let userID = await getUser_id();
	const query = new Parse.Query(DUEL_STATUS_TABLE);
	query.limit(10000);
	query.equalTo('user_id', userID);
	query.descending('createdAt')
	const parseObjects = (await query.find())
	parseObjects.forEach((parseObject: any) => {
		let status = parseObject.toJSON() as unknown as DuelStatus;
		status.heroHealth = Math.round(status.heroHealth)
		status.villainHealth = Math.round(status.villainHealth)
		allStatuses.push(status)
	})
	// log.debug(`[getAllDuels] duels: `, allStatuses);

	let currentStatuses: DuelStatus[] | null = await storageService.getObject(duelStatusKey);
	if (currentStatuses === null) currentStatuses = [];

	// If any of the statuses have changed, update the local storage
	if (JSON.stringify(allStatuses) !== JSON.stringify(currentStatuses)) {
		// log.debug("[getAllDuels] Statuses have changed, updating local storage");
		await storageService.setObject(duelStatusKey, allStatuses);
		await storageService.setItem(duelStatusLastUpdatedKey, moment().format());
	}
	return allStatuses;
}

export async function getAllDuelsForOtherUser(userId: string) {
	// log.debug("[getAllDuels] Fetching all duels status");
	let allStatuses: DuelStatus[] = [];
	const query = new Parse.Query(DUEL_STATUS_TABLE);
	query.limit(10000);
	query.equalTo('user_id', userId);
	query.descending('createdAt')
	const parseObjects = (await query.find())
	parseObjects.forEach((parseObject: any) => {
		let status = parseObject.toJSON() as unknown as DuelStatus;
		status.heroHealth = Math.round(status.heroHealth)
		status.villainHealth = Math.round(status.villainHealth)
		allStatuses.push(status)
	})
	return allStatuses;
}

export async function processDuelTick(duelID: string) {
	const params = {
		duelID: duelID,
	};
	await Parse.Cloud.run('processDuelTick', params).catch((error) => {
		sendSlackErrorNotification(`[startDuel] Full Error Obj`, "startDuel", `${error}`);
		console.error(`[startDuel] Full Error Obj: `, error);
		throw error;
	});
}

export async function fetchDuelsLedger(duel_id: string) {
	try {
		// Get all entries for a specific duel, add new ones to local storage and return all of them for the duel
		// log.debug("[getDuelsLedger] Fetching duels ledger for duel: ", duel_id);
		let allLedger: DuelLedger[] = [];
		const query = new Parse.Query(DUEL_LEDGER_TABLE);
		query.limit(10000);
		query.equalTo('duel_id', duel_id);
		query.descending('createdAt')
		const parseObjects = (await query.find())
		parseObjects.forEach((parseObject: any) => {
			allLedger.push(parseObject.toJSON())
		})
		let currentLedger: DuelLedger[] | null = await storageService.getObject(duelLedgerKey);
		if (currentLedger === null) currentLedger = [];

		// Create a Set of current UUIDs
		let currentLedgerIDs = new Set(currentLedger.map((ledgerEntry) => ledgerEntry.ledgerId));

		// Only add ledger entries from result that are not in currentWorkouts
		let newLedgerEntries = allLedger.filter((ledgerEntry) => !currentLedgerIDs.has(ledgerEntry.ledgerId));
		// log.debug(`Ledger entries added: ${newLedgerEntries.length}`);

		let joinedLedger = [...currentLedger];
		// Only add ledger entries from result that are not in currentLedger
		if (newLedgerEntries.length > 0) {
			joinedLedger = [...currentLedger, ...newLedgerEntries];
			await storageService.setObject(duelLedgerKey, [...joinedLedger.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))]);
		} else {
			// just make sure the stored ledger is sorted by date
			await storageService.setObject(duelLedgerKey, [...joinedLedger.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))]);
		}

		// Only return those that are for the duel_id
		return [...allLedger.sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))];
	} catch (error) {
		sendSlackErrorNotification(`[fetchDuelsLedger] Full Error Obj`, "fetchDuelsLedger", `${error}`);
		console.error(`[fetchDuelsLedger] Full Error Obj: `, error);
		throw error;
	}
}

interface StartDuelParams {
	villain_id: string;
	user_id: string;
	villainStrength: number;
	villainCardio: number;
	villainMobility: number;
	villainMindfulness: number;
	villain_group_instance_id?: string;
	villainHealth?: number; // for early level scaling
	difficulty: string;
	skillsToUse?: Skill[];
}

// export async function autoStartNextDuel () {
// 	let user = await getUser();
// 	let hero = await storageService.getObject(heroObjectKey);
// 	let villainGroupInstances = await storageService.getObject(villainGroupInstancesKey);
// 	if (!user || !hero || !villainGroupInstances) return;
// 	let villainGroupInstance = villainGroupInstances.find(vgi => vgi.villainGroupInstanceId === user.currentVillainGroupInstanceId);
// 	if (!villainGroupInstance) return;
// 	let villainGroup = await getVillainGroup(villainGroupInstance.villainGroupId);
// 	if (!villainGroup) return;
// 	let villain = await getVillain(villainGroup.villainId);
// 	if (!villain) return;
// 	let difficulty = "medium";
// 	let duel = await startDuel(villain, difficulty, villainGroupInstance.villainGroupInstanceId);
// 	if (duel) {
// 		await storageService.setObject(duelStatusKey, [duel]);
// 	}

// }

export async function startDuel(villain: Villain, difficulty: string, villainGroupInstanceId?: string, skillsToUse?: Skill[]): Promise<DuelStatus | undefined> {
	log.debug(`[startDuel] villain: `, villain);
	let user_id = await getUser_id();
	let villainID = villain.villainID;
	const params: StartDuelParams = {
		villain_id: villainID,
		user_id: user_id,
		villainStrength: villain.strength,
		villainCardio: villain.cardio,
		villainMobility: villain.mobility,
		villainMindfulness: villain.mindfulness,
		difficulty: difficulty,
		skillsToUse: skillsToUse
	};
	if (villain.health) params.villainHealth = villain.health;
	log.debug(`[startDuel] params: `, params);
	if (villainGroupInstanceId) {
		params.villain_group_instance_id = villainGroupInstanceId;
	}
	// log.debug(`[startDuel] params: `, params);
	const duel: DuelStatus = await Parse.Cloud.run('createNewDuel', params).catch((error) => {
		sendSlackErrorNotification(`[startDuel] Full Error Obj`, "startDuel", `${error}`);
		console.error(`[startDuel] Full Error Obj: `, error);
		throw error;
	});
	if (duel) {
		sendSlackNotification(`⚔️[NEW DUEL STARTED]⚔️\n${duel.heroName} started a duel vs. ${duel.villainName} on ${duel.difficulty} difficulty\n*Skills selected*: \`\`\`${JSON.stringify(skillsToUse)}\`\`\``, "newDuels");
		logGenericEventWithObject("Duel Started", duel)
		let ds = await getAllDuels();
		await storageService.setObject(duelStatusKey, ds);
		await storageService.removeItem(LAST_SUGGESTION_COMPLETED_KEY); // clear last suggestion completed
		return duel;
	}
}

export async function createVillainGroupInstance(villainGroupId?: string) {
	let userId = await getUser_id();
	const params = {
		villainGroupId: villainGroupId,
		userId: userId,
	};
	// log.debug(`[createVillainGroupInstance] params: `, params);
	let result = await Parse.Cloud.run('createVillainGroupInstance', params).catch((error) => {
		sendSlackErrorNotification(`[createVillainGroupInstance] Full Error Obj`, "createVillainGroupInstance", `${error}`);
		console.error(`[createVillainGroupInstance] Full Error Obj: `, error);
		throw error;
	});
	console.log("[createVillainGroupInstance] result: ", result)
	return result;
}

export interface LikelyWinner {
	likelyWinner: string;
	percentChanceFloat: number;
	percentChanceString: string;
	expectedDuration: number;
}

export async function calculateDuelSuccessPercent(villain: Villain, oddsObj?: OddsPredictionNumber): Promise<LikelyWinner> {
	let hero: HeroObject = await storageService.getObject(heroObjectKey);
	if (!oddsObj) {
		oddsObj = await simulateBattleByVillain(hero, villain, 100, true);
	}
	// log.debug(`[calculateDuelSuccessPercent] heroOdds: `, oddsObj);
	let likelyWinner: string = "";
	let percentChanceString: string = "";
	oddsObj.heroOdds += 0.3; // Add 30% to the odds to show that its more likely that the hero will win

	if (oddsObj.heroOdds < 0.5) {
		likelyWinner = villain.name;
	} else if (oddsObj.heroOdds > 0.5) {
		likelyWinner = "You!";
	} else {
		likelyWinner = "tie";
	}

	if (oddsObj.heroOdds < 0.01) {
		oddsObj.heroOdds = 0.01;
		percentChanceString = "<1%";
	} else if (oddsObj.heroOdds > 0.99) {
		oddsObj.heroOdds = 0.99;
		percentChanceString = ">99%";
	} else {
		percentChanceString = `${(oddsObj.heroOdds * 100).toFixed(0)}%`;
	}
	let duelSuccess = {
		likelyWinner: likelyWinner,
		percentChanceFloat: oddsObj.heroOdds,
		percentChanceString: percentChanceString,
		expectedDuration: oddsObj.expectedDuration,
	};
	// log.debug(`[calculateDuelSuccessPercent] duelSuccess: `, duelSuccess);
	return duelSuccess;
}

export async function concedeDuel(duelID: string) {
	// log.debug(`[concedeDuel] duelID: `, duelID);
	const params = {
		duel_id: duelID,
	}
	await Parse.Cloud.run('concedeDuel', params).catch((error) => {
		sendSlackErrorNotification(`[concedeDuel] Full Error Obj`, "concedeDuel", `${error}`);
		console.error(`[concedeDuel] Full Error Obj: `, error);
		throw error;
	});
	logGenericEventWithObject("Duel Conceded", { duelID: duelID })
	let ds = await getAllDuels();
	await storageService.setObject(duelStatusKey, ds);
}

interface OddsPredictionString {
	heroOdds: string;
	expectedDuration: number;
}

export function convertOddsToText(odds: number): string {
	if (odds < .10) {
		return "very low";
	} else if (odds < .30) {
		return "low";
	} else if (odds < .50) {
		return "even";
	} else if (odds < .70) {
		return "high";
	} else {
		return "very high";
	}

}

export async function getLatestOddsFromLedger(statusId: string) {
	let oddsObj: OddsPredictionString = {
		heroOdds: "",
		expectedDuration: 0,
	};
	let ledger = await fetchDuelsLedger(statusId);
	if (ledger.length > 0) {
		let lastEntry = ledger[0];
		// log.debug("lastEntry.heroOdds: ", lastEntry.heroOdds)
		if (lastEntry.heroOdds !== undefined) oddsObj.heroOdds = convertOddsToText(lastEntry.heroOdds);
		// oddsObj.heroOdds = lastEntry.heroOdds ? (lastEntry.heroOdds * 100).toFixed(0) + "%" : "";
	}
	return oddsObj;
}

export async function simulateBattleByStatus(duelStatus: DuelStatus, simulationCount: number): Promise<OddsPredictionString> {
	// log.debug(`[simulateBattleByStatus] duelStatus: `, duelStatus);
	// Get the villain
	let villainObject = await getVillainFromDuelStatus(duelStatus);

	// Get the hero
	let heroObject = await storageService.getObject(heroObjectKey);

	heroObject = {
		heroToHit: duelStatus.heroToHit,
		heroDamage: duelStatus.heroDamage,
		heroHealth: duelStatus.heroHealth,
		heroDodge: duelStatus.heroDodge,
	};

	let ho: OddsPredictionNumber = await simulateBattleByVillain(heroObject, villainObject, simulationCount);
	let oddsObj: OddsPredictionString = {
		heroOdds: "",
		expectedDuration: 0,
	};
	if (ho.heroOdds < 0.01) {
		oddsObj.heroOdds = "<1%";
	} else if (ho.heroOdds > 0.99) {
		oddsObj.heroOdds = ">99%";
	} else {
		oddsObj.heroOdds = (ho.heroOdds * 100).toFixed(0) + "%";
	}
	oddsObj.expectedDuration = ho.expectedDuration;
	// log.debug("ho: ", ho);
	return oddsObj;
}

async function calculateAveragePoints() {
	let totalStrengthPoints = 0;
	let totalCardioPoints = 0;
	let totalMobilityPoints = 0;
	let totalMindfulnessPoints = 0;

	let userObj = await getUser();
	if (!userObj) throw new Error("User not found");
	const query = new Parse.Query(ACTIVITIES_TABLE);
	query.limit(10000);
	query.equalTo('user_id', userObj.userID!);
	query.greaterThanOrEqualTo('startDate', moment().subtract(14, "days").format());
	const parseObjects = (await query.find())
	let activities: any[] = []
	parseObjects.forEach((parseObject: any) => {
		activities.push(parseObject.toJSON())
	})
	// log.debug("activities: ", activities)

	// Sum up all points
	activities.forEach(activity => {
		totalStrengthPoints += activity.strengthPoints || 0;
		totalCardioPoints += activity.cardioPoints || 0;
		totalMobilityPoints += activity.mobilityPoints || 0;
		totalMindfulnessPoints += activity.mindfulnessPoints || 0;
	});

	// Get unique days from the workouts
	// const uniqueDaysSet = new Set(workouts.map(workout => new Date(workout.startDate).toDateString()));
	// const uniqueDaysCount = uniqueDaysSet.size;
	const uniqueDaysCount = 14;

	// Calculate average points per unique day
	const averageStrengthPoints = Math.round(totalStrengthPoints / uniqueDaysCount);
	const averageCardioPoints = Math.round(totalCardioPoints / uniqueDaysCount);
	const averageMobilityPoints = Math.round(totalMobilityPoints / uniqueDaysCount);
	const averageMindfulnessPoints = Math.round(totalMindfulnessPoints / uniqueDaysCount);

	return {
		averageStrengthPoints,
		averageCardioPoints,
		averageMobilityPoints,
		averageMindfulnessPoints,
	};
}

async function calculateUniqueHourlyActivityBlocks(hero: HeroObject) {
	let user = await getUserByHeroID(hero.heroID!);
	if (!user.userID) throw new Error("User not found");

	const query = new Parse.Query(ACTIVITIES_TABLE);
	query.limit(10000);
	query.equalTo('user_id', user.userID);
	query.greaterThanOrEqualTo('startDate', moment().subtract(14, "days").format());
	const parseObjects = await query.find();

	let activitiesByDay: { [date: string]: Set<string> } = {};

	parseObjects.forEach((parseObject) => {
		const activityDate = moment(parseObject.get('startDate')).format('YYYY-MM-DD');
		const activityHour = moment(parseObject.get('startDate')).format('HH');

		if (!activitiesByDay[activityDate]) {
			activitiesByDay[activityDate] = new Set();
		}

		activitiesByDay[activityDate].add(activityHour);
	});

	let totalUniqueHourBlocks = 0;

	Object.values(activitiesByDay).forEach(hourSet => {
		totalUniqueHourBlocks += hourSet.size;
	});

	const uniqueDaysCount = Object.keys(activitiesByDay).length;
	let uniqueHourBlocksPerDay = uniqueDaysCount > 0 ? totalUniqueHourBlocks / uniqueDaysCount : 0;

	return uniqueHourBlocksPerDay;
}


async function calculateActivitiesPerDay(hero: HeroObject) {
	let user = await getUserByHeroID(hero.heroID!);
	if (!user.userID) throw new Error("User not found");
	const query = new Parse.Query(ACTIVITIES_TABLE);
	query.limit(10000);
	query.equalTo('user_id', user.userID);
	query.greaterThanOrEqualTo('startDate', moment().subtract(14, "days").format());
	const parseObjects = (await query.find())
	let activities: any[] = []
	parseObjects.forEach((parseObject: any) => {
		activities.push(parseObject)
	})
	// log.debug("# of activities: ", activities.length)
	const uniqueDaysCount = 14;
	let activitiesPerDay = activities.length / uniqueDaysCount;
	// log.debug("activitiesPerDay: ", activitiesPerDay)

	return activitiesPerDay;
}

export interface OddsPredictionNumber {
	heroOdds: number;
	expectedDuration: number;
	logString?: string;
}

export async function simulateBattleByVillain(hero: HeroObject, villain: Villain, simulationCount: number, showLogs: boolean = true, includeWorkoutsCheckbox: boolean = true): Promise<OddsPredictionNumber> {
	// log.debug(`[simulateBattleByVillain] hero: `, hero);
	// log.debug(`[simulateBattleByVillain] villain: `, villain);
	let heroWins = 0;
	let avgTickArray = [];
	let heroHitsArray = [];
	let villainHitsArray = [];
	let totalHeroDamageDealtArray = [];
	let totalVillainDamageDealtArray = [];


	// log.debug(`[simulateBattleByVillain] heroToHit: `, heroToHit);
	// log.debug(`[simulateBattleByVillain] villainToHit: `, villainToHit);

	let heroAvgPointsPerDay = await calculateAveragePoints();
	let activitiesPerDay = await calculateActivitiesPerDay(hero);
	// log.debug("heroAvgPointsPerDay: ", heroAvgPointsPerDay)

	const damageIncrease = Math.ceil(heroAvgPointsPerDay.averageStrengthPoints / 5);
	const healthIncrease = Math.ceil(heroAvgPointsPerDay.averageCardioPoints / 1);
	const dodgeIncrease = Math.ceil(heroAvgPointsPerDay.averageMobilityPoints / 5);
	const chanceToHitIncrease = Math.ceil(heroAvgPointsPerDay.averageMindfulnessPoints / 5);

	// log.debug("damageIncrease: ", damageIncrease / hero.heroDamage)
	// log.debug("healthIncrease: ", healthIncrease / hero.heroHealth)
	// log.debug("dodgeIncrease: ", dodgeIncrease / hero.heroDodge)
	// log.debug("chanceToHitIncrease: ", chanceToHitIncrease / hero.heroToHit)

	let heroToHit = Math.max((hero.heroToHit - villain.dodge), 5);
	if (heroToHit > 95) {
		heroToHit = 95;
	}
	let villainToHit = Math.max((villain.toHit - hero.heroDodge), 5);
	if (villainToHit > 95) {
		villainToHit = 95;
	}

	let heroToHitCurrent = heroToHit;
	const tickWhenIncreasesHit = 5

	for (let i = 0; i < simulationCount; i++) {
		// For every simulation, reset variables back to default
		let totalTicks = 0;
		let heroHits = 0;
		let villainHits = 0;
		let totalHeroDamageDealt = 0;
		let totalVillainDamageDealt = 0;
		let heroHealthCurrent = hero.heroHealth;
		let villainHealthCurrent = villain.health;
		let heroDamageCurrent = hero.heroDamage;
		let heroDodgeCurrent = hero.heroDodge;
		heroToHitCurrent = Math.max((hero.heroToHit - villain.dodge), 5);
		if (heroToHitCurrent > 95) {
			heroToHitCurrent = 95;
		}
		let villainToHit = Math.max((villain.toHit - heroDodgeCurrent), 5);
		if (villainToHit > 95) {
			villainToHit = 95;
		}

		while (heroHealthCurrent > 0 && villainHealthCurrent > 0) {
			// Hero attacks villain
			if ((Math.floor(Math.random() * 100) + 1) < heroToHitCurrent) {
				let heroDamage = Math.floor(Math.random() * (heroDamageCurrent - 1 + 1)) + 1;
				villainHealthCurrent -= heroDamage;
				heroHits++;
				totalHeroDamageDealt += heroDamage;
			}

			// Villain attacks hero
			// We recalculate this here in case the hero dodge has changed
			let villainToHit = Math.max((villain.toHit - heroDodgeCurrent), 5);
			if (villainToHit > 95) {
				villainToHit = 95;
			}
			if ((Math.floor(Math.random() * 100) + 1) < villainToHit) {
				let villainDamage = Math.floor(Math.random() * (villain.damage - 1 + 1)) + 1;
				heroHealthCurrent -= villainDamage;
				villainHits++;
				totalVillainDamageDealt += villainDamage;
			}

			// If the villain is defeated, increment hero wins and break loop
			if (villainHealthCurrent <= 0 && heroHealthCurrent > 0) {
				heroWins++;
				break;
			}
			// log.debug("currentHeroHealth: ", currentHeroHealth, "currentVillainHealth: ", currentVillainHealth);
			totalTicks++;
			if (totalTicks === tickWhenIncreasesHit && includeWorkoutsCheckbox) {
				// log.debug("Total Ticks: ", totalTicks)
				// log.debug("Workout impacts added")
				heroDamageCurrent += damageIncrease;
				heroHealthCurrent += healthIncrease;
				heroDodgeCurrent += dodgeIncrease;
				// heroToHitG += chanceToHitIncrease;
				heroToHitCurrent = Math.max((hero.heroToHit + chanceToHitIncrease - villain.dodge), 5);
				if (heroToHitCurrent > 95) {
					heroToHitCurrent = 95;
				}
			}
		}
		avgTickArray.push(totalTicks);
		heroHitsArray.push(heroHits / totalTicks);
		villainHitsArray.push(villainHits / totalTicks);
		totalHeroDamageDealtArray.push(totalHeroDamageDealt / totalTicks);
		totalVillainDamageDealtArray.push(totalVillainDamageDealt / totalTicks);
	}
	let avgTicks = avgTickArray.reduce((a, b) => a + b, 0) / avgTickArray.length;
	let avgHeroHits = heroHitsArray.reduce((a, b) => a + b, 0) / heroHitsArray.length;
	let avgVillainHits = villainHitsArray.reduce((a, b) => a + b, 0) / villainHitsArray.length;
	let avgTotalHeroDamageDealt = totalHeroDamageDealtArray.reduce((a, b) => a + b, 0) / totalHeroDamageDealtArray.length;
	let avgTotalVillainDamageDealt = totalVillainDamageDealtArray.reduce((a, b) => a + b, 0) / totalVillainDamageDealtArray.length;
	let ho: number = heroWins / simulationCount;
	if (showLogs === true) {
		log.debug("********** SIMULATION RESULTS *************")
		log.debug("heroWins: ", heroWins);
		log.debug("expected hero hit %: ", (heroToHitCurrent).toFixed(2) + "%");
		log.debug("avgHero %: ", (avgHeroHits * 100).toFixed(2) + "%");
		log.debug("avgTotalHeroDamageDealt: ", avgTotalHeroDamageDealt.toFixed(1));
		log.debug("expected villain hit %: ", (villainToHit).toFixed(2) + "%");
		log.debug("avgVillain %: ", (avgVillainHits * 100).toFixed(2) + "%");
		log.debug("avgTotalVillainDamageDealt: ", avgTotalVillainDamageDealt.toFixed(1));
		log.debug("# of hours: ", avgTicks);
		log.debug("# of days: ", avgTicks / 24);
		log.debug("Hero Odds: ", (ho * 100).toFixed(2) + "%");
	}

	return {
		heroOdds: ho,
		expectedDuration: Math.round(avgTicks),
	};
}

interface SimulationResults {
	heroOdds: number;
	expectedDuration: number;
	logString: string;
}

export async function simulateBattleServer(hero: HeroObject, villain: Villain): Promise<SimulationResults> {
	let user = await getUser();
	const params = {
		userId: user?.userID,
		hero: hero,
		villain: villain,
		simulationCount: 100,
		showLogs: false,
		useSkills: true,
	}
	let results: SimulationResults = await Parse.Cloud.run('simulateBattleByVillainV2', params).catch((error) => {
		sendSlackErrorNotification(`[simulateBattleServer] Full Error Obj`, "simulateBattleServer", `${error}`);
		console.error(`[simulateBattleServer] Full Error Obj: `, error);
		throw error;
	});
	return results;
}

export async function simulateBattleByVillainNew(hero: HeroObject, villain: Villain, simulationCount: number, showLogs: boolean = true): Promise<OddsPredictionNumber> {
	// log.debug(`[simulateBattleByVillain] hero: `, hero);
	// log.debug(`[simulateBattleByVillain] villain: `, villain);
	let heroWins = 0;
	let avgTickArray = [];
	let heroHitsArray = [];
	let villainHitsArray = [];
	let totalHeroDamageDealtArray = [];
	let totalVillainDamageDealtArray = [];


	// log.debug(`[simulateBattleByVillain] heroToHit: `, heroToHit);
	// log.debug(`[simulateBattleByVillain] villainToHit: `, villainToHit);

	let heroAvgPointsPerDay = await calculateAveragePoints();
	// let activitiesPerDay = await calculateActivitiesPerDay(hero);
	let activitiesPerDay = await calculateUniqueHourlyActivityBlocks(hero);
	log.debug("heroAvgPointsPerDay: ", heroAvgPointsPerDay)
	log.debug("activitiesPerDay: ", activitiesPerDay)
	if (activitiesPerDay < 1) activitiesPerDay = 1;
	let ticksBetweenHits = Math.round(24 / activitiesPerDay);
	let damagePerTick = hero.heroDamage;

	const damageIncrease = Math.ceil(heroAvgPointsPerDay.averageStrengthPoints / 5);
	const healthIncrease = Math.ceil(heroAvgPointsPerDay.averageCardioPoints / 1);
	const dodgeIncrease = Math.ceil(heroAvgPointsPerDay.averageMobilityPoints / 5);
	const chanceToHitIncrease = Math.ceil(heroAvgPointsPerDay.averageMindfulnessPoints / 5);

	// log.debug("damageIncrease: ", damageIncrease / hero.heroDamage)
	// log.debug("healthIncrease: ", healthIncrease / hero.heroHealth)
	// log.debug("dodgeIncrease: ", dodgeIncrease / hero.heroDodge)
	// log.debug("chanceToHitIncrease: ", chanceToHitIncrease / hero.heroToHit)

	let heroToHit = 100;
	let villainToHit = Math.max((villain.toHit - hero.heroDodge), 5);
	if (villainToHit > 95) {
		villainToHit = 95;
	}

	let heroToHitCurrent = heroToHit;
	const tickWhenIncreasesHit = ticksBetweenHits

	for (let i = 0; i < simulationCount; i++) {
		// For every simulation, reset variables back to default
		let totalTicks = 0;
		let heroHits = 0;
		let villainHits = 0;
		let totalHeroDamageDealt = 0;
		let totalVillainDamageDealt = 0;
		let heroHealthCurrent = hero.heroHealth;
		let villainHealthCurrent = villain.health;
		let heroDamageCurrent = hero.heroDamage;
		let heroDodgeCurrent = hero.heroDodge;
		let villainToHit = Math.max((villain.toHit - heroDodgeCurrent), 5);
		if (villainToHit > 95) {
			villainToHit = 95;
		}

		while (heroHealthCurrent > 0 && villainHealthCurrent > 0) {
			if (totalTicks % tickWhenIncreasesHit === 0) {
				// Hero only attacks every x ticks
				heroDamageCurrent += damageIncrease;
				heroHealthCurrent += healthIncrease;
				heroDodgeCurrent += dodgeIncrease;
				// heroToHitG += chanceToHitIncrease;
				villainHealthCurrent -= heroDamageCurrent;
				heroHits++;
				totalHeroDamageDealt += heroDamageCurrent;
			}

			// Villain attacks hero
			// We recalculate this here in case the hero dodge has changed
			let villainToHit = Math.max((villain.toHit - heroDodgeCurrent), 5);
			if (villainToHit > 95) {
				villainToHit = 95;
			}
			if ((Math.floor(Math.random() * 100) + 1) < villainToHit) {
				let villainDamage = Math.floor(Math.random() * (villain.damage - 1 + 1)) + 1;
				heroHealthCurrent -= villainDamage;
				villainHits++;
				totalVillainDamageDealt += villainDamage;
			}

			// If the villain is defeated, increment hero wins and break loop
			if (villainHealthCurrent <= 0 && heroHealthCurrent > 0) {
				heroWins++;
				break;
			}
			totalTicks++;
		}
		// log.debug("[End of Battle ", i, "]currentHeroHealth: ", heroHealthCurrent, "currentVillainHealth: ", villainHealthCurrent);
		avgTickArray.push(totalTicks);
		heroHitsArray.push(heroHits / totalTicks);
		villainHitsArray.push(villainHits / totalTicks);
		totalHeroDamageDealtArray.push(totalHeroDamageDealt / heroHits);
		totalVillainDamageDealtArray.push(totalVillainDamageDealt / totalTicks);
	}
	let avgTicks = avgTickArray.reduce((a, b) => a + b, 0) / avgTickArray.length;
	let avgHeroHits = heroHitsArray.reduce((a, b) => a + b, 0) / heroHitsArray.length;
	let avgVillainHits = villainHitsArray.reduce((a, b) => a + b, 0) / villainHitsArray.length;
	let avgTotalHeroDamageDealt = totalHeroDamageDealtArray.reduce((a, b) => a + b, 0) / totalHeroDamageDealtArray.length;
	let avgTotalVillainDamageDealt = totalVillainDamageDealtArray.reduce((a, b) => a + b, 0) / totalVillainDamageDealtArray.length;
	let ho: number = heroWins / simulationCount;
	if (showLogs === true) {
		log.debug("********** SIMULATION RESULTS *************")
		log.debug("heroWins: ", heroWins);
		log.debug("expected # of hero hits: ", avgTicks / ticksBetweenHits);
		log.debug("avgHero %: ", (avgHeroHits * 100).toFixed(2) + "%");
		log.debug("avgTotalHeroDamageDealt: ", avgTotalHeroDamageDealt.toFixed(1));
		log.debug("expected villain hit %: ", (villainToHit).toFixed(2) + "%");
		log.debug("avgVillain %: ", (avgVillainHits * 100).toFixed(2) + "%");
		log.debug("avgTotalVillainDamageDealt: ", avgTotalVillainDamageDealt.toFixed(1));
		log.debug("# of hours: ", avgTicks);
		log.debug("# of days: ", avgTicks / 24);
		log.debug("Hero Odds: ", (ho * 100).toFixed(2) + "%");
	}

	return {
		heroOdds: ho,
		expectedDuration: Math.round(avgTicks),
	};
}

export async function getVillainGroupInstanceByInstanceId(groupInstanceId: string): Promise<VillainGroupInstance> {
	const user_id = await getUser_id();
	const query = new Parse.Query(VILLAIN_GROUP_INSTANCE_TABLE);
	query.equalTo('villainGroupInstanceId', groupInstanceId);
	const parseObject = await query.first().catch((error) => { //TODO: Update to fetch and process all results
		sendSlackErrorNotification(`[getVillainGroupInstance] Full Error Obj`, "getVillainGroupInstance", `${error}`);
		console.error(`[getVillainGroupInstance] Error Message: ${error}`);
		console.error(`[getVillainGroupInstance] Full Error Obj: `, error);
	});
	if (!parseObject) throw new Error("Instance not found");
	let villainGroupInstance = parseObject.toJSON() as unknown as VillainGroupInstance;
	for (let node of villainGroupInstance.villains) {
		let villain = await getVillain(node.villainId);
		node.villainName = villain.name;
		node.villainImage = villain.imageURL
	}
	return villainGroupInstance;
}

export async function getCurrentDuel(villainId?: string): Promise<DuelStatus | undefined> {
	let currentDuel = await storageService.getObject(duelStatusKey);
	if (currentDuel) {
		currentDuel = currentDuel?.filter((duel: DuelStatus) => {
			duel.heroHealth = Math.round(duel.heroHealth)
			duel.villainHealth = Math.round(duel.villainHealth)
			if (villainId) {
				return duel.villainID === villainId && duel.complete !== true;
			} else {
				return duel.complete !== true;
			}
		});
		return currentDuel[0];
	} else {
		return undefined;
	}
}

export async function getVillainGroupInstanceByVillainGroupId(villainGroupId: string): Promise<VillainGroupInstance> {
	const user_id = await getUser_id();
	const query = new Parse.Query(VILLAIN_GROUP_INSTANCE_TABLE);
	query.equalTo('villainGroupId', villainGroupId);
	query.equalTo('userId', user_id);
	const parseObject = await query.first().catch((error) => { //TODO: Update to fetch and process all results
		sendSlackErrorNotification(`[getVillainGroupInstance] Full Error Obj`, "getVillainGroupInstance", `${error}`);
		console.error(`[getVillainGroupInstance] Error Message: ${error}`);
		console.error(`[getVillainGroupInstance] Full Error Obj: `, error);
	});
	if (!parseObject) throw new Error("Instance not found");
	let villainGroupInstance = parseObject.toJSON() as unknown as VillainGroupInstance;
	let currentDuel = await getCurrentDuel();
	// log.debug("currentDuel: ", currentDuel)
	for (let node of villainGroupInstance.villains) {
		let villain = await getVillain(node.villainId);
		node.villainName = villain.name;
		node.villainImage = villain.imageURL
		if (node.villainId === currentDuel?.villainID) {
			node.currentlyDuelling = true
		} else {
			node.currentlyDuelling = false
		}
	}
	return villainGroupInstance;
}

export async function getAllVillainGroupInstances(): Promise<VillainGroupInstance[]> {
	const user_id = await getUser_id();
	const query = new Parse.Query(VILLAIN_GROUP_INSTANCE_TABLE);
	query.equalTo('userId', user_id);
	const parseObjects = await query.find().catch((error) => { //TODO: Update to fetch and process all results
		sendSlackErrorNotification(`[getAllVillainGroupInstances] Full Error Obj`, "getAllVillainGroupInstances", `${error}`);
		console.error(`[getAllVillainGroupInstances] Error Message: ${error}`);
		console.error(`[getAllVillainGroupInstances] Full Error Obj: `, error);
	});
	if (!parseObjects) throw new Error("No instances found");
	let groupInstances: VillainGroupInstance[] = []
	parseObjects.forEach((parseObject: any) => {
		groupInstances.push(parseObject.toJSON())
	})
	await storageService.setObject(villainGroupInstancesKey, groupInstances);
	return groupInstances;
}

export async function canGroupBeUnlocked(group: VillainGroups): Promise<{ canBeUnlocked: boolean, reason: string }> {
	// If the previous groupId in the instances is complete then return true
	let canBeUnlocked = true;
	let reason = "";
	// if (group.groupId === 1) {
	// 	canBeUnlocked = true;
	// 	return { canBeUnlocked: canBeUnlocked, reason: reason };
	// }
	// let instances = await getAllVillainGroupInstances();
	// let previousGroupInstance = instances.find((instance) => instance.groupId === (group.groupId - 1));
	// if (previousGroupInstance?.completed === true) {
	// 	canBeUnlocked = true;
	// } else {
	// 	reason = `Defeat the previous group to unlock!`;
	// }
	if (group.specialty === true) {
		if (moment().isBefore(moment(group.startDate))) {
			reason = `This group unlocks on ${moment(group.startDate).format("MMMM Do YYYY")}`;
			canBeUnlocked = false;
		} else {
			reason = "This group is no longer available";
			canBeUnlocked = false;
		}
	}
	return { canBeUnlocked: canBeUnlocked, reason: reason };
}

export type StatDescriptions = {
	villainDamageDescription: string;
	villainDodgeDescription: string;
	villainToHitDescription: string;
}

export function calculateRelativeStat(stat: number, villainOverallLevel: number): string {
	if (villainOverallLevel <= 1) return "low";
	let relative = stat / (villainOverallLevel / 4);
	let description = "avg";
	if (relative >= 2) {
		description = "very high";
	} else if (relative >= 1.3) {
		description = "high";
	} else if (relative <= 0.5) {
		description = "low";
	}
	return description
}

export function calculateRelativeStats(duelStatus: DuelStatus): StatDescriptions {
	log.debug("duelStatus.villainLevel: ", duelStatus.villainLevel)

	if (duelStatus.villainLevel !== undefined && duelStatus.villainLevel <= 1) return {
		villainDamageDescription: "low",
		villainDodgeDescription: "low",
		villainToHitDescription: "low"
	}
	// calculate villains strength, mobility and mindfulness relative to villains overall level
	let villainStrength = duelStatus.villainStrength ?? 1;
	let villainMobility = duelStatus.villainMobility ?? 1;
	let villainMindfulness = duelStatus.villainMindfulness ?? 1;
	let villainOverallLevel = duelStatus.villainLevel ?? 1;
	if (villainOverallLevel < 4) villainOverallLevel = 4;
	let villainStrengthRelative = villainStrength / (villainOverallLevel / 4);
	let villainMobilityRelative = villainMobility / (villainOverallLevel / 4);
	let villainMindfulnessRelative = villainMindfulness / (villainOverallLevel / 4);
	log.debug("villainStrengthRelative: " + villainStrengthRelative);
	log.debug("villainMobilityRelative: " + villainMobilityRelative);
	log.debug("villainMindfulnessRelative: " + villainMindfulnessRelative);
	let villainDamageDescription = "avg";
	if (villainStrengthRelative >= 1.3) {
		villainDamageDescription = "high";
	} else if (villainStrengthRelative <= 0.5) {
		villainDamageDescription = "low";
	}
	let villainDodgeDescription = "avg";
	if (villainMobilityRelative >= 1.3) {
		villainDodgeDescription = "high";
	} else if (villainMobilityRelative <= 0.5) {
		villainDodgeDescription = "low";
	}
	let villainToHitDescription = "avg";
	if (villainMindfulnessRelative >= 1.3) {
		villainToHitDescription = "high";
	} else if (villainMindfulnessRelative <= 0.5) {
		villainToHitDescription = "low";
	}
	return {
		villainDamageDescription,
		villainDodgeDescription,
		villainToHitDescription
	}
}

export type DuelPointMatrix = {
	event: string;
	points: number;
}

async function fetchDuelPointMatrix() {
	let query = new Parse.Query(DUEL_POINT_MATRIX_TABLE);
	let results = await query.find();
	let matrix: DuelPointMatrix[] = [];
	results.forEach((result: any) => {
		matrix.push(result.toJSON());
	});
	storageService.setObject(duelPointMatrixKey, matrix);
	return matrix;
}

export async function getDuelPointMatrix(): Promise<DuelPointMatrix[]> {
	let storedPointMatrix: DuelPointMatrix[] | null = await storageService.getObject(duelPointMatrixKey);
	if (storedPointMatrix) {
		fetchDuelPointMatrix();
		return storedPointMatrix;
	}
	let matrix = await fetchDuelPointMatrix();
	return matrix;
}

export async function calcDuelPointsFromDifficulty(difficulty: string): Promise<number> {
	let dpm: DuelPointMatrix[] = await storageService.getObject(duelPointMatrixKey);
	if (!dpm) {
		dpm = await getDuelPointMatrix();
	}
	let points = 0
	switch (difficulty) {
		case "easy":
			points = dpm?.find((obj) => obj.event === "winDuelEasy")?.points || 0
			break;
		case "medium":
			points = dpm?.find((obj) => obj.event === "winDuelMedium")?.points || 0
			break;
		case "hard":
			points = dpm?.find((obj) => obj.event === "winDuelHard")?.points || 0
			break;
		case "boss":
			points = dpm?.find((obj) => obj.event === "winDuelBoss")?.points || 0
			break;
		default:
			points = 0
			break;
	}
	return points;
}

export async function isUserInADuel(currentStatuses?: DuelStatus[]): Promise<boolean> {
	if (!currentStatuses) {
		currentStatuses = await getAllDuels();
	}
	if (
		currentStatuses.length === 0 ||
		currentStatuses.every((duel) => duel.complete === true)
	) {
		return false
	} else {
		return true
	}
}

export type TallyObject = {
	villainId: string;
	easy: boolean;
	medium: boolean;
	hard: boolean;
	boss: boolean;
	userId: string;
	heroName: string;
	villainName: string;
}

export async function fetchAllUserVillainTally() {
	let userId = await getUser_id();
	const query = new Parse.Query(USER_VILLAIN_TALLY_TABLE);
	query.equalTo('userId', userId);
	const parseObject = await query.find();
	let tally: TallyObject[] = []
	parseObject.forEach((obj: any) => {
		tally.push(obj.toJSON())
	})
	// log.debug("tally: ", tally)
	await storageService.setObject(userVillainTallyKey, tally);
	return tally;
}

export async function getUserVillainTally(): Promise<TallyObject[]> {
	let storedTally: TallyObject[] | null = await storageService.getObject(userVillainTallyKey);
	if (storedTally) {
		// fetchAllUserVillainTally();
		return storedTally;
	}
	let tally = await fetchAllUserVillainTally();
	return tally;
}

export async function getUserVillainTallyByVillainId(villainId: string) {
	// log.debug("Getting tally for villainId: ", villainId)
	let tally = await getUserVillainTally();
	let userTally = tally.find((tally) => tally.villainId === villainId);
	return userTally;
}

export function returnDifficultyText(difficulty: string) {
	switch (difficulty) {
		case "easy":
			return (
				"Your hero should be able to beat this villain with a little effort."
			)
		case "medium":
			return (
				"Your hero will need you to do some activities to beat this villain."
			)
		case "hard":
			return (
				"Your hero will need you to do some workouts and uses some skills to beat this villain."
			)
		case "boss":
			return (
				"Your hero will need you to do a lot of workouts and be smart with your skills to beat this villain!"
			)
		default:
			break;
	}
}

export function calcDifficultyColor(difficulty: string) {
	switch (difficulty) {
		case "easy":
			return "green";
		case "medium":
			return "blue";
		case "hard":
			return "orange";
		case "boss":
			return "red";
		default:
			return "gray";
	}
}

export function convertActivitiesRecordedStringToArray(activityString: string) {
	let activityArray = activityString.split(",");
	activityArray.forEach((activity, index) => {
		activityArray[index] = activity.trim();
	});
	return activityArray;
}

export enum Activity {
	Cardio = "cardio",
	Strength = "strength",
	Mobility = "mobility",
	Mindfulness = "mindfulness"
}

export enum Reason {
	LowHealth = "lowHealth",
	LowDamage = "lowDamage",
	LowDodge = "lowDodge",
	HighVillainHit = "highVillainHit",
	HitFrequently = "hitFrequently",
	HighDamage = "highDamage",
	LowHitChance = "lowHitChance",
	MissedFrequently = "missedFrequently",
	HighDodge = "highDodge"
}

export function getActivitySuggestion(activity: Activity): Suggestion {
	let selectedActivity = "";
	let randomReps = 0;
	let randomTime = 0; // Default time in case it's rep based
	let imageURL = "";
	let activityType = "";
	switch (activity) {
		case Activity.Cardio:
			selectedActivity = getRandomValueFromArray(cardioActivities)
			imageURL = 'https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/94cbbd18ecf32c8fb1906c460fd64f08_cardio-suggestion.webp'
			break;
		case Activity.Strength:
			selectedActivity = getRandomValueFromArray(strengthActivities)
			imageURL = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/a3c22c31b2bf898bb7e3463ddb48c738_strength-suggestion.webp"
			break;
		case Activity.Mobility:
			selectedActivity = getRandomValueFromArray(mobilityActivities)
			imageURL = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/5531a142d51436ceae6f505ec4239c9e_stretching%20suggestion.webp"
			break;
		case Activity.Mindfulness:
			selectedActivity = getRandomValueFromArray(mindfulnessExercises)
			imageURL = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/31a561df0c3c5121574603561612d1eb_mindfulness-suggestion.webp"
			break;
		default:
			return emptySuggestion;
	}
	// check if selectedActivity has "<reps>"
	if (selectedActivity.includes("<reps>")) {
		randomReps = Math.floor(Math.random() * 5) + 5; // Random number between 5 and 10
		activityType = "reps";
		selectedActivity = selectedActivity.replace("<reps>", randomReps.toString());
	}
	// check if selectedActivity has "<time>"
	if (selectedActivity.includes("<time>")) {
		randomTime = Math.floor(Math.random() * 1) + 1; // Random number between 1 and 2
		activityType = "time";
		selectedActivity = selectedActivity.replace("<time>", randomTime.toString());
	}
	return {
		suggestionDescription: selectedActivity,
		duration: randomTime,
		imageURL: imageURL,
		activity: selectedActivity,
		reps: randomReps,
		activityType: activityType,
		type: activity,
		heroMessage: ""
	}
}

export type Suggestion = {
	heroMessage: string;
	activity: string;
	suggestionDescription: string;
	duration: number;
	type: Activity | null;
	imageURL: string;
	reps: number;
	activityType: string; // should be either "reps" or "time"
}

export const emptySuggestion: Suggestion = {
	heroMessage: "",
	suggestionDescription: "",
	duration: 0,
	type: null,
	imageURL: "",
	activity: "",
	reps: 0,
	activityType: ""
}

export const noSuggestionNeeded: Suggestion = {
	heroMessage: "This duel with this villain is going well",
	suggestionDescription: "Keep up the good work!",
	duration: 0,
	type: null,
	imageURL: "",
	activity: "",
	reps: 0,
	activityType: ""
}
export const appreciateSuggestionCompleted: Suggestion = {
	heroMessage: "Thanks for helping me out with this duel, though it is not yet over.",
	suggestionDescription: "Much appreciated!",
	duration: 0,
	type: null,
	imageURL: "",
	activity: "",
	reps: 0,
	activityType: ""
}

export function getSuggestion(activity: Activity, reason: Reason, hero: HeroObject) {
	let suggestionObject: Suggestion = emptySuggestion
	let activitySuggestion = getActivitySuggestion(activity);
	suggestionObject.duration = activitySuggestion.duration;
	suggestionObject.imageURL = activitySuggestion.imageURL;
	const possessivePronoun = getPossessivePronoun(hero.gender!);
	const personalPronoun = getPersonalPronoun(hero.gender!);
	const thirdPersonPronoun = getThirdPersonPronoun(hero.gender!);
	switch (activity) {
		case Activity.Cardio:
			switch (reason) {
				case Reason.LowHealth:
					suggestionObject.heroMessage = "My health is low relative to the villain's.";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to improve " + possessivePronoun + " health!";
					break;
				default:
					suggestionObject.heroMessage = "My health is low.";
					suggestionObject.suggestionDescription = "I could use some cardio!";
					break;
			}
			break;
		case Activity.Strength:
			switch (reason) {
				case Reason.LowDamage:
					suggestionObject.heroMessage = "My damage is low relative to the villain's.";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to help to increase " + possessivePronoun + " damage!";
					break;
				case Reason.LowDodge: // You can group cases if the suggestion is the same
					suggestionObject.heroMessage = "My dodge is relatively low.";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to help to increase " + possessivePronoun + " damage!";
					break;
				default:
					suggestionObject.heroMessage = "My strength is low.";
					suggestionObject.suggestionDescription = personalPronoun + " could use a strength workout!";
					break;
			}
			break;
		case Activity.Mobility:
			switch (reason) {
				case Reason.HighVillainHit:
					suggestionObject.heroMessage = "This villain has a high accuracy. ";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to help " + thirdPersonPronoun + " dodge those hits!";
					break;
				case Reason.HitFrequently:
					suggestionObject.heroMessage = "The villain has been hitting me a lot. ";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to help " + thirdPersonPronoun + " dodge those hits!";
					break;
				case Reason.HighDamage:
					suggestionObject.heroMessage = "This villain has a high damage. ";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to help " + thirdPersonPronoun + " dodge those painful hits!";
					break;
				default:
					suggestionObject.heroMessage = "My dodge is low.";
					suggestionObject.suggestionDescription = personalPronoun + " could use some mobility workouts!";
					break;
			}
			break;
		case Activity.Mindfulness:
			switch (reason) {
				case Reason.LowHitChance:
					suggestionObject.heroMessage = "I'm not very accurate vs. this villain. ";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to increase " + possessivePronoun + " hit chance!";
					break;
				case Reason.MissedFrequently:
					suggestionObject.heroMessage = "I've been missing the villain a lot. ";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to increase " + possessivePronoun + " hit chance!";
					break;
				case Reason.HighDodge:
					suggestionObject.heroMessage = "This villain has a high dodge. ";
					suggestionObject.suggestionDescription = activitySuggestion.activity + " to help " + thirdPersonPronoun + " be more accurate!";
					break;
				default:
					suggestionObject.heroMessage = "My accuracy is low.";
					suggestionObject.suggestionDescription = personalPronoun + " could use some mindfulness activities!";
					break;
			}
			break;
		default:
			suggestionObject.heroMessage = "This villain is tough.";
			suggestionObject.suggestionDescription = personalPronoun + " could use some activities!";
			break;
	}
	return suggestionObject;
}

export async function determineHelpNeeded(duelStatus: DuelStatus, duelLedger: DuelLedger[], hero: HeroObject): Promise<Suggestion[]> {
	// if (duelLedger && duelLedger.length > 0 && duelLedger[0].heroOdds && duelLedger[0].heroOdds > 0.7) {
	// 	log.debug("[determineHelpNeeded] hero has high odds of winning, return appreciation message")
	// 	return [noSuggestionNeeded]
	// }
	let lastSuggestionCompleted = await storageService.getItem(LAST_SUGGESTION_COMPLETED_KEY);
	log.debug("[determineHelpNeeded] lastSuggestionCompleted: ", lastSuggestionCompleted)
	// if the last suggestion completed exists and it's been less than 8 hours since the last suggestion
	if (lastSuggestionCompleted && moment().isBefore(moment(lastSuggestionCompleted).add(8, 'hours'))) {
		log.debug("[determineHelpNeeded] last suggestion was completed less than 8 hours ago, returning empty suggestion")
		return [appreciateSuggestionCompleted];
	}
	// Types of help needed:
	// Hero low on health relative to villain -> cardio suggestion
	// Hero damage recently low -> strength suggestion
	// Hero hit frequently recently -> mobility suggestion
	// Hero missing frequently recently -> mindfulness suggestion
	// Maximum of 2 suggestions
	// Suggestions aren't always required
	const heroHitThreshold = 2;
	const villainHitThreshold = 4;
	const maxSuggestions = 1;
	const heroHitChance = duelStatus.heroToHit - duelStatus.villainDodge;
	const villainHitChance = duelStatus.villainToHit - duelStatus.heroDodge;
	const villainRelativeStats = calculateRelativeStats(duelStatus);
	let suggestions: Suggestion[] = [];
	let totalHeroHitsInLast5Turns = 0;
	let totalVillainHitsInLast5Turns = 0;
	let recentCardioActivity = false;
	let recentStrengthActivity = false;
	let recentMobilityActivity = false;
	let recentMindfulnessActivity = false;
	let cardioSuggested = false;
	let strengthSuggested = false;
	let mobilitySuggested = false;
	let mindfulnessSuggested = false;
	let recentLedger = duelLedger.slice(-5);

	recentLedger.forEach((entry) => {
		if (entry.heroHit === true) {
			totalHeroHitsInLast5Turns += 1;
		}
		if (entry.villainHit === true) {
			totalVillainHitsInLast5Turns += 1;
		}
		if (entry.activitiesRecorded) {
			let activityArray = convertActivitiesRecordedStringToArray(entry.activitiesRecorded);
			activityArray.forEach((activity) => {
				let attributeBreakdown = getAttributeBreakdownByActivity(activity)
				if (attributeBreakdown.cardioRate > 0) {
					recentCardioActivity = true;
				}
				if (attributeBreakdown.strengthRate > 0) {
					recentStrengthActivity = true;
				}
				if (attributeBreakdown.mobilityRate > 0) {
					recentMobilityActivity = true;
				}
				if (attributeBreakdown.mindfulRate > 0) {
					recentMindfulnessActivity = true;
				}
			})
		}
	});
	// if the hero has low health relative to the villain
	if (
		duelStatus.heroMaxHealth &&
		duelStatus.villainMaxHealth &&
		duelStatus.heroHealth < (duelStatus.heroMaxHealth / 4) &&
		duelStatus.villainHealth > (duelStatus.villainMaxHealth / 2) &&
		recentCardioActivity === false
	) {
		log.debug("[determineHelpNeeded] suggesting cardio because hero health is low relative to villain")
		suggestions.push(getSuggestion(Activity.Cardio, Reason.LowHealth, hero));
	}
	// if the hero has missed the villain frequently in the last 5 turns
	if (totalHeroHitsInLast5Turns <= heroHitThreshold &&
		recentLedger.length >= 5 &&
		recentMindfulnessActivity === false
	) {
		log.debug("[determineHelpNeeded] suggesting mindfulness because hero has missed frequently")
		suggestions.push(getSuggestion(Activity.Mindfulness, Reason.MissedFrequently, hero));
	}
	// if the heroHitChance is <= 35 suggest mindfulness
	if (heroHitChance <= 35 &&
		recentMindfulnessActivity === false
	) {
		log.debug("[determineHelpNeeded] suggesting mindfulness because hero hit chance is low")
		suggestions.push(getSuggestion(Activity.Mindfulness, Reason.LowHitChance, hero));
	}
	// if the villainHitChance is >= 65 suggest mobility
	if (villainHitChance >= 65 &&
		recentMobilityActivity === false
	) {
		log.debug("[determineHelpNeeded] suggesting mobility because villain hit chance is high")
		suggestions.push(getSuggestion(Activity.Mobility, Reason.HighVillainHit, hero));
	}
	// if the villain has high health and the hero's damage is low relative to the villains health
	if (
		duelStatus.villainMaxHealth &&
		duelStatus.villainHealth > (duelStatus.villainMaxHealth / 2) &&
		duelStatus.heroDamage < (duelStatus.villainHealth / 5)
	) {
		log.debug("[determineHelpNeeded] suggesting strength because hero damage is low relative to villain health")
		suggestions.push(getSuggestion(Activity.Strength, Reason.LowDamage, hero));
	}
	// if the hero has been hit frequently in the last 5 turns
	if (totalVillainHitsInLast5Turns >= villainHitThreshold &&
		recentLedger.length >= 5 &&
		recentMobilityActivity === false
	) {
		log.debug("[determineHelpNeeded] suggesting mobility because hero has been hit frequently")
		suggestions.push(getSuggestion(Activity.Mobility, Reason.HitFrequently, hero));
	}
	// if the villain has a high relative damage suggest mobility
	if (villainRelativeStats.villainDamageDescription === "high" &&
		recentMobilityActivity === false
	) {
		log.debug("[determineHelpNeeded] suggesting mobility because villain damage is high")
		suggestions.push(getSuggestion(Activity.Mobility, Reason.HighDamage, hero));
	}
	// if the villain has a high relative dodge suggest mindfulness
	if (villainRelativeStats.villainDodgeDescription === "high" &&
		recentMindfulnessActivity === false
	) {
		log.debug("[determineHelpNeeded] suggesting mindfulness because villain dodge is high")
		suggestions.push(getSuggestion(Activity.Mindfulness, Reason.HighDodge, hero));
	}
	// if the villain has a low relative dodge suggest strength
	if (villainRelativeStats.villainDodgeDescription === "low" &&
		recentStrengthActivity === false
	) {
		log.debug("[determineHelpNeeded] suggesting strength because villain dodge is low")
		suggestions.push(getSuggestion(Activity.Strength, Reason.LowDodge, hero));
	}
	log.debug("suggestions: ", suggestions)
	let suggestion: Suggestion[] = []
	suggestion.push(getRandomValueFromArray(suggestions))
	return suggestion;
}