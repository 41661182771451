import { IonIcon, IonLoading, IonModal } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import "../../css/Skills.css";
import { DuelStatus } from '../../models/duelsModels';
import { Skill, SkillInUse, SkillModalType, genericIcon } from '../../models/skills';
import { fetchDuelsLedger } from '../../services/DuelsServics';
import { getAllOwnedSkills, getAllSkills, getSkillsInUse, loadSkillsInUse } from '../../services/SkillsService';
import SkillActivatedModal from './SkillActivatedModal';
import UseSkillModal from './UseSkillModal';
import SkillIcon from './SkillIcon';
import { informationCircleOutline } from 'ionicons/icons';
import { GenericModal } from '../../components/GenericModal';
import log from 'loglevel';
import GlobalState from '../../contexts/GlobalState';


const UseSkillBar = (props: { duelStatus: DuelStatus }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { ownedSkills, setOwnedSkills } = globalState;
    const [groupedSkills, setGroupedSkills] = useState<Skill[][]>([]);
    const [showSkillModal, setShowSkillModal] = useState<SkillModalType>({ show: false, skill: null });
    const [showSkillActivatedModal, setShowSkillActivatedModal] = useState<SkillModalType>({ show: false, skill: null });
    const [skillsInUse, setSkillsInUse] = useState<SkillInUse[]>([]);
    const [updating, setUpdating] = useState(false)
    const [skillUsed, setSkillUsed] = useState(false)
    const [skillsEnabled, setSkillsEnabled] = useState<Skill[]>([])
    const [showSkillsInfoModal, setShowSkillsInfoModal] = useState(false)

    useEffect(() => {
        getSkills()
        getOwnedSkills()
    }, []);

    useEffect(() => {
        getUsedSkills()
    }, [showSkillModal]);

    async function getSkills() {
        let allSkills = props.duelStatus.skillsEnabled
        if (!allSkills || allSkills.length === 0) {
            allSkills = await getAllSkills(true);
        } else {
            // remove any null elements from the array
            allSkills = allSkills.filter(skill => skill !== null)
        }
        setSkillsEnabled(allSkills)
        log.debug("All Skills: ", allSkills)
        const groupedSkills: Skill[][] = allSkills.reduce<Skill[][]>((acc, skill, index, array) => {
            // If it's the first skill or the tree of the current skill is different from the previous one, start a new group
            if (index === 0 || skill.tree !== array[index - 1].tree) {
                acc.push([]);
            }
            // Add the current skill to the last group
            acc[acc.length - 1].push(skill);
            return acc;
        }, []);
        setGroupedSkills(groupedSkills);
    }

    async function getOwnedSkills() {
        const ownedSkills = await getAllOwnedSkills();
        setOwnedSkills(ownedSkills);
    }

    async function getUsedSkills() {
        let skillsInUseObject = await loadSkillsInUse(props.duelStatus.statusId);
        log.debug("Skills In Use Object: ", skillsInUseObject)
        setSkillsInUse(skillsInUseObject.skillsInUse);
        if (skillsInUseObject.skillsRecentlyUsed.length > 0) {
            setSkillUsed(true)
        }
        // // setUpdating(true)
        // let skillsInUse = await getSkillsInUse();
        // setSkillsInUse(skillsInUse);
        // // Find the skill in skillsInUse with the most recent createdAt date
        // if (skillsInUse.length > 0) {
        //     let mostRecentSkill = skillsInUse.reduce((prevSkill: SkillInUse, currentSkill: SkillInUse) => {
        //         if (!prevSkill || new Date(currentSkill.createdAt) > new Date(prevSkill.createdAt)) {
        //             return currentSkill;
        //         } else {
        //             return prevSkill;
        //         }
        //     });
        //     let duelLedger = await fetchDuelsLedger(props.duelStatus.statusId)
        //     // log.debug("Duel Ledger: ",duelLedger)
        //     if (duelLedger.length > 0) {
        //         let lastDuelTickTime = duelLedger[0].createdAt
        //         log.debug("Last Duel Tick Time: ", lastDuelTickTime)
        //         log.debug("Most Recent Skill Time: ", mostRecentSkill.createdAt)
        //         if (mostRecentSkill && new Date(mostRecentSkill.createdAt) > new Date(lastDuelTickTime)) {
        //             setSkillUsed(true)
        //         }
        //     } else {
        //         setSkillUsed(true)

        //     }
        // }
        // // setUpdating(false)
    }

    return (
        <>
            <IonLoading isOpen={updating} message={'Updating...'} backdropDismiss={true} />
            <IonModal id="use-skill-modal" isOpen={showSkillModal.show} onWillDismiss={() => setShowSkillModal({ show: false, skill: null })}>
                <UseSkillModal skillDisabled={showSkillModal.skillDisabled} skill={showSkillModal.skill!} setShowSkillActivatedModal={setShowSkillActivatedModal} setShowSkillModal={setShowSkillModal} skillInUse={showSkillModal.skillInUse} skillUsed={skillUsed} />
            </IonModal>
            <IonModal id="use-skill-modal" isOpen={showSkillActivatedModal.show} onWillDismiss={() => setShowSkillActivatedModal({ show: false, skill: null })}>
                <SkillActivatedModal skill={showSkillActivatedModal.skill!} setShowSkillActivatedModal={setShowSkillActivatedModal} />
            </IonModal>
            <IonModal className="villain-difficulty-modal" isOpen={showSkillsInfoModal} onDidDismiss={() => setShowSkillsInfoModal(false)}>
                <GenericModal title="Skills" body={[
                    "Each skill has a cooldown period, which is the amount of time you have to wait before you can use the skill again.",
                    "The cooldown period for each skill is different.",
                    "After each duel your skills will be reset and you can use them again."
                ]} />
            </IonModal>
            {/* groupedSkills && ownedSkills && skillsInUse && (<>
                <div>
                    <div className='skill-header' onClick={() => setShowSkillsInfoModal(true)}>
                        Skills Enabled <IonIcon color="white" icon={informationCircleOutline} />
                    </div>
                    {groupedSkills.map((group, groupIndex) => {
                        let groupName = group[0].tree.charAt(0).toUpperCase() + group[0].tree.slice(1);
                        return (
                            <>
                                <div key={Math.random()} className="skill-group-header">{groupName} Skills</div>
                                <div key={groupIndex} className="skill-row-v2">
                                    {group.map((skill) => {
                                        let icon = skill.icon || genericIcon;
                                        let skillOnCoolDown = skillsInUse.find(skillInUse => skillInUse.skillId === skill.objectId);
                                        let skillDisabled = !ownedSkills.find(ownedSkill => ownedSkill.objectId === skill.objectId);
                                        if (skillDisabled && skill.level < 20) {
                                            return (
                                                <div key={skill.objectId} className="skill-icon-container-inner" onClick={() => setShowSkillModal({ show: true, skill: skill, skillDisabled: true })}>
                                                    <img src={icon.url} alt={skill.name} className="skill-icon" />
                                                    <div className="skill-icon-overlay"></div>
                                                </div>
                                            );
                                        } else if (!skillDisabled) {
                                            return (
                                                <div key={skill.objectId} className="skill-icon-container-inner" onClick={() => setShowSkillModal({ show: true, skill: skill, skillInUse: skillOnCoolDown })}>
                                                    <img src={icon.url} alt={skill.name} className="skill-icon" />
                                                    {skillOnCoolDown && <div className="skill-icon-overlay"><SkillIcon cooldown={skillOnCoolDown.cooldown} maxCooldown={skill.cooldown} /></div>}
                                                    {skillDisabled && <div className="skill-icon-overlay"></div>}
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </>

                        )
                    }
                    )}
                </div>
            </>
            )*/}

            {skillsEnabled && skillsInUse && (<>
                {/* <div className="skill-icon-container-outer"> */}
                <div style={{ marginTop: "30px" }}>
                    <div className='skill-header' onClick={() => setShowSkillsInfoModal(true)}>
                        Skills Enabled <IonIcon color="white" icon={informationCircleOutline} />
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap" }}>
                        {skillsEnabled.map((skill) => {
                            let icon = skill.icon || genericIcon;
                            let skillOnCoolDown = skillsInUse.find(skillInUse => skillInUse.skillId === skill.objectId);
                            let skillDisabled = !ownedSkills.find(ownedSkill => ownedSkill.objectId === skill.objectId);
                            if (skillDisabled && skill.level < 20) {
                                return (
                                    <div key={skill.objectId} className="skill-icon-container-inner" onClick={() => setShowSkillModal({ show: true, skill: skill, skillDisabled: true })}>
                                        <img src={icon.url} alt={skill.name} className="skill-icon" />
                                        <div className="skill-icon-overlay"></div>
                                    </div>
                                );
                            } else if (!skillDisabled) {
                                return (
                                    <div key={skill.objectId} className="skill-icon-container-inner" onClick={() => setShowSkillModal({ show: true, skill: skill, skillInUse: skillOnCoolDown })}>
                                        <img src={icon.url} alt={skill.name} className="skill-icon" />
                                        {skillOnCoolDown && <div className="skill-icon-overlay"><SkillIcon cooldown={skillOnCoolDown.cooldown} maxCooldown={skill.cooldown} /></div>}
                                        {skillDisabled && <div className="skill-icon-overlay"></div>}
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </>
            )}

        </>
    )
}

export default UseSkillBar

const SkillExplanation = () => {
    return (
        <div className="villain-difficulty-modal-div">
            <div>
                <span className="villian-header-2" style={{ fontSize: "30px" }}>Skills</span><br />
                <p>Each skill has a cooldown period, which is the amount of time you have to wait before you can use the skill again.</p>
                <p>The cooldown period for each skill is different.</p>
                <p>After each duel your skills will be reset and you can use them again.</p>
            </div>
        </div>
    )
}