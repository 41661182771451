export const villainsObjectKey = "villains";

export type Villain = {
    _id?: string;
    villainID: string;
    createdAt: string;
    updatedAt: string;
    name: string;
    imageURL: string;
    villainImage?: {
        name: string;
        url: string;
        __type: string;
    };
    combatPrompt?: string;
    generalPrompt?: string;
    toHit: number;
    damage: number;
    dodge: number;
    health: number;
    languageLevel: number;
    description: string;
    promptPrefix?: string;
    promptSuffix?: string;
    cardio: number;
    strength: number;
    mobility: number;
    mindfulness: number;
    level: number;
    defeated?: boolean;
    defeatedDate?: string;
    record?: {
        wins: number;
        losses: number;
    };
    strengthImp: string;
    cardioImp: string;
    mobilityImp: string;
    mindfulnessImp: string;
    boss: boolean;
    startHour: number;
    endHour: number;
};

