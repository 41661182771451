/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { IonAvatar, IonButton, IonContent, IonFooter, IonInput, IonLoading, IonPage, IonSpinner } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import HeaderLogo from "../../components/HeaderLogo";
import GlobalState from "../../contexts/GlobalState";
import { HeroObject, OnboardingObject, UserObject, userIDKey, userObjectKey } from "../../models/userModel";
import { StorageService } from "../../services/StorageService";
import { createHero, createUser, createUserNoEmailNoPass, updateUser } from "../../services/UserService";
import { logGenericEvent, mixpanelIdentifyUser, setProperty } from "../../services/AnalyticsService";
import log from "loglevel";
import { sendSlackErrorNotification, sendSlackNotification } from "../../services/NotificationService";
import { Device } from "@capacitor/device";
import { Keyboard } from "@capacitor/keyboard";

const NameHero = () => {
	const nextPage = "/onboarding/nameuser";
	const history = useHistory();
	const storageService = new StorageService();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { setHeroObject, setPreviousHeroObject, isMobile } = globalState;
	const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
	const [heroName, setHeroName] = useState<string>();
	const [savingHero, setSavingHero] = useState<boolean>(false);
	const [showFooter, setShowFooter] = useState<boolean>(true);


	const heroNameRef = useRef<any>();

	useEffect(() => {
		updateOnboardingObject();
	}, []);

	const [heroNameError, setHeroNameError] = useState({
		visible: false,
		message: "",
	});

	async function updateOnboardingObject() {
		let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding"); // Should this just include the full heroObject?
		tempOnboardingObject.url = window.location.pathname;
		tempOnboardingObject.completed = false;
		setOnboardingObject(tempOnboardingObject);
		await storageService.setObject("onboarding", tempOnboardingObject);
	}

	const validateHeroName = (heroName: string) => {
		if (!heroName || heroName.length < 3) {
			setHeroNameError({
				visible: true,
				message: "Hero names should be at least 3 characters",
			});
			return false;
		}
		if (heroName.length > 16) {
			setHeroNameError({
				visible: true,
				message: "Please keep your hero's name under 16 characters",
			});
			return false;
		}
		if (/[\.,\/#!$%\^&\*;:{}=\-_`~()?]/g.test(heroName)) {
			setHeroNameError({
				visible: true,
				message: "No punctuation please",
			});
			return false;
		}
		setHeroNameError({
			visible: false,
			message: "",
		});
		return true;
	};

	if (isMobile !== false) {
		// get user's platform
		Device.getInfo().then((device) => {
			if (device.platform !== "android") {
				Keyboard.addListener("keyboardWillShow", (info) => {
					setShowFooter(false);
					document.body.classList.add("keyboard-is-open");
					document.documentElement.style.setProperty("--keyboard-height", -info.keyboardHeight + "px");
				}).catch((err) => {
					// log.debug("Keyboard Error: ", err);
				});
				Keyboard.addListener("keyboardDidShow", (info) => {
					document?.activeElement?.scrollIntoView({ behavior: "smooth" });
					document.documentElement.style.setProperty("--keyboard-height", -info.keyboardHeight + "px");
				}).catch((err) => {
					// log.debug("Keyboard Error: ", err);
				});

				Keyboard.addListener("keyboardDidHide", () => {
					setShowFooter(true);
				}).catch((err) => { });
			} else {
				console.log("[Keyboard test] Android device");
			}
		});
	}

	async function saveHero() {
		let validName = validateHeroName(heroNameRef?.current?.value);
		if (validName === true) {
			setHeroName(heroNameRef?.current?.value);
			setSavingHero(true);
			let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
			if (tempOnboardingObject.heroPicture) {
				let hero: HeroObject | null = await createHero(heroNameRef?.current?.value, tempOnboardingObject.heroPicture);
				setProperty("heroID", hero?.heroID);
				setProperty("heroName", hero?.heroName);
				setProperty("heroPicture", hero?.heroImageURL);
				if (hero?.heroID) {
					let heroObject: HeroObject = {
						...hero,
						strengthLevel: hero.strengthLevel,
						strengthPoints: hero.strengthPoints,
						cardioLevel: hero.cardioLevel,
						cardioPoints: hero.cardioPoints,
						mindfulnessLevel: hero.mindfulnessLevel,
						mindfulnessPoints: hero.mindfulnessPoints,
						mobilityLevel: hero.mobilityLevel,
						mobilityPoints: hero.mobilityPoints,
						overallLevel: hero.overallLevel,
						totalPoints: hero.totalPoints,
					};
					setHeroObject(heroObject);
					setPreviousHeroObject(heroObject);
					tempOnboardingObject.heroName = heroNameRef?.current?.value;
					tempOnboardingObject.hero = heroObject;
					tempOnboardingObject.heroPicture = hero?.heroImageURL;
					await storageService.setObject("onboarding", tempOnboardingObject);
					// await updateUser({ heroID: hero.heroID });
					await createUserNoEmailNoPass(hero.heroID)
						.then(async (user: UserObject) => {
							log.debug("User returned by createUser: ", user);
							await storageService.setObject(userObjectKey, user);
							await storageService.setItem(userIDKey, user.userID);
							await mixpanelIdentifyUser(user);
							logGenericEvent("User created");
							log.debug("User saved to storage");
							// await updateUserState();
							let host = window.location.hostname;
							let device = await Device.getInfo();
							if (!host.includes("localhost") && device.platform !== "web") {
								sendSlackNotification("*New FIT HERO user created*\nHero: " + JSON.stringify(hero) + "\nUser: " + JSON.stringify(user), "creation");
							}
							setSavingHero(false);
							history.push(nextPage);
						})
						.catch((error) => {
							sendSlackErrorNotification("Error: User not created: " + error, "[NameHero]");
							alert("Error: User not created: " + error);
							setSavingHero(false);
							throw new Error("Error: User not created: " + error);
						});
				}
			} else {
				setSavingHero(false);
				console.error("Error: Hero name or picture not found in onboarding object");
				alert("Error: Hero name or picture not found");
			}
		}
	}
	return (
		<IonPage>
			<HeaderLogo checkforUser={false} />
			<IonContent>
				<IonLoading isOpen={savingHero} message={"Creating your FitHero..."} backdropDismiss />
				<div className="onboarding-main-div">
					<div className="onboarding-title">What would you like to name your FitHero?</div>
				</div>
				{onboardingObject?.heroPicture ? (
					<>
						<IonAvatar style={{ margin: "auto", height: "250px", width: "250px" }}>
							<img
								className="user-avatar"
								style={{ width: "100%", height: "100%", objectFit: "cover" }}
								src={onboardingObject?.heroPicture ?? "/assets/images/mystery-avatar.png"}
								alt="your fithero avatar"
							/>
						</IonAvatar>
						<div className="onboarding-username-box-div" style={{ marginTop: "50px" }}>
							{heroName ? (
								<IonInput
									className="onboarding-username-box"
									ref={heroNameRef}
									name="heroName"
									value={heroName}
									type="text"
									enterkeyhint="next"
									placeholder="Your hero's name"
									onFocus={() => setHeroNameError({ visible: false, message: "" })}
									required
								></IonInput>
							) : (
								<IonInput
									className="onboarding-username-box"
									ref={heroNameRef}
									name="heroName"
									type={"text"}
									enterkeyhint="next"
									onFocus={() => setHeroNameError({ visible: false, message: "" })}
									required
								></IonInput>
							)}
							{heroNameError.visible === true ? <div className="onboarding-error-message">{heroNameError.message}</div> : ""}
						</div>
						{showFooter === true && (
							<IonFooter className="onboarding-footer">
								<IonButton className="onboarding-advance-button" onClick={() => saveHero()}>
									Save
								</IonButton>
							</IonFooter>
						)}
					</>
				) : (
					<IonSpinner name="crescent" style={{ margin: "auto" }} />
				)}
			</IonContent>
		</IonPage>
	);
};

export default NameHero;
