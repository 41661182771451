import { useContext } from 'react'
import GlobalState from '../../contexts/GlobalState'

export const UserAvatarAnimated = (props: { avatarImage: string }) => {
    return (
        <>
            <div>
                <div className="avatar-image-wrapper">
                    <img src={props.avatarImage ?? "/assets/images/mystery-avatar.png"} alt="" />
                </div>
                <div className='avatar-powerup-badge fadeIn'>
                    ⚡️
                </div>
            </div>
        </>
    )
}
