/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonLoading, IonModal, IonRow, IonSelect, IonSelectOption, IonSpinner, IonToolbar } from "@ionic/react";
import { informationCircleOutline, scale } from "ionicons/icons";
import log from "loglevel";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GlobalState from "../../../contexts/GlobalState";
import { DuelStatus } from "../../../models/duelsModels";
import { OnboardingObject } from "../../../models/userModel";
import { Villain } from "../../../models/villainModels";
import { logGenericEventWithObject } from "../../../services/AnalyticsService";
import { OddsPredictionNumber, calcDifficultyColor, calcDuelPointsFromDifficulty, calculateDuelSuccessPercent, isUserInADuel, returnDifficultyText, startDuel } from "../../../services/DuelsServics";
import { formatTimeHours } from "../../../services/HelperService";
import { getVillain, scaleVillain, scaleVillainServer } from "../../../services/VillainServices";
import VillainAttributesCircularComponent from "./VillainAttributesCircularComponent";
import { VillainWeakness } from "./VillainWeakness";
import { VillainHoursComponent } from "./VillainHoursComponent";
import { PayWallClassDojoComponent } from "../../../Paywall/components/PayWallClassDojoComponent";
import "../../../css/Duels.css";
import "../../../css/PayWall.css";
import { TourComponent } from "../../Tour/StepsComponent";
import { VillainProfileSteps } from "../../Tour/Steps";
import { SelectSkillsForDuelComponent } from "../../Duels/components/SelectSkillsForDuelComponent";
import { Skill } from "../../../models/skills";



const VillainProfile = (props: {
	duelStatus?: DuelStatus;
	alreadyScaled?: boolean;
	villain?: Villain | undefined;
	villainID?: string | undefined;
	// onboardingObject?: OnboardingObject | undefined;
	inOnboarding?: boolean;
	showStartDuelButton?: boolean;
	setShowModal: React.Dispatch<React.SetStateAction<any>>;
	villainGroupInstanceId?: string;
	difficulty?: string;
	fromGym?: boolean;
	boss?: boolean;
}) => {
	// onboardingObject is only passed if the user is in the onboarding process
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);
	const [scaling, setScaling] = useState(false);
	const [likelyWinner, setLikelyWinner] = useState<number>();
	const [villain, setVillain] = useState<Villain | undefined>(props.villain);
	const [difficultyColor, setDifficultyColor] = useState("");
	const [showDifficultyModal, setShowDifficultyModal] = useState(false)
	const [showDurationModal, setShowDurationModal] = useState(false)
	const [showDuelPointsModal, setShowDuelPointsModal] = useState(false)
	const [userInDuel, setUserInDuel] = useState(false);
	const [selectedDifficulty, setSelectedDifficulty] = useState<string>();
	const [duelPoints, setDuelPoints] = useState<number>();
	const [showPaywall, setShowPaywall] = useState(false);
	const [skillsToUse, setSkillsToUse] = useState<Skill[]>([]);
	const [maxSkillsUsable, setMaxSkillsUsable] = useState<number>(0);


	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { updateUserState, paywallEnabled, currentlySubscribed } = globalState;

	useEffect(() => {
		setupData();
		calcDifficultyColor("");
		checkForCurrentDuel();
	}, []);

	useEffect(() => {
		setVillainDifficulty();
	}, [selectedDifficulty])

	useEffect(() => {
		log.debug("[VillainProfile] Skills to use: ", skillsToUse);
	}, [skillsToUse])

	async function setupData() {
		let tempVillain = props.villain;
		if (!tempVillain && props.villainID) {
			tempVillain = await getVillain(props.villainID!);
		}
		logGenericEventWithObject(`view villain profile - ${tempVillain?.name}`, tempVillain!);
		if (tempVillain && tempVillain.level === 1) {
			log.debug("[VillainProfile] Villain is level 1, don't scale");
			setSelectedDifficulty("easy");
		}
		if (props.inOnboarding === true) {
			log.debug("[VillainProfile] In onboarding, setting difficulty to easy");
			setLikelyWinner(1); // doesn't matter because we're not showing the duration
		}
		if (props.alreadyScaled === true && tempVillain && !props.inOnboarding) {
			if (props.duelStatus) {
				// If duelStatus is passed in, use that to set the villain stats
				log.debug("[VillainProfile] Villain already scaled");
				tempVillain.strength = props.duelStatus.villainStrength ? props.duelStatus.villainStrength : tempVillain.strength;
				tempVillain.cardio = props.duelStatus.villainCardio ? props.duelStatus.villainCardio : tempVillain.cardio;
				tempVillain.mobility = props.duelStatus.villainMobility ? props.duelStatus.villainMobility : tempVillain.mobility;
				tempVillain.mindfulness = props.duelStatus.villainMindfulness ? props.duelStatus.villainMindfulness : tempVillain.mindfulness;
				tempVillain.toHit = props.duelStatus.villainToHit ? props.duelStatus.villainToHit : tempVillain.toHit;
				tempVillain.dodge = props.duelStatus.villainDodge ? props.duelStatus.villainDodge : tempVillain.dodge;
				tempVillain.damage = props.duelStatus.villainDamage ? props.duelStatus.villainDamage : tempVillain.damage;
				tempVillain.health = props.duelStatus.villainHealth ? props.duelStatus.villainHealth : tempVillain.health;
				setSelectedDifficulty(props.duelStatus.difficulty ? props.duelStatus.difficulty : "medium");
			} else {
				// If duelStatus is not passed in, it's in a group and do nothing
				calculateDuelSuccessPercent(tempVillain!).then((result) => {
					setLikelyWinner(result.expectedDuration);
				});
			}
		}
		if (props.difficulty === "boss") setSelectedDifficulty("boss");
		setVillain(tempVillain);
	}

	async function checkForCurrentDuel() {
		setUserInDuel(await isUserInADuel());
	}

	async function setVillainDifficulty() {
		let difficulty = props.difficulty === "boss" ? "boss" : selectedDifficulty;
		if (difficulty) {
			setScaling(true)
			setLikelyWinner(undefined);
			setSelectedDifficulty(difficulty);
			calcDifficultyColor("");
			log.debug("[VillainProfile] setting villain to: ", difficulty);
			// let villainAndOdds = await scaleVillain(props.villain!, difficulty!);
			let villainAndOdds: {
				scaledVillain: Villain,
				oddsObj: OddsPredictionNumber
			}
			let tempVillain: Villain;
			if (props.villain && props.villain.level === 1) {
				// basicaly just for timid tom
				log.debug("[VillainProfile] Villain is level 1, don't scale");
				tempVillain = props.villain;
				villainAndOdds = {
					scaledVillain: tempVillain,
					oddsObj: {
						expectedDuration: 24,
						heroOdds: 60
					}
				}
			} else {
				// Scaling villain
				log.debug("[VillainProfile] Scaling villain");
				villainAndOdds = await scaleVillainServer(props.villain!, difficulty!);
				tempVillain = villainAndOdds.scaledVillain;
			}
			setLikelyWinner(villainAndOdds.oddsObj.expectedDuration)
			setVillain(tempVillain);
			calcDifficultyColor(difficulty);
			calcDuelPoints(difficulty);
			setScaling(false)
		}
	}

	async function startNewDuel() {
		setLoading(true);
		await startDuel(villain!, selectedDifficulty!, props.villainGroupInstanceId, skillsToUse)
			.then(async (result: DuelStatus | undefined) => {
				if (result) {
					setLoading(false);
					history.push(`/dueldetails/${result.statusId}/status`);
				} else {
					alert("Error starting new duel:\n " + result);
				}
			})
			.catch((error) => {
				alert("Error starting new duel:\n " + error);
			});
		setLoading(false);
	}

	async function calcDuelPoints(difficulty: string = selectedDifficulty || "") {
		setDuelPoints(await calcDuelPointsFromDifficulty(difficulty))
	}

	const changeDifficulty = (e: any) => {
		setSelectedDifficulty(e.detail.value);
	};

	const BottomButton = () => {
		if (props.fromGym === true && (props.difficulty === 'boss' || selectedDifficulty === 'boss')) {
			return (
				<IonFooter className="start-duel-footer">
					<div style={{ height: "75px", background: "darkred", fontSize: "24px", width: "100%", display: "flex" }}>
						<div style={{ width: "80%", margin: "auto" }}>Bosses can only be dueled <br />via the progression path</div>
					</div>
				</IonFooter>
			)
		}
		if (userInDuel) {
			return (
				<IonFooter className="start-duel-footer">
					<div style={{ height: "75px", background: "darkred", fontSize: "24px", width: "100%", display: "flex" }}>
						<div style={{ width: "80%", margin: "auto" }}>Complete your current duel <br />before starting a new one</div>
					</div>
				</IonFooter>
			)
		}
		if (props.showStartDuelButton !== false && selectedDifficulty && skillsToUse.filter((skill) => skill !== undefined).length === maxSkillsUsable) {
			return (
				<IonFooter className="start-duel-footer">
					<IonButton onClick={() => checkForPaywall()} color={"tertiary"} expand="full" className="start-duel-button">
						Duel {villain?.name}
					</IonButton>
				</IonFooter>
			)

		}
		if (props.showStartDuelButton === false) {
			return (
				<IonFooter className="start-duel-footer">
					<div style={{ height: "75px", background: "darkred", fontSize: "24px", width: "100%", display: "flex" }}>
						<div style={{ width: "80%", margin: "auto" }}>Beat earlier villains in the group <br />to unlock this one!</div>
					</div>
				</IonFooter>
			)
		}
		if (!selectedDifficulty && props.showStartDuelButton === true && props.difficulty !== "boss") {
			return (
				<IonFooter className="start-duel-footer">
					<div style={{ height: "75px", background: "darkred", fontSize: "24px", width: "100%", display: "flex" }}>
						<div style={{ width: "80%", margin: "auto" }}>Select a difficulty to <br />start the duel</div>
					</div>
				</IonFooter>
			)
		}
		if (skillsToUse.length < maxSkillsUsable) {
			return (
				<IonFooter className="start-duel-footer">
					<div style={{ height: "75px", background: "darkred", fontSize: "24px", width: "100%", display: "flex" }}>
						<div style={{ width: "80%", margin: "auto" }}>Select more skills to <br />start the duel</div>
					</div>
				</IonFooter>
			)
		}
		return (<></>)
	}

	async function checkForPaywall() {
		console.log("[VillainProfile] Currently subscribed: ", currentlySubscribed)
		if (paywallEnabled && paywallEnabled === true && currentlySubscribed === false && props.difficulty === "boss") {
			setShowPaywall(true);
		} else {
			setShowConfirmation(true);
		}
	}

	return (
		<>
			{props.villain && (
				<>
					<IonHeader style={{ "--background": "var(--ion-background-color, #fff)", "--opacity": "1.0" }}>
						<IonToolbar>
							<IonButtons slot="end">
								<IonButton strong={true} onClick={() => props.setShowModal({ show: false })}>
									X
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonLoading isOpen={loading} message={"Starting Duel..."} />
					<IonAlert
						isOpen={showConfirmation}
						header="Are your sure?"
						message="Start Duel against this villain?"
						buttons={[
							{
								text: "No",
								role: "cancel",
								handler: () => {
									setShowConfirmation(false);
								},
							},
							{
								text: "Yes, let's go!",
								role: "ok",
								handler: () => {
									startNewDuel();
								},
							},
						]}
					/>
					<IonModal className="villain-difficulty-modal" isOpen={showDifficultyModal} onDidDismiss={() => setShowDifficultyModal(false)}>
						<div className="villain-difficulty-modal-div">
							<div className="villian-difficulty-box" style={{ background: `${difficultyColor}` }}>
								<span className="villian-header-2">difficulty</span><br />{selectedDifficulty}
							</div>
							{returnDifficultyText(selectedDifficulty!)}
						</div>
					</IonModal>
					<IonModal className="paywall-modal" isOpen={showPaywall} onDidDismiss={() => setShowPaywall(false)}>
						<PayWallClassDojoComponent modal={true} setShowPaywall={setShowPaywall} />
					</IonModal>
					<IonModal className="villain-difficulty-modal" isOpen={showDuelPointsModal} onDidDismiss={() => setShowDuelPointsModal(false)}>
						<div className="villain-difficulty-modal-div">
							<div className="villian-difficulty-box" style={{ background: `${difficultyColor}` }}>
								<span className="villian-header-2">Duel Points</span><br />{duelPoints}
							</div>
							How many duel points you will earn for beating this villain.<br /><br />Duel points are used to calculate the leaderboard.
						</div>
					</IonModal>
					<IonModal className="villain-difficulty-modal" isOpen={showDurationModal} onDidDismiss={() => setShowDurationModal(false)}>
						<div className="villain-difficulty-modal-div">
							<div className="villian-difficulty-box" style={{ background: `${difficultyColor}` }}>
								<span className="villian-header-2">duel duration</span><br />~{formatTimeHours(likelyWinner!)}
							</div>
							Approximately how long your duel with this villain will take.
						</div>
					</IonModal>
					<IonContent>
						<div className="villian-profile-image">
							<img className="villain-avatar" style={{ border: `10px solid ${difficultyColor}` }} src={props.villain?.imageURL} />
						</div>
						<div className="villian-profile-name">{props.villain?.name}</div>
						<div onClick={() => setShowDifficultyModal(true)} className="villian-profile-name" style={{ display: "block" }}>Level {likelyWinner ? villain?.level : scaling ? <IonSpinner /> : "?"}<IonIcon icon={informationCircleOutline} style={{ fontSize: "12px" }} /></div>

						{villain && (
							<>
								{/* <VillainHoursComponent villain={villain} /> */}
								{props.difficulty === "boss" && !props.inOnboarding && (
									<>
										<div className="villain-level-div">
											<div className="select-difficulty" >
												<span className="villian-header-2">{props.difficulty}</span>
											</div>
										</div>
									</>
								)}
								{props.difficulty !== "boss" && !props.inOnboarding && (
									<>
										<div className="villain-level-div">
											<div className="select-difficulty" >
												<IonSelect value={selectedDifficulty} onIonChange={changeDifficulty} placeholder="Select Difficulty">
													{difficultyOptions.map((difficulty, index) => (
														<IonSelectOption key={index} value={difficulty}>
															{difficulty}
														</IonSelectOption>
													))}
												</IonSelect>

											</div>
										</div>
									</>
								)}
								{!props.inOnboarding && (
									<>
										<div className="villain-level-div"></div>
										<div className="villain-prediction-div" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
											<div className="villain-boxes" onClick={() => setShowDuelPointsModal(true)} style={{ borderColor: `${difficultyColor}`, borderWidth: "3px", borderStyle: "solid" }}>
												<span className="villian-header-2">Duel Points <IonIcon icon={informationCircleOutline} style={{ fontSize: "12px" }} /> </span><br />{likelyWinner ? duelPoints : scaling ? <IonSpinner /> : "?"}
											</div>
											<div className="villain-boxes" onClick={() => setShowDurationModal(true)} style={{ borderColor: `white`, borderWidth: "2px", borderStyle: "solid" }}>
												<span className="villian-header-2">Duration</span><br />~{likelyWinner ? formatTimeHours(likelyWinner!) : scaling ? <IonSpinner /> : "?"}
											</div>
										</div>
									</>
								)}
								<div>
									<SelectSkillsForDuelComponent setSkillsToUse={setSkillsToUse} skillsToUse={skillsToUse} setMaxSkillsUsable={setMaxSkillsUsable} />
								</div>
								<div>
									<VillainAttributesCircularComponent villain={villain} showLoading={likelyWinner ? false : true} />{" "}
								</div>
							</>
						)}
						<div>
							<VillainWeakness villain={props.villain} setShowPaywall={setShowPaywall} />
						</div>

						<div>
							<LanguageLevelBar villain={props.villain} />
						</div>
						<div>
							<VillainDescription villain={props.villain} />
						</div>
					</IonContent>
					<BottomButton />
					<TourComponent coachMarkName="villainProfile" steps={VillainProfileSteps} modalShowing={false} action={() => checkForPaywall()} />
				</>
			)}
		</>
	);
};

export default VillainProfile;

const VillainDescription = (props: { villain: Villain }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	if (!props.villain?.description) {
		return <></>;
	}
	const descriptionLines = props.villain?.description.split("\\n");
	const displayLines = isExpanded ? descriptionLines : [descriptionLines[0]];

	return (
		<div className="villain-profile-description">
			{displayLines.map((line, index) => (
				<React.Fragment key={index}>
					{line}
					<br />
				</React.Fragment>
			))}
			{descriptionLines.length > 1 &&
				(!isExpanded ? (
					<div className="read-more" onClick={() => setIsExpanded(true)}>
						Read More{" "}
					</div>
				) : (
					<div className="read-more" onClick={() => setIsExpanded(false)}>
						Read Less 🔼
					</div>
				))}
		</div>
	);
};

// const barColors = [
// 	"green", // Clean
// 	"green", // Mild
// 	"yellow", // Moderate
// 	"red", // Strong
// 	"darkred", // Extreme
// ];

const difficultyOptions = [
	"easy",
	"medium",
	"hard",
];

const barLabels = ["Clean", "Mild", "Moderate", "Strong", "Extreme"];

const LanguageLevelBar = (props: { villain: Villain }) => {
	let progress;
	let color;
	switch (props.villain.languageLevel) {
		case 1:
			progress = 10;
			color = "white";
			break;
		case 2:
			progress = 30;
			color = "green";
			break;
		case 3:
			progress = 50;
			color = "yellow";
			break;
		case 4:
			progress = 70;
			color = "orange";
			break;
		case 5:
			progress = 100;
			color = "red";
			break;
		default:
			progress = 0;
			color = "white";
			break;
	}
	const barStyle = {
		background: `${color}`,
		width: `${progress}%`,
		height: "20px",
		borderRadius: `20px`,
	};

	const labelStyle = {
		left: `${progress}%`,
	};

	return (
		<div className="language-bar-container">
			<div className="language-level-header">Language Level</div>
			<div className="language-bar">
				<div style={barStyle}></div>
			</div>
			<div className="label-container">
				<IonRow>
					{barLabels.map((label, index) => {
						let leftMargin = (index / 5) * 100;
						return (
							<IonCol key={`${index}`} size="2.4">
								{label}
							</IonCol>
						);
					})}
				</IonRow>
			</div>
		</div>
	);
};

// const VillainStats = (props: { villain: Villain | undefined }) => {
// 	return (
// 		<>
// 			<div className="villian-profile-stats">
// 				<div className="villian-profile-stat">
// 					<div className="villian-profile-stat-label">To-Hit</div>
// 					<div className="villian-profile-stat-value">{props.villain?.toHit}</div>
// 				</div>
// 				<div className="villian-profile-stat">
// 					<div className="villian-profile-stat-label">Dodge</div>
// 					<div className="villian-profile-stat-value">{props.villain?.dodge}</div>
// 				</div>
// 				<div className="villian-profile-stat">
// 					<div className="villian-profile-stat-label">Damage</div>
// 					<div className="villian-profile-stat-value">{props.villain?.damage}</div>
// 				</div>
// 				<div className="villian-profile-stat">
// 					<div className="villian-profile-stat-label">Health</div>
// 					<div className="villian-profile-stat-value">{props.villain?.health}</div>
// 				</div>
// 			</div>
// 		</>
// 	);
// };

