import { IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import { Skill } from "../../models/skills";
import { useEffect } from 'react';
import { logGenericEventWithObject } from '../../services/AnalyticsService';

const SkillModal = (props: { skill: Skill }) => {
    useEffect(() => {
        logGenericEventWithObject(`view skill modal - ${props.skill.name}`, props.skill)
    }, [props.skill]);
    return (
        <>
            <div style={{ margin: "20px" }}>
                <IonCard>
                    <img src={props.skill.icon?.url} />
                    <IonCardHeader>
                        <IonCardTitle>
                            {props.skill.name}
                        </IonCardTitle>
                        <IonCardSubtitle>
                            <p>Duration: {props.skill.repeat === 0 ? "Immediate" : props.skill.repeat + " hours"}  | Cooldown: {props.skill.cooldown} hours</p>
                            {/* <p>{props.skill.damage && (<>💥: {props.skill.damage}</>)}</p> */}
                        </IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>
                        {props.skill.description}
                    </IonCardContent>

                </IonCard>
            </div>
        </>
    )
}

export default SkillModal