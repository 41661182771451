import { IonBackButton, IonHeader, IonLoading, IonModal, IonPage, IonToolbar } from "@ionic/react";
import { Steps } from "intro.js-react";
import log from "loglevel";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import DuelResultCelebration from "../../../Celebrations/DuelResultCelebration";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Celebrations.css";
import "../../../css/Duels.css";
import { DuelStatus, VillainGroupInstance, VillainGroupInstanceNode } from "../../../models/duelsModels";
import { HeroObject } from "../../../models/userModel";
import { Villain } from "../../../models/villainModels";
import { getDuelStatusWithInstanceId, getVillainGroupInstanceByVillainGroupId } from "../../../services/DuelsServics";
import { hasCoachMarkBeenShown, updateCoachMark } from "../../../services/HelperService";
import { getHero } from "../../../services/UserService";
import { getVillain } from "../../../services/VillainServices";
import ProegressionsPath from "../../Duels/components/ProgressionPaths";
import VillainProfile from "../components/VillainProfile";
import { TourComponent } from "../../Tour/StepsComponent";
import { VillainProgressionExplanation } from "../../Tour/Steps";

const VillainGroupHome = () => {
	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { isMobile } = globalState;
	const { villainGroupId } = useParams<{ villainGroupId: string }>();
	const [loading, setLoading] = useState<boolean>(true);
	const [villainGroupInstance, setVillainGroupInstance] = useState<VillainGroupInstance>();
	const [nodes, setNodes] = useState<VillainGroupInstanceNode[]>([]);
	const [paths, setPaths] = useState<any[]>([]);
	const [hero, setHero] = useState<HeroObject>();
	const [showModal, setShowModal] = useState({ show: false, villain: {} as Villain, available: false, villainGroupInstanceId: "", difficulty: "" });
	const [showDefeatedModal, setShowDefeatedModal] = useState({ show: false, duelStatus: {} as DuelStatus });
	const [backgroundImage, setBackgroundImage] = useState<string>("");
	const nodeSize = 100; // in pixels

	useEffect(() => {
		async function getVillianGroupInfo() {
			const villainGroupInstance: VillainGroupInstance = await getVillainGroupInstanceByVillainGroupId(villainGroupId);
			setVillainGroupInstance(villainGroupInstance);
			setNodes(villainGroupInstance.villains);
			if (villainGroupInstance.paths) {
				setPaths(villainGroupInstance.paths);
			}
			setBackgroundImage(villainGroupInstance.groupImage);
		}

		async function pullHeroInfo() {
			setHero(await getHero());
		}

		Promise.all([getVillianGroupInfo(), pullHeroInfo()])
			.then(() => {
				log.debug("Villain Group Info loaded");
				setLoading(false);
			})
			.catch((error) => {
				console.error("An error occurred:", error);
				// Handle errors as needed
			});
	}, []);

	const handleNodeCompletionToggle = async (nodeId: number, available: boolean) => {
		log.debug(`NodeId`, nodeId);
		log.debug(`Available`, available);
		// log.debug(`Nodes`, nodes)
		// log.debug(`Node ${nodeId} completion toggled!`);
		// const updatedNodes = nodes.map(node => {
		//     log.debug("node", node)
		//     if (node.nodeId === nodeId) {
		//         return { ...node, canPass: !node.canPass };
		//     }
		//     return node;
		// });
		// log.debug("updatedNodes", updatedNodes)
		// setNodes(updatedNodes);
		let villainInNode: VillainGroupInstanceNode | undefined = nodes.find((node) => node.nodeId === nodeId);
		let duelStatus = await getDuelStatusWithInstanceId(villainGroupInstance?.villainGroupInstanceId!, villainInNode?.villainId!);
		log.debug("duelStatus", duelStatus);
		if (duelStatus !== null) {
			log.debug("duelStatus is not null");
			if (duelStatus.complete === true && duelStatus.winner === "hero") {
				log.debug("duelStatus is complete and hero won");
				setShowDefeatedModal({ show: true, duelStatus: duelStatus });
			} else if (duelStatus.complete !== true) {
				log.debug("duelStatus is not complete");
				history.push(`/dueldetails/${duelStatus.statusId}/updates`);
			} else {
				log.debug("duelStatus is complete and hero lost");
				let villain = await getVillain(villainInNode?.villainId!);
				villain = {
					...villain,
					toHit: villainInNode?.villainStats.toHit!,
					damage: villainInNode?.villainStats.damage!,
					dodge: villainInNode?.villainStats.dodge!,
					health: villainInNode?.villainStats.health!,
					level: villainInNode?.villainStats.level!,
					strength: villainInNode?.villainStats.strength!,
					cardio: villainInNode?.villainStats.cardio!,
					mobility: villainInNode?.villainStats.mobility!,
					mindfulness: villainInNode?.villainStats.mindfulness!,
				};
				setShowModal({ show: true, villain: villain, available: available, villainGroupInstanceId: villainGroupInstance?.villainGroupInstanceId!, difficulty: villainInNode?.difficulty! });
			}
		} else if (villainInNode?.canPass === false) {
			log.debug("duelStatus is null and canPass is false");
			let villain = await getVillain(villainInNode?.villainId!);
			villain = {
				...villain,
				toHit: villainInNode?.villainStats.toHit!,
				damage: villainInNode?.villainStats.damage!,
				dodge: villainInNode?.villainStats.dodge!,
				health: villainInNode?.villainStats.health!,
				level: villainInNode?.villainStats.level!,
				strength: villainInNode?.villainStats.strength!,
				cardio: villainInNode?.villainStats.cardio!,
				mobility: villainInNode?.villainStats.mobility!,
				mindfulness: villainInNode?.villainStats.mindfulness!,
			};
			setShowModal({ show: true, villain: villain, available: available, villainGroupInstanceId: villainGroupInstance?.villainGroupInstanceId!, difficulty: villainInNode?.difficulty! });
		}
	};

	return (
		<IonPage>
			<IonLoading isOpen={loading} backdropDismiss={true} message={"Fetching villains..."} duration={5000} />
			<IonHeader>
				<IonToolbar className="background">
					<IonBackButton defaultHref="/duels/current" style={{ "--border-radius": "unset" }} />
				</IonToolbar>
			</IonHeader>
			<IonModal
				isOpen={showModal.show}
				onWillDismiss={() => setShowModal({ show: false, villain: {} as Villain, available: false, villainGroupInstanceId: "", difficulty: "" })}
				style={isMobile ? {} : { "--height": "90%" }}
			>
				<VillainProfile
					villainGroupInstanceId={showModal.villainGroupInstanceId}
					villain={showModal.villain}
					setShowModal={setShowModal}
					// alreadyScaled={true}
					difficulty={showModal.difficulty}
					showStartDuelButton={showModal.available}
				/>
			</IonModal>
			<IonModal
				isOpen={showDefeatedModal.show}
				onWillDismiss={() => setShowDefeatedModal({ show: false, duelStatus: {} as DuelStatus })}
				style={isMobile ? {} : { "--height": "90%" }}
				id="activity-celebrations-modal"
			>
				<DuelResultCelebration
					duelStatus={showDefeatedModal.duelStatus}
					dismissFunction={() => setShowDefeatedModal({ show: false, duelStatus: {} as DuelStatus })}
				/>
			</IonModal>
			<div className="villain-group-map" style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}}>
				{villainGroupInstance && (
					<div className="villian-group-name-title">
						<h1>{villainGroupInstance.groupName}</h1>
					</div>
				)}
				<div className="game-map-inner">
					{nodes.map((node, index) => {
						// log.debug("node", node)
						// Compute the connected nodes from paths
						const connectedNodeIds = paths.filter((path) => path.endNodeId === node.nodeId).map((path) => path.startNodeId);
						if (node.villainImage && node.villainName) {
							return (
								<VillainNode
									index={index}
									key={node.nodeId}
									{...node}
									connectedNodes={connectedNodeIds}
									allNodes={nodes}
									villainId={node.villainId}
									villainImage={node.villainImage}
									villainName={node.villainName}
									villainDifficulty={node.difficulty}
									onClick={handleNodeCompletionToggle}
									nodeSize={nodeSize}
								/>
							);
						}
					})}
					{hero && nodes.length > 0 && <StartingNode hero={hero} allNodes={nodes} nodeSize={nodeSize} />}
					{hero && nodes.length > 0 && <HeroNode hero={hero} allNodes={nodes} nodeSize={nodeSize} />}
					{paths.map((path) => {
						// log.debug("path", path)
						const startNode = nodes.find((node) => node.nodeId === path.startNodeId);
						const endNode = nodes.find((node) => node.nodeId === path.endNodeId);
						if (!startNode || !endNode) return null;

						const pathsToEndNode = paths.filter((p) => p.endNodeId === endNode.nodeId);
						const currentIndex = pathsToEndNode.findIndex((p) => p.startNodeId === startNode.nodeId);

						const start = {
							id: startNode.nodeId,
							x: xCalculation(startNode.position.col),
							y: yCalculation(startNode.position.row),
						};

						const end = {
							id: endNode.nodeId,
							x: xCalculation(endNode.position.col),
							y: yCalculation(endNode.position.row),
						};

						return (
							<ProegressionsPath
								key={`${startNode.nodeId}-${endNode.nodeId}`}
								start={start}
								end={end}
								// nodesWithMultiplePaths={nodesWithMultiplePaths}
								// index={currentIndex}
								// totalPaths={pathsToEndNode.length}
								nodeSize={nodeSize}
							/>
						);
					})}
				</div>
			</div>
			<TourComponent modalShowing={showModal.show} coachMarkName="villainProgressionExplanation" steps={VillainProgressionExplanation} action={() => handleNodeCompletionToggle(7, true)} />
		</IonPage>
	);
};

export default VillainGroupHome;

const xCalculation = (col: number) => {
	return (col - 1) * 110;
};

const yCalculation = (row: number) => {
	return (row - 1) * 150 + 50;
};

interface StartingNodeProps {
	hero: HeroObject;
	allNodes: VillainGroupInstanceNode[]; // All nodes for checking completion status
	// onClick: (nodeId: number) => void; // Callback when a node is clicked
	nodeSize: number;
}

const HeroNode = (props: StartingNodeProps) => {
	const handleNodeClick = () => { };
	// parse props.allNodes to see if one has currentlyDueling = true
	const currentlyDuellingNode = props.allNodes.find((node) => node.currentlyDuelling);
	let endNode = props.allNodes.reduce((prev, current) => (prev.position.row > current.position.row ? prev : current));
	let col = 2;
	let row = endNode.position.row + 1;
	let x = xCalculation(col);
	let y = yCalculation(row);
	let duelSign = 0;
	if (currentlyDuellingNode) {
		col = currentlyDuellingNode.position.col;
		row = currentlyDuellingNode.position.row;
		x = xCalculation(col);
		y = yCalculation(row);
		if (currentlyDuellingNode.position.col === 3) {
			x = x - 100;
			duelSign = 90;
		} else {
			x = x + 100;
			duelSign = -10;
		}
	} else {
		// get last completed node by looking at allnodes and finding the one with the history.startdate with the highest value and completed = true
		let latestNode = findNodeWithLatestCompleteHistory(props.allNodes);
		if (latestNode) {
			col = latestNode.position.col;
			row = latestNode.position.row;
			x = xCalculation(col);
			y = yCalculation(row);
			if (latestNode.position.col === 3) {
				x = x - 100;
				duelSign = 90;
			} else {
				x = x + 100;
				duelSign = -10;
			}
		}
	}

	let backgroundColor = `gray`;
	let heroNodeId = props.allNodes.length;
	// the endNode is the node with the highest row value

	const start = {
		id: heroNodeId,
		x: x,
		y: y,
	};

	const end = {
		id: endNode.nodeId,
		x: xCalculation(endNode.position.col),
		y: yCalculation(endNode.position.row),
	};
	return (
		<>
			<div
				className={`villain-node hero-hint`}
				style={{ left: x, top: y, backgroundColor: backgroundColor, width: props.nodeSize, height: props.nodeSize }}
				onClick={handleNodeClick}
			>
				{duelSign !== 0 && currentlyDuellingNode !== undefined && (
					<div className="duel-current-duel-div" style={{ left: `${duelSign}px` }}>
						<span>⚔</span>
					</div>
				)}

				<img src={props.hero.heroImageURL} className={`villain-image-node `} />
				{/* Display level content here */}
			</div>
		</>
	);
};

const StartingNode = (props: StartingNodeProps) => {
	const handleNodeClick = () => {
		// // Get the highest nodeId in allNodes
		// const highestNodeId = Math.max(...props.allNodes.map(node => node.nodeId));
		// log.debug("highestNodeId", highestNodeId)
		// // Check if any of the connected nodes are completed
		// const hasCompletedConnectedNode = props.connectedNodes.some(
		//     nodeId => props.allNodes.find(node => node.nodeId === nodeId)?.canPass
		// );
		// if (!hasCompletedConnectedNode && props.nodeId !== highestNodeId) {
		//     // Behavior when no connected nodes are completed
		//     alert("You must complete a connected node first!");
		// } else {
		//     // Normal behavior
		//     props.onClick(props.nodeId);
		// }
	};
	let col = 2;
	let endNode = props.allNodes.reduce((prev, current) => (prev.position.row > current.position.row ? prev : current));
	let row = endNode.position.row + 1;
	let x = xCalculation(col);
	let y = yCalculation(row);
	let backgroundColor = `black`;
	let heroNodeId = props.allNodes.length;
	// the endNode is the node with the highest row value

	const start = {
		id: heroNodeId,
		x: x,
		y: y,
	};

	const end = {
		id: endNode.nodeId,
		x: xCalculation(endNode.position.col),
		y: yCalculation(endNode.position.row),
	};
	return (
		<>
			<div
				className={`villain-node`}
				style={{ left: x, top: y, backgroundColor: backgroundColor, width: props.nodeSize, height: props.nodeSize }}
				onClick={handleNodeClick}
			>
				<img src="/assets/images/fit-hero-shield-transparent.png" className={`logo-image-node`} />
				{/* Display level content here */}
			</div>
			<ProegressionsPath
				key={`${heroNodeId}-${endNode.nodeId}`}
				start={start}
				end={end}
				// nodesWithMultiplePaths={nodesWithMultiplePaths}
				// index={currentIndex}
				// totalPaths={pathsToEndNode.length}
				nodeSize={props.nodeSize}
			/>
		</>
	);
};

interface VillainNodeProps {
	index: number;
	nodeId: number;
	villainName: string;
	villainId: string;
	villainImage: string;
	villainDifficulty: string;
	position: { row: number; col: number };
	canPass: boolean;
	connectedNodes: number[]; // IDs of nodes connected to this one
	allNodes: VillainGroupInstanceNode[]; // All nodes for checking completion status
	onClick: (nodeId: number, duelAvailable: boolean) => void; // Callback when a node is clicked
	nodeSize: number;
}

const VillainNode = (props: VillainNodeProps) => {
	const handleNodeClick = () => {
		let available = true;
		// Get the highest nodeId in allNodes
		// log.debug("highestNodeId", highestNodeId)
		// Check if any of the connected nodes are completed
		const hasCompletedConnectedNode = props.connectedNodes.some((nodeId) => props.allNodes.find((node) => node.nodeId === nodeId)?.canPass);

		if (!hasCompletedConnectedNode && props.nodeId !== highestNodeId) {
			// Behavior when no connected nodes are completed
			// alert("You must complete a connected node first!");
			available = false;
		}
		props.onClick(props.nodeId, available);
	};
	const highestNodeId = Math.max(...props.allNodes.map((node) => node.nodeId));
	// let x = props.position.col * 100
	let x = xCalculation(props.position.col);
	let y = yCalculation(props.position.row);
	let backgroundColor;
	switch (props.villainDifficulty) {
		// case "easy":
		// 	backgroundColor = "green";
		// 	break;
		// case "medium":
		// 	backgroundColor = "blue";
		// 	break;
		// case "hard":
		// 	backgroundColor = "orange";
		// 	break;
		case "boss":
			backgroundColor = "red";
			break;
		default:
			backgroundColor = "darkgray";
			break;
	}

	if (props.canPass === true) {
		backgroundColor = "rgb(75 75 75)";
	}

	return (
		<div
			className={`villain-node ${props.index === highestNodeId - 1 && "first-villain-hint"} ${props.index === 0 && "boss-villain-hint"}`}
			style={{ left: x, top: y, backgroundColor: backgroundColor, width: props.nodeSize, height: props.nodeSize }}
			onClick={handleNodeClick}
		>
			{props.canPass === true && <img src="/assets/images/red-x.png" alt="Defeated" className="x-image-group" />}
			<img src={props.villainImage} className={`villain-image-node ${props.canPass ? "completed" : ""}`} />
			{/* Display level content here */}
		</div>
	);
};

const findNodeWithLatestCompleteHistory = (nodes: VillainGroupInstanceNode[]) => {
	// Step 1 & 2: Filter nodes with history and flatten the histories
	const histories = nodes
		.filter((node) => node.history && node.history.length > 0)
		.flatMap((node) => node.history?.map((history) => ({ ...history, nodeId: node.nodeId })) || []);

	// Step 3: Filter out histories where complete is true
	const completedHistories = histories.filter((history) => history.complete);

	// Step 4: Sort by startDate
	completedHistories.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime());

	// Step 5: Find the corresponding node
	const latestHistory = completedHistories[0];
	if (latestHistory) {
		return nodes.find((node) => node.nodeId === latestHistory.nodeId);
	}

	return null; // or appropriate default value
};
