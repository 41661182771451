export type MissionDescription = {
	title: string;
	description: string;
	attributeType: string;
};

export const AvailableMissions: MissionDescription[] = [
	{ title: "Melt away stiffness", description: "Log a stretching session for <x> minutes", attributeType: "mobility" },
	{ title: "Let's get mobile", description: "Log a foam rolling session for <x> minutes", attributeType: "mobility" },
	{
		title: "Embrace tranquility",
		description: "Unwind with a refreshing yoga session for <x> minutes",
		attributeType: "mobility",
	},
	{
		title: "Slow and steady wins the race",
		description: "Enjoy a soothing cool-down session for <x> minutes",
		attributeType: "mobility",
	},
	{
		title: "Seek balance and control",
		description: "Engage your core with a focused pilates session for <x> minutes",
		attributeType: "mobility",
	},
	{
		title: "Embrace inner peace",
		description: "Unroll your mat and enjoy a revitalizing yoga session for <x> minutes",
		attributeType: "mobility",
	},
	{
		title: "Restore and rejuvenate",
		description: "Take some time for a cool down session that lasts <x> minutes",
		attributeType: "mobility",
	},
	{
		title: "Build strength and flexibility",
		description: "Engage your mind and body in a Pilates session for <x> minutes",
		attributeType: "mobility",
	},
	{
		title: "Say goodbye to stiffness",
		description: "Dedicate <x> minutes to a relaxing stretching session and feel the difference",
		attributeType: "mobility",
	},
	{
		title: "Roll into better mobility",
		description: "Take <x> minutes for a foam rolling session and keep your muscles happy",
		attributeType: "mobility",
	},
	{
		title: "Push Your Limits",
		description: "Charge up your workout with a high-intensity HIIT session for <x> minutes",
		attributeType: "strength",
	},
	{
		title: "Heart Pumping Action",
		description: "Elevate your day with a vigorous HIIT workout for <x> minutes",
		attributeType: "strength",
	},
	{
		title: "Power Unleashed",
		description: "Flex your muscles in a traditional strength training session for <x> minutes",
		attributeType: "strength",
	},
	{
		title: "Unleash Your Potential",
		description: "Push past your limits with a strength training workout for <x> minutes",
		attributeType: "strength",
	},
	{
		title: "Unconventional Strength",
		description: "Try a functional strength training session and challenge yourself for <x> minutes",
		attributeType: "strength",
	},
	{
		title: "Break the Mold",
		description: "Log a functional strength training workout for <x> minutes and feel the burn",
		attributeType: "strength",
	},
	{
		title: "Core Power",
		description: "Strengthen your foundation with a core training session for <x> minutes",
		attributeType: "strength",
	},
	{
		title: "Centered Strength",
		description: "Cultivate inner power with a focused core training session for <x> minutes",
		attributeType: "strength",
	},
	{
		title: "Calm your mind",
		description: "Embark on a calming meditation session for <x> minutes",
		attributeType: "mindfulness",
	},
	{
		title: "Savor the silence",
		description: "Indulge in <x> minutes of serene meditation",
		attributeType: "mindfulness",
	},
	{
		title: "Reclaim your peace",
		description: "Claim <x> minutes of tranquility with a meditation session",
		attributeType: "mindfulness",
	},
	{
		title: "Find your balance",
		description: "Spend <x> minutes exploring the gentle movements of tai chi",
		attributeType: "mindfulness",
	},
	{
		title: "Flow with grace",
		description: "Engage in <x> minutes of tranquil tai chi movements",
		attributeType: "mindfulness",
	},
	{
		title: "Energize your spirit",
		description: "Experience the harmonizing effects of a <x> minute tai chi session",
		attributeType: "mindfulness",
	},
	{
		title: "Aim for success",
		description: "Draw your focus with a <x> minute Archery session",
		attributeType: "cardio",
	},
	{ title: "Tackle your goals", description: "Push through a challenging <x> minute American Football session", attributeType: "cardio" },
	{ title: "Bring out the Aussie in you", description: "Log an Australian Football session for <x> minutes", attributeType: "cardio" },
	{ title: "Serve up a sweat", description: "Play a match of Badminton for <x> minutes", attributeType: "cardio" },
	{ title: "Step up to the plate", description: "Swing for the fences with a Baseball workout of <x> minutes", attributeType: "cardio" },
	{ title: "Show your game", description: "Score a sweat with a Basketball session of <x> minutes", attributeType: "cardio" },
	{ title: "Roll towards fitness", description: "Bowl over your fitness goals with a <x> minute Bowling session", attributeType: "cardio" },
	{ title: "Jab, punch, sweat", description: "Get in the ring with a Boxing session of <x> minutes", attributeType: "cardio" },
	{ title: "Slide towards your goals", description: "Make your move with a Curling session of <x> minutes", attributeType: "cardio" },
	{ title: "Let your feet do the talking", description: "Dance your way to fitness for <x> minutes", attributeType: "cardio" },
	{ title: "Stride on", description: "Sweat it out on the Elliptical for <x> minutes", attributeType: "cardio" },
	{ title: "Ride towards fitness", description: "Engage in Equestrian Sports for <x> minutes", attributeType: "cardio" },
	{ title: "Parry your way to health", description: "Engage in a Fencing session of <x> minutes", attributeType: "cardio" },
	{ title: "Reel in your goals", description: "Go Fishing for <x> minutes and relax your mind", attributeType: "mindfulness" },
	{ title: "Ace your fitness", description: "Tee off with a <x> minute Golf session", attributeType: "cardio" },
	{ title: "Catch your goals", description: "Play a Handball match for <x> minutes", attributeType: "cardio" },
	{ title: "Shoot towards fitness", description: "Step on the ice with a Hockey session of <x> minutes", attributeType: "cardio" },
	{ title: "Stalk your fitness goals", description: "Enjoy Hunting for <x> minutes", attributeType: "mindfulness" },
	{ title: "Net your fitness goals", description: "Sprint with a Lacrosse stick for <x> minutes", attributeType: "cardio" },
	{ title: "Fight for fitness", description: "Embrace Martial Arts for <x> minutes", attributeType: "cardio" },
	{ title: "Play your way to health", description: "Log a Play session for <x> minutes", attributeType: "cardio" },
	{ title: "Sprint to your goals", description: "Rally in a Racquetball game for <x> minutes", attributeType: "cardio" },
	{ title: "Score fitness goals", description: "Tackle a Rugby session for <x> minutes", attributeType: "cardio" },
	{ title: "Race towards fitness", description: "Log a Running session for <x> minutes", attributeType: "cardio" },
	{ title: "Sail towards your goals", description: "Embrace the wind with a Sailing session for <x> minutes", attributeType: "cardio" },
	{ title: "Glide your way to fitness", description: "Experience the thrill of Skating Sports for <x> minutes", attributeType: "cardio" },
	{ title: "Plow through your goals", description: "Enjoy Snow Sports for <x> minutes", attributeType: "cardio" },
	{ title: "Score your fitness goals", description: "Dribble in a Soccer match for <x> minutes", attributeType: "cardio" },
	{ title: "Swing towards fitness", description: "Step up to the plate with a Softball game for <x> minutes", attributeType: "cardio" },
	{ title: "Crush your fitness goals", description: "Serve in a Squash game for <x> minutes", attributeType: "cardio" },
	{ title: "Ride the fitness wave", description: "Catch some waves with a Surfing Sports session of <x> minutes", attributeType: "cardio" },
	{ title: "Dive into fitness", description: "Swim for <x> minutes", attributeType: "cardio" },
	{ title: "Serve up a workout", description: "Play a Table Tennis match for <x> minutes", attributeType: "cardio" },
	{ title: "Ace your workout", description: "Serve and volley in a Tennis match for <x> minutes", attributeType: "cardio" },
	{ title: "Run towards your goals", description: "Participate in a Track and Field session for <x> minutes", attributeType: "cardio" },
	{ title: "Spike your way to fitness", description: "Bump, set, spike in a Volleyball match for <x> minutes", attributeType: "cardio" },
	{ title: "Step towards your goals", description: "Log a Walking session for <x> minutes", attributeType: "cardio" },
	{ title: "Dive into fitness", description: "Get your heart pumping with a Water Fitness session of <x> minutes", attributeType: "cardio" },
	{ title: "Make a splash", description: "Score in a Water Polo match for <x> minutes", attributeType: "cardio" },
	{ title: "Ride the wave of fitness", description: "Dive into Water Sports for <x> minutes", attributeType: "cardio" },
	{ title: "Pin down your fitness goals", description: "Log a Wrestling session for <x> minutes", attributeType: "cardio" },
	{ title: "Stride towards fitness", description: "Log a Cross Country Skiing session for <x> minutes", attributeType: "cardio" },
	{ title: "Slope towards fitness", description: "Ski downhill for <x> minutes", attributeType: "cardio" },
	{ title: "Jump towards your goals", description: "Log a Jump Rope session for <x> minutes", attributeType: "cardio" },
	{ title: "Kick off your fitness journey", description: "Log a Kickboxing session for <x> minutes", attributeType: "cardio" },
	{ title: "Slide towards your goals", description: "Log a Snowboarding session for <x> minutes", attributeType: "cardio" },
	{ title: "Roll towards fitness", description: "Log a Wheelchair Walk Pace session for <x> minutes", attributeType: "cardio" },
	{ title: "Race towards your goals", description: "Log a Wheelchair Run Pace session for <x> minutes", attributeType: "cardio" },
	{ title: "Mix up your fitness routine", description: "Log a Mixed Cardio session for <x> minutes", attributeType: "cardio" },
	{ title: "Pedal towards your goals", description: "Log a Hand Cycling session for <x> minutes", attributeType: "cardio" },
	{ title: "Step up to fitness", description: "Log a Step Training session for <x> minutes", attributeType: "cardio" },
	{ title: "Fly towards your goals", description: "Log a Disc Sports session for <x> minutes", attributeType: "cardio" },
	{ title: "Play towards fitness", description: "Engage in Fitness Gaming for <x> minutes", attributeType: "cardio" },
	/*
    "Do a foam rolling session for <x> minutes",
    "Practice Tai Chi for <x> minutes",
    "Dance for <x> minutes to improve your mobility",
    "Spend <x> minutes stretching your hips",
    "Spend <x> minutes stretching your hamstrings",
    "Spend <x> minutes stretching your calves",
    "Spend <x> minutes stretching your glutes",
    "Stretch your upper body for <x> minutes",
    "Roll your feet along a lacross ball for <x> minutes",
    "Do some trigger point release on a sore spot for <x> minutes",
    "Log"
    */
];
