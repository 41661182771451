import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonItem, IonLoading, IonPage, IonTitle, IonToggle, IonToolbar } from "@ionic/react";
import moment, { Moment } from "moment";
import { useContext, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import { getAndProcessBaselineWorkouts } from "../services/ExerciseService";
import { checkForGoogleFit, getGFActivities, getGFSteps, googleFitConnect, isGoogleFitAuthorized } from "../services/GoogleFitService";
import { authorizeHealthKit, getHKStepsv2, processWorkoutsFromHealthKit } from "../services/HealthKitService";
import { StorageService } from "../services/StorageService";
import { workoutKey } from "../models/userModel";
const storageService = new StorageService();

export default function PullData() {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const {
		healthKitConnected,
		setHealthKitConnected,
		googleFitConnected,
		setGoogleFitConnected,
		clearStoredHeroObject,
		updateHeroHealth,
		workoutData,
		lastUpdatedText,
		lastUpdated,
		serialStartupFunction,
	} = globalState;

	const [pullingData, setPullingData] = useState<boolean>(false);

	async function pullHKActivities(startDate: Moment, endDate: Moment, lastUpdated: Moment) {
		setPullingData(true);
		let workouts = await storageService.getObject(workoutKey);
		await processWorkoutsFromHealthKit(workouts, startDate, endDate);
		setPullingData(false);
	}

	async function getAndProcessRecentWorkouts() {
		setPullingData(true);
		await serialStartupFunction();
		setPullingData(false);
	}

	async function getBaselineWorkouts() {
		setPullingData(true);
		let baselineWorkoutsObject = await getAndProcessBaselineWorkouts();
		await updateHeroHealth(baselineWorkoutsObject.activityLedger, baselineWorkoutsObject.recentWorkouts, moment());
		setPullingData(false);
	}

	async function pullSteps(startDateMoment: Moment, endDateMoment: Moment) {
		// let updateHealthInfo = await getHKStepsv2(moment(startDateMoment).format(), moment(endDateMoment).format());
		// if (updateHealthInfo && updateHealthInfo.activityLedger && updateHealthInfo.workoutArray) {
		// 	await updateHeroHealth(updateHealthInfo.activityLedger, updateHealthInfo.workoutArray, moment());
		// }
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar className="background">
					<IonButtons slot="start">
						<IonBackButton text="" />
					</IonButtons>
					<IonTitle>Pull Data</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={pullingData} message={"Pulling data ..."} duration={5000} />
				<IonItem>
					<div style={{ width: "100%" }}>Connect GoogleFit: </div>
					<div>
						<IonButton onClick={() => googleFitConnect()} color="tertiary" />
					</div>
				</IonItem>
				<IonItem>
					<div style={{ width: "100%" }}>Check for GoogleFit: </div>
					<div>
						<IonButton onClick={() => checkForGoogleFit()} color="tertiary" />
					</div>
				</IonItem>
				<IonItem>
					<div style={{ width: "100%" }}>Is GoogleFit Authorized: </div>
					<div>
						<IonButton onClick={() => isGoogleFitAuthorized()} color="tertiary" />
					</div>
				</IonItem>
				<IonItem>
					<div style={{ width: "100%" }}>Get Activitites last 3 days: </div>
					<div>
						<IonButton onClick={() => getGFActivities(moment().subtract("3", "days").format(), moment().format())} color="tertiary" />
					</div>
				</IonItem>
				{/* <IonItem>
					<div style={{ width: "100%" }}>Get Steps last 3 days: </div>
					<div>
						<IonButton onClick={() => getGFSteps(moment().startOf("day").subtract("3", "days").format(), moment().format())} color="tertiary" />
					</div>
				</IonItem> */}
				<IonItem>
					<div style={{ width: "100%" }}>GoogleFit Connected: </div>
					<div>
						<IonToggle checked={googleFitConnected} onClick={() => googleFitConnect().then(() => setGoogleFitConnected(true))} color="tertiary" />
					</div>
				</IonItem>
				<IonItem>
					<div style={{ width: "100%" }}>HealthKit Connected: </div>
					<div>
						<IonToggle checked={healthKitConnected} onClick={() => authorizeHealthKit().then(() => setHealthKitConnected(true))} color="tertiary" />
					</div>
				</IonItem>

				<h1>Last Updated: {lastUpdatedText}</h1>
				{healthKitConnected ? (
					<>
						<IonButton onClick={() => pullHKActivities(moment().subtract(12, "months"), moment(), moment())}>
							Pull {`${moment().subtract(12, "months").format("MM-DD")}`} - {`${moment().format("MM-DD")}`}
						</IonButton>
						<IonButton onClick={() => getAndProcessRecentWorkouts()}>Get Recent Workouts</IonButton>
					</>
				) : (
					<>
						<IonButton onClick={() => getAndProcessRecentWorkouts()}>
							{/* add 1 day because of time zones*/}
							Pull from server {`${moment(lastUpdated).format("MM-DD")}`} - {`${moment().add(1, "day").format("MM-DD")}`}
						</IonButton>
						<IonButton onClick={() => getBaselineWorkouts()}>
							{/* add 1 day because of time zones*/}
							Pull from server past year
						</IonButton>
					</>
				)}
				{lastUpdatedText !== "Never" && lastUpdated ? (
					<>
						{/* {log.debug(`Last updated: ${levels.lastUpdated}`)} */}
						{/* {log.debug(`Last updated moment format: ${moment(levels.lastUpdated).format()}`)} */}
						{/* <IonButton onClick={() => processWorkoutsFromHealthKit(moment(heroObject.lastUpdated), moment(), moment())}>
									Pull {`${moment(heroObject.lastUpdated).format("MM-DD h:mm a")}`} - now
								</IonButton> */}
					</>
				) : (
					""
				)}

				<IonButton onClick={clearStoredHeroObject}>Clear Stored Fitness Levels</IonButton>

				<IonButton onClick={() => pullSteps(moment().subtract(2, "hour"), moment())}>Pull Steps last 2 hours</IonButton>
				<IonButton onClick={() => pullSteps(moment().startOf("day"), moment())}>Pull Steps today</IonButton>
				<IonButton onClick={() => pullSteps(moment().startOf("isoWeek"), moment())}>Pull Steps this week</IonButton>
				<IonButton onClick={() => pullSteps(moment().subtract(2, "weeks"), moment())}>Pull Steps Data last 2 weeks</IonButton>
				<IonButton onClick={() => pullSteps(moment().subtract(4, "weeks"), moment().subtract(2, "weeks"))}>
					Pull Steps Data for two weeks from 2 weeks ago
				</IonButton>
			</IonContent>
		</IonPage>
	);
}
