import { Moment } from "moment";

export const heroDisplayMessagesKey = "heroDisplayMessages";
export const heroDBObjectKey = "heroDBObject";
export const lastHeroPromptKey = "lastHeroPrompt";

export interface HerochatDisplayMessage {
    content: string;
    role: string;
    timestamp?: Moment;
    imageUrl?: string;
}

export type HeroMessage = {
    heroId?: string;
    userId?: string;
    messageId?: string;
    createdAt?: string;
    updatedAt?: string;
    objectId?: string;
    source?: string;
    message: string;
    responseType?: string;
    activityId?: string;
};

export interface FullMessageObject {
    heroMessageObject: HeroMessage;
    userMessageObject: HeroMessage;
}