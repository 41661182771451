import React, { useEffect, useState } from 'react'
import { DuelStatus } from '../../../models/duelsModels'
import { IonCol, IonGrid, IonRow, IonSpinner } from '@ionic/react'
import log from 'loglevel'
import { TallyObject } from '../../../services/DuelsServics'
import { Villain } from '../../../models/villainModels'

const AllVillainRecords = (props: { allTallys: TallyObject[], villains: Villain[] }) => {
    interface TallyRecord {
        easyWins: number,
        totalEasy: number,
        easyPercentage: string,
        mediumWins: number,
        totalMedium: number,
        mediumPercentage: string,
        hardWins: number,
        totalHard: number,
        hardPercentage: string,
        bossWins: number,
        totalBoss: number,
        bossPercentage: string,
    }
    const [tallyRecord, setTallyRecord] = useState<TallyRecord>()
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        calcWins()
    }, [props.allTallys])

    async function calcWins() {
        setLoading(true)
        // log.debug("AllVillainRecords useEffect triggered")
        if (props.allTallys && props.villains) {
            // count the number of villains where boss = true
            let bossCount = 0
            props.villains.forEach(villain => {
                if (villain.boss === true) {
                    bossCount++
                }
            })
            let tallyRecordTemp = {
                easyWins: 0,
                totalEasy: props.villains.length,
                easyPercentage: "0%",
                mediumWins: 0,
                totalMedium: props.villains.length,
                mediumPercentage: "0%",
                hardWins: 0,
                totalHard: props.villains.length,
                hardPercentage: "0%",
                bossWins: 0,
                totalBoss: bossCount,
                bossPercentage: "0%",
            }
            props.allTallys.forEach(tally => {
                if (tally.easy) {
                    tallyRecordTemp.easyWins++
                }
                if (tally.medium) {
                    tallyRecordTemp.mediumWins++
                }
                if (tally.hard) {
                    tallyRecordTemp.hardWins++
                }
                if (tally.boss === true) {
                    tallyRecordTemp.bossWins++
                }
            })
            tallyRecordTemp.easyPercentage = ((tallyRecordTemp.easyWins / tallyRecordTemp.totalEasy) * 100).toFixed(0) + "%"
            tallyRecordTemp.mediumPercentage = ((tallyRecordTemp.mediumWins / tallyRecordTemp.totalMedium) * 100).toFixed(0) + "%"
            tallyRecordTemp.hardPercentage = ((tallyRecordTemp.hardWins / tallyRecordTemp.totalHard) * 100).toFixed(0) + "%"
            tallyRecordTemp.bossPercentage = ((tallyRecordTemp.bossWins / tallyRecordTemp.totalBoss) * 100).toFixed(0) + "%"
            setTallyRecord(tallyRecordTemp)
            setLoading(false)
        }
    }

    return (
        <>
            {loading && (<div>Loading...<IonSpinner /></div>)}
            {tallyRecord && (
                <IonRow style={{ textAlign: "center", background: "rgb(0 161 255 / 20%)", color: "white" }}>
                    <IonCol className='duel-record-columns' style={{ borderRight: "solid" }}>
                        <div className='duel-record-label'>Easy<br />Progress</div>
                        <div className='duel-tally-stat'>{tallyRecord?.easyWins}/{tallyRecord?.totalEasy}</div>
                        <div className='duel-tally-stat'>{tallyRecord?.easyPercentage}</div>
                    </IonCol>
                    <IonCol className='duel-record-columns' style={{ borderRight: "solid" }}>
                        <div className='duel-record-label'>Medium<br />Progress</div>
                        <div className='duel-tally-stat'>{tallyRecord?.mediumWins}/{tallyRecord?.totalMedium}</div>
                        <div className='duel-tally-stat'>{tallyRecord?.mediumPercentage}</div>
                    </IonCol>
                    <IonCol className='duel-record-columns' style={{ borderRight: "solid" }}>
                        <div className='duel-record-label'>Hard<br />Progress</div>
                        <div className='duel-tally-stat'>{tallyRecord?.hardWins}/{tallyRecord?.totalHard}</div>
                        <div className='duel-tally-stat'>{tallyRecord?.hardPercentage}</div>
                    </IonCol>
                    <IonCol className='duel-record-columns'>
                        <div className='duel-record-label'>Boss<br />Progress</div>
                        <div className='duel-tally-stat'>{tallyRecord?.bossWins}/{tallyRecord?.totalBoss}</div>
                        <div className='duel-tally-stat'>{tallyRecord?.bossPercentage}</div>
                    </IonCol>
                </IonRow>
            )}
        </>
    )
}

export default AllVillainRecords