import { OperatingSystem } from "@capacitor/device";
import { ActivityLedger, SummaryType, WorkoutObject } from "./exerciseModels";

// Local Storage Keys
export const userObjectKey = "user";
export const userIDKey = "userID";
export const heroObjectKey = "hero";
export const allHeroesObjectKey = "allHeroes";
export const lastUpdatedAllHeroesKey = "allHeroesLastUpdated";
export const coachMarkObjectKey = "coachMarks";
export const activityLedgerKey = "activityLedger";
export const workoutKey = "workouts";
export const lastUpdatedWorkoutKey = "lastUpdated";
export const lastUpdatedStepsKey = "lastUpdatedSteps";
export const healthKitAuthorizedKey = "healthKitAuthorized";
export const googleFitAuthorizedKey = "googleFitAuthorized";
export const versionUpdateKey = "versionUpdate";

export const adminEmails = [
	"adam@myfithero.net",
	"adam@fithero.ai",
	"topher@topherdub.com",
	"topher@fitsquadapp.com",
]

export const liveUpdateVersions = [
	"Production",
	"Staging",
]

export const backends = [
	"Production",
	"Development",
]

export const AvatarCreationMessages = [
	"Baking in Sassiness",
	"Stirring Up Mischief",
	"Injecting a Dash of Drama",
	"Mixing Geek with Chic",
	"Brewing Up Bravado",
	"Weaving in Wit",
	"Blending in Boldness",
	"Assembling Awesomeness",
	"Crafting Your Persona",
	"Assembling Unique Features",
	"Tailoring Your Look",
	"Shaping Your Avatar",
	"Generating Character Details",
	"Infusing Personality Traits",
	"Fine-Tuning Appearance",
	"Adjusting Digital Expressions",
	"Synthesizing Avatar Style",
	"Bringing Avatar to Life",
]

export type UserState = {
	authenticated: boolean;
	user: UserObject | null;
	onboarding?: OnboardingObject;
};
export type DeviceInfoUUID = {
	/**
	 * The name of the device. For example, "John's iPhone".
	 *
	 * This is only supported on iOS and Android 7.1 or above.
	 *
	 * @since 1.0.0
	 */
	name?: string;
	/**
	 * The device model. For example, "iPhone13,4".
	 *
	 * @since 1.0.0
	 */
	model: string;
	/**
	 * The device platform (lowercase).
	 *
	 * @since 1.0.0
	 */
	platform: "ios" | "android" | "web";
	/**
	 * The operating system of the device.
	 *
	 * @since 1.0.0
	 */
	operatingSystem: OperatingSystem;
	/**
	 * The version of the device OS.
	 *
	 * @since 1.0.0
	 */
	osVersion: string;
	/**
	 * The manufacturer of the device.
	 *
	 * @since 1.0.0
	 */
	manufacturer: string;
	/**
	 * Whether the app is running in a simulator/emulator.
	 *
	 * @since 1.0.0
	 */
	isVirtual: boolean;
	/**
	 * Approximate memory used by the current app, in bytes. Divide by
	 * 1048576 to get the number of MBs used.
	 *
	 * @since 1.0.0
	 */
	memUsed?: number;
	/**
	 * How much free disk space is available on the normal data storage
	 * path for the os, in bytes.
	 *
	 * On Android it returns the free disk space on the "system"
	 * partition holding the core Android OS.
	 * On iOS this value is not accurate.
	 *
	 * @deprecated Use `realDiskFree`.
	 * @since 1.0.0
	 */
	diskFree?: number;
	/**
	 * The total size of the normal data storage path for the OS, in bytes.
	 *
	 * On Android it returns the disk space on the "system"
	 * partition holding the core Android OS.
	 *
	 * @deprecated Use `realDiskTotal`.
	 * @since 1.0.0
	 */
	diskTotal?: number;
	/**
	 * How much free disk space is available on the normal data storage, in bytes.
	 *
	 * @since 1.1.0
	 */
	realDiskFree?: number;
	/**
	 * The total size of the normal data storage path, in bytes.
	 *
	 * @since 1.1.0
	 */
	realDiskTotal?: number;
	/**
	 * The web view browser version
	 *
	 * @since 1.0.0
	 */
	webViewVersion: string;
	uuid?: string;
};

export type UserObject = {
	userID: string;
	email?: any;
	password?: any;
	emailVerified?: boolean;
	username?: string;
	heroID?: string; // the id of the hero
	inviteCode?: string;
	fitbitTokenRefresh?: string;
	fitbitAccessToken?: string;
	fitbitRefreshToken?: string;
	fitbitConnected?: boolean;
	createdAt?: string;
	updatedAt?: string;
	settings?: SettingsObject;
	timezone?: string;
	sideKickId?: string;
	sideKickThreadId?: string;
	firstName?: string;
};

// export type SubscriptionObject = {
// 	entitlements: {
// 		all: any;
// 		active: any;
// 		verification: string;
// 	};
// 	activeSubscriptions: any[];
// 	allPurchasedProductIdentifiers: any[];
// 	latestExpirationDate: any;
// 	firstSeen: string;
// 	originalAppUserId: string;
// 	requestDate: string;
// 	allExpirationDates: any;
// 	allPurchaseDates: any;
// 	originalApplicationVersion: any;
// 	originalPurchaseDate: string;
// 	managementURL: string;
// 	nonSubscriptionTransactions: any[];
// };

export type UserPoints = {
	objectId: string;
	createAt: string;
	updatedAt: string;
	userId: string;
	points: number;
}

export interface ExistingData {
	previousHeroObject: HeroObject;
	heroObject: HeroObject;
	summary: SummaryType[];
	activityLedger: ActivityLedger[];
	workouts: WorkoutObject[];
	lastUpdated: string;
	lastUpdatedSteps?: string;
}

export type AltogicSessionObject = {
	userId: string;
	token: string;
	creationDtm: string;
	userAgent: {
		family: string;
		major: string;
		minor: string;
		patch: string;
		device: {
			family: string;
			major: string;
			minor: string;
			patch: string;
		};
		os: {
			family: string;
			major: string;
			minor: string;
			patch: string;
		};
	};
	accessGroupKeys: string[];
};

export type HeroObject = {
	_id?: string;
	heroID?: string;
	createdAt?: string;
	updatedAt?: string;
	heroName?: string;
	heroToHit: number;
	heroDamage: number;
	heroDodge: number;
	heroHealth: number;
	heroImageURL: string;
	cardioLevel: number;
	cardioPoints: number;
	mindfulnessLevel: number;
	mindfulnessPoints: number;
	mobilityLevel: number;
	mobilityPoints: number;
	strengthLevel: number;
	strengthPoints: number;
	overallLevel: number;
	totalPoints: number;
	lastUpdated?: string;
	strengthTreeSpecialization?: string;
	cardioTreeSpecialization?: string;
	mobilityTreeSpecialization?: string;
	mindfulnessTreeSpecialization?: string;
	userId?: string;
	totalDuelPoints?: number;
	gender?: string;
	personalityType?: string;
	personalityTypeDescriprion?: string; // yea, I know it's spelled wrong, but it's spelled wrong in the database
	record?: {
		wins: number;
		losses: number;
		winPercentage: number;
		streak: number;
	}
	heroImages?: any;
};

export const EmptyAvatarImage = "/assets/images/mystery-avatar.png";

export interface OnboardingObject {
	url: string;
	completed: boolean;
	gender?: string;
	heroPicture?: string;
	heroName?: string;
	loggedActivity?: boolean;
	bonusPointsAwarded?: boolean;
	hero?: HeroObject;
	firstName?: string;
}

export type AttributeMatrix = {
	_id: string;
	createdAt: string;
	updatedAt: string;
	version: number;
	strengthAccuracy: number;
	strengthDamage: number;
	strengthHealth: number;
	strengthDodge: number;
	cardioAccuracy: number;
	cardioDamage: number;
	cardioHealth: number;
	cardioDodge: number;
	mobilityAccuracy: number;
	mobilityDamage: number;
	mobilityHealth: number;
	mobilityDodge: number;
	mindfulnessAccuracy: number;
	mindfulnessDamage: number;
	mindfulnessHealth: number;
	mindfulnessDodge: number;
	levelAccuracy: number;
	levelDamage: number;
	levelHealth: number;
	levelDodge: number;
	current: boolean;
};

export type BaselineStats = {
	_id: string;
	createdAt?: string;
	updatedAt?: string;
	version: string;
	accuracy: number;
	damage: number;
	health: number;
	dodge: number;
};

export type CoachMarks = {
	duelDetails?: boolean;
};

export type VersionInfo = {
	currentVersion: string;
	lastVersion: string;
	update: string;
	updateShown: boolean;
}

export type FitBitStatus = {
	objectId: string,
	userId: string,
	refreshFailures: number,
	accessToken?: string,
	refreshToken?: string,
	connected: boolean,
	createdAt: string,
	updatedAt: string,
}

export type SettingsObject = {
	sendChatNotifications?: boolean,
	sendDuelNotifications?: boolean,
	sendWeeklyEmails?: boolean
	promptToDuelInApp?: boolean,
	showInAppNotifications?: boolean,
}



export const GPTSystemPrompt = `I would like your help in generating prompts to be used in AI image generation tools. 
I will give you a list of attributes that I would like you to convert into prompts. 
These prompts need two components: A "prompt" and a "negative prompt". 
The prompt describes what should be in the image in great detail and the negative prompt describes what should NOT be in the image. 
Both the PROMPT and NEGATIVE PROMPT should only contain a list of comma separated descriptions, not full sentences. 
I will give you a list of attributes to use to create this list of descriptions. 
Where the attribute is "background", that is the scene for the prompt. 
Where attribute is "attire" that is what the character is wearing. 
For any attributes that are empty, please fill them in with a creative response. 
Here are examples: 
ATTRIBUTES: name: Silver Streak,eyeColor: Electric Blue,trait1: Fearless,race: Any ethnicity,trait2: Charismatic,
trait3: Quick-witted,trait4: Compassionate,hairstyle: Short, Spiky,hairColor: Silver,age: Young adult,gender: Male,
build: Lean and toned,accessories: Supercharged gauntlets, Goggles,background: Futuristic cityscape,expression: Determined,
attire: Sleek silver bodysuit, Knee-high boots. 
PROMPT: Silver Streak, Electric Blue eyes, Fearless, Any ethnicity, Charismatic, Quick-witted, Compassionate, Short, 
Spiky Silver hair, Young adult, Male, Lean and toned build, Wearing supercharged gauntlets, Wearing goggles, Futuristic cityscape, 
Determined expression, Wearing sleek silver bodysuit, Wearing knee-high boots. 
NEGATIVE PROMPT: double body, double face, double features, incorrect posture, two heads, two faces, plastic, Deformed, 
blurry, bad anatomy, bad eyes, crossed eyes, disfigured, poorly drawn face, mutation, mutated, {{extra limb}}, ugly, 
poorly drawn hands, missing limb, blurry, floating limbs, disconnected limbs, malformed hands, blur, out of focus, 
long neck, long body, {{{mutated hands and fingers}}}, {{{out of frame}}}, long fingers, blender, doll, cropped, 
low-res, close-up, poorly-drawn face, out of frame double, two heads, blurred, ugly, disfigured, too many fingers, 
deformed, repetitive, black and white, grainy, extra limbs, bad anatomy, High pass filter, airbrush, portrait, zoomed, 
soft light, smooth skin, closeup, deformed, extra limbs, extra fingers, mutated hands, bad anatomy, bad proportions , 
blind, bad eyes, ugly eyes, dead eyes, blur, vignette, out of shot, out of focus, gaussian`;

export const generalNegativeAvatarPrompt = `{{{superman}}}, {{{superman logo}}}, {{{superman outfit}}}, double body, double face, double features, incorrect posture, two heads, two faces, plastic, Deformed, blurry, bad anatomy, bad eyes, crossed eyes, disfigured, poorly drawn face, mutation, mutated, {{extra limb}}, ugly, poorly drawn hands, missing limb, blurry, floating limbs, disconnected limbs, malformed hands, blur, out of focus, long neck, long body, {{{mutated hands and fingers}}}, {{{out of frame}}}, long fingers, blender, doll, cropped, low-res, close-up, poorly-drawn face, out of frame double, two heads, blurred, ugly, disfigured, too many fingers, deformed, repetitive, black and white, grainy, extra limbs, bad anatomy, High pass filter, airbrush, portrait, zoomed, soft light, smooth skin, closeup, deformed, extra limbs, extra fingers, mutated hands, bad anatomy, bad proportions , blind, bad eyes, ugly eyes, dead eyes, blur, vignette, out of shot, out of focus, gaussian`;
