/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { IonAvatar, IonButton, IonContent, IonFooter, IonPage, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import HeaderLogo from "../../components/HeaderLogo";
import GlobalState from "../../contexts/GlobalState";
import { OnboardingObject } from "../../models/userModel";
import { StorageService } from "../../services/StorageService";
import { RateApp } from 'capacitor-rate-app';


const OnboardingComplete = () => {
	const history = useHistory();
	const storageService = new StorageService();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { updateUserState, serialStartupFunction } = globalState;
	const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();

	useEffect(() => {
		updateOnboardingObject();
		RateApp.requestReview();
	}, []);

	async function updateOnboardingObject() {
		let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding"); // Should this just include the full heroObject?
		tempOnboardingObject.url = "/";
		tempOnboardingObject.completed = true;
		setOnboardingObject(tempOnboardingObject);
		await storageService.setObject("onboarding", tempOnboardingObject);
	}

	async function completeOnboarding() {
		await updateUserState();
		await serialStartupFunction();
		history.push("/duels/progression");
	}

	return (
		<IonPage>
			<HeaderLogo />
			<IonContent>
				<div className="onboarding-main-div">
					<div className="villain-chat-container">
						<IonRow style={{ justifyContent: "center" }}>
							<div className="chat-bubble-onboarding">
								Alrighty <b>{onboardingObject?.firstName}</b> we're good to go. Let's get our first duel started!
							</div>
						</IonRow>
					</div>
					<IonAvatar style={{ margin: "auto", height: "250px", width: "250px" }}>
						<img
							className="user-avatar"
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
							src={onboardingObject?.heroPicture ?? "/assets/images/mystery-avatar.png"}
							alt="your fithero avatar"
						/>
					</IonAvatar>
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton className="onboarding-advance-button" onClick={() => completeOnboarding()}>
					Let's gooooooooo!
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default OnboardingComplete;
