import {
    IonHeader,
    IonToolbar
} from "@ionic/react";
import { useContext } from "react";
import GlobalState from "../contexts/GlobalState";

const HeaderLogoNonOnboarding = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");

    return (
        <>
            <IonHeader>
                <IonToolbar style={{ "--border-style": "none", "--background": "none", textAlign: "center" }}>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <img style={{ height: "50px", width: "50px" }} src="assets/images/fit-hero-shield-transparent.png" />
                    </div>
                </IonToolbar>
            </IonHeader>
        </>
    );
};

export default HeaderLogoNonOnboarding;
