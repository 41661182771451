import { IonButton, IonCol, IonIcon, IonRow, IonTextarea } from "@ionic/react";
import { sendSharp } from "ionicons/icons";
import { delay } from "lodash";
import log from "loglevel";
import React, { useRef, useState } from "react";
import "../../../css/ChatInput.css";
import { FullMessageObject, HeroMessage } from "../../../models/herochatModel";
import { insertNewLines } from "../../../services/HelperService";
import { processHeroResponse, sendHeroMessage } from "../../../services/HerochatService";
import { StorageService } from "../../../services/StorageService";
import { getUser_id } from "../../../services/UserService";
const storageService = new StorageService();
var globalContext = "";

const HeroChatInput = (props: {
	messages: HeroMessage[];
	setMessages: React.Dispatch<React.SetStateAction<any>>;
	setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	setScroller: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const [showToast, setShowToast] = useState(false);
	const [input, setInput] = useState("");
	const inputRef = useRef<any>(null);

	async function processInput(userInput: string, displayMessage: boolean) {
		if (userInput !== "") {
			setInput("");
			userInput = userInput.replace(/\n+$/, ""); // Remove trailing newlines

			let userMessage: HeroMessage = {
				source: "user",
				message: userInput,
			};
			props.setMessages([...props.messages, userMessage]); // this shows the message immediately

			let fullMessageObject: FullMessageObject = await sendHeroMessage(userInput);
			if (!fullMessageObject.heroMessageObject ||
				fullMessageObject.heroMessageObject.message === "" ||
				Object.keys(fullMessageObject.heroMessageObject).length === 0) {
				log.debug("No hero message object returned, likely conversation ended.");
				return;
			}
			let displayMessages = await processHeroResponse(fullMessageObject);

			let lastHeroMessage: HeroMessage = displayMessages[displayMessages.length - 1];
			let lastHeroMessageArray = insertNewLines(lastHeroMessage.message).split("\n");
			lastHeroMessageArray.forEach((message, index) => {
				props.setProcessing(true);
				// let randomMessageDelay = Math.floor(Math.random() * 3000) + 2000;
				let randomMessageDelay = 4000;
				setTimeout(() => {
					let heroMessage: HeroMessage = {
						...lastHeroMessage,
						message: message
					};
					// Using functional update to ensure we have the latest state
					props.setMessages((prevMessages: HeroMessage[]) => [...prevMessages, heroMessage]);

					if (index === lastHeroMessageArray.length - 1) {
						props.setProcessing(false);
					} else {
						props.setProcessing(true);
					}
				}, randomMessageDelay * (index + 1));
			});
		}
	}

	// function handleKeyUp(event) {
	// 	if (event.keyCode === 13) {
	// 		submitText();
	// 	}
	// }

	async function inputFocus() {
		log.debug("Focus on input. Should scroll");
		props.setScroller(true);
	}

	window.addEventListener("keyboardDidShow", async (event) => {
		// log.debug("KEYBOARD SHOWED");
		props.setScroller(true);
	});

	window.addEventListener("keyboardDidHide", async (event) => {
		// log.debug("KEYBOARD HID");
		props.setScroller(true);
	});

	// if not show a text box with a submit button
	return (
		<>
			<IonRow className="chat-input-row">
				<IonCol size="11">
					<IonTextarea
						value={input}
						autoGrow={true}
						rows={1}
						className="chat-input-textarea"
						ref={inputRef}
						placeholder="Type message..."
						// onKeyUp={handleKeyUp}
						onFocus={inputFocus}
						onKeyUp={(event) => {
							if (event.key === "Enter") {
								processInput(inputRef.current.value, true);
							}
						}}
					></IonTextarea>
				</IonCol>
				<IonCol size="1">
					<IonButton onClick={() => processInput(inputRef.current.value, true)} className="send-button" color="chat-bar-button" style={{ fontSize: "0px" }}>
						<IonIcon size="small" icon={sendSharp}></IonIcon>
					</IonButton>
				</IonCol>
			</IonRow>
		</>
	);
};

export default HeroChatInput;
