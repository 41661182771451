/* eslint-disable react-hooks/exhaustive-deps */
import { IonButton, IonContent, IonFooter, IonLoading, IonPage } from '@ionic/react';
import log from 'loglevel';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import InAppCelebrationContainer from '../../Celebrations/InAppNotificationContainer';
import HeroStatsCircularComponent from '../../Features/Profile/HeroStatsCircularComponent';
import HeaderLogo from '../../components/HeaderLogo';
import ProfileHeader from '../../Features/Profile/components/ProfileHeader';
import GlobalState from '../../contexts/GlobalState';
import { WorkoutObject } from '../../models/exerciseModels';
import { OnboardingObject } from '../../models/userModel';
import { convertManualActivityToWorkoutObject, createLedgerFromWorkoutArray, postWorkoutsToServer } from '../../services/ExerciseService';
import { StorageService } from '../../services/StorageService';

const BonusPoints = () => {
    const nextPage = "/onboarding/famousexamples";
    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, updateHeroHealth } = globalState;
    const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
    const [adding, setAdding] = useState<boolean>(false);

    useEffect(() => {
        updateOnboardingObject();
    }, []);

    useEffect(() => { }, [onboardingObject]);

    async function updateOnboardingObject() {
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        setOnboardingObject(tempOnboardingObject);
        await storageService.setObject("onboarding", tempOnboardingObject);
    }

    async function addBonusPoints() {
        setAdding(true)
        log.debug("[BonusPoints] heroObject: ", heroObject);
        let date = moment().format("YYYY-MM-DDTHH:mm[Z]");
        let duration = 600; // 10 mins
        let activityName = "Initial Points";
        let workoutObject = await convertManualActivityToWorkoutObject(date, duration, activityName, "low", 0)
        let workoutArray: WorkoutObject[] = [workoutObject];
        let activityLedger = await createLedgerFromWorkoutArray(workoutArray);
        if (workoutArray) {
            await postWorkoutsToServer(workoutArray, false);
            let currentWorkouts: WorkoutObject[] | null = await storageService.getObject("workouts");
            if (currentWorkouts === null) currentWorkouts = [];
            await storageService.setObject("workouts", [...currentWorkouts, ...workoutArray]);
        }
        await updateHeroHealth(activityLedger, workoutArray, moment(), true);
        if (onboardingObject !== undefined) {
            let onbObj = { ...onboardingObject, bonusPointsAwarded: true };
            storageService.setObject("onboarding", onbObj);
            setOnboardingObject({ ...onboardingObject, bonusPointsAwarded: true });
        }
        setAdding(false);
    }
    return (
        <IonPage>
            <HeaderLogo />
            <IonContent>
                <IonLoading isOpen={adding} message={"Adding bonus points..."} />
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Some bonus points!</div>
                    <ProfileHeader heroName={onboardingObject?.heroName} heroPicture={onboardingObject?.heroPicture} heroLevel={heroObject?.overallLevel} />

                    <HeroStatsCircularComponent />
                    <div className='onboarding-text-area'>
                        {onboardingObject && !onboardingObject.bonusPointsAwarded ? (
                            <>
                                <p>
                                    Because you've probably done SOMETHING else active in the last 24 hours, let's get you started with some bonus points.
                                </p>

                            </>
                        ) : (
                            <>
                                <InAppCelebrationContainer />
                                <div className='onboarding-text-area '>
                                    <p>
                                        Yea thats better. You now have a solid foundation for your first duel.
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                </div>

            </IonContent>
            {onboardingObject && onboardingObject.bonusPointsAwarded === true && (
                <IonFooter className="onboarding-footer">
                    <IonButton className="onboarding-advance-button" routerLink={nextPage}>
                        Cool
                    </IonButton>
                </IonFooter>
            )}
            {onboardingObject && !onboardingObject.bonusPointsAwarded && (
                <IonFooter className="onboarding-footer"><IonButton onClick={() => addBonusPoints()} className="onboarding-advance-button" >Let's do it!</IonButton></IonFooter>
            )}

        </IonPage>
    )
}

export default BonusPoints