import {
    IonAvatar,
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonRow,
    IonSpinner,
    IonToolbar
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../../contexts/GlobalState";
import "../../css/PayWall.css";
import "../../css/Profile.css";

import { App } from "@capacitor/app";
import { Device, DeviceInfo } from "@capacitor/device";
import { MakePurchaseResult, Purchases } from "@revenuecat/purchases-capacitor";
import { UserAvatar } from "../../components/UserAvatar";
import { annualPricePoints, featuredPricePoint, lifetimePricePoints, monthlyPricePoints } from "../../models/paywallConstants";
import { logGenericEvent, logGenericEventWithObject } from "../../services/AnalyticsService";
import { isOnboardingCompleted } from "../../services/HelperService";
import { smartLog } from "../../services/LoggingService";
import { sendSlackErrorNotification, sendSlackNotification } from "../../services/NotificationService";
import { purchaseProductAndroid, purchaseProductIOS } from "../../services/PaywallService";
import { getHero, getUser } from "../../services/UserService";
import { useHistory } from "react-router";
import { PayWallCelebrationComponent } from "./PayWallCelebrationComponent";
import { RateApp } from 'capacitor-rate-app';
import { PromoCodeComponent } from "./PromoCodeComponent";
import { userObjectKey } from "../../models/userModel";
import { StorageService } from "../../services/StorageService";
let storageService = new StorageService();

export const PayWallClassDojoComponent = (props: { modal?: boolean, setShowPaywall?: React.Dispatch<React.SetStateAction<boolean>>, skipable?: boolean, link?: string }) => {
    const purchaseTesting = false;

    const history = useHistory();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { userState, setupRevenueCat, serialStartupFunction, isMobile, heroObject, setHeroObject, productsForPaywall, paywallFeatures, setCurrentlySubscribed, currentlySubscribed, subscriptionInfo } = globalState;
    const [selectedPricePoint, setSelectedPricePoint] = useState<string>();
    const [selectedProduct, setSelectedProduct] = useState<any>();
    const [monthlyPrice, setMonthlyPrice] = useState<number | null>(null);
    const [showCelebration, setShowCelebration] = useState<boolean>(false);
    const [showPromoCode, setShowPromoCode] = useState<boolean>(false);
    const [userFirstName, setUserFirstName] = useState<string>("");

    useEffect(() => {
        logGenericEvent("Paywall Page Viewed");
        if (userState?.user?.firstName) {
            setUserFirstName(userState.user.firstName);
        } else {
            storageService.getObject(userObjectKey).then((user) => {
                if (user && user.firstName) {
                    setUserFirstName(user.firstName);
                }
            });
        }
    }, []);

    useEffect(() => {
        // smartlog("selectedPricePoint: ", selectedPricePoint);
        if (productsForPaywall && productsForPaywall.length > 0 && !selectedPricePoint && !selectedProduct) {
            smartLog("[PayWallClassDojoComponent] productsForPaywall: ", productsForPaywall);
            const maxPriceProduct = productsForPaywall.reduce((prev, current) => {
                return prev.price > current.price ? prev : current;
            });
            setSelectedPricePoint(maxPriceProduct.identifier);
            setSelectedProduct(maxPriceProduct);
        }
        if (!productsForPaywall || productsForPaywall.length === 0) { // usually in onboarding
            getProducts();
            retrieveHero();
        }
    }, [selectedPricePoint]);

    useEffect(() => {
        smartLog("[PayWallClassDojoComponent]: does this trigger on refresh?");
        smartLog("[PayWallClassDojoComponent]: useEffect triggered");
    }, [subscriptionInfo]);

    async function getProducts() {
        smartLog("Error: productsForPaywall is empty");
        let user = await getUser();
        let platform = (await Device.getInfo()).platform;
        await setupRevenueCat(platform, user!);
    }

    async function retrieveHero() {
        let hero = await getHero();
        let user = await getUser();
        sendSlackNotification(`*[Paywall Viewed]*\n*UserID:* ${user?.userID}\n*Hero Name:* ${hero?.heroName}`, "purchase", true);
        setHeroObject(hero)
    }
    async function triggerPurchase(validPromoCode: boolean = false) {
        smartLog("[triggerPurchase] Removing listeners...");
        await App.removeAllListeners();
        let purchaseSuccesful;
        if (selectedPricePoint && selectedProduct && !purchaseTesting && validPromoCode === false) {
            const user = await getUser();
            const hero = await getHero();
            smartLog("[triggerPurchase] attempted purchase: ", selectedPricePoint);
            smartLog("[triggerPurchase] attempted purchase: ", selectedProduct);
            logGenericEvent(`attempting to purchase ${selectedPricePoint}`);
            sendSlackNotification(`*[NEW PURCHASE ATTEMPTED]*\n*UserID:* ${user?.userID}\n*Hero Name:* ${hero?.heroName}\n*Product ID:* ${selectedPricePoint}`, "purchase", true);
            let device: DeviceInfo = await Device.getInfo();
            if (device.platform === "web") {
                alert("Purchases are not available on the web. Please try in the FitHero.ai mobile app.");
                logGenericEvent(`Error purchasing: Web purchasing not available`);
                sendSlackNotification(`❌ Error purchasing for UserID: ${user?.userID}\n*Error*: Web purchasing not available`, "purchase", true);
                // try {
                //     const purchase = await Purchases.purchaseStoreProduct({ product: selectedProduct });
                //     smartLog("[triggerPurchase] purchase: ", purchase);
                //     logGenericEvent(`successfully purchased: ${selectedPricePoint}`);
                //     sendSlackNotification(`Purchase Complete for UserID: ${user?.userID}`, "keyEvent", true);
                //     purchaseSuccesful = true;
                // } catch (error) {
                //     smartLog("[triggerPurchase] Error purchasing: ", error);
                //     sendSlackNotification(`Error purchasing for UserID: ${user?.userID}\n*Error*: ${error}`, "keyEvent", true);
                //     logGenericEvent(`Error purchasing: ${error}`);
                //     purchaseSuccesful = false;
                // }
            } else if (device.platform === "ios") {
                let result: MakePurchaseResult | null = await purchaseProductIOS(selectedProduct, selectedPricePoint, user!);
                if (result && result.customerInfo.entitlements.active.powerUp.isActive === true) {
                    purchaseSuccesful = true;
                }
            } else if (device.platform === "android") {
                let result: MakePurchaseResult | null = await purchaseProductAndroid(selectedProduct, selectedPricePoint, user!);
                if (result && result.customerInfo.entitlements.active.powerUp.isActive === true) {
                    purchaseSuccesful = true;
                }
            }
        } else {
            smartLog("Error: selectedPricePoint is null");
        }
        // re-establish listeners
        smartLog("[triggerPurchase] Restoring listeners...");
        App.addListener("appStateChange", async ({ isActive }) => {
            let onboardingCompleted = await isOnboardingCompleted();
            smartLog("[App] App state changed. onboardingCompleted?", onboardingCompleted);
            if (isActive === true && (onboardingCompleted === true || onboardingCompleted === null)) {
                smartLog("[App] Getting Messages & Data from appStateChange");
                await serialStartupFunction();
            }
        });
        if (purchaseSuccesful === true || validPromoCode === true) {
            smartLog("Purchase successful");
            logGenericEventWithObject("purchase", { product: selectedPricePoint });
            setCurrentlySubscribed(true);
            setShowCelebration(true);
        }
        if (purchaseTesting) {
            smartLog("Test Purchase successful");
            setShowCelebration(true);
        }
    }

    async function finishPurchase() {
        if (props.setShowPaywall) {
            props.setShowPaywall(false);
            // RateApp.requestReview();
        } else if (props.link) {
            smartLog("Pushing to: ", props.link);
            history.push(props.link);
            // RateApp.requestReview();
        }
    }

    async function selectProduct(product: any) {
        console.log("selected product: ", product);
        setSelectedPricePoint(product.identifier);
        setSelectedProduct(product);
    }
    return (
        <>
            <div>
                <div>
                    <div className="paywall-close-button">
                        {props.modal === true ? (
                            <>
                                <IonHeader>
                                    <IonToolbar style={{ "--background": "none" }}>
                                        <IonButtons slot="end">
                                            <IonButton strong={true} onClick={() => props.setShowPaywall!(false)}>
                                                X
                                            </IonButton>
                                        </IonButtons>
                                    </IonToolbar>
                                </IonHeader>
                            </>
                        ) : (
                            <>
                                {isMobile && (
                                    <IonButtons slot="start">
                                        <IonBackButton text="" />
                                    </IonButtons>
                                )}
                            </>
                        )}

                    </div>
                    {/* <img className="paywall-top-image" src="https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/a23ce28225671e47d06540883217bfd8_ClassDojoTopImage.webp" /> */}
                </div>
                {/* <div className="paywall-avatar">
                    <IonAvatar className="member-image" style={{ margin: "auto" }} >
                        <UserAvatar avatarImage={heroObject?.heroImageURL} />
                    </IonAvatar>
                </div> */}
            </div>
            <IonModal isOpen={showCelebration} onDidDismiss={() => finishPurchase()} className="paywall-celebration-modal">
                <PayWallCelebrationComponent setShowCelebration={setShowCelebration} />
            </IonModal>
            <IonModal isOpen={showPromoCode} onDidDismiss={() => setShowPromoCode(false)} className="villain-difficulty-modal">
                <PromoCodeComponent setShowPromoCode={setShowPromoCode} triggerPurchase={triggerPurchase} />
            </IonModal>
            <IonContent>
                {/* <div className="paywall-header-text">
                    {heroObject && heroObject.heroName && (<><span className="paywall-hero-name">{heroObject.heroName}</span><br /><br /></>)}Unlock the full FitHero.ai Experience!
                </div> */}
                <div className="villain-chat-container">
                    <IonRow style={{ justifyContent: "center" }}>
                        <div className="chat-bubble-onboarding">
                            Let's get a jump start on our journey together  <b>{userFirstName}</b>!<br /><br />Try <b>FitHero Premium</b> for 7 days.
                        </div>
                    </IonRow>
                </div>
                <IonAvatar style={{ margin: "auto", height: "250px", width: "250px" }}>
                    <img
                        className="user-avatar"
                        style={{ width: "100%", height: "100%", objectFit: "cover" }}
                        src={heroObject?.heroImageURL ?? "/assets/images/mystery-avatar.png"}
                        alt="your fithero avatar"
                    />
                </IonAvatar>
                <div className="paywall-features">
                    <IonGrid>
                        {paywallFeatures && paywallFeatures.map((feature, index) => {
                            return (
                                <IonRow className="paywall-feature-row" key={index}>
                                    <IonCol className="paywall-feature-bullet" size="1">
                                        <div className="paywall-feature-bullet-div">{feature.icon}</div>
                                    </IonCol>
                                    <IonCol className="paywall-feature-text" size="10">
                                        {feature.text}
                                    </IonCol>
                                </IonRow>
                            )
                        })}

                    </IonGrid>
                </div>
                <div className="paywall-price-points">
                    <IonRadioGroup value={selectedPricePoint} className="paywall-radio-group">
                        {!productsForPaywall && (
                            <IonSpinner />
                        )}
                        {productsForPaywall && productsForPaywall.map((product, index) => {
                            try {
                                let showPopular = false;
                                let priceClass = "paywall-price-point";
                                let showCrossedOutPrice = false;
                                let showTrial = true
                                let crossedOutPrice = 0;
                                let priceShown = product.price.toFixed(2);
                                let month = "1";
                                let monthText = "month"
                                if (product.identifier === selectedPricePoint) {
                                    priceClass += " selected";
                                }
                                if (product.identifier === featuredPricePoint) {
                                    showPopular = true;
                                }
                                // smartLog("product: ", product);
                                if (monthlyPricePoints.includes(product.identifier) && monthlyPrice === null) {
                                    // smartLog("Setting monthly price: ", product.price);
                                    setMonthlyPrice(product.price);
                                }
                                if (annualPricePoints.includes(product.identifier) && monthlyPrice) {
                                    if (product.price < monthlyPrice * 12) {
                                        showCrossedOutPrice = true;
                                        crossedOutPrice = monthlyPrice * 12;
                                        // priceShown = (product.price / 12).toFixed(2);

                                    }
                                    month = "12";
                                    monthText = "months";
                                }
                                if (lifetimePricePoints.includes(product.identifier)) {
                                    month = "";
                                    monthText = "Lifetime";
                                    showTrial = false;
                                    // priceShown = product.price.toFixed(2);
                                }

                                return (
                                    <>
                                        {showPopular === true && <div className="paywall-popular">Popular!</div>}
                                        <div key={product.identifier} className={priceClass} onClick={() => selectProduct(product)} >
                                            {/* {showCrossedOutPrice && (
                                                <>
                                                    <div className="paywall-price-point-savings-trial">
                                                        Free trial
                                                    </div>
                                                </>
                                            )} */}
                                            <div className="paywall-price-point-inner">
                                                <div className="paywall-price-point-inner-month-number">
                                                    {month}
                                                </div>
                                                <div className="paywall-price-point-inner-selected">
                                                    <IonRadio value={product.identifier} mode="ios" />
                                                </div>
                                                <div className="paywall-price-point-inner-month-text">
                                                    {monthText}
                                                </div>
                                                <div className="paywall-price-point-inner-price">
                                                    ${priceShown}
                                                </div>

                                            </div>
                                            {/* {showTrial && (
                                                <>
                                                    <div className="paywall-price-point-savings">
                                                        Free trial
                                                    </div>
                                                </>
                                            )} */}
                                        </div>
                                    </>
                                )
                            } catch (error) {
                                alert("Error pulling price points. Please try again later.");
                                sendSlackErrorNotification("Error pulling price points", error);
                                smartLog("Error: ", error);
                            }
                        })}
                    </IonRadioGroup>
                </div>
                {selectedPricePoint && (
                    <>
                        {(annualPricePoints.includes(selectedPricePoint) || monthlyPricePoints.includes(selectedPricePoint)) && (
                            <div className='paywall-trial-info'>
                                7 day trial, cancel anytime
                            </div>
                        )}
                        <div className="paywall-purchase-button">
                            <IonButton className="paywall-purchase-button" color="tertiary" expand="block" onClick={() => triggerPurchase()}>
                                {monthlyPricePoints.includes(selectedPricePoint) && ("Start Free Trial")}
                                {annualPricePoints.includes(selectedPricePoint) && ("Start Free Trial")}
                                {lifetimePricePoints.includes(selectedPricePoint) && ("Purchase")}
                            </IonButton>
                        </div>
                    </>
                )}
                <div style={{ textAlign: "center" }}>
                    <IonButton className="button-as-link" onClick={() => setShowPromoCode(true)}>
                        Enter Promo Code
                    </IonButton>
                </div>
                {props.modal === false && props.skipable === true && (
                    <div className="onboarding-app-button-div" style={{ textAlign: "center" }}>
                        <IonButton className="button-as-link" routerLink={props.link!}>
                            Skip for now
                        </IonButton>
                    </div>
                )}
            </IonContent >
        </>
    )
}