import React, { useEffect, useState } from 'react'
import { DuelStatus } from '../../../models/duelsModels'
import { IonCol, IonGrid, IonRow } from '@ionic/react'
import log from 'loglevel'

const DuelRecord = (props: { duelStatus: DuelStatus[] }) => {
    interface DuelRecord {
        wins: number,
        losses: number,
        winPercentage: string,
        streak: string
    }
    const [duelRecord, setDuelRecord] = useState<DuelRecord>({
        wins: 0,
        losses: 0,
        winPercentage: "0%",
        streak: "-"
    })

    useEffect(() => {
        log.debug("DuelRecord useEffect triggered")
        if (props.duelStatus) {
            let wins = 0
            let losses = 0
            let streakNumber = 0
            let streakType = ""
            let completedDuels = props.duelStatus.filter(duel => duel.complete)
            // sort completedDuels by date newest to oldest
            completedDuels.sort((a, b) => {
                let aDate = new Date(a.updatedAt)
                let bDate = new Date(b.updatedAt)
                return bDate.getTime() - aDate.getTime()
            })
            // calculate the user current win streak or lose streak from completedDuels
            // get the most recent duel and set the streakType to the winner
            if (completedDuels.length > 0) {
                if (completedDuels[0].winner === "hero") {
                    streakType = "W"
                } else {
                    streakType = "L"
                }
                let streakOver = false
                completedDuels.forEach(duel => {
                    if (duel.winner === "hero") {
                        wins++
                        if (streakOver === false) {
                            if (streakType === "W") {
                                streakNumber++
                            } else {
                                streakOver = true
                            }
                        }
                    } else {
                        losses++
                        if (streakOver === false) {
                            if (streakType === "L") {
                                streakNumber++
                            } else {
                                streakOver = true
                            }
                        }
                    }
                })
            }

            // calculate the users current streak, win or lose from completedDuels
            let streak = "-"
            if (streakType !== "") {
                streak = streakType + streakNumber
            }
            let winPercentage = ((wins / (wins + losses)) * 100).toFixed(0)
            if (isNaN(Number(winPercentage))) { winPercentage = "-" }
            setDuelRecord({
                wins: wins,
                losses: losses,
                winPercentage: winPercentage,
                streak: streak
            })
        }
    }, [props.duelStatus])

    return (
        <>
            {/* <IonGrid style={{ marginInline: "16px" }}> */}
            <IonRow style={{ textAlign: "center", background: "rgb(0 161 255 / 20%)", color: "white" }}>
                <IonCol className='duel-record-columns'>
                    <div className='duel-record-label'>Record</div>
                    <div className='duel-record-stat'>{duelRecord.wins} - {duelRecord.losses}</div>
                </IonCol>
                <IonCol className='duel-record-columns' style={{ borderLeft: "solid", borderRight: "solid" }}>
                    <div className='duel-record-label'>Win %</div>
                    <div className='duel-record-stat'>{duelRecord.winPercentage}%</div>
                </IonCol>
                <IonCol className='duel-record-columns'>
                    <div className='duel-record-label'>Current Streak</div>
                    <div className='duel-record-stat'>{duelRecord.streak}</div>
                </IonCol>
            </IonRow>
            {/* </IonGrid> */}
        </>
    )
}

export default DuelRecord