import { HeroStats } from "../models/exerciseModels";


export const Cristiano = {
    name: "Ristiano Conaldo",
    image: "../assets/images/onboarding/athletes/cristiano.jpg",
    stats: {
        strengthLevel: 140,
        cardioLevel: 240,
        mobilityLevel: 185,
        mindfulnessLevel: 175,
        strengthPoints: (140 * 100 / 2),
        cardioPoints: (240 * 100 / 2),
        mobilityPoints: (185 * 100 / 2),
        mindfulnessPoints: (175 * 100 / 2),
    } as HeroStats
}

export const TheRock = {
    name: "El Roque",
    image: "../assets/images/onboarding/athletes/ElRoque.jpg",
    stats: {
        strengthLevel: 260,
        cardioLevel: 90,
        mobilityLevel: 145,
        mindfulnessLevel: 170,
        strengthPoints: (260 * 100 / 2),
        cardioPoints: (90 * 100 / 2),
        mobilityPoints: (145 * 100 / 2),
        mindfulnessPoints: (170 * 100 / 2),
    } as HeroStats
}

export const Serena = {
    name: "Serena",
    image: "../assets/images/onboarding/athletes/Serena.jpg",
    stats: {
        strengthLevel: 170,
        cardioLevel: 160,
        mobilityLevel: 155,
        mindfulnessLevel: 210,
        strengthPoints: (170 * 100 / 2),
        cardioPoints: (160 * 100 / 2),
        mobilityPoints: (155 * 100 / 2),
        mindfulnessPoints: (210 * 100 / 2),
    } as HeroStats
}

export const Simone = {
    name: "Timone Siles",
    image: "../assets/images/onboarding/athletes/Simone.jpg",
    stats: {
        strengthLevel: 115,
        cardioLevel: 135,
        mobilityLevel: 230,
        mindfulnessLevel: 195,
        strengthPoints: (115 * 100 / 2),
        cardioPoints: (135 * 100 / 2),
        mobilityPoints: (230 * 100 / 2),
        mindfulnessPoints: (195 * 100 / 2),
    } as HeroStats
}

export const FamousAthletes = [
    Serena,
    Simone,
    Cristiano,
    TheRock,
]



