//import awsmobile from "../aws-exports";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,

    IonPage,
    IonRow,
    IonToolbar,
} from "@ionic/react";
import Parse from "parse";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router";
import "../css/Register.css";
import { validateEmail } from "../services/UserService";
import log from "loglevel";

const ForgotPassword = () => {
    const history = useHistory();
    const loggingInMessage = "Password succesfully changed. Logging you in";
    // const { login, setPullingUpdates } = useContext(AuthContext);

    const [loggingIn, setLoggingIn] = useState({
        visible: false,
        message: "",
    });

    const [validatingData, setValidatingData] = useState({
        visible: false,
        message: null,
    });

    const [resetPWError, setResetPWError] = useState({
        visible: false,
        message: "",
    });

    const [passwordError, setPasswordError] = useState({
        visible: false,
        message: null,
    });

    const [emailSentNotice, setEmailSentNotice] = useState({
        visible: false,
        message: "",
    });

    const [codeError, setCodeError] = useState({
        visible: false,
        message: null,
    });

    const [generalError, setGeneralError] = useState({
        visible: false,
        message: null,
    });

    const emailRef = useRef<any>(null);

    async function sendResetPWEmail() {
        if (emailRef.current.value) {
            const email = emailRef.current.value;
            let validEmail = await validateEmail(email).catch((error) => {
                log.debug("error: ", error);
                setResetPWError({
                    visible: true,
                    message: "Invalid email address",
                });
            });
            if (validEmail) {
                await Parse.User.requestPasswordReset(email).then((response) => {
                    log.debug("response: ", response);
                    clearError();
                    setEmailSentNotice({
                        visible: true,
                        message: "Email sent",
                    });
                }).catch((error) => {
                    log.debug("error: ", error);
                    setError(error.items[0].code);
                });
            } else {
                setError("Invalid email address");
            }
        }
    }

    function setError(message: string) {
        setResetPWError({
            visible: true,
            message: message,
        });
    }

    function clearError() {
        setResetPWError({
            visible: false,
            message: "",
        });
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ "--border-style": "none" }}>
                    <IonButtons slot="start">
                        <IonBackButton text=" " color="primary" />
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="login-main-div">
                    <p className="login-title">Forgot Password</p>
                    <IonItem style={{ "--min-height": "90px" }}>
                        <IonLabel className="login-label" style={{ fontSize: "20px" }} position="stacked">
                            Enter your email address
                        </IonLabel>
                        {/* <div style={{ marginTop: "20px" }}>Enter your email address</div> */}
                        <div>
                            <IonInput aria-label="" autoFocus={true} ref={emailRef} name="email" type={"email"} placeholder="whoops@doh.com" enterkeyhint="send" required></IonInput>
                        </div>
                    </IonItem>
                    {resetPWError.visible === true && (
                        <p className="error-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
                            {resetPWError.message}
                        </p>
                    )}
                    {emailSentNotice.visible === true && (
                        <p className="notification-message" style={{ marginLeft: "20px", marginRight: "20px", marginTop: "10px" }}>
                            {emailSentNotice.message}
                        </p>
                    )}
                    <div>
                        <IonRow>
                            <IonCol size="2"></IonCol>
                            <IonCol size="8">
                                <IonButton onClick={sendResetPWEmail} expand="block">
                                    Send Reset Code
                                </IonButton>
                            </IonCol>
                            <IonCol size="2"></IonCol>
                        </IonRow>
                    </div>

                </div>
            </IonContent>
        </IonPage >
    );
};
export default ForgotPassword;
