import {
    IonCard,
    IonCardContent,
    IonCol,
    IonItem,
    IonRow,
    IonSpinner
} from "@ionic/react";
import "../../../css/Duels.css";
import { DuelStatus } from "../../../models/duelsModels";
import { HeroObject } from "../../../models/userModel";
import HealthIndicator from "./HealthIndicator";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { getVillain } from "../../../services/VillainServices";
import { Villain } from "../../../models/villainModels";
import { timeBetweenDates } from "../../../services/HelperService";
import moment from "moment";

const DuelStatusTable = (props: { duel: DuelStatus, index: number, completed: boolean, hero: HeroObject | undefined, otherUser?: boolean }) => {
    const history = useHistory();

    const isEven = props.index % 2 === 0;
    const rowStyle = isEven ?
        { /* styles for even rows */ backgroundColor: 'rgba(0, 161, 255, 0.2)' } :
        { /* styles for odd rows */ backgroundColor: 'rgba(0, 161, 255, 0.4)' };

    const date = timeBetweenDates(moment(), moment(props.duel.updatedAt));

    function showDuel() {
        if (props.otherUser !== true) {
            history.push(`/dueldetails/${props.duel.statusId}/updates`)
        }
    }

    function difficultyColor(difficulty: string | undefined) {
        switch (difficulty) {
            case "easy":
                return "green"
                break;
            case "medium":
                return "blue"
                break;
            case "hard":
                return "orange"
                break;
            case "boss":
                return "red"
                break;
            default:
                return "gray"
                break;
        }
    }

    return (
        <IonRow style={rowStyle} key={props.index} className="duel-table-row" onClick={() => showDuel()}>
            <IonCol size="2" className="duel-table-avatar">
                <img className="duel-table-avatar" src={props.duel.villainImageURL ?? "/assets/images/mystery-avatar.png"} alt={props.duel.villainName} />
            </IonCol>
            <IonCol size="4" className="duel-table-name">
                <div className="name">{props.duel.villainName}</div>
                <div className="level">Level: {props.duel.villainLevel ? (props.duel.villainLevel) : ("")} (<span style={{ color: difficultyColor(props.duel.difficulty) }}>{props.duel.difficulty ? (props.duel.difficulty) : ""}</span>)</div>
            </IonCol>
            <IonCol size="3" className="duel-table-result">
                {props.duel.winner === "hero" ? (
                    <>
                        <div className="won">
                            Won Duel
                        </div>
                    </>
                ) : (
                    <>
                        <div className="lost">
                            Lost Duel
                        </div>
                    </>
                )}
                <div className="date">
                    {date}
                </div>
            </IonCol>
            <IonCol size="3" className="duel-table-score">
                <div>
                    {props.duel.heroHealth} - {props.duel.villainHealth}
                </div>
            </IonCol>
        </IonRow>
    )
}

export default DuelStatusTable;