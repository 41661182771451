import { HeroObject, UserObject } from "../models/userModel";

export function replaceStepHeroTextBubble(steps: any, heroObject: HeroObject | undefined, user: UserObject | null) {
    let replacedSteps = steps;
    // replace every instance of <heroImage> with the hero's image
    let heroChatImage = `
    <div class="villain-chat-container">
        <ion-row style="justify-content: center;">
            <div class="tour-bubble">
                <p id="message"><text></p>
            </div>
        </ion-row>
    </div>
    <div class="hero-avatar-image-container-tour">
        <img src="${heroObject?.heroImageURL}" alt="Boss" class="hero-avatar-tour boss-image" id="bossImage"/>
    </div>`;
    replacedSteps = replacedSteps.map((step: any) => {
        // first, replace any variables in the intro text
        step.intro = step.intro.replace("<firstName>", `<b>${user?.firstName}</b>` || "");
        let text = step.intro;
        let customizedHeroChatImage = heroChatImage.replace("<text>", text);
        step.intro = customizedHeroChatImage
        return step;
    });
    return replacedSteps;
}