import { IonAvatar, IonCol, IonContent, IonRow, IonSpinner } from "@ionic/react";
import log from "loglevel";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/HeroChatComponent.css";
import { HeroMessage, heroDisplayMessagesKey } from "../../../models/herochatModel";
import { excludedText } from "../../../models/sideKickModels";
import { formatMomentDate, insertNewLines, stringContains } from "../../../services/HelperService";
import { StorageService } from "../../../services/StorageService";
import { ActivityLedger, cardioIcon, mindfulnessIcon, mobilityIcon, strengthIcon } from "../../../models/exerciseModels";
import Emoji from "react-emoji-render";
import { getActivityById, getIconFromExerciseActivity } from "../../../services/ExerciseService";
const storageService = new StorageService();

const HeroChatRenderer = (props: {
	messages: HeroMessage[];
	setMessages: React.Dispatch<React.SetStateAction<HeroMessage[]>>;
	processing: any;
	setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	scroller: boolean;
	setScroller: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { heroObject, heroMessagesLoadingFinished } = globalState;

	const feedEndRef = useRef<HTMLDivElement | null>(null);
	const typingMessage = "Your FitHero is typing...";
	let waitTime = 1000; // just adding 1 incase it's nil

	useEffect(() => {
		props.setProcessing(true);
		log.debug("HeroChatRenderer loading messages");
		storageService.getObject(heroDisplayMessagesKey).then((result) => {
			if (result !== null) {
				// sort results by createdAt
				result.sort((a: HeroMessage, b: HeroMessage) => {
					return moment(a.createdAt).diff(moment(b.createdAt));
				});
				props.setMessages(result);
				props.setScroller(true);
				log.debug("Messages loaded");
				scrollView();
			}
			props.setProcessing(false);
		});
	}, [heroObject, heroMessagesLoadingFinished]);

	useEffect(() => {
		scrollView();
		// log.debug("Use Effect Triggered");


		//log.debug("Current Messages Array (useEffect): "+JSON.stringify(messages));
	}, [props.messages]);

	function scrollView() {
		if (props.scroller === true) {
			// log.debug("Messages: ", props.messages);
			if (feedEndRef.current !== null) {
				setTimeout(() => {
					try {
						feedEndRef.current?.scrollIntoView({ behavior: "auto" });
					} catch (error) {
						log.debug("ERROR: " + error);
					}
				}, waitTime);
				// feedEndRef.current.scrollIntoView({ behavior: "auto" });
				log.debug("Scrolling to bottom (useEffect)");
			} else {
				log.debug("Condition not met to scroll");
			}
		}
	}

	return (
		<>
			<IonContent>
				{props.messages &&
					props.messages.length > 0 &&
					props.messages.map((message: HeroMessage, index: number) => {
						if (message.message && stringContains(message.message, excludedText) === false) {
							// let cleanedContent = message.message.replace(/\n+/g, "\n"); // Replace consecutive newline characters with a single newline
							if (message.source === "assistant" || message.source === "hero") {
								let cleanedContent = message.message;
								if (!message.activityId) {
									cleanedContent = insertNewLines(message.message); // this is still needed for re-renders
								}
								const splitContent = cleanedContent.split("\n");
								return (
									<>
										<div key={index} className="assistant-message-div">
											<IonAvatar className="assistant-message-avatar">
												<img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={heroObject?.heroImageURL ? `${heroObject?.heroImageURL}` : `assets/images/onboarding/unknown-person.jpg`} alt="" />
											</IonAvatar>
											<div className={"assistant-message-container"}>
												{/* {message.activityId && (
													<>
														<ActivityItem key={index} index={index} activityId={message.activityId} />
													</>
												)} */}
												{splitContent.map((content: string, contentIndex: number) => {
													// log.debug("contentIndex: ", contentIndex);
													// log.debug("content: ", contentIndex);
													return (
														<div key={`${contentIndex}-${index}`} className={"assistant-message-bubble"} style={contentIndex > 0 ? { marginTop: '10px' } : {}}>
															{content}
														</div>
													)
												})}
											</div>
										</div>
										{message.createdAt && (
											<div className="message-timestamp bot">
												<span>
													<>{formatMomentDate(moment(message.createdAt))}</>
												</span>
											</div>
										)}
									</>
								);
							} else {
								return (
									<div key={index} className={"user-message-container"}>
										<div key={message.messageId} className={"user-message-bubble"}>
											{message.message}
										</div>
										{message.createdAt ? (
											<div className="message-timestamp user">
												<span>
													<>{formatMomentDate(moment(message.createdAt))}</>
												</span>
											</div>
										) : (
											<div className="message-timestamp user">
												<span>
													<>Delivered</>
												</span>
											</div>
										)}
									</div>
								);
							}
						}
					})}
				{props.processing === true && (
					<div className="assistant-message-div">
						<IonAvatar className="assistant-message-avatar">
							<img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={heroObject?.heroImageURL ? `${heroObject?.heroImageURL}` : `assets/images/onboarding/unknown-person.jpg`} alt="" />
						</IonAvatar>
						<div className={"assistant-message-container-typing"}><IonSpinner name="dots" /></div>
					</div>
				)}
				<div ref={feedEndRef} />
			</IonContent>
		</>
	);
};

export default HeroChatRenderer;

const ActivityItem = (props: { index: number, activityId: string }) => {
	// const [className, setClassName] = useState<string>("");
	// const [pointsDisplay, setPointsDisplay] = useState<string>("");
	const [activity, setActivity] = useState<ActivityLedger>();
	const [icon, setIcon] = useState<string>("");

	let userTimezone = moment.tz.guess();
	useEffect(() => {
		getActivityInfo()
	}, [props.activityId]);

	async function getActivityInfo() {
		getActivityById(props.activityId).then((activity: ActivityLedger | undefined) => {

			if (activity) {
				// log.debug("Found Activity: ", activity);
				setIcon(getIconFromExerciseActivity(activity.activityName));
				if (activity.strengthPoints > 0) {
					// setClassName(prevClassName => prevClassName + " strength");
					// setPointsDisplay(prevPointsDisplay => prevPointsDisplay + `${strengthIcon} ${activity.strengthPoints}`);
				}
				if (activity.cardioPoints > 0) {
					// setClassName(prevClassName => prevClassName + " cardio");
					// setPointsDisplay(prevPointsDisplay => prevPointsDisplay + `${cardioIcon} ${activity.cardioPoints}`);
				}
				if (activity.mobilityPoints > 0) {
					// setClassName(prevClassName => prevClassName + " mobility");
					// setPointsDisplay(prevPointsDisplay => prevPointsDisplay + `${mobilityIcon} ${activity.mobilityPoints}`);
				}
				if (activity.mindfulnessPoints > 0) {
					// setClassName(prevClassName => prevClassName + " mindfulness");
					// setPointsDisplay(prevPointsDisplay => prevPointsDisplay + `${mindfulnessIcon} ${activity.mindfulnessPoints}`);
				}
				setActivity(activity);
			} else {
				log.debug("Activity not found for id: ", props.activityId);
			}

		}
		)
	};

	return (
		<>
			{activity && icon ? (
				<>
					<div
						className={`activity-history-item-chat`}
					>
						<IonRow>
							<IonCol className="activity-history-icon-col" size="1">
								<div className={`activity-history-icon`}>
									<Emoji text={icon} />
								</div>
							</IonCol>
							<IonCol size="7">
								<>
									<div className={`activity-history-activityname ${activity.activityName.length > 14 ? "smaller" : ""}`}>
										{activity.activityName}
									</div>
									<div className="activity-history-duration">{activity.durationMinutes} min</div>
								</>
							</IonCol>
							<IonCol size="4" style={{ textAlign: "center" }}>
								{activity.activityName === "Steps" ? (
									<>
										{/* For steps the activity date is the end date because it looks better that way
																	<div className="activity-history-date" style={{ fontSize: "8px" }}>
																		{moment(activity.activityDate).subtract(activity.durationMinutes, "minutes").tz(userTimezone).format("h:mm a")} -{" "}
																		{moment(activity.activityDate).tz(userTimezone).format("h:mm a")}
																	</div> */}
									</>
								) : (
									<div className="activity-history-date">{moment(activity.activityDate).tz(userTimezone).format("h:mm a")}</div>
								)}
							</IonCol>
						</IonRow>
					</div>
				</>
			) : (
				<>
					<IonSpinner name="dots" />
				</>
			)}
		</>
	)
}