import React, { useEffect, useState } from 'react';
import moment from 'moment';
import "../../../css/Duels.css";



const CountdownTimerv2 = () => {
    const [time, setTime] = useState(getTimeRemaining());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(getTimeRemaining());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    function getTimeRemaining() {
        const current = moment();
        const nextHour = moment().endOf('hour');
        const duration = moment.duration(nextHour.diff(current));

        const minutes = Math.floor(duration.asMinutes());
        const seconds = Math.floor(duration.seconds());

        return {
            minutes: String(minutes).padStart(2, '0'),
            seconds: String(seconds).padStart(2, '0'),
        };
    }

    return (
        <div className='countdown-timer-v2'>
            <div>Next turn: {`${time.minutes}:${time.seconds}`}</div>
        </div>
    );
}

export default CountdownTimerv2;
