import React, { useEffect, useRef, useState } from 'react';
import { motion, useAnimation } from 'framer-motion';
import './testAnimation.css';

const collisionImages = [
  'https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/4f5442f9a27cbbe9d12b80cdc7f91dd0_POW.png',
  // Add more images as needed
];

const heroImage = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/b35d635779ac780433a83adf6146b5b2_user-ed0c459a-ff19-42be-b01e-9916d4044c47";
const villainImage = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/f2bf6ee1e0a2c559cac2f438f2796ede_guinney.webp";


const TestAnimationFramer = () => {
  const [isHeroAttacking, setIsHeroAttacking] = useState(false);
  const [isVillainAttacking, setIsVillainAttacking] = useState(false);
  const [showCollisionEffect, setShowCollisionEffect] = useState(false);
  const [collisionImage, setCollisionImage] = useState('');
  const [isShaking, setIsShaking] = useState(false); // New state to control shake

  const heroControls = useAnimation();
  const villainControls = useAnimation();

  const [currentX, setCurrentX] = useState(0); // Assuming this is managed elsewhere

  const createRelativeShakeAnimation = (currentX: number) => {
    const shakeOffsets = [-20, 20, -20, 20, -15, 15, -10, 10, -5, 5, 0]; // Shake pattern
    const shakeKeyframes = shakeOffsets.map(offset => currentX + offset); // Apply offsets relative to currentX

    return {
      x: shakeKeyframes,
      transition: {
        duration: 0.2,
        ease: "linear",
        times: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] // Adjust timing as needed
      }
    };
  };

  const moveToCollisionAndShake = async (isHero: boolean) => {
    const control = isHero ? heroControls : villainControls;
    const targetX = isHero ? 250 : -250; // Target position for moving to collision

    // Move to collision point
    await control.start({ x: targetX, transition: { duration: 0.5 } });
    setCurrentX(targetX); // Update currentX to new position

    // Create and start relative shake animation
    const shakeAnimationHero = createRelativeShakeAnimation(targetX);
    const shakeAnimationVillain = createRelativeShakeAnimation(0);

    heroControls.start(shakeAnimationHero);
    villainControls.start(shakeAnimationVillain);

    // Pause to simulate the moment of impact
    await new Promise(resolve => setTimeout(resolve, 200));

    // Return to the starting position
    await control.start({ x: 0, transition: { duration: 1 } });
  };

  const handleAttack = (isHero: boolean) => {
    // Set the collision image
    const randomImage = collisionImages[Math.floor(Math.random() * collisionImages.length)];
    setCollisionImage(randomImage);
    setShowCollisionEffect(false); // Initially hide the collision effect

    // Start the sequence of moving, pausing, shaking, and returning
    moveToCollisionAndShake(isHero);

    setTimeout(() => {
      setShowCollisionEffect(true); // Show collision effect after "collision"
    }, 1000); // Adjust timing as needed for visual effect
  };

  return (
    <div className="fit-hero-game">
      <motion.div
        className="hero"
        animate={heroControls}
        onClick={() => handleAttack(true)}
      >
        <img className='image' src={heroImage} alt='Hero' />
      </motion.div>

      <motion.div
        className="villain"
        animate={villainControls}
        onClick={() => handleAttack(false)}
      >
        <img className='image' src={villainImage} alt='Villain' />
      </motion.div>

      {showCollisionEffect && (
        <motion.div
          className="collision-effect"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <img className='pow-image' src={collisionImage} alt='Collision Effect' />
        </motion.div>
      )}
    </div>
  );
};

export default TestAnimationFramer;
