

import mixpanel from "mixpanel-browser";
import { WorkoutObject } from "../models/exerciseModels";
import { HeroObject, UserObject } from "../models/userModel";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";
import { getHero, getUser } from "./UserService";
import log from "loglevel";
import { Device } from "@capacitor/device";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { AFEvent, AFInit, AppsFlyer } from 'appsflyer-capacitor-plugin';


const mixpanelKey = "8230bb40f25bab0a562ec3bb5c45c3f7";
const APPS_FLYER_DEV_KEY = "chnrSFHZg737oKGrTRu9J8";
const APPLE_APP_ID = "id6449763830";
const firebaseConfig = {
	apiKey: "AIzaSyDQCeHKehgMjFdohD4bVB96dsEZxAsEkuY",
	authDomain: "fithero-396622.firebaseapp.com",
	projectId: "fithero-396622",
	storageBucket: "fithero-396622.appspot.com",
	messagingSenderId: "893824261218",
	appId: "1:893824261218:web:26906c37bf0ef70fb7853b",
	measurementId: "G-R3D18F2Q3D"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const afConfig = {
	appID: APPLE_APP_ID, // replace with your app ID. 
	devKey: APPS_FLYER_DEV_KEY, // replace with your dev key. 
	isDebug: true,
	waitForATTUserAuthorization: 10, // for iOS 14 and higher
	minTimeBetweenSessions: 6, // default 5 sec
	registerOnDeepLink: true,
	registerConversionListener: true,
	registerOnAppOpenAttribution: false,
	deepLinkTimeout: 4000, // default 3000 ms
	useReceiptValidationSandbox: true, // iOS only
	useUninstallSandbox: true // iOS only
};

export const initAnalytics = async () => {
	log.debug("[initAnalytics] Mixpanel Init");
	try {
		mixpanel.init(mixpanelKey, { debug: false, persistence: "localStorage" });
		let user = await getUser();
		let device = await Device.getInfo();
		if (device.platform !== "web") AppsFlyer.initSDK(afConfig).then(res => log.debug("AppsFlyer install: ", JSON.stringify(res)));
		if (user !== null) {
			mixpanelIdentifyUser(user);
			if (device.platform !== "web" && user?.userID) AppsFlyer.setCustomerUserId({ cuid: user?.userID });
		}
	} catch (error) {
		log.debug("[initAnalytics] Error - mixpanel init/identifty: " + error);
		sendSlackErrorNotification(`[initAnalytics] Error - mixpanel init/identify: ${error}`, "initAnalytics");
	}
};

export const mixpanelIdentifyUser = async (user: UserObject | null = null) => {
	log.debug("[mixpanelIdentifyUser] Mixpanel ID'ing user");
	if (user === null) {
		user = await getUser();
	}
	try {
		// log.debug("[mixpanelIdentifyUser] userID: " + userID);
		await mixpanel.identify(user?.userID);
		let hero: HeroObject = await getHero(user?.heroID);
		mixpanel.register({
			email: user?.email,
			heroID: user?.heroID ? user?.heroID : "null",
			inviteCode: user?.inviteCode,
		})
		mixpanel.people.set({
			$email: user?.email,
			"heroID": user?.heroID ? user?.heroID : "null",
			"inviteCode": user?.inviteCode,
		});
		mixpanelSetHeroProperties(hero);
		Device.getInfo().then(device => {
			if (device.platform !== "web" && user?.userID) AppsFlyer.setCustomerUserId({ cuid: user?.userID });
		});
	} catch (error) {
		console.error("[mixpanelIdentifyUser] Error - mixpanel init/identifty: " + error);
		sendSlackErrorNotification(`[mixpanelIdentifyUser] Error - mixpanel init/identify: ${error}`, "mixpanelIdentifyUser");
	}
};

export const mixpanelSetHeroProperties = async (hero: HeroObject) => {
	if (!hero) {
		let user = await getUser();
		hero = await getHero(user?.heroID);
	}
	mixpanel.people.set({
		"heroName": hero?.heroName,
		"heroToHit": hero?.heroToHit,
		"heroDamage": hero?.heroDamage,
		"heroDodge": hero?.heroDodge,
		"heroHealth": hero?.heroHealth,
		"heroImageURL": hero?.heroImageURL,
		$avatar: hero?.heroImageURL,
		"cardioLevel": hero?.cardioLevel,
		"cardioPoints": hero?.cardioPoints,
		"mindfulnessLevel": hero?.mindfulnessLevel,
		"mindfulnessPoints": hero?.mindfulnessPoints,
		"mobilityLevel": hero?.mobilityLevel,
		"mobilityPoints": hero?.mobilityPoints,
		"strengthLevel": hero?.strengthLevel,
		"strengthPoints": hero?.strengthPoints,
		"overallLevel": hero?.overallLevel,
		"totalPoints": hero?.totalPoints,
		"strengthTreeSpecialization": hero?.strengthTreeSpecialization,
		"cardioTreeSpecialization": hero?.cardioTreeSpecialization,
		"mobilityTreeSpecialization": hero?.mobilityTreeSpecialization,
		"mindfulnessTreeSpecialization": hero?.mindfulnessTreeSpecialization,
	});
}

export const initAnalyticsFreshInstall = async () => {
	log.debug("INIT MIXPANEL - FRESH INSTALL");
	try {
		let host = window.location.hostname;
		let device = await Device.getInfo();
		if (host.includes("localhost") && device.platform === "web") {
			log.debug("Localhost - skipping mixpanel init");
			return;
		}
		mixpanel.init(mixpanelKey, { debug: true, track_pageview: true, persistence: "localStorage" })
		mixpanel.track("Fresh install")
		if (device.platform !== "web") AppsFlyer.initSDK(afConfig).then(res => log.debug("AppsFlyer install: ", JSON.stringify(res)));
		sendSlackNotification(`*[Mixpanel]* Fresh install - Mixpanel initialized\n*Device:* \`\`\`${JSON.stringify(device)}\`\`\``, 'keyEvent');
	} catch (error) {
		log.debug("Error - mixpanel init/identifty: " + error);
		sendSlackErrorNotification(`Error - mixpanel init/identify: ${error}`, "initAnalyticsFreshInstall");
	}
};

export const logGenericEvent = (eventName: string) => {
	try {
		mixpanel.track(eventName);
		logEvent(analytics, eventName);
		Device.getInfo().then(device => {
			if (device.platform !== "web") AppsFlyer.logEvent({
				eventName: eventName,
				eventValue: null
			});
		});
	} catch (error) {
		log.debug("Error - mixpanel logGenericEvent: " + error);
		sendSlackErrorNotification(`Error - mixpanel logGenericEvent: ${error}`, "logGenericEvent");
	}
};

export const logGenericEventWithObject = (eventName: string, eventObject: object) => {
	try {
		mixpanel.track(eventName, eventObject);
		logEvent(analytics, eventName, eventObject);
		Device.getInfo().then(device => {
			if (device.platform !== "web") AppsFlyer.logEvent({
				eventName: eventName,
				eventValue: eventObject
			});
		});
	} catch (error) {
		log.debug("Error - mixpanel logGenericEvent: " + error);
		sendSlackNotification(`Error - mixpanel logGenericEvent: ${error}`);
	}
};

export const logGenericClickEvent = (source: string, value: string) => {
	mixpanel.track("Clicked - " + value, {
		source: source,
		value: value,
	});
};

export const setProperty = (propertyName: string, propertyValue: any) => {
	try {
		mixpanel.people.set(propertyName, propertyValue);
	} catch (error) {
		log.debug("Error in setProperty: " + error);
	}
};

export const logAddActivityEvent = (activityObject: WorkoutObject) => {
	try {
		mixpanel.track("Logged Activity", activityObject);
	} catch (error) {
		log.debug("Error in logAddActivityEvent: " + error);
	}
};

export const logPageView = (pageName: string) => {
	try {
		mixpanel.track(pageName)
	} catch (error) {
		console.error("Error in logpageView: " + error);
	}
};
