import React, { useState, useEffect, useMemo } from 'react';

interface PathProps {
    start: { x: number; y: number; id: number };
    end: { x: number; y: number; id: number };
    nodeSize: number;
}

const ProegressionsPath: React.FC<PathProps> = (props) => {
    // log.debug("props", props)
    const nodeRadius = props.nodeSize / 2;

    const adjustedStart = useMemo(() => ({
        x: props.start.x + nodeRadius,
        y: props.start.y + nodeRadius
    }), [props.start.x, props.start.y, nodeRadius]);

    const adjustedEnd = useMemo(() => ({
        x: props.end.x + nodeRadius,
        y: props.end.y + nodeRadius
    }), [props.end.x, props.end.y, nodeRadius]);

    // State variables to hold the control points
    const [controlPoint1, setControlPoint1] = useState({ x: 0, y: 0 });
    const [controlPoint2, setControlPoint2] = useState({ x: 0, y: 0 });

    // Effect to update the control points only when the start or end props change
    useEffect(() => {
        setControlPoint1({
            x: adjustedStart.x + Math.random() * (adjustedEnd.x - adjustedStart.x),
            y: adjustedStart.y - Math.random() * 100 // Increase this value for more dramatic curves
        });
        setControlPoint2({
            x: adjustedStart.x + Math.random() * (adjustedEnd.x - adjustedStart.x),
            y: adjustedEnd.y + Math.random() * 100 // Increase this value for more dramatic curves
        });
    }, [adjustedStart, adjustedEnd]);

    const d = `
        M ${adjustedStart.x} ${adjustedStart.y}
        C ${controlPoint1.x} ${controlPoint1.y}, ${controlPoint2.x} ${controlPoint2.y}, ${adjustedEnd.x} ${adjustedEnd.y}
    `;

    let svgHeight = (props.start.y / 6).toString() + "vh"

    return (
        <svg style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: svgHeight, overflow: "auto" }}>
            {/* Define the gradient */}
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#00c1ff" />
                    <stop offset="100%" stopColor="#001922" />
                </linearGradient>
            </defs>
            {/* Apply the gradient to the path */}
            {/* <path d={d} stroke="black" strokeWidth="10" fill="url(#gradient)" /> */}
            <path d={d} stroke="lightBlue" strokeWidth="10" fill="none" strokeDasharray="5,5" />;

        </svg>
    );
};

export default ProegressionsPath;
