import { IonContent } from '@ionic/react'
import React, { useState } from 'react'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import log from 'loglevel';
import CreateAvatar from '../../../Onboarding/v1/4-CreateAvatar';


const ChangeAvatar = (props: { setShowChangeAvatarModal: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass>()
    const [uploadPhoto, setUploadPhoto] = useState<boolean>(true)
    return (
        <>
            <IonContent>
                {/* <Swiper
                    spaceBetween={50}
                    modules={[Navigation]}
                    slidesPerView={1}
                    navigation
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    onSlideChange={() => log.debug('slide change')}
                    autoHeight={true}
                >
                    <SwiperSlide>
                        <PhotoOption setUploadPhoto={setUploadPhoto} swiper={swiperInstance} />
                    </SwiperSlide>
                    <SwiperSlide>
                        <CreateAvatar onboarding={false} upload={uploadPhoto} setShowChangeAvatarModal={props.setShowChangeAvatarModal} />
                    </SwiperSlide>
                </Swiper> */}


            </IonContent>
        </>
    )
}

export default ChangeAvatar

const PhotoOption = (props: { setUploadPhoto: React.Dispatch<React.SetStateAction<any>>, swiper: SwiperClass | undefined }) => {
    function selectOption(uploadPhoto: boolean) {
        props.swiper?.slideNext()
        props.setUploadPhoto(uploadPhoto)
    }
    return (
        <>
            <div style={{ marginTop: "5vh !important" }}>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Choose your FitHero avatar style</div>
                </div>
                <div style={{ marginBottom: "10px" }}>
                    <div className='onboarding-upload-photo' onClick={() => selectOption(true)}>
                        <img src="assets/images/onboarding/unknown-person.jpg" className="onboarding-add-photo-button" />
                    </div>
                    <div className='onboarding-add-photo-button-div'>
                        Create FitHero Based on a Photo <span className='most-fun'>(recommended)</span>
                    </div>
                </div>
                <div>
                    <div className='onboarding-upload-photo' onClick={() => selectOption(false)}>
                        <img src="assets/images/onboarding/create-ai-avatar.jpg" className="onboarding-add-photo-button" />
                    </div>
                    <div className='onboarding-add-photo-button-div'>
                        Create Generic FitHero
                    </div>
                </div>
            </div>
        </>
    )
}