import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react';
import { useContext, useEffect } from 'react';
import HeaderLogo from '../../components/HeaderLogo';
import "../../css/Onboarding.css";
import { StorageService } from "../../services/StorageService";
import GlobalState from '../../contexts/GlobalState';
const storageService = new StorageService();


const Intro1 = () => {
    const nextPage = "/onboarding/intro2";

    return (
        <IonPage>
            <HeaderLogo checkforUser={false} />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Step 1: Create Your Hero</div>
                    <div className='onboarding-text-area' style={{ textAlign: "center", marginTop: "0px", marginBottom: "20px" }}>
                        Each hero is unique.<br />Choose from millions of possibilities
                    </div>

                    <div>
                        <img className="onboarding-hero-image" src="assets/images/onboarding/hero-collage.jpg" alt="" />
                    </div>
                </div>
            </IonContent>
            <IonFooter className="onboarding-footer">
                <IonButton routerLink={nextPage} className="onboarding-advance-button">
                    Continue
                </IonButton>
            </IonFooter>
        </IonPage>
    )
}

export default Intro1