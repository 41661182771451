/* eslint-disable react-hooks/exhaustive-deps */
import { IonAlert, IonAvatar, IonButton, IonButtons, IonFooter, IonHeader, IonIcon, IonLoading, IonPage, IonToolbar } from "@ionic/react";
import "../../../css/App.css";
import "../../../css/HeroChat.css";

import { refreshCircleOutline, people } from "ionicons/icons";
import log from "loglevel";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../../../contexts/GlobalState";
import { SidekickMessage } from "../../../models/messageModels";
import { SidekickDBObject, SidekickObject, sidekickDBObjectKey, sidekickDisplayMessagesKey, sidekickThreadObjectKey } from "../../../models/sideKickModels";
import { SidekickMessagesResponse, disableSidekick, getSidekick, getSidekickMessages, getSidekickMessagesFromDB, intiateSidekick } from "../../../services/SideKickService";
import { StorageService } from "../../../services/StorageService";
import HeroChatComponent from "../components/ChatRenderComponent";
import ChatInput from "../components/SideKickChatInput";
import { ManageSidekicks } from "./ManageSidekicks";
const storageService = new StorageService();

const SidekickV2 = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { sidekick, setSidekick, sidekickLoadingFinished, setSidekickNewMessages } = globalState;
    const [messagesForDisplay, setMessagesForDisplay] = useState<SidekickMessage[]>([]);
    const [processing, setProcessing] = useState<boolean>(false);
    const [activeSidekick, setActiveSidekick] = useState<boolean>();
    const [awakeningSidekick, setAwakeningSidekick] = useState<boolean>(false);
    const [scroller, setScroller] = useState<boolean>(true); // used to simply trigger a rerender without more info
    const [showClearChatConfirmation, setShowClearChatConfirmation] = useState<boolean>(false);
    const [localSidekick, setLocalSidekick] = useState<SidekickDBObject | undefined>(sidekick);
    const [refreshing, setRefreshing] = useState<boolean>(false);

    useEffect(() => {
        async function getMessages() {
            setRefreshing(true);
            await getSidekickMessages(sidekick!.sideKickThreadId).then((messages) => {
                setMessagesForDisplay(messages);
            }).catch((error) => {
                log.error(error);
                alert("Error: " + error);
            });
            // log.debug("[getMessages] messages: ", messages);            
            setRefreshing(false);
        }
        log.debug("SidekickV2 useEffect Triggered");
        // log.debug("SidekickV2 sidekick: ", sidekick);
        if (sidekick && sidekick.sideKickThreadId && sidekick.sideKickThreadId !== "") {
            setActiveSidekick(true)
            setSidekickNewMessages(false);
            getMessages();
        } else {
            setActiveSidekick(false)
        }
    }, [sidekick, sidekickLoadingFinished]);

    async function clearChatHistory() {
        setRefreshing(true);
        setMessagesForDisplay([]);
        await storageService.removeItem(sidekickDisplayMessagesKey);
        let sk = sidekick
        if (!sk || !sk.sideKickThreadId || sk.sideKickThreadId === "") {
            sk = await getSidekick()
        }
        if (!sk || !sk.sideKickThreadId || sk.sideKickThreadId === "") {
            alert("Error: Sidekick not found.");
            setRefreshing(false);
            return;
        }
        getSidekickMessagesFromDB(sk.sideKickThreadId).then((result: SidekickMessagesResponse) => {
            setMessagesForDisplay(result.messages);
            setRefreshing(false);
        });
    }

    async function reawaken(confirmed: boolean) {
        if (confirmed === false) {
            setShowClearChatConfirmation(true);
        } else {
            setShowClearChatConfirmation(false);
            await storageService.removeItem(sidekickDisplayMessagesKey);
            await storageService.removeItem(sidekickThreadObjectKey);
            await disableSidekick();
            setMessagesForDisplay([]);
            setActiveSidekick(false);
        }
    }

    return (
        <IonPage>
            {activeSidekick === false ? (
                <>
                    {/* <IonLoading isOpen={awakeningSidekick} message={"Awakening your Sidekick..."} backdropDismiss />

                    <div className="awaken-sidekick-div">
                        <IonButton className="sidekick-throbbing-button" style={{ color: "white" }} size="large" expand="block" onClick={() => initSidekick()}>Awaken Sidekick</IonButton>
                        <p style={{ width: "70%", margin: "auto", fontSize: "larger", "marginTop": "40px" }}>
                            Your sidekick is your companion on your journey.<br /><br />They can explain how FitHero.ai works, give you tips, and help you stay motivated.
                        </p>
                    </div> */}
                    <ManageSidekicks setActiveSidekick={setActiveSidekick} />
                </>

            ) : (
                <>
                    <IonHeader>
                        <IonToolbar className="background">
                            <IonButtons slot="start" className="toolbar-left-buttons">
                                <IonButton onClick={() => clearChatHistory()}>
                                    <IonIcon style={{ color: "white" }} icon={refreshCircleOutline} />
                                </IonButton>
                            </IonButtons>
                            <div className="toolbar-avatar-holder">
                                <IonAvatar className="member-image" style={{ margin: "auto" }}>
                                    <img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={localSidekick?.sideKickImage ? `${localSidekick?.sideKickImage}` : `https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/37a94d6b6e738e8e1bd93182d397e327_snarf.jpg`} alt="" />
                                </IonAvatar>
                                {localSidekick?.sideKickName}
                            </div>
                            <IonButtons slot="end" className="toolbar-right-buttons">
                                <IonButton routerLink="/manageSidekicks">
                                    <IonIcon style={{ color: "white" }} icon={people} />
                                </IonButton>
                            </IonButtons>

                        </IonToolbar>
                    </IonHeader>
                    <IonAlert isOpen={showClearChatConfirmation} onDidDismiss={() => setShowClearChatConfirmation(false)} header={"Re-awaken you Sidekick?"} message={"Are you sure you want to re-awaken your Sidekick? This will start things over with them."} buttons={[{ text: "Cancel", role: "cancel" }, { text: "Re-awaken", handler: (data) => reawaken(true) }]} />
                    <IonLoading isOpen={refreshing} message={"Refreshing messages..."} backdropDismiss />

                    <HeroChatComponent
                        scroller={scroller}
                        setScroller={setScroller}
                        messages={messagesForDisplay}
                        setMessages={setMessagesForDisplay}
                        processing={processing}
                        setProcessing={setProcessing}
                    />
                    <IonFooter>
                        <ChatInput setMessages={setMessagesForDisplay} messages={messagesForDisplay} setProcessing={setProcessing} setScroller={setScroller} />
                    </IonFooter>
                </>
            )}

        </IonPage>
    );
};

export default SidekickV2;
