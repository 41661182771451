import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { IonAlert, IonAvatar, IonButton, IonCol, IonLabel, IonLoading, IonRow, IonSelect, IonSelectOption, SelectCustomEvent } from '@ionic/react';
import Parse from 'parse';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import GlobalState from '../../../contexts/GlobalState';
import "../../../css/Onboarding.css";
import { AvatarCreationMessages, OnboardingObject, UserObject } from '../../../models/userModel';
import { AlbedoBaseXL, ElementCheckboxValues, generateImage, getInferenceJob } from '../../../services/AvatarService';
import { sendSlackErrorNotification, sendSlackNotification } from '../../../services/NotificationService';
import { StorageService } from '../../../services/StorageService';
import { ImageAnalysisResponse, analyzeImage, generateAvatarFromImage, getHero, getUser, updateHero } from '../../../services/UserService';

import { Steps } from 'intro.js-react';
import log from 'loglevel';
import { v4 } from 'uuid';
import { ageOptions, backgroundOptions, convertPhotoToBlob, ethnicityOptions, eyeColorOptions, facialExpressionOptions, facialHairOptions, genderOptions, hairColorOptions, hairstyleOptions } from '../../../services/AvatarService';
import { getRandomValueFromArray } from '../../../services/HelperService';
import { logGenericEventWithObject } from "../../../services/AnalyticsService";


const CreateAvatarComponent = (props: { upload: boolean, onboarding?: boolean, setShowChangeAvatarModal?: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const nextPage = "/onboarding/namehero";
    const nextPageNotOnboarding = "/profile/hero";

    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile, setHeroObject } = globalState;

    interface SavingPhotoObject {
        visible: boolean;
        message: string;
    }

    interface SelectedImage {
        url: string;
        index: number;
    }

    interface APIResponse {
        base64: string;
        finishReason: string;
        seed: number;
    }

    type GeneratedImage = {
        url: string,
        nsfw: boolean,
        id: string,
        likeCount: number,
        generated_image_variation_generics: any[]
    };

    const history = useHistory();
    // const model = dreamshaper7
    const model = AlbedoBaseXL;
    const [savingPhoto, setSavingPhoto] = useState<SavingPhotoObject>({
        visible: false,
        message: "",
    });
    const [avatar, setAvatar] = useState<any>();
    const [confirmClearInputs, setConfirmClearInputs] = useState<boolean>(false);
    const [loadingMessage, setLoadingMessage] = useState({
        show: false,
        message: ""
    });
    const [images, setImages] = useState<string[]>([])
    const [dramatic, setDramatic] = useState<boolean>(false);
    const [imageAnalyzed, setImageAnalyzed] = useState<boolean>(false);
    const [imageSelected, setImageSelected] = useState<SelectedImage>({
        url: "",
        index: -1
    });
    const [imageURL, setImageURL] = useState<string>('');
    const [takenPhoto, setTakenPhoto] = useState<any>();
    const [photoError, setPhotoError] = useState({
        visible: false,
        message: "",
    });
    const baseFormValues = {
        eyeColor: "",
        ethnicity: "",
        hairstyle: "",
        hairColor: "",
        age: "",
        gender: "",
        background: "",
        facialExpression: "",
        facialHair: "",
    }
    const [formValues, setFormValues] = useState<ImageAnalysisResponse>(baseFormValues);
    const [elementCheckboxValues, setElementCheckboxValues] = useState<ElementCheckboxValues>({
        FIRE: false,
        MAGIC: false,
        STEEL: false,
        FANTASY: false,
        SCIFI: false,
        EGYPTIAN: false,
        PIRATE: false,
        CYBORG: false
    });
    const [showAnalyzeImageCoachmark, setShowAnalyzeImageCoachmark] = useState<boolean>(false)
    const [showAvatarTweakingCoachMark, setShowAvatarTweakingCoachMark] = useState<boolean>(false)
    const [showFinishCoachMark, setShowFinishCoachMark] = useState<boolean>(false)

    useEffect(() => {
        // if (props.onboarding === true) {
        //     updateOnboardingObject();
        // }
    }, []);

    // Randomize values if "Random" is selected
    useEffect(() => {
        ['gender', 'hairstyle', 'ethnicity', 'hairColor', 'eyeColor', 'age', 'background', 'facialExpression', 'facialHair', 'perspective'].forEach(field => {
            if ((formValues as { [key: string]: any })[field] === 'Random') {
                const randomValue = getRandomOption(eval(`${field}Options`));  // Note: eval is generally discouraged due to security reasons but it is safe in this case as it's being used with known variable names.
                setFormValues(prevValues => ({ ...prevValues, [field]: randomValue }));
            }
        });
    }, [formValues]);

    // // Function to handle checkbox change
    // const handleCheckboxChange = (name: keyof ElementCheckboxValues) => {
    //     setElementCheckboxValues(prev => {
    //         const updated = { ...prev, [name]: !prev[name] };
    //         const checkedCount = Object.values(updated).filter(val => val).length;
    //         if (checkedCount > 4) {
    //             setPhotoError({
    //                 visible: true,
    //                 message: "Maximum 4 design styles",
    //             });
    //             return prev;
    //         }
    //         return updated;
    //     });
    // };


    // async function updateOnboardingObject() {
    //     let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
    //     tempOnboardingObject.url = window.location.pathname;
    //     tempOnboardingObject.completed = false;
    //     await storageService.setObject("onboarding", tempOnboardingObject);
    // }

    function getRandomItem(arr: Array<string>): string {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
    }

    function getRandomOption(options: any) {
        const randomIndex = Math.floor(Math.random() * (options.length - 1));  // Exclude the "Random" option
        return options[randomIndex];
    }

    const randomizeValues = () => {
        let randomizedValues = {
            eyeColor: getRandomItem(eyeColorOptions),
            ethnicity: getRandomItem(ethnicityOptions),
            hairstyle: getRandomItem(hairstyleOptions),
            hairColor: getRandomItem(hairColorOptions),
            age: getRandomItem(ageOptions),
            gender: getRandomItem(genderOptions),
            background: getRandomItem(backgroundOptions),
            facialExpression: getRandomItem(facialExpressionOptions),
            facialHair: getRandomItem(facialHairOptions),
        }
        if (randomizedValues.gender === 'Female') (
            randomizedValues.facialHair = 'None'
        )
        if (randomizedValues.hairstyle === 'Bald') (
            randomizedValues.hairColor = 'None'
        )
        setFormValues(randomizedValues);
    };

    const extractUrls = (images: GeneratedImage[]) => {
        return images.map(image => image.url);
    }

    const runImageGeneration = async () => {
        logGenericEventWithObject("Generating FitHero", formValues);
        setLoadingMessage({ show: true, message: `Creating FitHero with AI<br/>(approx 20 seconds)...` });
        let pronoun = "he";
        let possesivePronoun = "his";
        if (formValues.gender === "Female") {
            pronoun = "she";
            possesivePronoun = "her";
        }
        if (formValues.gender === "Non-binary") {
            pronoun = "they";
            possesivePronoun = "their";
        }
        let prompt = `A dynamic, action-packed portrait of a ${formValues.gender} ${formValues.ethnicity} super hero with a cape and a mask. ${pronoun} has ${formValues.eyeColor} eyes, ${formValues.hairstyle}, ${formValues.hairColor} hair, and belongs to the ${formValues.age} generation. The background of the image should be a ${formValues.background} and ${pronoun} should have a ${formValues.facialExpression} look on ${possesivePronoun} face.`
        if (formValues.facialHair === "Clean shaven") {
            prompt = prompt + `${pronoun} should be clean shaven.`;
        } else if (formValues.facialHair !== "None") {
            prompt = prompt + `${pronoun} should have a ${formValues.facialHair} for facial hair.`;
        }
        // // add a comma-separate list of form values to the prompt
        // let propertiesString = Object.entries(formValues).map(([key, value]) => `${key}: ${value}`).join(", ");
        // prompt = prompt + ". " + propertiesString;
        log.debug("[runImageGeneration] Prompt: ", prompt);
        await generateImage(prompt, model).then(async (inferenceId) => {
            let status = "PENDING";
            while (status === "PENDING") {
                await new Promise(resolve => setTimeout(resolve, 3000)); // Sleep for 3 seconds
                const result = await getInferenceJob(inferenceId);
                log.debug("result: ", result);
                let message = getRandomValueFromArray(AvatarCreationMessages)
                setLoadingMessage({ show: true, message: message });
                status = result.status;
                if (result.generatedImages.length > 0) {
                    setLoadingMessage({ show: false, message: "" });
                    let urls = extractUrls(result.generatedImages);
                    log.debug("urls: ", urls);
                    setImages(urls);
                }
            }
        })
            .catch((error) => {
                log.debug(`Error Message: ${error.response.data.error}`);
                log.debug(`Full Error Obj: `, error);
                sendSlackErrorNotification("Error generating image: " + error.response.data.error, "[generateImage]", JSON.stringify(error));
                alert("Error generating image. Please try again.");
                setLoadingMessage({ show: false, message: "" });
                return
            })
    }

    const handleSelectChange = (event: SelectCustomEvent<any>) => {
        let valueUpdate = {
            [event.target.name]: event.target.value
        }
        if (event.target.value === 'Bald') {
            valueUpdate = {
                ...valueUpdate,
                hairColor: 'None'
            }
        }
        if (event.target.value === 'Female') {
            valueUpdate = {
                ...valueUpdate,
                facialHair: 'None'
            }
        }
        setFormValues({ ...formValues, ...valueUpdate });
    };

    const takePhotoHandler = async () => {
        const photo = await Camera.getPhoto({
            allowEditing: false,
            correctOrientation: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt,
            quality: 80,
            width: 500,
        }).catch((error) => {
            log.debug("Error taking photo: " + JSON.stringify(error));
        });

        //log.debug("Photo String: " + JSON.stringify(photo));
        if (photo && photo.dataUrl) {
            setTakenPhoto({
                path: photo.dataUrl,
                preview: photo.dataUrl,
            });
            setShowAnalyzeImageCoachmark(true)
        }
    };

    async function savePhoto(confirmed: boolean = false) {
        if (!confirmed) {
            setSavingPhoto({
                visible: true,
                message: `<img src="${imageSelected.url}" style="width: 100%; height: auto;"/>`
            });
            return;
        } else {
            setSavingPhoto({
                visible: true,
                message: "Processing Photo... 0%",
            });
            if (props.onboarding === true) {
                let heroImageUUID = v4();
                const image = await fetch(imageSelected.url)
                const imageBlob = await image.blob()
                const parseFile = new Parse.File("user-" + heroImageUUID, imageBlob);
                try {
                    const responseFile = await parseFile.save();
                    log.debug("File saved successfully", responseFile);
                    log.debug(`URL: ${parseFile._url}`)
                    log.debug("Image uploaded: " + parseFile._url);
                    // Here is where we create the onboarding object. No need for it prior to this...
                    let tempOnboardingObject: OnboardingObject = {
                        url: window.location.pathname,
                        completed: false,
                        heroPicture: parseFile._url,
                    };
                    await storageService.setObject("onboarding", tempOnboardingObject);
                    if (tempOnboardingObject.heroPicture) {
                        sendSlackNotification(
                            "*Hero Image Created*\nAvatar: " + tempOnboardingObject.heroPicture + "\n",
                            "creation"
                        );
                    }
                    setSavingPhoto({
                        visible: false,
                        message: "",
                    });
                    history.push(nextPage);
                } catch (error) {
                    sendSlackErrorNotification("Error saving photo: " + error, "[CreateAvatar Componenent - onboarding]", "heroImageUUID: " + heroImageUUID);
                    log.debug("Error uploading image: " + JSON.stringify(error));
                    setSavingPhoto({
                        visible: false,
                        message: "",
                    });
                    setPhotoError({
                        visible: true,
                        message: "There was an error saving your photo. Please try again. If the problem persists, please email support@fithero.ai",
                    });
                    // throw new Error("Error uploading image: " + JSON.stringify(error));
                }
            } else {
                let userObject: UserObject | null = await getUser();
                const image = await fetch(imageSelected.url)
                const imageBlob = await image.blob()
                const parseFile = new Parse.File("user-" + userObject?.userID, imageBlob);
                try {
                    const responseFile = await parseFile.save();
                    log.debug("File saved successfully", responseFile);
                    log.debug(`URL: ${parseFile._url}`)
                    // tempOnboardingObject.heroPicture = result.data.publicPath;
                    log.debug("Image uploaded: " + parseFile._url);
                    if (userObject) {
                        sendSlackNotification(
                            "*Hero Image updated*\nUserID: " + userObject.userID + "\nEmail: " + userObject.email + "\nAvatar: " + parseFile._url + "\n",
                            "creation"
                        );
                    }
                    let hero = await getHero();
                    hero = {
                        ...hero,
                        heroImageURL: parseFile._url,
                    }
                    let updatedHero = await updateHero(hero);
                    if (updatedHero) {
                        setHeroObject(updatedHero)
                    }
                    setSavingPhoto({
                        visible: false,
                        message: "",
                    });
                    history.push(nextPageNotOnboarding);
                } catch (error) {
                    log.debug("Error uploading image: " + JSON.stringify(error));
                    sendSlackErrorNotification("Error updating photo: " + error, "[CreateAvatar Componenent - not onboarding]", "user: " + JSON.stringify(userObject));
                    setSavingPhoto({
                        visible: false,
                        message: "",
                    });
                    setPhotoError({
                        visible: true,
                        message: "There was an error saving your photo. Please try again. If the problem persists, please email support@fithero.ai",
                    });
                    // throw new Error("Error uploading image: " + JSON.stringify(error));
                }
            }

        }
    }

    async function getImageAnalyzed() {
        setImageAnalyzed(false)
        setLoadingMessage({ show: true, message: "Analyzing Image..." })
        const blob = await convertPhotoToBlob(takenPhoto.path);
        const parseFile = new Parse.File(`avatar-image-${Date.now()}`, blob);
        let imageUrl = '';
        try {
            const responseFile = await parseFile.save();
            log.debug("File saved successfully", responseFile);
            log.debug(`URL: ${parseFile._url}`)
            // tempOnboardingObject.heroPicture = result.data.publicPath;
            log.debug("Image uploaded: " + parseFile._url);
            imageUrl = parseFile._url;
        } catch (error) {
            log.debug("Error uploading image to Parse: " + JSON.stringify(error));
            throw new Error("Error uploading image to Parse: " + JSON.stringify(error));
        }
        let analysisResponse: ImageAnalysisResponse = await analyzeImage(imageUrl).catch((error) => {
            sendSlackErrorNotification("Error analyzing image: " + error, "[analyzeImage]", "imageUrl: " + imageUrl);
            setLoadingMessage({ show: false, message: "" })
            setPhotoError({
                visible: true,
                message: "Error analyzing image. Please ensure your photo is of one person and try again.",
            });
            throw error;
        })
        setImageURL(imageUrl);
        log.debug(`[getImageAnalyzed] analysisResponse: `, analysisResponse);
        setLoadingMessage({ show: false, message: "" })
        setFormValues(prevState => {
            return { ...prevState, ...analysisResponse };
        });
        setImageAnalyzed(true)
        setShowAvatarTweakingCoachMark(true)
    }

    // Collect checked values into an array
    const getCheckedValues = (): string[] => {
        return Object.entries(elementCheckboxValues)
            .filter(([key, value]) => value)
            .map(([key]) => key);
    };

    const saveAndConvertImage = async () => {
        if (takenPhoto && imageURL) {
            setImageSelected({ index: -1, url: "" });
            setImages([]);
            log.debug(`[saveAndConvertImage] photo received `);
            let pronoun = "he";
            let possesivePronoun = "his";
            if (formValues.gender === "Female") {
                pronoun = "she";
                possesivePronoun = "her";
            }
            if (formValues.gender === "Non-binary") {
                pronoun = "they";
                possesivePronoun = "their";
            }
            let prompt = `A dynamic, action-packed portrait of a ${formValues.gender} ${formValues.ethnicity} super hero with a cape and a mask. ${pronoun} has ${formValues.eyeColor} eyes and belongs to the ${formValues.age} generation. ${pronoun} should have a ${formValues.facialExpression} look on ${possesivePronoun} face.`
            if (formValues.hairstyle !== "Bald" && formValues.hairColor !== "None") {
                prompt = prompt + ` ${pronoun} should have a ${formValues.hairstyle}, ${formValues.hairColor} hair.`;
            } else {
                prompt = prompt + ` ${pronoun} should be bald.`;
            }
            if (formValues.facialHair === "Clean shaven") {
                prompt = prompt + `${pronoun} should be clean shaven.`;
            } else if (formValues.facialHair !== "None") {
                prompt = prompt + `${pronoun} should have a ${formValues.facialHair} for facial hair.`;
            }
            // // add a comma-separate list of form values to the prompt
            // let propertiesString = Object.entries(formValues)
            //     .filter(([key, value]) => value !== "" && value !== null && value !== undefined)
            //     .map(([key, value]) => `${key}: ${value}`)
            //     .join(", ");
            // prompt = prompt + ". " + propertiesString;
            log.debug(`[saveAndConvertImage] prompt: `, prompt);
            setLoadingMessage({ show: true, message: `Generating Fithero with AI (this could take a bit)...` });
            setPhotoError({
                visible: false,
                message: "",
            });

            log.debug(`[saveAndConvertImage] generating avatar from image`);
            const checkedValues = getCheckedValues();
            let images = await generateAvatarFromImage(imageURL, dramatic, checkedValues, prompt).catch((error) => {
                log.debug(`[saveAndConvertImage] error generating avatar from image: `, error);
                throw error;
            });
            setImages(images);
            setLoadingMessage({ show: false, message: "" });
            setShowFinishCoachMark(true)
        } else {
            setPhotoError({
                visible: true,
                message: "Please take a photo of yourself",
            });
        }

    };

    const analyzeImageSteps = [
        {
            title: "Great photo!",
            intro: 'Looking good! Your FitHero is off to a great start.',
            position: 'bottom',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        },
        {
            element: '.analyze-image-hint',
            title: "Analyze This Photo",
            intro: 'Let\'s analyze it to create your FitHero. <br/><br/>Reminder: Your photo is not stored and will not be used for ANY other purpose other than to generate your FitHero.',
            position: 'bottom',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        }
    ];

    const avatarTweakingSteps = [
        {
            title: "Photo Analyzed",
            intro: 'Your attributes have been set!',
            position: 'bottom',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        },
        {
            element: '.attributes-coachmark',
            title: "Anything off?",
            intro: 'If anything looks off, you can tweak your settings below.<br/><br/>Feel free to get creative!',
            position: 'top',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        },
        {
            element: '.generate-hero-hint',
            title: "Finished?",
            intro: 'When you\'re happy with your hero, click "Generate FitHero" to create your hero.<br/><br/>And don\'t worry: You can always change your hero later.',
            position: 'bottom',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        }
    ];

    const finishingSteps = [
        {
            element: '.select-hero-hint',
            title: "Look Good?",
            intro: 'If you\'re happy with your hero, tap a photo to select it and then tap "Save Photo" to save your hero.<br/><br/>If you\'re not happy, you can generate more options by tapping "Generate FitHero" again.',
            position: 'top',
            tooltipClass: 'myTooltipClass',
            highlightClass: 'myHighlightClass',
        }
    ];

    function coachMarkLogic() {
        return (
            <>
                <Steps
                    enabled={showAnalyzeImageCoachmark}
                    steps={analyzeImageSteps}
                    initialStep={0}
                    onExit={() => setShowAnalyzeImageCoachmark(false)}

                    options={{
                        showBullets: false,
                        exitOnOverlayClick: false,
                        exitOnEsc: true,
                        showButtons: true,
                        disableInteraction: false,
                        hidePrev: true,
                        nextLabel: "▶",
                        prevLabel: "◀",
                        doneLabel: "✔",
                    }}
                />
                <Steps
                    enabled={showAvatarTweakingCoachMark}
                    steps={avatarTweakingSteps}
                    initialStep={0}
                    onExit={() => setShowAvatarTweakingCoachMark(false)}
                    options={{
                        showBullets: false,
                        exitOnOverlayClick: false,
                        exitOnEsc: true,
                        showButtons: true,
                        disableInteraction: false,
                        hidePrev: true,
                        nextLabel: "▶",
                        prevLabel: "◀",
                        doneLabel: "✔",
                    }}
                />
                <Steps
                    enabled={showFinishCoachMark}
                    steps={finishingSteps}
                    initialStep={0}
                    onExit={() => setShowFinishCoachMark(false)}
                    options={{
                        showBullets: false,
                        exitOnOverlayClick: false,
                        exitOnEsc: true,
                        showButtons: true,
                        disableInteraction: false,
                        hidePrev: true,
                        nextLabel: "▶",
                        prevLabel: "◀",
                        doneLabel: "✔",
                    }}
                />
            </>


        )

    }

    function clearInputs() {
        if (confirmClearInputs) {
            setConfirmClearInputs(false);
            setTakenPhoto(null);
            setImageURL('');
            setImages([]);
            setImageAnalyzed(false);
            setFormValues(baseFormValues);
            setImageSelected({ url: "", index: -1 });
        } else {
            setConfirmClearInputs(true);
        }
    }

    return (
        <>
            <IonAlert
                style={{ "--max-width": "90%" }}
                isOpen={savingPhoto.visible}
                header="Is this the photo you want to use for your FitHero?"
                subHeader='You can change this later'
                message={savingPhoto.message}
                className='hero-image-alert'
                buttons={[
                    {
                        text: "No",
                        role: "cancel",
                        handler: () => {
                            setSavingPhoto({ visible: false, message: "" });
                        },
                    },
                    {
                        text: "Save Photo",
                        role: "ok",
                        handler: () => {
                            savePhoto(true);
                        },
                    },
                ]}
            />
            <IonAlert
                isOpen={photoError.visible}
                header="Oops"
                message={photoError.message}
                onDidDismiss={() => setPhotoError({ visible: false, message: "" })}
                buttons={[
                    {
                        text: "Ok",
                        role: "ok",
                    },
                ]}
            />
            <IonAlert
                isOpen={confirmClearInputs}
                header="Are you sure?"
                message="This will clear your photo and generated images."
                buttons={[
                    {
                        text: "No",
                        role: "cancel",
                        handler: () => {
                            setConfirmClearInputs(false);
                        },
                    },
                    {
                        text: "Yes",
                        role: "ok",
                        handler: () => {
                            clearInputs();
                        },
                    },
                ]}
            />
            <IonLoading isOpen={loadingMessage.show} message={loadingMessage.message} backdropDismiss />
            {/* {coachMarkLogic()} */}
            <div className='avatar-creation'>
                {props.upload === false && (
                    <div className="onboarding-main-div">
                        <div className="onboarding-title">Set Your Hero's Attributes</div>
                    </div>
                )}
                {props.upload !== false && (
                    <>
                        <div className="onboarding-main-div">
                            <div className="onboarding-title">Upload a picture of you to stylize your FitHero</div>
                            <div className="onboarding-subtitle" style={{ textAlign: "center" }}>Note: Your picture is not stored and will not be used for ANY other purpose other than to generate your FitHero</div>
                        </div>
                        <div className='photo-upload-container'>
                            {takenPhoto && (
                                <>
                                    <IonAvatar style={{ margin: "auto", height: "auto", width: "auto" }}>
                                        <img src={takenPhoto.preview} className="onboarding-add-photo-button onboarding-avatar-image" alt="" onClick={takePhotoHandler} />
                                    </IonAvatar>
                                    {imageAnalyzed ? (
                                        <>
                                            <div className='generate-hero-hint' style={{ display: 'flex', justifyContent: "space-evenly", width: "100%" }}>
                                                <IonButton style={{ color: "white", "--background": "green" }} onClick={saveAndConvertImage}>Generate FitHero</IonButton>
                                                <IonButton style={{ color: "white", "--background": "red" }} onClick={clearInputs}>Clear Photo</IonButton>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className='analyze-image-hint' style={{ display: 'flex', justifyContent: "space-evenly", width: "100%" }}>
                                                <IonButton style={{ color: "white", "--background": "green" }} onClick={getImageAnalyzed}>Analyze Image</IonButton>
                                                <IonButton style={{ color: "white", "--background": "red" }} onClick={clearInputs}>Clear Photo</IonButton>
                                            </div>
                                        </>


                                    )}
                                </>
                            )}
                            {!takenPhoto && (
                                <>
                                    <IonAvatar style={{ margin: "auto", height: "auto", width: "auto" }}>
                                        <img
                                            src="assets/images/onboarding/unknown-person.jpg"
                                            className="onboarding-add-photo-button onboarding-avatar-image"
                                            alt=""
                                            onClick={takePhotoHandler}
                                        />
                                    </IonAvatar>
                                </>
                            )}
                        </div>
                    </>

                )}

                {images.length > 0 && (<div style={{ textAlign: "center" }}><h2 className='select-hero-hint'>Select Your FitHero</h2></div>)}
                {images.map((url: string, index: number) => {
                    return (
                        index % 2 === 0 ? (
                            <IonRow>
                                <IonCol onClick={() => setImageSelected({ index: index, url: images[index] })}>
                                    <img className={`${imageSelected.index === index ? "active" : ""}`} src={images[index]} alt={`Generated ${index}`} />
                                </IonCol>
                                <IonCol onClick={() => setImageSelected({ index: index + 1, url: images[index + 1] })}>
                                    {images[index + 1] ? <img src={images[index + 1]} className={`${imageSelected.index === index + 1 ? "active" : ""}`} alt={`Generated ${index + 1}`} /> : null}
                                </IonCol>
                            </IonRow>
                        ) : null
                    );
                })}
                {imageSelected.index > -1 && (
                    // <IonFooter className="onboarding-footer">
                    <div style={{ textAlign: "center" }}>
                        <IonButton className="onboarding-advance-button" color={'success'} onClick={() => savePhoto(false)}>
                            Save photo
                        </IonButton>
                    </div>
                    // </IonFooter>
                )}
                {(imageAnalyzed || props.upload === false) && (
                    <>
                        <form
                            className="form-grid"
                            style={{ color: "white" }}
                            onSubmit={(event) => {
                                event.preventDefault();
                                runImageGeneration();
                            }}
                        >
                            <div>
                                {/* <h2 className='attributes-coachmark'></h2> */}
                                <IonRow>
                                    <IonCol size='6'>
                                        <IonLabel className='avatar-creation-label-style'>Hairstyle</IonLabel>
                                        <IonSelect placeholder='Select hairstyle' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.hairstyle === "" ? '' : 'select-selected'}`} name="hairstyle" value={formValues.hairstyle} onIonChange={handleSelectChange}>
                                            {hairstyleOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </IonCol>
                                    <IonCol size='6'>
                                        <IonLabel className='avatar-creation-label-style'>Ethnicity</IonLabel>
                                        <IonSelect placeholder='Select ethnicity' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.ethnicity === "" ? '' : 'select-selected'}`} name="ethnicity" value={formValues.ethnicity} onIonChange={handleSelectChange} >
                                            {ethnicityOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='6'>
                                        {formValues.hairstyle === 'Bald' ? (
                                            <>
                                                <IonLabel className='avatar-creation-label-style'>Hair Color</IonLabel>
                                                <IonSelect placeholder='Select hair color' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select`} name="hairColor" value={"None"} disabled={true}>
                                                    {hairColorOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                                </IonSelect>
                                            </>
                                        ) : (
                                            <>
                                                <IonLabel className='avatar-creation-label-style'>Hair Color</IonLabel>
                                                <IonSelect placeholder='Select hair color' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.hairColor === "" ? '' : 'select-selected'}`} name="hairColor" value={formValues.hairColor} onIonChange={handleSelectChange} >
                                                    {hairColorOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                                </IonSelect>
                                            </>
                                        )}
                                    </IonCol>
                                    <IonCol size='6'>

                                        <IonLabel className='avatar-creation-label-style'>Eye Color</IonLabel>
                                        <IonSelect placeholder='Select eye color' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.eyeColor === "" ? '' : 'select-selected'}`} name="eyeColor" value={formValues.eyeColor} onIonChange={handleSelectChange} >
                                            {eyeColorOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='6'>
                                        <IonLabel className='avatar-creation-label-style'>Gender</IonLabel>
                                        <IonSelect placeholder='Select gender' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.gender === "" ? '' : 'select-selected'}`} name="gender" value={formValues.gender} onIonChange={handleSelectChange} >
                                            {genderOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </IonCol>
                                    <IonCol size='6'>
                                        <IonLabel className='avatar-creation-label-style'>Age</IonLabel>
                                        <IonSelect placeholder='Select age' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.age === "" ? '' : 'select-selected'}`} name="age" value={formValues.age} onIonChange={handleSelectChange} >
                                            {ageOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    {props.upload === true ? (
                                        <IonCol size='6'>
                                            <IonLabel className='avatar-creation-label-style'>Background</IonLabel>
                                            <IonSelect placeholder='Select background' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.background === "" ? '' : 'select-selected'}`} name="background" value={"None"} disabled={true} >
                                                {backgroundOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                            </IonSelect>
                                        </IonCol>
                                    ) : (
                                        <IonCol size='6'>
                                            <IonLabel className='avatar-creation-label-style'>Background</IonLabel>
                                            <IonSelect placeholder='Select background' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.background === "" ? '' : 'select-selected'}`} name="background" value={formValues.background} onIonChange={handleSelectChange} >
                                                {backgroundOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                            </IonSelect>
                                        </IonCol>
                                    )}

                                    <IonCol size='6'>
                                        <IonLabel className='avatar-creation-label-style'>Facial Expression</IonLabel>
                                        <IonSelect placeholder='Select expression' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.facialExpression === "" ? '' : 'select-selected'}`} name="facialExpression" value={formValues.facialExpression} onIonChange={handleSelectChange} >
                                            {facialExpressionOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </IonCol>
                                </IonRow>
                                <IonRow>
                                    <IonCol size='6'>
                                        {formValues.gender === 'Female' ? (
                                            <>
                                                <IonLabel className='avatar-creation-label-style'>Facial Hair</IonLabel>
                                                <IonSelect placeholder='Select facial hair' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.hairstyle === "" ? '' : 'select-selected'}`} name="facialHair" value={"None"} disabled={true} >
                                                    {facialHairOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                                </IonSelect>
                                            </>
                                        ) : (
                                            <>
                                                <IonLabel className='avatar-creation-label-style'>Facial Hair</IonLabel>
                                                <IonSelect placeholder='Select facial hair' interface='action-sheet' className={`avatar-creation-select-style avatar-option-select ${formValues.hairstyle === "" ? '' : 'select-selected'}`} name="facialHair" value={formValues.facialHair} onIonChange={handleSelectChange} >
                                                    {facialHairOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                                </IonSelect>
                                            </>
                                        )}
                                    </IonCol>
                                </IonRow>
                                {props.upload === false && (
                                    <IonRow>
                                        <IonCol style={{ textAlign: "center" }} size='6' >
                                            <IonButton color={'success'} type="submit">Generate</IonButton>
                                        </IonCol>
                                        <IonCol style={{ textAlign: "center" }} size='6'>
                                            <IonButton color={'primary'} onClick={randomizeValues}>Randomize</IonButton>
                                        </IonCol>
                                    </IonRow>
                                )}
                            </div>
                        </form>
                    </>
                )}


            </div>
        </>
    );
}

export default CreateAvatarComponent
