export const goalsObjectKey = "goals";

export type Goal = {
    goalId: string,
    createdAt: string,
    updatedAt: string,
    user_id: string,
    startDate: string,
    endDate: string,
    active: boolean,
    strengthPoints: number,
    cardioPoints: number,
    mobilityPoints: number,
    mindfulnessPoints: number,
    strengthGoalPoints: number,
    cardioGoalPoints: number,
    mobilityGoalPoints: number,
    mindfulnessGoalPoints: number,
    completeSuccess: boolean,
    partialSuccess: boolean,
    evaluated: boolean,
    notified: boolean,
    goal_type: string
};

export const BaselineGoalValues = {
    strengthGoalPoints: 60,
    cardioGoalPoints: 60,
    mobilityGoalPoints: 5,
    mindfulnessGoalPoints: 5,
};