/* eslint-disable react/jsx-no-undef */
import React, { useContext, useRef, useState } from 'react'
import { SidekickDBObject, SidekickObject, SidekickTypeObject, drillSergeantNames, sidekickDBObjectKey, sidekickDisplayMessagesKey, sidekickThreadObjectKey } from '../../../models/sideKickModels'
import { IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonInput, IonModal, IonSpinner, IonToolbar } from '@ionic/react'
import GlobalState from '../../../contexts/GlobalState';
import log from 'loglevel';
import { dice } from 'ionicons/icons';
import { getRandomValueFromArray } from '../../../services/HelperService';
import { disableSidekick, intiateSidekick } from '../../../services/SideKickService';
import { SidekickMessage } from '../../../models/messageModels';
import { StorageService } from "../../../services/StorageService";


export const SidekickProfile = (props: { sidekickType: SidekickTypeObject, setShowSidekickProfile: any, setSidekickCreated: any }) => {
    const storageService = new StorageService();

    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { updateUserState, paywallEnabled, currentlySubscribed, setSidekick } = globalState;
    const [showPaywall, setShowPaywall] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [sidekickName, setSidekickName] = useState("");
    const [showNameModal, setShowNameModal] = useState(false);
    const [awakeningSidekick, setAwakeningSidekick] = useState(false);

    async function checkForPaywall() {
        console.log("[VillainProfile] Currently subscribed: ", currentlySubscribed)
        if (paywallEnabled && paywallEnabled === true && currentlySubscribed === false && props.sidekickType.premium === true) {
            setShowPaywall(true);
        } else {
            setShowConfirmation(true);
        }
    }
    const BottomButton = () => {
        return (
            <IonFooter className="start-duel-footer">
                <IonButton onClick={() => checkForPaywall()} color={"tertiary"} expand="full" className="start-duel-button">
                    Awaken Sidekick
                </IonButton>
            </IonFooter>
        )
    }

    const [processing, setProcessing] = useState(false);


    async function initSidekick() {
        try {
            setAwakeningSidekick(true);
            await disableSidekick();
            await storageService.removeItem(sidekickDisplayMessagesKey);
            await storageService.removeItem(sidekickThreadObjectKey);
            await storageService.removeItem(sidekickDBObjectKey);
            let sidekickObject: SidekickObject = await intiateSidekick()
            log.debug("welcomeMessage", sidekickObject.message);
            let welcomeMessageArray: SidekickMessage[] = [];
            welcomeMessageArray.push(sidekickObject.message);
            let sidekickDBObject: SidekickDBObject = await storageService.getObject(sidekickDBObjectKey);
            setSidekick(sidekickDBObject)
            // setLocalSidekick(sidekickDBObject)
            props.setSidekickCreated(true);
            props.setShowSidekickProfile({ visible: false });
            // setActiveSidekick(true);
            setAwakeningSidekick(false);
        } catch (error) {
            log.error(error);
            alert("Error: " + error);
        }
    }

    async function dismissNameModal() {
        setShowNameModal(false)
        if (sidekickName !== "") {
            await initSidekick()
        }
    }


    return (
        <>
            <IonHeader style={{ "--background": "var(--ion-background-color, #fff)", "--opacity": "1.0" }}>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => props.setShowSidekickProfile({ visible: false })}>
                            X
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            {/* <IonModal isOpen={showNameModal} onDidDismiss={() => dismissNameModal()}>
                <NameSideKick sidekick={props.sidekickType} setShowNameModal={setShowNameModal} setSidekickName={setSidekickName} />
            </IonModal> */}
            <IonContent>
                <div className="villian-profile-image">
                    <img className="villain-avatar" style={{ border: `10px solid ` }} src={props.sidekickType?.baseImage} />
                </div>
                <div className="villian-profile-name">{props.sidekickType?.sidekickName}</div>
                <div>
                    <SidekickDescription sidekick={props.sidekickType} />
                </div>

            </IonContent>
            <BottomButton />
        </>
    )
}

const SidekickDescription = (props: { sidekick: SidekickTypeObject }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    if (!props.sidekick?.sidekickDescription) {
        return <></>;
    }
    const descriptionLines = props.sidekick?.sidekickDescription.split("\\n\\n");
    const displayLines = isExpanded ? descriptionLines : [descriptionLines[0]];

    return (
        <div className="villain-profile-description">
            {displayLines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                    <br />
                </React.Fragment>
            ))}
            {descriptionLines.length > 1 &&
                (!isExpanded ? (
                    <div className="read-more" onClick={() => setIsExpanded(true)}>
                        Read More{" "}
                    </div>
                ) : (
                    <div className="read-more" onClick={() => setIsExpanded(false)}>
                        Read Less 🔼
                    </div>
                ))}
        </div>
    );
};

const NameSideKick = (props: { sidekick: SidekickTypeObject, setShowNameModal: any, setSidekickName: any }) => {
    const nameRef = useRef<HTMLIonInputElement | null>(null);
    const [codeError, setCodeError] = useState({
        visible: false,
        message: "",
    });

    function handleKeyUp(event: { keyCode: number }) {
        if (event.keyCode === 13) {
            log.debug("enter hit");
            validateName();
        }
    }

    async function validateName() {
        if (!nameRef.current?.value) {
            setCodeError({
                visible: true,
                message: "Please enter a name for your sidekick",
            })
            return false;
        }
        props.setSidekickName(nameRef.current!.value)
        props.setShowNameModal(false)
    }

    function getRandomName() {
        let nameArray = []
        switch (props.sidekick?.sidekickTypeId) {
            case 1:
                nameArray = drillSergeantNames
                break;
            default:
                nameArray = drillSergeantNames
                break;
        }
        let name = getRandomValueFromArray(nameArray)
        nameRef.current!.value = name
    }
    return (
        <div className="villain-difficulty-modal-div">
            <span className="villian-header-2" style={{ fontSize: "30px" }}>Name Your Sidekick</span><br />
            <div>
                <IonIcon icon={dice} onClick={() => getRandomName()} />
                <IonInput
                    style={{ border: "1px solid", margin: "10px 0px 10px 0px" }}
                    ref={nameRef}
                    onKeyUp={handleKeyUp}
                    name="promoCode"
                    type={"text"}
                    placeholder="Enter a name for your sidekick"
                    enterkeyhint="enter"
                    required
                ></IonInput>
                {codeError.visible === true ? <p className="error-message">{codeError.message}</p> : ""}
            </div>
            <div style={{ textAlign: "center", width: "70%", margin: "auto" }}>
                <IonButton color={"success"} onClick={() => validateName()} style={{ width: "100%" }}>Awaken</IonButton>
            </div>
        </div>
    )
}



