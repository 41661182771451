import { Moment } from "moment";

export type PetResponse = {
	GPT: string;
	DP: string;
	DPE: string;
};

export type SidekickMessage = {
	sideKickId?: string;
	threadId?: string;
	messageId?: string;
	createdAt?: string;
	updatedAt?: string;
	objectId?: string;
	source: string;
	message: string;
};

export interface CoachAPIMessage {
	content: string;
	role: string;
}

export interface CoachMessageTransformedResponse {
	displayMessages: SidekickMessage[];
	apiMessages: CoachAPIMessage[];
}

export type DuelMessageObject = {
	_id: string;
	createdAt: string;
	updatedAt: string;
	source: string;
	message: string;
	duel_id: string;
	imageUrl?: string;
};

export interface DuelDisplayMessage {
	content: string;
	role: string;
	duel_id: string;
	timestamp?: Moment;
	imageUrl?: string;
}

export interface DuelAPIMessage {
	content: string;
	role: string;
}

export interface DuelMessageTransformedResponse {
	displayMessages: DuelDisplayMessage[];
	apiMessages: DuelAPIMessage[];
}

export const openAIHeaders = {
	"Content-Type": "application/json",
	Authorization: `Bearer ${process.env.REACT_APP_OPENAI_KEY}`,
};

export const HERO_REPSPONSE_TYPE_ARRAY = [
	'Throw Back', 'Crack Joke', 'Play Ignorant', 'Give Assurance', 'Share Reflection', 'Send Warning', 'Ask Back', 'Light Tease', 'Make Boast', 'Offer Ridicule', 'Share Trivia', 'Spotlight Science', 'Drop Non Sequitur', 'Play with Words', 'Make Sudden Shift', 'Explore Background', 'Request Story', 'Narrate Story', 'Attempt Flirtation', 'Launch Counterattack', 'Mock Openly', 'Issue Threat', 'Expose Weakness', 'Declare Dominance',
];
