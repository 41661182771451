import React, { useContext, useEffect, useState } from 'react'
import { DuelStatus } from '../../../models/duelsModels'
import { IonAvatar, IonCol, IonContent, IonIcon, IonModal, IonRow, IonSpinner } from '@ionic/react'
import UseSkillBar from '../../../Skills/components/UseSkillBar'
import SuggestionModal from './SuggestionModal'
import GlobalState from '../../../contexts/GlobalState'
import { Activity, Suggestion, determineHelpNeeded, emptySuggestion, fetchDuelsLedger, getActivitySuggestion } from '../../../services/DuelsServics'
import { getHeroTranslation } from '../../../services/MessageService'
import { StorageService } from '../../../services/StorageService'
import { LAST_SUGGESTION_COMPLETED_KEY } from '../../../models/helperSuggestions'
import moment from 'moment'
import log from 'loglevel'
import { GenericModal } from '../../../components/GenericModal'
import SkillIcon from '../../../Skills/components/SkillIcon'
import { DuelHelperBubbleOnly } from './DuelHelperBubbleOnly'
import { DuelHelperNoButton } from './DuelHelperNoButton'
import { informationCircleOutline } from 'ionicons/icons'
import { UserAvatar } from '../../../components/UserAvatar'
import "../../../css/HelpNeeded.css"
import { CardioMiniIcon, MindfulnessMiniIcon, MobilityMiniIcon, StrengthMiniIcon } from '../../../models/skills'
import { TourComponent } from '../../Tour/StepsComponent'
import { DuelSkillsSteps } from '../../Tour/Steps'

let storageService = new StorageService();


export const DuelsSkills = (props: { duelStatus: DuelStatus }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, helpNeeded, setHelpNeeded } = globalState;
    const [loading, setLoading] = useState<boolean>(false);
    const [showHowToHelp, setShowHowToHelp] = useState<{ suggestion: Suggestion, visible: boolean }>({ suggestion: emptySuggestion, visible: false });
    const [didLogActivity, setDidLogActivity] = useState<boolean>(false);
    const [showAlreadyLoggedActivity, setShowAlreadyLoggedActivity] = useState<boolean>(false);
    const [showMinisInfoModal, setShowMinisInfoModal] = useState<boolean>(false);
    const [localHelpNeeded, setLocalHelpNeeded] = useState<Suggestion[]>(helpNeeded);

    useEffect(() => {
        log.debug("helpNeeded", helpNeeded);
        if (!helpNeeded || helpNeeded.length === 0) {
            getHelpNeeded();
        } else {
            setLocalHelpNeeded(helpNeeded.filter(help => help !== undefined));
        }
        determineLastSuggestionCompleted();
    }, []);

    async function determineLastSuggestionCompleted() {
        let lastSuggestionCompleted = await storageService.getItem(LAST_SUGGESTION_COMPLETED_KEY);
        // determine if lastSuggestionCompleted was before the current start of the hour using moment.js
        if (lastSuggestionCompleted) {
            let lastSuggestionCompletedDate = moment(lastSuggestionCompleted);
            let startOfHour = moment().startOf('hour');
            let endOfHour = moment().endOf('hour');
            // log.debug("lastSuggestionCompletedDate", lastSuggestionCompletedDate.format("hh:mm a"));
            // log.debug("startOfHour", startOfHour.format("hh:mm a"));
            // log.debug("endOfHour", endOfHour.format("hh:mm a"));
            if (lastSuggestionCompletedDate.isAfter(startOfHour) && lastSuggestionCompletedDate.isBefore(endOfHour)) {
                setDidLogActivity(true);
            }
            getHelpNeeded();
        }
    }

    async function getHelpNeeded() {
        setLoading(true);
        let ledger = await fetchDuelsLedger(props.duelStatus.statusId)
        let helpNeededArray: Suggestion[] = await determineHelpNeeded(props.duelStatus, ledger, heroObject!);
        log.debug("helpNeededArray", helpNeededArray);
        if (helpNeededArray && helpNeededArray.length > 0 && helpNeededArray[0] !== undefined && helpNeededArray[0].heroMessage && helpNeededArray[0].heroMessage !== "") {
            helpNeededArray[0].heroMessage = await getHeroTranslation(helpNeededArray[0].heroMessage);
        } else {
            // its empty
            let message = await getHeroTranslation("I'm doing great in this duel!");
            helpNeededArray.push({ ...emptySuggestion, heroMessage: message });
        }
        setLocalHelpNeeded(helpNeededArray);
        setHelpNeeded(helpNeededArray); // do this so we don't reload a new one every time
        setLoading(false);
    }

    async function refreshHelper() {
        setShowHowToHelp({ suggestion: emptySuggestion, visible: false })
        if (didLogActivity === true) {
            setDidLogActivity(false);
            getHelpNeeded();
        }
    }

    function showModal(activity: Activity) {
        if (didLogActivity) {
            setShowAlreadyLoggedActivity(true);
            return;
        };
        let suggestion = getActivitySuggestion(activity);
        setShowHowToHelp({
            suggestion: {
                ...suggestion,
                heroMessage: "",
                type: activity,
                duration: suggestion.duration,
                suggestionDescription: suggestion.suggestionDescription,
                imageURL: suggestion.imageURL,
                reps: suggestion.reps,
                activityType: suggestion.activityType,
            }, visible: true
        });
    }
    return (
        <>
            <IonModal id="use-skill-modal" isOpen={showHowToHelp.visible} onDidDismiss={() => refreshHelper()}>
                <SuggestionModal suggestion={showHowToHelp.suggestion} hero={heroObject!} setShowHowToHelp={setShowHowToHelp} setDidLogActivity={setDidLogActivity} />
            </IonModal>

            <IonModal className="villain-difficulty-modal" isOpen={showAlreadyLoggedActivity} onDidDismiss={() => setShowAlreadyLoggedActivity(false)}>
                <GenericModal title="Minis" body={[
                    "Each Mini can only be used once per turn.",
                    "Come back in 1 turn and you can use a Mini again!",
                ]} />
            </IonModal>
            <IonModal className="villain-difficulty-modal" isOpen={showMinisInfoModal} onDidDismiss={() => setShowMinisInfoModal(false)}>
                <GenericModal title="Minis" body={[
                    "Mini's are short activities that help you lead a healthier life.",
                    "All mini's take less than 2 mintues to complete, help your hero and help you feel good.",
                ]} />
            </IonModal>
            <IonContent>
                <div className="explanation-hint-2">
                    {props.duelStatus.complete !== true && localHelpNeeded && localHelpNeeded[0] && localHelpNeeded[0].heroMessage && (
                        <div className='mini-message-hint-tour' style={{ display: "flex", marginBottom: "10px", marginTop: "10px" }}>
                            <div>
                                <IonAvatar className="member-image">
                                    <UserAvatar avatarImage={heroObject?.heroImageURL} />
                                </IonAvatar>
                            </div>
                            <div className="villain-chat-container" style={{ width: "90%", margin: "auto" }}>
                                <IonRow style={{ justifyContent: "center" }}>
                                    <div className="mini-help-bubble">
                                        <p>
                                            {localHelpNeeded[0].heroMessage}
                                        </p>
                                    </div>
                                </IonRow>
                            </div>
                        </div >
                    )}

                    <div className='mini-hint-tour skill-header' onClick={() => setShowMinisInfoModal(true)}>
                        Minis <IonIcon color="white" icon={informationCircleOutline} />
                    </div>
                    <div className='minis-div'>
                        <IonCol size="2.25" onClick={() => showModal(Activity.Strength)} >
                            <img src={StrengthMiniIcon} alt="Strength" />
                            {didLogActivity && (
                                <div className="skill-icon-overlay"><SkillIcon cooldown={1} maxCooldown={1} /></div>
                            )}
                        </IonCol>
                        <IonCol size="2.25" onClick={() => showModal(Activity.Cardio)}>
                            <img src={CardioMiniIcon} alt="Cardio" />
                            {didLogActivity && (
                                <div className="skill-icon-overlay"><SkillIcon cooldown={1} maxCooldown={1} /></div>
                            )}
                        </IonCol>
                        <IonCol size="2.25" onClick={() => showModal(Activity.Mobility)}>
                            <img src={MobilityMiniIcon} alt="Mobility" />
                            {didLogActivity && (
                                <div className="skill-icon-overlay"><SkillIcon cooldown={1} maxCooldown={1} /></div>
                            )}
                        </IonCol>
                        <IonCol size="2.25" onClick={() => showModal(Activity.Mindfulness)}>
                            <img src={MindfulnessMiniIcon} alt="Mindfulness" />
                            {didLogActivity && (
                                <div className="skill-icon-overlay"><SkillIcon cooldown={1} maxCooldown={1} /></div>
                            )}
                        </IonCol>
                    </div>
                </div>
                {props.duelStatus && props.duelStatus.complete !== true && (
                    <div className="skills-hint-tour" style={{ marginTop: "10px" }}>
                        <UseSkillBar duelStatus={props.duelStatus} />
                    </div>
                )}
                <TourComponent coachMarkName='duelSkills' steps={DuelSkillsSteps} modalShowing={false} link={`/dueldetails/${props.duelStatus.statusId}/chat`} />
            </IonContent>
        </>
    )
}
