import { IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonLoading } from '@ionic/react';
import log from 'loglevel';
import { useContext, useState } from 'react';
import GlobalState from '../../contexts/GlobalState';
import "../../css/Skills.css";
import { Skill, SkillInUse, SkillModalType, genericIcon } from "../../models/skills";
import { capitalizeFirstLetter } from '../../services/HelperService';
import { sendSlackNotification } from '../../services/NotificationService';
import { activateSkill } from '../../services/SkillsService';

const UseSkillModal = (props: { skillDisabled: boolean | undefined, skill: Skill, skillInUse?: SkillInUse, setShowSkillModal: React.Dispatch<React.SetStateAction<SkillModalType>>, setShowSkillActivatedModal: React.Dispatch<React.SetStateAction<SkillModalType>>, skillUsed: boolean }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject } = globalState;
    const [activatingSkill, setActivatingSkill] = useState(false)
    async function triggerSkill() {
        setActivatingSkill(true)
        sendSlackNotification(`[skill activation] Skill ${props.skill.name} activated by ${heroObject?.heroName}`, "duelUpdates", true)
        let skillActivated = await activateSkill(props.skill)
        if (skillActivated) {
            log.debug(`Skill ${props.skill.name} activated`)
            setActivatingSkill(false)
            props.setShowSkillModal({ show: false, skill: null })
            props.setShowSkillActivatedModal({ show: true, skill: props.skill })
        } else {
            log.debug("Skill not activated")
        }
        setActivatingSkill(false)
    }

    const renderSkillAction = () => {
        if (props.skillDisabled) {
            return <div className='skills-cant-use'>This skill requires {capitalizeFirstLetter(props.skill.tree)} level {props.skill.level} to use.</div>;
        }
        if (props.skillUsed) {
            return <div className='skills-cant-use'>Your hero has used a skill this turn. They can use another skill next turn.</div>;
        }
        if (props.skillInUse && props.skillInUse.cooldown > 0) {
            return <div>Skill activated. It can be used again in {props.skillInUse.cooldown + props.skillInUse.repeat} hours.</div>;
        }
        return <IonButton onClick={() => triggerSkill()} size='large' expand='block' color={'success'}>Use {props.skill.name}</IonButton>;
    };

    //TODO: Show confirmation modal after activating skill
    return (
        <>
            <IonLoading isOpen={activatingSkill} message={'Activating skill...'} backdropDismiss={true} />
            {props.skill && (
                <div style={{ margin: "20px" }}>
                    <IonCard>
                        <div className='skill-card-image-div'>
                            {props.skill.icon ? (
                                <img src={props.skill.icon.url} alt='skill icon' />

                            ) : (
                                <img src={genericIcon.url} alt='generic icon' />

                            )}
                        </div>
                        <IonCardHeader>
                            <IonCardTitle>
                                {props.skill.name}
                            </IonCardTitle>
                            <IonCardSubtitle>
                                <p>Duration: {props.skill.repeat === 0 ? "Immediate" : props.skill.repeat + " hours"}<br />Cooldown: {props.skill.cooldown + props.skill.repeat} hours</p>
                                {/* <p>{props.skill.damage && (<>💥: {props.skill.damage}</>)}</p> */}
                            </IonCardSubtitle>
                        </IonCardHeader>
                        <IonCardContent>
                            <p>{props.skill.description}</p>
                        </IonCardContent>
                        {renderSkillAction()}
                    </IonCard>
                </div>
            )}

        </>
    )
}

export default UseSkillModal