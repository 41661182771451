import moment from "moment";

export const ExcludedActivities = [
	"still", "unknown", "in_vehicle", "in_rail_vehicle", "in_road_vehicle", "in_two_wheeler_vehicle", "in_four_wheeler_vehicle"
]

export type WorkoutObject = {
	_id?: string; // server auto-generates this
	objectId?: string; // server auto-generates this
	createdAt?: string; // server auto-generates this
	updatedAt?: string; // server auto-generates this
	user_id: string; // the id of the user
	UUID: string;
	dataType: string;
	activityType: string;
	startDate: string;
	endDate: string;
	totalDistance: number;
	totalDistanceUnit: string;
	value: number; // duration or number of steps
	valueUnit: string; // duration time unit or number of steps
	totalEnergyBurned: number;
	reportId: string;
	sourceName: string;
	timeZone?: string;
	weatherTemperature?: string;
	humidity?: string;
	caloriesPerMinute?: number;
	intensityMultiple?: number;
	strengthPoints?: number;
	cardioPoints?: number;
	mobilityPoints?: number;
	mindfulnessPoints?: number;
	intensityLevel?: string;
	deleted?: boolean;
	activityId?: string;
};

export type HealthKitWorkoutObject = {
	endDate: string;
	startDate: string;
	totalDistance: any;
	sourceBundleId: string;
	totalEnergyBurned: string; // because it comes in like "61.178 kcal"
	duration: number; // time in fraction of hours
	workoutActivityName: string; // string of activity name - no need to convert
	source: string; // i.e. "Adam’s Apple Watch"
	uuid: string; // unique identifier of a workout object
};

export type GoogleFitWorkoutObject = {
	uuid: string;
	startDate: Date;
	endDate: Date;
	unit: string;
	value: any;
	sourceBundleId: string;
	distance: number;
	calories: number;
};

export type AttributeRate = {
	cardioRate: number;
	strengthRate: number;
	mobilityRate: number;
	mindfulRate: number;
};

export type ExercisePoints = {
	strengthPoints: number;
	cardioPoints: number;
	mindfulnessPoints: number;
	mobilityPoints: number;
};

export interface HeroStats {
	strengthLevel: number;
	strengthPoints: number;
	cardioLevel: number;
	cardioPoints: number;
	mindfulnessLevel: number;
	mindfulnessPoints: number;
	mobilityLevel: number;
	mobilityPoints: number;
	totalPoints: number;
	overallLevel: number;
}

export const LastUpdatedFormat = "YYYY-MM-DD hh:mm:ss a";

export const StrengthPointsPerLevel = 10;
export const CardioPointsPerLevel = 10;
export const MobilityPointsPerLevel = 10;
export const MindfulnessPointsPerLevel = 10;
export const HeroPointsPerLevel = 10;
export const attributes = ["strength", "cardio", "mobility", "mindfulness"];
export const pointsPerLevel: any = {
	strength: StrengthPointsPerLevel,
	cardio: CardioPointsPerLevel,
	mobility: MobilityPointsPerLevel,
	mindfulness: MindfulnessPointsPerLevel,
	// Add more attributes here as needed
};

export const BaseHeroStats = {
	strengthLevel: 1,
	strengthPoints: 0,
	cardioLevel: 1,
	cardioPoints: 0,
	mindfulnessLevel: 1,
	mindfulnessPoints: 0,
	mobilityLevel: 1,
	mobilityPoints: 0,
	totalPoints: 0,
	overallLevel: 1,
	lastUpdated: moment("01-01-2000").format(),
};

export type ActivityLedger = {
	uuid?: string;
	_id?: string;
	activityName: string;
	activityDate: string;
	caloriesBurned?: number; // these are optional for supporting degradation
	caloriesPerMinute?: number;
	durationMinutes: number;
	strengthPoints: number;
	cardioPoints: number;
	mobilityPoints: number;
	mindfulnessPoints: number;
	intensityLevel: string;
	intensityMultiple: number;
	deleted?: boolean;
	sourceName?: string;
	steps?: number; // didn't really have a place for this
	activityId?: string; // different than the uuid
};

export type ProgressCallback = (progress: string) => void;

export type ActivityResult = {
	activityName: string;
	duration: string;
};

export type DegradationRates = {
	strengthRate: number;
	cardioRate: number;
	mindfulnessRate: number;
	mobilityRate: number;
};

export type SummaryType = {
	attributes: string[];
	updated: string | null;
	date: string;
	reset: boolean;
};

export const baseDegradationRate = 10; // The basic rate of degradation per hour
export const degradationScaleFactor = 0.1; // The scale factor per level
export const strengthIcon = "💪";
export const cardioIcon = "💚";
export const mobilityIcon = "🧘‍♀️";
export const mindfulnessIcon = "🙏";
export const damageIcon = "💥";
export const healthIcon = "❤️";
export const dodgeIcon = "💨";
export const accuracyIcon = "🎯";
export const sourceIcon = "🔎"

export type ManualActivity = {
	activityName: string;
	duration: number;
	startDate: string;
};

export type ActivitySelection = {
	activityName: string;
	icon: string;
	metValue: number;
};

export const mostPopularActivities: ActivitySelection[] = [
	{ activityName: "Steps", icon: ":footprints:", metValue: 3.5 },
	{ activityName: "Walking", icon: ":walking:", metValue: 3.5 },
	{ activityName: "Running", icon: ":runner:", metValue: 8.0 },
	{ activityName: "High Intensity Interval Training", icon: ":sweat_smile:", metValue: 8.0 },
	{ activityName: "Meditation", icon: ":pray:", metValue: 0.0 },
	{ activityName: "Stretching", icon: ":person_in_lotus_position:", metValue: 2.5 },
	{ activityName: "Cycling", icon: ":bicyclist:", metValue: 6.8 },
	{ activityName: "Household Chores", icon: ":broom:", metValue: 3.5 },
	{ activityName: "Yoga", icon: ":person_in_lotus_position:", metValue: 2.5 },
	{ activityName: "Traditional Strength Training", icon: ":weight_lifter:", metValue: 5.0 },
	{ activityName: "Elliptical", icon: ":triumph:", metValue: 5.0 },
	{ activityName: "Swimming", icon: ":swimmer:", metValue: 7.0 },
	{ activityName: "Sexual Activity", icon: ":heart:", metValue: 1.3 },
	{ activityName: "Active Job", icon: ":construction_worker:", metValue: 4.0 },
];

export const allActivities: ActivitySelection[] = [
	{ activityName: "Active Job", icon: ":construction_worker:", metValue: 4.0 },
	{ activityName: "Acupuncture", icon: ":round_pushpin:", metValue: 1.0 },
	{ activityName: "American Football", icon: ":football:", metValue: 8.0 },
	{ activityName: "Archery", icon: ":bow_and_arrow:", metValue: 3.5 },
	{ activityName: "Australian Football", icon: ":rugby_football:", metValue: 7.0 },
	{ activityName: "Badminton", icon: ":badminton_racquet_and_shuttlecock:", metValue: 5.5 },
	{ activityName: "Barre", icon: ":ballet_shoes:", metValue: 4.0 },
	{ activityName: "Baseball", icon: ":baseball:", metValue: 5.0 },
	{ activityName: "Basketball", icon: ":basketball:", metValue: 6.5 },
	{ activityName: "Bowling", icon: ":bowling:", metValue: 3.0 },
	{ activityName: "Boxing", icon: ":boxing_glove:", metValue: 7.0 },
	{ activityName: "Climbing", icon: ":mountain:", metValue: 8.0 },
	{ activityName: "Cool Down", icon: ":snowflake:", metValue: 2.0 },
	{ activityName: "Core training", icon: ":weight_lifter:", metValue: 3.8 },
	{ activityName: "Cross Country Skiing", icon: ":skier:", metValue: 7.0 },
	{ activityName: "Cross Training", icon: ":weight_lifter:", metValue: 5.0 },
	{ activityName: "Curling", icon: ":curling_stone:", metValue: 4.0 },
	{ activityName: "Cycling", icon: ":bicyclist:", metValue: 6.8 },
	{ activityName: "Dance", icon: ":dancer:", metValue: 4.5 },
	{ activityName: "Dance Inspired Training", icon: ":dancer:", metValue: 4.5 },
	{ activityName: "Disc Sports", icon: ":flying_disc:", metValue: 4.0 },
	{ activityName: "Downhill Skiing", icon: ":skier:", metValue: 6.0 },
	{ activityName: "Equestrian Sports", icon: ":horse_racing:", metValue: 3.8 },
	{ activityName: "Elliptical", icon: ":triumph:", metValue: 5.0 },
	{ activityName: "Fencing", icon: ":fencer:", metValue: 6.0 },
	{ activityName: "Fishing", icon: ":fishing_pole_and_fish:", metValue: 2.5 },
	{ activityName: "Fitness Gaming", icon: ":joystick:", metValue: 4.0 },
	{ activityName: "Flexibility", icon: ":person_in_lotus_position:", metValue: 2.5 },
	{ activityName: "Functional Strength Training", icon: ":weight_lifter:", metValue: 4.0 },
	{ activityName: "General Cardio", icon: ":walking:", metValue: 3.5 },
	{ activityName: "General Mobility", icon: ":person_in_lotus_position:", metValue: 2.5 },
	{ activityName: "General Mindfulness", icon: ":person_in_lotus_position:", metValue: 2.5 },
	{ activityName: "Golf", icon: ":golf:", metValue: 4.8 },
	{ activityName: "Gymnastics", icon: ":man-cartwheeling:", metValue: 3.8 },
	{ activityName: "Hand Cycling", icon: ":bicyclist:", metValue: 8.0 },
	{ activityName: "Handball", icon: ":handball:", metValue: 8.0 },
	{ activityName: "High Intensity Interval Training", icon: ":sweat_smile:", metValue: 8.0 },
	{ activityName: "Hiking", icon: ":mountain:", metValue: 6.0 },
	{ activityName: "Hockey", icon: ":ice_hockey_stick_and_puck:", metValue: 8.0 },
	{ activityName: "Household chores", icon: ":broom:", metValue: 3.5 },
	{ activityName: "Hunting", icon: ":bow_and_arrow:", metValue: 6.0 },
	{ activityName: "Journaling", icon: ":writing_hand:", metValue: 0.0 },
	{ activityName: "Jump Rope", icon: ":muscle:", metValue: 10.0 },
	{ activityName: "Kickboxing", icon: ":boxing_glove:", metValue: 8.0 },
	{ activityName: "Lacrosse", icon: ":lacrosse:", metValue: 8.0 },
	{ activityName: "Martial Arts", icon: ":martial_arts_uniform:", metValue: 10.0 },
	{ activityName: "Massage", icon: ":massage:", metValue: 1.0 },
	{ activityName: "Meditation", icon: ":pray:", metValue: 0.0 },
	{ activityName: "Mixed Cardio", icon: ":person_doing_cartwheel:", metValue: 5.0 },
	{ activityName: "Mixed Metabolic Cardio Training", icon: ":person_doing_cartwheel:", metValue: 5.0 },
	{ activityName: "Mind And Body", icon: ":person_in_lotus_position:", metValue: 2.5 },
	{ activityName: "Other", icon: ":muscle:", metValue: 4.0 },
	{ activityName: "Paddle Sports", icon: ":canoe:", metValue: 6.0 },
	{ activityName: "Pickleball", icon: ":ping_pong:", metValue: 4.0 },
	{ activityName: "Pilates", icon: ":person_in_lotus_position:", metValue: 3.0 },
	{ activityName: "Play", icon: ":partying_face:", metValue: 3.0 },
	{ activityName: "Preparation And Recovery", icon: ":person_in_lotus_position:", metValue: 2.0 },
	{ activityName: "Racquetball", icon: ":tennis:", metValue: 7.0 },
	{ activityName: "Rowing", icon: ":rowboat:", metValue: 7.0 },
	{ activityName: "Rugby", icon: ":rugby_football:", metValue: 7.5 },
	{ activityName: "Running", icon: ":runner:", metValue: 8.0 },
	{ activityName: "Sailing", icon: ":sailboat:", metValue: 3.0 },
	{ activityName: "Sexual Activity", icon: ":heart:", metValue: 1.3 },
	{ activityName: "Skating Sports", icon: ":ice_skate:", metValue: 5.5 },
	{ activityName: "Snow Sports", icon: ":snowboarder:", metValue: 7.0 },
	{ activityName: "Snowboarding", icon: ":snowboarder:", metValue: 7.0 },
	{ activityName: "Soccer", icon: ":soccer:", metValue: 7.0 },
	{ activityName: "Softball", icon: ":baseball:", metValue: 5.0 },
	{ activityName: "Squash", icon: ":tennis:", metValue: 7.5 },
	{ activityName: "Stair Climbing", icon: ":leg:", metValue: 9.0 },
	{ activityName: "Stairs", icon: ":leg:", metValue: 9.0 },
	{ activityName: "Steps", icon: ":footprints:", metValue: 2.5 },
	{ activityName: "Step Training", icon: ":leg:", metValue: 5.5 },
	{ activityName: "Stretching", icon: ":person_in_lotus_position:", metValue: 2.5 },
	{ activityName: "Surfing Sports", icon: ":surfer:", metValue: 3.0 },
	{ activityName: "Swimming", icon: ":swimmer:", metValue: 7.0 },
	{ activityName: "Table Tennis", icon: ":ping_pong:", metValue: 4.0 },
	{ activityName: "Tai Chi", icon: ":person_in_lotus_position:", metValue: 3.0 },
	{ activityName: "Tennis", icon: ":tennis:", metValue: 7.3 },
	{ activityName: "Traditional Strength Training", icon: ":weight_lifter:", metValue: 5.0 },
	{ activityName: "Track And Field", icon: ":runner:", metValue: 10.0 },
	{ activityName: "Volleyball", icon: ":volleyball:", metValue: 4.0 },
	{ activityName: "Walking", icon: ":walking:", metValue: 3.5 },
	{ activityName: "Water Fitness", icon: ":swimmer:", metValue: 5.5 },
	{ activityName: "Water Polo", icon: ":water_polo:", metValue: 8.0 },
	{ activityName: "Water Sports", icon: ":swimmer:", metValue: 6.0 },
	{ activityName: "Wheelchair Run Pace", icon: ":man_in_manual_wheelchair:", metValue: 5.0 },
	{ activityName: "Wheelchair Walk Pace", icon: ":man_in_manual_wheelchair:", metValue: 2.5 },
	{ activityName: "Weightlifting", icon: ":weight_lifter:", metValue: 5.0 },
	{ activityName: "Wrestling", icon: ":wrestlers:", metValue: 6.0 },
	{ activityName: "Yoga", icon: ":person_in_lotus_position:", metValue: 2.5 },
];

export const sampleSteps = {
	resultData: [
		{
			unitName: "count",
			source: "Adam’s Apple Watch",
			value: 8888,
			sourceBundleId: "com.apple.health.52B00364-FEBB-4469-9F9F-1FE37C9BA40A",
			startDate: "2023-08-16T03:41:55Z",
			uuid: "4C933713-0D8C-4A4E-99D3-25B27A5E3C1C",
			endDate: "2023-08-16T03:41:57Z",
			device: {
				model: "Watch",
				name: "Apple Watch",
				hardwareVersion: "Watch4,3",
				softwareVersion: "9.3.1",
				manufacturer: "Apple Inc.",
			},
			duration: 0.0007142091790835063,
		},
		{
			duration: 0.0014260750346713595,
			unitName: "count",
			startDate: "2023-08-16T03:44:10Z",
			sourceBundleId: "com.apple.health.1A78BDB2-529F-4728-BE1E-A16A6FBEB5EF",
			value: 8889,
			device: {
				model: "iPhone",
				name: "iPhone",
				hardwareVersion: "iPhone15,2",
				softwareVersion: "16.1.1",
				manufacturer: "Apple Inc.",
			},
			endDate: "2023-08-16T03:44:15Z",
			source: "RiggzPhone",
			uuid: "3969191C-8502-4E00-A383-62E451E27EF9",
		},
		{
			duration: 0.1642530400885476,
			endDate: "2023-08-16T04:08:59Z",
			unitName: "count",
			sourceBundleId: "com.apple.health.52B00364-FEBB-4469-9F9F-1FE37C9BA40A",
			startDate: "2023-08-16T03:59:08Z",
			uuid: "B780B008-0925-4C87-A8E5-9D922A9B7693",
			device: {
				model: "Watch",
				softwareVersion: "9.3.1",
				name: "Apple Watch",
				hardwareVersion: "Watch4,3",
				manufacturer: "Apple Inc.",
			},
			source: "Adam’s Apple Watch",
			value: 5883,
		},
		{
			value: 4884,
			duration: 0.010693470107184517,
			source: "RiggzPhone",
			unitName: "count",
			device: {
				model: "iPhone",
				softwareVersion: "16.1.1",
				hardwareVersion: "iPhone15,2",
				name: "iPhone",
				manufacturer: "Apple Inc.",
			},
			sourceBundleId: "com.apple.health.1A78BDB2-529F-4728-BE1E-A16A6FBEB5EF",
			startDate: "2023-08-16T04:36:58Z",
			endDate: "2023-08-16T04:37:37Z",
			uuid: "8A70F2D9-714B-4F77-B375-684DFBFE1A08",
		},
	],
	countReturn: 4,
};

export const HealthKitReadTypes = {
	readTypes: ["HKQuantityTypeIdentifierStepCount", "HKWorkoutTypeIdentifier", "HKQuantityTypeIdentifierActiveEnergyBurned"],
};




