/* eslint-disable react-hooks/exhaustive-deps */
import { IonButton, IonContent, IonFooter, IonPage } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import HeaderLogo from "../../components/HeaderLogo";
import { OnboardingObject } from "../../models/userModel";
import { StorageService } from "../../services/StorageService";
import GlobalState from "../../contexts/GlobalState";
import { logGenericEvent } from "../../services/AnalyticsService";

const FitBitConnected = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { currentlySubscribed, paywallEnabled } = globalState;
    const [nextPage, setNextPage] = useState<string>("/onboarding/register");
    const storageService = new StorageService();
    const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
    useEffect(() => {
        updateOnboardingObject();
    }, []);

    async function updateOnboardingObject() {
        logGenericEvent("fitbit connected");
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        setOnboardingObject(tempOnboardingObject);
        await storageService.setObject("onboarding", tempOnboardingObject);
    }

    return (
        <IonPage>
            <HeaderLogo />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Your Fitbit is Connected!</div>
                    <div style={{ textAlign: "center", marginTop: "30%", width: "80%", marginLeft: "auto", marginRight: "auto" }}>
                        <p>As your Fitbit picks up your workouts, you will now automatically progress.</p>
                    </div>
                </div>
            </IonContent>
            <IonFooter className="onboarding-footer">
                <IonButton className="onboarding-advance-button" routerLink={nextPage}>
                    Awesome!
                </IonButton>
            </IonFooter>
        </IonPage>
    );
};

export default FitBitConnected;
