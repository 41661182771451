import { IonCol, IonContent, IonGrid, IonModal, IonRow, IonSpinner } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import GlobalState from '../../../contexts/GlobalState';
import "../../../css/Duels.css";
import { DuelStatus } from '../../../models/duelsModels';
import DuelStatusTable from './DuelStatusTable';
import { HeroObject } from '../../../models/userModel';
import { set } from 'lodash';
import { TallyObject, getAllDuels, getAllDuelsForOtherUser, getDuelStatus, getUserVillainTally } from '../../../services/DuelsServics';
import { getUserByHeroID } from '../../../services/UserService';
import log from 'loglevel';
import DuelStats from './DuelStats';
import DuelRecord from './DuelRecord';
import { Villain } from '../../../models/villainModels';
import { getVillains } from '../../../services/VillainServices';
import VillainProfile from '../../Villians/components/VillainProfile';
import { VillainTallyComponent } from '../../Villians/components/VillainTallyComponent';
import AllVillainRecords from './AllVillainRecords';

export const PracticeGym = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile } = globalState;
    const [villains, setVillains] = useState<Villain[]>([]);
    const [allTallys, setAllTallys] = useState<TallyObject[]>([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState({ show: false, villain: {} as Villain });


    useEffect(() => {
        pullVillains();
    }, []);

    async function pullVillains() {
        setLoading(true);
        setVillains(await getVillains());
        setAllTallys(await getUserVillainTally());
        setLoading(false);
    }

    return (
        <>
            <IonContent>
                <IonModal
                    isOpen={showModal.show}
                    onWillDismiss={() => {
                        setShowModal({ show: false, villain: {} as Villain });
                    }}
                    style={isMobile ? {} : { "--height": "90%" }}
                >
                    <VillainProfile villain={showModal.villain} setShowModal={setShowModal} showStartDuelButton={true} fromGym={true} difficulty={showModal.villain?.boss === true ? "boss" : ""} />
                </IonModal>
                <IonGrid>
                    {loading && <IonRow><IonCol style={{ textAlign: "center" }}><IonSpinner name="crescent" /></IonCol></IonRow>}
                    {villains && allTallys && (
                        <AllVillainRecords allTallys={allTallys} villains={villains} />
                    )}
                    {villains.map((villain, index) => {
                        return index % 3 === 0 ? (
                            <IonRow key={index}>
                                {villains.slice(index, index + 3).map((villain, i) => (
                                    <IonCol className={index === 0 ? "showHint" : ""} size="4" key={i} onClick={() => setShowModal({ show: true, villain: villain })}>
                                        <div>
                                            <img className="villain-avatar" src={villain.imageURL} alt={`${villain.name} avatar`} />
                                            <div className='tally-placement'>
                                                <VillainTallyComponent villainId={villain.villainID} boss={villain.boss} />
                                            </div>
                                        </div>
                                        <div className="villian-name" style={{ position: "relative", top: "-20px" }}>{villain.name}</div>
                                    </IonCol>
                                ))}
                            </IonRow>
                        ) : null;
                    })}
                </IonGrid>
            </IonContent>
        </>
    )
}
