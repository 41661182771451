/* eslint-disable jsx-a11y/img-redundant-alt */
import { IonContent, IonPage } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import HeaderLogo from '../../components/HeaderLogo';
import "../../css/Onboarding.css";
import { OnboardingObject } from '../../models/userModel';
import { StorageService } from '../../services/StorageService';


const PhotoOption = () => {
    const uploadPhoto = "/onboarding/uploadphoto";
    const createAvatar = "/onboarding/createavatar";
    const storageService = new StorageService();
    const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();

    const history = useHistory();
    useEffect(() => {
        updateOnboardingObject();
    }, []);

    async function updateOnboardingObject() {
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        if (!tempOnboardingObject) {
            tempOnboardingObject = {} as OnboardingObject;
        }
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        setOnboardingObject(tempOnboardingObject);
        await storageService.setObject("onboarding", tempOnboardingObject);
    }
    return (
        <IonPage>
            <HeaderLogo showBackButton={false} />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Choose your FitHero<br />avatar style</div>
                </div>
                <div>
                    <div className='onboarding-upload-photo' onClick={() => history.push(createAvatar)}>
                        <img src="assets/images/onboarding/create-ai-avatar.jpg" className="onboarding-add-photo-button" alt="create avatar with settings" />
                    </div>
                    <div style={{ marginBottom: "10px" }} className='onboarding-add-photo-button-div'>
                        Create FitHero Based on Settings
                    </div>
                </div>
                <div>
                    <div className='onboarding-upload-photo' onClick={() => history.push(uploadPhoto)}>
                        <img src="assets/images/onboarding/unknown-person.jpg" className="onboarding-add-photo-button" alt="create avatar with photo" />
                    </div>
                    <div className='onboarding-add-photo-button-div'>
                        Create FitHero Based on a Photo
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default PhotoOption