/* eslint-disable react-hooks/exhaustive-deps */
import { IonButton, IonContent, IonFooter, IonModal, IonPage } from "@ionic/react";
import log from "loglevel";
import { useContext, useEffect, useState } from "react";
import HeroStatsCircularComponent from "../../Features/Profile/HeroStatsCircularComponent";
import AddActivity from "../../components/AddActivity";
import HeaderLogo from "../../components/HeaderLogo";
import ProfileHeader from "../../Features/Profile/components/ProfileHeader";
import GlobalState from "../../contexts/GlobalState";
import { OnboardingObject } from "../../models/userModel";
import { StorageService } from "../../services/StorageService";

const LogFirstActivity = () => {
	const nextPage = "/onboarding/firsthit";
	const storageService = new StorageService();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { isMobile, heroObject, previousHeroObject } = globalState;
	const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
	const [activityModal, showAddActivityModal] = useState<boolean>(false);
	useEffect(() => {
		updateOnboardingObject();
	}, []);

	useEffect(() => { }, [onboardingObject]);

	useEffect(() => {
		log.debug("[LogFirstActivity] heroObject: ", heroObject);
		log.debug("[LogFirstActivity] previousHeroObject: ", previousHeroObject);
		if (heroObject !== previousHeroObject) {
			if (onboardingObject !== undefined) {
				let onbObj = { ...onboardingObject, loggedActivity: true };
				storageService.setObject("onboarding", onbObj);
				setOnboardingObject({ ...onboardingObject, loggedActivity: true });
			}
		} else {
			log.debug("[LogFirstActivity] heroObject NOT updated");
		}
	}, [activityModal]);

	async function updateOnboardingObject() {
		let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
		if (tempOnboardingObject === null) {
			tempOnboardingObject = {} as OnboardingObject;
		}
		tempOnboardingObject.url = window.location.pathname;
		tempOnboardingObject.completed = false;
		setOnboardingObject(tempOnboardingObject);
		await storageService.setObject("onboarding", tempOnboardingObject);
	}
	return (
		<IonPage>
			<HeaderLogo />

			<IonContent>
				<IonModal isOpen={activityModal} className={`${isMobile ? "" : "desktop-add-activity-modal"}`}>
					<AddActivity showActivityModal={showAddActivityModal} />
				</IonModal>
				<div className="onboarding-main-div">
					<div className="onboarding-title">Log your first activity</div>
					<ProfileHeader heroName={onboardingObject?.heroName} heroPicture={onboardingObject?.heroPicture} heroLevel={heroObject?.overallLevel} />

					<HeroStatsCircularComponent />
					<div className="onboarding-text-area" style={{ textAlign: "center" }}>
						{onboardingObject && !onboardingObject.loggedActivity ? (
							<>
								<p>Great! To show how this works in action, let's log your first activity.</p>
								<p>Just log <span className="onboarding-emphasis">SOMETHING</span> you've done in the last 24 hours, <span className="onboarding-emphasis">even just a short walk</span></p>

							</>
						) : (
							<>
								<p>Great job! You logged your first activity!</p>
								<p>As you can see <span className="onboarding-emphasis">your points</span> have increased as a result.</p>
								<p>Every minute of activity you log will increase your points by 1.</p>
								<p>If your activity is <span className="onboarding-emphasis">more intense</span>, you will earn <span className="onboarding-emphasis">more points</span>.</p>
							</>
						)}
					</div>
				</div>
			</IonContent>
			{onboardingObject && onboardingObject.loggedActivity === true && (
				<IonFooter className="onboarding-footer">
					<IonButton className="onboarding-advance-button" routerLink={nextPage}>
						Cool
					</IonButton>
				</IonFooter>
			)}
			{onboardingObject && !onboardingObject.loggedActivity && (
				<IonFooter className="onboarding-footer"><IonButton onClick={() => showAddActivityModal(true)} className="onboarding-advance-button">
					Add activity
				</IonButton></IonFooter>
			)}
		</IonPage>
	);
};

export default LogFirstActivity;
