/* eslint-disable no-loop-func */
import { HealthKit } from "@ionic-native/health-kit";
import moment, { Moment } from "moment";
import {
	ActivityLedger,
	AttributeRate,
	GoogleFitWorkoutObject,
	HealthKitWorkoutObject,
	ProgressCallback,
	WorkoutObject,
	sampleSteps,
} from "../models/exerciseModels";
// import { convertCalsToCredits } from "./HelperServices";
// import { API_URL } from "../models/constants";
import { Health } from "@awesome-cordova-plugins/health";
import { Device } from "@capacitor/device";
import { ActivityData, CapacitorHealthkit, OtherData, SampleNames } from "@perfood/capacitor-healthkit";
import { v4 as uuidv4 } from "uuid";
import { healthKitAuthorizedKey, lastUpdatedStepsKey } from "../models/userModel";
import {
	convertStepsToWorkoutObject,
	createLedgerFromWorkoutArray,
	filterForUniqueWorkouts,
	getRecentWorkouts,
	logStepsActivity,
	postWorkoutsToServer,
	upsertWorkouts,
} from "./ExerciseService";
import { createUUIDFromString } from "./GoogleFitService";
import { calculateAttributeRateFromHKObj, getFullDeviceInfo, getIntensityLevel } from "./HelperService";
import { StorageService } from "./StorageService";
import { getUser_id } from "./UserService";
import log from "loglevel";
const storageService = new StorageService();

export async function getHKStepsv2(startDate: string, endDate: string, baseline: boolean = false, onProgress?: ProgressCallback): Promise<HKData> {
	let workouts: WorkoutObject[] = [];

	let currentStart = moment(startDate);
	let end = moment(endDate);
	let currentDate = moment();

	while (currentStart.isBefore(end)) {
		/*
		// if currentStart is NOT the current day, then this is historic data, and the clone should add 1 day
		// But the problem is if the day before has already been pulled it will double count
		// if currentStart IS the current day, then this is current data, and the clone should add 30 minutes
		*/

		// for now just always pull 30 minute intervals
		// This will help to ensure that the first pull of the day does not double count the previous day

		let currentEnd: Moment;
		// if (currentStart.isSame(currentDate, "day")) {
		// 	// log.debug(`[getHKStepsv2] pulling for today`);
		// 	currentEnd = currentStart.clone().add(30, "minutes");
		// 	if (onProgress) onProgress(`Pulling steps for today...`);
		// } else {
		// 	// log.debug(`[getHKStepsv2] pulling for ${moment(currentStart).format("MM/DD/YYYY")}`);
		// 	currentEnd = currentStart.clone().add(1, "day").subtract(1, "second");
		// 	if (onProgress) onProgress(`Pulling steps for ${moment(currentStart).format("MM/DD/YYYY")}...}`);
		// }

		currentEnd = currentStart.clone().add(30, "minutes");

		if (currentEnd.isAfter(end)) {
			currentEnd = end;
		}

		let gfSteps: any = await Health.queryAggregated({
			startDate: currentStart.toDate(),
			endDate: currentEnd.toDate(),
			dataType: "steps",
			filtered: true,
		}).catch((err) => {
			log.debug(`[getHKStepsv2] queryAggregated error for interval ${currentStart.format()} - ${currentEnd.format()}: ${JSON.stringify(err)}`);
			throw err;
		});

		let caloriesBurned = 0; // Presumably, you might want to modify this according to the returned gfSteps.
		log.debug(`[getHKStepsv2] getHKStepsv2 for interval ${currentStart.format()} - ${currentEnd.format()}: ${JSON.stringify(gfSteps)}`);

		if (gfSteps && gfSteps.value > 0) {
			// gonna ignore 0 step sessions
			let activityString = moment(gfSteps.startDate.toString()).format() + moment(gfSteps.endDate.toString()).format() + gfSteps.value;
			let uuid = await createUUIDFromString(activityString);
			// log.debug(`[getHKStepsv2] activity: ${JSON.stringify(gfSteps)}`);
			let GFObject: GoogleFitWorkoutObject = {
				uuid: uuid,
				startDate: gfSteps.startDate,
				endDate: gfSteps.endDate,
				calories: caloriesBurned,
				distance: 0,
				sourceBundleId: gfSteps.sourceBundleId,
				unit: gfSteps.unit,
				value: gfSteps.value,
			};
			let durationInSeconds = moment(GFObject.endDate.toString()).diff(moment(GFObject.startDate.toString()), "seconds");
			let workout = await convertStepsToWorkoutObject(gfSteps.startDate, durationInSeconds, gfSteps.value);
			// log.debug(`[getHKStepsv2] workout: ${JSON.stringify(workout)}`);
			workouts.push(workout);
		}
		// Move to the next 30-minute interval
		// currentStart = currentEnd.clone().add(1, "second");
		currentStart = currentEnd;
	}
	log.debug(`[getHKStepsv2] steps workouts: ${JSON.stringify(workouts)}`);
	// get the sum of the value property for objects in the workouts array
	// let totalSteps = workouts.reduce((a, b) => a + b.value, 0);
	// log.debug(`[getHKStepsv2] totalSteps: ${totalSteps}`);

	// If there are workouts, post them to the server
	if (workouts.length > 0) {
		await postWorkoutsToServer(workouts, false);
	}

	// Regardless, get the recent workouts from the server
	let serverResponse: WorkoutObject[] = await getRecentWorkouts(baseline);
	let ledger = await createLedgerFromWorkoutArray(serverResponse);
	log.debug("[getHKStepsv2] Server Response: ", JSON.stringify(serverResponse));
	await upsertWorkouts(serverResponse);
	return {
		activityLedger: ledger,
		workoutArray: serverResponse,
		number: 0, // just returning 0 because it's never used
	};
}

export async function processWorkoutsFromHealthKit(
	workouts: WorkoutObject[],
	startDate: Moment,
	endDate: Moment,
	createFakeUUIDs: boolean = false,
	baseline: boolean = false
): Promise<HKData> {
	// Setup
	if (!startDate) {
		startDate = moment().subtract(2, "week");
	}
	let uniqueHKActivities: WorkoutObject[] = [];
	let workoutArray: WorkoutObject[] = [];

	// Get HK workouts
	// Big difference between this and GF is GF converts to WorkoutObjects before returning
	let ActivityData: ActivityData[] | undefined = await getHKWorkouts(moment(startDate).format(), moment(endDate).format());

	// Convert HK workouts to Exercise Objects
	let user_id = await getUser_id();
	if (ActivityData) {
		for (let element of ActivityData) {
			let data = convertHKObjectToExerciseObject(element, user_id, createFakeUUIDs);
			workoutArray.push(data);
			// log.debug("Workout Data: ", { element });
		}
	}
	// // log.debug(`Number of HKWorkouts pulled: ${HKWorkouts?.length}`);
	// // log.debug(`HealthKitWorkoutObject: ${JSON.stringify(workouts)}`);

	// Process workouts
	if (workoutArray) {
		uniqueHKActivities = await filterForUniqueWorkouts(workouts, workoutArray);
	}
	log.debug(`[processWorkoutsFromHealthKit] UniqueHKActivities: ${JSON.stringify(uniqueHKActivities)}`);

	// If there are workouts, post them to the server
	if (uniqueHKActivities.length > 0) {
		await postWorkoutsToServer(uniqueHKActivities, false);
	}
	// Get recent workouts. No worry about duplicates because of upsert function
	let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(baseline); // not returning something here because recursion
	log.debug(`[processWorkoutsFromHealthKit] RecentWorkouts: `, recentWorkouts);
	let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
	return {
		activityLedger: activityLedger,
		workoutArray: workoutArray,
		number: uniqueHKActivities?.length || 0,
	};
	// }
	// return {
	// 	activityLedger: [],
	// 	workoutArray: [],
	// 	number: uniqueHKActivities?.length || 0,
	// };
}

export async function getHKWorkouts(startDate: string, endDate: string) {
	let startDateJSDate = new Date(startDate);
	let endDateJSDate = new Date(endDate);

	try {
		const queryOptions = {
			sampleName: SampleNames.WORKOUT_TYPE,
			startDate: startDateJSDate.toISOString(),
			endDate: endDateJSDate.toISOString(),
			limit: 0,
		};
		let workouts = await CapacitorHealthkit.queryHKitSampleType<ActivityData>(queryOptions);
		log.debug(`Workouts from new plugin: `, workouts);

		return workouts.resultData as ActivityData[];
	} catch (error) {
		console.error(error);

		return undefined;
	}
}

export interface HealthKitSteps {
	duration: number;
	unitName: string;
	uuid: string;
	device: {
		manufacturer: string;
		hardwareVersion: string;
		name: string;
		model: string;
		softwareVersion: string;
	};
	startDate: string;
	endDate: string;
	source: string;
	sourceBundleId: string;
	value: number;
}

export interface HealthKitStepsObject {
	resultData: HealthKitSteps[];
	countReturn: number;
}

function getTotalDeduplicatedSteps(data: HealthKitSteps[]): number {
	let sortedData = [...data].sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

	for (let i = 0; i < sortedData.length - 1;) {
		const current = sortedData[i];
		const next = sortedData[i + 1];

		// Convert the duration into milliseconds
		const currentDuration = current.duration * 24 * 60 * 60 * 1000;

		if (new Date(current.startDate).getTime() + currentDuration > new Date(next.startDate).getTime()) {
			// Overlap detected. Remove the record with the lower value.
			if (current.value >= next.value) {
				sortedData.splice(i + 1, 1); // remove next
			} else {
				sortedData.splice(i, 1); // remove current and keep the loop index unchanged
			}
		} else {
			i++; // move to the next record
		}
	}

	// Sum up the values of the deduplicated records to get the total step count
	return sortedData.reduce((sum, record) => sum + record.value, 0);
}

export function getDedupedSteps(data: HealthKitSteps[]): number {
	// First, filter out records where the source is "Oura".
	const filteredData = data.filter((record) => record.device !== null);

	// First, sort the data by startDate.
	const sortedData = [...filteredData].sort((a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime());

	let totalSteps = 0;
	let currentEndDate = new Date("1900-01-01T00:00:00Z");

	for (const record of sortedData) {
		if (new Date(record.startDate).getTime() > currentEndDate.getTime()) {
			totalSteps += record.value;
			currentEndDate = new Date(record.endDate);
		} else if (new Date(record.endDate).getTime() > currentEndDate.getTime()) {
			// This means we have some overlapping data. We can adjust the total step count proportionally.
			const overlappingTime = currentEndDate.getTime() - new Date(record.startDate).getTime();
			const totalDuration = new Date(record.endDate).getTime() - new Date(record.startDate).getTime();

			// Calculate the proportion of non-overlapping data.
			const proportion = (totalDuration - overlappingTime) / totalDuration;

			totalSteps += Math.round(record.value * proportion);
			currentEndDate = new Date(record.endDate);
		}
	}

	return totalSteps;
}

function getTotalSteps(data: HealthKitSteps[]): number {
	return data.reduce((sum, record) => sum + record.value, 0);
}

export interface HKData {
	activityLedger: ActivityLedger[];
	workoutArray: WorkoutObject[];
	number: number;
}

export async function getHKSteps(startDate?: string, endDate?: string): Promise<HKData> {
	// keeping date params for testing
	if (!startDate) startDate = await storageService.getItem(lastUpdatedStepsKey);
	if (!endDate) endDate = moment().format();
	if (!startDate) {
		startDate = moment().startOf("day").format();
	}
	log.debug(`[getHKSteps] dates for steps: `, startDate, endDate);
	let startDateJSDate = new Date(startDate);
	let endDateJSDate = new Date(endDate);
	let deviceInfo = await Device.getInfo();
	try {
		const queryOptions = {
			sampleName: SampleNames.STEP_COUNT,
			startDate: startDateJSDate.toISOString(),
			endDate: endDateJSDate.toISOString(),
			limit: 0,
		};
		let hkResponse;
		if (deviceInfo.platform === "web") {
			hkResponse = sampleSteps;
		} else {
			hkResponse = await CapacitorHealthkit.queryHKitSampleType<OtherData>(queryOptions);
		}
		let steps: HealthKitStepsObject = hkResponse as HealthKitStepsObject;
		log.debug(`[getHKSteps] Steps from new plugin: `, steps);
		let stepCount3 = getDedupedSteps(steps.resultData);
		log.debug(`[getHKSteps] Step count: `, stepCount3);
		if (stepCount3 > 0) {
			let response = await logStepsActivity(moment(startDate), moment(endDate), stepCount3);
			return response;
		} else {
			return {
				activityLedger: [],
				workoutArray: [],
				number: stepCount3,
			};
		}
	} catch (error) {
		console.error(error);
		return {
			activityLedger: [],
			workoutArray: [],
			number: 0,
		};
	}
}

export async function filterForUniqueHKWorkouts(existingData: WorkoutObject[] | null, workouts: HealthKitWorkoutObject[], startDate: Moment, endDate: Moment) {
	let tempWorkoutArray: HealthKitWorkoutObject[] = [];
	log.debug("[filterForUniqueHKWorkouts] workouts: ", workouts);
	log.debug("[filterForUniqueHKWorkouts] existingData: ", existingData);
	// log the date range we're checking for
	log.debug("[filterForUniqueHKWorkouts] startDate: ", moment(startDate).format());
	log.debug("[filterForUniqueHKWorkouts] endDate: ", endDate);
	workouts.forEach((element: HealthKitWorkoutObject, index: number) => {
		// if (moment(element.endDate) <= endDate && moment(element.endDate) >= startDate) { // why do we care about the date?
		// Check if a workout with the same UUID exists in existingData
		if (existingData && existingData.some((existingElement) => existingElement.UUID === element.uuid)) {
			// Do nothing (don't push) if a workout with the same UUID already exists
			// log.debug(`Skipping ${element}`);
		} else {
			// If no workout with the same UUID exists, then push the element into the array
			tempWorkoutArray.push(element);
		}
		// }
	});
	log.debug("[filterForUniqueHKWorkouts] tempWorkoutArray: ", tempWorkoutArray);

	return tempWorkoutArray;
}

export async function authorizeHealthKit(): Promise<boolean | undefined> {
	let deviceInfo = await getFullDeviceInfo();
	if (deviceInfo.platform === "web") {
		log.debug("On web, no HealthKit");
		return false;
	} else {
		let hkAvailable = await HealthKit.available();
		if (hkAvailable === true) {
			try {
				var options1 = {
					readTypes: ["HKQuantityTypeIdentifierStepCount", "HKWorkoutTypeIdentifier", "HKQuantityTypeIdentifierActiveEnergyBurned"],
				};
				await HealthKit.requestAuthorization(options1).then(async (_) => {
					log.debug("Authorization complete");
					await storageService.setItem(healthKitAuthorizedKey, "true");
					return true;
				});
				return true;
			} catch (error) {
				log.debug("error in HK available: ", error);
				throw new Error(
					"Error trying to connect your Healthkit: " +
					error +
					"<br/><br/>Please try again. If it keeps happening, select 'No thanks' and you can connect it later."
				);
			}
		} else {
			log.debug("error in HK available: ", hkAvailable);
			throw new Error("Error connecting to healthKit");
		}
	}
}

export function convertHKObjectToExerciseObject(HKObject: ActivityData, user_id: string, createFakeUUIDs: boolean = false) {
	let us;
	const baselineMultiple = 8;
	// let readableWorkoutName = convertHKActivityToReadable(HKObject.activityType);
	let caloriesPerMinute = Math.round(HKObject.totalEnergyBurned) / (HKObject.duration * 60);
	let intensityMultiple = Number((caloriesPerMinute / baselineMultiple).toFixed(1));
	if (intensityMultiple < 1) intensityMultiple = 1;
	let uuid = createFakeUUIDs ? uuidv4() : HKObject.uuid;
	let data: WorkoutObject = {
		user_id: user_id,
		UUID: uuid,
		dataType: "workout",
		activityType: HKObject.workoutActivityName,
		startDate: HKObject.startDate,
		endDate: HKObject.endDate,
		totalDistance: HKObject.totalDistance, // cant send these because of the stupid plugin
		totalDistanceUnit: "meters",
		value: HKObject.duration * 60 * 60,
		valueUnit: "sec",
		totalEnergyBurned: HKObject.totalEnergyBurned,
		reportId: HKObject.uuid,
		sourceName: HKObject.source,
		timeZone: "",
		weatherTemperature: "",
		humidity: "",
		caloriesPerMinute: caloriesPerMinute,
		intensityMultiple: intensityMultiple,
		intensityLevel: getIntensityLevel(intensityMultiple),
	};
	return data;
}

export const loadStepsData = async (startDateMoment = moment().startOf("day"), endDateMoment = moment()) => {
	let stepSum = 0;
	var stepOptions = {
		startDate: startDateMoment.toDate(),
		//startDate:  moment().subtract(15, 'minutes').valueOf,
		endDate: endDateMoment.toDate(),
		unit: "count",
		sampleType: "HKQuantityTypeIdentifierStepCount",
	};
	//log.debug("stepOptions: ",stepOptions);

	let data = await HealthKit.querySampleType(stepOptions);
	return data;
};

export const loadAppleExerciseTime = async () => {
	let exerciseTime = 0;
	let day = 0;
	let maxDays = 30;
	while (day < maxDays) {
		var exerciseOptions = {
			startDate: moment().startOf("day").subtract(day, "days").toDate(),
			endDate: moment().endOf("day").subtract(day, "days").toDate(),
			unit: "min",
			sampleType: "HKQuantityTypeIdentifierAppleExerciseTime",
		};
		//log.debug("stepOptions: ",stepOptions);

		await HealthKit.querySampleType(exerciseOptions)
			.then((data) => {
				exerciseTime = exerciseTime + data.reduce((a: any, b: any) => a + b.quantity, 0);
				log.debug("Sum of minutes: " + exerciseTime);
				//log.debug("Steps data: ",data);
				//log.debug("Sum of steps: ",stepsPast24hours);
			})
			.catch((err) => {
				log.debug("error steps: ", err);
			});
		day++;
	}
	return exerciseTime;
};

export const loadCalorieData = async (startDate = moment().startOf("day").toDate(), endDate = moment().toDate()) => {
	let calSum = 0;
	log.debug("StartDate set: " + startDate);
	log.debug("EndDate set: " + endDate);
	let calorieOptions = {
		startDate: startDate,
		endDate: endDate,
		unit: "kcal",
		limit: 100000,
		sampleType: "HKQuantityTypeIdentifierActiveEnergyBurned",
	};
	//log.debug("calorieOptions: ",calorieOptions);

	await HealthKit.querySampleType(calorieOptions).then(
		(data) => {
			//log.debug("Data object: "+JSON.stringify(data));
			calSum = data.reduce((a: any, b: any) => a + b.quantity, 0);
			log.debug("Sum of cals: " + calSum);
		},
		(err) => {
			log.debug("error query calories: ", err);
		}
	);
	return Math.round(calSum);
};

export async function getWeight(refresh = true) {
	let userWeight: any = "";
	if (refresh === false) {
		// if refresh = false return value from local storage
		userWeight = await storageService.getItem("userWeight");
		if (userWeight !== null && userWeight !== undefined && userWeight !== "") {
			return userWeight;
		}
	}
	var weightOptions = {
		unit: "kg",
	};
	log.debug("Getting weight");

	await HealthKit.readWeight(weightOptions).then(
		(data: any) => {
			userWeight = data;
			log.debug("Weight object from HK: " + userWeight);
			userWeight = userWeight["value"];
			log.debug("Weight value from HK: " + userWeight);
		},
		(err) => {
			userWeight = 87;
			log.debug("error in getting weight: ", err, ". Using base value of ", userWeight);
		}
	);
	return userWeight;
}

export const loadSumStepsData = async (startDate: any = null, endDate: any = null) => {
	var stepSum;
	log.debug("StartDate passed to loadSumStepsData: " + startDate);
	if (startDate === null) {
		startDate = moment().startOf("day").toDate();
	}
	if (endDate === null) {
		endDate = moment().toDate();
	}
	log.debug("StartDate set: " + startDate);
	let stepOptions = {
		startDate: startDate.toDate(),
		endDate: endDate.toDate(), // basically right now
		unit: "count",
		sampleType: "HKQuantityTypeIdentifierStepCount",
	};

	await HealthKit.sumQuantityType(stepOptions).then(
		(data) => {
			stepSum = data;
			log.debug("Sum of steps: " + stepSum);
		},
		(err) => {
			log.debug("error in step sum quantity: ", err);
		}
	);
	return stepSum;
};

export const pullStepsByDayLast8days = async () => {
	let daysChecked = 0;
	let daysToCheck = 8;
	let stepsArray = [];
	while (daysChecked < daysToCheck) {
		let startDate = moment().startOf("day").subtract(daysChecked, "days");
		let endDate = moment().endOf("day").subtract(daysChecked, "days");
		log.debug("Checking " + startDate.format());
		let tempStepsArray: any = [];
		tempStepsArray = await loadSumStepsData(startDate, endDate); // should just output to console
		//log.debug({ tempStepsArray });
		if (tempStepsArray > 0) {
			stepsArray.push({
				dataType: "dailySteps",
				value: tempStepsArray,
				startDate: startDate.format("YYYY-MM-DD"),
			});
		}
		daysChecked++;
	}
	log.debug({ stepsArray });
	return stepsArray;
};

export const pullStepsByDay = async (startMoment: any, endMoment: any) => {
	let daysChecked = 0;
	let daysToCheck = endMoment.diff(startMoment, "days");
	let stepSum = 0;
	while (daysChecked < daysToCheck) {
		let startDate = moment().startOf("day").subtract(daysChecked, "days");
		let endDate = moment().endOf("day").subtract(daysChecked, "days");
		log.debug("Checking " + startDate.format());
		let tempSteps: any = 0;
		tempSteps = await loadSumStepsData(startDate, endDate); // should just output to console
		log.debug("Daily Steps: " + tempSteps);
		stepSum = stepSum + tempSteps;
		log.debug("Total Steps: " + stepSum);
		daysChecked++;
	}
	return stepSum;
};

export const convertHKActivityToReadable = (hkname: any) => {
	switch (hkname) {
		case "HKWorkoutActivityTypeAmericanFootball":
			return "American Football";
		case "HKWorkoutActivityTypeArchery":
			return "Archery";
		case "HKWorkoutActivityTypeAustralianFootball":
			return "Australian Football";
		case "HKWorkoutActivityTypeBadminton":
			return "Badminton";
		case "HKWorkoutActivityTypeBaseball":
			return "Baseball";
		case "HKWorkoutActivityTypeBasketball":
			return "Basketball";
		case "HKWorkoutActivityTypeBowling":
			return "Bowling";
		case "HKWorkoutActivityTypeBoxing":
			return "Boxing";
		case "HKWorkoutActivityTypeClimbing":
			return "Climbing";
		case "HKWorkoutActivityTypeCrossTraining":
			return "Cross Training";
		case "HKWorkoutActivityTypeCurling":
			return "Curling";
		case "HKWorkoutActivityTypeCycling":
			return "Cycling";
		case "HKWorkoutActivityTypeDance":
			return "Dance";
		case "HKWorkoutActivityTypeDanceInspiredTraining":
			return "Dance Inspired Training";
		case "HKWorkoutActivityTypeElliptical":
			return "Elliptical";
		case "HKWorkoutActivityTypeEquestrianSports":
			return "Equestrian Sports";
		case "HKWorkoutActivityTypeFencing":
			return "Fencing";
		case "HKWorkoutActivityTypeFishing":
			return "Fishing";
		case "HKWorkoutActivityTypeFunctionalStrengthTraining":
			return "Functional Strength Training";
		case "HKWorkoutActivityTypeGolf":
			return "Golf";
		case "HKWorkoutActivityTypeGymnastics":
			return "Gymnastics";
		case "HKWorkoutActivityTypeHandball":
			return "Handball";
		case "HKWorkoutActivityTypeHiking":
			return "Hiking";
		case "HKWorkoutActivityTypeHockey":
			return "Hockey";
		case "HKWorkoutActivityTypeHunting":
			return "Hunting";
		case "HKWorkoutActivityTypeLacrosse":
			return "Lacrosse";
		case "HKWorkoutActivityTypeMartialArts":
			return "Martial Arts";
		case "HKWorkoutActivityTypeMindAndBody":
			return "Mind and Body";
		case "HKWorkoutActivityTypeMixedMetabolicCardioTraining":
			return "Mixed Metabolic Cardio Training";
		case "HKWorkoutActivityTypePaddleSports":
			return "Paddle Sports";
		case "HKWorkoutActivityTypePlay":
			return "Play";
		case "HKWorkoutActivityTypePreparationAndRecovery":
			return "Preparation and Recovery";
		case "HKWorkoutActivityTypeRacquetball":
			return "Racquetball";
		case "HKWorkoutActivityTypeRowing":
			return "Rowing";
		case "HKWorkoutActivityTypeRugby":
			return "Rugby";
		case "HKWorkoutActivityTypeRunning":
			return "Running";
		case "HKWorkoutActivityTypeSailing":
			return "Sailing";
		case "HKWorkoutActivityTypeSkatingSports":
			return "Skating Sports";
		case "HKWorkoutActivityTypeSnowSports":
			return "Snow Sports";
		case "HKWorkoutActivityTypeSoccer":
			return "Soccer";
		case "HKWorkoutActivityTypeSoftball":
			return "Softball";
		case "HKWorkoutActivityTypeSquash":
			return "Squash";
		case "HKWorkoutActivityTypeStairClimbing":
			return "Stair Climbing";
		case "HKWorkoutActivityTypeSurfingSports":
			return "Surfing Sports";
		case "HKWorkoutActivityTypeSwimming":
			return "Swimming";
		case "HKWorkoutActivityTypeTableTennis":
			return "Table Tennis";
		case "HKWorkoutActivityTypeTennis":
			return "Tennis";
		case "HKWorkoutActivityTypeTrackAndField":
			return "Track and Field";
		case "HKWorkoutActivityTypeTraditionalStrengthTraining":
			return "Traditional Strength Training";
		case "HKWorkoutActivityTypeVolleyball":
			return "Volleyball";
		case "HKWorkoutActivityTypeWalking":
			return "Walking";
		case "HKWorkoutActivityTypeWaterFitness":
			return "Water Fitness";
		case "HKWorkoutActivityTypeWaterPolo":
			return "Water Polo";
		case "HKWorkoutActivityTypeWaterSports":
			return "Water Sports";
		case "HKWorkoutActivityTypeWrestling":
			return "Wrestling";
		case "HKWorkoutActivityTypeYoga":
			return "Yoga";

		// iOS 10
		case "HKWorkoutActivityTypeBarre":
			return "Barre";
		case "HKWorkoutActivityTypeCoreTraining":
			return "Core Training";
		case "HKWorkoutActivityTypeCrossCountrySkiing":
			return "Cross Country Skiing";
		case "HKWorkoutActivityTypeDownhillSkiing":
			return "Downhill Skiing";
		case "HKWorkoutActivityTypeFlexibility":
			return "Flexibility";
		case "HKWorkoutActivityTypeHighIntensityIntervalTraining":
			return "High Intensity Interval Training";
		case "HKWorkoutActivityTypeJumpRope":
			return "Jump Rope";
		case "HKWorkoutActivityTypeKickboxing":
			return "Kickboxing";
		case "HKWorkoutActivityTypePilates":
			return "Pilates";
		case "HKWorkoutActivityTypeSnowboarding":
			return "Snowboarding";
		case "HKWorkoutActivityTypeStairs":
			return "Stairs";
		case "HKWorkoutActivityTypeStepTraining":
			return "Step Training";
		case "HKWorkoutActivityTypeWheelchairWalkPace":
			return "Wheelchair Walk Pace";
		case "HKWorkoutActivityTypeWheelchairRunPace":
			return "Wheelchair Run Pace";

		// iOS 11
		case "HKWorkoutActivityTypeTaiChi":
			return "Tai Chi";
		case "HKWorkoutActivityTypeMixedCardio":
			return "Mixed Cardio";
		case "HKWorkoutActivityTypeHandCycling":
			return "Hand Cycling";

		// iOS 13
		case "HKWorkoutActivityTypeDiscSports":
			return "Disc Sports";
		case "HKWorkoutActivityTypeFitnessGaming":
			return "Fitness Gaming";
		case "HKWorkoutActivityTypeCooldown":
			return "Cool Down";
		case "Cool Down":
			return "Cool Down";
		case "Cool down":
			return "Cool Down";
		case "High Intensity Interval Training":
			return "High Intensity Interval Training";
		case "Flexibility":
			return "Flexibility";

		// Catch-all
		default:
			return "Cool Down";
	}
};

export const getAttributeBreakdownByActivity = (activityName: any) => {
	let attributeBreakdown = {
		cardioRate: 0,
		strengthRate: 0,
		mobilityRate: 0,
		mindfulRate: 0,
	};

	switch (activityName) {
		// Pure cardio
		case 'Aerobic Workout':
		case 'American Football':
		case 'Archery':
		case 'Australian Football':
		case 'Badminton':
		case 'Baseball':
		case 'Basketball':
		case 'Bowling':
		case 'Boxing':
		case 'Cross Country Skiing':
		case 'Curling':
		case 'Dance':
		case 'Disc Sports':
		case 'Downhill Skiing':
		case 'Elliptical':
		case 'Equestrian Sports':
		case 'Fencing':
		case 'Fishing':
		case 'Fitness Gaming':
		case 'Golf':
		case 'HKWorkoutActivityTypeAmericanFootball':
		case 'HKWorkoutActivityTypeArchery':
		case 'HKWorkoutActivityTypeAustralianFootball':
		case 'HKWorkoutActivityTypeBadminton':
		case 'HKWorkoutActivityTypeBaseball':
		case 'HKWorkoutActivityTypeBasketball':
		case 'HKWorkoutActivityTypeBowling':
		case 'HKWorkoutActivityTypeBoxing':
		case 'HKWorkoutActivityTypeCrossCountrySkiing':
		case 'HKWorkoutActivityTypeCurling':
		case 'HKWorkoutActivityTypeDance':
		case 'HKWorkoutActivityTypeDiscSports':
		case 'HKWorkoutActivityTypeDownhillSkiing':
		case 'HKWorkoutActivityTypeElliptical':
		case 'HKWorkoutActivityTypeEquestrianSports':
		case 'HKWorkoutActivityTypeFencing':
		case 'HKWorkoutActivityTypeFishing':
		case 'HKWorkoutActivityTypeFitnessGaming':
		case 'HKWorkoutActivityTypeGolf':
		case 'HKWorkoutActivityTypeHandCycling':
		case 'HKWorkoutActivityTypeHandball':
		case 'HKWorkoutActivityTypeHockey':
		case 'HKWorkoutActivityTypeHunting':
		case 'HKWorkoutActivityTypeJumpRope':
		case 'HKWorkoutActivityTypeKickboxing':
		case 'HKWorkoutActivityTypeLacrosse':
		case 'HKWorkoutActivityTypeMixedCardio':
		case 'HKWorkoutActivityTypePlay':
		case 'HKWorkoutActivityTypeRacquetball':
		case 'HKWorkoutActivityTypeRugby':
		case 'HKWorkoutActivityTypeRunning':
		case 'HKWorkoutActivityTypeSailing':
		case 'HKWorkoutActivityTypeSkatingSports':
		case 'HKWorkoutActivityTypeSnowSports':
		case 'HKWorkoutActivityTypeSnowboarding':
		case 'HKWorkoutActivityTypeSoccer':
		case 'HKWorkoutActivityTypeSoftball':
		case 'HKWorkoutActivityTypeSquash':
		case 'HKWorkoutActivityTypeStepTraining':
		case 'HKWorkoutActivityTypeSurfingSports':
		case 'HKWorkoutActivityTypeSwimming':
		case 'HKWorkoutActivityTypeTableTennis':
		case 'HKWorkoutActivityTypeTennis':
		case 'HKWorkoutActivityTypeTrackAndField':
		case 'HKWorkoutActivityTypeVolleyball':
		case 'HKWorkoutActivityTypeWalking':
		case 'HKWorkoutActivityTypeWaterFitness':
		case 'HKWorkoutActivityTypeWaterPolo':
		case 'HKWorkoutActivityTypeWaterSports':
		case 'HKWorkoutActivityTypeWheelchairRunPace':
		case 'HKWorkoutActivityTypeWheelchairWalkPace':
		case 'HKWorkoutActivityTypeWrestling':
		case 'Hand Cycling':
		case 'Handball':
		case 'Hockey':
		case 'Household Chores':
		case 'Hunting':
		case 'JumpRope':
		case 'Kickboxing':
		case 'Lacrosse':
		case 'Mixed Cardio':
		case 'Play':
		case 'Racquetball':
		case 'Rugby':
		case 'Run':
		case 'Running':
		case 'Sailing':
		case 'Skating Sports':
		case 'Snow Sports':
		case 'Snowboarding':
		case 'Soccer':
		case 'Softball':
		case 'Sport':
		case 'Squash':
		case 'Step Training':
		case 'Surfing Sports':
		case 'Swim':
		case 'Swimming':
		case 'Table Tennis':
		case 'Tennis':
		case 'Track And Field':
		case 'Treadmill 20min 3.5 mph 5.5 mph (MyFitnessPal)':
		case 'Treadmill':
		case 'Volleyball':
		case 'Walk':
		case 'Walking':
		case 'Walking.fitness':
		case 'Water Fitness':
		case 'Water Polo':
		case 'Water Sports':
		case 'WaterFitness':
		case 'WaterPolo':
		case 'WaterSports':
		case 'Wheelchair Run Pace':
		case 'Wheelchair Walk Pace':
		case 'Workout':
		case 'Wrestling':
		case 'volleyball':
		case 'Running.jogging':
		case 'Football.soccer':
		case 'Steps':
		case 'General Cardio':
			attributeBreakdown = {
				cardioRate: 1,
				strengthRate: 0,
				mobilityRate: 0,
				mindfulRate: 0,
			};
			break;

		// Pure strength
		case 'Core training':
		case 'CoreTraining':
		case 'Functional Strength Training':
		case 'HKWorkoutActivityTypeCoreTraining':
		case 'HKWorkoutActivityTypeFunctionalStrengthTraining':
		case 'HKWorkoutActivityTypeTraditionalStrengthTraining':
		case 'Kettlebell_training':
		case 'Strength training':
		case 'Strength_training':
		case 'Traditional Strength Training':
		case 'Weightlifting':
		case 'Weights':
		case 'Circuit Training':
		case 'Circuit_training':
			attributeBreakdown = {
				cardioRate: 0,
				strengthRate: 1,
				mobilityRate: 0,
				mindfulRate: 0,
			};
			break;

		// Pure Mobility
		case 'Cool Down':
		case 'Cool down':
		case 'Cooldown':
		case 'Flexibility':
		case 'HKWorkoutActivityTypeCooldown':
		case 'HKWorkoutActivityTypeFlexibility':
		case 'HKWorkoutActivityTypePreparationAndRecovery':
		case 'Massage':
		case 'Other':
		case 'Preparation And Recovery':
		case 'Stretching':
		case 'other':
		case 'General Mobility':
			attributeBreakdown = {
				cardioRate: 0,
				strengthRate: 0,
				mobilityRate: 1,
				mindfulRate: 0,
			};
			break;

		// Pure Mindfulness
		case 'HKWorkoutActivityTypeTaiChi':
		case 'Meditation':
		case 'Journaling':
		case 'TaiChi':
		case 'General Mindfulness':
			attributeBreakdown = {
				cardioRate: 0,
				strengthRate: 0,
				mobilityRate: 0,
				mindfulRate: 1,
			};
			break;

		// Mixed Cardio & Strength, mainly cardio
		case 'Bike':
		case 'Bootcamp':
		case 'Climbing':
		case 'Cross Training':
		case 'Cycling':
		case 'biking':
		case 'Dance Inspired Training':
		case 'Gym Workout (MyFitnessPal)':
		case 'Gymnastics':
		case 'HKWorkoutActivityTypeClimbing':
		case 'HKWorkoutActivityTypeCrossTraining':
		case 'HKWorkoutActivityTypeCycling':
		case 'HKWorkoutActivityTypeDanceInspiredTraining':
		case 'HKWorkoutActivityTypeGymnastics':
		case 'HKWorkoutActivityTypeHighIntensityIntervalTraining':
		case 'HKWorkoutActivityTypeHiking':
		case 'HKWorkoutActivityTypeMixedMetabolicCardioTraining':
		case 'HKWorkoutActivityTypePaddleSports':
		case 'HKWorkoutActivityTypeRowing':
		case 'HKWorkoutActivityTypeStairClimbing':
		case 'HKWorkoutActivityTypeStairs':
		case 'HKWorkoutActivityTypeMartialArts':
		case 'Health club exercise, general':
		case 'High Intensity Interval Training':
		case 'Hike':
		case 'Hiking':
		case 'Indoor Climbing':
		case 'Interval Workout':
		case 'Interval_training.high_intensity':
		case 'Kayaking':
		case 'Mixed Metabolic Cardio Training':
		case 'Outdoor Bike':
		case 'Paddle Sports':
		case 'Rowing':
		case 'Spinning':
		case 'Stair Climbing':
		case 'Stairclimber':
		case 'Stairs':
		case 'Martial Arts':
		case 'Biking':
		case 'Active Job':
			attributeBreakdown = {
				cardioRate: 1,
				strengthRate: 0.5,
				mobilityRate: 0,
				mindfulRate: 0,
			};
			break;

		// Mixed Strength & Mobility
		case 'Barre':
		case 'Calisthenics':
		case 'HKWorkoutActivityTypeBarre':
		case 'HKWorkoutActivityTypePilates':
		case 'HKWorkoutActivityTypeYoga':
		case 'Pilates':
		case 'Yoga':
			attributeBreakdown = {
				cardioRate: 0,
				strengthRate: 0.75,
				mobilityRate: 0.75,
				mindfulRate: 0,
			};
			break;

		// Mixed Mindfulness & Strength
		case 'HKWorkoutActivityTypeMindAndBody':
		case 'Mind And Body':
			attributeBreakdown = {
				cardioRate: 0,
				strengthRate: 0.75,
				mobilityRate: 0,
				mindfulRate: 0.75,
			};
			break;
		// Mixed Cardio & Mindfulness
		case 'Sexual Activity':
			attributeBreakdown = {
				cardioRate: 0.5,
				strengthRate: 0,
				mobilityRate: 0,
				mindfulRate: 0.5,
			};
			break;

		// // Special case for steps
		// case 'Steps':
		// 	// Values are going to be dividied by 60, so first we need to multiply
		// 	// by 0.6 to get the steps / 100, then we multiply by 0.9 for cardio
		// 	// and 0.1 for mindful to apply some category weights
		// 	attributeBreakdown = {
		// 		cardioRate: 0.1,
		// 		strengthRate: 0,
		// 		mobilityRate: 0,
		// 		mindfulRate: 0,
		// 	};
		// 	break;
		case 'Initial Points':
			// Random values for the initial bonus points
			attributeBreakdown = {
				cardioRate: Math.random(),
				strengthRate: Math.random(),
				mobilityRate: Math.random(),
				mindfulRate: Math.random(),
			};
			break;
		default:
			// We don't recognize the activity, so we'll just give it some values
			attributeBreakdown = {
				cardioRate: 0.25,
				strengthRate: 0.25,
				mobilityRate: 0.25,
				mindfulRate: 0.25,
			};
			break;
	}

	return attributeBreakdown;
};
