import { IonContent, IonGrid, IonHeader, IonItem, IonPage, IonToolbar } from "@ionic/react";
import React, { useContext, useEffect } from "react";
import GlobalState from "../contexts/GlobalState";
import "../css/Missions.css";

export default function Missions() {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { currentMissions, getSavedMissions, saveMissions } = globalState;

	// useEffect(() => {
	// 	getSavedMissions();
	// }, []);

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ textAlign: "center" }}>Missions</IonToolbar>
			</IonHeader>
			<IonContent fullscreen>
				<div className="mission-holder">
					{currentMissions[0] ? (
						<>
							<h2>{currentMissions[0].missionDescription?.title}</h2>
							<br />
							<h3>{currentMissions[0].missionDescription?.description}</h3>
							<p>{currentMissions[0].activityType}</p>
							<p>{currentMissions[0].goalMinutes} mins</p>
							<p>{currentMissions[0].rewardMultiplier}x points awarded</p>
						</>
					) : (
						"Empty Mission"
					)}
				</div>
				<div className="mission-holder">
					{currentMissions[1] ? (
						<>
							<h2>{currentMissions[1].missionDescription?.title}</h2>
							<br />
							<h3>{currentMissions[1].missionDescription?.description}</h3>
							<p>{currentMissions[1].activityType}</p>
							<p>{currentMissions[1].goalMinutes} mins</p>
							<p>{currentMissions[1].rewardMultiplier}x points awarded</p>
						</>
					) : (
						"Empty Mission"
					)}
				</div>
				<div className="mission-holder">
					{currentMissions[2] ? (
						<>
							<h2>{currentMissions[2].missionDescription?.title}</h2>
							<br />
							<h3>{currentMissions[2].missionDescription?.description}</h3>
							<p>{currentMissions[2].activityType}</p>
							<p>{currentMissions[2].goalMinutes} mins</p>
							<p>{currentMissions[2].rewardMultiplier}x points awarded</p>
						</>
					) : (
						"Empty Mission"
					)}
				</div>
			</IonContent>
		</IonPage>
	);
}
