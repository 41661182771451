import { IonContent, IonPage } from '@ionic/react';
import HeaderLogo from '../../components/HeaderLogo';
import "../../css/Onboarding.css";

import CreateAvatarComponent from '../../Features/Profile/updateAvatar/CreateAvatarComponent';


const CreateAvatar = (props: { upload: boolean }) => {
    return (
        <IonPage>
            <HeaderLogo checkforUser={false} />
            <IonContent>
                <CreateAvatarComponent onboarding={true} upload={props.upload} />
            </IonContent>
        </IonPage>
    )
}

export default CreateAvatar;