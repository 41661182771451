import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react';
import { useContext, useEffect } from 'react';
import HeaderLogo from '../../components/HeaderLogo';
import "../../css/Onboarding.css";
import { StorageService } from "../../services/StorageService";
import GlobalState from '../../contexts/GlobalState';
import { initAnalyticsFreshInstall } from '../../services/AnalyticsService';
const storageService = new StorageService();


const Intro = () => {
    const nextPage = "/onboarding/intro1";
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile } = globalState;

    useEffect(() => {
        initAnalyticsFreshInstall();
        storageService.removeItem("onboarding");
    }, []);
    return (
        <IonPage>
            <HeaderLogo checkforUser={false} />
            <IonContent>
                <div className="onboarding-hero-image">
                    <img style={{ objectFit: isMobile === true ? "cover" : "contain" }} className="onboarding-hero-image" src="/assets/images/onboarding/maybe-intro-3.jpg" alt="" />
                </div>
                <div className="onboarding-text-area">
                    <h1 className="onboarding-title">Welcome to FitHero.ai</h1>
                    <p className="onboarding-text">
                        FitHero.ai transforms your fitness journey into an epic adventure, where battling AI-powered foes <span className='onboarding-emphasis'>keeps you committed</span> and makes your journey <span className='onboarding-emphasis'>more fun</span>!</p>
                </div>
            </IonContent>
            <IonFooter className="onboarding-footer">
                <IonButton routerLink={nextPage} className="onboarding-advance-button">
                    Continue
                </IonButton>
            </IonFooter>
        </IonPage>
    )
}

export default Intro