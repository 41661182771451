import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonHeader, IonLoading, IonRow, IonToolbar } from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Duels.css";
import { GroupVillains, VillainGroups } from "../../../models/duelsModels";
import { OnboardingObject } from "../../../models/userModel";
import { Villain } from "../../../models/villainModels";
import { createVillainGroupInstance, getAllVillainGroupInstances } from "../../../services/DuelsServics";
import { StorageService } from "../../../services/StorageService";
import { getVillain } from "../../../services/VillainServices";
import { logGenericEventWithObject } from "../../../services/AnalyticsService";
import log from "loglevel";
import { TourComponent } from "../../Tour/StepsComponent";
import { VillainGroupsProfileSteps } from "../../Tour/Steps";


const storageService = new StorageService();

const GroupProfile = (props: {
	villainGroup: VillainGroups
	setShowModal: React.Dispatch<React.SetStateAction<any>>;
	unlockable: boolean;
	reason: string;
}) => {
	// onboardingObject is only passed if the user is in the onboarding process
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);
	const [villainsInGroup, setVillainsInGroup] = useState<Villain[]>([]);
	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { setOnboardingCompleted, updateUserState } = globalState;

	useEffect(() => {
		async function loadVillainInfo() {
			// clear villains in group
			setVillainsInGroup([]);
			props.villainGroup.villains.forEach(async (villain: GroupVillains) => {
				await getVillain(villain.villainId)
					.then((villain: Villain) => {
						// log.debug("Placing villain in group: ", villain)
						setVillainsInGroup((villainsInGroup) => [...villainsInGroup, villain]);
					})
					.catch((error) => {
						log.debug(error);
					});
			})
		}
		loadVillainInfo()
		logGenericEventWithObject("view villain group profile", props.villainGroup);
	}, [])

	async function unlockVillainGroup() {
		setLoading(true);
		await createVillainGroupInstance(props.villainGroup.villainGroupId)
			.then(async () => {
				setLoading(false);
				// Update locally stored instances
				await getAllVillainGroupInstances()
				history.push(`/duels/duelprogression/${props.villainGroup.villainGroupId}`);
			})
			.catch((error) => {
				alert("Error starting unlocking villain group:\n " + error);
			});
		setLoading(false);
	}

	return (
		<>
			{props.villainGroup && (
				<>
					<IonHeader style={{ "--background": "var(--ion-background-color, #fff)" }}>
						<IonToolbar>
							<IonButtons slot="end">
								<IonButton strong={true} onClick={() => props.setShowModal({ show: false })}>
									X
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonLoading isOpen={loading} message={"Unlocking group..."} />
					<IonAlert
						isOpen={showConfirmation}
						header="Are your sure?"
						message="Unlock this Villain Group?"
						buttons={[
							{
								text: "No",
								role: "cancel",
								handler: () => {
									setShowConfirmation(false);
								},
							},
							{
								text: "Yes, let's go!",
								role: "ok",
								handler: () => {
									unlockVillainGroup();
								},
							},
						]}
					/>
					<IonContent>
						<h1 style={{ textAlign: "center", margin: "30px" }}>{props.villainGroup.groupName}</h1>
						<div className="villains-in-group">
							<IonRow>
								{villainsInGroup.map((villain, index) => (
									<>
										<IonCol size="4" key={index}> {/* Each column takes up 4 units (12/3) */}
											<div className="villian-profile-image">
												<img className="villain-avatar-smaller" src={villain?.imageURL} alt={villain?.name} />
											</div>
											<div className="villian-profile-name">{villain?.name}</div>
										</IonCol>
										{((index + 1) % 3 === 0) && <IonRow></IonRow>}
									</>
								))}
							</IonRow>
						</div>
						<div>
							<VillainGroupDescription villainGroup={props.villainGroup} />
						</div>
						<TourComponent modalShowing={false} coachMarkName="villainGroupProfile" steps={VillainGroupsProfileSteps} />
					</IonContent>
					{props.unlockable === true ? (
						<IonFooter className="start-duel-footer">
							<IonButton onClick={() => setShowConfirmation(true)} color={"tertiary"} expand="full" className="start-duel-button">
								Unlock {props.villainGroup.groupName}
							</IonButton>
						</IonFooter>
					) : (
						<IonFooter className="start-duel-footer">
							<div style={{ height: "75px", background: "darkred", fontSize: "24px", width: "100%", display: "flex" }}>
								<div style={{ width: "80%", margin: "auto" }}>
									{props.reason}
								</div>
							</div>
						</IonFooter>
					)}
				</>
			)}
		</>
	);
};

export default GroupProfile;

const VillainGroupDescription = (props: { villainGroup: VillainGroups }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	if (!props.villainGroup?.groupDescription) {
		return <></>;
	}
	const descriptionLines = props.villainGroup?.groupDescription.split("\\n");
	const displayLines = isExpanded ? descriptionLines : [descriptionLines[0]];

	return (
		<div className="villain-profile-description">
			{displayLines.map((line, index) => (
				<React.Fragment key={index}>
					{line}
					<br />
				</React.Fragment>
			))}
			{descriptionLines.length > 1 &&
				(!isExpanded ? (
					<div className="read-more" onClick={() => setIsExpanded(true)}>
						Read More{" "}
					</div>
				) : (
					<div className="read-more" onClick={() => setIsExpanded(false)}>
						Read Less 🔼
					</div>
				))}
		</div>
	);
};
