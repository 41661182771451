import {
	IonAlert,
	IonButton,
	IonButtons,
	IonContent,
	IonFooter,
	IonHeader,
	IonIcon,
	IonLabel,
	IonLoading,
	IonModal,
	IonPage,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar
} from "@ionic/react";
import { Steps } from "intro.js-react";
import { alertCircleOutline, analyticsOutline, barbellOutline, chatboxEllipsesOutline, chatboxOutline, fitnessOutline, informationCircle, newspaperOutline, statsChartOutline } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import UseSkillBar from "../../../Skills/components/UseSkillBar";
import GlobalState from "../../../contexts/GlobalState";
import { DuelLedger, DuelStatus, duelStatusKey } from "../../../models/duelsModels";
import { HeroObject } from "../../../models/userModel";
import { concedeDuel, fetchDuelsLedger, getAllDuels, getLatestOddsFromLedger } from "../../../services/DuelsServics";
import { shouldShowCoachmark, updateCoachMark } from "../../../services/HelperService";
import { StorageService } from "../../../services/StorageService";
import { DuelInstructions } from "../components/DuelInstructions";
import DuelStatusInfo from "../components/DuelStatusInfo";
import DuelsLedgerComponent from "../components/DuelUpdates";
import DuelsMessages from "../components/DuelsMessages";
import VillainProfileMidDuel from "../../Villians/components/VillainProfileMidDuel";
import { DuelHelper } from "../components/DuelHelper";
import { DuelsSkills } from "../components/DuelsSkills";
import { DuelsStatus } from "../components/DuelsStatus";
import { MiniDuelHeader } from "../components/MiniDuelHeader";
import CountdownTimerv2 from "../components/CountdownTimerv2";
const storageService = new StorageService();

const DuelsDetails = () => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { isMobile } = globalState;
	const { duelID, viewParam } = useParams<{ duelID: string, viewParam: string }>();
	const history = useHistory();
	const [duelStatus, setDuelStatus] = useState<DuelStatus>();
	const [duelLedger, setDuelLedger] = useState<DuelLedger[]>([]);
	const [loading, setLoading] = useState(false);
	const [hero, setHero] = useState<HeroObject>();
	const [view, setView] = useState<string | undefined>(viewParam);
	const [heroOdds, setHeroOdds] = useState<string>("");

	const [duelUpdatesCoachMarkEnabled, setDuelUpdatesCoachMarkEnabled] = useState<boolean>(false);
	const [showModal, setShowModal] = useState({ show: false, duelStatus: {} as DuelStatus });
	const [showDuelInstructions, setShowDuelInstructions] = useState<boolean>(false);


	useEffect((() => {
		setView(viewParam);
	}), [viewParam])

	useEffect(() => {
		loadDuelStatus();
		loadDuelLedger();
	}, [duelID]);

	async function loadDuelLedger() {
		let tempLedger = await fetchDuelsLedger(duelID);
		setDuelLedger(tempLedger);
	}

	async function loadDuelStatus() {
		setLoading(true);
		let ds = await getAllDuels(); // ensure we have the latest info. Maybe not always necessary
		await storageService.setObject(duelStatusKey, ds);
		let currentDuelStatus = ds.find((duelStatus: DuelStatus) => duelStatus.statusId === duelID)
		setDuelStatus(currentDuelStatus);
		setHero(await storageService.getObject("hero"))
		// calculateDuelSuccessPercent(props.villain).then((result) => {
		//     setLikelyWinner(result);
		// })
		if (currentDuelStatus) {
			let oddsObj = await getLatestOddsFromLedger(currentDuelStatus.statusId)
			// let oddsObj = await simulateBattleByStatus(currentDuelStatus, 1000)
			setHeroOdds(oddsObj.heroOdds);
		}
		setLoading(false);
		await shouldShowCoachmark("duelUpdates").then((result: boolean) => {
			setDuelUpdatesCoachMarkEnabled(result);
		})
	}

	function navigate(view: string) {
		history.push(`/dueldetails/${duelID}/${view}`);
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar style={{ textAlign: "center" }}>
					<IonButtons slot="start">
						<IonButton className="info-tooltip" onClick={() => setShowDuelInstructions(true)}><IonIcon icon={informationCircle} style={{ color: "white", fontSize: "30px" }} /></IonButton>
					</IonButtons>
					<IonTitle>Duel Progress</IonTitle>
					<IonButtons slot="end">
					</IonButtons>
				</IonToolbar>

			</IonHeader>
			{/* <div>
				<MiniDuelHeader duelStatus={duelStatus} />
			</div> */}
			{duelStatus && duelStatus.complete === false && (
				<>
					<div>
						<CountdownTimerv2 />
					</div>
				</>
			)}
			{duelStatus && duelStatus?.complete === true && (
				<>
					<div className="duel-completed-footer" style={{ width: "100%" }}>
						<p>This duel is complete</p>
					</div>
				</>
			)}
			<div style={{ minHeight: "60px", width: "95%", margin: "auto" }}>
				<IonSegment className="duels-segment" mode="ios" color="tertiary" value={view} onIonChange={(e) => setView(e.detail.value)}>
					<IonSegmentButton onClick={() => navigate("status")} className="squad-home-segement-button updatesButton" value="status" layout="icon-start">
						<IonIcon icon={statsChartOutline} size="small" />
						{/* <IonLabel className="duels-progress-segment-label">Status</IonLabel> */}
					</IonSegmentButton>
					<IonSegmentButton onClick={() => navigate("updates")} className="duels-progress-segment-button" value="updates" layout="icon-start">
						<IonIcon icon={newspaperOutline} size="small" />
						{/* <IonLabel className="duels-progress-segment-label">Updates</IonLabel> */}
					</IonSegmentButton>
					<IonSegmentButton onClick={() => navigate("chat")} className="duels-progress-segment-button chat-hint-tour" value="chat" layout="icon-start">
						<IonIcon icon={chatboxEllipsesOutline} size="small" />
						{/* <IonLabel className="duels-progress-segment-label">Chat</IonLabel> */}
					</IonSegmentButton>
					<IonSegmentButton onClick={() => navigate("skills")} className="squad-home-segement-button" value="skills" layout="icon-start" disabled={duelStatus?.complete}>
						<IonIcon icon={barbellOutline} size="small" />
						{/* <IonLabel className="duels-progress-segment-label">Skills</IonLabel> */}
					</IonSegmentButton>
				</IonSegment>
			</div>
			<IonModal
				isOpen={showModal.show}
				onWillDismiss={() => setShowModal({ show: false, duelStatus: {} as DuelStatus })}
				style={isMobile ? {} : { "--height": "90%" }}
			>
				<VillainProfileMidDuel duelStatus={showModal.duelStatus} setShowModal={setShowModal} />
			</IonModal>
			<IonModal
				isOpen={showDuelInstructions}
				onWillDismiss={() => setShowDuelInstructions(false)}
				style={isMobile ? {} : { "--height": "90%" }}
			>
				<DuelInstructions setShowModal={setShowDuelInstructions} />
			</IonModal>

			<IonLoading isOpen={loading} message={"Loading..."} backdropDismiss />

			{view === "status" && duelStatus && (
				<>
					<IonContent>
						{duelStatus && hero && setShowModal && (
							<DuelsStatus duelStatus={duelStatus} heroOdds={heroOdds} setShowModal={setShowModal} duelLedger={duelLedger} />
						)}
					</IonContent>
				</>
			)}

			{view === "updates" && (
				<>
					<IonContent>
						{duelStatus && (
							<DuelsLedgerComponent duelID={duelID} duelStatus={duelStatus} duelLedger={duelLedger} />
						)}
					</IonContent>
				</>
			)}
			{view === "chat" &&
				<>
					{duelStatus ?
						<div className="explanation-hint-chat" style={{ display: "contents" }}>
							<DuelsMessages setDuelUpdatesCoachMarkEnabled={setDuelUpdatesCoachMarkEnabled} duelStatus={duelStatus} />
						</div>
						: "No Duel Status"
					}
				</>
			}
			{view === "skills" &&
				<>
					{duelStatus && (
						<DuelsSkills duelStatus={duelStatus} />
					)}
				</>
			}
		</IonPage>
	);
};

export default DuelsDetails;
