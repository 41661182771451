import { IonAvatar, IonButton, IonCol, IonModal, IonRow } from '@ionic/react';
import log from 'loglevel';
import { useContext, useEffect, useState } from 'react';
import GlobalState from '../../../contexts/GlobalState';
import "../../../css/Sidekick.css";
import { SidekickTypeObject } from '../../../models/sideKickModels';
import { loadSidekicks } from '../../../services/SideKickService';
import { SidekickProfile } from '../components/SidekickProfile';



export const ManageSidekicks = (props: { setActiveSidekick: any }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { sidekick, sidekickLoadingFinished } = globalState;
    const [awakenButtonClicked, setAwakenButtonClicked] = useState<boolean>(false);
    const [sidekicks, setSidekicks] = useState<SidekickTypeObject[]>([]);
    const [sidekickCreated, setSidekickCreated] = useState<boolean>(false);
    const [showSidekickProfile, setShowSidekickProfile] = useState<any>({
        sidekickType: null,
        visible: false
    });

    useEffect(() => {
        loadSidekicks().then((sidekicks) => {
            // order sidekicks by sidekickTypeId
            sidekicks.sort((a, b) => a.sidekickTypeId - b.sidekickTypeId);
            log.debug("Sidekicks loaded: ", sidekicks);
            setSidekicks(sidekicks);
        }).catch((error) => {
            log.error(error);
            alert("Error: " + error);
        });
    }, [sidekickLoadingFinished]);

    async function wasSidekickCreated() {
        setShowSidekickProfile({
            sidekickType: null,
            visible: false
        })
        if (sidekickCreated === true) {
            props.setActiveSidekick(true)
        }
    }

    return (
        <>
            <IonModal isOpen={showSidekickProfile.visible} onDidDismiss={() => wasSidekickCreated()}>
                <SidekickProfile sidekickType={showSidekickProfile.sidekickType} setShowSidekickProfile={setShowSidekickProfile} setSidekickCreated={setSidekickCreated} />
            </IonModal>
            {(awakenButtonClicked === false && !sidekick) && (
                <div className="awaken-sidekick-div">
                    <IonButton className="sidekick-throbbing-button" style={{ color: "white" }} size="large" expand="block" onClick={() => setAwakenButtonClicked(true)}>Awaken Sidekick</IonButton>
                    <p style={{ width: "70%", margin: "auto", fontSize: "larger", "marginTop": "40px" }}>
                        Your sidekick is your companion on your journey.<br /><br />They can explain how FitHero.ai works, give you tips, and help you stay motivated.
                    </p>
                </div>
            )}
            {(awakenButtonClicked === true || sidekick) && sidekicks && (
                <SelectSidekickComponent sidekicks={sidekicks} setShowSidekickProfile={setShowSidekickProfile} />
            )}
        </>
    )
}

const SelectSidekickComponent = (props: { sidekicks: SidekickTypeObject[], setShowSidekickProfile: any }) => {
    return (
        <>
            <div className="awaken-sidekick-div">
                <h1>Select your Sidekick</h1>
                <div>
                    <IonRow style={{ textAlign: "-webkit-center" }}>
                        {props.sidekicks.map((sidekick, index) => (
                            // Use IonCol to create a column for each sidekick. The "size" prop determines the column width.
                            // Since Ionic uses a 12-column grid, setting size="6" creates 2 columns per row.
                            <IonCol size="6" key={sidekick.sidekickTypeId} className='sidekick-column' onClick={() => props.setShowSidekickProfile({
                                sidekickType: sidekick,
                                visible: true
                            })}>
                                <IonAvatar className='sidekick-avatar'>
                                    <img src={sidekick.baseImage} alt={sidekick.sidekickName} />
                                </IonAvatar>
                                {/* <IonButton
                                    style={{ color: "white" }}
                                    size="large"
                                    expand="block"
                                    onClick={() => console.log("Selected Sidekick: ", sidekick)}
                                >
                                    {sidekick.sidekickName}
                                </IonButton> */}
                            </IonCol>
                        ))}
                    </IonRow>
                </div>
            </div>
        </>


    )
}