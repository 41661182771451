/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { IonCol, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { userVillainTallyKey } from "../../../models/duelsModels";
import { TallyObject, getUserVillainTally, getUserVillainTallyByVillainId } from "../../../services/DuelsServics";
import { StorageService } from "../../../services/StorageService";
import { TallyMarkComponent } from "../../Duels/components/TallyMarkComponent";
let storageService = new StorageService();


export const VillainTallyComponent = (props: { villainId: string, boss: boolean }) => {
    const [tally, setTally] = useState<TallyObject | null>(null);

    useEffect(() => {
        createTallys();
    }, []);

    async function createTallys() {
        // let tallys: TallyObject[] | null = await storageService.getObject(userVillainTallyKey);
        // if (tallys === null) {
        //     tallys = await getUserVillainTally();
        // }
        // // look for the villain in the tallys
        // let villainTally = tallys.find((tally) => tally.villainId === props.villainId);
        let villainTally = await getUserVillainTallyByVillainId(props.villainId);
        // if the villain is found, set the tally
        if (villainTally) {
            // if the boss flag is true, set the boss flag in the tally
            if (props.boss) {
                villainTally.boss = true;
            }
            setTally(villainTally);
        }
        // if the villain is not found, create a new tally
        else {
            let emptyTally: TallyObject = {
                villainId: props.villainId,
                easy: false,
                medium: false,
                hard: false,
                boss: false,
                userId: "",
                heroName: "",
                villainName: ""
            }
            setTally(emptyTally);
        }
        // console.log(`villainTally for villainId ${props.villainId}: ${JSON.stringify(villainTally)}`)
    }

    return (
        <>
            {tally && props.boss !== true && (
                <IonRow>
                    <IonCol className="tally-mark-column">
                        <TallyMarkComponent completed={tally.easy} difficulty="easy" />
                    </IonCol>
                    <IonCol className="tally-mark-column">
                        <TallyMarkComponent completed={tally.medium} difficulty="medium" />
                    </IonCol>
                    <IonCol className="tally-mark-column">
                        <TallyMarkComponent completed={tally.hard} difficulty="hard" />
                    </IonCol>
                </IonRow>
            )}
            {tally && props.boss === true && (
                <IonRow>
                    <IonCol className="tally-mark-column">
                        <TallyMarkComponent completed={tally.boss} difficulty="boss" />
                    </IonCol>
                </IonRow>
            )}
        </>
    )
}
