import React, { useContext, useEffect, useState } from 'react'
import GlobalState from '../../../contexts/GlobalState';
import log from 'loglevel';
import { getAllOwnedSkills, getSkillsInUse } from '../../../services/SkillsService';
import { Skill, SkillInUse } from '../../../models/skills';
import { IonButton, IonCol, IonHeader, IonIcon, IonLabel, IonModal, IonRow, IonTitle, IonToolbar } from '@ionic/react';
import { GenericModal } from '../../../components/GenericModal';
import { closeOutline } from 'ionicons/icons';
import SkillIcon from '../../../Skills/components/SkillIcon';



export const SelectSkillsForDuelComponent = (props: { setSkillsToUse: any, skillsToUse: Skill[], setMaxSkillsUsable: any }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { ownedSkills, setOwnedSkills, heroObject } = globalState;

    interface SkillModalType {
        show: boolean;
        skills: Skill[] | null;
        slot: number | null;
    }
    const [ownedCardioSkills, setOwnedCardioSkills] = useState<Skill[]>([]);
    const [ownedStrengthSkills, setOwnedStrengthSkills] = useState<Skill[]>([]);
    const [showSelectSkillsModal, setShowSelectSkillsModal] = useState<SkillModalType>({ show: false, skills: null, slot: null });
    const [selectedSkills, setSelectedSkills] = useState<Skill[]>([]);
    const [showMinLevelModal, setShowMinLevelModal] = useState<{ show: boolean, minLevel: number }>({ show: false, minLevel: 0 });

    const slot0MinLevel = 2;
    const slot1MinLevel = 25;
    const slot2MinLevel = 75;
    const slot3MinLevel = 150;
    const slot4MinLevel = 2;
    const slot5MinLevel = 50;
    const slot6MinLevel = 100;
    const slot7MinLevel = 200;

    useEffect(() => {
        setupSkills();
    }, []);

    async function setupSkills() {
        let ownedSkillsLocal = ownedSkills;
        if (!ownedSkillsLocal || ownedSkillsLocal.length === 0) {
            ownedSkillsLocal = await getAllOwnedSkills();
            log.debug(`ownedSkillsLocal Skills: `, ownedSkillsLocal);
            setOwnedSkills(ownedSkillsLocal);
        }
        const cardioSkills = ownedSkillsLocal.filter(skill => skill.tree === 'cardio');
        const strengthSkills = ownedSkillsLocal.filter(skill => skill.tree === 'strength');
        setOwnedCardioSkills(cardioSkills);
        setOwnedStrengthSkills(strengthSkills);

        const maxSkillsUsable = heroObject?.overallLevel! >= slot7MinLevel ? 8 :
            heroObject?.overallLevel! >= slot3MinLevel ? 7 :
                heroObject?.overallLevel! >= slot6MinLevel ? 6 :
                    heroObject?.overallLevel! >= slot2MinLevel ? 5 :
                        heroObject?.overallLevel! >= slot5MinLevel ? 4 :
                            heroObject?.overallLevel! >= slot1MinLevel ? 3 :
                                heroObject?.overallLevel! >= slot0MinLevel ? 2 : 0;
        props.setMaxSkillsUsable(maxSkillsUsable);
    }

    async function setSkillSlot(skill: Skill, slot: number) {
        let selectedSkillsLocal = selectedSkills;
        selectedSkillsLocal[slot] = skill;
        setSelectedSkills(selectedSkillsLocal);

        let skillsToUseLocal = [...props.skillsToUse]
        skillsToUseLocal[slot] = skill;
        props.setSkillsToUse(skillsToUseLocal);



        setShowSelectSkillsModal({ show: false, skills: null, slot: null });
    }

    async function showSkillModal(skills: Skill[], slot: number, minLevel: number) {
        if (heroObject?.overallLevel! >= minLevel) {
            setShowSelectSkillsModal({ show: true, skills: skills, slot: slot });
        } else {
            setShowMinLevelModal({ show: true, minLevel: minLevel });
        }
    }


    return (
        <>
            <IonModal isOpen={showSelectSkillsModal.show} onDidDismiss={() => setShowSelectSkillsModal({ show: false, skills: null, slot: null })}>
                <SelectSkillsForDuelModalComponent selectedSkills={selectedSkills} showSelectSkillsModal={showSelectSkillsModal} setSkillSlot={setSkillSlot} setShowSelectSkillsModal={setShowSelectSkillsModal} />
            </IonModal>
            <IonModal className="villain-difficulty-modal" isOpen={showMinLevelModal.show} onDidDismiss={() => setShowMinLevelModal({ show: false, minLevel: 0 })}>
                <GenericModal title='Level Requirement' body={[`You must be level ${showMinLevelModal.minLevel} to unlock this skill slot`]} />
            </IonModal>
            <IonRow style={{ textAlign: "center", marginTop: "20px" }}>
                <div className='duel-skill-select-div' style={{ border: "2px solid var(--strength-color)" }}>
                    <div className='duel-skill-select-header'>Strength Skills</div>
                    <div style={{ display: "flex" }}>
                        <IonCol size='2' className='duel-skill-selection-box' onClick={() => showSkillModal(ownedStrengthSkills, 0, slot0MinLevel)}>
                            {selectedSkills[0] ?
                                <img src={selectedSkills[0].icon?.url} alt='skill' /> :
                                <div className='duel-skill-selection-box-text'>
                                    {<>
                                        {heroObject?.overallLevel! >= slot0MinLevel ? "select" : `unlock at level ${slot0MinLevel}`}
                                    </>}
                                </div>
                            }
                        </IonCol>
                        <IonCol size='2' className='duel-skill-selection-box' onClick={() => showSkillModal(ownedStrengthSkills, 1, slot1MinLevel)}>
                            {selectedSkills[1] ?
                                <img src={selectedSkills[1].icon?.url} alt='skill' /> :
                                <div className='duel-skill-selection-box-text'>
                                    {<>
                                        {heroObject?.overallLevel! >= slot1MinLevel ? "select" : `unlock at level ${slot1MinLevel}`}
                                    </>}
                                </div>
                            }
                        </IonCol>
                        <IonCol size='2' className='duel-skill-selection-box' onClick={() => showSkillModal(ownedStrengthSkills, 2, slot2MinLevel)}>
                            {selectedSkills[2] ?
                                <img src={selectedSkills[2].icon?.url} alt='skill' /> :
                                <div className='duel-skill-selection-box-text'>
                                    {<>
                                        {heroObject?.overallLevel! >= slot2MinLevel ? "select" : `unlock at level ${slot2MinLevel}`}
                                    </>}
                                </div>
                            }
                        </IonCol>
                        <IonCol size='2' className='duel-skill-selection-box' onClick={() => showSkillModal(ownedStrengthSkills, 3, slot3MinLevel)}>
                            {selectedSkills[3] ?
                                <img src={selectedSkills[3].icon?.url} alt='skill' /> :
                                <div className='duel-skill-selection-box-text'>
                                    {<>
                                        {heroObject?.overallLevel! >= slot3MinLevel ? "select" : `unlock at level ${slot3MinLevel}`}
                                    </>}
                                </div>
                            }
                        </IonCol>
                    </div>
                </div>
            </IonRow>
            <IonRow style={{ textAlign: "center", marginTop: "20px" }}>
                <div className='duel-skill-select-div' style={{ border: "2px solid var(--cardio-color)" }}>
                    <div className='duel-skill-select-header'>Cardio Skills</div>
                    <div style={{ display: "flex" }}>
                        <IonCol size='2' className='duel-skill-selection-box' onClick={() => showSkillModal(ownedCardioSkills, 4, slot4MinLevel)}>
                            {selectedSkills[4] ?
                                <img src={selectedSkills[4].icon?.url} alt='skill' /> :
                                <div className='duel-skill-selection-box-text'>
                                    {<>
                                        {heroObject?.overallLevel! >= slot4MinLevel ? "select" : `unlock at level ${slot4MinLevel}`}
                                    </>}
                                </div>
                            }
                        </IonCol>
                        <IonCol size='2' className='duel-skill-selection-box' onClick={() => showSkillModal(ownedCardioSkills, 5, slot5MinLevel)}>
                            {selectedSkills[5] ?
                                <img src={selectedSkills[5].icon?.url} alt='skill' /> :
                                <div className='duel-skill-selection-box-text'>
                                    {<>
                                        {heroObject?.overallLevel! >= slot5MinLevel ? "select" : `unlock at level ${slot5MinLevel}`}
                                    </>}
                                </div>
                            }
                        </IonCol>
                        <IonCol size='2' className='duel-skill-selection-box' onClick={() => showSkillModal(ownedCardioSkills, 6, slot6MinLevel)}>
                            {selectedSkills[6] ?
                                <img src={selectedSkills[6].icon?.url} alt='skill' /> :
                                <div className='duel-skill-selection-box-text'>
                                    {<>
                                        {heroObject?.overallLevel! >= slot6MinLevel ? "select" : `unlock at level ${slot6MinLevel}`}
                                    </>}
                                </div>
                            }
                        </IonCol>
                        <IonCol size='2' className='duel-skill-selection-box' onClick={() => showSkillModal(ownedCardioSkills, 7, slot7MinLevel)}>
                            {selectedSkills[7] ?
                                <img src={selectedSkills[7].icon?.url} alt='skill' /> :
                                <div className='duel-skill-selection-box-text'>
                                    {<>
                                        {heroObject?.overallLevel! >= slot7MinLevel ? "select" : `unlock at level ${slot7MinLevel}`}
                                    </>}
                                </div>
                            }
                        </IonCol>
                    </div>
                </div>
            </IonRow>
        </>
    )
}

const SelectSkillsForDuelModalComponent = (props: { selectedSkills: Skill[], showSelectSkillsModal: { show: boolean, skills: Skill[] | null, slot: number | null }, setSkillSlot: (skill: Skill, slot: number) => void, setShowSelectSkillsModal: any }) => {

    const [skillsInUse, setSkillsInUse] = useState<SkillInUse[]>([]);

    useEffect(() => {
        async function getSkillsOnCooldown() {
            let skillsInUseObject = await getSkillsInUse();
            // log.debug("Skills In Use Object: ", skillsInUseObject)
            // skillsInUseObject = [{
            //     ...skillsInUseObject[0],
            //     skillId: "aHSBh37cKC",
            //     cooldown: 5,
            // }]
            setSkillsInUse(skillsInUseObject);
        }
        getSkillsOnCooldown();
    }, [props.showSelectSkillsModal.show, props.showSelectSkillsModal.skills, props.showSelectSkillsModal.slot]);

    async function selectSkill(skill: Skill) {
        props.setSkillSlot(skill, props.showSelectSkillsModal.slot!);
        // props.setShowSelectSkillsModal({ show: false, skills: null, slot: null })
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Select Skill</IonTitle>
                    <IonButton color="light" slot="end" onClick={() => props.setShowSelectSkillsModal({ show: false, skills: null, slot: null })}>
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <div style={{ overflowY: "auto" }}>
                {props.showSelectSkillsModal.skills?.map((skill) => {
                    let skillOnCoolDown = skillsInUse.find(skillInUse => skillInUse.skillId === skill.objectId);
                    log.debug(`props.selectedSkills: `, props.selectedSkills)
                    let skillSelected = props.selectedSkills.find(selectedSkill => selectedSkill?.objectId === skill.objectId);
                    return (
                        <>

                            <IonRow
                                key={skill.objectId}
                                className={`skill-row`}
                                // onClick={() => setSkillModal({ show: true, skill: skill })}
                                onClick={skillSelected ? () => { } : () => selectSkill(skill)}
                            >
                                <div className={skillSelected ? "skill-selected-overlay" : ""}>
                                    <IonRow style={{ marginBottom: "20px" }}>
                                        <IonCol size="3" className="skill-slection-column-icon">
                                            <img src={skill.icon?.url} alt={skill.name} className="skill-icon" />
                                            {skillOnCoolDown && <div className="skill-icon-overlay"><SkillIcon cooldown={skillOnCoolDown.cooldown} maxCooldown={skill.cooldown} /></div>}
                                        </IonCol>
                                        <IonCol size='9'>
                                            <IonRow>
                                                <IonCol size="12">
                                                    <IonLabel>{skill.name}</IonLabel>
                                                </IonCol>
                                            </IonRow>
                                            <IonRow>
                                                <IonCol size="12" className='skill-selection-description-col'>
                                                    <IonLabel className='skill-selection-description'>{skill.description}</IonLabel>
                                                </IonCol>
                                            </IonRow>
                                        </IonCol>
                                    </IonRow>
                                </div>

                            </IonRow>
                        </>
                    )
                })}
            </div>
        </>
    )
}
