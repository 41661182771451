// Define the enum for event types
export enum EventType {
    Achievement = 'achievement',
    Workout = 'workout',
    Battle = 'battle',
    Mindfulness = 'mindfulness',
    Mobility = 'mobility'
}


// Define the type for a feed item
export type FeedItem = {
    id: string;
    timestamp: string; // ISO 8601 date string
    eventType: EventType;
    title: string;
    details: string;
    image?: string; // Optional URL to an additional image
};

export const sampleFeedItems: FeedItem[] = [
    {
        id: '1',
        timestamp: '2024-05-21T07:30:00Z',
        eventType: EventType.Achievement,
        title: 'Achievement Unlocked: 10K Steps in a Day',
        details: 'You reached a new milestone by walking 10,000 steps in a single day! Keep up the great work.',
        image: 'https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/f18f4a9fc3d70525a6100ae3f83b82b3_10k-steps-achievement.webp'
    },
    {
        id: '2',
        timestamp: '2024-05-21T06:00:00Z',
        eventType: EventType.Workout,
        title: 'Workout Completed: Morning CrossFit Session',
        details: '60 minutes of intense CrossFit training. Stats: 500 calories burned, 30 reps of squats, 20 push-ups, etc.',
        image: 'https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/63759cffd62fad02f485e98e861aeab6_morning%20crossfit%20session.webp'
    },
    {
        id: '3',
        timestamp: '2024-05-20T18:00:00Z',
        eventType: EventType.Battle,
        title: 'Battle Outcome: Victory over Timid Tom',
        details: 'You successfully defeated Timid Tom by completing a high-intensity interval training session.',
        image: 'https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/eca993216a2fc997ddb690bad243e866_victory-over-timid-tom.webp'
    }
];