import { IonButton, IonButtons, IonContent, IonHeader, IonToolbar } from '@ionic/react';
import React from 'react';
import "../../../css/Duels.css";

export const DuelInstructions = (props: { setShowModal: React.Dispatch<React.SetStateAction<any>>; }) => {
    return (
        <>
            <IonHeader style={{ "--background": "var(--ion-background-color, #fff)" }}>
                <IonToolbar>
                    <IonButtons slot="end">
                        <IonButton strong={true} onClick={() => props.setShowModal(false)}>
                            X
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div className="villain-profile-description">
                    <h2 className='duel-instuction-header'>How Duels Work</h2>
                    <img src="/assets/images/instructions-vs.jpg" alt='vs-image' style={{ width: "100%" }} />
                    <p>Duels are the main attraction of FitHero.ai!</p><p>When you start a duel, you are kicking off <span className='duel-instructions-emphasis'>head-to-head combat</span> against an AI villain to see who will triumph!</p>
                    <h2 className='duel-instuction-header'>Why Duels Work</h2>
                    <img src="/assets/images/defeated-villain.jpg" alt='defeated-image' style={{ width: "100%" }} />
                    <p>Duels help to give <span className='duel-instructions-emphasis'>motivation and purpose</span> to your healthy habits.</p><p>Because you to want to <span className='duel-instructions-emphasis'>beat the villain</span>, and because healthy activities help you to win, you'll be more likely to engage in them more frequently.</p>
                    <h2 className='duel-instuction-header'>Structure</h2>
                    <p>Every hour, the villain will attempt to "hit" you, with a <span className='duel-instructions-emphasis'>random chance</span> of that happening.</p>
                    <p>Higher <span className='duel-instructions-emphasis'>accuracy</span> (🎯) = better chance <span className='duel-instructions-emphasis'>the villain hits</span></p>
                    <p>Higher <span className='duel-instructions-emphasis'>dodge</span> (💨) = better chance to <span className='duel-instructions-emphasis'>you dodge a hit</span></p>
                    <p>You can <span className='duel-instructions-emphasis'>only hit</span> the villain if YOU <span className='duel-instructions-emphasis'>are active.</span></p>
                    <p>If there's a hit, damage will then be randomly calculated.</p>
                    <p>Higher <span className='duel-instructions-emphasis'>damage</span> (💥) = the <span className='duel-instructions-emphasis'>more damage</span> done</p>
                    <p>Damage will be deducted from your health or the villains health (❤️)</p>
                    <p style={{ fontSize: "18px", textAlign: "center" }}><span className='duel-instructions-emphasis'>The first one to 0 health loses</span></p>
                    <h2 className='duel-instuction-header'>Effects of your activities</h2>
                    <p>Every activity you do will have a different effect on your duel.</p>
                    <p>Strength activities will increase your damage (💥)</p>
                    <p>Cardio activities will increase your health (❤️)</p>
                    <p>Mobility activities will increase your dodge (💨)</p>
                    <h2 className='duel-instuction-header'>How to win</h2>
                    <p>Winning a duel is a combination of luck and strategy.</p>
                    <p>But the more activities you do, the better your odds of winning!</p>
                    <h2 className='duel-instuction-header'>Skills</h2>
                    <img src="/assets/images/skills.png" alt='skills-image' />
                    <p>Skills are special abilities that you can use to help you win a duel.</p>
                    <p>Skills are unlocked by completing activities and leveling up.</p>
                    <p>Each skill has a cooldown period, which is the amount of time you have to wait before you can use the skill again.</p>
                    <p>The cooldown period for each skill is different.</p>
                    <p>After each duel your skills will be reset and you can use them again.</p>
                    <h1 style={{ textAlign: "center" }}>Good luck!</h1>
                </div>
            </IonContent>
        </>

    );
}
