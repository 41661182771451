import { Skill } from "./skills";
import { HeroObject } from "./userModel";

export const duelStatusKey = "duelsStatusList";
export const duelLedgerKey = "duelsLedger";
export const duelStatusLastUpdatedKey = "duelsStatusLastUpdated";
export const duelLedgerLastUpdatedKey = "duelsLedgerLastUpdated";
export const duelDisplayMessagesKey = "duelsDisplayMessages";
export const duelAPIMessagesKey = "duelsAPIMessages";
export const villainGroupInstancesKey = "villainGroupInstances";
export const villainGroupsKey = "villainGroups";
export const activityMatrixKey = "activityMatrix";
export const duelPointMatrixKey = "duelPointMatrix";
export const userVillainTallyKey = "userVillainTally";

export type LeaderboardObjects = {
	position: number;
	hero: HeroObject;
}

export type DuelLedger = {
	_id: string;
	ledgerId: string;
	createdAt: string;
	updatedAt: string;
	duel_id: string;
	updateString: string;
	heroHitChance: number;
	heroSwing: number;
	villainHitChance: number;
	villainSwing: number;
	heroHit: boolean;
	villainHit: boolean;
	heroDamageDealt: number;
	villainDamageDealt: number;
	heroOdds?: number;
	currentHeroHealth?: number;
	currentVillainHealth?: number;
	commentaryTitle?: string;
	commentaryMessage?: string;
	activitiesRecorded?: string;
};

export type DuelStatus = {
	statusId: string;
	createdAt: string;
	updatedAt: string;
	villainID: string;
	user_id: string;
	heroName: string;
	villainName: string;
	villainImageURL: string;
	heroToHit: number;
	heroDamage: number;
	heroDodge: number;
	heroHealth: number;
	villainToHit: number;
	villainDamage: number;
	villainDodge: number;
	villainHealth: number;
	complete: boolean;
	winner: string;
	villainGeneralPrompt: string;
	villainCombatPrompt: string;
	heroMaxHealth?: number;
	villainMaxHealth?: number;
	villainStrength?: number;
	villainCardio?: number;
	villainMobility?: number;
	villainMindfulness?: number;
	villainLevel?: number;
	celebrated?: boolean;
	villainGroupInstanceId?: string;
	difficulty?: string;	// This is the difficulty of the villain
	skillsEnabled?: Skill[] | undefined;
};

export type VillainGroupInstanceNodeHistory = {
	duelId: string;
	complete: boolean;
	startDate: string;
};

export type VillainGroupInstanceNode = {
	nodeId: number;
	villainId: string;
	villainName?: string;
	villainImage?: string;
	difficulty: string;
	position: {
		row: number;
		col: number;
	};
	canPass: boolean;
	history?: VillainGroupInstanceNodeHistory[];
	villainStats: {
		toHit: number;
		damage: number;
		dodge: number;
		health: number;
		level: number;
		strength: number;
		cardio: number;
		mobility: number;
		mindfulness: number;
	};
	currentlyDuelling?: boolean;
}

export enum VillainDifficulty {
	Easy = "easy",
	Medium = "medium",
	Hard = "hard",
	Boss = "boss",
}

export type GroupVillains = {
	nodeId: number;
	villainId: string;
	difficulty: string;
	position: {
		row: number;
		col: number;
	};
}

export type VillainGroupInstance = {
	objectId: string;
	createdAt: string;
	updatedAt: string;
	villains: VillainGroupInstanceNode[],
	userId: string;
	groupName: string;
	paths: [{
		startNodeId: number;
		endNodeId: number;
	}],
	groupId: number;
	groupImage: string;
	villainGroupInstanceId: string;
	groupDescription: string;
	completed: boolean;
	villainGroupId: string;
}

export type VillainGroups = {
	villains: GroupVillains[];
	paths?: [{
		startNodeId: number;
		endNodeId: number;
	}],
	groupId: number;
	groupName: string;
	groupImage: string;
	groupDescription: string;
	active: boolean;
	createdAt: string;
	updatedAt: string;
	villainGroupId: string;
	objectId: string;
	specialty: boolean;
	startDate: string;
	endDate: string;
}

export const DefaultVillainGroupPath = [
	{
		startNodeId: 1,
		endNodeId: 2,
	}, {
		startNodeId: 2,
		endNodeId: 3,
	}, {
		startNodeId: 3,
		endNodeId: 4,
	}, {
		startNodeId: 4,
		endNodeId: 5,
	}, {
		startNodeId: 5,
		endNodeId: 6,
	}, {
		startNodeId: 6,
		endNodeId: 7,
	}, {
		startNodeId: 7,
		endNodeId: 8,
	},

]

export const duelInfoExplanationSteps = [
	{
		element: '.report-tooltip',
		title: "View the Box Score",
		intro: "View the report to see details of your duel.",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.concede-tooltip',
		title: "Concede",
		intro: "If you would like to end your duel early, you can concede.",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.info-tooltip',
		title: "Want More Info?",
		intro: "Want more info on how duels work? Tap here.",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
]

export const duelInfoExplanationStepsOnboarding = [
	{
		element: '.explanation-hint-1',
		title: "Duel explanation",
		intro: "<span class='onboarding-emphasis'>Every hour,</span> this villain will attack you.",
		position: 'top',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.explanation-hint-1',
		title: "Duel explanation",
		intro: "You defeat the villain by <span class='onboarding-emphasis'>completing activities.<span>",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.explanation-hint-health',
		title: "Duel explanation",
		intro: "This ❤️ is your health",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.explanation-hint-villain-health',
		title: "Duel explanation",
		intro: "This ❤️ is the villains health.",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.explanation-hint-1',
		title: "Duel explanation",
		intro: "If your ❤️ reduced to 0 before the villains ❤️ is 0 <span class='onboarding-emphasis'>you will lose!</span>",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.explanation-hint-1',
		title: "Duel explanation",
		intro: "You don't want to lose.",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.explanation-hint-damage',
		title: "Duel explanation",
		intro: "💥 is your damage. <br/><br/>Every time <span class='onboarding-emphasis'>you do an activity</span>, you will do this much damage to the villain.",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.explanation-hint-dodge',
		title: "Duel explanation",
		intro: "💨 is your dodge. <br/><br/>It influences how likely you are to <span class='onboarding-emphasis'>dodge the villain's attack.</span>",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	},
	{
		element: '.explanation-hint-accuracy',
		title: "Duel explanation",
		intro: "🎯 is the villains accuracy. <br/><br/>It influences how likely the villain is <span class='onboarding-emphasis'>to hit you.</span>",
		position: 'bottom',
		tooltipClass: 'myTooltipClass',
		highlightClass: 'myHighlightClass',
	}
]