import { IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonIcon, IonPicker, IonRow, IonSkeletonText, IonToast } from '@ionic/react';
import ProgressBar from "@ramonak/react-progress-bar";
import { checkmarkDoneCircle, radioButtonOffOutline } from 'ionicons/icons';
import log from 'loglevel';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import GlobalState from '../../../contexts/GlobalState';
import "../../../css/Goals.css";
import { updateGoal } from '../../../services/GoalsService';
import { updateCoachMark } from '../../../services/HelperService';

export const CurrentGoal = () => {
    const history = useHistory();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { currentGoal } = globalState;
    const [dayOfTheWeek, setDayOfTheWeek] = useState<number>(0)
    const [strengthGoal, setStrengthGoal] = useState<number>()
    const [cardioGoal, setCardioGoal] = useState<number>()
    const [mobilityGoal, setMobilityGoal] = useState<number>()
    const [mindfulnessGoal, setMindfulnessGoal] = useState<number>()
    const [openToast, setOpenToast] = useState<any>({ visible: false, message: "" });
    const [goalsAccomplished, setGoalsAccomplished] = useState<any>({ strength: false, cardio: false, mobility: false, mindfulness: false });
    const [almostGoals, setAlmostGoals] = useState<any>({ strength: false, cardio: false, mobility: false, mindfulness: false });
    const [testSkeleton, setTestSkeleton] = useState<boolean>(true);


    useEffect(() => {
        setDayOfTheWeek(moment().day()); // 0 is Sunday, 6 is Saturday
        // setDayOfTheWeek(1); // 0 is Sunday, 6 is Saturday
    }, [])

    useEffect(() => {
        if (currentGoal) {
            setStrengthGoal(currentGoal.strengthGoalPoints)
            setCardioGoal(currentGoal.cardioGoalPoints)
            setMobilityGoal(currentGoal.mobilityGoalPoints)
            setMindfulnessGoal(currentGoal.mindfulnessGoalPoints);
            let tempGoalsAccomplished = {
                strength: (currentGoal.strengthGoalPoints > 0 && currentGoal.strengthPoints > 0) ? currentGoal.strengthPoints >= currentGoal.strengthGoalPoints : false,
                cardio: (currentGoal.cardioGoalPoints > 0 && currentGoal.cardioPoints > 0) ? currentGoal.cardioPoints >= currentGoal.cardioGoalPoints : false,
                mobility: (currentGoal.mobilityGoalPoints > 0 && currentGoal.mobilityPoints > 0) ? currentGoal.mobilityPoints >= currentGoal.mobilityGoalPoints : false,
                mindfulness: (currentGoal.mindfulnessGoalPoints > 0 && currentGoal.mindfulnessPoints > 0) ? currentGoal.mindfulnessPoints >= currentGoal.mindfulnessGoalPoints : false
            };
            setGoalsAccomplished(tempGoalsAccomplished);
            setAlmostGoals({
                strength: (currentGoal.strengthPoints / currentGoal.strengthGoalPoints >= 0.7 && !tempGoalsAccomplished.strength) ? (currentGoal.strengthGoalPoints - currentGoal.strengthPoints) : false,
                cardio: (currentGoal.cardioPoints / currentGoal.cardioGoalPoints >= 0.7 && !tempGoalsAccomplished.cardio) ? (currentGoal.cardioGoalPoints - currentGoal.cardioPoints) : false,
                mobility: (currentGoal.mobilityPoints / currentGoal.mobilityGoalPoints >= 0.7 && !tempGoalsAccomplished.mobility) ? (currentGoal.mobilityGoalPoints - currentGoal.mobilityPoints) : false,
                mindfulness: (currentGoal.mindfulnessPoints / currentGoal.mindfulnessGoalPoints >= 0.7 && !tempGoalsAccomplished.mindfulness) ? (currentGoal.mindfulnessGoalPoints - currentGoal.mindfulnessPoints) : false
            });

        }
    }, [currentGoal])



    function setGoal(goalType: string, value: any) {
        let goal = currentGoal!;
        switch (goalType) {
            case "strength":
                setStrengthGoal(value)
                goal.strengthGoalPoints = value;
                break;
            case "cardio":
                setCardioGoal(value)
                goal.cardioGoalPoints = value;
                break;
            case "mobility":
                setMobilityGoal(value)
                goal.mobilityGoalPoints = value;
                break;
            case "mindfulness":
                setMindfulnessGoal(value)
                goal.mindfulnessGoalPoints = value;
                break;
        }
        updateGoal(goal).then(() => {
            setOpenToast({ visible: true, message: `Your ${goalType} goal for the week has been set` });
        }).catch((error) => {
            setOpenToast({ visible: true, message: `Error updating your ${goalType} goal` });
        })
    }

    // note: This whole component should not render unless goal exists
    return (
        <>
            {currentGoal ? (
                <>
                    <IonToast
                        isOpen={openToast.visible}
                        message={openToast.message}
                        onDidDismiss={() => setOpenToast({ visible: false, message: "" })}
                        duration={3000}
                    ></IonToast>
                    <IonCard className='current-goal-hint goal-background'>
                        {/* <IonCardSubtitle className='goal-accomp-1' style={{ textAlign: "center" }}>Completing your goal will give {heroObject?.heroName} a boost</IonCardSubtitle> */}
                        <IonCardTitle style={{ textAlign: "center", color: "black", marginTop: "10px", fontSize: "20px" }}>{moment(currentGoal?.startDate).format("MM/DD")} - {moment(currentGoal?.endDate).format("MM/DD")}</IonCardTitle>
                        <IonCardContent style={{ paddingInlineStart: "10px", paddingInlineEnd: "10px" }}>
                            <IonRow className='goal-bar-container'>
                                <IonCol size="1">
                                    💪
                                </IonCol>
                                <IonCol size="8.5" className='goal-progress-bar-container'>
                                    <ProgressBar
                                        className='goal-progress-bar'
                                        completed={`${currentGoal?.strengthPoints}`}
                                        maxCompleted={currentGoal?.strengthGoalPoints}
                                        bgColor='var(--strength-color)' />
                                </IonCol>
                                {dayOfTheWeek === 1 ? (
                                    <>
                                        <IonCol size="2" className='goal-cell'>
                                            <div id='open-picker-str'>
                                                <IonButton className='goal-set-button'>{strengthGoal} ▼</IonButton>
                                            </div>
                                            <ValuePicker trigger='open-picker-str' setValue={(detail) => setGoal("strength", detail)} />
                                        </IonCol>
                                    </>
                                ) : (
                                    <>
                                        <IonCol size="1" className='goal-cell'>
                                            {currentGoal?.strengthGoalPoints}
                                        </IonCol>
                                        <IonCol size="1" className='goal-almost-accomplished'>
                                            {almostGoals.strength && (
                                                <><span style={{ fontSize: "10px" }}>❗❗</span></>
                                            )}
                                            {goalsAccomplished.strength && "🏆"}
                                        </IonCol>
                                    </>
                                )}
                            </IonRow>
                            <IonRow className='goal-bar-container'>
                                <IonCol size="1">
                                    💚
                                </IonCol>
                                <IonCol size="8.5" className='goal-progress-bar-container'>
                                    <ProgressBar
                                        className='goal-progress-bar'
                                        completed={`${currentGoal?.cardioPoints}`}
                                        maxCompleted={currentGoal?.cardioGoalPoints}
                                        bgColor='var(--cardio-color)' />
                                </IonCol>
                                {dayOfTheWeek === 1 ? (
                                    <>
                                        <IonCol size="2" className='goal-cell'>
                                            <div id='open-picker-card'>
                                                <IonButton className='goal-set-button'>{cardioGoal} ▼</IonButton>
                                            </div>
                                            <ValuePicker trigger='open-picker-card' setValue={(detail) => setGoal("cardio", detail)} />
                                        </IonCol>

                                    </>
                                ) : (
                                    <>
                                        <IonCol size="1" className='goal-cell'>
                                            {currentGoal?.cardioGoalPoints}
                                        </IonCol>
                                        <IonCol size="1" className='goal-almost-accomplished'>
                                            {almostGoals.cardio && (
                                                <><span style={{ fontSize: "10px" }}>❗❗</span></>
                                            )}
                                            {goalsAccomplished.cardio && "🏆"}
                                        </IonCol>
                                    </>
                                )}
                            </IonRow>
                            <IonRow className='goal-bar-container'>
                                <IonCol size="1">
                                    🧘‍♀️
                                </IonCol>
                                <IonCol size="8.5" className='goal-progress-bar-container'>
                                    <ProgressBar
                                        className='goal-progress-bar'
                                        completed={`${currentGoal?.mobilityPoints}`}
                                        maxCompleted={currentGoal?.mobilityGoalPoints}
                                        bgColor='var(--mobility-color)' />
                                </IonCol>
                                {dayOfTheWeek === 1 ? (
                                    <>
                                        <IonCol size="2" className='goal-cell'>
                                            <div id='open-picker-mob'>
                                                <IonButton className='goal-set-button'>{mobilityGoal} ▼</IonButton>
                                            </div>
                                            <ValuePicker trigger='open-picker-mob' setValue={(detail) => setGoal("mobility", detail)} />
                                        </IonCol>

                                    </>
                                ) : (
                                    <>
                                        <IonCol size="1" className='goal-cell'>
                                            {currentGoal?.mobilityGoalPoints}
                                        </IonCol>
                                        <IonCol size="1" className='goal-almost-accomplished'>
                                            {almostGoals.mobility && (
                                                <><span style={{ fontSize: "10px" }}>❗❗</span></>
                                            )}
                                            {goalsAccomplished.mobility && "🏆"}
                                        </IonCol>
                                    </>
                                )}
                            </IonRow>
                            <IonRow className='goal-bar-container'>
                                <IonCol size="1">
                                    🙏
                                </IonCol>
                                <IonCol size="8.5" className='goal-progress-bar-container'>
                                    <ProgressBar
                                        className='goal-progress-bar'
                                        completed={`${currentGoal?.mindfulnessPoints}`}
                                        maxCompleted={currentGoal?.mindfulnessGoalPoints}
                                        bgColor='var(--mindfulness-color)' />
                                </IonCol>
                                {dayOfTheWeek === 1 ? (
                                    <>
                                        <IonCol size="2" className='goal-cell'>
                                            <div id='open-picker-mind'>
                                                <IonButton className='goal-set-button'>{mindfulnessGoal} ▼</IonButton>
                                            </div>
                                            <ValuePicker trigger='open-picker-mind' setValue={(detail) => setGoal("mindfulness", detail)} />
                                        </IonCol>
                                    </>
                                ) : (
                                    <>
                                        <IonCol size="1" className='goal-cell'>
                                            {currentGoal?.mindfulnessGoalPoints}
                                        </IonCol>
                                        <IonCol size="1" className='goal-almost-accomplished'>
                                            {almostGoals.mindfulness && (
                                                <><span style={{ fontSize: "10px" }}>❗❗</span></>
                                            )}
                                            {goalsAccomplished.mindfulness && "🏆"}
                                        </IonCol>
                                    </>
                                )}
                            </IonRow>
                        </IonCardContent>
                        <div className='goal-bonus-div'>
                            <IonIcon icon={goalsAccomplished.strength === true ? checkmarkDoneCircle : radioButtonOffOutline} /> +20% damage for next week<br />
                            <IonIcon icon={goalsAccomplished.cardio === true ? checkmarkDoneCircle : radioButtonOffOutline} /> +20% health for next week<br />
                            <IonIcon icon={goalsAccomplished.mobility === true ? checkmarkDoneCircle : radioButtonOffOutline} /> +20% dodge for next week<br />
                            <IonIcon icon={goalsAccomplished.mindfulness === true ? checkmarkDoneCircle : radioButtonOffOutline} /> +20% accuracy for next week
                        </div>
                    </IonCard>
                </>
            ) : (
                <>
                    <IonSkeletonText animated style={{ width: '60%', height: '20px', margin: "10px auto" }} />
                </>
            )}
        </>
    )
}

const ValuePicker = (props: { trigger: string, setValue: React.Dispatch<React.SetStateAction<number>> }) => {
    return (
        <IonPicker
            // style={{ top: "-200px", position: "relative" }}
            trigger={props.trigger}
            columns={[
                {
                    name: 'points',
                    options: [
                        ...Array.from({ length: 400 }, (v, k) => ({
                            text: `${k * 10}`,
                            value: `${k * 10}`,
                        }))
                    ]
                },
            ]}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Confirm',
                    handler: (value: any) => {
                        props.setValue(parseInt(value.points.value))

                    },
                },
            ]}
        />
    )
}

const steps = [
    {
        element: '.current-goal-hint',
        title: "Current Goal",
        intro: 'These are your goals for the week.',
        position: 'top',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '.goal-cell',
        title: "Goals",
        intro: 'Get this many points to complete your goal for that attribute for the week.',
        position: 'top',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '.goal-accomp-1',
        title: "Goals",
        intro: 'Accomplishing individual goals will give your hero a small boost for the week.',
        position: 'top',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '.goal-accomp-1',
        title: "Goals",
        intro: 'Accomplishing all your goals will give your hero a large boost!',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    },
    {
        element: '.goal-cell',
        title: "Goals",
        intro: 'You can set your goals on Monday\'s',
        position: 'bottom',
        tooltipClass: 'myTooltipClass',
        highlightClass: 'myHighlightClass',
    }
];

