import { Device } from "@capacitor/device";
import log from "loglevel";

export async function smartLog(text: string, data?: any, verbose: boolean = false) {
    let device = await Device.getInfo();
    if (device.platform === "web") {
        if (data) {
            log.debug(text, data);
        } else {
            log.debug(text);
        }
    } else {
        if (data && verbose === true) {
            console.log(text, JSON.stringify(data, null, 2));
        } else if (data && verbose === false) {
            console.log(text, JSON.stringify(data));

        } else {
            console.log(text);
        }
    }
}