import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import HeroStatsCircularComponent from '../../Features/Profile/HeroStatsCircularComponent';
import HeaderLogo from '../../components/HeaderLogo';
import ProfileHeader from '../../Features/Profile/components/ProfileHeader';
import GlobalState from '../../contexts/GlobalState';
import { OnboardingObject } from '../../models/userModel';
import { StorageService } from '../../services/StorageService';

//TODO:

const ExplainStats = () => {
    const nextPage = "/onboarding/explainstatspt2";
    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject } = globalState;
    const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();

    useEffect(() => {
        updateOnboardingObject();
    }, []);

    async function updateOnboardingObject() {
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        setOnboardingObject(tempOnboardingObject);
        await storageService.setObject("onboarding", tempOnboardingObject);
    }
    return (
        <IonPage>
            <HeaderLogo />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">You also have stats</div>
                    <ProfileHeader heroName={onboardingObject?.heroName} heroPicture={onboardingObject?.heroPicture} heroLevel={heroObject?.overallLevel} />
                    <HeroStatsCircularComponent />
                    <div className='onboarding-text-area' style={{ textAlign: "center" }}>
                        <p>
                            As you do <span className='onboarding-emphasis'>strength</span> activities, <span className='onboarding-emphasis'>cardio</span> activities, <span className='onboarding-emphasis'>mobility</span> activities and <span className='onboarding-emphasis'>mindfulness</span> activities in real life, <span className='onboarding-emphasis'>your stats will increase</span>.
                        </p>
                        <p>
                            As you progress, you will level up and become more powerful <br />(just like <span className='onboarding-emphasis'>you do</span> in real life!)
                        </p>
                    </div>

                </div>

            </IonContent>
            <IonFooter className="onboarding-footer">
                <IonButton className="onboarding-advance-button" routerLink={nextPage} >
                    Ok
                </IonButton>
            </IonFooter>
        </IonPage>
    )
}

export default ExplainStats