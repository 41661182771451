/* eslint-disable no-loop-func */
import axios, { all } from "axios";
import moment, { Moment } from "moment-timezone";
import { v4 as uuidv4 } from "uuid";
import { DELETE_ALL_ACTIVITIES_ENDPOINT, DELETE_ALL_STEPS_ENDPOINT, WORKOUT_ENDPOINT } from "../models/endPoints";
import { ActivityLedger, ActivityResult, ExercisePoints, WorkoutObject } from "../models/exerciseModels";
import { calculateAttributeRateFromExerciseObj, formatTime, getIntensityLevel, getIntensityMultiplier, timeToSeconds } from "./HelperService";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";

import { getHero, getUser, getUser_id } from "./UserService";
import Parse, { User } from "parse";
import { StorageService } from "./StorageService";
import { HKData } from "./HealthKitService";
import { createUUIDFromString } from "./GoogleFitService";
import { UserObject, activityLedgerKey, lastUpdatedStepsKey, lastUpdatedWorkoutKey } from "../models/userModel";
import { ACTIVITIES_TABLE } from "../models/databaseModels";
import log from "loglevel";
import { logGenericEventWithObject } from "./AnalyticsService";
import { NotificationObject } from "../models/celebrationModels";
let storageService = new StorageService();

/**
 *
 * Takes in an array of objects, an exercise type and a number of seconds that exercise was performed
 * If the exercise exists in the array, increment the number of seconds
 * If not add it to the array
 *
 * @param array
 * @param key
 * @param value
 * @returns
 */

export async function createWorkoutHistorySummary(array: any, readableWorkoutName: string, numberOfSeconds: number) {
	let found = false;
	let newArray = array.map((obj: any) => {
		// If the object has the key, create a new object with the updated 'minutes' value
		if (obj.hasOwnProperty(readableWorkoutName)) {
			found = true;
			let totalSeconds = timeToSeconds(obj[readableWorkoutName].minutes) + numberOfSeconds;
			return {
				...obj,
				[readableWorkoutName]: { minutes: formatTime(totalSeconds) },
			};
		}
		// Otherwise, return the original object
		else {
			return obj;
		}
	});

	// If no object with the key was found, add a new object at the end
	if (!found) {
		let newObj: any = {};
		newObj[readableWorkoutName] = { minutes: formatTime(numberOfSeconds) };
		newArray.push(newObj);
	}

	// Sort the array by the 'minutes' property in descending order
	newArray.sort((a: any, b: any) => {
		const aMinutes = timeToSeconds(a[Object.keys(a)[0]].minutes);
		const bMinutes = timeToSeconds(b[Object.keys(b)[0]].minutes);

		return bMinutes - aMinutes;
	});

	return newArray;
}

export function sumDurationByActivityType(activityLedger: ActivityLedger[], existingResults: ActivityResult[] = []): ActivityResult[] {
	// Create a copy of the existing results so we don't mutate the original array
	const resultObject: { [key: string]: number } = existingResults.reduce((accumulator: { [key: string]: number }, activityResult) => {
		accumulator[activityResult.activityName] = convertTimeStringToMinutes(activityResult.duration);
		return accumulator;
	}, {});

	// Add the new durations
	for (let activity of activityLedger) {
		if (!resultObject[activity.activityName]) {
			resultObject[activity.activityName] = activity.durationMinutes;
		} else {
			resultObject[activity.activityName] += activity.durationMinutes;
		}
	}

	// Convert to the desired output format
	const results: ActivityResult[] = [];

	for (let [activityName, duration] of Object.entries(resultObject)) {
		results.push({
			activityName,
			duration: formatTime(duration),
		});
	}

	return results;
}

const convertTimeStringToMinutes = (timeString: string): number => {
	const [hours, minutes] = timeString.split(" ").map((val, index) => {
		return parseInt(val.slice(0, -1)) * (index === 0 ? 60 : 1);
	});

	return hours + minutes;
};

export function sumPoints(activityArray: ActivityLedger[]): ExercisePoints {
	let result: ExercisePoints = {
		strengthPoints: 0,
		cardioPoints: 0,
		mindfulnessPoints: 0,
		mobilityPoints: 0,
	};

	for (let points of activityArray) {
		if (points.strengthPoints !== undefined) {
			result.strengthPoints += Math.round(points.strengthPoints);
		}
		if (points.cardioPoints !== undefined) {
			result.cardioPoints += Math.round(points.cardioPoints);
		}
		if (points.mindfulnessPoints !== undefined) {
			result.mindfulnessPoints += Math.round(points.mindfulnessPoints);
		}
		if (points.mobilityPoints !== undefined) {
			result.mobilityPoints += Math.round(points.mobilityPoints);
		}
	}
	return result;
}

export function filterByStartDate(exercises: WorkoutObject[], startDate: string): WorkoutObject[] {
	const startMoment = moment(startDate);

	return exercises.filter((exercise) => {
		const exerciseStartMoment = moment(exercise.startDate);
		return exerciseStartMoment.isSameOrAfter(startMoment);
	});
}

export async function createLedgerFromWorkoutArray(workoutArray: WorkoutObject[]) {
	let userTimezone = moment.tz.guess();
	let tempLedger: Array<ActivityLedger> = [];
	let storedLedger = await storageService.getObject(activityLedgerKey);
	if (!storedLedger || storedLedger === "") {
		storedLedger = [];
	}

	for (let workout of workoutArray) {
		if (workout.deleted === true) {
			continue;
		}
		// check if workout is already in the ledger
		let ledgerWorkoutIndex = storedLedger.findIndex((lw: ActivityLedger) => lw.uuid === workout.UUID);
		let durationInMinutes = Math.floor(workout.value / 60);
		let steps = undefined;
		let activityDate = moment(workout.startDate).tz(userTimezone).format();
		// if (workout.activityType === "Steps") {
		// 	// For steps we need to do things a little differently
		// 	// We need to get the duration from the start and end date
		// 	// We need to get the steps from the value
		// 	// We need to get the activity date from the end date
		// 	durationInMinutes = moment(workout.endDate).diff(moment(workout.startDate), "minutes");
		// 	steps = workout.value;
		// 	activityDate = moment(workout.endDate).tz(userTimezone).format();
		// }
		if (ledgerWorkoutIndex !== -1) {
			// log.debug("[createLedgerFromWorkoutArray] Workout found in ledger");
			// If workout found in ledger, update it

			storedLedger[ledgerWorkoutIndex] = {
				...storedLedger[ledgerWorkoutIndex],
				activityName: workout.activityType,
				activityDate: activityDate,
				durationMinutes: durationInMinutes,
				caloriesBurned: workout.totalEnergyBurned,
				caloriesPerMinute: Math.round(workout.totalEnergyBurned / durationInMinutes),
				strengthPoints: workout.strengthPoints || 0,
				cardioPoints: workout.cardioPoints || 0,
				mobilityPoints: workout.mobilityPoints || 0,
				mindfulnessPoints: workout.mindfulnessPoints || 0,
				intensityLevel: workout.intensityLevel || "",
				intensityMultiple: workout.intensityMultiple || 1,
				sourceName: workout.sourceName,
				steps: steps,
				activityId: workout.activityId,
			};
			if (workout.UUID) {
				storedLedger[ledgerWorkoutIndex].uuid = workout.UUID;
			}
			await storageService.setObject(activityLedgerKey, storedLedger);
		} else {
			// Otherwise create new ledgerObject and push it to the tempLedger array
			// log.debug("[createLedgerFromWorkoutArray] No workout found in ledger, adding");
			let ledgerObject: ActivityLedger = {
				activityName: workout.activityType,
				activityDate: activityDate,
				durationMinutes: durationInMinutes,
				caloriesBurned: workout.totalEnergyBurned,
				caloriesPerMinute: Math.round(workout.totalEnergyBurned / durationInMinutes),
				strengthPoints: workout.strengthPoints || 0,
				cardioPoints: workout.cardioPoints || 0,
				mobilityPoints: workout.mobilityPoints || 0,
				mindfulnessPoints: workout.mindfulnessPoints || 0,
				intensityLevel: workout.intensityLevel || "",
				intensityMultiple: workout.intensityMultiple || 1,
				sourceName: workout.sourceName,
				steps: steps,
				activityId: workout.activityId,
			};
			if (workout.UUID) {
				ledgerObject.uuid = workout.UUID;
			}
			tempLedger.push(ledgerObject);
		}
	}
	return tempLedger;
}

export async function logStepsActivity(startDate: Moment, endDate: Moment, numberOfSteps: number): Promise<HKData> {
	let duration = endDate.diff(startDate, "seconds");
	let workoutObject = await convertStepsToWorkoutObject(moment(startDate).format(), duration, numberOfSteps);
	let workoutArray: WorkoutObject[] = [workoutObject];
	if (workoutArray) {
		let serverResponse: WorkoutObject[] = await postWorkoutsToServer(workoutArray, false);
		let ledger = await createLedgerFromWorkoutArray(serverResponse);
		// log.debug("[logStepsActivity] Server Response: ", serverResponse);
		let currentWorkouts: WorkoutObject[] | null = await storageService.getObject("workouts");
		if (currentWorkouts === null) currentWorkouts = [];
		await storageService.setObject("workouts", [...currentWorkouts, ...serverResponse]);
		// log.debug("Activity Ledger: ", ledger)
		// log.debug("Workout Array: ", workoutArray)
		return {
			activityLedger: ledger,
			workoutArray: serverResponse,
			number: numberOfSteps,
		};
	}
	return {
		activityLedger: [],
		workoutArray: [],
		number: numberOfSteps,
	};
}

export const getIconFromExerciseActivity = (activity: string) => {
	if (activity === undefined) {
		return ":muscle:";
	}
	let activityType = activity.toLowerCase();

	if (activityType.indexOf("walk") >= 0 || activityType.indexOf("hik") >= 0) {
		return ":walking:";
	} else if (activityType.indexOf("Steps") >= 0 || activityType.indexOf("steps") >= 0) {
		return ":footprints:";
	} else if (activityType.indexOf("jog") >= 0 || activityType.indexOf("run") >= 0) {
		return ":runner:";
	} else if (
		activityType.indexOf("weight") >= 0 ||
		activityType.indexOf("trx") >= 0 ||
		activityType.indexOf("strength") >= 0 ||
		activityType.indexOf("pushup") >= 0 ||
		activityType.indexOf("push-up") >= 0 ||
		activityType.indexOf("pullup") >= 0 ||
		activityType.indexOf("pull-up") >= 0 ||
		activityType.indexOf("squat") >= 0 ||
		activityType.indexOf("wad") >= 0 ||
		activityType.indexOf("wod") >= 0 ||
		activityType.indexOf("martial arts") >= 0 ||
		activityType.indexOf("traditional strength training") >= 0 ||
		activityType.indexOf("functional strength training") >= 0
	) {
		return ":weight_lifter:";
	} else if (activityType.indexOf("cycling") >= 0 || activityType.indexOf("bike") >= 0) {
		return ":bicyclist:";
	} else if (activityType.indexOf("soccer") >= 0) {
		return ":soccer:";
	} else if (
		activityType.indexOf("cardio") >= 0 ||
		activityType.indexOf("circuit") >= 0 ||
		activityType.indexOf("high intensity interval training") >= 0 ||
		activityType.indexOf("hiit") >= 0 ||
		activityType.indexOf("jump") >= 0 ||
		activityType.indexOf("mixed metabolic cardio training") >= 0
	) {
		return ":person_doing_cartwheel:";
	} else if (activityType.indexOf("yoga") >= 0 || activityType.indexOf("pilates") >= 0 || activityType.indexOf("stretch") >= 0) {
		return ":person_in_lotus_position:";
	} else if (activityType.indexOf("baseball") >= 0 || activityType.indexOf("softball") >= 0) {
		return ":baseball:";
	} else if (activityType.indexOf("basketball") >= 0) {
		return ":basketball:";
	} else if (activityType.indexOf("tennis") >= 0 || activityType.indexOf("racquetball") >= 0) {
		return ":tennis:";
	} else if (activityType.indexOf("house") >= 0 || activityType.indexOf("yard") >= 0) {
		return ":broom:";
	} else if (activityType.indexOf("swi") >= 0) {
		return ":swimmer:";
	} else if (
		activityType.indexOf("mma") >= 0 ||
		activityType.indexOf("boxing") >= 0 ||
		activityType.indexOf("kickboxing") >= 0 ||
		activityType.indexOf("wrestling") >= 0
	) {
		return ":wrestlers:";
	} else if (activityType.indexOf("golf") >= 0) {
		return ":golfer:";
	} else if (activityType.indexOf("surf") >= 0) {
		return ":surfer:";
	} else if (activityType.indexOf("volley") >= 0) {
		return ":volleyball:";
	} else if (activityType.indexOf("sex") >= 0) {
		return ":heart:";
	} else if (activityType.indexOf("cool") >= 0) {
		return ":person_in_lotus_position:";
	} else if (activityType.indexOf("ellip") >= 0) {
		return ":triumph:";
	} else if (activityType.indexOf("medi") >= 0 || activityType.indexOf("mindful") >= 0) {
		return ":pray:";
	} else if (activityType.indexOf("archery") >= 0) {
		return ":bow_and_arrow:";
	} else if (activityType.indexOf("dance") >= 0) {
		return ":dancer:";
	} else if (activityType.indexOf("fencing") >= 0) {
		return ":crossed_swords:";
	} else if (activityType.indexOf("hockey") >= 0) {
		return ":ice_hockey_stick_and_puck:";
	} else if (activityType.indexOf("sailing") >= 0) {
		return ":sailboat:";
	} else if (
		activityType.indexOf("snow sports") >= 0 ||
		activityType.indexOf("snowboarding") >= 0 ||
		activityType.indexOf("downhill skiing") >= 0 ||
		activityType.indexOf("cross country skiing") >= 0
	) {
		return ":snowboarder:";
	} else if (
		activityType.indexOf("journal") >= 0
	) {
		return ":memo:";
	}
	else {
		return ":muscle:";
	}
};

export async function getSumPoints(beginningDate: string, endDate: string, attribute: string, activityLedger: ActivityLedger[]): Promise<number[]> {
	// let activityLedger: ActivityLedger[] | null = await storageService.getObject("activityLedger");

	if (activityLedger) {
		const result: { [key: string]: number } = {};
		// Initialize all dates to zero
		let currentDate = moment(beginningDate);
		while (currentDate.isSameOrBefore(endDate)) {
			result[currentDate.format("YYYY-MM-DD")] = 0;
			currentDate.add(1, "days");
		}
		activityLedger.forEach((activity: any) => {
			if (activity.deleted && activity.deleted === true) return; // skip deleted activities
			const activityDate = activity.activityDate.split("T")[0]; // stripping off the time part if any, keeping just the date

			// we only process entries which are within the given date range
			if (activityDate >= beginningDate && activityDate <= endDate) {
				if (result[activityDate]) {
					result[activityDate] += activity[`${attribute}Points`];
				} else {
					result[activityDate] = activity[`${attribute}Points`];
				}
			}
		});
		// log.debug(`getSumPoints Result: `, Object.values(result));
		return Object.values(result); // returning only the values, not keys
	} else {
		// log.debug("No activityLedger found in storage");
		return [];
	}
}

export const postWorkoutsToServer = async (workouts: WorkoutObject[], sendNotification: boolean = true): Promise<any> => {
	let params = {
		activities: workouts,
		sendNotification: sendNotification,
	};
	// log.debug(`[postWorkoutsToServer] Posting workouts: `, JSON.stringify(params));
	const parseObjects = await Parse.Cloud.run("addActivity", params).catch((error) => {
		log.debug(`[postWorkoutsToServer] Error Message: ${error}`);
		throw error;
	});
	// log.debug(`[postWorkoutsToServer] parseObjects: `, parseObjects);
	// log.debug(`[postWorkoutsToServer] parseObjects JSON: `, parseObjects.toJSON());
	// log.debug(`[postWorkoutsToServer] parseObjects JSON: `, parseObjects[0].toJSON());
	if (Array.isArray(parseObjects) && parseObjects.length > 1 && parseObjects[0].status && parseObjects[0].status === "rejected") {
		return [];
	}
	let activities: WorkoutObject[] = [];
	if (!parseObjects) {
		console.log(`[postWorkoutsToServer] No parseObjects returned from server: `, parseObjects);
		return [];
	}
	// parseObjects.forEach((parseObject: any) => {
	// 	// log.debug(`[postWorkoutsToServer] parseObject: `, parseObject);
	// 	let activity = parseObject.toJSON()
	// 	// log.debug(`[postWorkoutsToServer] activity: `, activity);
	// 	activities.push(activity);
	// });
	// // log.debug(`[postWorkoutsToServer] Full API Response: `, activities);
	// return activities;
};

interface WorkoutResponse {
	countInfo: {
		count: number;
		totalPages: number;
		currentPage: number;
		pageSize: number;
	};
	result: [WorkoutObject];
}

export async function getIncompleteWorkouts() {
	let workouts: WorkoutObject[] = await storageService.getObject("workouts");
	// find all workout that do not have strengthPoints, cardioPoints, mobilityPoints, or flexibilityPoints
	let incompleteWorkouts = workouts.filter((workout) => {
		return (
			workout.strengthPoints === undefined &&
			workout.cardioPoints === undefined &&
			workout.mobilityPoints === undefined &&
			workout.mindfulnessPoints === undefined
		);
	});
	// log.debug(`[getIncompleteWorkouts] # of incompleteWorkouts: `, incompleteWorkouts.length);
	return incompleteWorkouts;
}

export async function getWorkoutsSinceTime(time: string) {
	log.debug(`[getWorkoutsSinceTime] time: `, time);
	let user_id = await getUser_id();
	const query = new Parse.Query(ACTIVITIES_TABLE);
	query.limit(10);
	query.equalTo("user_id", user_id);
	query.notEqualTo("deleted", true);
	query.descending("createdAt");
	const parseObjects = await query.find();
	let workouts: WorkoutObject[] = [];
	parseObjects.forEach((parseObject: any) => {
		workouts.push(parseObject.toJSON());
	});
	log.debug(`[getWorkoutsSinceTime] workouts: `, workouts);
	// remove any workouts that are older than the time
	workouts = workouts.filter((workout) => {
		return moment(workout.createdAt).isAfter(time);
	});
	return workouts;
}

/**
 * Retrieves the recent workouts for a user. If baseline is true, it will fetch all workouts since the user was created
 * and compare them to the stored workouts.
 * 
 * @param baseline - A boolean indicating whether to use a baseline date for comparison.
 * @param user - An optional UserObject representing the user. If not provided, it will be fetched using getUser().
 * @returns An array of new workouts.
 */
export async function getRecentWorkouts(baseline: boolean = false, user?: UserObject | null) {
	log.debug(`[getRecentWorkouts] running`);
	if (!user) {
		user = await getUser();
	}
	log.debug(`[getRecentWorkouts] user: `, user);
	let lastUpdated = await storageService.getItem(lastUpdatedWorkoutKey);
	log.debug(`[getRecentWorkouts] lastUpdated: `, lastUpdated);
	if (lastUpdated === "" || baseline === true) {
		// Subtracting a day here just in case. We use th enddate of the workout so we don't want to miss any
		lastUpdated = moment(user?.createdAt).subtract("1", "day").utc().format("YYYY-MM-DDTHH:mm:ss");
	} else if (lastUpdated === "Invalid date") {
		// if user was created more than a week ago, set lastUpdated to 1 week ago
		// otherwise set lastUpdated to user.createdAt
		if (moment().diff(moment(user?.createdAt), "days") > 7) {
			lastUpdated = moment().subtract(1, "week").utc().format("YYYY-MM-DDTHH:mm:ss");
		} else {
			lastUpdated = moment(user?.createdAt).utc().format("YYYY-MM-DDTHH:mm:ss");
		}
	} else {
		// if user was created more than a week ago, set lastUpdated existing lastUpdated - 1 week
		// otherwise set lastUpdated to user.createdAt
		if (moment().diff(moment(user?.createdAt), "days") > 7) {
			lastUpdated = moment(lastUpdated).subtract(7, "days").utc().format("YYYY-MM-DDTHH:mm:ss"); // convert to UTC & DB format
		} else {
			lastUpdated = moment(user?.createdAt).utc().format("YYYY-MM-DDTHH:mm:ss");
		}
	}

	let allWorkouts: WorkoutObject[] = [];
	log.debug(`[getRecentWorkouts] lastUpdated: `, lastUpdated);
	const query = new Parse.Query(ACTIVITIES_TABLE);
	query.limit(10000);
	query.equalTo("user_id", user?.userID);
	query.notEqualTo("deleted", true);
	query.greaterThanOrEqualTo("endDate", moment(lastUpdated).format());
	const parseObjects = await query.find();
	// log.debug(`[getRecentWorkouts] Full API Response: `, parseObjects);
	parseObjects.forEach((parseObject: any) => {
		let workout = parseObject.toJSON();
		// log.debug(`[getRecentWorkouts] workout endDate: ${workout.endDate}. lastUpdate: ${lastUpdated} `);
		if (workout.endDate >= lastUpdated) {
			allWorkouts.push(workout);
		}
		allWorkouts.push(parseObject.toJSON());
	});
	// log.debug(`[getRecentWorkouts] # of workouts: `, allWorkouts.length);
	log.debug(`[getRecentWorkouts] Full API Response: `, allWorkouts);
	let newWorkouts = await upsertWorkouts(allWorkouts);
	await storageService.setItem(lastUpdatedWorkoutKey, moment().format());
	return [...newWorkouts];
}

export async function removeDuplicateWorkouts(allWorkouts: WorkoutObject[]) {
	// Filter out any workouts that have the same UUID
	log.debug(`[removeDuplicateWorkouts] Checking ${allWorkouts.length} workouts for duplicates`);
	let uniqueWorkouts = allWorkouts.filter((workout, index, self) => self.findIndex((t) => t.UUID === workout.UUID) === index);
	log.debug(`[removeDuplicateWorkouts] Removed ${allWorkouts.length - uniqueWorkouts.length} duplicate workouts `);
	await storageService.setObject("workouts", uniqueWorkouts);
	return uniqueWorkouts;
}

/**
 *
 * Gets currently stored workouts
 * Pulls their UUIDs
 * Filters newWorkouts to only include workouts that are not already stored
 * Adds newWorkouts to currently stored workouts
 *
 * @param newWorkouts
 * @returns
 */

export async function upsertWorkouts(newWorkouts: WorkoutObject[]) {
	if (newWorkouts.length === 0) return [];
	let currentWorkouts: WorkoutObject[] | null = await storageService.getObject("workouts");
	log.debug(`[upsertWorkouts] Current workouts: ${currentWorkouts?.length}`);
	if (currentWorkouts === null) {
		currentWorkouts = [];
		newWorkouts = await removeDuplicateWorkouts(newWorkouts);
	} else {
		currentWorkouts = await removeDuplicateWorkouts(currentWorkouts); // Just in case some dupe workouts are returned
		// Create a Set of current UUIDs
		let currentUUIDs = new Set(currentWorkouts.map((workout) => workout.UUID));
		// Only add workouts from result that are not in currentWorkouts
		newWorkouts = newWorkouts.filter((workout) => !currentUUIDs.has(workout.UUID));
	}

	log.debug(`[upsertWorkouts] Workouts added: ${newWorkouts.length}`);

	// Only add workouts from result that are not in currentWorkouts
	if (newWorkouts.length > 0) {
		log.debug(`[upsertWorkouts] Adding ${newWorkouts.length} workouts to storage`);
		await storageService.setObject("workouts", [...currentWorkouts, ...newWorkouts]);
	}
	return [...newWorkouts];
}

async function updateWorkoutObjectOnServer(workoutObject: WorkoutObject, user_id: string) {
	let query = new Parse.Query(ACTIVITIES_TABLE);
	let workoutID = workoutObject.objectId ? workoutObject.objectId : workoutObject._id;
	query.equalTo("objectId", workoutID);
	let result = await query.first().catch((error: any) => {
		log.debug("[updateWorkoutObjectOnServer] Error: " + error.code + " " + error.message);
		throw error;
	});
	if (!result) {
		log.debug(`[updateWorkoutObjectOnServer] No activity found for workoutID ${workoutID}`);
		return null;
	}
	result.set(workoutObject);
	result = await result.save();
	if (!result) {
		log.debug(`[updateWorkoutObjectOnServer] Error updating activity found for workoutID ${workoutID}`);
		return null;
	}
	let resultJSON = result.toJSON();
}

/**
 *
 * Updates the workout objects to ensure that they have the correct fields
 *
 * @param workoutObjects
 * @param user_id
 * @returns WorkoutObject[]
 */

export async function updateWorkoutObjects(workoutObjects: WorkoutObject[]): Promise<WorkoutObject[]> {
	let user_id = await getUser_id();
	const promises = workoutObjects.map(async (workoutObject) => {
		if (
			workoutObject.caloriesPerMinute === undefined ||
			(workoutObject.strengthPoints === undefined &&
				workoutObject.cardioPoints === undefined &&
				workoutObject.mobilityPoints === undefined &&
				workoutObject.mindfulnessPoints === undefined)
		) {
			// log.debug(`[updateWorkoutObjects] Updating workoutObject: `, workoutObject);
			let updatedWorkout = convertPartialExerciseObjectToFull(workoutObject, user_id);
			await updateWorkoutObjectOnServer(updatedWorkout, user_id); // update the workout on the server
			return updatedWorkout;
		} else {
			return workoutObject;
		}
	});

	return Promise.all(promises);
}

export function convertPartialExerciseObjectToFull(workout: WorkoutObject, user_id: string): WorkoutObject {
	const baselineMultiple = 8;
	let caloriesPerMinute = Math.round(workout.totalEnergyBurned / (workout.value / 60));
	let intensityMultiple = Number((caloriesPerMinute / baselineMultiple).toFixed(1));
	if (intensityMultiple < 1) intensityMultiple = 1;
	let attributeRateObject = calculateAttributeRateFromExerciseObj(workout, intensityMultiple);
	let data: WorkoutObject = {
		user_id: user_id,
		UUID: workout.UUID,
		dataType: "workout",
		activityType: workout.activityType,
		startDate: workout.startDate,
		endDate: workout.endDate,
		totalDistance: 0, // cant send these because of the stupid plugin
		totalDistanceUnit: "meters",
		value: workout.value,
		valueUnit: "sec",
		totalEnergyBurned: workout.totalEnergyBurned,
		reportId: workout.UUID,
		sourceName: workout.sourceName,
		timeZone: "",
		weatherTemperature: "",
		humidity: "",
		caloriesPerMinute: caloriesPerMinute,
		intensityMultiple: intensityMultiple,
		strengthPoints: attributeRateObject.strengthPoints,
		cardioPoints: attributeRateObject.cardioPoints,
		mindfulnessPoints: attributeRateObject.mindfulnessPoints,
		mobilityPoints: attributeRateObject.mobilityPoints,
		intensityLevel: getIntensityLevel(intensityMultiple),
	};
	return data;
}

export async function convertManualActivityToWorkoutObject(
	date: string,
	durationInSeconds: number,
	activityName: string,
	intensity: string,
	calories: number
): Promise<WorkoutObject> {
	// set endDate to be startDate + duration using Moment.js
	let user_id = await getUser_id();
	let UUID = uuidv4();
	let activityData: WorkoutObject = {
		user_id: user_id,
		UUID: UUID,
		dataType: "workout",
		activityType: activityName,
		// Switched to better match duel tick
		// startDate: moment(date).format(),
		// endDate: moment(date).add(durationInSeconds, "seconds").format(),
		startDate: moment(date).subtract(durationInSeconds, "seconds").format(),
		endDate: moment(date).format(),
		totalDistance: 0,
		totalDistanceUnit: "meters",
		value: durationInSeconds,
		valueUnit: "seconds",
		totalEnergyBurned: calories,
		reportId: UUID,
		sourceName: "Manual Entry",
		timeZone: "",
		weatherTemperature: "",
		humidity: "",
		caloriesPerMinute: Math.round(calories / Math.floor(durationInSeconds / 60)),
		intensityMultiple: getIntensityMultiplier(intensity),
		intensityLevel: intensity,
	};
	return activityData;
}

export async function convertStepsToWorkoutObject(date: string, durationInSeconds: number, numberOfSteps: number): Promise<WorkoutObject> {
	// set endDate to be startDate + duration using Moment.js
	let user_id = await getUser_id();
	// let activityString = "steps" + moment(date).format() + numberOfSteps;
	// log.debug("[convertGFObjectToExerciseObject] activityString: ", activityString)
	let uuid = user_id + "-" + moment(date).format("YYYY-MM-DDHH:mm") + "-" + numberOfSteps;
	let calories = 0.04 * numberOfSteps; // https://calculator.academy/steps-to-calories-calculator/
	let activityData: WorkoutObject = {
		user_id: user_id,
		UUID: uuid,
		dataType: "steps",
		activityType: "Steps",
		startDate: moment(date).format(),
		endDate: moment(date).add(durationInSeconds, "seconds").format(),
		totalDistance: 0,
		totalDistanceUnit: "meters",
		value: numberOfSteps,
		valueUnit: "steps",
		totalEnergyBurned: calories,
		reportId: uuid,
		sourceName: "Steps",
		timeZone: "",
		weatherTemperature: "",
		humidity: "",
		caloriesPerMinute: Math.round(calories / Math.floor(durationInSeconds / 60)),
		intensityMultiple: 1,
		intensityLevel: "low",
	};
	return activityData;
}

/**
 * Retrieves activities by user ID.
 * If userID is not provided, it retrieves activities for the current user.
 * @param userID - The ID of the user (optional).
 * @returns A promise that resolves to an array of WorkoutObject.
 */
export async function getActivitiesByUserID(userID?: string): Promise<WorkoutObject[]> {
	if (!userID) {
		userID = await getUser_id();
	}
	const query = new Parse.Query(ACTIVITIES_TABLE);
	query.limit(10000);
	query.equalTo("user_id", userID);
	query.notEqualTo("deleted", true);
	const parseObjects = await query.find();
	let activities: WorkoutObject[] = [];
	parseObjects.forEach((parseObject: any) => {
		activities.push(parseObject.toJSON());
	});
	// order activities by endDate
	activities.sort((a, b) => {
		return moment(b.endDate).diff(moment(a.endDate));
	});
	// log.debug(`[getActivities] activities: `, activities);
	return activities;
}

/**
 * Retrieves an activity by its ID.
 * 
 * @param activityId - The ID of the activity to retrieve.
 * @returns A promise that resolves to the activity object if found, otherwise undefined.
 */
export async function getActivityById(activityId: string): Promise<ActivityLedger | undefined> {
	// first check if the activity is in the activityLedger local storage
	let activityLedger = await storageService.getObject(activityLedgerKey);
	if (activityLedger) {
		let activity = activityLedger.find((activity: ActivityLedger) => activity.activityId === activityId);
		if (activity) {
			return activity;
		}
	}
	// if not, get all activities from the server for the user
	let user_id = await getUser_id();
	let workouts = await getActivitiesByUserID(user_id);
	let activities = await createLedgerFromWorkoutArray(workouts);
	let activity = activities.find((activity: ActivityLedger) => activity.activityId === activityId);
	return activity;
}

export async function getAndProcessBaselineWorkouts(): Promise<{ pointsResult: any; activityLedger: any; recentWorkouts: any }> {
	let user_id = await getUser_id();
	let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(true); // not returning something here because recursion
	// recentWorkouts = await updateWorkoutObjects(recentWorkouts);
	if (recentWorkouts.length > 0) {
		let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
		let pointsResult: ExercisePoints = sumPoints(activityLedger);
		return { pointsResult: pointsResult, activityLedger: activityLedger, recentWorkouts: recentWorkouts };
	}
	return { pointsResult: null, activityLedger: null, recentWorkouts: null };
}

export async function filterForUniqueWorkouts(existingData: WorkoutObject[] | null, workouts: WorkoutObject[]) {
	let tempWorkoutArray: WorkoutObject[] = [];
	// log.debug("workouts: ", workouts);
	// log.debug("existingData: ", existingData);
	workouts.forEach((element: WorkoutObject, index: number) => {
		// if (moment(element.endDate) <= endDate && moment(element.endDate) >= startDate) {
		// Check if a workout with the same UUID exists in existingData
		if (existingData && existingData.some((existingElement) => existingElement.UUID === element.UUID)) {
			// Do nothing (don't push) if a workout with the same UUID already exists
			// log.debug(`Skipping ${element}`);
		} else {
			// If no workout with the same UUID exists, then push the element into the array
			tempWorkoutArray.push(element);
		}
		// }
	});
	// log.debug("tempWorkoutArray: ", tempWorkoutArray);

	return tempWorkoutArray;
}

export async function deleteAllActivitiesForUser() {
	let userID = await getUser_id();
	const query = new Parse.Query(ACTIVITIES_TABLE);
	query.equalTo("user_id", userID);
	let result = await query.find();
	let activitiesDeleted = 0;
	result.forEach(async (stuff) => {
		// log.debug(`deleteActivitiesByUserID: ${JSON.stringify(stuff)}`);
		await stuff
			.destroy()
			.then(() => {
				activitiesDeleted++;
			})
			.catch(async (error) => {
				sendSlackErrorNotification(`[deleteAllActivitiesForUser] Full Error Obj`, "postWorkoutsToServer", `${error}`);
				log.debug(`[deleteAllActivitiesForUser] Error Message: ${error}`);
				log.debug(`[deleteAllActivitiesForUser] Full Error Obj: `, error);
			});
	});
	log.debug(`[deleteAllActivitiesForUser] Deleted ${activitiesDeleted} activities`);
}

export async function deleteAllStepsForUser() {
	// Not really needed now as we don't count steps
	// let user_id = await getUser_id();
	// let ENDPOINT = DELETE_ALL_STEPS_ENDPOINT + "/?userID=" + user_id;
	// await axios({
	// 	url: ENDPOINT,
	// 	method: "delete",
	// })
	// 	.then((apiResponse) => {
	// 		log.debug(`[deleteAllStepsForUser] Full API Response: `, JSON.stringify(apiResponse));
	// 	})
	// 	.catch(async (error) => {
	// 		sendSlackErrorNotification(`[deleteAllStepsForUser] Full Error Obj`, "postWorkoutsToServer", `${error}`);
	// 		log.debug(`[deleteAllStepsForUser] Error Message: ${error}`);
	// 		log.debug(`[deleteAllStepsForUser] Full Error Obj: `, error);
	// 	});
}