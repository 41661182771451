/* eslint-disable react-hooks/rules-of-hooks */
import { IonCol, IonLoading, IonModal, IonRow } from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { CircularProgressbar, CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { cardioColor, mindfulnessColor, mobilityColor, strengthColor } from "../../constants/theme";
import GlobalState from "../../contexts/GlobalState";
import "../../css/Badge.css";
import "../../css/HeroHealthComponent.css";
import "../../css/Duels.css";
import { CardioPointsPerLevel, MindfulnessPointsPerLevel, MobilityPointsPerLevel, StrengthPointsPerLevel, pointsPerLevel } from "../../models/exerciseModels";
import { HeroObject } from "../../models/userModel";
import { shouldShowCoachmark, updateCoachMark } from "../../services/HelperService";
import { Steps } from "intro.js-react";
import { getHero } from "../../services/UserService";
import { useHistory } from "react-router";
import { TourComponent } from "../Tour/StepsComponent";

export default function HeroStatsCircularComponent(props: { showCoachMark?: boolean, showSkills?: boolean }) {
	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { heroObject, previousHeroObject, activityLedger, setHeroObject } = globalState;
	const [showStatsCoachMark, setShowStatsCheckMark] = useState<boolean>(false)
	const [showComingSoonModal, setShowComingSoonModal] = useState<boolean>(false)


	type LevelUpState = {
		strength: boolean;
		cardio: boolean;
		mobility: boolean;
		mindfulness: boolean;
	};

	const initialLevelUpState = {
		strength: false,
		cardio: false,
		mobility: false,
		mindfulness: false,
	};

	const [progressBar, setProgressBar] = useState<HeroObject | undefined>(previousHeroObject);

	const [levelUp, setLevelUp] = useState<LevelUpState>(initialLevelUpState);

	const [showLevelUpBadge, setShowLevelUpBadge] = useState(false);

	useEffect(() => {
		async function updateHero() {
			if (heroObject && previousHeroObject) {
				// log.debug(`[HeroStatsCircularComponent] heroObject in health component: `, heroObject);
				// log.debug(`[HeroStatsCircularComponent] previousHeroObject in health component: `, previousHeroObject);
				setProgressBar({
					...heroObject,
					strengthLevel: previousHeroObject.strengthLevel,
					strengthPoints: previousHeroObject.strengthPoints,
					cardioLevel: previousHeroObject.cardioLevel,
					cardioPoints: previousHeroObject.cardioPoints,
					mindfulnessLevel: previousHeroObject.mindfulnessLevel,
					mindfulnessPoints: previousHeroObject.mindfulnessPoints,
					mobilityLevel: previousHeroObject.mobilityLevel,
					mobilityPoints: previousHeroObject.mobilityPoints,
				});
			} else {
				let storedHero = await getHero();
				if (storedHero) {
					setProgressBar(storedHero)
				} else {
					console.error(`No hero stats & no previous hero stats`);
				}
			}
		}
		updateHero()
	}, [activityLedger, heroObject, previousHeroObject]);

	// Strength
	useEffect(() => {
		let interval: NodeJS.Timeout;

		if (previousHeroObject && heroObject) {
			interval = setInterval(() => {
				setProgressBar((prevObject: any) => {
					let newObject = { ...prevObject };

					if (newObject.strengthLevel === heroObject.strengthLevel) {
						newObject.strengthPoints = heroObject.strengthPoints;
					} else if (newObject.strengthPoints === (newObject.strengthLevel + 0) * StrengthPointsPerLevel - 1) {
						newObject.strengthLevel += 1;
						newObject.strengthPoints = 0;
						// setShowLevelUpBadge('strength', true);
					} else {
						newObject.strengthPoints = (newObject.strengthLevel + 0) * StrengthPointsPerLevel - 1;
					}

					return newObject;
				});
			}, 1000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [heroObject, previousHeroObject]);

	// Cardio
	useEffect(() => {
		let interval: NodeJS.Timeout;

		if (previousHeroObject && heroObject) {
			interval = setInterval(() => {
				setProgressBar((prevObject: any) => {
					let newObject = { ...prevObject };

					if (newObject.cardioLevel === heroObject.cardioLevel) {
						newObject.cardioPoints = heroObject.cardioPoints;
					} else if (newObject.cardioPoints === (newObject.cardioLevel + 0) * CardioPointsPerLevel - 1) {
						newObject.cardioLevel += 1;
						newObject.cardioPoints = 0;
						// setShowLevelUpBadge('cardio', true);
					} else {
						newObject.cardioPoints = (newObject.cardioLevel + 0) * CardioPointsPerLevel - 1;
					}

					return newObject;
				});
			}, 1000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [heroObject, previousHeroObject]);

	// Mobility
	useEffect(() => {
		let interval: NodeJS.Timeout;

		if (previousHeroObject && heroObject) {
			interval = setInterval(() => {
				setProgressBar((prevObject: any) => {
					let newObject = { ...prevObject };

					if (newObject.mobilityLevel === heroObject.mobilityLevel) {
						newObject.mobilityPoints = heroObject.mobilityPoints;
					} else if (newObject.mobilityPoints === (newObject.mobilityLevel + 0) * MobilityPointsPerLevel - 1) {
						newObject.mobilityLevel += 1;
						newObject.mobilityPoints = 0;
						// setShowLevelUpBadge('mobility', true);
					} else {
						newObject.mobilityPoints = (newObject.mobilityLevel + 0) * MobilityPointsPerLevel - 1;
					}

					return newObject;
				});
			}, 1000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [heroObject, previousHeroObject]);

	// Mindfulness
	useEffect(() => {
		let interval: NodeJS.Timeout;

		if (previousHeroObject && heroObject) {
			interval = setInterval(() => {
				setProgressBar((prevObject: any) => {
					let newObject = { ...prevObject };

					if (newObject.mindfulnessLevel === heroObject.mindfulnessLevel) {
						newObject.mindfulnessPoints = heroObject.mindfulnessPoints;
					} else if (newObject.mindfulnessPoints === (newObject.mindfulnessLevel + 0) * MindfulnessPointsPerLevel - 1) {
						newObject.mindfulnessLevel += 1;
						newObject.mindfulnessPoints = 0;
						// setShowLevelUpBadge('mindfulness', true);
					} else {
						newObject.mindfulnessPoints = (newObject.mindfulnessLevel + 0) * MindfulnessPointsPerLevel - 1;
					}

					return newObject;
				});
			}, 1000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [heroObject, previousHeroObject]);

	useEffect(() => {
		if (props.showCoachMark === true)
			shouldShowCoachmark("stats").then((result: boolean) => {
				setShowStatsCheckMark(result);
			});
	}, [props.showCoachMark]);

	type HeroStats = {
		strengthLevel: number;
		cardioLevel: number;
		mobilityLevel: number;
		mindfulnessLevel: number;
		strengthPoints: number;
		cardioPoints: number;
		mobilityPoints: number;
		mindfulnessPoints: number;
	};

	const attributes = ["strength", "cardio", "mobility", "mindfulness"] as const;
	type AttributeType = (typeof attributes)[number];

	function attributeIcon(attribute: AttributeType) {
		switch (attribute) {
			case "strength":
				return "💪";
			case "cardio":
				return "💚"
			case "mobility":
				return "🧘"
			case "mindfulness":
				return "🙏"
		}
	}

	function navToSkills(attribute: AttributeType) {
		if (attribute === "mobility" || attribute === "mindfulness") {
			setShowComingSoonModal(true)
		} else if (props.showSkills === true) {
			history.push(`/skills/${attribute}`)
		}
	}

	return (
		<>
			<IonModal className="villain-difficulty-modal" isOpen={showComingSoonModal} onDidDismiss={() => setShowComingSoonModal(false)}>
				<div className="villain-difficulty-modal-div" style={{ textAlign: "center" }}>
					These skills are coming soon
				</div>
			</IonModal>
			{heroObject && progressBar && (
				<div className="progress-bar-container">
					<IonRow className="attribute-container">
						{attributes.map((attribute: AttributeType) => {
							let currentPoints = progressBar[`${attribute}Points` as keyof HeroStats];
							let goalPoints = progressBar[`${attribute}Level` as keyof HeroStats] * pointsPerLevel[attribute];
							let percentage = (currentPoints / goalPoints) * 100;
							let level = progressBar[`${attribute}Level` as keyof HeroStats];
							let barColor = "";
							switch (attribute) {
								case "strength":
									barColor = `${strengthColor}`;
									break;
								case "cardio":
									barColor = `${cardioColor}`;
									break;
								case "mobility":
									barColor = `${mobilityColor}`;
									break;
								case "mindfulness":
									barColor = `${mindfulnessColor}`;
									break;
							}
							return (
								<IonCol key={`${attribute}`} size="2.5" className={`circular-progress-col`} onClick={() => navToSkills(attribute)}>
									<div className={`attribute-title`}>{attributeIcon(attribute)}<br />{attribute}</div>
									<CircularProgressbarWithChildren
										styles={buildStyles({
											pathColor: `${barColor}`,
											textColor: "white",
											textSize: "30px",
										})}
										value={percentage}
										className={`${attribute}-hint`}
									>
										<div style={{ fontSize: "1vh" }}>Level<div style={{ fontSize: "2vh" }}>{level}</div></div>
										{/* <div style={{ fontSize: "1vh" }}>Level</div> */}
									</CircularProgressbarWithChildren>
									<div className={`attribute-title`} style={{ marginBottom: "0px" }}>
										{goalPoints - currentPoints} minutes <br />to level up
									</div>
								</IonCol>
							);
						})}
					</IonRow>
				</div>
			)}
		</>
	);
}
