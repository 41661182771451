import React from 'react';
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonImg, IonText } from '@ionic/react';
import { FeedItem } from '../../../models/feedModels';

const FeedItemCard = (props: { feedItem: FeedItem }) => {
    return (
        <IonCard>
            {props.feedItem.image && <IonImg src={props.feedItem.image} alt={`${props.feedItem.eventType} image`} />}
            <IonCardHeader>
                <IonCardTitle>{props.feedItem.title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <p>{props.feedItem.details}</p>
            </IonCardContent>
        </IonCard>
    );
};

export default FeedItemCard;
