import {
    IonAvatar,
    IonCard,
    IonCardContent,
    IonCol,
    IonRow
} from "@ionic/react";
import "../../../css/Duels.css";
import { DuelStatus } from "../../../models/duelsModels";
import { HeroObject } from "../../../models/userModel";
import HealthIndicator from "./HealthIndicator";
import { UserAvatar } from "../../../components/UserAvatar";

const DuelStatusCard = (props: { duel: DuelStatus, index: number, hero: HeroObject | undefined }) => {
    return (
        <IonCard key={props.index} className="duel-card" routerLink={`/dueldetails/${props.duel.statusId}/updates`}>
            <IonCardContent>
                <IonRow>
                    <IonCol className="fighter" size="5">
                        <IonAvatar className="member-image">
                            <UserAvatar avatarImage={props.hero?.heroImageURL} />
                        </IonAvatar>
                        {/* <img className="fighter-image" src={props.hero?.heroImageURL ?? "/assets/images/mystery-avatar.png"} alt={props.duel.heroName} /> */}
                        <p>{props.duel.heroName}</p>
                        <HealthIndicator health={props.duel.heroHealth} maxHealth={props.duel.heroMaxHealth} />
                        <p>Health: {props.duel.heroHealth}</p>
                    </IonCol>

                    <IonCol size="2" className="vs">
                        VS
                    </IonCol>

                    <IonCol className="fighter" size="5">
                        <IonAvatar className="member-image">

                            <img className="fighter-image" src={props.duel.villainImageURL} alt={props.duel.villainName} />
                        </IonAvatar>

                        <p>{props.duel.villainName}</p>
                        <HealthIndicator health={props.duel.villainHealth} maxHealth={props.duel.villainMaxHealth} />
                        <p>Health: {props.duel.villainHealth}</p>
                    </IonCol>
                </IonRow>
                <p className="update">Last updated: {new Date(props.duel.updatedAt).toLocaleString()}</p>
            </IonCardContent>
        </IonCard>
    )
}

export default DuelStatusCard;