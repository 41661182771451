/* eslint-disable react-hooks/exhaustive-deps */
import { IonButton, IonFooter, IonPage } from "@ionic/react";
import log from "loglevel";
import { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import GlobalState from "../contexts/GlobalState";

const EntryScreen = () => {
	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { userState, isMobile } = globalState;

	useEffect(() => {
		log.debug(`[entryscreen] userState: ${JSON.stringify(userState)}`);
		if (userState && userState.authenticated === true) {
			// log window.location.pathname
			log.debug(`[entryscreen] window.location.pathname: ${window.location.pathname}`);
			if (window.location.pathname === "" || window.location.pathname === "/") {
				// history.push(`/profile`);
			} else if (window.location.pathname === "/entryscreen") { // were on entryscreen but user is authenticated
				history.push(`/home`);
			} else {
				history.push(window.location.pathname);
			}
		}
	}, [userState]);

	return (
		<IonPage>

			<div style={{ height: "100%", display: "block", textAlign: "center", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>

				{/* <img src="assets/images/fithero-ai-logo-black-lowercase.jpg" style={{ width: "70%" }} alt="logo" />
				<h1 style={{ textAlign: "center", color: "white" }}>Live Your Healthiest Life,<br />One Adventure at a Time</h1> */}
				<div style={{ height: "50%" }}>
					<img style={{ objectFit: isMobile === true ? "cover" : "contain" }} className="onboarding-hero-image" src="/assets/images/onboarding/intro-image-jpg.jpg" alt="" />
				</div>

				<img src="assets/images/fithero-ai-logo-black-lowercase.jpg" style={{ width: "70%", margin: "auto" }} alt="logo" />
				<h1 style={{ textAlign: "center", color: "white" }}>Live Your Healthiest Life,<br />One Adventure at a Time</h1>
				<div style={{ marginTop: "20%" }}>
					<IonButton className="onboarding-advance-button" style={{ color: "white" }} routerLink="/onboarding/intro">
						Get Started
					</IonButton>
					<IonButton className="button-as-link" routerLink="/login">
						I already have a Hero
					</IonButton>
				</div>
			</div>
		</IonPage>
	);
};

export default EntryScreen;
