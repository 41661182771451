import { IonButton } from '@ionic/react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import "../css/Celebrations.css";
import { VillainGroupInstance } from '../models/duelsModels';
import { getAllVillainGroupInstances } from '../services/DuelsServics';

export const NoCurrentDuel = (props: { dismissFunction: any }) => {
    const history = useHistory();
    const [currentVillainGroup, setCurrentVillainGroup] = useState<VillainGroupInstance | undefined>(undefined);

    useEffect(() => {
        showNextVillain()
    }, [])


    async function showNextVillain() {
        const villainGroupInstances: VillainGroupInstance[] = await getAllVillainGroupInstances();
        if (villainGroupInstances.length === 0) {
            // has NEVER been in a group before
            props.dismissFunction()
        } else {
            let currentVillainGroup: VillainGroupInstance | undefined = villainGroupInstances.find(villainGroupInstance => villainGroupInstance.completed === false);
            // currentVillainGroup = undefined
            if (currentVillainGroup) {
                setCurrentVillainGroup(currentVillainGroup);
            } else {
                props.dismissFunction()
            }
        }
    }

    async function navToGroup() {
        history.push(`/duels/duelprogression/${currentVillainGroup?.villainGroupId}`)
        props.dismissFunction()
    }
    return (
        <>
            {currentVillainGroup && (
                <>
                    <div className="celebration-container">
                        <div>
                            <div className="celebration-middle-area">
                                <div className="celebration-middle-header">This villain group awaits!</div>
                            </div>
                            <div className="celebration-top-area">
                                <div className='celebration-next-villains'>
                                    <div
                                        className={`group-node-celebration`}
                                        onClick={() => navToGroup()}
                                    >
                                        <div className='group-name'>{currentVillainGroup.groupName}</div>
                                        <img src={currentVillainGroup.groupImage} className={`villainGroup-image-node`} />
                                    </div>
                                </div>
                                <div className="celebration-bottom-area">
                                    <div style={{ marginTop: "20px" }}>
                                        <IonButton color={'success'} onClick={navToGroup}>Start your next duel</IonButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </>
            )}
        </>
    )
}