import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import {
	IonAlert,
	IonAvatar,
	IonButton,
	IonContent,
	IonPage,
	IonRow
} from "@ionic/react";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import HeaderLogo from "../../components/HeaderLogo";
import GlobalState from "../../contexts/GlobalState";
import "../../css/App.css";
import "../../css/Onboarding.css";
import { OnboardingObject } from "../../models/userModel";
import { googleFitConnect } from "../../services/GoogleFitService";
import { authorizeHealthKit } from "../../services/HealthKitService";
import { isOnboardingCompleted } from "../../services/HelperService";
import { StorageService } from "../../services/StorageService";
import { logGenericEvent } from "../../services/AnalyticsService";
import log from "loglevel";

const ConnectTracker = () => {
	const nextPage = "/onboarding/register";
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { userState, currentlySubscribed, updateUserState, paywallEnabled } = globalState;
	const healthKitConfirmation = "/onboarding/healthkitconfirmation";
	const googleFitConfirmation = "/onboarding/googlefitconfirmation";
	const fitbitConfirmation = "/onboarding/fitbitconfirmation";
	const storageService = new StorageService();
	const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
	const [platform, setPlatform] = useState<string>();
	const [fitbitLink, setFitbitLink] = useState<string>();
	const [fitBitAlert, setFitbitAlert] = useState<boolean>();
	const [errorAlert, setErrorAlert] = useState({
		visible: false,
		message: "",
	});
	const history = useHistory();

	useEffect(() => {
		updateOnboardingObject();
		Device.getInfo().then((deviceInfo) => {
			setPlatform(deviceInfo.platform);
		});
		if (userState?.user?.fitbitConnected === true) {
			history.push(fitbitConfirmation);
		} else {
			// log.debug("[ConnectTracker] userState?.user?.fitbitConnected: ", userState?.user?.fitbitConnected);
		}
		let fbLink = "https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22BLTK&scope=activity&state=" + userState.user?.userID;
		log.debug("fbLink: ", fbLink);
		setFitbitLink(fbLink);
	}, []);

	useEffect(() => {
		log.debug("[ConnectTracker] useEffect App Listener added");
		App.addListener("appStateChange", async () => {
			let onboardingCompleted = await isOnboardingCompleted()
			if (onboardingCompleted === false) {
				let uState = await updateUserState();
				// log.debug("[ConnectTracker] State change uState: ", JSON.stringify(uState));
				// log.debug("[ConnectTracker] State change UserState: ", JSON.stringify(userState));
				if (uState?.user?.fitbitConnected === true) {
					history.push(fitbitConfirmation);
				} else if (uState?.onboarding?.url === googleFitConfirmation) {
					// log.debug("[ConnectTracker] updateOnboardingObject - googleFitConfirmation");
					history.push(googleFitConfirmation);
				} else {
					// log.debug("[ConnectTracker] uState?.user?.fitbitConnected: ", uState?.user?.fitbitConnected);
				}
			}
		});
		return () => {
			log.debug("[ConnectTracker] useEffect App Listener removed");
			App.removeAllListeners();
		}
	}, []);

	async function updateOnboardingObject() {
		let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
		if (tempOnboardingObject.url === googleFitConfirmation) {
			// log.debug("[ConnectTracker] updateOnboardingObject - googleFitConfirmation");
			history.push(googleFitConfirmation);
		} else {
			tempOnboardingObject.url = window.location.pathname;
			tempOnboardingObject.completed = false;
			setOnboardingObject(tempOnboardingObject);
			await storageService.setObject("onboarding", tempOnboardingObject);
		}
	}

	const connectFitbit = () => {
		logGenericEvent("attempting to connect fitbit");
		window.open(fitbitLink, "_system");
		setFitbitAlert(true);
	};

	async function connectHealthKit() {
		if (platform === "web") {
			// tell user they can only access HealthKit on iOS
			setErrorAlert({
				visible: true,
				message: "Sorry, you can only connect to HealthKit on an iPhone or iPad.",
			});
		} else {
			logGenericEvent("attempting to connect healthkit");
			await authorizeHealthKit()
				.then((result) => {
					if (result === true) {
						logGenericEvent("healthkit connected");
						history.push(healthKitConfirmation);
					} else {
						setErrorAlert({
							visible: true,
							message: "Error trying to connect your to Healthkit. Please try again. If it keeps happening, select 'No thanks' and you can connect it later.",
						});
					}
				})
				.catch((err) => {
					log.debug("error in HK available: ", err);
					setErrorAlert({
						visible: true,
						message:
							"Error trying to connect to your Healthkit: " +
							err +
							"<br/><br/>Please try again. If it keeps happening, select 'No thanks' and you can connect it later.",
					});
				});
		}
	}

	async function connectGoogleFit() {
		if (platform === "web") {
			// tell user they can only access HealthKit on iOS
			setErrorAlert({
				visible: true,
				message: "Sorry, you can only connect to GoogleFit on an Android device.",
			});
		} else {
			await googleFitConnect(googleFitConfirmation)
				.then((result) => {
					logGenericEvent("googlefit connected");
					if (result === true) {
						log.debug("[ConnectTracker] connectGoogleFit - wont ever see this");
						// history.push(googleFitConfirmation);
					} else {
						setErrorAlert({
							visible: true,
							message: "Error trying to connect to your GoogleFit. Please try again. If it keeps happening, select 'No thanks' and you can connect it later.",
						});
					}
				})
				.catch((err) => {
					log.debug("error in GF available: ", err);
					setErrorAlert({
						visible: true,
						message:
							"Error trying to connect to your GoogleFit: " +
							err +
							"<br/><br/>Please try again. If it keeps happening, select 'No thanks' and you can connect it later.",
					});
				});
		}
	}

	return (
		<IonPage>
			<HeaderLogo checkforUser={false} />
			<IonContent>
				<IonAlert
					isOpen={!!errorAlert.visible}
					header="Oops"
					message={errorAlert.message}
					buttons={[
						{
							text: "Ok",
							role: "cancel",
							handler: () => {
								setErrorAlert({ visible: false, message: "" });
							},
						},
					]}
					onDidDismiss={() => setErrorAlert({ visible: false, message: "" })}
				/>
				<div className="onboarding-main-div">
					<div className="villain-chat-container">
						<IonRow style={{ justifyContent: "center" }}>
							<div className="chat-bubble-onboarding">
								Great to meet you <b>{onboardingObject?.firstName}</b>! I'm excited for your help in defeating the villains of the FitHero universe.<br /><br /> Let's get started by connecting your activity tracker. Which one do you have?
							</div>
						</IonRow>
					</div>
				</div>
				<IonAvatar style={{ margin: "auto", height: "250px", width: "250px" }}>
					<img
						className="user-avatar"
						style={{ width: "100%", height: "100%", objectFit: "cover" }}
						src={onboardingObject?.heroPicture ?? "/assets/images/mystery-avatar.png"}
						alt="your fithero avatar"
					/>
				</IonAvatar>
				<div className="onboarding-text-area" style={{ textAlign: "center" }}>
					<div className="onboarding-app-button-div">
						<IonButton className="onboarding-app-button" color="tertiary" onClick={connectFitbit}>
							Fitbit
						</IonButton>
					</div>
					{(platform === "ios" || platform === "web") && (
						<React.Fragment>
							<div className="onboarding-app-button-div">
								<IonButton className="onboarding-app-button" color="tertiary" onClick={connectHealthKit}>
									Apple Health / Apple Watch
								</IonButton>
							</div>
							{/* <div className="onboarding-help-text" style={{ width: "60%", margin: "auto" }}>
									Syncing with HealthKit will allow you to earn points from your activity
								</div> */}
						</React.Fragment>
					)}
					{/* {(platform === "android" || platform === "web") && (
						<React.Fragment>
							<div className="onboarding-app-button-div">
								<IonButton className="onboarding-app-button" color="tertiary" onClick={connectGoogleFit}>
									GoogleFit
								</IonButton>
							</div>
						</React.Fragment>
					)} */}
					<div className="onboarding-app-button-div">
						<IonButton className="button-as-link" routerLink={nextPage}>
							No thanks / Don't have one
						</IonButton>
					</div>
				</div>
			</IonContent>
		</IonPage>
	);
};

export default ConnectTracker;
