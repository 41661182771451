import moment from "moment";
import { WorkoutObject } from "../models/exerciseModels";
import { Mission } from "../models/missionModels";
import { AvailableMissions, MissionDescription } from "../models/missions";
import log from "loglevel";

export const MissionGenerator = async (activityHistory: WorkoutObject[]) => {
	// Get the number of unique days in the user's activity history
	let uniqueActivityDays = 0;
	if (activityHistory) {
		uniqueActivityDays = new Set(activityHistory.map((activity) => moment(activity.endDate).startOf("day").format())).size;
	}

	// If less than 2 weeks of data or no missions slots are empty, generate a random mission
	if (uniqueActivityDays < 14) {
		log.debug("History is less than 14 entries, generating random missions");
		return generateRandomMission();
	}

	// Otherwise, generate a mission based on user's activity history
	return generateMission(activityHistory);
};
const selectRandomMission = (randomActivityType: string, goalMinutes: number): MissionDescription => {
	// Filter missions of the randomActivityType
	log.debug(`randomActivityType: ${randomActivityType}`);

	const possibleMissions: MissionDescription[] = AvailableMissions.filter((mission: any) => mission.attributeType === randomActivityType);
	log.debug(`possibleMissions: `, possibleMissions);

	// Select a random mission from the possibleMissions array
	const randMissionNumber = Math.floor(Math.random() * possibleMissions.length);
	log.debug(`randMissionNumber: `, randMissionNumber);
	log.debug(`possibleMissions.length: ${possibleMissions.length}`);

	const randomMission: MissionDescription = possibleMissions[randMissionNumber];
	log.debug(`randomMission: `, randomMission);

	randomMission.description = randomMission.description.replace("<x>", String(goalMinutes));

	return randomMission;
};

const generateRandomMission = (): Mission => {
	const activityTypes = ["strength", "cardio", "mobility", "mindfulness"];
	const randomActivityType = activityTypes[Math.floor(Math.random() * activityTypes.length)];
	let goalMinutes = Math.floor(Math.random() * 15) + 1;
	let randomMission = selectRandomMission(randomActivityType, goalMinutes);

	return {
		activityType: randomActivityType,
		goalMinutes: goalMinutes, // Random number between 1 and 15
		rewardMultiplier: 1, // For new users, reward is not multiplied
		missionDescription: randomMission,
	};
};

const generateMission = (activityHistory: WorkoutObject[]): Mission => {
	log.debug("Generating mission based on history");
	// Calculate the total time spent on each activity in the past week
	const totalActivityTimes: { [key: string]: number } = {
		strength: 0,
		cardio: 0,
		mobility: 0,
		mindfulness: 0,
	};

	// Calculate totalActivityTimes for past 14 days
	activityHistory
		.filter((activity) => moment().diff(moment(activity.endDate), "days") <= 14)
		.forEach((activity) => {
			totalActivityTimes[activity.activityType] += activity.value / 60; // value is in seconds, convert to minutes
		});

	log.debug(`totalActivityTimes: `, totalActivityTimes);

	// Calculate weighted probabilities
	const totalMinutes = Object.values(totalActivityTimes).reduce((a, b) => a + b, 0);
	const activityProbabilities: { [key: string]: number } = {
		strength: (totalMinutes - totalActivityTimes.strength + 1) / (4 * totalMinutes + 4), // add 1 to numerator and 4 to denominator to avoid division by 0
		cardio: (totalMinutes - totalActivityTimes.cardio + 1) / (4 * totalMinutes + 4),
		mobility: (totalMinutes - totalActivityTimes.mobility + 1) / (4 * totalMinutes + 4),
		mindfulness: (totalMinutes - totalActivityTimes.mindfulness + 1) / (4 * totalMinutes + 4),
	};

	log.debug(`activityProbabilities: `, activityProbabilities);

	// Choose activity based on weighted probabilities
	const activityTypes = ["strength", "cardio", "mobility", "mindfulness"];
	let cumulativeProbability = 0;
	const random = Math.random();
	const activityType =
		activityTypes.find((activityType) => {
			cumulativeProbability += activityProbabilities[activityType];
			return random < cumulativeProbability;
		}) || "strength"; // default to 'strength' in case no suitable activity type was found

	const weightedRandom = (difficulty: string) => {
		const randNum = Math.random();
		switch (difficulty) {
			case "easy":
				return parseFloat((1 + 3 * Math.pow(randNum, 2)).toFixed(1));
			case "medium":
				return parseFloat((1 + 3 * Math.pow(randNum, 1.5)).toFixed(1));
			default: // hard
				return parseFloat((1 + 3 * randNum).toFixed(1));
		}
	};
	// Calculate mission goal based on past activity
	let goalMinutes, difficulty, rewardMultiplier;
	if (totalActivityTimes[activityType] < 30) {
		difficulty = "easy";
		goalMinutes = 10;
		rewardMultiplier = weightedRandom(difficulty);
	} else if (totalActivityTimes[activityType] < 60) {
		difficulty = "medium";
		goalMinutes = 20;
		rewardMultiplier = weightedRandom(difficulty);
	} else {
		difficulty = "hard";
		goalMinutes = 30;
		rewardMultiplier = weightedRandom(difficulty);
	}

	let randomMission = selectRandomMission(activityType, goalMinutes);

	// Return new Mission
	return {
		activityType,
		goalMinutes,
		rewardMultiplier,
		missionDescription: randomMission,
	};
};
