import { IonAvatar, IonContent, IonSpinner } from "@ionic/react";
import { useContext, useEffect, useRef } from "react";
import GlobalState from "../contexts/GlobalState";
import { SidekickMessage } from "../models/messageModels";
import { StorageService } from "../services/StorageService";
import "../css/HeroChatComponent.css";
import moment from "moment";
import { formatMomentDate, insertNewLines, stringContains } from "../services/HelperService";
import log from "loglevel";
import { SidekickDBObject, SidekickObject, assistantDisplayMessagesKey, excludedText, sidekickDisplayMessagesKey } from "../models/sideKickModels";
const storageService = new StorageService();

const AssistantChatRenderer = (props: {
	messages: SidekickMessage[];
	setMessages: React.Dispatch<React.SetStateAction<SidekickMessage[]>>;
	processing: any;
	setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	scroller: boolean;
	setScroller: React.Dispatch<React.SetStateAction<boolean>>;
	assistant: SidekickDBObject;
}) => {
	const feedEndRef = useRef<HTMLDivElement | null>(null);
	const typingMessage = "Your FitHero is typing...";
	let waitTime = 1000; // just adding 1 incase it's nil

	useEffect(() => {
		props.setProcessing(true);
		storageService.getObject(assistantDisplayMessagesKey).then((result) => {
			if (result !== null) {
				props.setMessages(result);
			}
			props.setProcessing(false);
		});
	}, []);

	useEffect(() => {
		if (props.scroller === true) {
			if (feedEndRef.current !== null) {
				setTimeout(() => {
					try {
						feedEndRef.current?.scrollIntoView({ behavior: "auto" });
					} catch (error) {
						log.debug("ERROR: " + error);
					}
				}, waitTime);
			} else {
				// log.debug("Condition not met to scroll");
			}
		}
	}, [props.messages]);

	return (
		<>
			<IonContent>
				{props.messages &&
					props.messages.length > 0 &&
					props.messages.map((message: SidekickMessage, index: number) => {
						if (message.message && stringContains(message.message, excludedText) === false) {
							// let cleanedContent = message.message.replace(/\n+/g, "\n"); // Replace consecutive newline characters with a single newline
							if (message.source === "assistant") {
								let cleanedContent = insertNewLines(message.message); // this is still needed for re-renders
								const splitContent = cleanedContent.split("\n");
								return (
									<>
										<div key={index} className="assistant-message-div">
											<IonAvatar className="assistant-message-avatar">
												<img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.assistant?.sideKickImage ? `${props.assistant?.sideKickImage}` : `https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/01cd3e33aa221ca06e8af8166aa73421_avatar.webp`} alt="" />
											</IonAvatar>
											<div key={index} className={"assistant-message-container"}>
												{splitContent.map((content: string, contentIndex: number) => {
													return (
														<div key={contentIndex} className={"assistant-message-bubble"}>
															{content}
														</div>
													)
												})}
											</div>
										</div>
										{message.createdAt && (
											<div className="message-timestamp bot">
												<span>
													<>{formatMomentDate(moment(message.createdAt))}</>
												</span>
											</div>
										)}
									</>
								);
							} else {
								return (
									<div key={index} className={"user-message-container"}>
										<div key={message.messageId} className={"user-message-bubble"}>
											{message.message}
										</div>
										{message.createdAt ? (
											<div className="message-timestamp user">
												<span>
													<>{formatMomentDate(moment(message.createdAt))}</>
												</span>
											</div>
										) : (
											<div className="message-timestamp user">
												<span>
													<>Delivered</>
												</span>
											</div>
										)}
									</div>
								);
							}
						}
					})}
				{props.processing === true && (
					<div className="assistant-message-div">
						<IonAvatar className="assistant-message-avatar">
							<img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.assistant?.sideKickImage ? `${props.assistant?.sideKickImage}` : `https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/01cd3e33aa221ca06e8af8166aa73421_avatar.webp`} alt="" />
						</IonAvatar>
						<div className={"assistant-message-container-typing"}><IonSpinner name="dots" /></div>
					</div>
				)}
				<div ref={feedEndRef} />
			</IonContent>
		</>
	);
};

export default AssistantChatRenderer;
