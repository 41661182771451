export const ALTOGIC_ENVURL = "https://c5-na.altogic.com/e:645ad37d7ea57f56fe00c007";
export const GPT_API_CHAT_COMPLETIONS_ENDPOINT = "https://api.openai.com/v1/chat/completions";
export const GPT_API_COMPLETIONS_ENDPOINT = "https://api.openai.com/v1/completions";
export const MESSAGE_ENDPOINT = ALTOGIC_ENVURL + "/coach_messages";
export const DUEL_MESSAGE_ENDPOINT = ALTOGIC_ENVURL + "/duel_messages";
export const WORKOUT_ENDPOINT = ALTOGIC_ENVURL + "/activities";
export const CALC_STATS_ENDPOINT = ALTOGIC_ENVURL + "/calcHeroPoints";
export const DUEL_LEDGER_ENDPOINT = ALTOGIC_ENVURL + "/duel_ledger";
export const DUEL_STATUS_ENDPOINT = ALTOGIC_ENVURL + "/duel_by_id";
export const INVITE_CODE_ENDPOINT = ALTOGIC_ENVURL + "/inviteCode";
export const EMAIL_VALIDATION_ENDPOINT = ALTOGIC_ENVURL + "/validateEmail";
export const FITBIT_HISTORY_ENDPOINT = ALTOGIC_ENVURL + "/fitbit-history";
export const FITBIT_ACTIVITY_ENDPOINT = ALTOGIC_ENVURL + "/fitbitApp";


export const DUEL_CREATION_ENDPOINT = ALTOGIC_ENVURL + "/duels";
export const CONCEDE_DUEL_ENDPOINT = ALTOGIC_ENVURL + "/concedeDuel";
export const DELETE_ALL_ACTIVITIES_ENDPOINT = ALTOGIC_ENVURL + "/deleteActivities";
export const DELETE_ALL_STEPS_ENDPOINT = ALTOGIC_ENVURL + "/deleteSteps";
export const ALTOGIC_CLIENT_KEY = "48643691651c43ddbbb63f8e722d904f"

