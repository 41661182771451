import {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonToolbar
} from "@ionic/react";
import { useHistory } from 'react-router';
import "../../../css/Onboarding.css";
import { useContext } from "react";
import GlobalState from "../../../contexts/GlobalState";

const UpdateAvatarPhotoOption = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile, heroObject } = globalState;
    const history = useHistory();
    const uploadPhoto = "/profile/uploadphoto";
    const createAvatar = "/profile/createavatar";

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ textAlign: "center" }}>
                    {isMobile && (
                        <IonButtons slot="start">
                            <IonBackButton text="" />
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <div style={{ marginTop: "5vh !important" }}>
                    <div className="onboarding-main-div">
                        <div className="onboarding-title">Choose your FitHero<br />avatar style</div>
                    </div>
                    <div>
                        <div className='onboarding-upload-photo' onClick={() => history.push(createAvatar)}>
                            <img src="assets/images/onboarding/create-ai-avatar.jpg" className="onboarding-add-photo-button" alt="create avatar with settings" />
                        </div>
                        <div style={{ marginBottom: "10px" }} className='onboarding-add-photo-button-div'>
                            Create FitHero Based on Settings
                        </div>
                    </div>
                    <div>
                        <div className='onboarding-upload-photo' onClick={() => history.push(uploadPhoto)}>
                            <img src="assets/images/onboarding/unknown-person.jpg" className="onboarding-add-photo-button" alt="create avatar with photo" />
                        </div>
                        <div className='onboarding-add-photo-button-div'>
                            Create FitHero Based on a Photo
                        </div>
                    </div>

                </div>
            </IonContent>
        </IonPage>

    )
}

export default UpdateAvatarPhotoOption