import { Device } from "@capacitor/device";
import { Keyboard } from "@capacitor/keyboard";
import {
	IonAlert,
	IonAvatar,
	IonButton,
	IonContent,
	IonFooter,
	IonInput,
	IonLoading,
	IonPage,
	IonRow
} from "@ionic/react";
import log from "loglevel";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import HeaderLogo from "../../components/HeaderLogo";
import GlobalState from "../../contexts/GlobalState";
import "../../css/Onboarding.css";
import { OnboardingObject, UserObject, userIDKey, userObjectKey } from "../../models/userModel";
import { logGenericEvent, mixpanelIdentifyUser } from "../../services/AnalyticsService";
import { sendSlackErrorNotification, sendSlackNotification } from "../../services/NotificationService";
import { StorageService } from "../../services/StorageService";
import { addUserToEmailList, getRandomUser, updateUser, validateInviteCode } from "../../services/UserService";

const storageService = new StorageService();

const Register = () => {
	const nextPage = "/onboarding/upgrade";
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { isMobile, updateUserState } = globalState;
	const [email, setEmail] = useState<string>();
	const [password, setPassword] = useState<string>();
	const [showFooter, setShowFooter] = useState<boolean>(true);
	const [generalError, setGeneralError] = useState<string>();
	const [allowFake, setAllowFake] = useState<boolean>(false);
	const [isFakeUser, setIsFakeUser] = useState(false);
	const [inviteCode, setInviteCode] = useState<string>();
	const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();

	const inviteCodeRef = useRef<any>(null);
	const passwordRef = useRef<any>(null);
	const emailRef = useRef<any>(null);

	const [emailError, setEmailError] = useState({
		visible: false,
		message: "",
	});

	const [inviteCodeError, setInviteCodeError] = useState({
		visible: false,
		message: "",
	});

	const [emailConfirmation, setEmailConfirmation] = useState({
		visible: false,
		message: "",
	});
	const [passwordError, setPasswordError] = useState({
		visible: false,
		message: "",
	});

	const [registering, setRegistering] = useState({
		visible: false,
		message: "",
	});

	const history = useHistory();

	useEffect(() => {
		updateOnboardingObject();
	}, []);

	if (isMobile !== false) {
		// get user's platform
		Device.getInfo().then((device) => {
			if (device.platform !== "android") {
				Keyboard.addListener("keyboardWillShow", (info) => {
					setShowFooter(false);
					document.body.classList.add("keyboard-is-open");
					document.documentElement.style.setProperty("--keyboard-height", -info.keyboardHeight + "px");
				}).catch((err) => {
					// log.debug("Keyboard Error: ", err);
				});
				Keyboard.addListener("keyboardDidShow", (info) => {
					document?.activeElement?.scrollIntoView({ behavior: "smooth" });
					document.documentElement.style.setProperty("--keyboard-height", -info.keyboardHeight + "px");
				}).catch((err) => {
					// log.debug("Keyboard Error: ", err);
				});

				Keyboard.addListener("keyboardDidHide", () => {
					setShowFooter(true);
				}).catch((err) => { });
			} else {
				console.log("[Keyboard test] Android device");
			}
		});
	}

	async function updateOnboardingObject() {
		let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding"); // Should this just include the full heroObject?
		tempOnboardingObject.url = window.location.pathname;
		tempOnboardingObject.completed = false;
		log.debug("tempOnboardingObject", tempOnboardingObject);
		setOnboardingObject(tempOnboardingObject);
		let host = window.location.hostname;
		let device = await Device.getInfo();
		if (host.includes("localhost") && device.platform === "web") {
			setAllowFake(true);
		}
		await storageService.setObject("onboarding", tempOnboardingObject);
	}
	const validateDataFake = async () => {
		setIsFakeUser(true);
		let rando = await getRandomUser();
		log.debug(`rando: `, rando);
		setEmail(rando.results[0].email);
		setPassword("qqqqqqqq");
		// setInviteCode("alpha");
	};

	const validateEmail = (email: string) => {
		var mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		// log.debug("mailformat.test(email) :" + mailformat.test(email));
		if (!mailformat.test(email)) {
			setEmailError({
				visible: true,
				message: "Please enter a valid email",
			});
			return false;
		} else {
			setEmailError({
				visible: false,
				message: "",
			});
			return true;
		}
	};

	const validatePassword = (password: string) => {
		log.debug("Password: " + password);
		if (!password || password.length < 8) {
			setPasswordError({
				visible: true,
				message: "Password should be at least 8 characters",
			});
			return false;
		} else {
			setPasswordError({
				visible: false,
				message: "",
			});
			return true;
		}
	};

	const validateData = async () => {
		let validData = true;
		if (validData === true) {
			validData = validateEmail(emailRef.current.value);
			log.debug("Checking email");
		}
		if (validData === true) {
			validData = validatePassword(passwordRef.current.value);
			log.debug("Checking password");
		}
		// if (validData === true) {
		// 	validData = await validateCode(inviteCodeRef.current.value);
		// 	log.debug("Checking invite code");
		// }
		log.debug("Valid Data: " + validData);
		if (validData === false) {
			return false;
		} else {
			setEmailConfirmation({
				visible: true,
				message: "You entered your email as: " + emailRef.current.value,
			});
		}
	};

	const confirmEmail = async () => {
		setRegistering({ visible: true, message: "Creating your account..." });
		let device = await Device.getInfo();
		await updateUser({
			email: emailRef.current.value,
			password: passwordRef.current.value,
			username: emailRef.current.value,
		}).then(async (user: UserObject | null) => {
			if (user !== null) {
				log.debug("User returned by createUser: ", user);
				setGeneralError("");
				await storageService.setObject(userObjectKey, user);
				logGenericEvent("signup");
				setRegistering({ visible: false, message: "" });
				// await updateUserState();
				if (isFakeUser !== true) {
					sendSlackNotification("*User Registered*\nUser: \`\`\`" + JSON.stringify(user) + "\`\`\`\nPlatform: " + device.platform, "creation");
					addUserToEmailList(emailRef.current.value, user.firstName);
				}
				history.push(nextPage);
			} else {
				sendSlackErrorNotification("Error: User not created. Update User returned null ", "[Register]", "User Onboarding Object: " + JSON.stringify(onboardingObject));
				setGeneralError("Error creating user");
				throw new Error("Error creating user");
			}
			setRegistering({ visible: false, message: "" });
		}).catch((error) => {
			sendSlackErrorNotification("Error: User not created. Update User returned null ", "[Register]", "User Onboarding Object: " + JSON.stringify(onboardingObject));
			setGeneralError(error.message);
			setRegistering({ visible: false, message: "" });
		});
	};

	async function validateCode(inviteCode: string) {
		let userId = await storageService.getItem(userIDKey);
		let inviteCodeResult = await validateInviteCode(inviteCode, userId).catch(async (err) => {
			log.debug("Error validating invite code: ", err);
			setInviteCodeError({
				visible: true,
				message: "Invalid invite code",
			});
		});

		log.debug("inviteCode: " + inviteCode);
		if (!inviteCode) {
			setInviteCodeError({
				visible: true,
				message: "Please enter your invite code",
			});
			return false;
		} else if (!inviteCodeResult) {
			setInviteCodeError({
				visible: true,
				message: "Invalid invite code",
			});
			return false;
		} else {
			setInviteCodeError({
				visible: false,
				message: "",
			});
			return true;
		}
	}

	return (
		<IonPage>
			<HeaderLogo checkforUser={false} />
			<IonLoading isOpen={registering.visible} message={registering.message} backdropDismiss />
			<IonContent>
				<IonAlert
					isOpen={!!emailConfirmation.visible}
					header="Is this correct?"
					message={emailConfirmation.message}
					buttons={[
						{
							text: "No",
							role: "cancel",
							handler: () => {
								setEmailConfirmation({
									visible: false,
									message: "",
								});
							},
						},
						{
							text: "Yes",
							role: "ok",
							handler: () => confirmEmail(),
						},
					]}
					onDidDismiss={() =>
						setEmailConfirmation({
							visible: false,
							message: "",
						})
					}
				/>
				<div className="onboarding-main-div">
					<div className="villain-chat-container">
						<IonRow style={{ justifyContent: "center" }}>
							<div className="chat-bubble-onboarding">
								<b>{onboardingObject?.firstName}</b> create your account so you don't lose your progress!
							</div>
						</IonRow>
					</div>
					<IonAvatar style={{ margin: "auto", height: "250px", width: "250px" }}>
						<img
							className="user-avatar"
							style={{ width: "100%", height: "100%", objectFit: "cover" }}
							src={onboardingObject?.heroPicture ?? "/assets/images/mystery-avatar.png"}
							alt="your fithero avatar"
						/>
					</IonAvatar>
					<div className="onboarding-text-area">
						<div className="onboarding-username-box-div">
							<div className="onboarding-subtitle">Email address</div>
							{email ? (
								<IonInput
									className="onboarding-email-box"
									ref={emailRef}
									name="email"
									value={email}
									type={"email"}
									enterkeyhint={"next"}
									onFocus={() => setEmailError({ visible: false, message: "" })}
									onKeyUp={(event) => {
										if (event.key === "Enter") {
											if (passwordRef.current) {
												passwordRef.current.setFocus();
											}
										}
									}}
									required
								></IonInput>
							) : (
								<IonInput
									className="onboarding-email-box"
									ref={emailRef}
									name="email"
									type={"email"}
									enterkeyhint={"next"}
									onFocus={() => setEmailError({ visible: false, message: "" })}
									onKeyUp={(event) => {
										if (event.key === "Enter") {
											if (passwordRef.current) {
												passwordRef.current.setFocus();
											}
										}
									}}
									required
								></IonInput>
							)}
							{emailError.visible === true ? <div className="onboarding-error-message">{emailError.message}</div> : ""}
						</div>
						<div className="onboarding-username-box-div">
							<div className="onboarding-subtitle">Password</div>
							{password ? (
								<IonInput
									className="onboarding-email-box"
									ref={passwordRef}
									name="password"
									value={password}
									type={"password"}
									placeholder="(min. 8 characters)"
									minlength={8}
									enterkeyhint={"next"}
									onFocus={() => setPasswordError({ visible: false, message: "" })}
									onKeyUp={(event) => {
										if (event.key === "Enter") {
											if (inviteCodeRef.current) {
												inviteCodeRef.current.setFocus();
											}
										}
									}}
									required
								></IonInput>
							) : (
								<IonInput
									className="onboarding-email-box"
									ref={passwordRef}
									name="password"
									type={"password"}
									placeholder="(min. 8 characters)"
									minlength={8}
									enterkeyhint={"next"}
									onFocus={() => setPasswordError({ visible: false, message: "" })}
									onKeyUp={(event) => {
										if (event.key === "Enter") {
											if (inviteCodeRef.current) {
												inviteCodeRef.current.setFocus();
											}
										}
									}}
									required
								></IonInput>
							)}
							{passwordError.visible === true ? <div className="onboarding-error-message">{passwordError.message}</div> : ""}
						</div>
						{/* <div className="onboarding-username-box-div">
							<div className="onboarding-subtitle">Invite code</div>
							{inviteCode ? (
								<IonInput
									className="onboarding-email-box"
									ref={inviteCodeRef}
									name="inviteCode"
									value={inviteCode}
									type={"text"}
									placeholder="Enter your invite code"
									enterkeyhint={"enter"}
									onFocus={() => setInviteCodeError({ visible: false, message: "" })}
									onKeyUp={(event) => {
										if (event.key === "Enter") {
											validateData();
										}
									}}
									required
								></IonInput>
							) : (
								<IonInput
									className="onboarding-email-box"
									ref={inviteCodeRef}
									name="inviteCode"
									type={"text"}
									placeholder="Enter your invite code"
									enterkeyhint="enter"
									onFocus={() => setInviteCodeError({ visible: false, message: "" })}
									onKeyUp={(event) => {
										if (event.key === "Enter") {
											validateData();
										}
									}}
									required
								></IonInput>
							)}
							{inviteCodeError.visible === true ? <div className="onboarding-error-message">{inviteCodeError.message}</div> : ""}
						</div> */}
					</div>
					<div className="onboarding-general-error">{generalError && <>{generalError}</>}</div>
					<div className="onboarding-footer"></div>
				</div>
			</IonContent>

			{showFooter === true ? (
				<>
					<IonFooter>
						<div style={{ textAlign: "center" }}>
							<IonButton onClick={validateData} className="onboarding-advance-button">
								Create Account
							</IonButton>
							{allowFake === true ? (
								<IonButton onClick={validateDataFake} className="onboarding-advance-button">
									Register fake
								</IonButton>
							) : (
								""
							)}
						</div>
						<div className="onboarding-form">
							<div className="onboarding-disclaimer">
								By tapping to continue, you are indicating that you agree with our{" "}
								<a href="https://www.fithero.ai/privacy-policy" target="_new">
									Privacy Policy
								</a>{" "}
								and{" "}
								<a href="https://www.fithero.ai/terms-of-use" target="_new">
									Terms of Service
								</a>
							</div>
						</div>
					</IonFooter>
				</>
			) : (
				""
			)}
		</IonPage>
	);
};

export default Register;
