import { IonAlert, IonButton, IonButtons, IonCol, IonContent, IonFooter, IonHeader, IonIcon, IonLoading, IonModal, IonRow, IonSpinner, IonToolbar } from "@ionic/react";
import { informationCircleOutline } from "ionicons/icons";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import GlobalState from "../../../contexts/GlobalState";
import { DuelStatus } from "../../../models/duelsModels";
import { OnboardingObject } from "../../../models/userModel";
import { Villain } from "../../../models/villainModels";
import { logGenericEventWithObject } from "../../../services/AnalyticsService";
import { LikelyWinner, calcDifficultyColor, calcDuelPointsFromDifficulty, calculateDuelSuccessPercent, returnDifficultyText, startDuel } from "../../../services/DuelsServics";
import { StorageService } from "../../../services/StorageService";
import { getVillain, getVillainFromDuelStatus, scaleVillainBasic } from "../../../services/VillainServices";
import VillainAttributesCircularComponent from "./VillainAttributesCircularComponent";
import { VillainWeakness } from "./VillainWeakness";
import { formatTimeHours } from "../../../services/HelperService";
import { VillainHoursComponent } from "./VillainHoursComponent";
import { PayWallClassDojoComponent } from "../../../Paywall/components/PayWallClassDojoComponent";
const storageService = new StorageService();

const VillainProfileMidDuel = (props: {
	duelStatus?: DuelStatus;
	villain?: Villain;
	setShowModal: React.Dispatch<React.SetStateAction<any>>;
}) => {
	// onboardingObject is only passed if the user is in the onboarding process
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [loading, setLoading] = useState(false);
	const [likelyWinner, setLikelyWinner] = useState<LikelyWinner>();
	const [villain, setVillain] = useState<Villain | undefined>();
	const [difficultyColor, setDifficultyColor] = useState("");
	const [showDifficultyModal, setShowDifficultyModal] = useState(false)
	const [showDurationModal, setShowDurationModal] = useState(false)
	const [showDuelPointsModal, setShowDuelPointsModal] = useState(false)
	const [duelPoints, setDuelPoints] = useState<number>();
	const [showPaywall, setShowPaywall] = useState(false);

	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { updateUserState } = globalState;

	useEffect(() => {
		async function setupData() {
			if (props.duelStatus) {
				let tempVillain = await getVillainFromDuelStatus(props.duelStatus)
				logGenericEventWithObject(`view villain profile mid duel - ${tempVillain?.name}`, tempVillain!);
				setVillain(tempVillain);
				setDifficultyColor(calcDifficultyColor(props.duelStatus.difficulty!))
			} else if (props.villain) {
				setVillain(props.villain);
			}
		}
		setupData();
		calcDuelPoints();
	}, []);

	async function calcDuelPoints() {
		setDuelPoints(await calcDuelPointsFromDifficulty(props.duelStatus?.difficulty!))
	}

	return (
		<>
			<IonModal className="villain-difficulty-modal" isOpen={showDifficultyModal} onDidDismiss={() => setShowDifficultyModal(false)}>
				<div className="villain-difficulty-modal-div">
					<div className="villian-difficulty-box" style={{ background: `${difficultyColor}` }}>
						<span className="villian-header-2">difficulty</span><br />{props.duelStatus?.difficulty}
					</div>
					{returnDifficultyText(props.duelStatus?.difficulty!)}
				</div>
			</IonModal>
			<IonModal className="villain-difficulty-modal" isOpen={showDuelPointsModal} onDidDismiss={() => setShowDuelPointsModal(false)}>
				<div className="villain-difficulty-modal-div">
					<div className="villian-difficulty-box" style={{ background: `${difficultyColor}` }}>
						<span className="villian-header-2">Duel Points</span><br />{duelPoints}
					</div>
					How many duel points you will earn for beating this villain.<br /><br />Duel points are used to calculate the leaderboard.
				</div>
			</IonModal>

			<IonModal className="paywall-modal" isOpen={showPaywall} onDidDismiss={() => setShowPaywall(false)}>
				<PayWallClassDojoComponent modal={true} setShowPaywall={setShowPaywall} />
			</IonModal>
			{villain && (
				<>
					<IonHeader style={{ "--background": "var(--ion-background-color, #fff)" }}>
						<IonToolbar>
							<IonButtons slot="end">
								<IonButton strong={true} onClick={() => props.setShowModal({ show: false })}>
									X
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonLoading isOpen={loading} message={"Starting Duel..."} />
					<IonContent>
						<div className="villian-profile-image">
							<img className="villain-avatar" style={{ border: `10px solid ${difficultyColor}` }} src={props.duelStatus?.villainImageURL} />
						</div>
						<div className="villian-profile-name">{villain?.name}</div>
						{villain && (
							<>
								<div className="villain-level-div">
									Level {villain?.level}
								</div>
								<VillainHoursComponent villain={villain} />
								<div className="villain-prediction-div" style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
									<div className="villain-boxes" onClick={() => setShowDuelPointsModal(true)} style={{ borderColor: `${difficultyColor}`, borderWidth: "3px", borderStyle: "solid" }}>
										<span className="villian-header-2">Duel Points <IonIcon icon={informationCircleOutline} style={{ fontSize: "12px" }} /> </span><br />{duelPoints}
									</div>
									<div className="villain-boxes" onClick={() => setShowDifficultyModal(true)} style={{ borderColor: `${difficultyColor}`, borderWidth: "3px", borderStyle: "solid" }}>
										<span className="villian-header-2">Difficulty <IonIcon icon={informationCircleOutline} style={{ fontSize: "12px" }} /></span><br />{props.duelStatus?.difficulty}
									</div>
								</div>
								<div>
									<VillainAttributesCircularComponent villain={villain} showLoading={likelyWinner ? false : true} />{" "}
								</div>
							</>
						)}
						<div>
							<VillainWeakness villain={villain} setShowPaywall={setShowPaywall} />
						</div>
						<div>
							<LanguageLevelBar villain={villain} />
						</div>
						<div>
							<VillainDescription villain={villain} />
						</div>
					</IonContent>
				</>
			)}
		</>
	);
};

export default VillainProfileMidDuel;

const VillainDescription = (props: { villain: Villain }) => {
	const [isExpanded, setIsExpanded] = useState(false);
	if (!props.villain?.description) {
		return <></>;
	}
	const descriptionLines = props.villain?.description.split("\\n");
	const displayLines = isExpanded ? descriptionLines : [descriptionLines[0]];

	return (
		<div className="villain-profile-description">
			{displayLines.map((line, index) => (
				<React.Fragment key={index}>
					{line}
					<br />
				</React.Fragment>
			))}
			{descriptionLines.length > 1 &&
				(!isExpanded ? (
					<div className="read-more" onClick={() => setIsExpanded(true)}>
						Read More{" "}
					</div>
				) : (
					<div className="read-more" onClick={() => setIsExpanded(false)}>
						Read Less 🔼
					</div>
				))}
		</div>
	);
};

const barColors = [
	"green", // Clean
	"green", // Mild
	"yellow", // Moderate
	"red", // Strong
	"darkred", // Extreme
];

const barLabels = ["Clean", "Mild", "Moderate", "Strong", "Extreme"];

const LanguageLevelBar = (props: { villain: Villain }) => {
	let progress;
	let color;
	switch (props.villain.languageLevel) {
		case 1:
			progress = 10;
			color = "white";
			break;
		case 2:
			progress = 30;
			color = "green";
			break;
		case 3:
			progress = 50;
			color = "yellow";
			break;
		case 4:
			progress = 70;
			color = "orange";
			break;
		case 5:
			progress = 100;
			color = "red";
			break;
		default:
			progress = 0;
			color = "white";
			break;
	}
	const barStyle = {
		background: `${color}`,
		width: `${progress}%`,
		height: "20px",
		borderRadius: `20px`,
	};

	const labelStyle = {
		left: `${progress}%`,
	};

	return (
		<div className="language-bar-container">
			<div className="language-level-header">Language Level</div>
			<div className="language-bar">
				<div style={barStyle}></div>
			</div>
			<div className="label-container">
				<IonRow>
					{barLabels.map((label, index) => {
						let leftMargin = (index / 5) * 100;
						return (
							<IonCol key={`${index}`} size="2.4">
								{label}
							</IonCol>
						);
					})}
				</IonRow>
			</div>
		</div>
	);
};

const VillainStats = (props: { villain: Villain | undefined }) => {
	return (
		<>
			<div className="villian-profile-stats">
				<div className="villian-profile-stat">
					<div className="villian-profile-stat-label">To-Hit</div>
					<div className="villian-profile-stat-value">{props.villain?.toHit}</div>
				</div>
				<div className="villian-profile-stat">
					<div className="villian-profile-stat-label">Dodge</div>
					<div className="villian-profile-stat-value">{props.villain?.dodge}</div>
				</div>
				<div className="villian-profile-stat">
					<div className="villian-profile-stat-label">Damage</div>
					<div className="villian-profile-stat-value">{props.villain?.damage}</div>
				</div>
				<div className="villian-profile-stat">
					<div className="villian-profile-stat-label">Health</div>
					<div className="villian-profile-stat-value">{props.villain?.health}</div>
				</div>
			</div>
		</>
	);
};
