/* eslint-disable react-hooks/rules-of-hooks */
import { IonCol, IonRow } from "@ionic/react";
import log from "loglevel";
import { useEffect, useState } from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { cardioColor, mindfulnessColor, mobilityColor, strengthColor } from "../constants/theme";
import "../css/Badge.css";
import "../css/HeroHealthComponent.css";
import {
    HeroStats,
    pointsPerLevel
} from "../models/exerciseModels";

export default function AthleteCircularComponent(props: { stats: HeroStats }) {
    const [progressBar, setProgressBar] = useState<HeroStats | undefined>(props.stats);

    useEffect(() => {
        if (props.stats) {
            setProgressBar({
                strengthLevel: props.stats.strengthLevel,
                strengthPoints: props.stats.strengthPoints,
                cardioLevel: props.stats.cardioLevel,
                cardioPoints: props.stats.cardioPoints,
                mindfulnessLevel: props.stats.mindfulnessLevel,
                mindfulnessPoints: props.stats.mindfulnessPoints,
                mobilityLevel: props.stats.mobilityLevel,
                mobilityPoints: props.stats.mobilityPoints,
                overallLevel: props.stats.overallLevel,
                totalPoints: props.stats.totalPoints,
            });
        } else {
            log.debug(`No hero stats & no previous hero stats`);
        }
    }, [props.stats]);

    const attributes = ["strength", "cardio", "mobility", "mindfulness"] as const;
    type AttributeType = (typeof attributes)[number];

    return (
        <>
            {props.stats && progressBar ? (
                <div className="progress-bar-container">
                    <IonRow className="attribute-container">
                        {attributes.map((attribute: AttributeType) => {
                            let currentPoints = progressBar[`${attribute}Points` as keyof HeroStats];
                            let goalPoints = (progressBar[`${attribute}Level` as keyof HeroStats]) * pointsPerLevel[attribute];
                            let percentage = (currentPoints / goalPoints) * 100;
                            let level = progressBar[`${attribute}Level` as keyof HeroStats];
                            let barColor = "";
                            switch (attribute) {
                                case "strength":
                                    barColor = `${strengthColor}`;
                                    break;
                                case "cardio":
                                    barColor = `${cardioColor}`;
                                    break;
                                case "mobility":
                                    barColor = `${mobilityColor}`;
                                    break;
                                case "mindfulness":
                                    barColor = `${mindfulnessColor}`;
                                    break;
                            }
                            return (
                                <IonCol key={`${attribute}`} size="2.5" className="circular-progress-col">
                                    <div className="attribute-title">{attribute}</div>
                                    <CircularProgressbarWithChildren
                                        styles={buildStyles({
                                            pathColor: `${barColor}`,
                                            textColor: "white",
                                            textSize: "30px",
                                        })}
                                        value={percentage}
                                    >
                                        <div style={{ fontSize: "3vh" }}>{level}</div>
                                        <div style={{ fontSize: "1vh" }}>Level</div>
                                    </CircularProgressbarWithChildren>
                                </IonCol>
                            );
                        })}
                    </IonRow>
                </div>
            ) : (
                ""
            )}
        </>
    );
}
