import React, { useState, useEffect } from 'react';
import { getLastDuelMessage } from '../services/MessageService';
import "../css/Celebrations.css";
import "../css/Duels.css";
import { IonRow } from '@ionic/react';

// ChatBubble component that displays the image and the chat bubble with text
const LastMessage = (props: { villainImage: string, duelId: string }) => {
    const [chatText, setChatText] = useState('');

    useEffect(() => {
        // Function to fetch text from API
        const getChatText = async () => {
            try {
                const text = await getLastDuelMessage(props.duelId); // Assuming fetchChatText is a function that fetches the text
                setChatText(text);
            } catch (error) {
                console.error('Failed to fetch chat text:', error);
                // Handle error state appropriately, perhaps setting a default message
                setChatText('...');
            }
        };

        getChatText();
    }, [chatText]);

    // Component render
    return (
        <div className="villain-chat-container">
            <IonRow style={{ justifyContent: "center" }}><div className="chat-bubble">{chatText}</div></IonRow>
            <IonRow style={{ justifyContent: "center" }}><img src={props.villainImage} alt="Villain" className="villain-avatar-rounded" style={{ width: "100px", height: "100px" }} /></IonRow>
        </div>
    );
};

export default LastMessage;

