import { LocalNotifications } from "@capacitor/local-notifications";
// import { PushNotifications } from "@capacitor/push-notifications";
import log from "loglevel";
import { getFullDeviceInfo } from "./HelperService";
import { StorageService } from "./StorageService";
import { getHero, getUser } from "./UserService";

const storageService = new StorageService();


const androidPayloadNotificationActionPerformed = {
    "actionId": "tap",
    "notification": {
        "id": "0:1610323071768781%2155dd7c2155dd7c",
        "data": {
            "google.delivered_priority": "high",
            "google.sent_time": "1610323071754",
            "google.ttl": "2419200",
            "google.original_priority": "high",
            // is escaped
            "payload": {
                "timeStamp": "2021-01-10T23:57:51.345Z",
                "createdAt": "2021-01-10T23:57:51.345Z",
                "deleted": false,
                "payload": "",
                "author": "b2b3ca3d-e22e-458d-bb2e-f99631646657",
                "reactions": [],
                "id": "827a2e74-aa18-4b3c-a371-a9d26a1b8564",
                "message": "qqqqqqq\\n",
                "type": "text",
                "reactionCount": 0,
                "chatRoom": "5631b270-2d29-431e-9e9f-f77d9b73fc5b",
                "updatedAt": "2021-01-10T23:57:51.345Z"
            },
            "from": "400305806374",
            "collapse_key": "fitsquad.app"
        }
    }
}

const convertNotification = async (notificationList) => {
    let converetedNotificationList = []
    for (let notification of notificationList) {
        let tempNotification = {
            "id": 0,
            "title": "Riggz // Test Chat",
            "body": "rrrr\n",
            "groupSummary": false,
            "data": {
                "android.title": "Riggz // Test Chat",
                "android.reduced.images": true,
                "android.template": "android.app.Notification$BigTextStyle",
                "android.showChronometer": false,
                "android.text": "rrrr\n", "android.progress": 0,
                "android.progressMax": 0,
                "android.appInfo": "ApplicationInfo{b45318 fitsquad.app}",
                "android.showWhen": true,
                "android.bigText": "rrrr\n",
                "android.progressIndeterminate": false
            }
        }
    }
}

// export const storeDeliveredNotifications = async () => {
//     log.debug("Processing notifications");
//     let notificationList = await PushNotifications.getDeliveredNotifications()
//     //let converetedNotificationList = await convertNotification(notificationList)
//     let existingNotifications = await storageService.getObject("deliveredNotifications")
//     let updatedNotificationList = {}
//     if (existingNotifications === null ){
//         updatedNotificationList = notificationList
//     } else {
//         updatedNotificationList = {
//             ...existingNotifications,
//             ...notificationList
//         }
//     }
//     log.debug("Notification list as object: ",{updatedNotificationList});
//     log.debug("Notification list as stringfy: "+ JSON.stringify(updatedNotificationList));
//     //log.debug("Notification list as parse + stringfy: "+ JSON.stringify(JSON.parse(updatedNotificationList)));
//     await storageService.setObject("deliveredNotifications", updatedNotificationList)
//     let temp = await storageService.getObject("deliveredNotifications")
//     log.debug("temp list as object: ",{temp});
//     log.debug("temp list as stringfy: "+ JSON.stringify(temp));
//     //log.debug("temp list as parse + stringfy: "+ JSON.stringify(JSON.parse(temp)));
// }

const androidNotificationReceived = {
    "id": "0:1610323731505430%2155dd7c2155dd7c",
    "data": {
        // Needs to be parsed... comes in escaped
        "payload": {
            "timeStamp": "2021-01-11T00:08:50.970Z",
            "createdAt": "2021-01-11T00:08:50.970Z",
            "deleted": false,
            "payload": "",
            "author": "b2b3ca3d-e22e-458d-bb2e-f99631646657",
            "reactions": [],
            "id": "33499f2f-8b63-4003-8cb6-830f4009ac95",
            "message": "app open\\n",
            "type": "text",
            "reactionCount": 0,
            "chatRoom": "5631b270-2d29-431e-9e9f-f77d9b73fc5b",
            "updatedAt": "2021-01-11T00:08:50.970Z"
        }
    },
    "title": "Riggz // Test Chat",
    "body": "app open\n"
}

const androidNotificationStored = {
    "notifications": [{
        "id": 0,
        "title": "Riggz // Test Chat",
        "body": "rrrr\n",
        "groupSummary": false,
        "data": {
            "android.title": "Riggz // Test Chat",
            "android.reduced.images": true,
            "android.template": "android.app.Notification$BigTextStyle",
            "android.showChronometer": false,
            "android.text": "rrrr\n", "android.progress": 0,
            "android.progressMax": 0,
            "android.appInfo": "ApplicationInfo{b45318 fitsquad.app}",
            "android.showWhen": true,
            "android.bigText": "rrrr\n",
            "android.progressIndeterminate": false
        }
    }]
}



const notificationObject = {
    "notifications": [{
        "id": 0,
        "title": "Riggz // Test Chat",
        "body": "rrrr\n",
        "data": {
            "timeStamp": "",
            "author": "",
            "chatRoom": "",
            "deleted": "",
            "type": "",
            "reactions": "",
            "reactionCount": "",
        }
    }]

}


// export const storeNotification = async (notificationObject) => {
//     log.debug("Storing notification");

//     let notificationList = await PushNotifications.getDeliveredNotifications()
//     let existingNotifications = await storageService.getObject("deliveredNotifications")
//     let updatedNotificationList = {}
//     if (existingNotifications === null ){
//         updatedNotificationList = notificationList
//     } else {
//         updatedNotificationList = {
//             ...existingNotifications,
//             ...notificationList
//         }
//     }
//     log.debug("Notification list as object: ",{updatedNotificationList});
//     log.debug("Notification list as stringfy: "+ JSON.stringify(updatedNotificationList));
//     //log.debug("Notification list as parse + stringfy: "+ JSON.stringify(JSON.parse(updatedNotificationList)));
//     await storageService.setObject("deliveredNotifications", updatedNotificationList)
//     let temp = await storageService.getObject("deliveredNotifications")
//     log.debug("temp list as object: ",{temp});
//     log.debug("temp list as stringfy: "+ JSON.stringify(temp));
//     //log.debug("temp list as parse + stringfy: "+ JSON.stringify(JSON.parse(temp)));
// }

export const registerActionTypes = async () => {
    LocalNotifications.registerActionTypes({
        // I think these are randomly set by the server
        types: [
            {
                id: "workout-5",
                actions: [
                    {
                        id: ":+1:",
                        title: "👍",
                        destructive: true,
                    },
                    {
                        id: ":muscle:",
                        title: "💪",
                        destructive: true,
                    },
                    {
                        id: ":poop:",
                        title: "💩",
                        destructive: true,
                    },
                ],
            }, {
                id: "workout-1",
                actions: [
                    {
                        id: ":grinning:",
                        title: "😀",
                        destructive: true,
                    },
                    {
                        id: ":punch:",
                        title: "👊",
                        destructive: true,
                    },
                    {
                        id: ":eggplant:",
                        title: "🍆",
                        destructive: true,
                    },
                ],
            },
            {
                id: "workout-2",
                actions: [
                    {
                        id: ":raised_hands:",
                        title: "🙌",
                        destructive: true,
                    },
                    {
                        id: ":sign_of_the_horns:",
                        title: "🤘",
                        destructive: true,
                    },
                    {
                        id: ":thinking_face:",
                        title: "🤔",
                        destructive: true,
                    },
                ],
            },
            {
                id: "workout-3",
                actions: [
                    {
                        id: ":ok_hand:",
                        title: "👌",
                        destructive: true,
                    },
                    {
                        id: ":boom:",
                        title: "💥",
                        destructive: true,
                    },
                    {
                        id: ":beer:",
                        title: "🍺",
                        destructive: true,
                    },
                ],
            },
            {
                id: "workout-4",
                actions: [
                    {
                        id: ":clap:",
                        title: "👏",
                        destructive: true,
                    },
                    {
                        id: ":100:",
                        title: "💯",
                        destructive: true,
                    },
                    {
                        id: ":face_vomiting:",
                        title: "🤮",
                        destructive: true,
                    },
                ],
            },
        ],
    });
}

export const setupNotificationListener = async (history) => {
    LocalNotifications.removeAllListeners();
    // PushNotifications.removeAllListeners();
    await registerActionTypes();


    /**
     * This is triggered when the app is open on Android and the user taps a notification
     */

    LocalNotifications.addListener("localNotificationReceived", async (notification) => {
        log.debug("localNotificationReceived: " + JSON.stringify(notification));

    })

    LocalNotifications.addListener("localNotificationActionPerformed", async (action) => {
        let deviceInfo = await getFullDeviceInfo();
        // if (deviceInfo.platform === "android") {
        log.debug("Action performed: " + JSON.stringify(action));
        if (action.actionId === "tap") {
            if (action.notification.extra) {
                log.debug("Data payload: " + JSON.stringify(action.notification.extra));
                if (action.notification.extra.activityData) { // it has activityData property
                    // if(action.notification.extra.activityData.payload?.activityData?.dataType === "seasonRegistration"){
                    //     window.open("http://fitsquadapp.com/products", "_new");
                    // }
                } else if (action.notification.extra.payload.chatRoom) {
                    // log.debug("ChatRoom: " + action.notification.extra.payload.chatRoom);   
                    // log.debug("History: " + JSON.stringify(history));   
                    // let squadID = await getSquadIDFromChatRoomID(action.notification.extra.payload.chatRoom)
                    // if (squadID !== null){
                    //     window.open("/squadhome/" + squadID);
                    // }                            
                }
            }
        } else if (action.actionId === "free_text") {
            // // log.debug("squadID: " + action.notification.extra.activityData.squadId);

            // let chatRoomID = await getChatRoomIDFromSquadID(action.notification.extra.activityData.squadId);
            // // log.debug("ChatRoomID: " + chatRoomID);
            // postChatRoomMessage(chatRoomID, action.inputValue);
            // For posting a free text response to a chat
        } else {
            // let response = await addEmoji(action.notification.id, action.actionId, "activity", action.notification.extra.activityData.squadId);
            // log.debug("Response from addEmoji: " + response);
        }
        // }
    });


    // /**
    //  * 
    //  * Converts Pushes to Locals for Android
    //  * pushNotificationReceived doesn't fire for iOS
    //  * 
    //  */
    // PushNotifications.addListener("pushNotificationReceived", async (notification) => {
    //     let deviceInfo = await getFullDeviceInfo();
    //     log.debug("DeviceInfo: ", JSON.stringify(deviceInfo));
    //     log.debug("Notification: " + JSON.stringify(notification));
    //     if (deviceInfo.platform === "android") {
    //         let dataPayload;
    //         if (notification?.data?.payload) {
    //             dataPayload = await JSON.parse(notification.data.payload);
    //             log.debug("Notification  - dataPayload: " + JSON.stringify(dataPayload));
    //             if (dataPayload.payload?.activityData) { // for a workout
    //                 log.debug("Datatype: " + dataPayload["payload"]["activityData"]["dataType"]);
    //                 log.debug("Activity ID: " + dataPayload["payload"]["sourceActivityId"]);
    //                 let randResponse = Math.floor(Math.random() * Math.floor(4)) + 1;
    //                 let actTypeID = dataPayload["payload"]["activityData"]["dataType"] + "-" + randResponse;
    //                 log.debug("randResponse: " + actTypeID);
    //                 await LocalNotifications.schedule({
    //                     notifications: [
    //                         {
    //                             title: dataPayload["title"],
    //                             body: dataPayload["body"],
    //                             id: dataPayload["payload"]["id"],
    //                             schedule: {
    //                                 on: new Date(Date.now() + 5000),
    //                             },
    //                             channelId: "primary",
    //                             extra: {
    //                                 activityData: dataPayload,
    //                             },
    //                             actionTypeId: actTypeID,
    //                         },
    //                     ],
    //                 });
    //             } else {// it's a chat message
    //                 log.debug("ID: " + dataPayload["payload"]["id"]);                        
    //                 await LocalNotifications.schedule({
    //                     notifications: [
    //                         {
    //                             title: dataPayload["title"],
    //                             body: dataPayload["body"],
    //                             id: Date.now(),
    //                             schedule: {
    //                                 on: new Date(Date.now() + 5000),
    //                             },
    //                             channelId: "primary",
    //                             extra: {
    //                                 payload: dataPayload["payload"],
    //                             },
    //                             actionTypeId: null,
    //                         },
    //                     ],
    //                 });
    //             }
    //         } else {
    //             // this shouldn't happen
    //             log.debug("No data payload");
    //         }
    //     }
    //     log.debug("\n\nPUSH - pushNotificationReceived *NEW!*: " + JSON.stringify(notification));
    // });

    // /**
    //  * 
    //  * Android is converted to LocalNotifications when the app is open, but this works when it's closed
    //  * 
    //  */
    //  PushNotifications.addListener("pushNotificationActionPerformed", async (action) => {
    //     let deviceInfo = await getFullDeviceInfo();
    //     log.debug("DeviceInfo: "+ JSON.stringify(deviceInfo));
    //     if (deviceInfo.platform === "ios") {
    //         log.debug("\n\nPUSH - pushNotificationActionPerformed iOS: " + JSON.stringify(action));

    //         let destination
    //         if (action.notification.data.aps.alert.payload.chatRoom) { // if it has the chatRoom property, push to there   
    //             // let squadID = await getSquadIDFromChatRoomID(action.notification.data.aps.alert.payload.chatRoom)
    //             // if (squadID !== null){
    //             //     window.open("/squadhome/" + squadID);
    //             // }            
    //         } else if (action.notification.data.aps.alert.payload.activityData?.dataType === "workout") {
    //             if (action.actionId === "free_text") {
    //                 // For posting a free text response to a chat
    //                 // log.debug("squadID: " + action.notification.data.aps.alert.payload.squadId);
    //                 // let chatRoomID = await getChatRoomIDFromSquadID(action.notification.data.aps.alert.payload.squadId);                
    //                 // postChatRoomMessage(chatRoomID, action.inputValue);
    //             } else if (action.actionId === "tap") {
    //                 let squadID = action.notification.data.aps.alert.payload.squadId  
    //                 if (squadID !== null){
    //                     window.open("/squadhome/" + squadID);
    //                 }
    //             } else {
    //                 // await addEmoji(action.notification.data.aps.alert.payload.id, action.actionId, "activity", action.notification.data.aps.alert.payload.squadId);
    //             }
    //         } else if (action.notification.data.aps.alert.payload.activityData?.dataType === "seasonRegistration") {
    //             //destination = "/season-sales-page"
    //             window.open("http://fitsquadapp.com/products", "_new");
    //         } else {                
    //             if (action.notification.data.aps.alert.payload.squadId) { // this is for parsing whether it's a chatRoom or competitionChat
    //                 let squadID = action.notification.data.aps.alert.payload.squadId;
    //                 window.open("/squadhome/" + squadID);
    //             }
    //             log.debug("User is on iOS. Using that payload");
    //         }
    //     }
    //     if (deviceInfo.platform === "android") {
    //         log.debug("\n\nPUSH - pushNotificationActionPerformed Android: " + JSON.stringify(action));
    //         if (action.notification.data.payload) { // if it has the payload property
    //             let payload = JSON.parse(action.notification.data.payload); // parse it since
    //             log.debug("Data payload: " + JSON.stringify(payload.payload));
    //             log.debug("ChatRoom: " + payload.payload.chatRoom);
    //             if (payload.payload.chatRoom) {
    //                 // let squadID = await getSquadIDFromChatRoomID(payload.payload.chatRoom)
    //                 // if (squadID !== null){
    //                 //     window.open("/squadhome/" + squadID);
    //                 // }
    //             }
    //         }
    //     }
    // });
}

/**
 * 
 * Sends a notification to Slack. Possible channels:
 * default, creation, keyEvent, activity, love, messaging, error, feedback, purchase, challenges, notificationtesting
 * 
 * @param {*} text 
 * @param {"default", "creation", "keyEvent", "activity", "love", "messaging", "error", "feedback", "purchase", "challenges", "notificationtesting"} channel 
 */

export const sendSlackNotification = async (text, channel = "default", includeTesters = true) => {
    let url;
    switch (channel) {
        case "default":
            url = "https://hooks.slack.com/services/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"
            break;
        case "creation":
            url = "https://hooks.slack.com/services/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"
            break;
        case "keyEvent":
            url = "https://hooks.slack.com/services/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"
            break;
        case "activity":
            url = "https://hooks.slack.com/services/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"
            break;
        case "love":
            url = "https://hooks.slack.com/services/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"
            break;
        case "messaging":
            url = "https://hooks.slack.com/services/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"
            break;
        case "error":
            url = "https://hooks.slack.com/services/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"
            break;
        case "feedback":
            url = "https://hooks.slack.com/services/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"
            break;
        case "purchase":
            url = "https://hooks.slack.com/services/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"
            break;
        case "challenges":
            url = "https://hooks.slack.com/services/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"
            break;
        case "notificationtesting":
            url = "https://hooks.slack.com/services/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"
            break;
        case 'sidekickMessages':
            url = 'https://hooks.slack.com/services/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';
            break;
        case 'duelUpdates':
            url = 'https://hooks.slack.com/services/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';
            break;
        case 'newDuels':
            url = 'https://hooks.slack.com/services/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';
            break;
        default:
            url = "https://hooks.slack.com/services/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"
            break;
    }
    let data = {
        "text": text
    }
    //log.debug("Sending Slack Notification with text: "+text);
    Promise.resolve(
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
        })
    ).catch((error) => {
        log.debug("[sendSlackNotification] Error in API call: " + error);
        throw Error(error);
    });
}

/**
 * 
 * @param {string} error - text of error to display under "NEW ERROR" title
 * @param {*} source - text of the source of the error
 * @param {*} additionalData - any additional data to display. If it's to be printed in code use ```
 */

export const sendSlackErrorNotification = async (error, source, additionalData = "") => {
    let user = await getUser();
    if (!user.email) {
        user.email = "Email not found"
    }
    let hero = await getHero();
    if (!hero.heroName) {
        hero.heroName = "Name not found"
    }
    let url;
    url = "https://hooks.slack.com/services/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"
    let data = {
        "text": `*NEW ERROR*\n*Error:* ${error}\n*Source:* ${source}\n*User:*${user.email}\n*Hero:* ${hero.heroName}\n*Additional Data:* ${additionalData}`
    }
    //log.debug("Sending Slack Notification with text: "+text);
    Promise.resolve(
        fetch(url, {
            method: "POST",
            body: JSON.stringify(data),
        })
    ).catch((error) => {
        log.debug("[sendSlackNotification] Error in API call: " + error);
        throw Error(error);
    });
}

export const enableNotifications = async () => {
    let deviceInfo = await getFullDeviceInfo();
    if (deviceInfo.platform !== 'android') {
        await enableLocalNotifications()
        let body;
        let pushToken = await storageService.getItem("pushToken");
        if (pushToken !== "") {
            log.debug("Your pushToken: " + pushToken);
        } else {
            log.debug("No push token stored");
        }
        if (deviceInfo.platform === "web") {
            return
        }
        // //LocalNotifications.requestPermission();
        // PushNotifications.addListener("registration", async (token) => {
        //     storageService.setItem("pushToken", token.value);
        //     log.debug("Push Notification set to: "+token.value);
        //     const platformsArray = getPlatforms();
        //     log.debug("Platforms: ", { platformsArray });
        //     platformsArray.forEach((platform) => {
        //         if (platform === "ios") {
        //             body = {
        //                 deviceToken: token.value.toLowerCase(),
        //             };
        //             log.debug("User is on iOS. Using that payload");
        //         }
        //         if (platform === "android") {
        //             body = {
        //                 registrationId: token.value,
        //             };
        //             log.debug("User is on Android. Using that payload");
        //         }
        //     });
        //     // await updateUser(body);
        //     log.debug("User updated")
        //     await setupNotificationListener()
        //     return true
        // });

        // // Some issue with our setup and push will not work
        // PushNotifications.addListener("registrationError", (error) => {
        //     log.debug("PUSH - Error on registration: " + JSON.stringify(error));
        //     return false
        // });

        // let permResult = await PushNotifications.requestPermissions();
        // if (permResult.receive === "granted") {
        //     // Register with Apple / Google to receive push via APNS/FCM
        //     const result = await PushNotifications.register();
        //     log.debug("register result: " + JSON.stringify(result));
        // } else {
        //     log.debug("push notifications wont work");
        //     log.debug("permResult: " + JSON.stringify(permResult));
        //     //alert("Oops something happened. Try tapping the button again");
        //     return false
        // }
    }
};

// export const updateReply = async (updateID, bodyObject) =>{
//     logReactionGiven(":blue_heart:")
//     let response = await post("updates/"+updateID,bodyObject)
//     return response
// }

// export const areNotificationsEnabled = async () => {
//     let user = await getUser(null,false)
//     let pushToken = await storageService.getItem("pushToken");
//     if (user.pushIdentifiers.length > 0 && pushToken !== ""){
//         return true
//     } else {
//         return false
//     }
// }

// export const areNotificationsAuthorized = async () => {
//     let user = await getUser(null,false)
//     let pushToken = await storageService.getItem("pushToken");
//     if (user.pushIdentifiers.length > 0 || pushToken !== ""){ //if it's stored server side OR client-side then yes, authorized
//         return true
//     } else {
//         return false
//     }
// }


export const enableLocalNotifications = async () => {
    // log.debug("Enabling LOCAL notifications")
    let permResult = await LocalNotifications.requestPermissions();
    log.debug("LOCAL register result: " + JSON.stringify(permResult));
    if (permResult.display === "granted") {
    } else {
        log.debug("LOCAL notifications denied/wont work");
        // alert("Oops something happened. Try tapping the button again");
        return false
    }
}

export const clearPendingNotifications = async (challengeID) => {
    // log.debug("Clearing all notifications for challenge ID "+challengeID)
    let pending = await LocalNotifications.getPending();
    pending.notifications.forEach((element) => {
        // log.debug("Notification object: " + JSON.stringify(element));
        if (element.extra.challengeID && element.extra.challengeID === challengeID) {
            let result = LocalNotifications.cancel({
                notifications: [{ id: element.id }],
            });
            // log.debug("ID " + element.id + " cleared");
            // log.debug("Result: " + JSON.stringify(result)); 
        }
    });
};
