export const StrengthAccomplishmentSkillId = 'eWVkXTIqm6';
export const CardioAccomplishmentSkillId = 'UpMd58Hixl';
export const MobilityAccomplishmentSkillId = 'XjFvHAe9BU';
export const MindfulnessAccomplishmentSkillId = 'Tj2KacBrAv';

export const StrengthMiniIcon = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/6aa98011de93af1150b54aed700ba39c_strength-mini.png";
export const CardioMiniIcon = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/7a9e377b1b40c9bd30483679a859e584_cardio-mini-2.png";
export const MobilityMiniIcon = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/9cb242fdffcfb46083fb9c1e9ea0171a_mobility-mini-icon-2.png";
export const MindfulnessMiniIcon = "https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/4822142c0165dacf13a3e7a5be9f6591_mindfulness-mini-2.png";

export const allSkillsKey = 'allSkills';

export type Skill = {
    objectId: string;
    tree: string;
    subTree: string;
    level: number;
    name: string;
    description: string;
    target: string;
    operation: string;
    health?: string | number;
    damage?: string | number;
    chanceToHit?: string | number;
    dodge?: string | number;
    repeat: number;
    cooldown: number;
    complimentarySkillId?: string;
    visible: boolean;
    createdAt: string;
    updatedAt: string;
    icon?: {
        __type: string;
        name: string;
        url: string;
    };
    effectDescription?: string;
}

export type SkillInUse = {
    objectId: string;
    userId: string;
    skillId: string;
    createdAt: string;
    updatedAt: string;
    cooldown: number;
    repeat: number;
    effectDescription?: string;
    skillName?: string;
    skillIcon?: {
        __type: string;
        name: string;
        url: string;
    };
}

export type Specialization = {
    objectId: string;
    name: string;
    tree: string;
    description: string;
    createdAt: string;
    updatedAt: string;
}

export const StrengthSpecializations = [
    "Powerlifting",
    "BodyBuilding",
    "Functional"
]

export const CardioSpecializations = [
    "Endurance",
    "Sprinting",
    "Sports"
]

export type SkillModalType = {
    show: boolean,
    skill: Skill | null
    skillInUse?: SkillInUse
    skillDisabled?: boolean
}

export const genericIcon = {
    __type: "File",
    name: "generic.png",
    url: 'assets/images/kettlebell-swing-icon.png'
}

