import React, { useState } from 'react'
import DuelStatusInfo from './DuelStatusInfo'
import { DuelLedger, DuelStatus } from '../../../models/duelsModels'
import { concedeDuel, fetchDuelsLedger } from '../../../services/DuelsServics';
import DuelReportComponent from './DuelReportComponent';
import { IonAlert, IonButton, IonLoading } from '@ionic/react';
import { useHistory } from 'react-router';
import { TourComponent } from '../../Tour/StepsComponent';
import { DuelStatusTour } from '../../Tour/Steps';

export const DuelsStatus = (props: { duelStatus: DuelStatus, heroOdds: string, setShowModal: any, duelLedger: DuelLedger[] }) => {
    const [concedeAlert, setConcedeAlert] = useState(false);
    const [conceding, setConceding] = useState(false);
    const history = useHistory();

    async function concede(confirmed: boolean) {
        if (confirmed === false) {
            setConcedeAlert(true);
        } else {
            setConcedeAlert(false);
            setConceding(true);
            await concedeDuel(props.duelStatus.statusId);
            setConceding(false);
            history.push(`/dueldetails/${props.duelStatus.statusId}/chat`);
        }
    }
    return (
        <>
            <IonLoading isOpen={conceding} message={"Conceding your duel..."} backdropDismiss={true} />
            <IonAlert
                isOpen={concedeAlert}
                header={"Concede Duel"}
                message={"Are you sure you want to concede this duel?"}
                buttons={[
                    { text: "Cancel", role: "cancel" },
                    { text: "Concede", handler: () => concede(true) },
                ]}
            />
            <DuelStatusInfo duelStatus={props.duelStatus} heroOdds={props.heroOdds} setShowModal={props.setShowModal} />
            <DuelReportComponent duelLedger={props.duelLedger} duelStatus={props.duelStatus} />
            {props.duelStatus.complete !== true && (
                <div style={{ width: "60%", margin: "auto" }}>
                    <IonButton color={"danger"} expand='block' className="concede-tooltip" onClick={() => concede(false)}>Concede Duel</IonButton>
                </div>
            )}
        </>
    )
}
