import { IonButton, IonCol, IonContent, IonInput, IonItem, IonLabel, IonLoading, IonPage, IonRow } from "@ionic/react";
// import AWS from "aws-sdk";
import { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GlobalState } from "../contexts/GlobalState";
import "../css/Desktop.css";
import "../css/Register.css";
// import { initAnalytics, logLogin } from "../services/AnalyticsService";
import { StorageService } from "../services/StorageService";
// import { getCognitoUsernameFromUsername, getUser, removeBadgesFromUserObject, updateUser } from "../services/UserService";
import moment from "moment";
import { EmptyAvatarImage, UserObject, userObjectKey } from "../models/userModel";
import { getAndProcessBaselineWorkouts } from "../services/ExerciseService";
import { getHero, loginUser } from "../services/UserService";
import { Device } from "@capacitor/device";
import log from "loglevel";
// import log from "loglevel";

//import { FacebookLoginResponse } from "@rdlabo/capacitor-facebook-login";
//const { FacebookLogin } = Plugins;
const storageService = new StorageService();
// Todos:
// - Implement social sign in

const LoginScreen = () => {
	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { updateUserState, userState, updateHeroHealth, serialStartupFunction, isMobile, logout } = globalState;

	const loggingInMessage = "Logging you in";
	const [localHost, setLocalHost] = useState(false);
	const [loggingIn, setLoggingIn] = useState({
		visible: false,
		message: "",
	});

	const [emailError, setEmailError] = useState({
		visible: false,
		message: "",
	});
	const [passwordError, setPasswordError] = useState({
		visible: false,
		message: "",
	});

	const passwordRef = useRef<HTMLIonInputElement | null>(null);
	const emailRef = useRef<HTMLIonInputElement | null>(null);

	useEffect(() => {
		Device.getInfo().then((device) => {
			let host = window.location.hostname;
			log.debug("[Login] Host: ", host);
			// if host contains 192.168.1. or localhost, set localHost to true
			if (host.includes("localhost") && device.platform === "web") {
				setLocalHost(true);
			}
		});
	}, []);

	function handleKeyUpEmail(event: { keyCode: number }) {
		if (event.keyCode === 13) {
			if (passwordRef.current) {
				log.debug("Focusing on pw");
				// passwordRef.current.setFocus();
			}
		}
	}

	function handleKeyUpPassword(event: { keyCode: number }) {
		if (event.keyCode === 13) {
			log.debug("enter hit");
			validateData();
		}
	}

	const validatePassword = (password: any) => {
		if (!password || password.length < 8) {
			setPasswordError({
				visible: true,
				message: "Password should be at least 8 characters",
			});
			return false;
		} else {
			setPasswordError({
				visible: false,
				message: "",
			});
			return true;
		}
	};

	const validateData = async () => {
		await logout();
		setEmailError({
			visible: false,
			message: "",
		});
		setPasswordError({
			visible: false,
			message: "",
		});
		let validData = true;
		let response;

		// First make sure the values are valid
		//validData = validateEmail(emailRef.current.value);
		validData = validatePassword(passwordRef.current ? passwordRef.current.value : null);
		// log.debug("Valid Data: " + validData);
		// ensure passwordRef && emailRef are not null
		if (!passwordRef.current || !emailRef.current) {
			validData = false;
		}
		if (validData === false) {
			return false;
		}
		setLoggingIn({ visible: true, message: loggingInMessage });
		await loginUser(emailRef.current ? emailRef.current.value : "", passwordRef.current ? passwordRef.current.value : "")
			.then(async (response: any) => {
				log.debug("Login Response: ", response);
				if (response) {
					response.email = emailRef.current ? emailRef.current.value : "";
					response.password = passwordRef.current ? passwordRef.current.value : "";
				}
				await storageService.setObject(userObjectKey, response);
				let nextPage = "/dashboard";
				let hero = await getHero()
				if (hero.heroImageURL === EmptyAvatarImage) {
					nextPage = "/onboarding/photooption";
				} else {
					let baselineWorkoutsObject = await getAndProcessBaselineWorkouts();
					await updateHeroHealth(baselineWorkoutsObject.activityLedger, baselineWorkoutsObject.recentWorkouts, moment());
					await serialStartupFunction();
				}
				setLoggingIn({ visible: false, message: "" });
				history.push(nextPage);
			})
			.catch((error) => {
				setLoggingIn({ visible: false, message: "" });
				log.debug("Error: " + error);
				let message = "";
				switch (error) {
					case "UserNotFoundException":
					case "AuthError":
						message = "No user found with that email address";
						setEmailError({
							visible: true,
							message: message,
						});
						break;
					case "NotAuthorizedException":
						message = "Incorrect username and/or password";
						setPasswordError({
							visible: true,
							message: message,
						});
						break;
					default:
						message = error.message;
						setEmailError({
							visible: true,
							message: message,
						});
						break;
				}
			});
	};

	function adamLogin() {
		emailRef.current!.value = "adam@myfithero.net";
		passwordRef.current!.value = "12345678";
	}

	function riggzLogin() {
		emailRef.current!.value = "adam@fithero.ai";
		passwordRef.current!.value = "qqqqqqqq";
	}

	return (
		<IonPage className={isMobile ? "app-centering-login" : "app-centering-login"}>
			<IonContent>
				<IonLoading
					isOpen={loggingIn.visible}
					onDidDismiss={() => setLoggingIn({ visible: false, message: "" })}
					message={loggingIn.message}
					backdropDismiss={true}
				/>
				<div className="login-logo">
					<img src="/assets/images/fit-hero-rectangle-transparent.png" alt="FitHero Logo" />
				</div>
				<div className={isMobile ? "login-main-div" : ""}>
					<p className="login-title">Sign In</p>
					<IonItem>
						<IonLabel className="login-label" position="stacked">
							Email
						</IonLabel>
						<IonInput
							ref={emailRef}
							onKeyUp={handleKeyUpEmail}
							name="email"
							type={"email"}
							placeholder="Superman@FitHero.com"
							enterkeyhint="next"
							required
						></IonInput>
						{emailError.visible === true ? <p className="error-message">{emailError.message}</p> : ""}
					</IonItem>
					<IonItem>
						<IonLabel className="login-label" position="stacked">
							Password
						</IonLabel>
						<IonInput
							ref={passwordRef}
							onKeyUp={handleKeyUpPassword}
							name="password"
							type={"password"}
							placeholder="8 characters"
							minlength={8}
							enterkeyhint="done"
							required
						></IonInput>
						{passwordError.visible === true ? <p className="error-message">{passwordError.message}</p> : ""}
					</IonItem>
					<div className="forgot-password">
						<IonButton className="button-as-link" routerLink={"/forgotpassword"}>
							Forgot Password?
						</IonButton>
					</div>
					<div>
						{/*
													<IonRow>
							<IonCol size="2"></IonCol>
							<IonCol size="8">
								<IonButton onClick={fbLogin} expand="block">
									Sign in with Facebook!
								</IonButton>
							</IonCol>
							<IonCol size="2"></IonCol>
						</IonRow>

							*/}
						<div style={{ width: "60%", margin: "auto" }}>
							<IonButton onClick={validateData} expand="block">
								Sign in
							</IonButton>
						</div>
						<div style={{ width: "60%", margin: "auto", textAlign: "center" }}>
							<IonButton className="button-as-link" routerLink="/entryScreen">
								Register
							</IonButton>
						</div>

						{localHost && (
							<IonButton onClick={adamLogin} className="onboarding-advance-button">
								Adam login
							</IonButton>
						)}

						{localHost && (
							<IonButton onClick={riggzLogin} className="onboarding-advance-button">
								Riggz login
							</IonButton>
						)}

						{/* <p style={{ textAlign: "center", fontSize: "15px" }}>
							Don't have an account yet?{" "}
							<a className="link" href="/onboarding">
								Create an account
							</a>
						</p> */}
					</div>
					{/*
				
				*/}
				</div>
			</IonContent>
		</IonPage>
	);
};
export default LoginScreen;
