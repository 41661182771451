import {
	IonAlert,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonIcon,
	IonList,
	IonLoading,
	IonModal,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonToolbar,
	useIonViewWillEnter
} from "@ionic/react";
import { flagOutline, newspaperOutline, personOutline, refreshCircleOutline, settingsOutline, statsChartOutline } from "ionicons/icons";
import log from "loglevel";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import GlobalState from "../../contexts/GlobalState";
import "../../css/Profile.css";
import { SummaryType } from "../../models/exerciseModels";
import { logGenericEvent } from "../../services/AnalyticsService";
import { HeroProfile } from "./HeroProfile";
import { ProfileActivities } from "./ProfileActivities";
import { ProfileGoals } from "./ProfileGoals";
import { ProfileStats } from "./ProfileStats";
import ProfileHeader from "./components/ProfileHeader";
import ChangeAvatar from "./updateAvatar/ChangeAvatar";;

export default function Profile() {
	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { userState, heroObject, refreshHeroData, isMobile, heroLoadingFinished } = globalState;
	const { viewParam } = useParams<{ viewParam: string }>();
	const [view, setView] = useState<string | undefined>(viewParam);

	const [errorAlert, setErrorAlert] = useState<any>({
		visible: false,
		message: null,
	});
	const [refreshAlert, setRefreshAlert] = useState<boolean>(false);
	const [refreshing, setRefreshing] = useState<boolean>(false);
	const [showChangeAvatarModal, setShowChangeAvatarModal] = useState<boolean>(false);

	let heroName = heroObject?.heroName ?? "User Name";

	useIonViewWillEnter(() => {
		showTab();
		// log.debug(`Activity Ledger: ${activityLedger}`);
	});

	useEffect(() => {
		log.debug("view: ", view);
	}, [view]);

	function showTab() {
		const tabBar = document.getElementById("tabBar");
		if (tabBar !== null) {
			tabBar.style.display = "flex";
		}
	}

	async function confirmRefresh(confirmed: boolean = false) {
		if (confirmed) {
			setRefreshing(true);
			logGenericEvent("refreshing profile data");
			await refreshHeroData(true);
			setRefreshing(false);
		} else {
			setRefreshAlert(true);
		}
	}

	function navigate(view: string) {
		history.push(`/profile/${view}`);
	}



	return (
		<>
			{userState && userState.authenticated === true && (
				<IonPage>
					<IonHeader>
						<IonToolbar className="background">
							<ProfileHeader heroName={heroName} heroPicture={heroObject?.heroImageURL} heroLevel={heroObject?.overallLevel} setShowChangeAvatarModal={setShowChangeAvatarModal} />
							<IonButtons slot="start" className="toolbar-left-buttons">
								<IonButton onClick={() => confirmRefresh(false)}>
									<IonIcon style={{ color: "white" }} icon={refreshCircleOutline} />
								</IonButton>
							</IonButtons>
							<IonButtons slot="end" className="toolbar-right-buttons">
								<IonButton routerLink="/settings">
									<IonIcon style={{ color: "white" }} icon={settingsOutline} />
								</IonButton>
							</IonButtons>
						</IonToolbar>
					</IonHeader>
					<IonModal onDidDismiss={() => setShowChangeAvatarModal(false)} isOpen={showChangeAvatarModal} className={`${isMobile ? "" : "desktop-add-activity-modal"}`}>
						<ChangeAvatar setShowChangeAvatarModal={setShowChangeAvatarModal} />
					</IonModal>
					<IonAlert
						isOpen={!!refreshAlert}
						header="Refresh Data?"
						message="Are you sure you want to refresh your data? This will take a few minutes."
						buttons={[
							{
								text: "Cancel",
								role: "cancel",
								handler: () => {
									setRefreshAlert(false);
								},
							},
							{
								text: "Ok",
								handler: () => {
									setRefreshAlert(false);
									confirmRefresh(true);
								},
							},
						]}
					/>

					<IonAlert
						isOpen={!!errorAlert.visible}
						header="Oops"
						message={errorAlert.message}
						buttons={[
							{
								text: "Ok",
								role: "cancel",
								handler: () => {
									setErrorAlert({ visible: false, message: null });
								},
							},
						]}
						onDidDismiss={() => setErrorAlert({ visible: false, message: null })}
					/>
					<IonLoading isOpen={refreshing} message={"Refreshing data ..."} backdropDismiss />
					<IonLoading isOpen={heroLoadingFinished === false} message={"Loading ..."} backdropDismiss duration={5000} />
					<div style={{ minHeight: "60px", width: "90%", margin: "auto" }}>
						<IonSegment style={{ width: "90%" }} className="duels-segment" mode="ios" color="tertiary" value={view} onIonChange={(e) => setView(e.detail.value)}>
							<IonSegmentButton onClick={() => navigate("goals")} value="goals" layout="icon-start" className="squad-home-segement-button updatesButton">
								<IonIcon icon={flagOutline} size="small" />
								{/* <IonLabel>Goals</IonLabel> */}
							</IonSegmentButton>
							<IonSegmentButton onClick={() => navigate("activities")} value="activities" layout="icon-start" className="squad-home-segement-button updatesButton">
								<IonIcon icon={newspaperOutline} size="small" />
								{/* <IonLabel>Activities</IonLabel> */}
							</IonSegmentButton>
							<IonSegmentButton onClick={() => navigate("stats")} value="stats" layout="icon-top" className="duels-segment-button">
								<IonIcon icon={statsChartOutline} size="small" />
							</IonSegmentButton>
							<IonSegmentButton onClick={() => navigate("hero")} value="hero" layout="icon-top" className="duels-segment-button">
								<IonIcon icon={personOutline} size="small" />
							</IonSegmentButton>
							{/* <IonSegmentButton onClick={() => navigate("feed")} value="feed" layout="icon-top" className="duels-segment-button">
								<div className="duels-segment-icon-special">📃</div>
								<IonLabel>Feed</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton onClick={() => navigate("achievements")} value="achievements" layout="icon-top" className="duels-segment-button">
								<div className="duels-segment-icon-special">🏆</div>
								<IonLabel>Achievements</IonLabel>
							</IonSegmentButton> */}
						</IonSegment>
					</div>
					{(view === "goals") && (
						<ProfileGoals />
					)}
					{view === "activities" && (
						<ProfileActivities />
					)}
					{view === "stats" && (
						<ProfileStats />
					)}
					{view === "feed" && (
						<ProfileStats />
					)}
					{view === "achivements" && (
						<ProfileStats />
					)}
					{view === "hero" && (
						<HeroProfile />
					)}


					{/* <div style={{ minHeight: "10%" }}>
						<IonSegment mode="ios" color="tertiary" value={view} onIonChange={(e) => setView(e.detail.value)} style={{ position: "fixed", zIndex: "20000" }}>
							<IonSegmentButton className="squad-home-segement-button" value="history" layout="icon-top">
								<IonIcon icon={newspaperOutline} />
								<IonLabel className="squad-home-label">History</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton className="squad-home-segement-button" value="stats" layout="icon-top">
								<IonIcon icon={analyticsOutline} />
								<IonLabel className="squad-home-label">Stats</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</div>
					{view === "history" ? (
						<>
							<ActivityHistory />
						</>
					) : (
						""
					)}
					{view === "stats" ? (
						<>
							<IonContent></IonContent>
						</>
					) : (
						""
					)} */}
				</IonPage>
			)}
		</>
	);
}

const ActivitySummary = (props: { summary: SummaryType[] }) => {
	return (
		<>
			{props.summary && (
				<IonContent>
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Summary</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonList>
								{props.summary.map((data: SummaryType, index: number) => {
									return (
										<div key={index}>
											{data.updated && <div style={{ fontWeight: "bold" }}>Last updated: {moment(data.updated).format("dddd MMMM Do, YYYY")}</div>}
											{data.attributes.map((text: string, textIndex: number) => (
												<div key={textIndex} style={{ whiteSpace: "unset" }}>
													{text}
												</div>
											))}
										</div>
									);
								})}
							</IonList>
						</IonCardContent>
					</IonCard>
				</IonContent>
			)}
		</>
	);
};
