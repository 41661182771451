import { IonContent, IonIcon, IonModal, IonSkeletonText } from '@ionic/react';
import { lockClosed, checkmarkCircleOutline } from 'ionicons/icons';
import log from 'loglevel';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router";
import "../../../css/Duels.css";
import { VillainGroupInstance, VillainGroups, villainGroupInstancesKey, villainGroupsKey } from '../../../models/duelsModels';
import { canGroupBeUnlocked, getAllVillainGroupInstances } from '../../../services/DuelsServics';
import { StorageService } from '../../../services/StorageService';
import { getAllVillainGroupsFromDB } from '../../../services/VillainServices';
import GroupProfile from '../../Duels/components/GroupProfile';
import { TourComponent } from '../../Tour/StepsComponent';
import { VillainGroupsSteps } from '../../Tour/Steps';

const VillainGroupProgression = () => {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [standardVillainGroups, setStandardVillainGroups] = useState<VillainGroups[]>([]);
    const [specialityVillainGroups, setSpecialityVillainGroups] = useState<VillainGroups[]>([]);
    const [completedVillainGroups, setCompletedVillainGroups] = useState<VillainGroupInstance[]>([]);
    const [lockedVillainGroups, setLockedVillainGroups] = useState<string[]>([]);
    const [villainGroupInstances, setVillainGroupInstances] = useState<VillainGroupInstance[]>([]);
    const [showUnlockModal, setShowUnlockModal] = useState({ show: false, villainGroup: {} as VillainGroups, unlockable: false, reason: "" });
    let storageService = new StorageService();

    useEffect(() => {
        getVillainGroups();
        // getVillainGroupInstances();
    }, []);

    async function getVillainGroups() {
        setLoading(true)
        let [villainGroupsLocal, villainGroupInstances] = await Promise.all([
            storageService.getObject(villainGroupsKey) as unknown as VillainGroups[],
            storageService.getObject(villainGroupInstancesKey) as unknown as VillainGroupInstance[]
        ]);

        // Set all groups
        if (!villainGroupsLocal) {
            villainGroupsLocal = await getAllVillainGroupsFromDB()
        }
        setStandardVillainGroups(villainGroupsLocal.filter(group => group.groupId > 0).sort((a, b) => a.groupId - b.groupId));
        setSpecialityVillainGroups(villainGroupsLocal.filter(group => group.groupId === 0).sort((a, b) => a.groupId - b.groupId));

        // Set completed groups
        if (!villainGroupInstances) {
            villainGroupInstances = await getAllVillainGroupInstances()
        }
        setVillainGroupInstances(villainGroupInstances);
        setCompletedVillainGroups(villainGroupInstances.filter(instance => instance.completed));

        // Set locked groups
        let lockedGroups = villainGroupsLocal.filter(group => {
            if (group.startDate && group.endDate) {
                if (
                    (moment(group.startDate).isAfter() ||
                        moment(group.endDate).isBefore()) && (
                        villainGroupInstances.some(vgi => vgi.villainGroupId === group.villainGroupId) === false // If the group is not in the instances
                    )) { return group; }
            }
        }).map(group => group.villainGroupId);
        setLockedVillainGroups(lockedGroups);
        setLoading(false);
    }

    async function handleNodeClick(villainGroup: VillainGroups) {
        if (
            (villainGroupInstances.some(vgi => vgi.villainGroupId === villainGroup.villainGroupId) === false &&
                !lockedVillainGroups.includes(villainGroup.villainGroupId)) // If the group is not in the instances and is not locked
            ||
            (lockedVillainGroups.includes(villainGroup.villainGroupId))) // Or if the group IS locked
        {
            let unlockableObject = await canGroupBeUnlocked(villainGroup);
            setShowUnlockModal({ show: true, villainGroup: villainGroup, unlockable: unlockableObject.canBeUnlocked, reason: unlockableObject.reason });
        } else {
            history.push(`/duels/duelprogression/${villainGroup.villainGroupId}`);
        }
    }

    return (
        <>

            <IonModal isOpen={showUnlockModal.show} onWillDismiss={() => setShowUnlockModal({ show: false, villainGroup: {} as VillainGroups, unlockable: false, reason: "" })}>
                <GroupProfile setShowModal={setShowUnlockModal} villainGroup={showUnlockModal.villainGroup} unlockable={showUnlockModal.unlockable} reason={showUnlockModal.reason} />
            </IonModal>
            <h1 style={{ textAlign: "center" }}>Standard Groups</h1>
            <div className="game-map">
                {loading && (
                    <SkeletonText />
                )}
                {!loading && standardVillainGroups.map((villainGroup, index) => (
                    <div key={index} className={`group-node ${index === 0 && 'first-villain-group-hint'}`} onClick={() => handleNodeClick(villainGroup)}>
                        <div className={`${completedVillainGroups.some(vgi => vgi.villainGroupId === villainGroup.villainGroupId) ? 'group-node-locked' : ''} ${lockedVillainGroups.includes(villainGroup.villainGroupId) ? 'group-node-locked' : ''}`}>
                            {completedVillainGroups.some(vgi => vgi.villainGroupId === villainGroup.villainGroupId) && (
                                <>
                                    <div className='specialty-group-node-completed'>
                                        Completed on {moment(completedVillainGroups.find(group => group.villainGroupId === villainGroup.villainGroupId)?.updatedAt).format("dddd MMMM Do, YYYY")}
                                    </div>
                                </>
                            )}
                            {lockedVillainGroups.includes(villainGroup.villainGroupId) && (
                                <>
                                    <div className='group-node-locked-icon'>
                                        <IonIcon icon={lockClosed} />
                                    </div>
                                </>
                            )}
                        </div>
                        <img src={villainGroup.groupImage} className="villainGroup-image-node" alt="villain group" />
                        <div className='group-name'>{villainGroup.groupName}</div>
                    </div>
                ))}
            </div>
            <h1 style={{ textAlign: "center" }}>Specialty Groups</h1>
            <div className="game-map">
                {!loading && specialityVillainGroups.map((villainGroup, index) => (
                    <div key={index} className='group-node' onClick={() => handleNodeClick(villainGroup)}>
                        <div className={`${completedVillainGroups.some(vgi => vgi.villainGroupId === villainGroup.villainGroupId) ? 'group-node-locked' : ''} ${lockedVillainGroups.includes(villainGroup.villainGroupId) ? 'group-node-locked' : ''}`}>
                            {completedVillainGroups.some(vgi => vgi.villainGroupId === villainGroup.villainGroupId) && (
                                <>
                                    <div className='specialty-group-node-completed'>
                                        Completed on {moment(completedVillainGroups.find(group => group.villainGroupId === villainGroup.villainGroupId)?.updatedAt).format("dddd MMMM Do, YYYY")}
                                    </div>
                                </>
                            )}
                            {lockedVillainGroups.includes(villainGroup.villainGroupId) && (
                                <>
                                    <div className='group-node-locked-icon'>
                                        <IonIcon icon={lockClosed} />
                                    </div>
                                </>
                            )}
                        </div>
                        <img src={villainGroup.groupImage} className="villainGroup-image-node" alt="villain group" />
                        <div className='group-name'>{villainGroup.groupName}</div>
                    </div>
                ))}
            </div>
            <TourComponent coachMarkName='villainGroups' steps={VillainGroupsSteps} modalShowing={showUnlockModal.show} action={() => handleNodeClick(standardVillainGroups[0])} />
        </>
    );
};

export default VillainGroupProgression;

const SkeletonText = () => {
    return (
        <>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
            <div className='group-node' style={{ width: "122px", height: "200px" }}>
                <IonSkeletonText animated style={{ width: '100%', height: '100%' }} />
            </div>
        </>
    )
}