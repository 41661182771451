import { IonButton, IonContent, IonFooter, IonLoading, IonPage } from '@ionic/react';
import log from 'loglevel';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import DuelStatusInfo from '../../Features/Duels/components/DuelStatusInfo';
import DuelsLedgerComponent from '../../Features/Duels/components/DuelUpdates';
import HeaderLogo from '../../components/HeaderLogo';
import GlobalState from '../../contexts/GlobalState';
import { DuelLedger, DuelStatus } from '../../models/duelsModels';
import { WorkoutObject } from '../../models/exerciseModels';
import { OnboardingObject } from '../../models/userModel';
import { getCurrentDuel } from '../../services/DuelsServics';
import { getActivitiesByUserID } from '../../services/ExerciseService';
import { StorageService } from '../../services/StorageService';
import { getHero } from '../../services/UserService';
import { set } from 'lodash';


const FirstHit = () => {
    const nextPage = "/onboarding/connecttracker";
    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, setHeroObject } = globalState;
    const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
    const [duelStatus, setDuelStatus] = useState<DuelStatus>();
    const [duelLedger, setDuelLedger] = useState<DuelLedger[]>([]);
    const [loadingDuelInfo, setLoadingDuelInfo] = useState(false);

    useEffect(() => {
        updateOnboardingObject();
        addActivityToDuelLedger();
    }, []);

    async function updateOnboardingObject() {
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        if (!heroObject && tempOnboardingObject.hero) {
            setHeroObject(tempOnboardingObject.hero);
        } else if (!heroObject) {
            let hero = await getHero();
            setHeroObject(hero);
            tempOnboardingObject.hero = hero;
        }
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        setOnboardingObject(tempOnboardingObject);
        await storageService.setObject("onboarding", tempOnboardingObject);
    }

    async function addActivityToDuelLedger() {
        setLoadingDuelInfo(true);
        let currentDuel = await getCurrentDuel();
        if (currentDuel) {
            // Fetch the activity
            let activities: WorkoutObject[] = await getActivitiesByUserID()
            log.debug("activities: ", activities);
            // Create fake duel ledger entry
            currentDuel.villainHealth = currentDuel.villainHealth! - currentDuel.heroDamage!;
            let fakeLedgerEntry: DuelLedger = {
                _id: "fake",
                ledgerId: "fake",
                createdAt: activities[0].createdAt!,
                updatedAt: activities[0].updatedAt!,
                duel_id: currentDuel?.statusId,
                updateString: `1 activity found since last turn.\nYou hit ${currentDuel?.villainName} for ${currentDuel?.heroDamage!} damage!\n${currentDuel?.villainName} missed you\n`,
                heroHitChance: 100,
                heroSwing: 100,
                villainHitChance: currentDuel?.villainToHit! - currentDuel?.heroDodge!,
                villainSwing: 0,
                heroHit: true,
                villainHit: false,
                heroDamageDealt: currentDuel?.heroDamage!,
                villainDamageDealt: 0,
                heroOdds: 50,
                currentHeroHealth: currentDuel?.heroHealth!,
                currentVillainHealth: currentDuel.villainHealth,
                commentaryTitle: "💪 Fitness Boosts Fuel Mighty Strike!",
                commentaryMessage: `With a surge of power, you hit ${currentDuel?.villainName} for ${currentDuel?.heroDamage!} damage, while dodging an attack! ❤ at ${currentDuel.villainHealth}.`
            }
            setDuelStatus(currentDuel);
            setDuelLedger([fakeLedgerEntry]);
        } else {
            log.error("No current duel found");
            alert("Hmm, sothing went wrong. No current duel found. Try tapping back and trying again...");
        }
        setLoadingDuelInfo(false);
    }


    return (
        <IonPage>
            <HeaderLogo />
            <IonLoading isOpen={loadingDuelInfo} message={"Loading duel info..."} />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Your first activity landed a blow!</div>
                    {duelStatus && duelLedger && (
                        <>
                            <DuelStatusInfo duelStatus={duelStatus} previousVillainHealth={duelStatus.villainHealth} currentVillainHealth={duelLedger[0].currentVillainHealth} />
                            <div className='onboarding-text-area' style={{ textAlign: "center" }}>
                                <p>
                                    This is your first activity since your duel started. Great job on your first hit!
                                </p>
                            </div>
                            <DuelsLedgerComponent duelID={duelStatus.statusId} duelStatus={duelStatus} duelLedger={duelLedger} showTimer={false} />
                        </>
                    )}

                </div>

            </IonContent>
            <IonFooter className="onboarding-footer">
                <IonButton className="onboarding-advance-button" routerLink={nextPage} >
                    Sweet!
                </IonButton>
            </IonFooter>


        </IonPage>
    )
}

export default FirstHit