import React, { useState } from 'react'
import { IonButton, IonContent, IonPage, IonProgressBar, IonSpinner } from '@ionic/react';
import axios from 'axios';
import log from 'loglevel';

const TestImagineDev = () => {
    const imagineDevAPI = "https://demo.imagineapi.dev/items/images"
    const imagineDevAPI_KEY = "SFbuj4_e5X93SCySkk7yYnBuHmet5DR4";
    const HEADERS = {
        "accept": "application/json",
        "content-type": "application/json",
        "authorization": `Bearer ${imagineDevAPI_KEY}`,
        "Access-Control-Allow-Origin": "*", // Required for CORS support to work
    };
    const [images, setImages] = useState<string[]>([]);
    const [generating, setGenerating] = useState<boolean>(false);
    const [status, setStatus] = useState<string>("");
    const [progress, setProgress] = useState<number>(0);
    const [formValues, setFormValues] = useState({
        name: "",
        eyeColor: '',
        trait1: '',
        race: '',
        trait2: '',
        trait3: '',
        trait4: '',
        hairstyle: '',
        hairColor: '',
        age: '',
        gender: '',
        build: '',
        accessories: '',
        background: '',
        expression: '',
        attire: ''
    });

    const generateImage = async (prompt: string) => {
        const url = imagineDevAPI;
        const payload = {
            "prompt": prompt,
        }
        const response = await axios.post(url, payload, { headers: HEADERS });
        const data = response.data;
        log.debug("[generateImage]: ", data);
        setGenerating(true);
        return { id: data["id"], status: data["status"], progress: data["progress"] };
    };

    const getInferenceJob = async (id: string) => {
        // const url = `https://api.tryleap.ai/api/v1/images/models/${modelId}/inferences/${inferenceId}`;
        const url = `${imagineDevAPI}/${id}`;
        const response = await axios.get(url, { headers: HEADERS });
        const data = response.data;
        log.debug("[getInferenceJob]: ", data);
        return { id: data["id"], status: data["status"], progress: data['progress'], url: data["url"]?.length ? data["url"] : null };
    };

    const runImageGeneration = async (prompt: string) => {
        let { id, status, progress } = await generateImage(prompt);
        while (status !== "finished") {
            await new Promise(resolve => setTimeout(resolve, 3000)); // Sleep for 10 seconds
            const result = await getInferenceJob(id);
            id = result.id;
            setStatus(result.status);
            setProgress(result.progress);
            if (result.url) {
                setGenerating(false);
                setImages(prevImages => [...prevImages, ...result.url]);
            }
        }
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormValues({ ...formValues, [event.target.name]: event.target.value });
    };

    const generatePrompt = () => {
        let prompt = `${formValues.gender}, around ${formValues.age} years old, with ${formValues.hairColor} ${formValues.hairstyle} hair, an ${formValues.build}, wearing ${formValues.attire} and a ${formValues.expression} on their face. Place them in a ${formValues.background} background and ${formValues.accessories} should be present. portrait fantasy, cosmic horror, dramatic lighting 8k`
        prompt = `Craft a dynamic and powerful profile image of the superhero ${formValues.name}, inspired by the iconic styles of Marvel and DC comics. 
        As an ${formValues.race} ${formValues.gender} hero approximately ${formValues.age} years old, ${formValues.name} exudes ${formValues.trait1} and ${formValues.trait2}. 
        His ${formValues.build} build should be emphasized, complemented by his ${formValues.hairColor} ${formValues.hairstyle} and ${formValues.eyeColor} eyes, which radiate ${formValues.trait3} and ${formValues.trait4}. 
        He should wear a suit that is both practical and awe-inspiring, signaling his readiness for action. 
        Utilize a Nikon D850 with a Nikkor 70-200mm f/2.8 lens to capture ${formValues.name}'s image in exceptional detail. 
        Use an aperture of f/4, an ISO of 400, and a shutter speed of 1/500 sec to focus on ${formValues.name} and create a stunning depth of field effect, separating them from the backdrop and highlighting his imposing figure. 
        The lighting should be dramatic, highlighting ${formValues.name}'s physique and lending an air of heroic stature. 
        A bold and dynamic background, perhaps a city skyline or a dramatic sky, should act as a backdrop, signaling his commitment to protect and serve. The colors should be vibrant and bold, embodying the spirit of comic book art, and highlighting ${formValues.name}'s heroic nature.`
        log.debug(`Prompt: ${prompt}`);
        runImageGeneration(prompt);
    };

    return (
        <IonPage>
            <IonContent>
                <h1>Test Leap</h1>
                <form style={{ color: "black" }} onSubmit={(event) => {
                    event.preventDefault();
                    generatePrompt()
                }}>
                    <input type="text" name="name" placeholder="Name" onChange={handleInputChange} />
                    <input type="text" name="hairstyle" placeholder="Hair style" onChange={handleInputChange} />
                    <input type="text" name="race" placeholder="Race" onChange={handleInputChange} />
                    <input type="text" name="hairColor" placeholder="Hair color" onChange={handleInputChange} />
                    <input type="text" name="eyeColor" placeholder="Eye color" onChange={handleInputChange} />
                    <input type="text" name="age" placeholder="Age" onChange={handleInputChange} />
                    <input type="text" name="gender" placeholder="Gender" onChange={handleInputChange} />
                    <input type="text" name="build" placeholder="Build" onChange={handleInputChange} />
                    <input type="text" name="trait1" placeholder="Trait (i.e. courage)" onChange={handleInputChange} />
                    <input type="text" name="trait2" placeholder="Trait (i.e. determination)" onChange={handleInputChange} />
                    <input type="text" name="trait3" placeholder="Trait (i.e. strength)" onChange={handleInputChange} />
                    <input type="text" name="trait4" placeholder="Trait (i.e. resilience)" onChange={handleInputChange} />
                    <input type="text" name="background" placeholder="Background" onChange={handleInputChange} />
                    <input type="text" name="expression" placeholder="Facial expression" onChange={handleInputChange} />
                    <input type="text" name="attire" placeholder="Attire" onChange={handleInputChange} />
                    <IonButton type='submit'>Generate Image</IonButton>
                </form>
                {generating && (
                    <>
                        <div>Generating image...</div>
                        <div>Status: {status}</div>
                        <IonProgressBar value={progress} />
                        <IonSpinner />
                    </>
                )}
                <div>
                    {images.map((imageUri, index) => (
                        <img key={index} src={imageUri} alt={`Generated image ${index}`} />
                    ))}
                </div>
            </IonContent>
        </IonPage>
    )
}

export default TestImagineDev