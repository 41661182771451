import { IonButton, IonCol, IonIcon, IonRow, IonTextarea } from "@ionic/react";
import { sendSharp } from "ionicons/icons";
import React, { useRef, useState } from "react";
import "../../../css/ChatInput.css";
import { SidekickMessage } from "../../../models/messageModels";
import { logMessageInDB } from "../../../services/MessageService";
import { StorageService } from "../../../services/StorageService";
import { getUser_id } from "../../../services/UserService";
import log from "loglevel";
import { processSidekickResponse, sendSidekickMessage } from "../../../services/SideKickService";
import { SidekickMessageFullObject } from "../../../models/sideKickModels";
import { insertNewLines } from "../../../services/HelperService";
import { delay, update } from "lodash";
const storageService = new StorageService();
var globalContext = "";

const SidekickChatInput = (props: {
	messages: SidekickMessage[];
	setMessages: React.Dispatch<React.SetStateAction<any>>;
	setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	setScroller: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const [showToast, setShowToast] = useState(false);
	const [input, setInput] = useState("");
	const inputRef = useRef<any>(null);

	// async function processInput(userInput: string, displayMessage: boolean) {
	// 	let user_id = await getUser_id();
	// 	if (userInput !== "") {
	// 		setInput("");
	// 		userInput = userInput.replace(/\n+$/, ""); // This will remove all trailing newline characters from the string.
	// 		props.setProcessing(true);
	// 		// if (displayMessage === true) {
	// 		let updatedDisplayResponse: SidekickMessage[];
	// 		let userMessage: SidekickMessage = {
	// 			source: "user",
	// 			message: userInput,
	// 		};
	// 		updatedDisplayResponse = [...props.messages, userMessage];
	// 		props.setMessages(updatedDisplayResponse);
	// 		// logMessageInDB(userInput, "user", user_id);
	// 		// }
	// 		let sideKickResponse: SidekickMessage = await sendSidekickMessage(userInput);
	// 		let displayMessages = await processSidekickResponse(sideKickResponse, userInput);
	// 		// // log.debug(`displayMessages in hero: `, displayMessages);
	// 		// props.setProcessing(false);
	// 		// props.setMessages(displayMessages);
	// 		// props.setProcessing(false);
	// 		let lastSidekickMessage: SidekickMessage = displayMessages[displayMessages.length - 1];
	// 		let lastSidekickMessageArray = insertNewLines(lastSidekickMessage.message).split("\n") // split the message into an array of lines
	// 		let i = 1
	// 		lastSidekickMessageArray.forEach(async (message: string) => {
	// 			delay(() => {
	// 				props.setProcessing(false);
	// 				let sideKickMessage: SidekickMessage = {
	// 					...lastSidekickMessage,
	// 					message: message
	// 				}
	// 				updatedDisplayResponse.push(sideKickMessage)
	// 				props.setMessages([...props.messages, updatedDisplayResponse]);
	// 				props.setProcessing(true);
	// 				log.debug("Delaying");
	// 			}, 2000 * i);
	// 			i++;
	// 		});
	// 		props.setProcessing(false);
	// 	} else {
	// 		// log.debug(`User input: ${userInput}`);
	// 	}
	// }
	async function processInput(userInput: string, displayMessage: boolean) {
		if (userInput !== "") {
			setInput("");
			userInput = userInput.replace(/\n+$/, ""); // Remove trailing newlines

			let userMessage: SidekickMessage = {
				source: "user",
				message: userInput,
			};
			props.setMessages([...props.messages, userMessage]);

			let sideKickResponse: SidekickMessage = await sendSidekickMessage(userInput);
			let displayMessages = await processSidekickResponse(sideKickResponse, userInput);

			let lastSidekickMessage: SidekickMessage = displayMessages[displayMessages.length - 1];
			let lastSidekickMessageArray = insertNewLines(lastSidekickMessage.message).split("\n");
			lastSidekickMessageArray.forEach((message, index) => {
				props.setProcessing(true);
				// let randomMessageDelay = Math.floor(Math.random() * 3000) + 2000;
				let randomMessageDelay = 4000;
				setTimeout(() => {
					let sideKickMessage: SidekickMessage = {
						...lastSidekickMessage,
						message: message
					};
					// Using functional update to ensure we have the latest state
					props.setMessages((prevMessages: SidekickMessage[]) => [...prevMessages, sideKickMessage]);

					if (index === lastSidekickMessageArray.length - 1) {
						props.setProcessing(false);
					} else {
						props.setProcessing(true);
					}
				}, randomMessageDelay * (index + 1));
			});
		}
	}

	// function handleKeyUp(event) {
	// 	if (event.keyCode === 13) {
	// 		submitText();
	// 	}
	// }

	async function inputFocus() {
		log.debug("Focus on input. Should scroll");
		props.setScroller(true);
	}

	window.addEventListener("keyboardDidShow", async (event) => {
		// log.debug("KEYBOARD SHOWED");
		props.setScroller(true);
	});

	window.addEventListener("keyboardDidHide", async (event) => {
		// log.debug("KEYBOARD HID");
		props.setScroller(true);
	});

	// if not show a text box with a submit button
	return (
		<>
			<IonRow className="chat-input-row">
				<IonCol size="11">
					<IonTextarea
						value={input}
						autoGrow={true}
						rows={1}
						className="chat-input-textarea"
						ref={inputRef}
						placeholder="Type message..."
						// onKeyUp={handleKeyUp}
						onFocus={inputFocus}
						onKeyUp={(event) => {
							if (event.key === "Enter") {
								processInput(inputRef.current.value, true);
							}
						}}
					></IonTextarea>
				</IonCol>
				<IonCol size="1">
					<IonButton onClick={() => processInput(inputRef.current.value, true)} className="send-button" color="chat-bar-button" style={{ fontSize: "0px" }}>
						<IonIcon size="small" icon={sendSharp}></IonIcon>
					</IonButton>
				</IonCol>
			</IonRow>
		</>
	);
};

export default SidekickChatInput;
