import React from 'react';

const TestEmail = () => {
    const rawHTML = `
<!DOCTYPE html>
<html>
<head>
    <meta charset="UTF-8">
    <title>Weekly Fitness Summary</title>
    <style>
        body {
            font-family: 'RF Dewi', sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }

        .email-container {
            max-width: 600px;
            margin: 20px auto;
            background-color: #ffffff;
            padding: 20px;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        }

        .header {
            text-align: center;
            margin-bottom: 20px;
        }

        .content {
            margin-bottom: 20px;
        }

        .attribute {
            font-family: 'RF Dewi Condensed', sans-serif;
            color: #75aad2;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .footer {
            font-size: 12px;
            color: #a9a9a9;
            text-align: center;
        }

        .cta {
            display: block;
            background-color: #ff6901;
            color: #ffffff;
            text-align: center;
            padding: 10px 0;
            border-radius: 5px;
            text-decoration: none;
            margin: 20px 0;
        }

        /* Adding styles for section headers */
        .section-header {
            font-family: 'RF Dewi Condensed', sans-serif;
            background-color: #75aad2;
            color: #ffffff;
            padding: 5px 10px;
            border-radius: 3px;
            margin-top: 20px;
        }

        /* Style for activity details */
        .activity-details {
            border: 1px solid #a9a9a9;
            padding: 10px;
            margin-top: 10px;
        }

        .activity-name {
            font-weight: bold;
        }

        .activity-time, .activity-points {
            margin: 5px 0;
        }

        .container {
            color: white;
            text-align: center;
            margin: 10px;
        }

        .view-report-button {
            padding: 10px;
            border-radius: 10px;
            background: orange;
            color: white;
        }
    </style>
</head>
<body>
    <div class="email-container">
        <!-- Header -->
        <div class="header">
            <img src="path-to-your-logo.jpg" alt="Your Logo" width="200" /> 
            <!-- Placeholder image for now -->
        </div>

        <!-- Body -->
        <div class="content">
            <!-- Stats Progression Section -->
            <div class="section-header">Stats Progression</div>
            <table style="text-align:center;width:100%">
                <tr>
                    <th>
                        Attribute
                    </th>
                    <th>
                        Prior Week Points
                    </th>
                    <th>
                        Last Week Points
                    </th>
                    <th>
                        % change
                    </th>
                </tr>
                <tr>
                    <td>
                        Strength
                    </td>
                    <td>
                        100
                    </td>
                    <td>
                        150
                    </td>
                    <td>
                        50%
                    </td>
                </tr>
                <tr>
                    <td>
                        Cardio
                    </td>
                    <td>
                        100
                    </td>
                    <td>
                        150
                    </td>
                    <td>
                        50%
                    </td>
                </tr>
                <tr>
                    <td>
                        Mobility
                    </td>
                    <td>
                        100
                    </td>
                    <td>
                        150
                    </td>
                    <td>
                        50%
                    </td>
                </tr>
                <tr>
                    <td>
                        Mindfulness
                    </td>
                    <td>
                        100
                    </td>
                    <td>
                        150
                    </td>
                    <td>
                        50%
                    </td>
                </tr>
            </table>            
    
            <!-- Weekly Analysis Section -->
            <div class="section-header">Weekly Analysis</div>
            <p>
                [Insert your analysis of the changes here]
            </p>
    
            <!-- Villains Defeated -->
            <h2>Defeated Villains</h2>
            <img src="path-to-defeated-villain1.jpg" alt="Defeated Villain 1" width="100" />
            <img src="path-to-defeated-villain2.jpg" alt="Defeated Villain 2" width="100" />
            <!-- Add more images as needed -->
    
            <!-- New Villains Suggestion -->
            <h2>New Challenges</h2>
            <img src="path-to-new-villain1.jpg" alt="New Villain 1" width="100" />
            <img src="path-to-new-villain2.jpg" alt="New Villain 2" width="100" />
            <!-- Add more images as needed -->
    
            <!-- Call to Action -->
            <a href="link-to-your-app-or-specific-section" class="cta">Take on New Villains</a>
        </div>
    
        <!-- Footer -->
        <div class="footer">
            <p>&copy; 2023 Your Company. All Rights Reserved.</p>
            <p>Your Company Address, City, Zip</p>
            <a href="link-to-unsubscribe">Unsubscribe</a>
        </div>
    </div>
</body>
</html>
`;

    const rawHTML2 = `
<style>
        body {
            font-family: 'RF Dewi', sans-serif;
            background-color: #f4f4f4;
            margin: 0;
            padding: 0;
        }

        .email-container {
            max-width: 600px;
            margin: 20px auto;
            background-color: #ffffff;
            padding: 20px;
            box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
        }

        .header {
            text-align: center;
            margin-bottom: 20px;
        }

        .content {
            margin-bottom: 20px;
        }

        .attribute {
            font-family: 'RF Dewi Condensed', sans-serif;
            color: #75aad2;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .footer {
            font-size: 12px;
            color: #a9a9a9;
            text-align: center;
        }

        .cta {
            display: block;
            background-color: #ff6901;
            color: #ffffff;
            text-align: center;
            padding: 10px 0;
            border-radius: 5px;
            text-decoration: none;
            margin: 20px 0;
        }

        /* Adding styles for section headers */
        .section-header {
            font-family: 'RF Dewi Condensed', sans-serif;
            background-color: #75aad2;
            color: #ffffff;
            padding: 5px 10px;
            border-radius: 3px;
            margin-top: 20px;
        }

        /* Style for activity details */
        .activity-details {
            border: 1px solid #a9a9a9;
            padding: 10px;
            margin-top: 10px;
        }

        .activity-name {
            font-weight: bold;
        }

        .activity-time, .activity-points {
            margin: 5px 0;
        }

        .container {
            color: white;
            text-align: center;
            margin: 10px;
        }

        .view-report-button {
            padding: 10px;
            border-radius: 10px;
            background: orange;
            color: white;
            text-decoration: none;
        }
    </style>
<div style="display: flex;">
    <div class="container">
        <div><img style="border-radius: 50px;" src="https://c5-na.altogic.com/_storage/645ad37d7ea57f56fe00c007/64b23300c84a89138270d3df/64d72a84526099f915d8dc7b" alt="Defeated Villain 1" width="100"></div>
        <div>Timid Tom</div>
        <div style="margin-bottom:10px">21-0</div>
        <div><a class="view-report-button" target="_new" href="https://app.fithero.ai/duelreport/650f791350530079063a981f">View Report</a></div>
    </div>
    
    <div class="container">
        <div><img style="border-radius: 50px;" src="https://c5-na.altogic.com/_storage/645ad37d7ea57f56fe00c007/64b23300c84a89138270d3df/64d72a84526099f915d8dc7b" alt="Defeated Villain 1" width="100"></div>
        <div>Timid Tom</div>
        <div style="margin-bottom:10px">21-0</div>
        <div><button class="view-report-button">View Report</button></div>
    </div>
</div>`
    // log.debug("Haroh?");

    return (
        <>
            <div>
                {<div dangerouslySetInnerHTML={{ __html: rawHTML }} />}
            </div>
        </>
    )
}
export default TestEmail