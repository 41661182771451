import { IonButton, IonCol, IonIcon, IonRow, IonTextarea } from "@ionic/react";
import { sendSharp } from "ionicons/icons";
import log from "loglevel";
import React, { useRef, useState } from "react";
import "../css/ChatInput.css";
import { SidekickMessage } from "../models/messageModels";
import { insertNewLines } from "../services/HelperService";
import { getRunStatus, processAssistantResponse, processEndOfRun, sendAssistantMessage, sendAssistantMessageWithRun } from "../services/AssistantService";
import { RunObject } from "../models/sideKickModels";


const AssistantChatInput = (props: {
	messages: SidekickMessage[];
	setMessages: React.Dispatch<React.SetStateAction<any>>;
	setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	setScroller: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
	const [input, setInput] = useState("");
	const inputRef = useRef<any>(null);

	async function processMessages(runObject: RunObject) {
		let sideKickResponse: SidekickMessage = await processEndOfRun(runObject.thread_id, runObject.id);
		let displayMessages = await processAssistantResponse(sideKickResponse, input);
		let lastSidekickMessage: SidekickMessage = displayMessages[displayMessages.length - 1];
		props.setMessages((prevMessages: SidekickMessage[]) => [...prevMessages, lastSidekickMessage]);
		// let lastSidekickMessage: SidekickMessage = displayMessages[displayMessages.length - 1];
		// let lastSidekickMessageArray = insertNewLines(lastSidekickMessage.message).split("\n");
		// lastSidekickMessageArray.forEach((message, index) => {
		// 	props.setProcessing(true);
		// 	// let randomMessageDelay = Math.floor(Math.random() * 3000) + 2000;
		// 	let randomMessageDelay = 4000;
		// 	setTimeout(() => {
		// 		let sideKickMessage: SidekickMessage = {
		// 			...lastSidekickMessage,
		// 			message: message
		// 		};
		// 		// Using functional update to ensure we have the latest state
		// 		props.setMessages((prevMessages: SidekickMessage[]) => [...prevMessages, sideKickMessage]);

		// 		if (index === lastSidekickMessageArray.length - 1) {
		// 			props.setProcessing(false);
		// 		} else {
		// 			props.setProcessing(true);
		// 		}
		// 	}, randomMessageDelay * (index + 1));
		// });
	}

	async function processInput(userInput: string, displayMessage: boolean) {
		if (userInput !== "") {
			setInput("");
			userInput = userInput.replace(/\n+$/, ""); // Remove trailing newlines

			let userMessage: SidekickMessage = {
				source: "user",
				message: userInput,
			};
			props.setMessages([...props.messages, userMessage]);

			let runObject: RunObject = await sendAssistantMessageWithRun(userInput);
			props.setProcessing(true);
			while (runObject.status !== "completed") {
				await new Promise(resolve => setTimeout(resolve, 3000)); // Sleep for 3 seconds
				runObject = await getRunStatus(runObject.thread_id, runObject.id);
				log.debug("RunObject in progress: ", runObject);
			}
			log.debug("RunObject completed: ", runObject);
			await processMessages(runObject);
			props.setProcessing(false);
		}
	}

	async function inputFocus() {
		log.debug("Focus on input. Should scroll");
		props.setScroller(true);
	}

	window.addEventListener("keyboardDidShow", async (event) => {
		// log.debug("KEYBOARD SHOWED");
		props.setScroller(true);
	});

	window.addEventListener("keyboardDidHide", async (event) => {
		// log.debug("KEYBOARD HID");
		props.setScroller(true);
	});

	// if not show a text box with a submit button
	return (
		<>
			<IonRow className="chat-input-row">
				<IonCol size="11">
					<IonTextarea
						value={input}
						autoGrow={true}
						rows={1}
						className="chat-input-textarea"
						ref={inputRef}
						placeholder="Type message..."
						// onKeyUp={handleKeyUp}
						onFocus={inputFocus}
						onKeyUp={(event) => {
							if (event.key === "Enter") {
								processInput(inputRef.current.value, true);
							}
						}}
					></IonTextarea>
				</IonCol>
				<IonCol size="1">
					<IonButton onClick={() => processInput(inputRef.current.value, true)} className="send-button" color="chat-bar-button" style={{ fontSize: "0px" }}>
						<IonIcon size="small" icon={sendSharp}></IonIcon>
					</IonButton>
				</IonCol>
			</IonRow>
		</>
	);
};

export default AssistantChatInput;
