import { IonButton, IonCheckbox, IonContent, IonInput, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup, IonSelect, IonSelectOption } from "@ionic/react";
import log from "loglevel";
import { useEffect, useState } from "react";
import { HeroObject } from "../models/userModel";
import { Villain } from "../models/villainModels";
import { simulateBattleServer } from "../services/DuelsServics";
import { getAttributeMatrix } from "../services/HelperService";
import { getAllHeroes } from "../services/UserService";
import { getVillain, getVillains } from "../services/VillainServices";

const Simulator = () => {
	// State to hold characters' attributes
	const [characters, setCharacters] = useState([
		{ type: 1, strength: 16, cardio: 25, mobility: 10, mindfulness: 1 },
		{ type: 2, strength: 1, cardio: 1, mobility: 1, mindfulness: 1 },
	]);

	const [matrix, setMatrix] = useState({
		accuracy: { strength: 0.25, cardio: 0.25, mobility: 0.25, mindfulness: 0.25, level: 0.25 },
		damage: { strength: 0.1, cardio: 0, mobility: 0, mindfulness: 0, level: 0.1 },
		health: { strength: 0.25, cardio: 0.25, mobility: 0.25, mindfulness: 0.25, level: 0.25 },
		dodge: { strength: 0.25, cardio: 0.25, mobility: 0, mindfulness: 0.25, level: 0.25 },
	});

	const [selectedHero, setSelectedHero] = useState<HeroObject>();
	const [selectedVillain, setSelectedVillain] = useState<Villain>();
	const [allHeroes, setAllHeroes] = useState<HeroObject[]>([]);
	const [allVillains, setAllVillains] = useState<Villain[]>([]);
	const [calculatedStats, setCalculatedStats] = useState(characters.map(calculateStats));
	const [includeWorkoutsCheckbox, setIncludeWorkoutsCheckbox] = useState(true);
	const [scaleVillainCheckbox, setScaleVillainCheckbox] = useState(true);
	const [villainDifficulty, setVillainDifficulty] = useState("easy");

	useEffect(() => {
		updateMatrix();
		fetchOptions();
	}, [])

	useEffect(() => {
		if (selectedHero !== undefined) {
			setCharacters(prevCharacters => [
				{ ...prevCharacters[0], strength: selectedHero.strengthLevel, cardio: selectedHero.cardioLevel, mobility: selectedHero.mobilityLevel, mindfulness: selectedHero.mindfulnessLevel },  // Just an example change. Adjust as needed.
				...prevCharacters.slice(1)
			]);
		}
	}, [selectedHero]);

	useEffect(() => {
		updateVillain()
	}, [selectedVillain, scaleVillainCheckbox, villainDifficulty]);

	useEffect(() => {
		setCalculatedStats(characters.map(calculateStats));
	}, [characters, matrix]); // Depend on both characters and matrix

	async function scaleVillain(villain: Villain): Promise<Villain> {
		let trueLevel = villain.level + 4;
		let scalingFactor = (selectedHero!.overallLevel / trueLevel);
		log.debug("base scalingFactor: ", scalingFactor)

		// return (await scaleVillainBasic(villain, villainDifficulty, selectedHero)).scaledVillain

		let heroOdds = 0
		let baseVillain = selectedVillain
		let checks = 0

		// After scaling, this is the desired probability of winning for the user
		let targetWinRate = 0.5;
		const winRateSpread = 0.09;
		if (villainDifficulty === 'easy') {
			targetWinRate = 0.70;
		} else if (villainDifficulty === 'medium') {
			targetWinRate = 0.5;
		} else if (villainDifficulty === 'hard') {
			targetWinRate = 0.3;
		} else if (villainDifficulty === 'boss') {
			targetWinRate = 0.1;
		}

		const lowEndHeroOdds = targetWinRate - winRateSpread;
		const highEndHeroOdds = targetWinRate + winRateSpread;
		log.debug("lowEndHeroOdds: ", lowEndHeroOdds)
		log.debug("highEndHeroOdds: ", highEndHeroOdds)
		while ((heroOdds <= lowEndHeroOdds || heroOdds >= highEndHeroOdds) && checks < 10) {
			villain.strength = Math.max(Math.round(baseVillain!.strength * scalingFactor), 1);
			villain.cardio = Math.max(Math.round(baseVillain!.cardio * scalingFactor), 1);
			villain.mobility = Math.max(Math.round(baseVillain!.mobility * scalingFactor), 1);
			villain.mindfulness = Math.max(Math.round(baseVillain!.mindfulness * scalingFactor), 1);
			villain.level = villain.strength + villain.cardio + villain.mobility + villain.mindfulness - 4;
			updateAttribute(1, "strength", villain.strength);
			updateAttribute(1, "cardio", villain.cardio);
			updateAttribute(1, "mobility", villain.mobility);
			updateAttribute(1, "mindfulness", villain.mindfulness);
			log.debug("villain strength: ", villain.strength, "villain cardio: ", villain.cardio, "villain mobility: ", villain.mobility, "villain mindfulness: ", villain.mindfulness, "villain level: ", villain.level)

			heroOdds = (await simulateBattle(false))
			log.debug("heroOdds: ", heroOdds)
			const ADJUSTMENT_FACTOR = 0.15; // you can tune this value

			if (heroOdds < lowEndHeroOdds) { // Villain is too hard
				log.debug("*** VILLAIN IS TOO HARD *** ")
				scalingFactor *= (1 - ADJUSTMENT_FACTOR);
				log.debug("updated scalingFactor: ", scalingFactor)
			} else if (heroOdds > highEndHeroOdds) { // Villain is too easy
				log.debug("*** VILLAIN IS TOO EASY ***")
				scalingFactor *= (1 + ADJUSTMENT_FACTOR);
				log.debug("updated scalingFactor: ", scalingFactor)
			} else {
				log.debug("*** VILLAIN IS JUST RIGHT ***")
				checks = 10
			}
			checks++
		}

		return villain;
	}

	async function updateVillain() {
		log.debug("Updating villain ")
		// log.debug("selectedVillain: ", selectedVillain)
		// if (selectedVillain !== undefined) {
		// 	setCharacters(prevCharacters => [
		// 		prevCharacters[0],
		// 		{ ...prevCharacters[1], strength: selectedVillain!.strength, cardio: selectedVillain!.cardio, mobility: selectedVillain!.mobility, mindfulness: selectedVillain!.mindfulness },  // Just an example change. Adjust as needed.
		// 	]);
		// }

		if (selectedVillain !== undefined) {
			let baseVillain = await getVillain(selectedVillain.villainID, true);
			// log.debug("baseVillain: ", baseVillain)
			if (scaleVillainCheckbox && selectedHero) {

				// log.debug("Scaling villain to hero level")
				// log.debug("selectedVillain: ", selectedVillain)
				let scaledVillain = await scaleVillain(baseVillain);
				setCharacters(prevCharacters => [
					prevCharacters[0],
					{ ...prevCharacters[1], strength: scaledVillain.strength, cardio: scaledVillain.cardio, mobility: scaledVillain.mobility, mindfulness: scaledVillain.mindfulness },  // Just an example change. Adjust as needed.
				]);
			} else {
				// log.debug("Setting villain back to base levels")
				// log.debug("baseVillain: ", baseVillain)
				if (baseVillain !== undefined) {
					// updateAttribute(1, "strength", baseVillain.strength);
					// updateAttribute(1, "cardio", baseVillain.cardio);
					// updateAttribute(1, "mobility", baseVillain.mobility);
					// updateAttribute(1, "mindfulness", baseVillain.mindfulness);
					setCharacters(prevCharacters => [
						prevCharacters[0],
						{ ...prevCharacters[1], strength: baseVillain!.strength, cardio: baseVillain!.cardio, mobility: baseVillain!.mobility, mindfulness: baseVillain!.mindfulness },  // Just an example change. Adjust as needed.
					]);
				}

			}
		}
	}

	async function updateMatrix() {
		interface Matrix {
			_id: string;
			createdAt: string;
			updatedAt: string;
			version: number;
			strengthAccuracy: number;
			strengthDamage: number;
			strengthHealth: number;
			strengthDodge: number;
			cardioAccuracy: number;
			cardioDamage: number;
			cardioHealth: number;
			cardioDodge: number;
			mobilityAccuracy: number;
			mobilityDamage: number;
			mobilityHealth: number;
			mobilityDodge: number;
			mindfulnessAccuracy: number;
			mindfulnessDamage: number;
			mindfulnessHealth: number;
			mindfulnessDodge: number;
			levelAccuracy: number;
			levelDamage: number;
			levelHealth: number;
			levelDodge: number;
			current: boolean;
		}
		try {
			// Replace with your API endpoint for fetching options.
			let object = await getAttributeMatrix()
			log.debug(`[updateMatrix] Object: `, object);
			setMatrix({
				accuracy: { strength: object.strengthAccuracy, cardio: object.cardioAccuracy, mobility: object.mobilityAccuracy, mindfulness: object.mindfulnessAccuracy, level: object.levelAccuracy },
				damage: { strength: object.strengthDamage, cardio: object.cardioDamage, mobility: object.mobilityDamage, mindfulness: object.mindfulnessDamage, level: object.levelDamage },
				health: { strength: object.strengthHealth, cardio: object.cardioHealth, mobility: object.mobilityHealth, mindfulness: object.mindfulnessHealth, level: object.levelHealth },
				dodge: { strength: object.strengthDodge, cardio: object.cardioDodge, mobility: object.mobilityDodge, mindfulness: object.mindfulnessDodge, level: object.levelDodge },
			})
		} catch (error) {
			console.error("[updateMatrix] There was an error fetching the options", error);
		}
	}

	async function fetchOptions() {
		try {
			let object = await getAllHeroes()
			log.debug(`[fetchOptions] Hero Objects: `, object);
			setAllHeroes(object);
			setAllVillains(await getVillains())
		} catch (error) {
			console.error("[fetchOptions] There was an error fetching the options", error);
		}
	};

	async function updateMatrixData(stat: keyof typeof matrix, attribute: keyof (typeof matrix)["accuracy"], value: number) {
		setMatrix({
			...matrix,
			[stat]: {
				...matrix[stat],
				[attribute]: value,
			},
		});
	};

	// Function to update attributes
	function updateAttribute(index: number, attribute: keyof (typeof characters)[0], value: number) {
		const newCharacters = [...characters];
		// only set the value if it's a number
		newCharacters[index][attribute] = value;
		setCharacters(newCharacters);
		const newStats = [...calculatedStats];
		newStats[index] = calculateStats(newCharacters[index]);
		setCalculatedStats(newStats);
	};
	// Function to calculate derived stats
	function calculateStats(character: (typeof characters)[0]) {
		// log.debug("character type: ", character.type)
		// log.debug("Calculating state for character: ", character)
		let accuracy, damage, health, dodge, level;
		if (character.type === 1) {
			level = character.strength + character.cardio + character.mobility + character.mindfulness;
			accuracy = Math.floor(
				character.strength * matrix.accuracy.strength +
				character.cardio * matrix.accuracy.cardio +
				character.mobility * matrix.accuracy.mobility +
				character.mindfulness * matrix.accuracy.mindfulness +
				level * matrix.accuracy.level +
				baselineStatsType1.accuracy
			);
			damage = Math.floor(
				character.strength * matrix.damage.strength +
				character.cardio * matrix.damage.cardio +
				character.mobility * matrix.damage.mobility +
				character.mindfulness * matrix.damage.mindfulness +
				level * matrix.damage.level +
				baselineStatsType1.damage
			);
			health = Math.floor(
				(character.strength * matrix.health.strength) +
				(character.cardio * matrix.health.cardio) +
				(character.mobility * matrix.health.mobility) +
				(character.mindfulness * matrix.health.mindfulness) +
				(level * matrix.health.level) +
				baselineStatsType1.health
			);
			dodge = Math.floor(
				character.strength * matrix.dodge.strength +
				character.cardio * matrix.dodge.cardio +
				character.mobility * matrix.dodge.mobility +
				character.mindfulness * matrix.dodge.mindfulness +
				level * matrix.dodge.level +
				baselineStatsType1.dodge
			);
		} else {
			level = character.strength + character.cardio + character.mobility + character.mindfulness - 4;
			accuracy = Math.floor(
				character.strength * matrix.accuracy.strength +
				character.cardio * matrix.accuracy.cardio +
				character.mobility * matrix.accuracy.mobility +
				character.mindfulness * matrix.accuracy.mindfulness +
				level * matrix.accuracy.level +
				baselineStatsType2.accuracy
			);
			damage = Math.floor(
				character.strength * matrix.damage.strength +
				character.cardio * matrix.damage.cardio +
				character.mobility * matrix.damage.mobility +
				character.mindfulness * matrix.damage.mindfulness +
				level * matrix.damage.level +
				baselineStatsType2.damage
			);
			health = Math.floor(
				character.strength * matrix.health.strength +
				character.cardio * matrix.health.cardio +
				character.mobility * matrix.health.mobility +
				character.mindfulness * matrix.health.mindfulness +
				level * matrix.health.level +
				baselineStatsType2.health
			);
			dodge = Math.floor(
				character.strength * matrix.dodge.strength +
				character.cardio * matrix.dodge.cardio +
				character.mobility * matrix.dodge.mobility +
				character.mindfulness * matrix.dodge.mindfulness +
				level * matrix.dodge.level +
				baselineStatsType2.dodge
			);
		}
		return {
			accuracy: accuracy,
			damage: damage,
			dodge: dodge,
			health: health,
			level: level,
		};
	};

	async function simulateBattle(showLogs: boolean = true) {
		const simulationCount = 1000;
		const [hero, villain] = characters.map(calculateStats);
		let heroObject = {} as HeroObject
		let villainObject = {} as Villain
		if (selectedHero) {
			heroObject = {
				...selectedHero,
				strengthLevel: characters[0].strength,
				cardioLevel: characters[0].cardio,
				mobilityLevel: characters[0].mobility,
				mindfulnessLevel: characters[0].mindfulness,
				heroToHit: hero.accuracy,
				heroDamage: hero.damage,
				heroHealth: hero.health,
				heroDodge: hero.dodge,
				overallLevel: hero.level,
			}
		}
		if (selectedVillain) {
			villainObject = {
				...selectedVillain,
				strength: characters[1].strength,
				cardio: characters[1].cardio,
				mobility: characters[1].mobility,
				mindfulness: characters[1].mindfulness,
				toHit: villain.accuracy,
				damage: villain.damage,
				health: villain.health,
				dodge: villain.dodge,
				level: villain.level,
			}
		}
		// let ho = await simulateBattleByVillainNew(heroObject, villainObject, 100, showLogs);
		log.debug("heroObject: ", heroObject)
		log.debug("villainObject: ", villainObject)
		let results = await simulateBattleServer(heroObject, villainObject);
		log.debug("Results: ", results);
		log.debug("Log: ", results.logString);

		return results.heroOdds;
	}

	return (
		<IonPage>
			<IonContent>
				<h3>Level Impacts Matrix</h3>
				<table>
					<thead>
						<tr>
							<th></th>
							<th>Strength</th>
							<th>Cardio</th>
							<th>Mobility</th>
							<th>Mindfulness</th>
							<th>Level</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(matrix).map((stat) => (
							<tr key={stat}>
								<td>{stat}</td>
								{Object.keys(matrix[stat as keyof typeof matrix]).map((attr) => (
									<td key={attr}>
										<IonInput
											label="Matrix"
											type="number"
											value={matrix[stat as keyof typeof matrix][attr as keyof (typeof matrix)["accuracy"]]}
											onIonChange={(e) => updateMatrixData(stat as keyof typeof matrix, attr as keyof (typeof matrix)["accuracy"], Number(e.detail.value))}
										/>
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
				<div>
					<IonItem>
						{/* Binding the checked property to our state and setting an event listener to update the state */}
						<IonCheckbox slot="start" checked={includeWorkoutsCheckbox} onIonChange={e => setIncludeWorkoutsCheckbox(e.detail.checked)} />
						<IonLabel>Include workouts in odds</IonLabel>
					</IonItem>
				</div>
				<div>
					<h3>Hero</h3>
					<IonLabel>Select an option:</IonLabel>
					<IonSelect value={selectedHero} onIonChange={e => setSelectedHero(e.detail.value)}>
						<IonSelectOption value="default">Choose an Option</IonSelectOption>
						{allHeroes.map(hero => (
							<IonSelectOption key={hero.heroID} value={hero}>
								{hero.heroName}
							</IonSelectOption>
						))}
					</IonSelect>
					{selectedHero && (
						<img src={selectedHero.heroImageURL} style={{ height: "200px", borderRadius: "200px" }} />
					)}
					<IonItem>
						<IonLabel>Strength:</IonLabel>
						<IonInput
							label="Strength"
							type="number"
							value={characters[0].strength}
							onIonChange={(e) => updateAttribute(0, "strength", Number(e.detail.value))}
						/>
					</IonItem>
					<IonItem>
						<IonLabel>Cardio:</IonLabel>
						<IonInput
							label="Cardio"
							type="number"
							value={characters[0].cardio}
							onIonChange={(e) => updateAttribute(0, "cardio", Number(e.detail.value))}
						/>
					</IonItem>
					<IonItem>
						<IonLabel>Mobility:</IonLabel>
						<IonInput
							label="Mobility"
							type="number"
							value={characters[0].mobility}
							onIonChange={(e) => updateAttribute(0, "mobility", Number(e.detail.value))}
						/>
					</IonItem>
					<IonItem>
						<IonLabel>Mindfulness:</IonLabel>
						<IonInput
							label="Mindfulness"
							type="number"
							value={characters[0].mindfulness}
							onIonChange={(e) => updateAttribute(0, "mindfulness", Number(e.detail.value))}
						/>
					</IonItem>
					<h4>Calculated Stats:</h4>
					{(() => {
						const { accuracy, damage, dodge, health, level } = calculatedStats[0]; // Get stats from calculatedStats
						return (
							<ul>
								<li>Accuracy: {accuracy}</li>
								<li>Damage: {damage}</li>
								<li>Dodge: {dodge}</li>
								<li>Health: {health}</li>
								<li>Level: {level}</li>
							</ul>
						);
					})()}
				</div>

				<div>
					<h3>Villain</h3>
					<IonLabel>Select an option:</IonLabel>
					<IonSelect value={selectedVillain} onIonChange={e => setSelectedVillain(e.detail.value)}>
						<IonSelectOption value="default">Choose an Option</IonSelectOption>
						{allVillains.map(villain => (
							<IonSelectOption key={villain.villainID} value={villain}>
								{villain.name} ({villain.level})
							</IonSelectOption>
						))}
					</IonSelect>
					{selectedVillain && (
						<img src={selectedVillain.imageURL} style={{ height: "200px", borderRadius: "200px" }} />
					)}
					<div>
						{/* <IonItem>
							<IonCheckbox slot="start" checked={scaleVillainCheckbox} onIonChange={e => setScaleVillainCheckbox(e.detail.checked)} />
							<IonLabel>Scale villain</IonLabel>
						</IonItem> */}
						<IonItem>
							{/* Binding the checked property to our state and setting an event listener to update the state */}
							<IonRadioGroup onIonChange={e => setVillainDifficulty(e.detail.value)}>
								<IonLabel>Difficulty:</IonLabel>
								<IonItem>
									<IonRadio value="easy">Easy</IonRadio>
								</IonItem>
								<IonItem>

									<IonRadio value="medium">Medium</IonRadio>
								</IonItem>
								<IonItem>

									<IonRadio value="hard">Hard</IonRadio>
								</IonItem>
								<IonItem>

									<IonRadio value="boss">Boss</IonRadio>
								</IonItem>
							</IonRadioGroup>
						</IonItem>
					</div>
					<IonItem>
						<IonLabel>Strength:</IonLabel>
						<IonInput
							label="Strength"
							type="number"
							value={characters[1].strength}
							onIonChange={(e) => updateAttribute(1, "strength", Number(e.detail.value))}
						/>
					</IonItem>
					<IonItem>
						<IonLabel>Cardio:</IonLabel>
						<IonInput
							label="Cardio"
							type="number"
							value={characters[1].cardio}
							onIonChange={(e) => updateAttribute(1, "cardio", Number(e.detail.value))}
						/>
					</IonItem>
					<IonItem>
						<IonLabel>Mobility:</IonLabel>
						<IonInput
							label="Mobility"
							type="number"
							value={characters[1].mobility}
							onIonChange={(e) => updateAttribute(1, "mobility", Number(e.detail.value))}
						/>
					</IonItem>
					<IonItem>
						<IonLabel>Mindfulness:</IonLabel>
						<IonInput
							label="Mindfulness"
							type="number"
							value={characters[1].mindfulness}
							onIonChange={(e) => updateAttribute(1, "mindfulness", Number(e.detail.value))}
						/>
					</IonItem>
					<h4>Calculated Stats:</h4>
					{(() => {
						const { accuracy, damage, dodge, health, level } = calculatedStats[1]; // Get stats from calculatedStats
						return (
							<ul>
								<li>Accuracy: {accuracy}</li>
								<li>Damage: {damage}</li>
								<li>Dodge: {dodge}</li>
								<li>Health: {health}</li>
								<li>Level: {level}</li>
							</ul>
						);
					})()}
				</div>

				<IonButton onClick={() => simulateBattle()}>Simulate Combat</IonButton>
			</IonContent>
		</IonPage>
	);
};


const baselineStatsType1 = {
	accuracy: 50,
	damage: 10,
	health: 100,
	dodge: 0,
};

const baselineStatsType2 = {
	accuracy: 50,
	damage: 10,
	health: 100,
	dodge: 0,
};

export default Simulator;
