import {
    IonContent,
    IonPage
} from "@ionic/react";
import { useContext } from "react";
import GlobalState from "../contexts/GlobalState";
import "../css/PayWall.css";
import "../css/Profile.css";

import { PayWallClassDojoComponent } from "./components/PayWallClassDojoComponent";

export const PaywallClassDojo = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile, heroObject, productsForPaywall, paywallFeatures } = globalState;


    return (
        <IonPage>
            <IonContent>
                <PayWallClassDojoComponent />
            </IonContent>
        </IonPage>
    );

}
