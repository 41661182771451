import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { sendSlackNotification } from "../services/NotificationService";
import log from "loglevel";

const AppURLListener = () => {
	let history = useHistory();
	useEffect(() => {
		App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
			// Example url: https://beerswift.app/tabs/tab2
			// slug = /tabs/tab2
			log.debug("[AppURLListener] App opened with URL:", event);
			// sendSlackNotification(`App opened with URL: ${JSON.stringify(event)}`, "notificationtesting", true);
			const slug = event.url.split("FitHero:/").pop();
			log.debug("[AppURLListener] slug: ", slug);
			if (slug) {
				log.debug("[AppURLListener] navigating to: ", slug);
				history.push(slug);
			} else {
				log.debug("[AppURLListener] no slug found: ", slug);
			}
		});
	}, []);

	return null;
};

export default AppURLListener;
