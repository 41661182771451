/* eslint-disable react-hooks/rules-of-hooks */
import { IonCol, IonRow } from "@ionic/react";
import { useEffect, useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { cardioColor, mindfulnessColor, mobilityColor, strengthColor } from "../../../constants/theme";
import "../../../css/Badge.css";
import "../../../css/HeroHealthComponent.css";
import { HeroObject } from "../../../models/userModel";

export default function OtherHeroCircularComponent(props: { hero: HeroObject, showLoading: boolean }) {

    interface VillainStats {
        strengthLevel: number;
        cardioLevel: number;
        mindfulnessLevel: number;
        mobilityLevel: number;
    }

    const [progressBar, setProgressBar] = useState<VillainStats>();

    useEffect(() => {
        if (props.hero) {
            setProgressBar({
                strengthLevel: props.hero.strengthLevel,
                cardioLevel: props.hero.cardioLevel,
                mindfulnessLevel: props.hero.mindfulnessLevel,
                mobilityLevel: props.hero.mobilityLevel,
            });
        }
    }, [props.hero, props.showLoading]);

    const attributes = ["strength", "cardio", "mobility", "mindfulness"] as const;
    type AttributeType = (typeof attributes)[number];

    function attributeIcon(attribute: AttributeType) {
        switch (attribute) {
            case "strength":
                return "💪";
            case "cardio":
                return "💚"
            case "mobility":
                return "🧘"
            case "mindfulness":
                return "🙏"
        }
    }

    return (
        <>
            {props.hero && progressBar ? (
                <div className="progress-bar-container">
                    <IonRow className="attribute-container">
                        {attributes.map((attribute: AttributeType) => {
                            let level = progressBar[`${attribute}Level` as keyof VillainStats];
                            let barColor = "";
                            switch (attribute) {
                                case "strength":
                                    barColor = `${strengthColor}`;
                                    break;
                                case "cardio":
                                    barColor = `${cardioColor}`;
                                    break;
                                case "mobility":
                                    barColor = `${mobilityColor}`;
                                    break;
                                case "mindfulness":
                                    barColor = `${mindfulnessColor}`;
                                    break;
                            }
                            return (
                                <IonCol key={`${attribute}`} size="2.5" className="circular-progress-col">
                                    <div className="attribute-title">{attributeIcon(attribute)}<br />{attribute}</div>
                                    <CircularProgressbar
                                        styles={buildStyles({
                                            pathColor: `${barColor}`,
                                            textColor: "white",
                                            textSize: "30px",
                                        })}
                                        value={100}
                                        text={props.showLoading === true ? `...` : `${level}`}

                                    />
                                </IonCol>
                            );
                        })}
                    </IonRow>
                </div>
            ) : (
                ""
            )}
        </>
    );
}
