import { IonButton, IonContent, IonFooter, IonLoading, IonPage } from '@ionic/react';
import { Steps } from "intro.js-react";
import { useContext, useEffect, useState } from 'react';
import Confetti from "react-confetti";
import DuelStatusInfo from '../../Features/Duels/components/DuelStatusInfo';
import DuelsLedgerComponent from '../../Features/Duels/components/DuelUpdates';
import HeaderLogo from '../../components/HeaderLogo';
import GlobalState from '../../contexts/GlobalState';
import { DuelStatus, duelInfoExplanationStepsOnboarding } from '../../models/duelsModels';
import { OnboardingObject } from '../../models/userModel';
import { getCurrentDuel } from '../../services/DuelsServics';
import { updateCoachMark } from '../../services/HelperService';
import { StorageService } from '../../services/StorageService';
import { getHero } from '../../services/UserService';
import log from 'loglevel';


const CongratsOnFirstDuel = () => {
    const nextPage = "/onboarding/explainstats";
    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, setHeroObject } = globalState;
    const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
    const [duelStatus, setDuelStatus] = useState<DuelStatus>();
    const [showDuelCoachMarks, setShowDuelCoachMarks] = useState<boolean>(false);
    const [showProgressButton, setShowProgressButton] = useState<boolean>(false);
    const [loadingDuelInfo, setLoadingDuelInfo] = useState(false);

    useEffect(() => {
        updateOnboardingObject();
        getDuelInfo();
    }, []);

    async function updateOnboardingObject() {
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        if (!heroObject && tempOnboardingObject.hero) {
            setHeroObject(tempOnboardingObject.hero);
        } else if (!heroObject) {
            let hero = await getHero();
            setHeroObject(hero);
            tempOnboardingObject.hero = hero;
        }
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        setOnboardingObject(tempOnboardingObject);
        await storageService.setObject("onboarding", tempOnboardingObject);
    }

    async function getDuelInfo() {
        setLoadingDuelInfo(true);
        let currentDuel = await getCurrentDuel();
        if (currentDuel) {
            setDuelStatus(await getCurrentDuel());
        } else {
            log.error("No current duel found");
            alert("Hmm, sothing went wrong. No current duel found. Try tapping back and trying again...");
        }
        setLoadingDuelInfo(false);
    }

    async function duelExplanationCoachMarkComplete() {
        // log.debug("Coach marks complete");
        await updateCoachMark("duelExplanation", true)
        setShowDuelCoachMarks(false);
        setShowProgressButton(true);
    }

    return (
        <IonPage>
            <HeaderLogo />
            <Confetti height={window.innerHeight} numberOfPieces={1500} recycle={false} />
            <IonLoading isOpen={loadingDuelInfo} message={"Loading..."} backdropDismiss duration={5000} />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Congrats on starting your first duel!</div>
                    {duelStatus && (
                        <>
                            <Steps
                                enabled={showDuelCoachMarks}
                                steps={duelInfoExplanationStepsOnboarding}
                                initialStep={0}
                                onExit={duelExplanationCoachMarkComplete}
                                options={{
                                    showBullets: false,
                                    exitOnOverlayClick: false,
                                    exitOnEsc: true,
                                    showButtons: true,
                                    disableInteraction: false,
                                    hidePrev: true,
                                    nextLabel: "▶",
                                    prevLabel: "◀",
                                    doneLabel: "✔",
                                }}
                            />
                            <DuelStatusInfo duelStatus={duelStatus} />
                            <div className='onboarding-text-area' style={{ textAlign: "center" }}>
                                <p>
                                    You will now duel this villain <br />to the death!
                                </p>
                            </div>
                            <DuelsLedgerComponent duelID={duelStatus.statusId} duelStatus={duelStatus} showTimer={false} />
                            <div style={{ textAlign: "center", marginTop: "30px" }}>
                                {showProgressButton === false && showDuelCoachMarks === false && (
                                    <IonButton color={'tertiary'} className="onboarding-advance-button" onClick={() => setShowDuelCoachMarks(true)}>
                                        How does that work?
                                    </IonButton>
                                )}

                            </div>
                        </>
                    )}

                </div>

            </IonContent>
            {showProgressButton && (
                <IonFooter className="onboarding-footer">
                    <IonButton className="onboarding-advance-button" routerLink={nextPage} >
                        Got it
                    </IonButton>
                </IonFooter>
            )}

        </IonPage>
    )
}

export default CongratsOnFirstDuel