/* eslint-disable no-template-curly-in-string */
import log from "loglevel";
import Parse from 'parse';
import { HERO_MESSAGES_TABLE, HERO_PROMPTS_TABLE } from "../models/databaseModels";
import { FullMessageObject, HeroMessage, heroDisplayMessagesKey, lastHeroPromptKey } from "../models/herochatModel";
import { HeroObject, UserObject } from "../models/userModel";
import { sendSlackErrorNotification } from "./NotificationService";
import { StorageService } from "./StorageService";
import { getHero, getUser } from "./UserService";
import moment from "moment";
const storageService = new StorageService();


export interface HeroMessagesResponse {
    messages: HeroMessage[];
    newMessages: boolean;
    numberOfNewMessages: number;
}

export async function getHeroMessagesFromDB(heroId?: string): Promise<HeroMessagesResponse> {
    if (!heroId) {
        let tempHero = await getHero();
        if (tempHero === null) {
            throw new Error(`[getHeroMessagesFromDB] hero is null`);
        }
        heroId = tempHero.heroID!
    }
    // Get all sidekick messages
    // log.debug("[getHeroMessagesFromDB] Fetching sidekick messages for threadId: ", threadId);
    let existingMessages = await storageService.getObject(heroDisplayMessagesKey);
    let dbMessages: HeroMessage[] = [];
    let query = new Parse.Query(HERO_MESSAGES_TABLE);
    query.equalTo("heroId", heroId);
    query.limit(10000);
    query.descending("createdAt");
    let results = await query.find();
    results.forEach((result) => {
        dbMessages.push(result.toJSON() as unknown as HeroMessage);
    });
    // log.debug("[getHeroMessagesFromDB] Messages: ", allMessages);
    // get the oldest message in the existing messages array
    // sort existing messages by createdAt where the oldest message is the first element
    // existingMessages = existingMessages.sort((a: any, b: any) => {
    //     return moment(a.createdAt).unix() + moment(b.createdAt).unix();
    // });
    // get the oldest message in the all messages array
    // sort all messages by createdAt where the oldest message is the first element
    if (existingMessages !== null) {
        existingMessages = existingMessages.reverse();
    } else {
        existingMessages = [];
    }
    log.debug("[getHeroMessagesFromDB] first dbMessages: ", dbMessages[0]);
    let newMessages = false;
    let numberOfNewMessages = 0;
    if (existingMessages.length === 0 || moment(dbMessages[0].createdAt).isAfter(moment(existingMessages[0].createdAt))) {
        newMessages = true;
        if (existingMessages.length > 0) {
            numberOfNewMessages = dbMessages.filter((message) => moment(message.createdAt).isAfter(moment(existingMessages[0].createdAt))).length;
        } else {
            numberOfNewMessages = dbMessages.length;
        }
    }

    existingMessages = dbMessages.reverse();

    await storageService.setObject(heroDisplayMessagesKey, existingMessages);
    return {
        messages: dbMessages,
        newMessages: newMessages,
        numberOfNewMessages: numberOfNewMessages,
    }
}

export async function getHeroMessages(heroId?: string): Promise<HeroMessage[]> {
    if (!heroId) {
        let tempHero = await getHero();
        if (tempHero === null) {
            throw new Error(`[getHeroMessagesFromDB] hero is null`);
        }
        heroId = tempHero.heroID!
    }
    let messages: HeroMessage[] = await storageService.getObject(heroDisplayMessagesKey);
    getHeroMessagesFromDB(heroId).then((dbMessages: HeroMessagesResponse) => {
        // if dbMessages is different than messages, update messages
        if (dbMessages.messages.length !== messages.length) {
            return dbMessages.messages;
        }
    });
    if (messages) {
        return messages;
    } else {
        return [] as HeroMessage[]
    }
}

export async function sendHeroMessage(message: string, hero?: HeroObject, user?: UserObject): Promise<FullMessageObject> {
    if (!user) {
        let tempUser = await getUser();
        if (tempUser === null) {
            throw new Error(`[sendHeroMessage] user is null`);
        }
        user = tempUser;
    }
    if (!hero) {
        hero = await getHero();
    }
    const params = {
        user: user,
        hero: hero,
        message: message,
    }
    // sendSlackNotification(`*New Hero Message*\nHero: ${hero.heroName}\nSource: Hero\nMessage: ${message}`, "heroMessages");
    log.debug(`[sendHeroMessage] running function`)
    log.debug(`[sendHeroMessage] params: `, params)
    // Returning a full message object so all fields are populated locally
    let fullMessageObject: { heroMessageObject: HeroMessage, userMessageObject: HeroMessage } = await Parse.Cloud.run('createHeroMessage', params).catch((error) => {
        sendSlackErrorNotification(`[sendHeroMessage] Full Error Obj`, "sendHeroMessage", `${error}`);
        console.error(`[sendHeroMessage] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[sendHeroMessage] sendHeroMessage: `, fullMessageObject)
    if (!fullMessageObject) {
        throw new Error(`[sendHeroMessage] sendHeroMessage is null`);
    }
    return fullMessageObject;
}

export async function sendHeroSystemMessage(primaryResponseType: string, secondaryResponseType: string, message: string, hero?: HeroObject, user?: UserObject): Promise<HeroMessage> {
    if (!user) {
        let tempUser = await getUser();
        if (tempUser === null) {
            throw new Error(`[sendHeroMessage] user is null`);
        }
        user = tempUser;
    }
    if (!hero) {
        hero = await getHero();
    }
    const params = {
        user: user,
        hero: hero,
        message: message,
        responseType: {
            primaryResponseType: primaryResponseType,
            secondaryResponseType: secondaryResponseType,
        }
    }
    // sendSlackNotification(`*New Hero Message*\nHero: ${hero.heroName}\nSource: Hero\nMessage: ${message}`, "heroMessages");
    log.debug(`[sendHeroSystemMessage] running function`)
    log.debug(`[sendHeroSystemMessage] params: `, params)
    // Returning a full message object so all fields are populated locally
    let fullMessageObject: HeroMessage = await Parse.Cloud.run('createHeroMessageSystem', params).catch((error) => {
        sendSlackErrorNotification(`[sendHeroSystemMessage] Full Error Obj`, "sendHeroSystemMessage", `${error}`);
        console.error(`[sendHeroSystemMessage] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[sendHeroSystemMessage] sendHeroSystemMessage: `, fullMessageObject)
    if (!fullMessageObject) {
        throw new Error(`[sendHeroSystemMessage] sendHeroSystemMessage is null`);
    }
    return fullMessageObject;
}


export async function processHeroResponse(fullMessageObject: FullMessageObject): Promise<HeroMessage[]> {
    let existingDisplayMessages: HeroMessage[] = [];
    existingDisplayMessages = await storageService.getObject(heroDisplayMessagesKey);// get existing messages
    log.debug(`[processHeroResponse] existingDisplayMessages: `, existingDisplayMessages);
    if (existingDisplayMessages === null) existingDisplayMessages = []; // if no messages, set to empty array
    let updatedDisplayResponse: HeroMessage[] = [];
    updatedDisplayResponse = [...existingDisplayMessages, fullMessageObject.userMessageObject]; // add user message to existing messages
    updatedDisplayResponse = [...updatedDisplayResponse, fullMessageObject.heroMessageObject]; // add hero message to existing messages
    log.debug(`[processHeroResponse] updatedDisplayResponse: `, updatedDisplayResponse);
    await storageService.setObject(heroDisplayMessagesKey, updatedDisplayResponse); // save updated messages
    return updatedDisplayResponse; // return updated messages
}

export async function processHeroSystemResponse(heroResponse: HeroMessage): Promise<HeroMessage[]> {
    let existingDisplayMessages: HeroMessage[] = [];
    existingDisplayMessages = await storageService.getObject(heroDisplayMessagesKey);// get existing messages
    log.debug(`[processHeroSystemResponse] existingDisplayMessages: `, existingDisplayMessages);
    if (existingDisplayMessages === null) existingDisplayMessages = []; // if no messages, set to empty array
    let updatedDisplayResponse: HeroMessage[] = [];
    updatedDisplayResponse = [...existingDisplayMessages, heroResponse]; // add user message to existing messages
    log.debug(`[processHeroSystemResponse] updatedDisplayResponse: `, updatedDisplayResponse);
    await storageService.setObject(heroDisplayMessagesKey, updatedDisplayResponse); // save updated messages
    return updatedDisplayResponse; // return updated messages
}

export type PersonalityDescriptions = {
    personalityType: string;
    personalityTypeDescription: string;
    icon: string;
    premium: boolean;
}

export async function getPersonalityDescriptions(): Promise<PersonalityDescriptions[]> {
    let personalityDescriptions: PersonalityDescriptions[] = [];
    let query = new Parse.Query('hero_personalities');
    query.limit(10000);
    let results = await query.find();
    results.forEach((result) => {
        personalityDescriptions.push(result.toJSON() as unknown as PersonalityDescriptions);
    });
    return personalityDescriptions;
}

export async function shouldSendHeroPrompt(): Promise<boolean> {
    let lastHeroPrompt = await storageService.getItem(lastHeroPromptKey);
    if (lastHeroPrompt === "" || moment().diff(moment(lastHeroPrompt), 'hours') > 4) {
        return true;
    } else {
        return false;
    }
}

export type HeroPrompt = {
    primaryResponseType: string;
    secondaryResponseType: string;
    prompt: string;
    objectId: string;
}

export type SystemMessage = {
    responseType: {
        primaryResponseType: string;
        secondaryResponseType: string;
    }
    message: string;
}

export async function getHeroPrompts(): Promise<HeroPrompt[]> {
    let heroPrompts: HeroPrompt[] = [];
    let query = new Parse.Query(HERO_PROMPTS_TABLE);
    query.limit(10000);
    let results = await query.find();
    results.forEach((result) => {
        heroPrompts.push(result.toJSON() as unknown as HeroPrompt);
    });
    return heroPrompts;
}