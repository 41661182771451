import { IonAlert, IonButton, IonContent } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { StorageService } from '../../../services/StorageService';

export const ChallengeExplanation = () => {
    const storageService = new StorageService();
    const [inChallenge, setInChallenge] = useState<boolean>(false)
    const [showAlert, setShowAlert] = useState<boolean>(false)
    useEffect(() => {
        async function checkChallenge() {
            let challenge = await storageService.getItem("challengeStatus");
            if (challenge === "true") {
                setInChallenge(true)
            }
        }
        checkChallenge();
    }, [])

    async function joinChallenge(confirmed: boolean = false) {
        if (confirmed) {
            setShowAlert(false)
            await storageService.setItem("challengeStatus", "true")
            setInChallenge(true)
        } else {
            setShowAlert(true)
        }
    }
    return (
        <>
            <IonContent className="ion-padding">
                <IonAlert isOpen={showAlert} header="Join Challenge" message="Are you sure you're ready to join the challenge?" buttons={[{ text: 'Cancel', role: 'cancel' }, { text: "Let's do it!", handler: () => joinChallenge(true) }]} />
                {!inChallenge ? (
                    <div style={{ textAlign: "center" }}>
                        <IonButton color={'tertiary'} className="onboarding-advance-button" style={{ color: "white" }} onClick={() => joinChallenge(false)}>
                            Join Now
                        </IonButton>
                    </div>
                ) : (
                    <div style={{ textAlign: "center", border: "1px solid white" }}>
                        You're in the challenge! Good luck!
                    </div>

                )}
                <h1 style={{ textAlign: "center" }}>Welcome to the <br />Heroic Summer Shape-Up!</h1><br />
                <p>Ready to heat up your fitness journey this summer? Join the Heroic Summer Shape-Up challenge!</p><br />
                <p>Participating is <span className='onboarding-emphasis'>EASY</span>. Simply battle villains and invite friends throughout the month of June!</p><br />

                <p>

                </p>
                <p>You'll earn points, rise to top the leaderboard and earn a chance at some great prizes.</p><br />
                <p>It's the <span className='onboarding-emphasis'>PERFECT</span> way to establish healthy habits for the summer, look your best for those summer cookouts, and feel all around better!</p>
                <br />
                <h2>Earning Points</h2>
                <div>
                    <div><br />
                        <span className='onboarding-emphasis'>Defeat Villains</span><p>Challenge villains of varying difficulties and earn points based on their difficulty level:</p>
                        <li>Easy Difficulty: 10 points</li>
                        <li>Medium Difficulty: 30 points</li>
                        <li>Hard Difficulty: 50 points</li>
                        <li>Boss Difficulty: 100 points</li>
                    </div><br />
                    <div>
                        <span className='onboarding-emphasis'>Invite Friends</span><p>Every new person you invite who registers for FitHero earns you an additional 50 points. Tap the share button to get your custom code!</p>
                    </div>
                </div>
                <br />
                <h2>Tracking Your Progress</h2><br />
                <p>Check your progress in this section of the app. Here you'll find the leaderboard where you can see how you stack up against other heroes.</p>
                <br />
                <h2>Participation</h2><br />
                <p>Duration: The challenge starts Monday June 3 00:00 and ends Wednesday July 3 23:59 Pacific Time (GMT-7)</p><br />
                <p>Open to All: There are no specific requirements for participation—everyone is welcome!</p><br />
                <p>Join Anytime: Feel free to join the challenge at any point during its duration. It's never too late to start your journey!</p>
                <br />
                <h2>Villains</h2><br />
                <p>Choose Your Challenge: With over 50 villains available, select the difficulty level for each duel to suit your fitness level and strategy.</p>
                <br />
                <h2>Prizes</h2><br />
                <div>
                    <div>
                        <span className='onboarding-emphasis'>First Place:</span>
                        <li>Lifetime premium membership</li>
                        <li>Personalized gym bag with your hero name on it</li>
                        <li>FitHero t-shirt</li>
                        <li>FitHero water bottle</li>
                        <li>Have your hero become a villain in the app!</li>
                    </div>
                    <br />
                    <div>
                        <span className='onboarding-emphasis'>Second Place:</span>
                        <li>Six-month premium membership</li>
                        <li>FitHero workout towel</li>
                        <li>FitHero water bottle</li>
                        <li>Have your hero become a villain in the app!</li>
                    </div>
                    <br />
                    <div>
                        <span className='onboarding-emphasis'>Third Place:</span>
                        <li>Three-month premium membership</li>
                        <li>FitHero water bottle</li>
                        <li>Have your hero become a villain in the app!</li>
                    </div>
                </div>
                <br />
                <h2>Community</h2><br />
                <p>Connect and Share: Join our <a href='https://www.facebook.com/groups/fithero'>Facebook group</a> to connect with other participants, share your progress, and get extra motivation!</p>
                <br />
                <h2>Questions?</h2>
                <p><a href="mailto:adam@fithero.ai">Drop us a note at adam@fithero.ai</a></p><br />
            </IonContent>
        </>
    )
}
