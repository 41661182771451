import React, { useEffect, useState } from "react";
import { StorageService } from "../services/StorageService";
import {
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonIcon,
	IonLabel,
	IonPage,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonTitle,
	IonToolbar,
	useIonViewWillEnter,
} from "@ionic/react";
import moment from "moment";
import { analyticsOutline, newspaperOutline } from "ionicons/icons";
import { activityLedgerKey, workoutKey } from "../models/userModel";
import { ActivityLedger } from "../models/exerciseModels";
import log from "loglevel";
const storageService = new StorageService();

function FullWorkoutLog() {
	const [workoutLog, setWorkoutLog] = useState<any>([]);
	const [activityLedger, setActivityLedger] = useState<any>([]);
	const [lastUpdated, setLastUpdated] = useState<string>("");
	const [view, setView] = useState<string | undefined>("workout");

	useIonViewWillEnter(() => {
		async function getWorkoutLogFile() {
			let workouts = await storageService.getObject(workoutKey);
			// sort workouts by endDate
			workouts.sort((a: any, b: any) => {
				return moment(b.endDate).diff(moment(a.endDate));
			});
			log.debug(`[JS workouts]: `, workouts);
			setWorkoutLog(workouts);
		}
		async function getActivityLogFile() {
			let ledger = await storageService.getObject(activityLedgerKey);
			log.debug(`[JS activityLedger]: `, ledger);
			setActivityLedger(ledger);
		}
		async function getLastUpdated() {
			let lastUpdated = await storageService.getItem("lastUpdated");
			setLastUpdated(moment(lastUpdated).format("YYYY-MM-DD hh:mm a"));
		}
		getWorkoutLogFile();
		getActivityLogFile();
		getLastUpdated();
	});
	return (
		<IonPage>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Workout Log Work</IonTitle>
				</IonToolbar>
			</IonHeader>
			Last Updated: {lastUpdated}
			<div style={{ minHeight: "10%" }}>
				<IonSegment mode="ios" color="tertiary" value={view} onIonChange={(e) => setView(e.detail.value)} style={{ position: "fixed", zIndex: "20000" }}>
					<IonSegmentButton className="squad-home-segement-button" value="workout" layout="icon-top">
						<IonIcon icon={newspaperOutline} />
						<IonLabel className="squad-home-label">Workout Log</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton className="squad-home-segement-button" value="activity" layout="icon-top">
						<IonIcon icon={analyticsOutline} />
						<IonLabel className="squad-home-label">Activity Ledger</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</div>
			{view === "workout" && (
				<>
					<IonContent>
						{workoutLog && (
							<>
								{log.debug(`[JS Workoutlog]: `, workoutLog)}
								<IonGrid style={{ fontSize: "10px", textAlign: "center" }}>
									<IonRow>
										<IonCol>Activity Type</IonCol>
										<IonCol>Start Date</IonCol>
										<IonCol>End Date</IonCol>
										<IonCol>Total Energy Burned</IonCol>
										<IonCol>Calories Per Minute</IonCol>
										<IonCol>Intensity Level</IonCol>
									</IonRow>
									{workoutLog.map((workout: any) => (
										<IonRow key={workout.UUID}>
											<IonCol>{workout.activityType}</IonCol>
											<IonCol>{moment(workout.startDate).format("MM-DD-YY h:mm a")}</IonCol>
											<IonCol>{moment(workout.endDate).format("MM-DD-YY h:mm a")}</IonCol>
											<IonCol>{workout.totalEnergyBurned}</IonCol>
											<IonCol>{workout.caloriesPerMinute}</IonCol>
											<IonCol>{workout.intensityLevel}</IonCol>
										</IonRow>
									))}
								</IonGrid>
							</>
						)}
					</IonContent>
				</>
			)}
			{view === "activity" && (
				<>
					<IonContent>
						{activityLedger && (
							<>
								<IonGrid style={{ fontSize: "10px", textAlign: "center" }}>
									<IonRow>
										<IonCol>Activity Name</IonCol>
										<IonCol>Activity Date</IonCol>
										<IonCol>Duration</IonCol>
										<IonCol>Strength Points</IonCol>
										<IonCol>Cardio Points</IonCol>
										<IonCol>Mobility Points</IonCol>
									</IonRow>
									{activityLedger.map((workout: ActivityLedger) => (
										<IonRow key={workout.uuid}>
											<IonCol>{workout.activityName}</IonCol>
											<IonCol>{moment(workout.activityDate).format("MM-DD-YY h:mm a")}</IonCol>
											<IonCol>{workout.durationMinutes}</IonCol>
											<IonCol>{workout.strengthPoints}</IonCol>
											<IonCol>{workout.cardioPoints}</IonCol>
											<IonCol>{workout.mobilityPoints}</IonCol>
										</IonRow>
									))}
								</IonGrid>
							</>
						)}
					</IonContent>
				</>
			)}
		</IonPage>
	);
}

export default FullWorkoutLog;
