import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import HeaderLogo from '../../components/HeaderLogo';
import GlobalState from '../../contexts/GlobalState';
import { OnboardingObject } from '../../models/userModel';
import { StorageService } from '../../services/StorageService';
import AthleteCircularComponent from '../AthleteCircularComponent';
import { FamousAthletes } from '../famousAthletes';

const FamousExamples = () => {
    const nextPage = "/onboarding/connecttracker";
    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    useEffect(() => {
        updateOnboardingObject();
    }, []);

    async function updateOnboardingObject() {
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        await storageService.setObject("onboarding", tempOnboardingObject);
    }
    return (
        <IonPage>
            <HeaderLogo />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Some Famous Examples</div>
                    <div className='onboarding-text-area '>
                        <p>
                            While it will take a lot of work to get you to the level of these famous athletes, with FitHero you will have a fun way to track your progress and stay motivated.
                        </p>
                    </div>

                    {FamousAthletes.map((athlete, index) => {
                        return (
                            <>
                                <div style={{ textAlign: "center" }}>
                                    <img className="famous-athlete-image" src={athlete.image} alt="" /><br />
                                    <div className="famous-athlete-name">{athlete.name}</div>
                                </div>
                                <div>
                                    <AthleteCircularComponent stats={athlete.stats} />
                                </div>
                            </>

                        )
                    })}
                </div>
            </IonContent>
            <IonFooter className="onboarding-footer">
                <IonButton className="onboarding-advance-button" routerLink={nextPage}>
                    Makes sense
                </IonButton>
            </IonFooter>
        </IonPage>
    )
}

export default FamousExamples