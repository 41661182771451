import React, { useEffect, useState } from 'react'
import { HeroObject } from '../models/userModel'
import { getAllHeroes, getHero, getLeaderboard } from '../services/UserService'
import { IonAlert, IonAvatar, IonButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonLoading, IonPage, IonRow, IonSpinner, IonTitle, IonToolbar } from '@ionic/react'
import { logGenericEvent } from '../services/AnalyticsService'
import { refreshCircleOutline } from 'ionicons/icons'
import "../css/Feed.css";
import log from 'loglevel'
import { useHistory } from 'react-router'
import { LeaderboardObjects } from '../models/duelsModels'

const MiniLeaderboard = () => {
    const history = useHistory();
    const [leaderboard, setLeaderboard] = useState<LeaderboardObjects[]>([])
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [refreshAlert, setRefreshAlert] = useState<boolean>(false);
    const [heroId, setHeroId] = useState<string>("")


    useEffect(() => {
        refreshHeroData()
    }, [])

    async function refreshHeroData(refresh: boolean = false) {
        setRefreshing(true)
        let heroId = (await getHero()).heroID!
        setHeroId(heroId)
        let heroes = await getLeaderboard()
        let lb: LeaderboardObjects[] = []
        let position = 1
        heroes.forEach(async (hero) => {
            lb.push({
                position: position,
                hero: hero
            })
            position++
        })
        // trim the list to only 2 heroes above and below the current hero
        let index = lb.findIndex(lbObj => lbObj.hero.heroID === heroId)
        // if the hero is in the top 5, only show the top 5
        if (index < 2) {
            lb = lb.slice(0, 5)
        }
        // if the hero is in the bottom 5, only show the bottom 5
        else if (index > lb.length - 3) {
            lb = lb.slice(lb.length - 5, lb.length)
        }
        else {
            lb = lb.slice(index - 2, index + 3)
        }
        setLeaderboard(lb)
        setRefreshing(false)
    }

    async function tapAvatar(heroId: string) {
        history.push(`/otherprofile/${heroId}`)
    }

    return (
        <>
            <IonGrid>
                <IonRow>
                    <IonCol size="8" className='leaderboard-column-header' style={{ textAlign: "left" }}>
                        Hero
                    </IonCol>
                    <IonCol size="4" className='leaderboard-column-header' style={{ textAlign: "right" }}>
                        Points
                    </IonCol>
                </IonRow>
                {leaderboard.map((lbObj: LeaderboardObjects, index) => {
                    let className = "hero-row";
                    if (index % 2 !== 0) {
                        className = "hero-row-alt";
                    }
                    if (lbObj.hero.heroID === heroId) {
                        className = "hero-row-current"
                    }
                    return (
                        // alternate row colors based on index
                        <IonRow className={className}>
                            <IonCol size="3" style={{ paddingRight: "0px" }}>
                                {lbObj.position}.
                            </IonCol>
                            <IonCol size="2">
                                <IonAvatar className="member-image-feed-mini">
                                    <img src={lbObj.hero.heroImageURL} alt='hero image' />
                                </IonAvatar>
                            </IonCol>
                            <IonCol size="4" style={{ fontSize: "12px" }}>
                                {lbObj.hero.heroName}
                            </IonCol>
                            <IonCol size="3" style={{ textAlign: "center" }}>
                                {lbObj.hero.totalDuelPoints}
                            </IonCol>
                        </IonRow>
                    )
                })}
            </IonGrid>
        </>
    )
}

export default MiniLeaderboard