import { IonAlert, IonAvatar, IonButton, IonCard, IonContent, IonHeader, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonLoading, IonModal, IonPage, IonRadio, IonRadioGroup, IonTextarea, IonToolbar } from '@ionic/react'
import React, { useContext, useEffect, useState } from 'react'
import GlobalState from '../../contexts/GlobalState';
import { useHistory } from 'react-router';
import ProfileHeader from './components/ProfileHeader';
import "../../css/Duels.css";
import { updateHero } from '../../services/UserService';
import { PersonalityDescriptions, getPersonalityDescriptions } from '../../services/HerochatService';
import log from 'loglevel';
import { capitalizeFirstLetter } from '../../services/HelperService';
import { sendSlackNotification } from '../../services/NotificationService';


export const HeroProfile = () => {
    const history = useHistory();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { userState, heroObject, refreshHeroData, isMobile, heroLoadingFinished, setHeroObject } = globalState;
    const [showChangeNameModal, setShowChangeNameModal] = useState<boolean>(false);
    const [confirmNameChange, setConfirmNameChange] = useState<boolean>(false);
    const [changingName, setChangingName] = useState<boolean>(false);
    const [newHeroName, setNewHeroName] = useState<string>("");
    const [heroPersonality, setHeroPersonality] = useState<string>(heroObject?.personalityType || "general");
    const [personalityDescriptions, setPersonalityDescriptions] = useState<PersonalityDescriptions[]>([]);
    const [currentDescription, setCurrentDescription] = useState<string>("");

    useEffect(() => {
        async function fetchData() {
            const descriptions = await getPersonalityDescriptions();
            setPersonalityDescriptions(descriptions);
            const currentDesc = descriptions.find(desc => desc.personalityType === heroPersonality)?.personalityTypeDescription || "";
            setCurrentDescription(currentDesc);
        }
        fetchData();
        log.debug(heroPersonality);
    }, [heroPersonality]);

    async function handleNameChange() {
        if (heroObject) {
            setConfirmNameChange(false);
            setChangingName(true);
            let hero = heroObject;
            hero.heroName = newHeroName;
            await updateHero(hero)
                .then((response) => {
                    if (response) {
                        sendSlackNotification(`*Hero Name Change*\nOld Name: ${heroObject.heroName}\nNew Name: ${newHeroName}`, "keyEvent");
                        setHeroObject(response);
                        setChangingName(false);
                        setShowChangeNameModal(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setChangingName(false);
                    setShowChangeNameModal(false);
                });
            setShowChangeNameModal(false);
        }
    };

    async function handlePersonalityChange(personality: string) {
        let localPersonality = personality;
        setHeroPersonality(localPersonality);
        if (heroObject) {
            // setChangingName(true);
            let hero = heroObject;
            hero.personalityType = localPersonality;
            hero.personalityTypeDescriprion = personalityDescriptions.find(desc => desc.personalityType === localPersonality)?.personalityTypeDescription || "";
            log.debug("hero.personalityTypeDescription: ", hero);
            await updateHero(hero)
                .then((response) => {
                    if (response) {
                        log.debug("response: ", response);
                        setHeroObject(response);
                        sendSlackNotification(`*Hero Personality Change*\nHero: ${response.heroName}\nPersonality: ${localPersonality}`, "keyEvent");
                        // setChangingName(false);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    setChangingName(false);
                });
        }
    }

    return (
        <>
            <IonLoading isOpen={changingName} message={"Changing Name..."} />
            <IonModal isOpen={showChangeNameModal} className="villain-difficulty-modal">
                <div className="villain-difficulty-modal-div">
                    <div>
                        <span className="villian-header-2" style={{ fontSize: "30px" }}>Change Hero Name</span><br />
                        <IonInput
                            value={newHeroName}
                            placeholder="Enter new hero name"
                            onIonChange={e => setNewHeroName(e.detail.value!)}
                        />
                        <IonButton style={{ marginRight: "30px" }} color={'success'} size='small' onClick={() => setConfirmNameChange(true)}>Submit</IonButton>
                        <IonButton color={'secondary'} size='small' onClick={() => setShowChangeNameModal(false)}>Cancel</IonButton>
                    </div>
                </div>
            </IonModal>
            <IonAlert
                isOpen={confirmNameChange}
                onDidDismiss={() => setConfirmNameChange(false)}
                header={"Confirm Name Change"}
                message={`Are you sure you want to change your hero's name to ${newHeroName}?`}
                buttons={[
                    { text: "Confirm", handler: () => handleNameChange() },
                    { text: "Cancel", role: "cancel" },

                ]}
            />
            <IonContent>
                <div style={{ marginLeft: "10px" }}>Hero</div>
                <IonCard>
                    <IonItem className='profile-background'>
                        <IonLabel slot='start'>
                            <strong>Hero Name</strong>
                        </IonLabel>
                        <IonLabel onClick={() => setShowChangeNameModal(true)} slot='end'>
                            {heroObject?.heroName}
                        </IonLabel>
                    </IonItem>
                    <IonItem className='profile-background' button={true} routerLink='/updateAvatar'>
                        <IonLabel slot='start'>
                            <strong>Hero appearance</strong>
                        </IonLabel>
                    </IonItem>
                </IonCard>
                <div style={{ marginLeft: "10px" }}>Hero Personality</div>
                <IonCard>
                    <IonRadioGroup value={heroPersonality} onIonChange={e => handlePersonalityChange(e.detail.value)}>
                        {personalityDescriptions.map(desc => (
                            <IonItem key={desc.personalityType} className='profile-background'>
                                <IonLabel>{desc.icon} {capitalizeFirstLetter(desc.personalityType)} {desc.premium === true && (<div className='avatar-powerup-badge-icon'>⚡</div>)}</IonLabel>
                                <IonRadio slot="end" value={desc.personalityType} />
                            </IonItem>
                        ))}
                    </IonRadioGroup>
                </IonCard>
                <div style={{ marginLeft: "10px" }}>Hero Personality Description</div>
                <IonCard>
                    <IonItem className='profile-background'>
                        <div>
                            {currentDescription}
                        </div>
                    </IonItem>
                </IonCard>
            </IonContent>
        </>
    )
}
