// Database table names
export const USERS_TABLE = 'User';
export const BASELINE_STATS_TABLE = 'baseline_stats';
export const HEROES_TABLE = 'heroes';
export const ATTRIBUTE_MATRIX_TABLE = 'attribute_matrix';
export const VILLAINS_TABLE = 'villains';
export const ACTIVITIES_TABLE = 'activities';
export const GOALS_TABLE = 'goals';
export const DUEL_STATUS_TABLE = 'duel_status';
export const DUEL_LEDGER_TABLE = 'duel_ledger';
export const UPDATES_TABLE = 'update_log';
export const DUEL_MESSAGES_TABLE = 'duel_messages';
export const INVITE_CODE_TABLE = 'user_invite_codes';
export const VILLAIN_GROUP_INSTANCE_TABLE = 'villain_group_instances';
export const SKILLS_TABLE = 'skills';
export const SKILLS_IN_USE_TABLE = 'skillsInUse';
export const SPECIALIZATIONS_TABLE = 'Specializations';
export const FITBIT_TOKENS_TABLE = 'fitbit_tokens';
export const SIDEKICK_MESSAGES_TABLE = 'sideKick_messages';
export const SIDEKICK_TABLE = 'sidekicks';
export const SIDEKICK_TYPES_TABLE = 'sidekick_types';
export const USER_POINTS_TABLE = 'user_points';
export const DUEL_POINT_MATRIX_TABLE = 'point_matrix';
export const USER_VILLAIN_TALLY_TABLE = 'hero_villain_tally';
export const SUBSCRIPTION_PRODUCTS_TABLE = 'subscription_products';
export const ACHIEVEMENTS_TABLE = 'records';
export const HERO_MESSAGES_TABLE = 'hero_messages';
export const HERO_PROMPTS_TABLE = 'hero_prompts';
export const JUNE_CHALLENGE_POINT_LEDGER_TABLE = 'june_challenge_point_ledger';