import { IonAlert, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon, IonLoading } from '@ionic/react';
import log from 'loglevel';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import GlobalState from '../../../contexts/GlobalState';
import { ActivityLedger, WorkoutObject } from '../../../models/exerciseModels';
import { HeroObject, lastUpdatedStepsKey, lastUpdatedWorkoutKey } from '../../../models/userModel';
import { logGenericEventWithObject } from '../../../services/AnalyticsService';
import { Activity, Suggestion, emptySuggestion, getActivitySuggestion } from '../../../services/DuelsServics';
import { convertManualActivityToWorkoutObject, createLedgerFromWorkoutArray, getRecentWorkouts, postWorkoutsToServer } from '../../../services/ExerciseService';
import { formatTime } from '../../../services/HelperService';
import { sendSlackNotification } from '../../../services/NotificationService';
import { StorageService } from "../../../services/StorageService";
import { LAST_SUGGESTION_COMPLETED_KEY } from '../../../models/helperSuggestions';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import { Haptics, ImpactStyle, NotificationType } from '@capacitor/haptics';
import { KeepAwake } from '@capacitor-community/keep-awake';
import { refresh } from 'ionicons/icons';
let storageService = new StorageService();

const SuggestionModal = (props: { suggestion: Suggestion, hero: HeroObject, setDidLogActivity: any, setShowHowToHelp: any }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { updateHeroHealth } = globalState;
    const [adding, setAdding] = useState<boolean>(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [showTimer, setShowTimer] = useState<boolean>(false);
    const [localSuggestion, setLocalSuggestion] = useState<Suggestion>(props.suggestion);

    useEffect(() => {
        logGenericEventWithObject(`view suggestion modal`, localSuggestion)
    }, [localSuggestion]);

    async function acceptSuggestion(confirmed: boolean = false) {
        logGenericEventWithObject(`suggestion accepted`, localSuggestion)
        if (!confirmed && localSuggestion.activityType === "time" && localSuggestion.duration > 0) {
            KeepAwake.keepAwake();
            setShowTimer(true)
            // setShowConfirmationModal(true);
        }
        if (!confirmed && localSuggestion.activityType === "reps" && localSuggestion.reps > 0) {
            setShowConfirmationModal(true);
        }
        if (confirmed) {
            setShowTimer(false);
            setShowConfirmationModal(false);
            await Haptics.vibrate({ duration: 1000 })
            logActivity();
            KeepAwake.allowSleep();
        }
    }

    // Can't centralize this function because it needs to access globalState
    const logActivity = async () => {
        setAdding(true)
        let date = moment().format("YYYY-MM-DD HH:mm:ss");
        let duration = localSuggestion.duration > 0 ? localSuggestion.duration * 60 : 120; // convert to seconds
        let activityName = "";
        let calories = localSuggestion.duration * 3; // 3 calories per minute as a safe bet
        switch (localSuggestion.type) {
            case Activity.Strength:
                activityName = "Strength training";
                break;
            case Activity.Cardio:
                activityName = "General Cardio";
                break;
            case Activity.Mobility:
                activityName = "General Mobility";
                break;
            case Activity.Mindfulness:
                activityName = "General Mindfulness";
                calories = 0;
                break;
        }
        let intensity = "low";
        let workoutObject = await convertManualActivityToWorkoutObject(date, duration, activityName, intensity, calories)
        log.debug("[SuggestionModal logActivity] Workout Object: ", workoutObject)
        let workoutArray: WorkoutObject[] = [workoutObject];
        if (workoutArray) {
            workoutArray.forEach((workout) => {
                sendSlackNotification(`*Manual Activity Logged [suggestion]*\n*Hero Name*: ${props.hero.heroName}\n*Activity*: ${workout.activityType}\n*Duration*: ${formatTime(workout.value, true)}`, "activity", true)
            })
            await postWorkoutsToServer(workoutArray, false);
            props.setDidLogActivity(true);
            props.setShowHowToHelp({ suggestion: emptySuggestion, visible: false });
            logGenericEventWithObject("manual activity logged", workoutObject);
            // log.debug("[logActivity] Server Response: ", serverResponse)
            let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(true);
            log.debug(`[SuggestionModal logActivity] RecentWorkouts: `, recentWorkouts);
            if (recentWorkouts.length > 0) {
                let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
                log.debug("[logActivity] Activity Ledger: ", activityLedger)
                await updateHeroHealth(activityLedger, recentWorkouts, moment(), true);
                await storageService.setItem(lastUpdatedWorkoutKey, moment().format());
                await storageService.setItem(lastUpdatedStepsKey, moment().format());
                log.debug("Activity Ledger: ", activityLedger)
                log.debug("Workout Array: ", workoutArray)
            }
        }
        await storageService.setItem(LAST_SUGGESTION_COMPLETED_KEY, moment().format());
        setAdding(false);
    }

    async function getDifferentSuggestion() {
        setLocalSuggestion(getActivitySuggestion(props.suggestion.type!))
    }

    return (
        <>
            <IonLoading isOpen={adding} message="Logging Activity..." backdropDismiss={true} duration={5000} />
            <IonAlert isOpen={showConfirmationModal} onDidDismiss={() => setShowConfirmationModal(false)} header={'Confirm'} message={`Did you complete this activity?`} buttons={[{ text: 'Cancel', role: 'cancel' }, { text: 'Yes', handler: () => acceptSuggestion(true) }]} />
            <div style={{ margin: "20px" }}>
                <IonCard>
                    {localSuggestion.imageURL !== "" && showTimer === false && (
                        <img src={localSuggestion.imageURL} alt='activity suggestion' />
                    )}
                    {showTimer === true && (
                        <div className='mini-countdown-timer'>
                            <CountdownCircleTimer
                                isPlaying={true}
                                duration={localSuggestion.duration * 60}
                                colors={"#4600ff"}
                                size={200}
                                strokeWidth={20}
                                onComplete={() => { acceptSuggestion(true) }}>
                                {({ remainingTime }) => (
                                    <div className="timer">
                                        <div className="mini-timer-value">{remainingTime}</div>
                                    </div>
                                )}
                            </CountdownCircleTimer>
                        </div>
                    )}
                    <IonCardHeader>
                        <IonCardTitle>
                            {props.hero.heroName} needs help with {localSuggestion.type}
                        </IonCardTitle>
                        {/* <IonCardSubtitle>
                            <p>Duration: {props.skill.repeat === 0 ? "Immediate" : props.skill.repeat + " hours"}  | Cooldown: {props.skill.cooldown} hours</p>
                        </IonCardSubtitle> */}
                    </IonCardHeader>
                    <IonCardContent>
                        <p>{localSuggestion.suggestionDescription}</p>
                        <div style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                            {localSuggestion.activityType === "time" && localSuggestion.duration > 0 && showTimer === false && (
                                <p style={{ textAlign: "center" }}><IonButton color={'success'} onClick={() => acceptSuggestion(false)}>Start Timer</IonButton></p>
                            )}
                            {localSuggestion.activityType === "reps" && localSuggestion.reps > 0 && (
                                <p style={{ textAlign: "center" }}><IonButton color={'success'} onClick={() => acceptSuggestion(false)}>Log Activity</IonButton></p>
                            )}
                            {showTimer === false && (
                                <p style={{ textAlign: "center" }}><IonButton color={'light'} onClick={() => getDifferentSuggestion()}><IonIcon icon={refresh} /> </IonButton></p>
                            )}
                        </div>
                    </IonCardContent>

                </IonCard>
            </div>
        </>
    )
}

export default SuggestionModal