export const DashboardSteps = [
    {
        title: "",
        intro: "This is your dashboard, that shows your current goals and current duel",
        position: "bottom-middle-aligned",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".home-hint",
        title: "",
        intro: "Let's check out your current goals",
        position: "top",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    }
];

export const VillainGroupsSteps = [
    {
        intro: "<firstName> these are the groups of villains I am determined to defeat. 😤",
        position: "bottom-middle-aligned",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".first-villain-group-hint",
        intro: "First up are the <b>Fitness Extremes.</b> Tap here",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClassFinal",
    }
];

export const VillainGroupsProfileSteps = [
    {
        intro: "This group of villains are the Fitness Extremes. <br/><br/>They are <b>AI characters</b> representing extremes in the fitness world.",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        intro: "I'm going to take them down one by one!<br/><br/>With your help of course 😉",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".start-duel-footer",
        intro: "Let's unlock this group",
        position: "top",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClassFinal",
    }
];

export const VillainProgressionExplanation = [
    {
        intro: "This is the villain group map. I will progress through the map by <b>completing duels</b> with the villains.",
        position: "top",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".hero-hint",
        intro: "This is me! I start at the bottom of each group and progress upwards by successfully completing duels.",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".boss-villain-hint",
        intro: "This is the boss. I'll need to defeat him to clear the group.",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".first-villain-hint",
        intro: "Looks like <b>Timid Tom</b> is the first villain for me to duel. Let's get it on! 👊",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClassFinal",
    },
];

export const VillainProfileSteps = [
    {
        // element: ".villian-profile-image",
        intro: "Look at this guy! He's shaking in his boots. 😂",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        // element: ".start-duel-footer",
        intro: "Let's show him what we're made of!",
        position: "top",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClassFinal",
    }
];

export const GoalSteps = [
    {
        intro: "These are your current goals for the week",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".goal-cell",
        intro: "Goals are measured in <b>number of minutes</b> for the week",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".goal-cell",
        intro: "You can set and edit your goals on <b>Mondays.</b>",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".goal-bonus-div",
        intro: "If you accomplish your goals, I'll get a <b>significant boost</b> in my duels! 🙌",
        position: "top",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    }
]

export const ActivitiesTour = [
    {
        intro: "Here you can see your activity history",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".stats-date-selector-div",
        intro: "Go back & forth in time to see different weeks",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".stats-weekly-summary-div",
        intro: "These are the sum of your minutes for each type of activity for the week",
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: ".first-activity-hint",
        intro: "Tap your activity to see more details!",
        position: "top",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClassFinal",
    }
]

export const StatsTour = [
    {
        intro: "These are my stats. The <b>more activities</b> you do, the <b>more powerful</b> I become! 💪",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.strength-hint',
        intro: 'Strength represents my overall physical strength.',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.damage-tour-hint',
        intro: 'It influences how much <b>DAMAGE</b> 💥 I do in a duel.',
        position: 'top',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.strength-hint',
        intro: 'Help me improve my strength with exercises like: <li>🏋️‍♀️ weight lifting</li><li>💪 body weight exercises</li><li>🤸‍♂️ hiit workouts</li>',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.cardio-hint',
        intro: 'Cardio represents (no surprise) my cardiovascular levels.',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.health-tour-hint',
        intro: 'It influences how much <b>HEALTH</b> 💚 I have in a duel.',
        position: 'top',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.cardio-hint',
        intro: 'Many activities you do will help me this, like <li>🧹 household chores</li><li>🚶‍♀️ walking</li><li>🏃‍♂️ running</li><li>🏊‍♀️ swimming</li><li>🥾 hiking</li>',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.mobility-hint',
        intro: 'Mobility represents how nimble and flexible I am.',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.dodge-tour-hint',
        intro: 'It influences how likely I am to <b>DODGE</b> villain attacks (💨) in a duel.',
        position: 'top',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.mobility-hint',
        intro: 'Help me be more nimble with activities like: <li>🤸‍♂️ stretching</li><li>🧘‍♀️ yoga</li><li>🗞 foam rolling</li>',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.mindfulness-hint',
        intro: 'And finally, Mindfulness is my level of calm and ability to be present.',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.accuracy-tour-hint',
        intro: 'It influences how likely I am to <b>HIT</b> 🎯 the villian in a duel',
        position: 'top',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.mindfulness-hint',
        intro: 'Help me with this by practicing <li>🙏meditation</li><li>🥱breathing exercises</li><li>✍ journaling</li>',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        intro: 'Each attribute has <b>SKILLS</b> associated with it that I can use in a duel.',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.cardio-hint',
        intro: `Let's take a look at the Cardio skills!`,
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClassFinal",
    }
]

export const SkillsTour = [
    {
        intro: 'These are the skills I can use in a duel. The more I level up, the more skills I unlock!',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.first-skill',
        intro: 'Each skill has a unique effect in a duel.',
        position: 'bottom',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: '.select-specialization',
        intro: 'At level 20 you can select a specialization for me, giving me a unique set of skills!',
        position: 'top',
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    }
]

export const DuelStatusTour = [
    {
        intro: `Alright <firstName>, it's time to get it on! Here's how the duels work.`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        intro: `Me and Timid Tom here are going to duke it out <b>every hour</b> 🥊`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: `.explanation-hint-stats`,
        intro: `These are my stats. Later you can tap them for more details.<br><br> For now, the most important one is ❤️ health. If it reaches 0, <b>I lose!</b>`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        intro: `<b>DON'T LET ME LOSE</b>`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: `.add-hint`,
        intro: `You can help me by <b>doing activities</b>. Let's log something right now...`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClassFinal",
    }
]

export const LogActivity1Tour = [
    {
        intro: `<firstName> go ahead log <b>ANYTHING</b> you've done recently. Even if it's just a short walk.`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
]

export const FirstHitTour = [
    {
        intro: `Great job <firstName>! You can see here that <b>your activity</b> caused me to whack Timid Tom good!.`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: `.countdown-timer-v2`,
        intro: `Every time you do an activity, I'll hit the villain. But even if you don't I'll still <b>try</b> to hit them every hour!`,
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
]

export const DuelSkillsSteps = [
    {
        element: `.skills-hint-tour`,
        intro: `To aid me in my duels, you can tell me to use a skill. The more I level up, the more skills I unlock.`,
        position: "top",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: `.mini-hint-tour`,
        intro: `Also, even the smallest activity helps me <firstName>!<br/><br/>Try these minis throughout your day.<br/><br/>They help me win and add a little activity to your day!`,
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: `.mini-message-hint-tour`,
        intro: `I'll even give you a hint as to what would really help me in my duel`,
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        element: `.chat-hint-tour`,
        intro: `Looks like Timid Tom and I have been chatting. Let's check it out!`,
        position: "bottom",
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClassFinal",
    }
]

export const DuelChatSteps = [
    {
        intro: `You can see Timid Tom and I have been chatting. Every chat between a villain and I is unique!`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        intro: `We'll often taunt each other, tell stories or even drop some health knowledge. All in good fun!`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        intro: `That does it for the FitHero tour! I look forward to keeping you <b>on track with your goals</b> and defeating these villains!`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    },
    {
        intro: `Continue to explore the app and if you have questions, tap the 💬 Hero Chat at the bottom. 👋`,
        tooltipClass: "heroSpeaksTooltipClass",
        highlightClass: "heroSpeaksHighlightClass",
    }
]