import {
    IonCol,
    IonRow
} from "@ionic/react";
import { useContext } from "react";
import Text from "react-emoji-render";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Duels.css";

const HealthIndicator = (props: { health: number, maxHealth: number | undefined }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile } = globalState;

    let filledHearts = Math.floor(props.health / 10);
    if (filledHearts === 0 && props.health > 0) {
        filledHearts = 1;
    }
    filledHearts = Math.max(0, filledHearts); // Ensure filledHearts is not negative
    const emptyHearts = Math.max(0, ((props.maxHealth ? props.maxHealth : 100) / 10) - filledHearts); // Ensure emptyHearts is not negative

    const hearts = Array.from({ length: filledHearts }, () => "❤️")
        .concat(Array.from({ length: emptyHearts }, () => "🤍"));

    const heartRows = Array.from(
        { length: Math.ceil(hearts.length / 10) },
        (_, i) => hearts.slice(i * 10, i * 10 + 10)
    );

    return (
        <div className={isMobile ? "shifted-div" : ""}>
            {heartRows.map((row, i) => {
                return (
                    <IonRow key={i} className={i > 0 ? "second-row-health" : ""}>
                        {row.map((heart, j) => (
                            <IonCol className={isMobile ? "heart-size" : "heart-size-desktop"} key={j} size="1">
                                <Text text={heart} />
                            </IonCol>
                        ))}
                    </IonRow>)
            })}
        </div>
    );
};

export default HealthIndicator;