/* eslint-disable no-throw-literal */
import { Device } from "@capacitor/device";
import log from "loglevel";
import moment, { Moment } from "moment";
import Parse from "parse";
import { ATTRIBUTE_MATRIX_TABLE, UPDATES_TABLE } from "../models/databaseModels";
import { ActivityLedger, AttributeRate, ExercisePoints, HealthKitWorkoutObject, WorkoutObject, accuracyIcon, attributes, cardioIcon, damageIcon, dodgeIcon, healthIcon, mindfulnessIcon, mobilityIcon, pointsPerLevel, strengthIcon } from "../models/exerciseModels";
import { DuelDisplayMessage } from "../models/messageModels";
import {
	AttributeMatrix,
	BaselineStats,
	DeviceInfoUUID,
	HeroObject,
	OnboardingObject,
	VersionInfo,
	coachMarkObjectKey,
	versionUpdateKey,
} from "../models/userModel";
import { getAttributeBreakdownByActivity } from "./HealthKitService";
import { StorageService } from "./StorageService";
import { getBaselineStats, getUser_id } from "./UserService";
const storageService = new StorageService();

export const getFullDeviceInfo = async () => {
	let deviceInfo: DeviceInfoUUID;
	deviceInfo = await storageService.getObject("deviceInfo");
	if (deviceInfo === null || deviceInfo === undefined || !deviceInfo.uuid) {
		deviceInfo = await Device.getInfo();
		let tempUUID = await Device.getId();
		deviceInfo.uuid = tempUUID.identifier;
		await storageService.setObject("deviceInfo", deviceInfo);
	}
	return deviceInfo;
};

/**
 *
 * Takes a number of seconds and converts it to a human readable string in the format of Xh Xm Xs
 *
 * @param seconds
 * @returns string
 */

export function formatTime(seconds: number, returnZero: boolean = false) {
	if (seconds === 0 && returnZero) {
		return "0 mins";
	}
	let hours = Math.floor(seconds / 3600);
	seconds %= 3600;
	let minutes = Math.floor(seconds / 60);
	seconds = Math.round(seconds % 60);

	let result = [];
	if (hours > 0) {
		result.push(`${hours}h`);
	}
	if (minutes > 0) {
		result.push(`${minutes}m`);
	}
	// log.debug(`Result: ${result}`);

	return result.join(" ");
}

export function formatTimeHours(hours: number, returnZero: boolean = false): string {
	if (hours === 0 && returnZero) {
		return "< 1 day";
	}

	// Calculate initial days based on the total hours
	let days = Math.floor(hours / 24);
	// Calculate the remaining hours after extracting the days
	let remainingHours = hours % 24;

	// Apply rounding logic based on the remaining hours
	if (remainingHours >= 12) {
		days += 1; // Round up
	}

	// If the result is less than a day, return "< 1 day"
	if (days < 1) {
		return "< 1 day";
	} else {
		// Use "day" or "days" based on the number of days
		let dayText = days === 1 ? "day" : "days";
		return `${days} ${dayText}`;
	}
}

/**
 *
 * Takes a string in the format of Xh Xm Xs and converts it into a number of seconds
 *
 * @param time
 * @returns number
 */

export function timeToSeconds(time: string) {
	let parts = time.split(" ");
	let hours = 0,
		minutes = 0,
		seconds = 0;

	for (let part of parts) {
		if (part.includes("h")) {
			hours = parseInt(part) * 3600;
		} else if (part.includes("m")) {
			minutes = parseInt(part) * 60;
		} else if (part.includes("s")) {
			seconds = parseInt(part);
		}
	}

	return hours + minutes + seconds;
}

export function calculateAttributeRateFromHKObj(workoutObj: HealthKitWorkoutObject, intensityMultiple: number) {
	// Takes the rate for each attribute and multiplies it by the number of minutes in that activity\
	// So if someone does something that is a 1x cardio for 3600 seconds (60 mins), they will receive 60 "points"
	let attributeRate: AttributeRate = getAttributeBreakdownByActivity(workoutObj.workoutActivityName);
	log.debug(`Attribute rate: `, attributeRate);

	let attributeRateObject: ExercisePoints = {
		strengthPoints: Math.floor(attributeRate.strengthRate * (workoutObj.duration * 60) * intensityMultiple),
		cardioPoints: Math.floor(attributeRate.cardioRate * (workoutObj.duration * 60) * intensityMultiple),
		mobilityPoints: Math.floor(attributeRate.mobilityRate * (workoutObj.duration * 60) * intensityMultiple),
		mindfulnessPoints: Math.floor(attributeRate.mindfulRate * (workoutObj.duration * 60) * intensityMultiple),
	};
	log.debug(`attributeRateObject: `, attributeRateObject);

	return attributeRateObject;
}

export function calculateAttributeRateFromExerciseObj(workoutObj: WorkoutObject, intensityMultiple: number) {
	// Takes the rate for each attribute and multiplies it by the number of minutes in that activity\
	// So if someone does something that is a 1x cardio for 3600 seconds (60 mins), they will receive 60 "points"
	let attributeRate: AttributeRate = getAttributeBreakdownByActivity(workoutObj.activityType);
	let attributeRateObject: ExercisePoints = {
		strengthPoints: Math.floor(attributeRate.strengthRate * (workoutObj.value / 60) * intensityMultiple),
		cardioPoints: Math.floor(attributeRate.cardioRate * (workoutObj.value / 60) * intensityMultiple),
		mobilityPoints: Math.floor(attributeRate.mobilityRate * (workoutObj.value / 60) * intensityMultiple),
		mindfulnessPoints: Math.floor(attributeRate.mindfulRate * (workoutObj.value / 60) * intensityMultiple),
	};
	return attributeRateObject;
}

export function calculateAttributeRateFromManualWorkoutObj(activityName: string, durationInSeconds: number, intensityMultiple: number) {
	// Takes the rate for each attribute and multiplies it by the number of minutes in that activity\
	// So if someone does something that is a 1x cardio for 3600 seconds (60 mins), they will receive 60 "points"
	let attributeRate: AttributeRate = getAttributeBreakdownByActivity(activityName);
	let attributeRateObject: ExercisePoints = {
		strengthPoints: Math.floor(attributeRate.strengthRate * (durationInSeconds / 60) * intensityMultiple),
		cardioPoints: Math.floor(attributeRate.cardioRate * (durationInSeconds / 60) * intensityMultiple),
		mobilityPoints: Math.floor(attributeRate.mobilityRate * (durationInSeconds / 60) * intensityMultiple),
		mindfulnessPoints: Math.floor(attributeRate.mindfulRate * (durationInSeconds / 60) * intensityMultiple),
	};
	return attributeRateObject;
}
/**
 * Calculating levels
 * Top level: 15 hours a week training
 * 15 hours a week is 900 mins or
 */

type AttributeType = keyof typeof pointsPerLevel;


export async function getAttributeMatrix(): Promise<AttributeMatrix> {
	const query = new Parse.Query(ATTRIBUTE_MATRIX_TABLE);
	query.equalTo("current", true);
	const result = await query.first();
	if (result) {
		return result.toJSON() as unknown as AttributeMatrix;
	} else {
		throw "Error getting attribute matrix";
	}
}

interface StatsUpdate {
	heroObject: HeroObject;
	statsSummary: Array<string>;
}

export async function calculateStats(heroObject: HeroObject): Promise<StatsUpdate> {
	let attributeMatrix: AttributeMatrix = await getAttributeMatrix();
	let baselineStats: BaselineStats = await getBaselineStats();
	let statsSummary: Array<string> = [];
	let previousHeroObject = heroObject;
	let currentHeroObject = heroObject;
	currentHeroObject = {
		...currentHeroObject,
		heroToHit:
			baselineStats.accuracy +
			heroObject.strengthLevel * attributeMatrix.strengthAccuracy +
			heroObject.cardioLevel * attributeMatrix.cardioAccuracy +
			heroObject.mobilityLevel * attributeMatrix.mobilityAccuracy +
			heroObject.mindfulnessLevel * attributeMatrix.mindfulnessAccuracy +
			heroObject.overallLevel * attributeMatrix.levelAccuracy,
		heroDodge:
			baselineStats.dodge +
			heroObject.strengthLevel * attributeMatrix.strengthDodge +
			heroObject.cardioLevel * attributeMatrix.cardioDodge +
			heroObject.mobilityLevel * attributeMatrix.mobilityDodge +
			heroObject.mindfulnessLevel * attributeMatrix.mindfulnessDodge +
			heroObject.overallLevel * attributeMatrix.levelDodge,
		heroDamage:
			baselineStats.damage +
			heroObject.strengthLevel * attributeMatrix.strengthDamage +
			heroObject.cardioLevel * attributeMatrix.cardioDamage +
			heroObject.mobilityLevel * attributeMatrix.mobilityDamage +
			heroObject.mindfulnessLevel * attributeMatrix.mindfulnessDamage +
			heroObject.overallLevel * attributeMatrix.levelDamage,
		heroHealth:
			baselineStats.health +
			heroObject.strengthLevel * attributeMatrix.strengthHealth +
			heroObject.cardioLevel * attributeMatrix.cardioHealth +
			heroObject.mobilityLevel * attributeMatrix.mobilityHealth +
			heroObject.mindfulnessLevel * attributeMatrix.mindfulnessHealth +
			heroObject.overallLevel * attributeMatrix.levelHealth,
	};
	if (currentHeroObject.heroToHit > previousHeroObject.heroToHit) {
		statsSummary.push(`Hero's accuracy has increased from ${previousHeroObject.heroToHit} to ${currentHeroObject.heroToHit}`);
	}
	if (currentHeroObject.heroDodge > previousHeroObject.heroDodge) {
		statsSummary.push(`Hero's dodge has increased from ${previousHeroObject.heroDodge} to ${currentHeroObject.heroDodge}`);
	}
	if (currentHeroObject.heroDamage > previousHeroObject.heroDamage) {
		statsSummary.push(`Hero's damage has increased from ${previousHeroObject.heroDamage} to ${currentHeroObject.heroDamage}`);
	}
	if (currentHeroObject.heroHealth > previousHeroObject.heroHealth) {
		statsSummary.push(`Hero's health has increased from ${previousHeroObject.heroHealth} to ${currentHeroObject.heroHealth}`);
	}
	return {
		heroObject: currentHeroObject,
		statsSummary: statsSummary,
	};
}

export function getStatInfo(statAttribute: string): { icon: string; text: string } {
	switch (statAttribute) {
		case "accuracy":
			return ({
				icon: accuracyIcon,
				text: "This increases your hero's chance to hit their opponent."
			}
			)
		case "damage":
			return ({
				icon: damageIcon,
				text: "This increases the amount of damage your hero does to their opponent."
			}
			)
		case "dodge":
			return ({
				icon: dodgeIcon,
				text: "This increases your hero's chance to dodge their opponent's attack."
			}
			)
		case "health":
			return ({
				icon: healthIcon,
				text: "This increases your hero's health."
			}
			)
		case "strength":
			return ({
				icon: strengthIcon,
				text: "This increases the amount of damage your hero does to their opponent."
			}
			)
		case "cardio":
			return ({
				icon: cardioIcon,
				text: "This increases your hero's health."
			}
			)
		case "mobility":
			return ({
				icon: mobilityIcon,
				text: "This increases your hero's chance to dodge their opponent's attack."
			}
			)
		case "mindfulness":
			return ({
				icon: mindfulnessIcon,
				text: "This increases your hero's chance to hit their opponent."
			}
			)
	}
	return ({
		icon: "",
		text: ""
	}
	)
}

export function getGoalAccomplishedInfo(statAttribute: string): { icon: string; value: string; text: string } {
	switch (statAttribute) {
		case "strength":
			return ({
				icon: damageIcon,
				value: "+20%",
				text: "Your hero will do more damage in duels for the next 7 days!"
			}
			)
		case "cardio":
			return ({
				icon: healthIcon,
				value: "+20%",
				text: "Your hero will have more health in duels for the next 7 days!"
			}
			)
		case "mobility":
			return ({
				icon: dodgeIcon,
				value: "+20%",
				text: "Your hero will have a better chance to dodge in duels for the next 7 days!"
			}
			)
		case "mindfulness":
			return ({
				icon: accuracyIcon,
				value: "+20%",
				text: "Your hero will have a better chance to hit in duels for the next 7 days!"
			}
			)
	}
	return ({
		icon: "",
		value: "",
		text: ""
	}
	)
}

export function reorderProperties(obj: any, preferredOrder: string[]) {
	var orderedObj: any = {};
	const remainingProps: any = { ...obj };  // Copy properties from obj to remainingProps

	for (const key of preferredOrder) {
		if (remainingProps.hasOwnProperty(key)) {
			orderedObj[key] = remainingProps[key];
			delete remainingProps[key];  // Remove the property from remainingProps
		}
	}

	return {
		...orderedObj,
		...remainingProps  // Spread remaining properties into the result object
	};
}


interface HeroUpdate {
	heroObject: HeroObject;
	summary: {
		text: Array<string>;
		updated: string | null;
		previousUpdate: string | null;
	};
}

export async function getUpdatedHero(reset: boolean = false) {
	let userID = await getUser_id();
	const params1 = {
		userID: userID,
		// reset: reset,
		reset: true,
	};
	// log.debug(`[getUpdatedHero] running`);
	try {
		// InitParse();
		const update = await Parse.Cloud.run("calculateHeroStats", params1);
		// let update = await calculateHeroStats(params1);
		// log.debug(`[getUpdatedHero] update: `, update);
		if (update && update.heroObject && update.summary) {
			return { heroObject: update.heroObject, summary: update.summary };
		} else {
			throw "Error getting updated hero";
		}
	} catch {
		throw "Error getting updated hero";
	}
}

export async function convertPointsToLevelsWithSummary(
	heroObject: HeroObject,
	exercisePoints: ExercisePoints,
	updateDate: Moment,
	summary: {
		text: Array<string>;
		updated: null | string;
	}[] = []
) {
	log.debug(`[convertPointsToLevelsWithSummary] currentLevels: `, heroObject);
	log.debug(`[convertPointsToLevelsWithSummary] exercisePoints: `, exercisePoints);
	log.debug("[convertPointsToLevelsWithSummary] converting points to levels");
	let summaryText: Array<string> = [];
	let totalPoints = 0;
	let level = 0;
	attributes.forEach((attribute) => {
		if (exercisePoints[`${attribute}Points` as keyof ExercisePoints] !== 0) {
			const currentPoints = convertLevelsToPoints(
				heroObject[`${attribute}Level` as keyof HeroObject],
				heroObject[`${attribute}Points` as keyof HeroObject],
				attribute as AttributeType
			);
			log.debug(`[convertPointsToLevelsWithSummary] currentPoints for ${attribute}: ${currentPoints}`);

			const newPoints = currentPoints + exercisePoints[`${attribute}Points` as keyof ExercisePoints];

			log.debug(`[convertPointsToLevelsWithSummary] newPoints for ${attribute}: ${newPoints}`);

			const initialLevel = heroObject[`${attribute}Level` as keyof HeroObject];
			const initialPoints = heroObject[`${attribute}Points` as keyof HeroObject];

			const newLevelData: any = convertPointsToLevel(newPoints, attribute as AttributeType);
			level += newLevelData.level;
			totalPoints += newPoints;
			log.debug(`[convertPointsToLevelsWithSummary] newLevelData for ${attribute}: `, newLevelData);

			(heroObject as any)[`${attribute}Level`] = newLevelData.level;
			(heroObject as any)[`${attribute}Points`] = newLevelData.points;

			if (initialLevel && initialLevel < newLevelData.level) {
				summaryText.push(
					`${attribute.charAt(0).toUpperCase() + attribute.slice(1)} Points increased by ${exercisePoints[
						`${attribute}Points` as keyof ExercisePoints
					].toLocaleString()}, increasing ${attribute} level from ${initialLevel} to ${newLevelData.level}`
				);
			} else if (initialPoints && initialPoints < newLevelData.points) {
				summaryText.push(
					`${attribute.charAt(0).toUpperCase() + attribute.slice(1)} Points increased by ${exercisePoints[
						`${attribute}Points` as keyof ExercisePoints
					].toLocaleString()}`
				);
			} else if (initialLevel && initialLevel > newLevelData.level) {
				summaryText.push(
					`${attribute.charAt(0).toUpperCase() + attribute.slice(1)} Points decreased by ${exercisePoints[
						`${attribute}Points` as keyof ExercisePoints
					].toLocaleString()}, decreasing ${attribute} level from ${initialLevel} to ${newLevelData.level}`
				);
			} else if (initialLevel && initialPoints && initialPoints > newLevelData.points && initialLevel === newLevelData.level) {
				summaryText.push(
					`${attribute.charAt(0).toUpperCase() + attribute.slice(1)} Points decreased by ${exercisePoints[
						`${attribute}Points` as keyof ExercisePoints
					].toLocaleString()}`
				);
			}
		}
	});

	let newTotalPoints = totalPoints;
	let newOverallLevel = convertTotalPointsToOverallLevel(newTotalPoints);

	if (heroObject.overallLevel < newOverallLevel) {
		summaryText.push(
			`Total Points increased by ${newTotalPoints.toLocaleString()}, increasing overall level from ${heroObject.overallLevel} to ${newOverallLevel}`
		);
	} else if (heroObject.overallLevel > newOverallLevel) {
		summaryText.push(
			`Total Points decreased by ${newTotalPoints.toLocaleString()}, decreasing overall level from ${heroObject.overallLevel} to ${newOverallLevel}`
		);
	}
	heroObject.totalPoints = totalPoints;
	heroObject.overallLevel = newOverallLevel;

	let stats = await calculateStats(heroObject);
	summaryText.push(...stats.statsSummary);
	heroObject = stats.heroObject;

	summary.push({
		text: summaryText,
		updated: updateDate.format(),
	});

	log.debug(`currentLevels after conversion: `, heroObject);
	log.debug({ summary });
	await storageService.setItem("lastUpdated", moment(updateDate).format());
	return { updatedHeroObject: heroObject, summary };
}

export function convertLevelsToPoints(levels: any, remainingPoints: any, attribute: any) {
	const basePoints = pointsPerLevel[attribute];
	if (basePoints === undefined) {
		throw new Error(`Unknown attribute type: ${String(attribute)}`);
	}
	const totalPoints = (((levels - 1) * levels) / 2) * basePoints + remainingPoints;
	return totalPoints;
}

export function convertPointsToLevel(points: number, attribute: AttributeType) {
	const basePoints = 10;
	if (basePoints === undefined) {
		throw new Error(`Unknown attribute type: ${String(attribute)}`);
	}

	// Calculate the level by finding the maximum level where total points required
	// to reach that level is less than or equal to the actual points.
	let level = 1;
	while ((((level - 1) * level) / 2) * basePoints <= points) {
		level += 1;
	}
	level -= 1; // Rollback the last level addition, which caused the total to exceed the points.

	// Calculate progress towards next level
	const totalPointsForCurrentLevel = (((level - 1) * level) / 2) * basePoints;
	const remainingPoints = points - totalPointsForCurrentLevel;
	if (level <= 0) {
		level = 1;
	}

	return {
		level: level,
		points: remainingPoints,
	};
}

export function convertTotalPointsToOverallLevel(points: number) {
	const basePoints = 100;
	return Math.floor((-(-1) + Math.sqrt(-1 * -1 - 4 * 1 * ((-2 * points) / basePoints))) / 2);
}

export function timeBetweenDates(laterTime: Moment, earlierTime: Moment): string {
	// if (!date1 || !date2) return "just now"
	let duration = moment.duration(laterTime.diff(earlierTime));
	let minutes = duration.asMinutes();
	let hours = duration.asHours();
	let days = duration.asDays();
	let months = duration.asMonths();
	let years = duration.asYears();

	if (years >= 1) {
		return years >= 2 ? `about ${Math.round(years)} years ago` : "about a year ago";
	} else if (months >= 1) {
		return months >= 2 ? `about ${Math.round(months)} months ago` : "about a month ago";
	} else if (days >= 1) {
		return days >= 2 ? `about ${Math.round(days)} days ago` : "about a day ago";
	} else if (hours >= 1) {
		return hours >= 2 ? `about ${Math.round(hours)} hours ago` : "about an hour ago";
	} else if (minutes >= 1) {
		return minutes >= 2 ? `about ${Math.round(minutes)} minutes ago` : "about a minute ago";
	} else {
		return `just now`;
	}
}

export function formatMomentDate(date: Moment) {
	const now = moment();
	const passed = moment(date);

	const diffDays = now.diff(passed, "days");
	const diffWeeks = now.diff(passed, "weeks");
	const diffMonths = now.diff(passed, "months");
	const diffYears = now.diff(passed, "years");

	if (diffDays === 0) {
		return passed.format("h:mm a");
	} else if (diffDays === 1) {
		return "Yesterday, " + passed.format("h:mm a");
	} else if (diffDays < 7) {
		return diffDays + " days ago, " + passed.format("h:mm a");
	} else if (diffWeeks < 4) {
		return diffWeeks + (diffWeeks > 1 ? " weeks ago" : " week ago");
	} else if (diffMonths < 12) {
		return diffMonths + (diffMonths > 1 ? " months ago" : " month ago");
	} else if (diffYears >= 1) {
		return "Over a year ago";
	}
}

export function getIntensityMultiplier(intensity: string): number {
	switch (intensity) {
		case "low":
			return 1;
		case "moderate":
			return 1.25;
		case "high":
			return 1.75;
		case "very high":
		case "veryHigh":
			return 2;
		default:
			return 1;
	}
}

export function getIntensityLevel(result: number): string {
	if (result <= 1) {
		return "low";
	} else if (result >= 1.1 && result < 1.2) {
		return "moderate";
	} else if (result >= 1.2 && result < 1.5) {
		return "high";
	} else {
		return "very high";
	}
}

type Summary = {
	summaryText: string[];
	hoursElapsed: number;
	strengthPoints: number;
	cardioPoints: number;
	mobilityPoints: number;
	mindfulnessPoints: number;
};

type DegradeHealthReturn = {
	newObject: HeroObject;
	summary: Summary;
};

export function degradeHeroHealth(currentObject: HeroObject, lastUpdated: Moment): DegradeHealthReturn {
	let newObject: any = { ...currentObject }; // Clone current stats to avoid mutation
	let attributes = ["strength", "cardio", "mobility", "mindfulness"];
	let degradationRates: any = { strength: 0.02, cardio: 0.03, mobility: 0.01, mindfulness: 0.02 };
	let summaryText: string[] = [];

	// Initialize the Summary
	let summary: any = {
		summaryText: [],
		hoursElapsed: 0,
		strengthPoints: 0,
		cardioPoints: 0,
		mobilityPoints: 0,
		mindfulnessPoints: 0,
	};

	// Calculate the elapsed time in hours
	let elapsedHours = moment.duration(moment().diff(lastUpdated)).asHours();

	attributes.forEach((attribute) => {
		let initialLevel = newObject[`${typeof attribute}Level`];
		let degradationRate = degradationRates[typeof attribute] * (1 + 0.1 * (newObject[`${typeof attribute}Level`] - 1));

		// Calculate the health lost over the elapsed time and round it to the nearest integer
		let pointsLost = Math.round(degradationRate * elapsedHours);

		newObject[`${attribute}Points`] -= pointsLost;

		// If the attribute points fell below 0, decrease the level and set the points to the maximum for that level minus 1
		while (newObject[`${attribute}Points`] < 0 && newObject[`${attribute}Level`] > 1) {
			newObject[`${attribute}Level`]--;
			newObject[`${attribute}Points`] = 100 * (newObject[`${attribute}Level`] + 1) + newObject[`${attribute}Points`]; // Add negative healthLoss to new max health
		}

		// If level is 1 and points are below 0, just reset points to max points - 1
		if (newObject[`${attribute}Level`] === 1 && newObject[`${attribute}Points`] < 0) {
			newObject[`${attribute}Points`] = 100 * (newObject[`${attribute}Level`] + 1) - 1;
		}

		// Construct a summary string
		if (pointsLost > 0) {
			let summary = `After ${elapsedHours.toFixed(2)} hours, ${attribute} points have been reduced by ${pointsLost}. `;
			if (initialLevel !== newObject[`${attribute}Level`]) {
				summary += `The hero's ${attribute} level is now ${newObject[`${attribute}Level`]} `;
			}
			summaryText.push(summary);
		}

		// Update summary points
		summary[`${attribute}Points`] = pointsLost;
	});

	summary.summaryText = summaryText;
	summary.hoursElapsed = elapsedHours;

	return { newObject, summary };
}

export const calculatePoints = (
	activities: ActivityLedger[],
	period: "daily" | "weekly" | "monthly" | "yearly"
): { strengthPoints: any; cardioPoints: any; mobilityPoints: any; mindfulnessPoints: any } => {
	let result: any = {
		strengthPoints: {},
		cardioPoints: {},
		mobilityPoints: {},
		mindfulnessPoints: {},
	};

	let periodFormat: string;
	switch (period) {
		case "weekly":
			periodFormat = "YYYY-[W]WW";
			break;
		case "monthly":
			periodFormat = "YYYY-MM";
			break;
		case "yearly":
			periodFormat = "YYYY";
			break;
		case "daily":
		default:
			periodFormat = "YYYY-MM-DD";
			break;
	}

	if (activities !== null) {
		activities.forEach((curr: any) => {
			if (curr.deleted && curr.deleted === true) {
				return;
			}
			const activityDate = moment(curr.activityDate).format(periodFormat);
			attributes.forEach((attribute) => {
				if (curr[attribute + "Points"]) {
					if (result[attribute + "Points"][activityDate]) {
						result[attribute + "Points"][activityDate] += curr[attribute + "Points"];
					} else {
						result[attribute + "Points"][activityDate] = curr[attribute + "Points"];
					}
				}
			});
		});
	}

	return result;
};

export function cleanMessages(messages: DuelDisplayMessage[]) {
	return messages.map((message) => {
		// Remove 'Vincent Vegas:' from the beginning
		if (message.content.startsWith("Vincent Vegas:")) {
			message.content = message.content.replace("Vincent Vegas:", "");
		}

		// Remove 'VV:' from the beginning
		if (message.content.startsWith("VV:")) {
			message.content = message.content.replace("VV:", "");
		}

		// Remove quote marks from anywhere in the string
		message.content = message.content.replace(/"/g, "");

		// Remove leading and trailing whitespace
		message.content = message.content.trim();

		return message;
	});
}

export function cleanString(str: string, villainName: string) {
	// Remove 'Vincent Vegas:' from the beginning
	if (str.startsWith(villainName + ":")) {
		str = str.replace(villainName + ":", "");
	}

	// Remove 'VV:' from the beginning
	if (str.startsWith("VV:")) {
		str = str.replace("VV:", "");
	}

	// Remove quote marks from anywhere in the string
	str = str.replace(/"/g, "");

	// Remove leading and trailing whitespace
	str = str.trim();

	return str;
}

export async function updateCoachMark(coachMarkName: string, coachMarkValue: boolean) {
	let coachMarks = await storageService.getObject(coachMarkObjectKey);
	// update coachMarks if it exists, otherwise set it
	coachMarks = { ...coachMarks, [coachMarkName]: coachMarkValue };
	await storageService.setObject(coachMarkObjectKey, coachMarks);
}

export async function shouldShowCoachmark(coachMarkName: string): Promise<boolean> {
	let coachMarks = await storageService.getObject(coachMarkObjectKey);
	// update coachMarks if it exists, otherwise set it
	if (coachMarks && coachMarks[coachMarkName] && coachMarks[coachMarkName] === true) {
		return false;
	} else {
		return true;
	}
}

export function hashCode(string: string) {
	var hash = 0,
		i,
		chr;
	if (string.length === 0) return hash;
	for (i = 0; i < string.length; i++) {
		chr = string.charCodeAt(i);
		hash = (hash << 5) - hash + chr;
		hash |= 0; // Convert to 32bit integer
	}
	return Math.abs(hash);
}

export function getNumberOfStepsFromWorkoutArray(workoutArray: WorkoutObject[]) {
	// for every element in the array, if the activity type is "Steps", add the value to the total
	let totalSteps = 0;
	workoutArray.forEach((workout) => {
		if (workout.activityType === "Steps") {
			totalSteps += workout.value;
		}
	});
	return totalSteps;
}

export async function isOnboardingCompleted() {
	let onboarding: OnboardingObject | null = await storageService.getObject("onboarding");
	if (onboarding?.completed) return true;
	if (onboarding?.completed === false) return false;
	return null;
}

export async function hasCoachMarkBeenShown(coachMarkName: string) {
	let coachMarks = await storageService.getObject(coachMarkObjectKey);
	// update coachMarks if it exists, otherwise set it
	if (coachMarks && coachMarks[coachMarkName] && coachMarks[coachMarkName] === true) {
		return true;
	} else {
		return false;
	}
}

export async function getPlatform() {
	let deviceInfo = await Device.getInfo();
	return deviceInfo.platform;
}

interface UpdateLog {
	createdAt: string;
	updateString: string;
	versionNumber: string;
	_id: string;
}

export async function getVersionFromServer(): Promise<VersionInfo> {
	let query = new Parse.Query(UPDATES_TABLE);
	query.descending("createdAt");
	query.limit(1);
	let result = await query.first();
	if (!result) {
		throw "Error getting version from server";
	}
	let update = result.toJSON() as unknown as UpdateLog;
	// log.debug(`[getVersionFromServer] result: `, update);

	let versionInfo: VersionInfo | null = await storageService.getObject(versionUpdateKey);
	// return update[0].update
	if (versionInfo === null) {
		versionInfo = {
			currentVersion: update.versionNumber,
			lastVersion: update.versionNumber,
			update: update.updateString,
			updateShown: true,
		};
		await storageService.setObject(versionUpdateKey, versionInfo);
		return versionInfo;
	}
	if (versionInfo.currentVersion !== update.versionNumber) {
		versionInfo = {
			lastVersion: versionInfo.currentVersion,
			currentVersion: update.versionNumber,
			update: update.updateString,
			updateShown: false,
		};
		await storageService.setObject(versionUpdateKey, versionInfo);
		return versionInfo;
	}
	return versionInfo;
}

/**
 *
 * Returns a random value from the array that was passed
 * Array should be a simple one of just a set of values
 *
 * @param {*} array
 */

export const getRandomValueFromArray = (array: any[]) => {
	return array[Math.floor(Math.random() * array.length)];
};

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stringContains(string: string, substring: string[]) {
	let result = false;
	substring.forEach((sub) => {
		if (string.includes(sub)) {
			result = true;
		}
	});
	return result;
}

export function insertNewLines(text: string) {
	// This regular expression matches a period, exclamation point, or question mark
	// followed by one or more spaces and then a capital letter, capturing both punctuation and space.
	const sentenceEndRegex = /([.!?])\s+(?=[A-Z])/g;

	// Split the text into an array of sentences based on the regex.
	// We include the capture group to keep the punctuation as part of the split result.
	let sentences = text.split(sentenceEndRegex);

	// Reconstruct the text, ensuring punctuation is correctly placed with the previous segment.
	let result = "";
	for (let i = 0; i < sentences.length - 1; i += 2) {
		result += sentences[i] + sentences[i + 1].trim() + "\n";
	}
	// Add the last sentence if it does not end with a punctuation mark
	if (sentences.length % 2 !== 0) {
		result += sentences[sentences.length - 1];
	}

	return result.trim(); // Trim any trailing whitespace or newlines.
}

// export function insertNewLines(text: string) {
// 	// This regular expression matches a period, exclamation point, or question mark
// 	// followed by a space and then a capital letter. It uses positive lookahead to include
// 	// the punctuation in the split without consuming the space and capital letter, thus not copying it over.
// 	const sentenceEndRegex = /(?<=[.!?])\s+(?=[A-Z])/;

// 	// Split the text into an array of sentences based on the regex.
// 	const sentences = text.split(sentenceEndRegex);

// 	// Reconstruct the text, inserting a newline after each sentence.
// 	let result = sentences.join("\n");

// 	return result.trim(); // Trim any trailing whitespace or newlines.
// }

// export function insertNewLines(text: string): string {
// 	const sentenceEndRegex = /[.!?]/;
// 	let sentenceCount = 0;
// 	let result = '';
// 	if (!text) {
// 		return text;
// 	}

// 	for (let i = 0; i < text.length; i++) {
// 		result += text[i];
// 		if (sentenceEndRegex.test(text[i])) {
// 			sentenceCount++;
// 			if (sentenceCount % 2 === 0 && i < text.length - 1) {
// 				// Check if the remaining text contains another sentence-ending punctuation
// 				const remainingText = text.substring(i + 1);
// 				const nextSentenceEnd = remainingText.search(sentenceEndRegex);
// 				if (nextSentenceEnd !== -1) {
// 					result += '\n';
// 				}
// 			}
// 		}
// 	}

// 	return result.trim(); // Trim any trailing whitespace or newlines.
// }


export function convertStepsToSeconds(steps: number) {
	return Math.round((steps / 10000 * 5) / 2.5 * 3600)
}

export function convertSecondsToSteps(seconds: number) {
	return Math.round((seconds / 3600 * 2.5) / 5 * 10000)
}

export function convertStepsToCalories(steps: number) {
	return Math.round(steps * 0.05)
}

export function getPersonalPronoun(gender: string | undefined) {
	if (gender === 'male') {
		return 'he';
	} else if (gender === 'female') {
		return 'she';
	} else {
		return 'they';
	}
}

export function getPossessivePronoun(gender: string | undefined) {
	if (gender === 'male') {
		return 'his';
	} else if (gender === 'female') {
		return 'her';
	} else {
		return 'their';
	}
}

export function getThirdPersonPronoun(gender: string | undefined) {
	if (gender === 'male') {
		return 'him';
	} else if (gender === 'female') {
		return 'her';
	} else {
		return 'them';
	}
}

export function toCamelCase(str: string) {
	return str
		.replace(/\s+/g, ' ') // Replace multiple spaces with a single space
		.trim() // Remove leading and trailing spaces
		.split(' ') // Split the string into words
		.map((word, index) => {
			// Convert the first word to lower case, and the rest to "PascalCase"
			if (index === 0) {
				return word.toLowerCase();
			} else {
				return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
			}
		})
		.join(''); // Join the words without spaces
}
