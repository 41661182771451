import { IonButton, IonContent, IonSpinner } from '@ionic/react'
import React, { useContext, useEffect, useState } from 'react'
import GlobalState from '../../../contexts/GlobalState';
import { createInviteCode, getInviteCode } from '../../../services/ChallengeService';
import InstagramShare from './ShareComponent';

export const ChallengeInvite = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const {
        userState,
        heroObject,
    } = globalState;
    const [inviteCode, setInviteCode] = useState<string>("");
    const [gettingCode, setGettingCode] = useState<boolean>(false);

    useEffect(() => {
        setCode();
    }, [])

    async function setCode() {
        setGettingCode(true);
        let code = await getInviteCode(userState.user?.userID!);
        if (code) {
            setInviteCode(code);
        }
        setGettingCode(false);
    }

    async function createCode() {
        setGettingCode(true);
        let code = await createInviteCode(userState.user?.userID!, heroObject?.heroName!);
        setInviteCode(code);
        setGettingCode(false);
    }

    return (
        <>
            <IonContent>
                <div style={{ textAlign: "center", width: "90%", margin: "auto" }}>
                    <h1>Every friend that joins the challenge earns you 50 points</h1>
                    <div style={{ marginTop: "40px" }}>
                        {gettingCode ? <IonSpinner /> : (
                            <>
                                {inviteCode ? (
                                    <>
                                        <p>Your invite code is:<br /><span style={{ fontSize: "36px" }}>{inviteCode}</span></p>
                                    </>
                                ) : (
                                    <>
                                        <IonButton color={'tertiary'} style={{ color: "white" }} onClick={() => createCode()}>Create your share code</IonButton>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                    {inviteCode && (
                        <div style={{ marginTop: "40px" }}>
                            <InstagramShare shareCode={inviteCode} />
                        </div>
                    )}
                </div>

            </IonContent>
        </>
    )
}
