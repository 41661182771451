import React from 'react'
import {
    IonButton,
    IonIcon, IonModal, IonRow, IonSpinner
} from "@ionic/react";
import { arrowForward } from "ionicons/icons";
import { useContext, useEffect, useState } from "react";
import Confetti from "react-confetti";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import GlobalState from "../contexts/GlobalState";
import "../css/Celebrations.css";
import "../css/Skills.css";
import { HeroStatsUpdate, activityCelebrationImages } from "../models/celebrationModels";
import { getGoalAccomplishedInfo, getRandomValueFromArray, getStatInfo, reorderProperties } from "../services/HelperService";
import { logGenericEventWithObject } from '../services/AnalyticsService';
import { getSkillInfo } from '../services/SkillsService';
import { Skill } from '../models/skills';
import log from 'loglevel';
import { getHeroTranslation } from '../services/MessageService';
import { startDuel } from '../services/DuelsServics';


const LevelAndStatCelebration = (props: { updateObject: HeroStatsUpdate, dismissFunction: any }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject } = globalState;
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass>();
    const [summary, setSummary] = useState<HeroStatsUpdate>({});
    const [celebrationImage, setCelebrationImage] = useState<string>("");
    const [newCardioSkill, setNewCardioSkill] = useState<Skill>();
    const [newStrengthSkill, setNewStrengthSkill] = useState<Skill>();
    const [pickCardioSpecialization, setPickCardioSpecialization] = useState<boolean>(false);
    const [pickStrengthSpecialization, setPickStrengthSpecialization] = useState<boolean>(false);
    const [mindfulMessage, setMindfulMessage] = useState<string>("");
    const [strengthMessage, setStrengthMessage] = useState<string>("");
    const [cardioMessage, setCardioMessage] = useState<string>("");
    const [mobilityMessage, setMobilityMessage] = useState<string>("");
    // const closeModal = async () => {
    //     showCelebrationModal({ show: false });
    // };

    useEffect(() => { }, [swiperInstance]);
    useEffect(() => {
        createCelebration();
    }, []);

    type Attributes = "mindfulness" | "strength" | "cardio" | "mobility";
    type ChangeTypes = "increase" | "decrease" | "none";
    const attributeNames: Attributes[] = ["mindfulness", "strength", "cardio", "mobility"];

    type StatDetails = {
        change?: ChangeTypes;
        value?: number;
        current?: number;
    };

    const levelsWithNewSkills = [2, 5, 7, 10, 15]
    const levelWithNewSkillsAfterSpecialization = [25, 30, 35, 40, 45, 50]

    async function createCelebration() {
        //TODO: Runs these in parallel
        log.debug(`props.updateObject: `, props.updateObject);
        await checkForNewCardioSkills()
        await checkForNewStrengthSkills()
        let reOrdered = reorderProperties(props.updateObject?.summary || {}, ["level"]);
        log.debug(`reOrdered: `, reOrdered);

        logGenericEventWithObject("level/stat celebration", reOrdered);
        // setCelebrationImage(getRandomValueFromArray(activityCelebrationImages));
        if (heroObject && heroObject.heroImageURL) {
            // log.debug("heroObject: ", heroObject);
            if (heroObject.heroImages && heroObject.heroImages.happy) {
                let randomURL = getRandomValueFromArray(heroObject.heroImages.happy);
                setCelebrationImage(randomURL)
            } else {
                setCelebrationImage(heroObject?.heroImageURL);
            }
        } else {
            setCelebrationImage(getRandomValueFromArray(activityCelebrationImages));
        }

        Object.entries(reOrdered).map(async ([summaryProperty, stats], index) => {
            // summaryProperty will be an attribute, "level" or "statsSummary"
            let message = `Thank you for increasing my ${summaryProperty}. This will help in my mission to defeat the villains!`;

            if (summaryProperty === "strength") {
                setStrengthMessage(await getHeroTranslation(message))
            }
            if (summaryProperty === "cardio") {
                setCardioMessage(await getHeroTranslation(message))
            }
            if (summaryProperty === "mobility") {
                setMobilityMessage(await getHeroTranslation(message))
            }
            if (summaryProperty === "mindfulness") {
                setMindfulMessage(await getHeroTranslation(message))
            }
        })
        setSummary(reOrdered);
    }

    async function checkForNewCardioSkills() {
        if (props.updateObject.summary?.cardio?.level?.change === "increase") {
            if (levelsWithNewSkills.includes(props.updateObject.summary?.cardio?.level.current!)) {
                let skillInfo = await getSkillInfo("cardio", props.updateObject.summary?.cardio?.level.current!)
                setNewCardioSkill(skillInfo)
                log.debug(`skillInfo: `, skillInfo);
            } else if (props.updateObject.summary?.cardio?.level.current! === 20) {
                // show slide for picking a specialization
                setPickCardioSpecialization(true)
            } else if (levelWithNewSkillsAfterSpecialization.includes(props.updateObject.summary?.cardio?.level.current!)) {
                // check specialization first, if it exists show new skill for it, if not prompt to pick a specialization
                if (heroObject?.cardioTreeSpecialization === undefined || heroObject?.cardioTreeSpecialization === "" || heroObject?.cardioTreeSpecialization === null) {
                    setPickCardioSpecialization(true)
                } else {
                    let skillInfo = await getSkillInfo("cardio", props.updateObject.summary?.cardio?.level.current!, heroObject?.cardioTreeSpecialization)
                    setNewCardioSkill(skillInfo)
                }
            }
        }
    }

    async function checkForNewStrengthSkills() {
        if (props.updateObject.summary?.strength?.level?.change === "increase") {
            if (levelsWithNewSkills.includes(props.updateObject.summary?.strength?.level.current!)) {
                let skillInfo = await getSkillInfo("strength", props.updateObject.summary?.strength?.level.current!)
                setNewStrengthSkill(skillInfo)
                log.debug(`skillInfo: `, skillInfo);
            } else if (props.updateObject.summary?.strength?.level.current! === 20) {
                // show slide for picking a specialization
                setPickStrengthSpecialization(true)
            } else if (levelWithNewSkillsAfterSpecialization.includes(props.updateObject.summary?.strength?.level.current!)) {
                // check specialization first, if it exists show new skill for it, if not prompt to pick a specialization
                if (heroObject?.strengthTreeSpecialization === undefined || heroObject?.strengthTreeSpecialization === "" || heroObject?.strengthTreeSpecialization === null) {
                    setPickStrengthSpecialization(true)
                } else {
                    log.debug("heroObject?.strengthTreeSpecialization: ", heroObject?.strengthTreeSpecialization)
                    let skillInfo = await getSkillInfo("strength", props.updateObject.summary?.strength?.level.current!, heroObject?.strengthTreeSpecialization)
                    setNewStrengthSkill(skillInfo)
                }
            }
        }
    }

    function renderAttributes(attribute: string, statAttribute: string, details: any) {
        let statInfo = getStatInfo(attribute)
        return (
            <div>
                <div className="celebration-stat-item">
                    <div className={`celebration-stat-icon celebration-stat-icon-${attribute}`}>{statInfo.icon}</div>
                    {statAttribute === "points" && (
                        <div className="celebration-stat-details">
                            <span className="celebration-stat-change">
                                {details.change === "increase" ? "+" : "-"} {details.value}
                            </span>
                            &nbsp;points
                        </div>
                    )}
                    {statAttribute === "level" && (
                        <>
                            <div className="celebration-stat-details">
                                <span className="celebration-stat-change">
                                    {details.change === "increase" ? "+" : "-"} {details.value} level
                                </span>{" "}
                                <span className="celebration-stat-current">
                                    <IonIcon className="celebration-stat-arrow" icon={arrowForward} /> Level {details.current}
                                </span>

                            </div>
                        </>
                    )}
                </div>
                {statAttribute === "level" && (
                    <>
                        <div style={{ fontStyle: "italic" }}>{statInfo.text}</div>
                    </>
                )}
            </div>
        );
    }

    function renderGoalAccomplishment(attribute: string) {
        let statInfo = getGoalAccomplishedInfo(attribute)
        return (
            <div>
                <div className="celebration-goal-item">
                    <div className={`celebration-stat-icon celebration-stat-icon-${attribute}`}>{statInfo.icon}</div>
                    <div className="celebration-stat-details">
                        <span className="celebration-stat-change">
                            {statInfo.value}
                        </span>
                    </div>
                </div>
                <div style={{ fontStyle: "italic" }}>{statInfo.text}</div>
            </div>
        );
    }

    function renderLevel(stats: any) {
        // log.debug(`stats: `, stats);
        // let previousLevel = stats.level.current! - stats.level.value!;
        let currentLevel = stats.level.current;
        return (
            <>
                <div className="celebration-level-container">
                    <div className="celebration-level-text fade-in">{currentLevel}</div>
                    <img src="https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/a890821cf93ef69ee6afdec851a21640_gray-level-up-kettlebell-1-count.gif" />
                </div>
            </>
        )
    }

    return (
        <>
            <div className="celebration-container">
                <div className="celebration-swiper">
                    <Swiper
                        spaceBetween={50}
                        modules={[Navigation]}
                        slidesPerView={1}
                        navigation
                        pagination={{ type: "bullets" }}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        autoHeight={false}
                        className='celebration-slide'
                    >
                        {summary &&
                            Object.entries(summary).map(([summaryProperty, stats], index) => {
                                log.debug(`summaryProperty: `, summaryProperty);
                                log.debug(`stats: `, stats);
                                if (summaryProperty === "level") {
                                    return (
                                        <SwiperSlide>
                                            <div className="celebration-top-area">
                                                <div>
                                                    {renderLevel(summary)}
                                                </div>
                                            </div>
                                            <div className="celebration-subtitle">{heroObject?.heroName} leveled up!</div>
                                            {/* <div key={summaryProperty}>{renderLevel(stats as StatDetails)}</div> */}
                                            <div className="celebration-body-text">{heroObject?.heroName} is now <span className='emphasis-text'>STRONGER</span> and <span className='emphasis-text'>FITTER</span></div>
                                            <div className="celebration-body-text">Great job supporting your hero! Keep up the great work</div>
                                        </SwiperSlide>
                                    );
                                } else if (attributeNames.includes(summaryProperty as Attributes)) {
                                    // ie. if it's an attribute
                                    log.debug(`summaryProperty: `, summaryProperty);
                                    // log.debug(`stats: `, stats);
                                    let attributeAdjective = "stronger"
                                    let message = ""
                                    switch (summaryProperty) {
                                        case "mindfulness":
                                            attributeAdjective = "more mindful"
                                            message = mindfulMessage
                                            break;
                                        case "cardio":
                                            attributeAdjective = "more fit"
                                            message = cardioMessage
                                            break;
                                        case "mobility":
                                            attributeAdjective = "more flexible"
                                            message = mobilityMessage
                                            break;
                                        default:
                                            attributeAdjective = "stronger"
                                            message = strengthMessage
                                            break;
                                    }
                                    return (
                                        <>
                                            <SwiperSlide key={Math.random()}>
                                                <div className="celebration-top-area">
                                                    <div className="villain-chat-container" style={{ width: "90%", margin: "auto" }}>
                                                        <IonRow style={{ justifyContent: "center" }}>
                                                            <div className="celebration-bubble">
                                                                {message === "" ? (
                                                                    <IonSpinner color={'tertiary'} name="crescent" />
                                                                ) : (
                                                                    <p>
                                                                        {message}
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </IonRow>
                                                    </div >
                                                    {celebrationImage && (
                                                        <>
                                                            <div className="hero-avatar-image-container">
                                                                <img src={`${celebrationImage}`} alt="Boss" className="hero-avatar-rounded boss-image" />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="celebration-subtitle">Your activity made {heroObject?.heroName}<br /><span className='emphasis-text'>{attributeAdjective}</span></div>
                                                <div className="celebrations-attributes-container" key={`${summaryProperty}-${index}`}>
                                                    {Object.entries(stats).map(([statAttribute, details]) => renderAttributes(summaryProperty, statAttribute, details))}
                                                </div>

                                            </SwiperSlide>
                                            {Object.entries(stats).map(([statAttribute, details]) => {
                                                if (statAttribute === "goalJustAccomplished") {
                                                    return (
                                                        <>
                                                            <SwiperSlide key={Math.random()}>
                                                                <div className="celebration-top-area">
                                                                    <div className="villain-chat-container" style={{ width: "90%", margin: "auto" }}>
                                                                        <IonRow style={{ justifyContent: "center" }}>
                                                                            <div className="celebration-bubble">
                                                                                {message === "" ? (
                                                                                    <IonSpinner color={'tertiary'} name="crescent" />
                                                                                ) : (
                                                                                    <p>
                                                                                        {message}
                                                                                    </p>
                                                                                )}
                                                                            </div>
                                                                        </IonRow>
                                                                    </div >
                                                                    {celebrationImage && (
                                                                        <>
                                                                            <div className="hero-avatar-image-container">
                                                                                <img src={`${celebrationImage}`} alt="Boss" className="hero-avatar-rounded boss-image" />
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className="celebration-subtitle">You accomplished your {summaryProperty} goal for the week!</div>
                                                                <div className="celebrations-attributes-container" key={`${summaryProperty}-${index}`}>
                                                                    {Object.entries(stats).map(([statAttribute, details]) => {
                                                                        if (statAttribute === "goalJustAccomplished" && details === true) {
                                                                            return renderGoalAccomplishment(summaryProperty);
                                                                        }
                                                                    })}
                                                                </div>
                                                            </SwiperSlide>
                                                        </>
                                                    )
                                                }
                                            })}
                                            {newCardioSkill && summaryProperty === "cardio" && (
                                                <SwiperSlide key={Math.random()}>
                                                    <div className="celebration-top-area">
                                                        <div className={`image-container`}>
                                                            <img src={`${newCardioSkill.icon!.url}`} alt="new cardio skill icon" className="skill-icon-celebration" />
                                                        </div>
                                                    </div>
                                                    <div className="celebration-subtitle">{heroObject?.heroName} earned a new Cardio skill!</div>
                                                    <div className='celebration-middle-area'>
                                                        <div className="celebration-body-text"><span className="skill-name">{newCardioSkill.name}</span></div>
                                                        <div className="celebration-skill-description"><p>{newCardioSkill.description}</p><p style={{ fontSize: "14px" }}>{heroObject?.heroName} can now use the {newCardioSkill.name} skill in duels</p></div>
                                                    </div>
                                                </SwiperSlide>
                                            )}
                                            {pickCardioSpecialization === true && (
                                                <SwiperSlide key={Math.random()}>
                                                    <div className="celebration-top-area">
                                                        <div className={`image-container`}>
                                                            <img src={`assets/images/cardio-specialization-selection-2.jpg`} alt="new cardio skill icon" className="skill-icon-celebration" />
                                                        </div>
                                                    </div>
                                                    <div className="celebration-subtitle">{heroObject?.heroName} hit level 20 in Cardio!<br />Time to pick a specialization</div>
                                                    <div className='celebration-middle-area'>
                                                        Specializations give your hero access to new skills, letting them defeat more challenging villains
                                                    </div>
                                                    <div className='celebration-bottom-area'>
                                                        <IonButton style={{ margin: "20px", "--background": "var(--cardio-color)" }} routerLink={`/skills/cardio`} onClick={() => props.dismissFunction()}>Pick a Cardio Specialization</IonButton>
                                                    </div>

                                                </SwiperSlide>
                                            )}
                                            {newStrengthSkill && summaryProperty === "strength" && (
                                                <SwiperSlide key={Math.random()}>
                                                    <div className="celebration-top-area">
                                                        <div className={`image-container`}>
                                                            <img src={`${newStrengthSkill.icon!.url}`} alt="new strength skill icon" className="skill-icon-celebration" />
                                                        </div>
                                                    </div>
                                                    <div className="celebration-subtitle">{heroObject?.heroName} earned a new Strength skill!</div>
                                                    <div className='celebration-middle-area'>
                                                        <div className="celebration-body-text"><span className="skill-name">{newStrengthSkill.name}</span></div>
                                                        <div className="celebration-skill-description"><p>{newStrengthSkill.description}</p><p style={{ fontSize: "14px" }}>{heroObject?.heroName} can now use the {newStrengthSkill.name} skill in duels</p></div>
                                                    </div>
                                                </SwiperSlide>
                                            )}
                                            {pickStrengthSpecialization === true && (
                                                <SwiperSlide key={Math.random()}>
                                                    <div className="celebration-top-area">
                                                        <div className={`image-container`}>
                                                            <img src={`assets/images/strength-specialization-selection.jpg`} alt="new cardio skill icon" className="skill-icon-celebration" />
                                                        </div>
                                                    </div>
                                                    <div className="celebration-subtitle">{heroObject?.heroName} hit level 20 in Strength!<br />Time to pick a specialization</div>
                                                    <div className='celebration-middle-area'>
                                                        Specializations give your hero access to new skills, letting them defeat more challenging villains
                                                    </div>
                                                    <div className='celebration-bottom-area'>
                                                        <IonButton style={{ margin: "20px", "--background": "var(--strength-color)" }} routerLink={`/skills/strength`} onClick={() => props.dismissFunction()}>Pick a Strength Specialization</IonButton>
                                                    </div>

                                                </SwiperSlide>
                                            )}
                                        </>

                                    );
                                }
                            })}
                    </Swiper>
                </div>
            </div>
        </>
    )
}

export default LevelAndStatCelebration