import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react';
import { useContext, useEffect } from 'react';
import HeaderLogo from '../../components/HeaderLogo';
import "../../css/Onboarding.css";
import { StorageService } from "../../services/StorageService";
import GlobalState from '../../contexts/GlobalState';
const storageService = new StorageService();


const Intro3 = () => {
    const nextPage = "/onboarding/createavatar";
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile } = globalState;


    useEffect(() => {
        // initAnalyticsFreshInstall();
        storageService.removeItem("onboarding");
    }, []);
    return (
        <IonPage>
            <HeaderLogo checkforUser={false} />
            <IonContent>
                <div className="onboarding-main-div">
                    <div className="onboarding-title">Step 3: Help Your Hero Win <br />By Being Active</div>
                    <div className='onboarding-text-area' style={{ textAlign: "center", marginTop: "0px", marginBottom: "20px" }}>
                        Engage in healthy activities to help your hero win and help you <span className='onboarding-emphasis'>stay accountable</span> to a healthy lifestyle
                    </div>

                    <div>
                        <img className="onboarding-hero-image" src="assets/images/onboarding/hero-human.png" alt="" />
                    </div>
                </div>
            </IonContent>
            <IonFooter className="onboarding-footer">
                <IonButton routerLink={nextPage} className="onboarding-advance-button">
                    Create your Hero
                </IonButton>
            </IonFooter>
        </IonPage>
    )
}

export default Intro3