/* eslint-disable no-loop-func */
import { Health, HealthData } from "@awesome-cordova-plugins/health";
import moment, { Moment } from "moment";
import { ActivityLedger, GoogleFitWorkoutObject, ProgressCallback, WorkoutObject } from "../models/exerciseModels";
import {
	convertStepsToWorkoutObject,
	createLedgerFromWorkoutArray,
	filterForUniqueWorkouts,
	getRecentWorkouts,
	postWorkoutsToServer,
	upsertWorkouts,
} from "./ExerciseService";
import { getIntensityLevel, hashCode } from "./HelperService";
import { StorageService } from "./StorageService";
import { getUser_id } from "./UserService";
import { OnboardingObject, googleFitAuthorizedKey } from "../models/userModel";
import { ExcludedActivities } from "../models/exerciseModels";
import log from "loglevel";
const storageService = new StorageService();

export async function createUUIDFromString(string: string) {
	let userID = await getUser_id();
	string = string + userID;
	let uuid = hashCode(string).toString();
	log.debug("[health] uuid: ", uuid);
	return uuid;
}

export async function convertGFObjectToExerciseObject(GFObject: GoogleFitWorkoutObject, user_id: string = "") {
	if (user_id === "") {
		user_id = await getUser_id();
	}
	let duration = moment(GFObject.endDate.toString()).diff(moment(GFObject.startDate.toString()), "seconds");
	// log.debug("[Health] duration: ", duration)
	const baselineMultiple = 8;
	let caloriesBurned = Math.round(GFObject.calories * 100) / 100;
	let caloriesPerMinute = Math.round((caloriesBurned / (duration / 60)) * 100) / 100;
	let intensityMultiple = Number((caloriesPerMinute / baselineMultiple).toFixed(1));
	if (intensityMultiple < 1) intensityMultiple = 1;
	let data: WorkoutObject = {
		user_id: user_id,
		UUID: GFObject.uuid,
		dataType: "workout",
		activityType: GFObject.value.charAt(0).toUpperCase() + GFObject.value.slice(1), // yes, for google fit, the activity type is the value
		startDate: moment(GFObject.startDate.toString()).format(),
		endDate: moment(GFObject.endDate.toString()).format(),
		totalDistance: 0, // cant send these because of the stupid plugin
		totalDistanceUnit: "meters",
		value: duration,
		valueUnit: "sec",
		totalEnergyBurned: caloriesBurned,
		reportId: GFObject.uuid,
		sourceName: GFObject.sourceBundleId,
		timeZone: "",
		weatherTemperature: "",
		humidity: "",
		caloriesPerMinute: caloriesPerMinute,
		intensityMultiple: intensityMultiple,
		intensityLevel: getIntensityLevel(intensityMultiple),
	};
	return data;
}

export async function getGFSteps(startDate: string, endDate: string, baseline: boolean = false, onProgress?: ProgressCallback): Promise<GFData> {
	if (onProgress) onProgress(`Pulling steps data...`);
	let workouts: WorkoutObject[] = [];

	let currentStart = moment(startDate);
	let end = moment(endDate);
	let currentDate = moment();

	while (currentStart.isBefore(end)) {
		// if currentStart is NOT the current day, then this is historic data, and the clone should add 1 day
		// if currentStart IS the current day, then this is current data, and the clone should add 30 minutes
		let currentEnd: Moment;
		// if (currentStart.isSame(currentDate, "day")) {
		// 	currentEnd = currentStart.clone().add(30, "minutes");
		// 	if (onProgress) onProgress(`Pulling steps for today...`);
		// } else {
		// 	currentEnd = currentStart.clone().add(1, "day").subtract(1, "second");
		// 	if (onProgress) onProgress(`Pulling steps for ${moment(currentStart).format("MM/DD/YYYY")}...}`);
		// }

		currentEnd = currentStart.clone().add(30, "minutes");


		if (currentEnd.isAfter(end)) {
			currentEnd = end;
		}

		let gfSteps: any = await Health.queryAggregated({
			startDate: currentStart.toDate(),
			endDate: currentEnd.toDate(),
			dataType: "steps",
			filtered: true,
		}).catch((err: any) => {
			log.debug(`[getGFSteps] queryAggregated error for interval ${currentStart.format()} - ${currentEnd.format()}: ${JSON.stringify(err)}`);
			throw err;
		});

		let caloriesBurned = 0; // Presumably, you might want to modify this according to the returned gfSteps.
		// log.debug(`[getGFSteps] gfSteps for interval ${currentStart.format()} - ${currentEnd.format()}: ${JSON.stringify(gfSteps)}`);

		if (gfSteps && gfSteps.value > 0) {
			// gonna ignore 0 step sessions
			let activityString = moment(gfSteps.startDate.toString()).format() + moment(gfSteps.endDate.toString()).format() + gfSteps.value;
			let uuid = await createUUIDFromString(activityString);
			log.debug(`[getGFSteps] activity: ${JSON.stringify(gfSteps)}`);
			let GFObject: GoogleFitWorkoutObject = {
				uuid: uuid,
				startDate: gfSteps.startDate,
				endDate: gfSteps.endDate,
				calories: caloriesBurned,
				distance: 0,
				sourceBundleId: gfSteps.sourceBundleId,
				unit: gfSteps.unit,
				value: gfSteps.value,
			};
			let durationInSeconds = moment(GFObject.endDate.toString()).diff(moment(GFObject.startDate.toString()), "seconds");
			let workout = await convertStepsToWorkoutObject(gfSteps.startDate, durationInSeconds, gfSteps.value);
			// log.debug(`[getGFSteps] workout: ${JSON.stringify(workout)}`);
			workouts.push(workout);
		}
		// Move to the next 30-minute interval
		// currentStart = currentEnd.clone().add(1, "second");
		currentStart = currentEnd
	}
	log.debug(`[getGFSteps] steps workouts: ${JSON.stringify(workouts)}`);

	if (workouts.length > 0) {
		await postWorkoutsToServer(workouts, false);
	}

	// Regardless, get the recent workouts from the server
	let serverResponse: WorkoutObject[] = await getRecentWorkouts(baseline);
	let ledger = await createLedgerFromWorkoutArray(serverResponse);
	log.debug("[getGFSteps] Server Response: ", JSON.stringify(serverResponse));
	await upsertWorkouts(serverResponse);
	return {
		activityLedger: ledger,
		workoutArray: serverResponse,
		number: 0, // just returning 0 because it's never used
	};
}

export async function getGFActivities(startDate: string, endDate: string, onProgress?: ProgressCallback): Promise<WorkoutObject[]> {
	if (onProgress) onProgress(`Checking Google Fit installation...`);
	let googleFitInstalled = await checkForGoogleFit();
	if (!googleFitInstalled) {
		throw new Error("Google Fit not installed");
	}
	if (onProgress) onProgress(`Google Fit installed, checking authorization...`);
	let isAuthorized = await isGoogleFitAuthorized();
	if (!isAuthorized) {
		let authorizeResult = await googleFitConnect();
		if (!authorizeResult) {
			throw new Error("Google Fit not authorized");
		}
	}
	log.debug(`[getGFActivities] startDate: ${startDate}, endDate: ${endDate}`);
	let workouts: WorkoutObject[] = [];
	if (onProgress) onProgress(`Fetching workouts...`);
	let gfworkouts = await Health.query({
		startDate: moment(startDate).toDate(),
		endDate: moment(endDate).toDate(),
		dataType: "activity",
		// includeCalsAndDist: true,
		limit: 10000,
	}).catch((err: any) => {
		log.debug(`[getGFActivities] query error: ${JSON.stringify(err)}`);
		throw err;
	});
	log.debug(`[getGFActivities] gfworkouts found: ${gfworkouts.length}`);

	// remove any workouts from gFworkouts where the difference between startDate and endDate is less than 1 minute
	gfworkouts = gfworkouts.filter((workout: HealthData) => {
		let startDate = moment(workout.startDate);
		let endDate = moment(workout.endDate);
		let duration = moment.duration(endDate.diff(startDate)).asMinutes();
		// log.debug(`[getGFActivities] duration: ${duration}`);
		return duration > 1;
	});

	// Remove any workouts in gfworkouts that are in the ExcludedActivities array
	gfworkouts = gfworkouts.filter((workout: HealthData) => {
		return !ExcludedActivities.includes(workout.value.toLowerCase());
	});

	log.debug(`[getGFActivities] gfworkouts after trim: ${gfworkouts.length}`);

	if (onProgress) onProgress(`Found ${gfworkouts.length} workouts...`);

	if (gfworkouts) {
		// log.debug(`[Health] query responses: ${JSON.stringify(resp)}`);
		// for each activity, create a workout object
		let currentWorkout = 1;
		for (let activity of gfworkouts) {
			if (onProgress) onProgress(`Processing workout ${currentWorkout} of ${gfworkouts.length}`);
			log.debug(`[getGFActivities] activity: ${JSON.stringify(activity)}`);
			let calorieArray: HealthData[] | void = await Health.query({
				startDate: activity.startDate,
				endDate: activity.endDate,
				dataType: "calories",
				limit: 1000,
			}).catch((err: any) => {
				log.debug(`[getGFActivities] query calories error: ${JSON.stringify(err)}`);
				throw err;
			});
			// sum all the calories for the activity if calorieArray.sourceBundleId === activity.sourceBundleId
			let caloriesBurned = 0;
			if (calorieArray) {
				for (let calorieObject of calorieArray) {
					caloriesBurned += parseFloat(calorieObject.value);
				}
			}
			// Adding the uuid at this level to keep the GoogleFitWorkoutObject and HealthKitWorkoutObject consistent
			let activityString = moment(activity.startDate.toString()).format() + moment(activity.endDate.toString()).format() + activity.value;
			let uuid = await createUUIDFromString(activityString);
			log.debug("[getGFActivities] uuid: ", uuid);
			let GFObject: GoogleFitWorkoutObject = {
				startDate: activity.startDate,
				endDate: activity.endDate,
				calories: caloriesBurned,
				distance: 0,
				sourceBundleId: activity.sourceBundleId,
				unit: activity.unit,
				value: activity.value,
				uuid: uuid,
			};
			log.debug(`[getGFActivities] calories: ${JSON.stringify(calorieArray)}`);
			log.debug(`[getGFActivities] caloriesBurned: ${caloriesBurned}`);
			let workout = await convertGFObjectToExerciseObject(GFObject);
			log.debug(`[getGFActivities] workout: ${JSON.stringify(workout)}`);
			workouts.push(workout);
			currentWorkout++;
		}
		log.debug(`[getGFActivities] workouts: ${JSON.stringify(workouts)}`);
	}
	return workouts;
}

export async function googleFitConnect(onboardingLink: string = ""): Promise<boolean> {
	let authorizeResult = true;
	await Health.requestAuthorization(["calories", "distance", "activity", "steps"])
		.then(async (resp: any) => {
			log.debug(`[Health] requestAuthorization responses: ${JSON.stringify(resp)}`);
			if (onboardingLink !== "") {
				let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
				tempOnboardingObject.url = onboardingLink;
				tempOnboardingObject.completed = false;
				log.debug(`[Health] tempOnboardingObject: ${JSON.stringify(tempOnboardingObject)}`);
				await storageService.setObject("onboarding", tempOnboardingObject);
			}
			await storageService.setItem(googleFitAuthorizedKey, "true");
		})
		.catch((err: any) => {
			log.debug(`[Health] requestAuthorization err: ${JSON.stringify(err)}`);
			authorizeResult = false;
			throw err;
		});
	return authorizeResult;
}

export async function checkForGoogleFit(): Promise<boolean> {
	let available = true;
	await Health.isAvailable()
		.then((resp: any) => {
			log.debug(`[Health] isAvailable responses: ${JSON.stringify(resp)}`);
		})
		.catch((err: any) => {
			log.debug(`[Health] isAvailable err: ${JSON.stringify(err)}`);
			available = false;
		});
	return available;
}

export async function isGoogleFitAuthorized(): Promise<boolean> {
	let isAuthorized = true;
	await Health.isAuthorized(["calories", "distance", "activity", "steps"])
		.then((resp: any) => {
			log.debug(`[Health] isAuthorized responses: ${JSON.stringify(resp)}`);
		})
		.catch((err: any) => {
			log.debug(`[Health] isAuthorized err: ${JSON.stringify(err)}`);
			isAuthorized = false;
		});
	return isAuthorized;
}

export interface GFData {
	activityLedger: ActivityLedger[];
	workoutArray: WorkoutObject[];
	number: number;
}

export async function filterForUniqueGFWorkouts(existingData: WorkoutObject[] | null, workouts: WorkoutObject[], startDate: Moment, endDate: Moment) {
	let tempWorkoutArray: WorkoutObject[] = [];
	// log.debug("workouts: ", workouts);
	// log.debug("existingData: ", existingData);
	workouts.forEach((element: WorkoutObject, index: number) => {
		// if (moment(element.endDate) <= endDate && moment(element.endDate) >= startDate) {
		// Check if a workout with the same UUID exists in existingData
		if (existingData && existingData.some((existingElement) => existingElement.UUID === element.UUID)) {
			// Do nothing (don't push) if a workout with the same UUID already exists
			// log.debug(`Skipping ${element}`);
		} else {
			// If no workout with the same UUID exists, then push the element into the array
			tempWorkoutArray.push(element);
		}
		// }
	});
	// log.debug("tempWorkoutArray: ", tempWorkoutArray);

	return tempWorkoutArray;
}

export async function processWorkoutsFromGoogleFit(
	workouts: WorkoutObject[],
	startDate: Moment,
	endDate: Moment,
	createFakeUUIDs: boolean = false,
	baseline: boolean = false,
	onProgress?: ProgressCallback
): Promise<GFData> {
	// Setup
	if (!startDate) {
		startDate = moment().subtract(2, "week");
	}
	let GFActivityArray: WorkoutObject[] = [];
	let workoutArray: WorkoutObject[] = [];

	// Get GF workouts
	log.debug(`[processWorkoutsFromGoogleFit] Getting GF workouts from ${startDate.format()} to ${endDate.format()}`);
	let GFWorkouts: WorkoutObject[] = await getGFActivities(moment(startDate).format(), moment(endDate).format(), (progress) => {
		if (onProgress) onProgress(`${progress}`);
		// log.debug(`Progress: ${progress}`);
	});
	// // log.debug(`Number of HKWorkouts pulled: ${HKWorkouts?.length}`);
	// // log.debug(`HealthKitWorkoutObject: ${JSON.stringify(workouts)}`);

	// Process workouts
	if (GFWorkouts) {
		GFActivityArray = await filterForUniqueWorkouts(workouts, GFWorkouts);
	}
	log.debug(`[processWorkoutsFromGoogleFit] GFActivityArray: ${JSON.stringify(GFActivityArray)}`);
	log.debug(`[processWorkoutsFromGoogleFit] # unique activities found: ${GFActivityArray.length}`);

	// If there are workouts, post them to the server
	if (GFActivityArray.length > 0) {
		log.debug(`[processWorkoutsFromGoogleFit] Posting ${GFActivityArray.length} workouts to the server`);
		await postWorkoutsToServer(GFActivityArray, false);
	}
	let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(baseline); // not returning something here because recursion
	log.debug(`[processWorkoutsFromGoogleFit] RecentWorkouts: `, recentWorkouts);
	let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
	return {
		activityLedger: activityLedger,
		workoutArray: workoutArray,
		number: GFWorkouts?.length || 0,
	};
}
