import {
    IonAlert,
    IonBackButton,
    IonButton,
    IonButtons,
    IonHeader,
    IonToolbar
} from "@ionic/react";
import { useHistory } from "react-router-dom";
import { StorageService } from "../services/StorageService";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../contexts/GlobalState";
import { getUser } from "../services/UserService";
import log from "loglevel";

const HeaderLogo = (props: { showBackButton?: boolean, checkforUser?: boolean }) => {
    const history = useHistory();
    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { logout } = globalState;
    const [confirmRestart, setConfirmRestart] = useState(false);


    const returnToStart = async () => {
        setConfirmRestart(false);
        await storageService.removeItem("onboarding");
        await logout();
        history.push(`/entryscreen`);
    };
    useEffect(() => {
        if (props.checkforUser !== false) {
            log.debug("[headerLogo] Checking for user");
            getUser().then((user) => {
                if (!user) {
                    returnToStart();
                }
            });
        }
    }, []);
    return (
        <>
            <IonHeader>
                <IonAlert
                    isOpen={confirmRestart}
                    header="Start over?"
                    buttons={[
                        {
                            text: "No",
                            role: "cancel",
                            handler: () => {
                                setConfirmRestart(false);
                            },
                        },
                        {
                            text: "Yes",
                            role: "ok",
                            handler: () => {
                                returnToStart();
                            },
                        },
                    ]}
                />
                <IonToolbar style={{ "--border-style": "none", "--background": "none", textAlign: "center" }}>
                    {(props.showBackButton === undefined || props.showBackButton !== false) && (
                        <>
                            <IonButtons slot="start" style={{ position: "absolute" }}>
                                <IonBackButton style={{ fontSize: "14px" }} color="primary" text={''}>
                                    {/* <IonIcon icon={chevronBack} /> */}
                                </IonBackButton>
                            </IonButtons>
                        </>
                    )}

                    <div
                        style={{ display: "flex", justifyContent: "center", alignItems: "center" }}
                        onClick={() => setConfirmRestart(true)}
                    >
                        <img style={{ height: "30px", width: "30px" }} src="assets/images/fit-hero-shield-transparent.png" />
                    </div>
                </IonToolbar>
            </IonHeader>
        </>
    );
};

export default HeaderLogo;
