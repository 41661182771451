/* eslint-disable react-hooks/exhaustive-deps */
import { IonButton, IonCol, IonContent, IonDatetime, IonHeader, IonIcon, IonInput, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPicker, IonSearchbar, IonTitle, IonToolbar } from '@ionic/react';
import { arrowBack, closeOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Navigation } from 'swiper';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import '../css/AddActivity.css';

import log from 'loglevel';
import moment from 'moment';
import Emoji from 'react-emoji-render';
import { useHistory } from 'react-router';
import 'swiper/css';
import 'swiper/css/navigation';
import { LogActivity1Tour } from '../Features/Tour/Steps';
import { TourComponent } from '../Features/Tour/StepsComponent';
import GlobalState from '../contexts/GlobalState';
import { ActivityLedger, ActivitySelection, ExercisePoints, WorkoutObject, allActivities, cardioIcon, mindfulnessIcon, mobilityIcon, mostPopularActivities, strengthIcon } from '../models/exerciseModels';
import { lastUpdatedStepsKey, lastUpdatedWorkoutKey } from '../models/userModel';
import { logGenericEventWithObject } from '../services/AnalyticsService';
import { processDuelTick } from '../services/DuelsServics';
import { convertManualActivityToWorkoutObject, createLedgerFromWorkoutArray, getRecentWorkouts, postWorkoutsToServer } from '../services/ExerciseService';
import { calculateAttributeRateFromManualWorkoutObj, convertStepsToCalories, convertStepsToSeconds, formatTime } from '../services/HelperService';
import { sendSlackNotification } from '../services/NotificationService';
import { StorageService } from "../services/StorageService";
import { getHero } from '../services/UserService';
let storageService = new StorageService();

const AddActivity = (props: { showActivityModal: any, duelId?: string | null }) => {
    const history = useHistory();
    const contentRef = useRef<any>();
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass>()
    const [selectedActivity, setSelectedActivity] = useState<ActivitySelection>({ icon: "", activityName: "", metValue: 0 })

    const closeModal = async (activityLogged: boolean = false) => {
        log.debug("Closing modal, props.duelId: ", props.duelId, "activityLogged: ", activityLogged)
        if (props.duelId && activityLogged === true) {
            await processDuelTick(props.duelId);
            history.push("/dueldetails/" + props.duelId + "/updates");
        }
        props.showActivityModal(false);
    };

    useEffect(() => { }, [swiperInstance])
    useEffect(() => {
        log.debug("Scrolling to top")
        log.debug("Duel ID: ", props.duelId)
        contentRef.current && contentRef.current.scrollToTop();
    }, [selectedActivity])


    return (
        <>
            <IonContent>
                <Swiper
                    spaceBetween={50}
                    modules={[Navigation]}
                    slidesPerView={1}
                    navigation
                    onSwiper={(swiper) => setSwiperInstance(swiper)}
                    onSlideChange={() => log.debug('slide change')}
                    autoHeight={true}
                >
                    <SwiperSlide>
                        <SelectionScreen closeModal={closeModal} swiper={swiperInstance} setSelectedActivity={setSelectedActivity} />
                    </SwiperSlide>
                    <SwiperSlide>
                        {selectedActivity.icon !== "" && selectedActivity.activityName === "Steps" && <LogSteps closeModal={closeModal} swiper={swiperInstance} selectedActivity={selectedActivity} />}
                        {selectedActivity.icon !== "" && selectedActivity.activityName !== "Steps" && <DataScreen closeModal={closeModal} swiper={swiperInstance} selectedActivity={selectedActivity} />}
                    </SwiperSlide>
                </Swiper>


            </IonContent>
        </>
    )
}


export default AddActivity

const LogSteps = (props: { closeModal: any, selectedActivity: ActivitySelection, swiper: SwiperClass | undefined }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const {
        updateHeroHealth,
        userState
    } = globalState;
    const previousSlide = () => {
        props.swiper?.slidePrev()
    }

    const [date, setDate] = useState<string | undefined | null | string[]>(moment().format())
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
    const [steps, setSteps] = useState<number>(0)
    const [adding, setAdding] = useState<boolean>(false)
    const [calories, setCalories] = useState<number>(0)
    const [pointsObject, setPointsObject] = useState<ExercisePoints>({ strengthPoints: 0, cardioPoints: 0, mobilityPoints: 0, mindfulnessPoints: 0 })

    useEffect(() => {
        let calories = 0;
        let intensityMultiplier = 1
        calories = convertStepsToCalories(steps)
        setCalories(Math.floor(calories))
        let stepsDurationInSeconds = convertStepsToSeconds(steps)
        console.log("Steps Duration in mins: ", stepsDurationInSeconds / 60)
        setPointsObject(calculateAttributeRateFromManualWorkoutObj(props.selectedActivity.activityName, stepsDurationInSeconds, intensityMultiplier))
    }, [steps])

    const updateDate = (info: any) => {
        setDate(info)
        // setShowDatePicker(false)
    }

    const logActivity = async () => {
        if (typeof date === 'string') {
            setAdding(true)
            let intensity = "low";
            let stepsDurationInSeconds = convertStepsToSeconds(steps)
            let workoutObject = await convertManualActivityToWorkoutObject(date, stepsDurationInSeconds, props.selectedActivity.activityName, intensity, calories)
            log.debug("[logActivity] Workout Object: ", workoutObject)
            let workoutArray: WorkoutObject[] = [workoutObject];
            if (workoutArray) {
                let hero = await getHero()
                workoutArray.forEach((workout) => {
                    sendSlackNotification(`*Manual Activity Logged*\n*Hero Name*: ${hero.heroName}\n*Activity*: ${workout.activityType}\n*Duration*: ${formatTime(workout.value, true)}`, "activity", true)
                })
                await postWorkoutsToServer(workoutArray, false);
                logGenericEventWithObject("manual activity logged", workoutObject);
                // log.debug("[logActivity] Server Response: ", serverResponse)
                let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(true);
                log.debug(`[logActivity] RecentWorkouts: `, recentWorkouts);
                if (recentWorkouts.length > 0) {
                    let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
                    log.debug("[logActivity] Activity Ledger: ", activityLedger)
                    await updateHeroHealth(activityLedger, recentWorkouts, moment(), true);
                    await storageService.setItem(lastUpdatedWorkoutKey, moment().format());
                    await storageService.setItem(lastUpdatedStepsKey, moment().format());
                    log.debug("Points Object: ", pointsObject)
                    log.debug("Activity Ledger: ", activityLedger)
                    log.debug("Workout Array: ", workoutArray)
                }
            }
            setAdding(false);
            props.closeModal(true);
        } else {
            log.debug("Error: Date is not a string")
        }

    }

    return (
        <>
            <IonLoading isOpen={adding} message={'Logging activity...'} backdropDismiss />
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{props.selectedActivity.activityName}</IonTitle>
                    <IonButton color="light" slot="start" onClick={previousSlide}>
                        <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                    </IonButton>
                    <IonButton color="light" slot="end" onClick={props.closeModal}>
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonModal id='set-date-modal' isOpen={showDatePicker} onDidDismiss={() => setShowDatePicker(false)}>
                <IonDatetime presentation="time-date" min={`${userState.user?.createdAt}`} value={moment(date).format()} max={moment().format()} onIonChange={(e) => updateDate(e.detail.value)} />
                <IonButton onClick={() => setShowDatePicker(false)}>Done</IonButton>
            </IonModal>
            {/* {showDatePicker === true && (
                <div>
                    <IonDatetime presentation="time-date" min={`${userState.user?.createdAt}`} value={moment(date).format()} max={moment().format()} onIonChange={(e) => updateDate(e.detail.value)} />
                </div>
            )} */}
            <div className='add-activity-large'>
                <Emoji text={props.selectedActivity.icon} className='add-activity-icon-large' />
            </div>


            <IonItem onClick={() => setShowDatePicker(true)}>
                <div>Date</div>
                <div className='data-screen-data'>{moment(date).format('dddd, MMMM D')}</div>
            </IonItem>
            {/* 
            {isMobile ? (
                <>
                    <IonItem id='open-picker'>
                        <div>Number of Steps</div>
                        <div className='data-screen-data'>{formatTime(duration, true)}</div>
                    </IonItem>
                    <DurationPicker trigger='open-picker' setDuration={setDuration} />
                </>
            ) : (<> */}
            <IonItem>
                <IonLabel>Number of Steps</IonLabel>
                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: "auto" }}>
                    <IonInput
                        type="number"
                        min={0}
                        value={steps}
                        onIonChange={(e) => setSteps(Number(e.detail.value!))}
                        className='duration-input'
                        style={{ width: "150px" }}
                    />
                </div>
            </IonItem>
            {/* </>)} */}

            <IonItem>
                <div>Approx. Calories</div>
                <div className='data-screen-data'>{calories}</div>
            </IonItem>
            <IonItem>
                <div>Points</div>
                <div className='data-screen-data'>
                    <IonCol size="3">
                        {strengthIcon} {pointsObject.strengthPoints || 0}
                    </IonCol>
                    <IonCol size="3">
                        {cardioIcon} {pointsObject.cardioPoints || 0}
                    </IonCol>
                    <IonCol size="3">
                        {mobilityIcon} {pointsObject.mobilityPoints || 0}
                    </IonCol>
                    <IonCol size="3">
                        {mindfulnessIcon} {pointsObject.mindfulnessPoints || 0}
                    </IonCol>
                </div>
            </IonItem>
            <div style={{ height: "60px" }}>
                {steps > 0 && (
                    <IonButton expand="block" onClick={() => logActivity()}>Log activity</IonButton>
                )}
            </div>
        </>
    )
}

const DataScreen = (props: { closeModal: any, selectedActivity: ActivitySelection, swiper: SwiperClass | undefined }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const {
        updateHeroHealth,
        isMobile,
        userState
    } = globalState;
    const previousSlide = () => {
        props.swiper?.slidePrev()
    }

    const [date, setDate] = useState<string | undefined | null | string[]>(moment().format())
    const [showDatePicker, setShowDatePicker] = useState<boolean>(false)
    const [duration, setDuration] = useState<number>(0)
    const [adding, setAdding] = useState<boolean>(false)
    const [calories, setCalories] = useState<number>(0)
    const [pointsObject, setPointsObject] = useState<ExercisePoints>({ strengthPoints: 0, cardioPoints: 0, mobilityPoints: 0, mindfulnessPoints: 0 })

    useEffect(() => {
        let calories = 0;
        let weightInKG = 73;
        let intensityMultiplier = 1
        calories = ((props.selectedActivity.metValue * 3.5 * weightInKG / 200) * intensityMultiplier) * (duration / 60);
        setCalories(Math.floor(calories))
        setPointsObject(calculateAttributeRateFromManualWorkoutObj(props.selectedActivity.activityName, duration, intensityMultiplier))
    }, [duration, props.selectedActivity])

    const hours = Math.floor(duration / 3600);
    const minutes = Math.floor((duration % 3600) / 60);

    const adjustTime = (unit: string, change: number) => {
        // log.debug("Duration: ", duration)
        if (unit === "hours") {
            setDuration(duration + change * 3600);
        } else if (unit === "minutes") {
            setDuration(duration + change * 60);
        }
    };

    const updateDate = (info: any) => {
        setDate(info)
        // setShowDatePicker(false)
    }
    // This has to live in a component because it updates the state
    const logActivity = async () => {
        if (typeof date === 'string') {
            setAdding(true)
            let intensity = "low";
            let workoutObject = await convertManualActivityToWorkoutObject(date, duration, props.selectedActivity.activityName, intensity, calories)
            // log.debug("[logActivity] Workout Object: ", workoutObject)
            let workoutArray: WorkoutObject[] = []
            if (workoutObject) {
                workoutArray.push(workoutObject);
            }
            log.debug("[logActivity] Workout Array: ", workoutArray)
            if (workoutArray) {
                let hero = await getHero()
                workoutArray.forEach((workout) => {
                    sendSlackNotification(`*Manual Activity Logged*\n*Hero Name*: ${hero.heroName}\n*Activity*: ${workout.activityType}\n*Duration*: ${formatTime(workout.value, true)}`, "activity", true)
                })
                await postWorkoutsToServer(workoutArray, false);
                logGenericEventWithObject("manual activity logged", workoutObject);
                // log.debug("[logActivity] Server Response: ", serverResponse)
                let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(true);
                log.debug(`[logActivity] RecentWorkouts: `, recentWorkouts);
                // filter out any duplicate workouts based on UUID
                recentWorkouts = recentWorkouts.filter((workout, index, self) =>
                    index === self.findIndex((t) => (
                        t.UUID === workout.UUID
                    ))
                )
                if (recentWorkouts.length > 0) {
                    let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
                    log.debug("[logActivity] Activity Ledger: ", activityLedger)
                    await updateHeroHealth(activityLedger, recentWorkouts, moment(), true);
                    await storageService.setItem(lastUpdatedWorkoutKey, moment().format());
                    await storageService.setItem(lastUpdatedStepsKey, moment().format());
                    log.debug("Points Object: ", pointsObject)
                    log.debug("Activity Ledger: ", activityLedger)
                    log.debug("Workout Array: ", workoutArray)
                }
            }
            setAdding(false);
            props.closeModal(true);
        } else {
            log.debug("Error: Date is not a string")
        }
    }

    return (
        <>
            <IonLoading isOpen={adding} message={'Logging activity...'} backdropDismiss />
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{props.selectedActivity.activityName}</IonTitle>
                    <IonButton color="light" slot="start" onClick={previousSlide}>
                        <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
                    </IonButton>
                    <IonButton color="light" slot="end" onClick={props.closeModal}>
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <IonModal id='set-date-modal' isOpen={showDatePicker} onDidDismiss={() => setShowDatePicker(false)}>
                <IonDatetime presentation="time-date" min={`${userState.user?.createdAt}`} value={moment(date).format()} max={moment().format()} onIonChange={(e) => updateDate(e.detail.value)} />
                <IonButton onClick={() => setShowDatePicker(false)}>Done</IonButton>
            </IonModal>
            {/* {showDatePicker === true && (
                <div>
                    <IonDatetime presentation="time-date" min={`${userState.user?.createdAt}`} value={moment(date).format()} max={moment().format()} onIonChange={(e) => updateDate(e.detail.value)} />
                </div>
            )} */}
            <div className='add-activity-large'>
                <Emoji text={props.selectedActivity.icon} className='add-activity-icon-large' />
            </div>

            <IonItem onClick={() => setShowDatePicker(true)}>
                <div>Date</div>
                <div className='data-screen-data'>{moment(date).format('dddd, MMMM D h:mm a')}</div>
            </IonItem>

            {isMobile ? (
                <>
                    <IonItem id='open-picker'>
                        <div>Duration</div>
                        <div className='data-screen-data'>{formatTime(duration, true)}</div>
                    </IonItem>
                    <DurationPicker trigger='open-picker' setDuration={setDuration} />
                </>
            ) : (<>
                <IonItem>
                    <IonLabel>Duration</IonLabel>
                    <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginLeft: "auto" }}>
                        <IonButton onClick={() => adjustTime("hours", -1)}>-</IonButton>
                        <IonInput
                            type="number"
                            min={0}
                            value={hours}
                            onIonChange={(e) => setDuration(Number(e.detail.value!) * 3600 + minutes * 60)}
                            className='duration-input'
                        />
                        <IonLabel className='duration-label'>hours</IonLabel>
                        <IonButton onClick={() => adjustTime("hours", 1)}>+</IonButton>
                        <IonButton style={{ marginLeft: "50px" }} onClick={() => adjustTime("minutes", -1)}>-</IonButton>
                        <IonInput
                            type="number"
                            min={0}
                            max={59}
                            value={minutes}
                            onIonChange={(e) => setDuration(hours * 3600 + Number(e.detail.value!) * 60)}
                            className='duration-input'
                        />
                        <IonLabel className='duration-label'>minutes</IonLabel>
                        <IonButton onClick={() => adjustTime("minutes", 1)}>+</IonButton>

                    </div>
                </IonItem>
            </>)}

            <IonItem>
                <div>Approx. Calories</div>
                <div className='data-screen-data'>{calories}</div>
            </IonItem>
            <IonItem>
                <div>Points</div>
                <div className='data-screen-data'>
                    <IonCol size="3">
                        {strengthIcon} {pointsObject.strengthPoints || 0}
                    </IonCol>
                    <IonCol size="3">
                        {cardioIcon} {pointsObject.cardioPoints || 0}
                    </IonCol>
                    <IonCol size="3">
                        {mobilityIcon} {pointsObject.mobilityPoints || 0}
                    </IonCol>
                    <IonCol size="3">
                        {mindfulnessIcon} {pointsObject.mindfulnessPoints || 0}
                    </IonCol>
                </div>
            </IonItem>
            <div style={{ height: "60px" }}>
                {duration > 0 && (
                    <IonButton expand="block" onClick={() => logActivity()}>Log activity</IonButton>
                )}
            </div>
        </>
    )
}



const SelectionScreen = (props: { closeModal: any, swiper: SwiperClass | undefined, setSelectedActivity: React.Dispatch<React.SetStateAction<ActivitySelection>> }) => {
    const click = (activityName: ActivitySelection) => {
        props.swiper?.slideNext()
        props.setSelectedActivity(activityName)
    }

    const [showAll, setShowAll] = useState<boolean>(true)
    const [showPopular, setShowPopular] = useState<boolean>(true)

    let [results, setResults] = useState([...allActivities]);

    const handleInput = (ev: Event) => {
        let query = '';
        const target = ev.target as HTMLIonSearchbarElement;
        if (target && target.value !== "") {
            setShowAll(false);
            setShowPopular(false);
            query = target.value!.toLowerCase();
        } else {
            setShowPopular(true);
        }
        setResults(allActivities.filter((d) => d.activityName.toLowerCase().indexOf(query) > -1));
    };


    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Log Activity</IonTitle>
                    <IonButton color="light" slot="end" onClick={props.closeModal}>
                        <IonIcon slot="icon-only" icon={closeOutline}></IonIcon>
                    </IonButton>
                </IonToolbar>
            </IonHeader>
            <div>
                <IonSearchbar onIonInput={(ev) => handleInput(ev)}></IonSearchbar>

                {showPopular === true ? (
                    <><div className='section-divider'>Most Popular</div>
                        {mostPopularActivities.map((activity, index) => {
                            return (
                                <IonItem key={index} onClick={() => click(activity)} button={true}>
                                    <Emoji text={activity.icon} className='add-activity-icon' />{activity.activityName}
                                </IonItem>
                            )
                        })}
                        {showAll === false ? (
                            <div className='section-divider' onClick={() => setShowAll(true)}>Show all activities</div>

                        ) : (
                            <>
                                <div className='section-divider'>All Activities (alphabetical)</div>
                                {allActivities.map((activity, index) => {
                                    return (
                                        <IonItem key={index} onClick={() => click(activity)} button={true}>
                                            <Emoji text={activity.icon} className='add-activity-icon' />{activity.activityName}
                                        </IonItem>
                                    )
                                })}
                            </>
                        )}
                    </>
                ) : (
                    <>
                        <div className='section-divider'>Search Results</div>
                        <IonList>
                            {results.map((activity, index) => (
                                <IonItem key={index} onClick={() => click(activity)}>
                                    <Emoji text={activity.icon} className='add-activity-icon' />{activity.activityName}
                                </IonItem>
                            ))}
                        </IonList>
                    </>

                )}
            </div>
            <TourComponent coachMarkName="logActivity1" steps={LogActivity1Tour} modalShowing={false} />
        </>
    )
}

const DurationPicker = (props: { trigger: string, setDuration: React.Dispatch<React.SetStateAction<number>> }) => {
    return (
        <IonPicker
            style={{ top: "-200px", position: "relative" }}
            trigger={props.trigger}
            columns={[
                {
                    name: 'hours',
                    options: [
                        {
                            text: '0 hours',
                            value: '0',
                        }, {
                            text: '1 hour',
                            value: '3600',
                        },
                        {
                            text: '2 hours',
                            value: '7200',
                        },
                        {
                            text: '3 hours',
                            value: '10800',
                        },
                        {
                            text: '4 hours',
                            value: '14400',
                        },
                        {
                            text: '5 hours',
                            value: '18000',
                        },
                        {
                            text: '6 hours',
                            value: '21600',
                        },
                        {
                            text: '7 hours',
                            value: '25200',
                        },
                        {
                            text: '8 hours',
                            value: '28800',
                        },
                    ],
                },
                {
                    name: 'minutes',
                    options: [
                        ...Array.from({ length: 12 }, (v, k) => ({
                            text: `${k * 5} min${k === 0 || k > 1 ? 's' : ''}`,
                            value: `${k * 5 * 60}`,
                        }))
                    ]
                },
            ]}
            buttons={[
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
                {
                    text: 'Confirm',
                    handler: (value: any) => {
                        props.setDuration(parseInt(value.hours.value) + parseInt(value.minutes.value))

                    },
                },
            ]}
        />
    )
}
