/* eslint-disable react-hooks/exhaustive-deps */
import { IonAlert, IonAvatar, IonButton, IonButtons, IonFooter, IonHeader, IonIcon, IonLoading, IonPage, IonToolbar } from "@ionic/react";
import "../css/App.css";
import "../css/HeroChat.css";

import { refreshCircleOutline, skull } from "ionicons/icons";
import log from "loglevel";
import { useEffect, useState } from "react";
import HeroChatComponent from "../Features/Sidekick/components/ChatRenderComponent";
import ChatInput from "../Features/Sidekick/components/SideKickChatInput";
import { SidekickMessage } from "../models/messageModels";
import { SidekickDBObject, SidekickObject, assistantDBObjectKey, assistantDisplayMessagesKey, assistantThreadObjectKey } from "../models/sideKickModels";
import { SidekickMessagesResponse } from "../services/SideKickService";
import { disableAssistant, getAssistant, getAssistantMessages, getAssistantMessagesFromDB, initiateAssistant } from "../services/AssistantService";

import { StorageService } from "../services/StorageService";
import AssistantChatRenderer from "./AssistantChatRenderComponent";
import AssistantChatInput from "./assistantChatInput";
const storageService = new StorageService();

const Assistant = () => {
    const [messagesForDisplay, setMessagesForDisplay] = useState<SidekickMessage[]>([]);
    const [activeAssistant, setActiveAssistant] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(false);
    const [scroller, setScroller] = useState<boolean>(true); // used to simply trigger a rerender without more info
    const [showClearChatConfirmation, setShowClearChatConfirmation] = useState<boolean>(false);
    const [localAssistant, setLocalAssistant] = useState<SidekickDBObject | undefined>();
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [awakeningAssistant, setAwakeningAssistant] = useState<boolean>(false);

    const assistantThreadId = 'thread_27XXmetxee7h6t8QlgmPTLnC';

    useEffect(() => {
        log.debug("Assistant useEffect Triggered");
        loadAssistant();
    }, []);

    async function loadAssistant() {
        let assistantDBObject = await storageService.getObject(assistantDBObjectKey);
        if (assistantDBObject) {
            setLocalAssistant(assistantDBObject);
            setActiveAssistant(true);
            getMessages(assistantDBObject.sideKickThreadId);
        } else {
            setActiveAssistant(false);
        }
    }

    async function getMessages(threadId: string) {
        setRefreshing(true);
        await getAssistantMessages(threadId).then((messages) => {
            setMessagesForDisplay(messages);
            log.debug("[getMessages] messages: ", messages);
        }).catch((error) => {
            log.error(error);
            alert("Error: " + error);
        });
        setRefreshing(false);
    }

    async function clearChatHistory() {
        setRefreshing(true);
        setMessagesForDisplay([]);
        await storageService.removeItem(assistantDisplayMessagesKey);
        let sk = await getAssistant();
        if (!sk || !sk.sideKickThreadId || sk.sideKickThreadId === "") {
            setRefreshing(false);
            initiateAssistant()
            return
        }
        getAssistantMessagesFromDB(sk.sideKickThreadId).then((result: SidekickMessagesResponse) => {
            setMessagesForDisplay(result.messages);
            setRefreshing(false);
        });
    }

    async function reawaken(confirmed: boolean) {
        if (confirmed === false) {
            setShowClearChatConfirmation(true);
        } else {
            setShowClearChatConfirmation(false);
            await storageService.removeItem(assistantDisplayMessagesKey);
            await storageService.removeItem(assistantThreadObjectKey);
            await disableAssistant();
            setMessagesForDisplay([]);
            setActiveAssistant(false);
        }
    }

    async function initAssistant() {
        try {
            setAwakeningAssistant(true);
            await disableAssistant();
            await storageService.removeItem(assistantDisplayMessagesKey);
            await storageService.removeItem(assistantThreadObjectKey);
            await storageService.removeItem(assistantDBObjectKey);
            let assistantObject: SidekickObject = await initiateAssistant()
            log.debug("welcomeMessage", assistantObject.message);
            let welcomeMessageArray: SidekickMessage[] = [];
            welcomeMessageArray.push(assistantObject.message);
            let assistantDBObject: SidekickDBObject = await storageService.getObject(assistantDBObjectKey);
            setLocalAssistant(assistantDBObject)
            setMessagesForDisplay(welcomeMessageArray);
            setActiveAssistant(true);
            setAwakeningAssistant(false);
        } catch (error) {
            log.error(error);
            alert("Error: " + error);
        }
    }

    return (
        <IonPage>{activeAssistant === false ? (
            <>
                <IonLoading isOpen={awakeningAssistant} message={"Starting up your Assistant..."} backdropDismiss />

                <div className="awaken-sidekick-div">
                    <IonButton className="sidekick-throbbing-button" style={{ color: "white" }} size="large" expand="block" onClick={() => initAssistant()}>Startup Assistant</IonButton>
                </div>
            </>

        ) : (
            <>
                <IonHeader>
                    <IonToolbar className="background">
                        <IonButtons slot="start" className="toolbar-left-buttons">
                            <IonButton onClick={() => clearChatHistory()}>
                                <IonIcon style={{ color: "white" }} icon={refreshCircleOutline} />
                            </IonButton>
                        </IonButtons>
                        <div className="toolbar-avatar-holder">
                            <IonAvatar className="member-image" style={{ margin: "auto" }}>
                                <img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={localAssistant?.sideKickImage ? `${localAssistant?.sideKickImage}` : `https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/01cd3e33aa221ca06e8af8166aa73421_avatar.webp`} alt="" />
                            </IonAvatar>
                            {localAssistant?.sideKickName}
                        </div>
                        <IonButtons slot="end" className="toolbar-right-buttons">
                            <IonButton onClick={() => reawaken(false)}>
                                <IonIcon style={{ color: "white" }} icon={skull} />
                            </IonButton>
                        </IonButtons>

                    </IonToolbar>
                </IonHeader>
                <IonAlert isOpen={showClearChatConfirmation} onDidDismiss={() => setShowClearChatConfirmation(false)} header={"Refresh your assistant?"} message={"Are you sure you want to refresh your Assistant? This will start things over with them."} buttons={[{ text: "Cancel", role: "cancel" }, { text: "Re-awaken", handler: (data) => reawaken(true) }]} />
                <IonLoading isOpen={refreshing} message={"Refreshing messages..."} backdropDismiss />
                {localAssistant && (
                    <AssistantChatRenderer
                        scroller={scroller}
                        setScroller={setScroller}
                        messages={messagesForDisplay}
                        setMessages={setMessagesForDisplay}
                        processing={processing}
                        setProcessing={setProcessing}
                        assistant={localAssistant}
                    />
                )}

                <IonFooter>
                    <AssistantChatInput setMessages={setMessagesForDisplay} messages={messagesForDisplay} setProcessing={setProcessing} setScroller={setScroller} />
                </IonFooter>
            </>
        )}
        </IonPage>
    );
};

export default Assistant;
