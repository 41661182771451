/* eslint-disable react-hooks/exhaustive-deps */
import { IonButton, IonContent, IonFooter, IonLoading, IonModal, IonPage, IonRow, IonSpinner } from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { DuelInstructions } from '../../Features/Duels/components/DuelInstructions';
import HeaderLogo from '../../components/HeaderLogo';
import GlobalState from '../../contexts/GlobalState';
import { OnboardingObject } from '../../models/userModel';
import { Villain } from '../../models/villainModels';
import { createVillainGroupInstance, getDuelStatusWithInstanceId, getVillainGroupInstanceByVillainGroupId, startDuel } from '../../services/DuelsServics';
import { getLastDuelMessage } from '../../services/MessageService';
import { sendSlackErrorNotification } from '../../services/NotificationService';
import { StorageService } from '../../services/StorageService';
import { getHero } from '../../services/UserService';
import { getVillain, scaleVillainToLevelOne } from '../../services/VillainServices';
import log from 'loglevel';

const StartFirstDuel = () => {
    const nextPage = "/onboarding/congratsonfirstduel";
    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, setHeroObject, isMobile } = globalState;
    const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
    const [firstVillain, setFirstVillain] = useState<Villain>();
    const [showDuelInstructions, setShowDuelInstructions] = useState<boolean>(false);
    const [startingDuel, setStartingDuel] = useState<boolean>(false);
    const [chatText, setChatText] = useState<string>();
    const [duelStarted, setDuelStarted] = useState<boolean>(false);
    const [loadingVillain, setLoadingVillain] = useState<boolean>(false);

    const firstVillainId = "64d7296e7c4cbc32371eb0ed" // Tom 
    const firstVillainGroupId = "62a653c9-7daf-4aba-b4e1-235292bd0df1"

    useEffect(() => {
        updateOnboardingObject();
        getFirstVillain();
    }, []);

    async function updateOnboardingObject() {
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        if (!heroObject && tempOnboardingObject.hero) {
            setHeroObject(tempOnboardingObject.hero);
        } else if (!heroObject) {
            let hero = await getHero();
            setHeroObject(hero);
            tempOnboardingObject.hero = hero;
        }
        console.log("temp onboarding object", tempOnboardingObject)
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        setOnboardingObject(tempOnboardingObject);
        await storageService.setObject("onboarding", tempOnboardingObject);
    }

    async function getFirstVillain() {
        setLoadingVillain(true)
        // let villain = await scaleVillainToLevelOne(await getVillain(firstVillainId))
        let villain = await getVillain(firstVillainId)
        log.debug("first villain", villain);
        setFirstVillain(villain)
        setLoadingVillain(false)
        return villain
    }

    async function getChatText(duelId: string) {
        try {
            const text = await getLastDuelMessage(duelId); // Assuming fetchChatText is a function that fetches the text
            log.debug("chat text", text)
            setChatText(text);
        } catch (error) {
            console.error('Failed to fetch chat text:', error);
            // Handle error state appropriately, perhaps setting a default message
            setChatText('...');
        }
    };

    async function startFirstDuel() {
        setStartingDuel(true)
        // confirm duel does not already exist
        let firstInstanceId
        let groupInstance
        let existingDuel
        // Does an instance already exist?
        await getVillainGroupInstanceByVillainGroupId(firstVillainGroupId).then((groupInstance) => {
            console.log("[startFirstDuel] groupInstance: ", groupInstance)
            if (groupInstance) {
                console.log("groupInstance exists")
                firstInstanceId = groupInstance.villainGroupInstanceId
            }
        }).catch((err) => {
            console.log("Villain group instance does not exist")
        })

        // If not, create one
        if (!firstInstanceId) {
            await createVillainGroupInstance(firstVillainGroupId).catch((err) => {
                sendSlackErrorNotification("Error creating villain group instance", err, `OnboardingObject: ${JSON.stringify(onboardingObject, null, 2)}`)
                if (err.message === "Villain group instance already exists") {
                    console.log("Villain group instance already exists")
                    console.log("err", JSON.stringify(err))
                } else {
                    console.log(JSON.stringify(err, null, 2))
                    setStartingDuel(false)
                    alert("There was an error starting your duel. Please try again.")
                    throw new Error("Error creating villain group instance")
                }
            })
        } else {
            // if an instance already exists, check if there is a duel in progress
            console.log("firstInstanceId", firstInstanceId)
            existingDuel = await getDuelStatusWithInstanceId(firstInstanceId, firstVillain!.villainID)
            console.log("existingDuel", existingDuel)
        }

        // If there is no duel in progress, start one
        if (!existingDuel) {
            await getVillainGroupInstanceByVillainGroupId(firstVillainGroupId).then((groupInstance) => {
                console.log("[startFirstDuel] groupInstance: ", groupInstance)
                if (groupInstance) {
                    console.log("groupInstance exists")
                    firstInstanceId = groupInstance.villainGroupInstanceId
                }
            }).catch((err) => {
                console.log("Villain group instance does not exist")
            })
            await startDuel(firstVillain!, "easy", firstInstanceId).then(async (duelStatus) => {
                console.log("duelStatus", duelStatus)
                await getChatText(duelStatus!.statusId)
                setDuelStarted(true)
                setStartingDuel(false)
            }).catch((err) => {
                sendSlackErrorNotification("Error starting duel", err, `OnboardingObject: ${JSON.stringify(onboardingObject, null, 2)}`)
                console.log(err)
                setStartingDuel(false)
                alert("There was an error starting your duel. Please try again.")
                throw new Error("Error starting duel")
            })
        } else {
            await getChatText(existingDuel!.statusId)
            setDuelStarted(true)
            setStartingDuel(false)
        }
    };

    return (
        <IonPage>
            <HeaderLogo />

            <IonContent>
                <IonLoading isOpen={startingDuel} message={'Starting duel...'} backdropDismiss />
                <IonLoading isOpen={loadingVillain} message={'Loading first villain...'} backdropDismiss />

                <IonModal
                    isOpen={showDuelInstructions}
                    onWillDismiss={() => setShowDuelInstructions(false)}
                    style={isMobile ? {} : { "--height": "90%" }}
                >
                    <DuelInstructions setShowModal={setShowDuelInstructions} />
                </IonModal>
                {firstVillain && onboardingObject && (
                    <>
                        <div className="onboarding-main-div">
                            <div className="onboarding-title">Start Your First Duel</div>
                            <div className='onboarding-text-area' style={{ textAlign: "center", marginTop: "0px", marginBottom: "20px" }}>
                                Your first duel will be against <br /><b>{firstVillain!.name}</b>.
                            </div>
                            <div>
                                <div className="villian-profile-image">
                                    <div className="villain-chat-container">
                                        {chatText && (
                                            <IonRow style={{ justifyContent: "center" }}>
                                                <div className="chat-bubble">
                                                    {chatText}
                                                </div>
                                            </IonRow>
                                        )}
                                        {firstVillain ? (
                                            <IonRow style={{ justifyContent: "center" }}><img src={firstVillain!.imageURL} alt="Villain" className="villain-avatar-rounded" /></IonRow>

                                        ) : (
                                            <IonRow style={{ justifyContent: "center" }}><IonSpinner /></IonRow>

                                        )}
                                    </div>

                                </div>
                            </div>
                            {!duelStarted && (
                                <div className='onboarding-text-area' >
                                    <VillainDescription villain={firstVillain!} />
                                </div>
                            )}
                        </div>
                    </>
                )}
            </IonContent>
            <IonFooter>
                {duelStarted ? (
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <IonButton color={'success'} className="onboarding-advance-button" routerLink={nextPage}>
                            Oh it's on!
                        </IonButton>
                    </div>
                ) : (
                    <div style={{ textAlign: "center", marginTop: "30px" }}>
                        <IonButton color={'tertiary'} className="onboarding-advance-button" onClick={() => startFirstDuel()}>
                            Let's do it!
                        </IonButton>
                        <IonButton color={'secondary'} className="onboarding-advance-button" onClick={() => setShowDuelInstructions(true)}>
                            How do duels work?
                        </IonButton>
                    </div>
                )}

            </IonFooter>
        </IonPage>
    )
}

export default StartFirstDuel

const VillainDescription = (props: { villain: Villain }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    if (!props.villain?.description) {
        return <></>;
    }
    const descriptionLines = props.villain?.description.split("\\n");
    const displayLines = isExpanded ? descriptionLines : [descriptionLines[0]];

    return (
        <div className="villain-profile-description">
            {displayLines.map((line, index) => (
                <React.Fragment key={index}>
                    {line}
                    <br />
                </React.Fragment>
            ))}
            {descriptionLines.length > 1 &&
                (!isExpanded ? (
                    <div className="read-more" onClick={() => setIsExpanded(true)}>
                        Read More{" "}
                    </div>
                ) : (
                    <div className="read-more" onClick={() => setIsExpanded(false)}>
                        Read Less 🔼
                    </div>
                ))}
        </div>
    );
};