import { IonAvatar } from '@ionic/react'
import React, { useContext, useEffect } from 'react'
import { HeroObject } from '../models/userModel'
import { Purchases } from '@revenuecat/purchases-capacitor'
import GlobalState from '../contexts/GlobalState'

export const UserAvatar = (props: { showBorder?: boolean, avatarImage?: string }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { currentlySubscribed } = globalState;

    return (
        <>
            {currentlySubscribed === true || props.showBorder === true ? (
                <>
                    <div>
                        <img className="avatar-powerup-border" src={props.avatarImage ?? "/assets/images/mystery-avatar.png"} alt="" />
                        <div className='avatar-powerup-badge'>
                            ⚡️
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <img className="paywall-avatar" src={props.avatarImage ?? "/assets/images/mystery-avatar.png"} alt="" />
                </>
            )}
        </>
    )
}
