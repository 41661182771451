import { IonAlert, IonAvatar, IonButton, IonCheckbox, IonCol, IonContent, IonFooter, IonLabel, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, SelectCustomEvent } from '@ionic/react';
import axios from "axios";
import Parse from 'parse';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import HeaderLogo from '../components/HeaderLogo';
import GlobalState from '../contexts/GlobalState';
import "../css/Onboarding.css";
import { GPT_API_CHAT_COMPLETIONS_ENDPOINT } from "../models/endPoints";
import { openAIHeaders } from "../models/messageModels";
import { GPTSystemPrompt, OnboardingObject, UserObject, generalNegativeAvatarPrompt } from '../models/userModel';
import { sendSlackErrorNotification, sendSlackNotification } from '../services/NotificationService';
import { StorageService } from '../services/StorageService';
import { ImageAnalysisResponse, analyzeImage, getUser } from '../services/UserService';
import InitImageUpload from '../components/UploadImage';
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { generateAvatarFromImage } from '../services/UserService';
import { ageOptions, backgroundOptions, convertPhotoToBlob, ethnicityOptions, eyeColorOptions, facialExpressionOptions, facialHairOptions, genderOptions, hairColorOptions, hairstyleOptions } from '../services/AvatarService';
import log from 'loglevel';

const TestLeonardo = () => {
    interface SavingPhotoObject {
        visible: boolean;
        message: string;
    }

    interface SelectedImage {
        url: string;
        index: number;
    }

    interface APIResponse {
        base64: string;
        finishReason: string;
        seed: number;
    }

    type GeneratedImage = {
        url: string,
        nsfw: boolean,
        id: string,
        likeCount: number,
        generated_image_variation_generics: any[]
    };

    const cfg_scale = 7;
    const creative = "6bef9f1b-29cb-40c7-b9df-32b51c1f67d3"
    const select = "cd2b2a15-9760-4174-a5ff-4d2925057376"
    const signature = "291be633-cb24-434f-898f-e662799936ad"
    const dreamshaper7 = "ac614f96-1082-45bf-be9d-757f2d31c174"
    const AlbedoBaseXL = "2067ae52-33fd-4a82-bb92-c2c55e7d2786"
    const RPGv5 = "f1929ea3-b169-4c18-a16c-5d58b4292c69"

    const model = AlbedoBaseXL
    const url = "https://cloud.leonardo.ai/api/rest/v1/generations";
    const API_KEY = "602b2675-8efe-43c7-81f2-0e847aba3b6a";
    const PROD_API_KEY = "14d52df6-efe3-43d5-bde1-cc1adbcfdf35"
    const HEADERS = {
        accept: "application/json",
        "content-type": "application/json",
        authorization: `Bearer ${PROD_API_KEY}`,
    };

    const [savingPhoto, setSavingPhoto] = useState<SavingPhotoObject>({
        visible: false,
        message: "",
    });

    const [avatar, setAvatar] = useState<any>();
    const [dramatic, setDramatic] = useState<boolean>(false);
    const [imageAnalyzed, setImageAnalyzed] = useState<boolean>(false);
    const [generatingImages, setGeneratingImages] = useState<boolean>(false);
    const [images, setImages] = useState<string[]>([])
    const [imageSelected, setImageSelected] = useState<SelectedImage>({
        url: "",
        index: -1
    });

    const [formValues, setFormValues] = useState<ImageAnalysisResponse>({
        eyeColor: "",
        ethnicity: "",
        hairstyle: "",
        hairColor: "",
        age: "",
        gender: "",
        background: "",
        facialExpression: "",
        facialHair: "",
    });
    const [imageURL, setImageURL] = useState<string>('');
    const [takenPhoto, setTakenPhoto] = useState<any>();
    const [photoError, setPhotoError] = useState({
        visible: false,
        message: "",
    });

    type CheckboxValues = {
        FIRE: boolean;
        MAGIC: boolean;
        STEEL: boolean;
        FANTASY: boolean;
        SCIFI: boolean;
        EGYPTIAN: boolean;
        PIRATE: boolean;
        CYBORG: boolean;
    };

    // State to manage checkbox values
    const [checkboxValues, setCheckboxValues] = useState<CheckboxValues>({
        FIRE: false,
        MAGIC: false,
        STEEL: false,
        FANTASY: false,
        SCIFI: false,
        EGYPTIAN: false,
        PIRATE: false,
        CYBORG: false
    });


    // Randomize values if "Random" is selected
    useEffect(() => {
        ['gender', 'hairstyle', 'ethnicity', 'hairColor', 'eyeColor', 'age', 'background', 'facialExpression', 'facialHair', 'perspective'].forEach(field => {
            if ((formValues as { [key: string]: any })[field] === 'Random') {
                const randomValue = getRandomOption(eval(`${field}Options`));  // Note: eval is generally discouraged due to security reasons but it is safe in this case as it's being used with known variable names.
                setFormValues(prevValues => ({ ...prevValues, [field]: randomValue }));
            }
        });
    }, [formValues]);

    function getRandomItem(arr: Array<string>): string {
        const randomIndex = Math.floor(Math.random() * arr.length);
        return arr[randomIndex];
    }

    function getRandomOption(options: any) {
        const randomIndex = Math.floor(Math.random() * (options.length - 1));  // Exclude the "Random" option
        return options[randomIndex];
    }

    const randomizeValues = () => {
        let randomizedValues = {
            eyeColor: getRandomItem(eyeColorOptions),
            ethnicity: getRandomItem(ethnicityOptions),
            hairstyle: getRandomItem(hairstyleOptions),
            hairColor: getRandomItem(hairColorOptions),
            age: getRandomItem(ageOptions),
            gender: getRandomItem(genderOptions),
            background: getRandomItem(backgroundOptions),
            facialExpression: getRandomItem(facialExpressionOptions),
            facialHair: getRandomItem(facialHairOptions),
        }
        if (randomizedValues.gender === 'Female') (
            randomizedValues.facialHair = 'None'
        )
        if (randomizedValues.hairstyle === 'Bald') (
            randomizedValues.hairColor = 'None'
        )
        setFormValues(randomizedValues);
    };


    const generateImage = async (positivePrompt: string, model: string) => {
        const payload = {
            "prompt": positivePrompt,
            "negative_prompt": generalNegativeAvatarPrompt,
            "modelId": model,
            "sd_version": "v2",
            "num_images": 4,
            "width": 512,
            "height": 512,
            "num_inference_steps": null,
            "guidance_scale": cfg_scale,
            "init_generation_image_id": null,
            "init_image_id": null,
            "init_strength": null,
            "scheduler": "EULER_DISCRETE",
            "presetStyle": "LEONARDO",
            "tiling": false,
            "public": false,
            "promptMagic": true,
            "controlNet": null,
            "controlNetType": null,
            "alchemy": true,
            // "photoReal": true,
        }
        const response = await axios.post(url, payload, { headers: HEADERS }).catch((error) => {
            throw error
        });
        if (response) {
            const data = response.data;
            log.debug("[generateImage]: ", data);
            return data["sdGenerationJob"]["generationId"]
        }
    };

    const getInferenceJob = async (inferenceId: string) => {
        const response = await axios.get(url + `/${inferenceId}`, { headers: HEADERS });
        const data = response.data;
        log.debug("[getInferenceJob]: ", data);
        return {
            "generatedImages": data["generations_by_pk"]["generated_images"],
            "status": data["generations_by_pk"]["status"]
        }
    };


    const extractUrls = (images: GeneratedImage[]) => {
        return images.map(image => image.url);
    }

    const runImageGeneration = async () => {
        setGeneratingImages(true);
        let prompt = "A dynamic, action-packed portrait of a super hero with a cape and a mask"
        // add a comma-separate list of form values to the prompt
        let propertiesString = Object.entries(formValues).map(([key, value]) => `${key}: ${value}`).join(", ");
        prompt = prompt + ". " + propertiesString;
        await generateImage(prompt, model).then(async (inferenceId) => {
            let status = "PENDING";
            while (status === "PENDING") {
                await new Promise(resolve => setTimeout(resolve, 3000)); // Sleep for 3 seconds
                const result = await getInferenceJob(inferenceId);
                log.debug("result: ", result);
                status = result.status;
                if (result.generatedImages.length > 0) {
                    setGeneratingImages(false);
                    let urls = extractUrls(result.generatedImages);
                    log.debug("urls: ", urls);
                    setImages(urls);
                }
            }
        })
            .catch((error) => {
                log.debug(`Error Message: ${error.response.data.error}`);
                log.debug(`Full Error Obj: `, error);
                sendSlackErrorNotification("Error generating image: " + error.response.data.error, "[generateImage]", JSON.stringify(error));
                alert("Error generating image. Please try again.");
                setGeneratingImages(false)
                return
            })
    }

    const takePhotoHandler = async () => {
        const photo = await Camera.getPhoto({
            allowEditing: false,
            correctOrientation: true,
            resultType: CameraResultType.DataUrl,
            source: CameraSource.Prompt,
            quality: 80,
            width: 500,
        }).catch((error) => {
            log.debug("Error taking photo: " + JSON.stringify(error));
        });

        //log.debug("Photo String: " + JSON.stringify(photo));
        if (photo && photo.dataUrl) {
            setTakenPhoto({
                path: photo.dataUrl,
                preview: photo.dataUrl,
            });
        }
    };

    const handleSelectChange = (event: SelectCustomEvent<any>) => {
        let valueUpdate = {
            [event.target.name]: event.target.value
        }

        if (event.target.value === 'Bald') {
            valueUpdate = {
                ...valueUpdate,
                hairColor: 'None'
            }
        }

        if (event.target.value === 'Female') {
            valueUpdate = {
                ...valueUpdate,
                facialHair: 'Clean shaven'
            }
        }

        setFormValues({ ...formValues, ...valueUpdate });
    };

    const selectStyle = {
        margin: '0.5em 0',
        backgroundColor: '#75aad2',
        color: '#fff',
        fontWeight: 'bold',
        padding: '0.5em',
        borderRadius: '4px'
    };

    const formLabelStyle = {
        fontWeight: 'bold',
        fontSize: '1.2em',
        color: '#fff',
        margin: '0.5em 0',
    };

    // Function to handle checkbox change
    const handleCheckboxChange = (name: keyof CheckboxValues) => {
        setCheckboxValues(prev => {
            const updated = { ...prev, [name]: !prev[name] };
            const checkedCount = Object.values(updated).filter(val => val).length;
            if (checkedCount > 4) {
                // Prevent more than 4 checkboxes from being checked
                return prev;
            }
            return updated;
        });
    };

    const saveAndConvertImage = async () => {
        if (takenPhoto && imageURL) {
            log.debug(`[saveAndConvertImage] photo received `);
            let prompt = "A dynamic, action-packed portrait of a super hero with a cape and a mask"
            // add a comma-separate list of form values to the prompt
            let propertiesString = Object.entries(formValues).map(([key, value]) => `${key}: ${value}`).join(", ");
            prompt = prompt + ". " + propertiesString;
            log.debug(`[saveAndConvertImage] prompt: `, prompt);
            setGeneratingImages(true);
            setSavingPhoto({
                visible: true,
                message: "Uploading Photo... 0%",
            });
            setPhotoError({
                visible: false,
                message: "",
            });

            log.debug(`[saveAndConvertImage] generating avatar from image`);
            const checkedValues = getCheckedValues();
            if (checkedValues.length === 0) {
                setGeneratingImages(false);
                setPhotoError({
                    visible: true,
                    message: "Please select at least one category",
                });
                return;
            }
            let images = await generateAvatarFromImage(imageURL, dramatic, checkedValues, prompt).catch((error) => {
                log.debug(`[saveAndConvertImage] error generating avatar from image: `, error);
                throw error;
            });
            setImages(images);
            setGeneratingImages(false);
        } else {
            setPhotoError({
                visible: true,
                message: "Please take a photo of your hero",
            });
        }

    };

    // Collect checked values into an array
    const getCheckedValues = (): string[] => {
        return Object.entries(checkboxValues)
            .filter(([key, value]) => value)
            .map(([key]) => key);
    };

    async function getImageAnalyzed() {
        //Uploads a file to the profiles-images bucket
        // var data = takenPhoto.path.split(",")[1];
        // var binaryString = atob(data); // decode base64
        // var binaryLen = binaryString.length;
        // var bytes = new Uint8Array(binaryLen);

        // for (var i = 0; i < binaryLen; i++) {
        //     var ascii = binaryString.charCodeAt(i);
        //     bytes[i] = ascii;
        // }
        const blob = await convertPhotoToBlob(takenPhoto.path);
        const parseFile = new Parse.File(`avatar-image-${Date.now()}`, blob);
        let imageUrl = '';
        try {
            const responseFile = await parseFile.save();
            log.debug("File saved successfully", responseFile);
            log.debug(`URL: ${parseFile._url}`)
            // tempOnboardingObject.heroPicture = result.data.publicPath;
            log.debug("Image uploaded: " + parseFile._url);
            imageUrl = parseFile._url;
        } catch (error) {
            log.debug("Error uploading image to Parse: " + JSON.stringify(error));
            throw new Error("Error uploading image to Parse: " + JSON.stringify(error));
        }
        let analysisResponse: ImageAnalysisResponse = await analyzeImage(imageUrl);
        setImageURL(imageUrl);
        log.debug(`[getImageAnalyzed] analysisResponse: `, analysisResponse);
        setFormValues(analysisResponse);
        setImageAnalyzed(true)
    }


    return (
        <IonPage>
            <HeaderLogo />
            <IonContent>
                <IonLoading isOpen={generatingImages} message={"Creating your FitHero..."} backdropDismiss />
                <div className="onboarding-main-div">
                    <div className="onboarding-title">How would you like your FitHero to look?</div>
                </div>
                {images.length > 0 && (<div style={{ textAlign: "center" }}><h2>Select Your FitHero</h2></div>)}

                {takenPhoto ? (
                    <>
                        <IonAvatar style={{ margin: "auto", height: "auto", width: "auto" }}>
                            <img src={takenPhoto.preview} className="onboarding-add-photo-button onboarding-avatar-image" alt="" onClick={takePhotoHandler} />
                        </IonAvatar>
                        <IonButton onClick={getImageAnalyzed}>Analyze Image</IonButton>
                    </>
                ) : (
                    <>
                        <IonAvatar style={{ margin: "auto", height: "auto", width: "auto" }}>
                            <img
                                src="assets/images/onboarding/unknown-person.jpg"
                                className="onboarding-add-photo-button onboarding-avatar-image"
                                alt=""
                                onClick={takePhotoHandler}
                            />
                        </IonAvatar>
                        <div className="onboarding-add-photo-button-div">
                            <img src="assets/images/onboarding/add-photo-button.png" className="onboarding-add-photo-button" alt="" onClick={takePhotoHandler} />
                        </div>
                    </>
                )}
                {imageAnalyzed && (
                    <IonButton onClick={saveAndConvertImage}>Generate FitHero</IonButton>
                )}

                {images.map((url: string, index: number) => {
                    return (
                        index % 2 === 0 ? (
                            <IonRow>
                                <IonCol onClick={() => setImageSelected({ index: index, url: images[index] })}>
                                    <img className={`${imageSelected.index === index ? "active" : ""}`} src={images[index]} alt={`Generated ${index}`} />
                                </IonCol>
                                <IonCol onClick={() => setImageSelected({ index: index + 1, url: images[index + 1] })}>
                                    {images[index + 1] ? <img src={images[index + 1]} className={`${imageSelected.index === index + 1 ? "active" : ""}`} alt={`Generated ${index + 1}`} /> : null}
                                </IonCol>
                            </IonRow>
                        ) : null
                    );
                })}
                {/* Checkbox inputs */}
                {Object.keys(checkboxValues).map((key) => (
                    <div key={key}>
                        <IonCheckbox
                            checked={checkboxValues[key as keyof CheckboxValues]}
                            onIonChange={() => handleCheckboxChange(key as keyof CheckboxValues)}
                        />
                        <IonLabel>{key}</IonLabel>
                    </div>
                ))}
                <IonCheckbox checked={dramatic} onIonChange={() => setDramatic(!dramatic)}>
                    <IonLabel>Dramatic Effect</IonLabel>
                </IonCheckbox>
                <form
                    className="form-grid"
                    style={{ color: "white" }}
                    onSubmit={(event) => {
                        event.preventDefault();
                        runImageGeneration();
                    }}
                >
                    <div>
                        <IonRow>
                            <IonCol size='6'>
                                <IonLabel style={formLabelStyle}>Hairstyle</IonLabel>
                                <IonSelect placeholder='Select hairstyle' interface='action-sheet' style={selectStyle} name="hairstyle" value={formValues.hairstyle} onIonChange={handleSelectChange} className="avatar-option-select">
                                    {hairstyleOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                </IonSelect>
                            </IonCol>
                            <IonCol size='6'>
                                <IonLabel style={formLabelStyle}>Ethnicity</IonLabel>
                                <IonSelect placeholder='Select ethnicity' interface='action-sheet' style={selectStyle} name="ethnicity" value={formValues.ethnicity} onIonChange={handleSelectChange} className="avatar-option-select">
                                    {ethnicityOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                </IonSelect>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='6'>
                                {formValues.hairstyle === 'Bald' ? (
                                    <>
                                        <IonLabel style={formLabelStyle}>Hair Color</IonLabel>
                                        <IonSelect placeholder='Select hair color' interface='action-sheet' style={selectStyle} name="hairColor" value={"None"} className="avatar-option-select" disabled={true}>
                                            {hairColorOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </>
                                ) : (
                                    <>
                                        <IonLabel style={formLabelStyle}>Hair Color</IonLabel>
                                        <IonSelect placeholder='Select hair color' interface='action-sheet' style={selectStyle} name="hairColor" value={formValues.hairColor} onIonChange={handleSelectChange} className="avatar-option-select">
                                            {hairColorOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </>
                                )}
                            </IonCol>
                            <IonCol size='6'>

                                <IonLabel style={formLabelStyle}>Eye Color</IonLabel>
                                <IonSelect placeholder='Select eye color' interface='action-sheet' style={selectStyle} name="eyeColor" value={formValues.eyeColor} onIonChange={handleSelectChange} className="avatar-option-select">
                                    {eyeColorOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                </IonSelect>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='6'>
                                <IonLabel style={formLabelStyle}>Gender</IonLabel>
                                <IonSelect placeholder='Select gender' interface='action-sheet' style={selectStyle} name="gender" value={formValues.gender} onIonChange={handleSelectChange} className="avatar-option-select">
                                    {genderOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                </IonSelect>
                            </IonCol>
                            <IonCol size='6'>
                                <IonLabel style={formLabelStyle}>Age</IonLabel>
                                <IonSelect placeholder='Select age' interface='action-sheet' style={selectStyle} name="age" value={formValues.age} onIonChange={handleSelectChange} className="avatar-option-select">
                                    {ageOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                </IonSelect>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='6'>
                                <IonLabel style={formLabelStyle}>Background</IonLabel>
                                <IonSelect placeholder='Select background' interface='action-sheet' style={selectStyle} name="background" value={formValues.background} onIonChange={handleSelectChange} className="avatar-option-select">
                                    {backgroundOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                </IonSelect>
                            </IonCol>
                            <IonCol size='6'>
                                <IonLabel style={formLabelStyle}>Facial Expression</IonLabel>
                                <IonSelect placeholder='Select expression' interface='action-sheet' style={selectStyle} name="facialExpression" value={formValues.facialExpression} onIonChange={handleSelectChange} className="avatar-option-select">
                                    {facialExpressionOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                </IonSelect>
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol size='6'>
                                {formValues.gender === 'Female' ? (
                                    <>
                                        <IonLabel style={formLabelStyle}>Facial Hair</IonLabel>
                                        <IonSelect placeholder='Select facial hair' interface='action-sheet' style={selectStyle} name="facialHair" value={"None"} disabled={true} className="avatar-option-select">
                                            {facialHairOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </>
                                ) : (
                                    <>
                                        <IonLabel style={formLabelStyle}>Facial Hair</IonLabel>
                                        <IonSelect placeholder='Select facial hair' interface='action-sheet' style={selectStyle} name="facialHair" value={formValues.facialHair} onIonChange={handleSelectChange} className="avatar-option-select">
                                            {facialHairOptions.map((option, index) => <IonSelectOption key={index} value={option}>{option}</IonSelectOption>)}
                                        </IonSelect>
                                    </>
                                )}
                            </IonCol>
                        </IonRow>
                        <IonRow>
                            <IonCol style={{ textAlign: "center" }} size='6' >
                                <IonButton color={'success'} type="submit">Generate Image</IonButton>
                            </IonCol>
                            <IonCol style={{ textAlign: "center" }} size='6'>
                                <IonButton color={'primary'} onClick={randomizeValues}>Randomize Values</IonButton>
                            </IonCol>
                        </IonRow>
                    </div>
                </form>
                {/* <InitImageUpload /> */}
            </IonContent>
        </IonPage>
    );
};

export default TestLeonardo;
