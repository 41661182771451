import React from 'react'
import "../../../css/Duels.css";


export const TallyMarkComponent = (props: { completed: boolean, difficulty: string }) => {
    if (props.difficulty === "boss") {
        return (
            <div className={`completion-circle ${props.difficulty} ${props.completed === true ? "completed" : ""}`}>
                {props.completed ? (
                    <>
                        ✔
                    </>
                ) : (
                    <>
                        {props.difficulty}
                    </>
                )}

            </div>
        )
    }
    return (
        <div className={`completion-circle ${props.difficulty} ${props.completed === true ? "completed" : ""}`}>
            {props.completed ? (
                <>
                    ✔
                </>
            ) : (
                <>
                    {props.difficulty[0].toUpperCase()}
                </>
            )}

        </div>
    )
    switch (props.difficulty) {
        case "easy":
            return (
                <div>
                    <div className={`completion-circle easy ${props.completed === true ? "completed" : ""}`}>
                        E
                    </div>
                    {props.completed ? (
                        <>

                        </>
                    ) : (
                        <>
                            <div className='completion-circle easy'>
                                E
                            </div>
                        </>
                    )}
                </div>
            )
        case "medium":
            return (
                <div>
                    {props.completed ? "M" : "O"}
                </div>
            )
        case "hard":
            return (
                <div>
                    {props.completed ? "H" : "O"}
                </div>
            )
        case "boss":
            return (
                <div>
                    {props.completed ? "B" : "O"}
                </div>
            )
        default:
            return (
                <div>
                    {props.completed ? "E" : "O"}
                </div>
            )
    }
}
