import { IonAvatar, IonCol, IonContent, IonGrid, IonRow, IonSpinner } from '@ionic/react'
import log from 'loglevel'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import "../../../css/Feed.css"
import { HeroObject } from '../../../models/userModel'
import { getHero, getChallengeLeaderboard, ChallengeLeaderboardObject } from '../../../services/UserService'


export const ChallengeLeaderboard = (props: { hero: HeroObject }) => {
    const history = useHistory();
    const [allHeroes, setAllHeroes] = useState<ChallengeLeaderboardObject[]>([])
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [heroId] = useState<string>(props.hero.heroID!)

    useEffect(() => {
        refreshHeroData()
    }, [])

    async function refreshHeroData(refresh: boolean = false) {
        setRefreshing(true)
        let heroes = await getChallengeLeaderboard()
        // console.log(heroes)
        setAllHeroes(heroes)
        setRefreshing(false)
    }

    async function tapAvatar(heroId: string) {
        history.push(`/otherprofile/${heroId}`)
    }

    async function sortHeroes(sortType: string) {
        log.debug(`sortType: ${sortType}`)
        let heroes = [...allHeroes];  // Create a new array
        if (sortType === "duelPoints") {
            heroes.sort((a, b) => b.challengePoints! - a.challengePoints!);
        } else if (sortType === "name") {
            heroes.sort((a, b) => a.heroName!.localeCompare(b.heroName!));
        }
        setAllHeroes(heroes);
    }


    return (
        <>
            <IonContent>
                {refreshing && (
                    <>
                        <IonSpinner name="crescent" />
                    </>
                )}
                {/* <IonLoading isOpen={refreshing} message={"Refreshing data ..."} backdropDismiss /> */}
                <IonGrid>
                    <IonRow>
                        <IonCol size="6" className='challenge-leaderboard-column-header' onClick={() => sortHeroes("name")}>
                            Hero
                        </IonCol>
                        <IonCol size="6" className='challenge-leaderboard-column-header' style={{ textAlign: "center" }} onClick={() => sortHeroes("duelPoints")}>
                            Duel Points
                        </IonCol>
                    </IonRow>
                    {allHeroes.map((hero: ChallengeLeaderboardObject, index) => {
                        let className = "hero-row";
                        if (index % 2 !== 0) {
                            className = "hero-row-alt";
                        }
                        if (hero.heroID === heroId) {
                            className = "hero-row-current"
                        }
                        return (
                            // alternate row colors based on index
                            <IonRow className={className}>
                                <IonCol size="1" style={{ paddingRight: "0px" }}>
                                    {index + 1}.
                                </IonCol>
                                <IonCol size="2">
                                    <IonAvatar className="member-image-feed" onClick={() => tapAvatar(hero.heroID!)}>
                                        <img src={hero.heroImageURL} alt='hero image' />
                                    </IonAvatar>
                                </IonCol>
                                <IonCol size="3">
                                    {hero.heroName}
                                </IonCol>
                                <IonCol size="6" style={{ textAlign: "center" }}>
                                    {hero.challengePoints}
                                </IonCol>
                            </IonRow>
                        )
                    })}
                </IonGrid>
                <div>

                </div>
            </IonContent>
        </>
    )
}
