import axios from "axios";
import { generalNegativeAvatarPrompt } from "../models/userModel";
import log from "loglevel";

/** AVATAR OPTIONS  */
export const genderOptions = ["Male", "Female", "Non-binary"];
export const hairstyleOptions = ["Curly", "Straight", "Wavy", "Bald", "Dreadlock", "Braids", "Spiky", "Short cropped"];
export const ethnicityOptions = ["Caucasian", "African", "Asian", "Hispanic"];
export const hairColorOptions = ["Black", "Brown", "Blonde", "Red", "Blue", "Purple", "Green"];
export const eyeColorOptions = ["Brown", "Blue", "Green", "Hazel"];
export const ageOptions = ["Gen Z", "Millenial", "Gen X", "Baby Boomer"];
export const facialExpressionOptions = ["Happy", "Sad", "Angry", "Neutral", "Peaceful", "Determined"];
export const facialHairOptions = ["Clean shaven", "Stubble", "Goatee", "Full Beard", "Mustache", "Sideburns", "None"];
// const perspectiveOptions = ["portrait", "full body image"];
export const backgroundOptions = [
    "Modern Gym",
    "Outdoor Fitness Park",
    "Boxing Ring",
    "Martial Arts Dojo",
    "Yoga Studio",
    "Rock Climbing Gym",
    "Olympic Swimming Pool",
    "Sports Stadium",
    "Running Track",
    "Home Gym",
    "Crossfit Box",
    "Bootcamp Class",
    "Beach Volleyball Court",
    "Ski Resort",
    "Hiking Trail",
    "Mountain Bike Trail",
    "Soccer Field",
    "Tennis Court",
    "Forest Running Path",
    "Surf Spot",
    "Basketball Court",
    "Outdoor Yoga Retreat",
    "None",
];

/** LEONARDO.AI MODELS  */
export const creative = "6bef9f1b-29cb-40c7-b9df-32b51c1f67d3"
export const select = "cd2b2a15-9760-4174-a5ff-4d2925057376"
export const signature = "291be633-cb24-434f-898f-e662799936ad"
export const dreamshaper7 = "ac614f96-1082-45bf-be9d-757f2d31c174"
export const AlbedoBaseXL = "2067ae52-33fd-4a82-bb92-c2c55e7d2786"
export const RPGv5 = "f1929ea3-b169-4c18-a16c-5d58b4292c69"

/** CONFIGURATION OPTIONS  */
const imageGenerationURL = "https://cloud.leonardo.ai/api/rest/v1/generations";
const cfg_scale = 7;
// const API_KEY = "602b2675-8efe-43c7-81f2-0e847aba3b6a";
const PROD_API_KEY = "14d52df6-efe3-43d5-bde1-cc1adbcfdf35"
const HEADERS = {
    accept: "application/json",
    "content-type": "application/json",
    authorization: `Bearer ${PROD_API_KEY}`,
};
export type ElementCheckboxValues = {
    FIRE: boolean;
    MAGIC: boolean;
    STEEL: boolean;
    FANTASY: boolean;
    SCIFI: boolean;
    EGYPTIAN: boolean;
    PIRATE: boolean;
    CYBORG: boolean;
};


/** FUNCTIONS */
export async function convertPhotoToBlob(photoString: string) {
    log.debug(`[convertPhotoToBlob] converting photo to blob`);
    var data = photoString.split(",")[1];
    var binaryString = atob(data); // decode base64
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);

    for (var i = 0; i < binaryLen; i++) {
        var ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
    }
    const blob = new Blob([bytes], { type: "image/jpeg" }); // Create a BLOB object
    return blob;
}

export const generateImage = async (positivePrompt: string, model: string) => {
    const payload = {
        "prompt": positivePrompt,
        "negative_prompt": generalNegativeAvatarPrompt,
        "modelId": model,
        "sd_version": "v2",
        "num_images": 4,
        "width": 512,
        "height": 512,
        "num_inference_steps": null,
        "guidance_scale": cfg_scale,
        "init_generation_image_id": null,
        "init_image_id": null,
        "init_strength": null,
        "scheduler": "EULER_DISCRETE",
        "presetStyle": "DYNAMIC",
        "tiling": false,
        "public": false,
        "promptMagic": false,
        "controlNet": null,
        "controlNetType": null,
        "alchemy": true,
        // "photoReal": true,
    }
    const response = await axios.post(imageGenerationURL, payload, { headers: HEADERS }).catch((error) => {
        throw error
    });
    if (response) {
        const data = response.data;
        log.debug("[generateImage]: ", data);
        return data["sdGenerationJob"]["generationId"]
    }
};

export const getInferenceJob = async (inferenceId: string) => {
    const response = await axios.get(imageGenerationURL + `/${inferenceId}`, { headers: HEADERS });
    const data = response.data;
    log.debug("[getInferenceJob]: ", data);
    return {
        "generatedImages": data["generations_by_pk"]["generated_images"],
        "status": data["generations_by_pk"]["status"]
    }
};