import React, { useEffect, useRef, useState } from 'react';
import './testAnimation.css';

const TestAnimation = () => {
  const [isHeroAttacking, setIsHeroAttacking] = useState(false);
  const [isVillainAttacking, setIsVillainAttacking] = useState(false);
  const [showCollisionEffect, setShowCollisionEffect] = useState(false);
  const [collisionEffectPosition, setCollisionEffectPosition] = useState({ x: 0, y: 0 });
  const [collisionImage, setCollisionImage] = useState('');
  const collisionEffectRef = useRef<HTMLDivElement>(null);
  const heroRef = useRef<HTMLDivElement>(null);
  const villainRef = useRef<HTMLDivElement>(null);

  const collisionImages = [
    'https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/4f5442f9a27cbbe9d12b80cdc7f91dd0_POW.png', // Replace these with your actual image paths
    // ... add as many images as you like
  ];

  // Use an effect to continuously check for collision during animations
  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isHeroAttacking || isVillainAttacking) {
      intervalId = setInterval(checkCollision, 100); // check for collision every 100ms
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [isHeroAttacking, isVillainAttacking]);

  const triggerCollisionEffect = (x: number, y: number) => {
    // Select a random image from the array
    const randomImage = collisionImages[Math.floor(Math.random() * collisionImages.length)];
    setCollisionImage(randomImage);
    setShowCollisionEffect(true);
    setCollisionEffectPosition({ x, y });

    // This will hide the effect after 2 seconds, allowing for the 2-second pause at the collision point
    setTimeout(() => {
      setShowCollisionEffect(false);
    }, 1000); // 2 seconds for the pause, plus 500 ms to hide the effect
  };

  const checkCollision = () => {
    const hero = heroRef.current?.getBoundingClientRect();
    const villain = villainRef.current?.getBoundingClientRect();
    if (hero && villain) {
      const collision = hero.x < villain.x + villain.width &&
        hero.x + hero.width > villain.x &&
        hero.y < villain.y + villain.height &&
        hero.y + hero.height > villain.y;

      if (collision) {
        // Set the collision effect to show and position it
        setShowCollisionEffect(true);
        const effectX = (hero.x + villain.x / 2); // X position for collision effect
        const effectY = (hero.y + hero.height / 4)// Y position for collision effect
        triggerCollisionEffect(effectX, effectY);

        // Add shaking effect
        heroRef.current?.classList.add('shake-animation');
        villainRef.current?.classList.add('shake-animation');
        setTimeout(() => {
          heroRef.current?.classList.remove('shake-animation');
          villainRef.current?.classList.remove('shake-animation');
        }, 500); // The shaking animation lasts for half a second
      }
    };
  };

  const handleHeroAttack = () => {
    setIsHeroAttacking(true);
    setIsVillainAttacking(false);
    setTimeout(() => {
      setIsHeroAttacking(false);
      checkCollision(); // The collision will now have been checked during the pause
    }, 1000); // Account for the 2-second pause
  };

  const handleVillainAttack = () => {
    setIsVillainAttacking(true);
    setIsHeroAttacking(false);
    setTimeout(() => {
      setIsVillainAttacking(false);
      checkCollision(); // The collision will now have been checked during the pause
    }, 1000); // Account for the 2-second pause
  };

  return (
    <div className="fit-hero-game">
      <div
        ref={heroRef}
        className={`hero ${isHeroAttacking ? 'hero-attack-animation' : ''}`}
        onClick={handleHeroAttack}
      >
        <img className='image' src='https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/b35d635779ac780433a83adf6146b5b2_user-ed0c459a-ff19-42be-b01e-9916d4044c47' />
      </div>
      <div
        ref={villainRef}
        className={`villain ${isVillainAttacking ? 'villain-attack-animation' : ''}`}
        onClick={handleVillainAttack}
      >
        <img className='image' src='https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/f2bf6ee1e0a2c559cac2f438f2796ede_guinney.webp' />
      </div>
      {showCollisionEffect && (
        <div
          //   ref={collisionEffectRef}
          className="collision-effect"
          style={{
            left: collisionEffectPosition.x,
            top: collisionEffectPosition.y
          }}
        >
          <img className='pow-image' src={collisionImage} alt='Collision Effect' />
        </div>
      )}
    </div>
  );
};

export default TestAnimation;
