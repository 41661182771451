import React, { useContext, useEffect, useState } from 'react'
import { Villain } from '../../../models/villainModels'
import { ActivityMatrix, convertImpactToText, convertImpactToTextV2, convertResistantImpactToText, convertVulnerableImpactToText, getActivityImpactMatrix, resistantKeys, vulnerableKeys } from '../../../services/VillainServices';
import { IonButton, IonIcon, IonModal, IonSpinner } from '@ionic/react';
import { caretDown, caretUp, caretUpCircleOutline, informationCircleOutline } from 'ionicons/icons';
import "../../../css/Duels.css";
import { set } from 'lodash';
import GlobalState from '../../../contexts/GlobalState';


export const VillainWeakness = (props: { villain: Villain, setShowPaywall?: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { paywallEnabled, currentlySubscribed } = globalState;
    const [vulnerableImpacts, setVulnerableImpacts] = useState<string[]>();
    const [resistantImpacts, setResistantImpacts] = useState<string[]>([]);
    const [showVulnerableModal, setShowVulnerableModal] = useState<boolean>(false);
    const [showResistantModal, setShowResistantModal] = useState<boolean>(false);
    const [vulnerableActivities, setVulnerableActivities] = useState<string[]>([]);
    const [resistantActivities, setResistantActivities] = useState<string[]>([]);
    const [loadingImpacts, setLoadingImpacts] = useState<boolean>(true);

    useEffect(() => {
        getImpacts();
        console.log("[VillainWeakness] currently subscribed: ", currentlySubscribed)
    }, [props.villain]);

    async function getImpacts() {
        let activityMatrix = await getActivityImpactMatrix()
        let vulnerableActivities: string[] = [];
        if (vulnerableKeys.includes(props.villain.strengthImp)) {
            vulnerableActivities.push("💪 strength")
        }
        if (vulnerableKeys.includes(props.villain.cardioImp)) {
            vulnerableActivities.push("💚 cardio")
        }
        if (vulnerableKeys.includes(props.villain.mindfulnessImp)) {
            vulnerableActivities.push("🙏 mindfulness")
        }
        if (vulnerableKeys.includes(props.villain.mobilityImp)) {
            vulnerableActivities.push("🧘‍♀️ mobility")
        }
        let resistantActivities: string[] = [];
        if (resistantKeys.includes(props.villain.strengthImp)) {
            resistantActivities.push("💪 strength")
        }
        if (resistantKeys.includes(props.villain.cardioImp)) {
            resistantActivities.push("💚 cardio")
        }
        if (resistantKeys.includes(props.villain.mindfulnessImp)) {
            resistantActivities.push("🙏 mindfulness")
        }
        if (resistantKeys.includes(props.villain.mobilityImp)) {
            resistantActivities.push("🧘‍♀️ mobility")
        }
        setVulnerableActivities(vulnerableActivities)
        setResistantActivities(resistantActivities)
        setVulnerableImpacts(convertVulnerableImpactToText(props.villain, activityMatrix))
        setResistantImpacts(convertResistantImpactToText(props.villain, activityMatrix))
        setLoadingImpacts(false);
    }
    return (
        <>
            <IonModal className="villain-difficulty-modal" isOpen={showVulnerableModal} onDidDismiss={() => setShowVulnerableModal(false)}>
                <div className="villain-difficulty-modal-div" style={{ textAlign: "center" }}>
                    <p style={{ marginBottom: "0px" }} ><IonIcon icon={caretUp} className='up-arrow-weakness' style={{ fontSize: "50px" }} /> </p>
                    <p>{props.villain.name} is vulnerable to {vulnerableActivities.join(", ")} activities</p>
                    <p>Do <span className='onboarding-emphasis'>more of these types of activities</span> to beat this villain!</p>
                    <p>
                        Slight = A bit more damage<br />
                        Moderate = A good amount more damage<br />
                        Extreme = A lot more damage
                    </p>
                </div>
            </IonModal>
            <IonModal className="villain-difficulty-modal" isOpen={showResistantModal} onDidDismiss={() => setShowResistantModal(false)}>
                <div className="villain-difficulty-modal-div" style={{ textAlign: "center" }}>
                    <p style={{ marginBottom: "0px" }} ><IonIcon icon={caretDown} className='down-arrow-weakness' style={{ fontSize: "50px" }} /> </p>
                    <p>{props.villain.name} is resistant to {resistantActivities.join(", ")} activities</p>
                    <p>These activites <span className='onboarding-emphasis'>will not hurt the villain</span> as much!</p>
                    <p>
                        Slight = A bit less damage<br />
                        Moderate = A good amount less damage<br />
                        Extreme = A lot less damage
                    </p>
                </div>
            </IonModal>

            <div className='weakness-container'>
                {vulnerableImpacts && vulnerableImpacts.length > 0 ? (
                    <h3 onClick={() => setShowVulnerableModal(true)} className='weakness-header'><IonIcon icon={caretUp} className='up-arrow-weakness' />{props.villain.name} is vulnerable to:<IonIcon style={{ fontSize: "12px", marginTop: "-15px" }} color="white" icon={informationCircleOutline} /></h3>
                ) : (
                    <h3 className='weakness-header'><IonIcon icon={caretUp} className='up-arrow-weakness' />{props.villain.name} is vulnerable to:</h3>
                )}
                {loadingImpacts === true ? (
                    <>
                        <p>Loading villain weaknesses... <IonSpinner /></p>
                    </>
                ) : (
                    <>
                        {paywallEnabled === true && currentlySubscribed === false ? (
                            <>
                                <div style={{ position: "relative" }}>
                                    <ul className='paywall-blurred-text'>
                                        {vulnerableImpacts && vulnerableImpacts.length === 0 ? (
                                            <>
                                                <li>No vulnerabilities</li>
                                            </>
                                        ) : (
                                            <>
                                                {vulnerableImpacts && vulnerableImpacts.map((impact, index) => {
                                                    return <li key={index}>{impact}</li>
                                                })}
                                            </>
                                        )}
                                    </ul>
                                    <div className='paywall-villain-weaks-div'>
                                        <IonButton color={"success"} className='paywall-powerup-button' onClick={() => props.setShowPaywall!(true)}>Unlock</IonButton>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <ul>
                                    {vulnerableImpacts && vulnerableImpacts.length === 0 ? (
                                        <li>No vulnerabilities</li>
                                    ) : (
                                        <>
                                            {vulnerableImpacts && vulnerableImpacts.map((impact, index) => {
                                                return <li key={index}>{impact}</li>
                                            })}
                                        </>
                                    )}
                                </ul>
                            </>
                        )}
                    </>
                )}
                {resistantImpacts.length > 0 && (paywallEnabled === false || currentlySubscribed === true) ? (
                    <h3 onClick={() => setShowResistantModal(true)} className='weakness-header'><IonIcon icon={caretDown} className='down-arrow-weakness' />{props.villain.name} is resistant to:<IonIcon style={{ fontSize: "12px", marginTop: "-15px" }} color="white" icon={informationCircleOutline} /></h3>

                ) : (
                    <h3 className='weakness-header'><IonIcon icon={caretDown} className='down-arrow-weakness' />{props.villain.name} is resistant to:</h3>
                )}
                {loadingImpacts === true ? (
                    <>
                        <p>Loading villain resistances... <IonSpinner /></p>
                    </>
                ) : (
                    <>
                        {paywallEnabled === true && currentlySubscribed === false ? (
                            <>
                                <div style={{ position: "relative" }}>
                                    <ul className='paywall-blurred-text'>
                                        {resistantImpacts.length === 0 ? (
                                            <>
                                                <li>No resistances</li>
                                            </>
                                        ) : (
                                            <>
                                                {resistantImpacts.map((impact, index) => {
                                                    return <li key={index}>{impact}</li>
                                                })}
                                            </>
                                        )}
                                    </ul>
                                    <div className='paywall-villain-weaks-div'>
                                        <IonButton color={"success"} className='paywall-powerup-button' onClick={() => props.setShowPaywall!(true)}>Unlock</IonButton>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <ul>
                                    {resistantImpacts.length === 0 ? (
                                        <>
                                            <li>No resistances</li>
                                        </>
                                    ) : (
                                        <>
                                            {resistantImpacts.map((impact, index) => {
                                                return <li key={index}>{impact}</li>
                                            })}
                                        </>
                                    )}
                                </ul>
                            </>
                        )}

                    </>
                )}
            </div>
        </>
    )
}
