import {
	IonContent,
	IonHeader,
	IonLabel,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonToolbar
} from "@ionic/react";
import { Steps } from "intro.js-react";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Leaderboard from "../../../Feed/pages/Leaderboard";
import ProfileHeader from "../../Profile/components/ProfileHeader";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Duels.css";
import { DuelStatus, duelStatusKey } from "../../../models/duelsModels";
import { hasCoachMarkBeenShown, updateCoachMark } from "../../../services/HelperService";

import CurrentDuel from "../components/CurrentDuel";
import DuelHistory from "../components/DuelHistory";
import { PracticeGym } from "../components/PracticeGym";
import VillainGroupProgression from "../../Villians/components/VillainGroupProgression";
import { StorageService } from "../../../services/StorageService";
let storageService = new StorageService();

const DuelsHome = () => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const {
		heroObject,
		duelLoadingFinished,
		shouldShowNotificationModal
	} = globalState;
	const { viewParam } = useParams<{ duelID: string, viewParam: string }>();
	const [duelStatus, setDuelStatus] = useState<DuelStatus[]>([]);

	const history = useHistory();
	const [view, setView] = useState<string | undefined>(viewParam);

	useEffect(() => {
		async function pullDuelInfo() {
			setDuelStatus(await storageService.getObject(duelStatusKey));
		}
		if (view === undefined) { setView("current") };
		pullDuelInfo();
	}, [duelLoadingFinished]);

	function navigate(view: string) {
		history.push(`/duels/${view}`);
	}


	return (
		<IonPage>
			<IonHeader>
				<IonToolbar className="background">
					<ProfileHeader heroName={heroObject?.heroName} heroPicture={heroObject?.heroImageURL} heroLevel={heroObject?.overallLevel} />
				</IonToolbar>
			</IonHeader>
			{/* <IonLoading isOpen={duelLoadingFinished === false} message={"Loading..."} backdropDismiss duration={5000} /> */}
			<div style={{ minHeight: "65px" }}>
				<IonSegment mode="ios" color="tertiary" value={view} onIonChange={(e) => setView(e.detail.value)} style={{ position: "fixed", zIndex: "20000", paddingTop: "5px", paddingBottom: "5px" }}>
					<IonSegmentButton onClick={() => navigate("current")} value="current" layout="icon-top" className="duels-segment-button">
						<div className="duels-segment-icon-special">⚔</div>
						<IonLabel>Current</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton onClick={() => navigate("history")} value="history" layout="icon-top" className="duels-segment-button">
						<div className="duels-segment-icon-special">📰</div>
						<IonLabel>History</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton onClick={() => navigate("progression")} value="progression" layout="icon-top" className="duels-segment-button">
						<div className="duels-segment-icon-special">📈</div>
						<IonLabel>Progression</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton onClick={() => navigate("open")} value="open" layout="icon-top" className="duels-segment-button">
						<div className="duels-segment-icon-special">🥊</div>
						<IonLabel>Open</IonLabel>
					</IonSegmentButton>
					<IonSegmentButton onClick={() => navigate("leaderboard")} value="leaderboard" layout="icon-top" className="duels-segment-button">
						<div className="duels-segment-icon-special">🏅</div>
						<IonLabel>Leaderboard</IonLabel>
					</IonSegmentButton>
				</IonSegment>
			</div>
			{(view === "current") && (
				<IonContent>
					<CurrentDuel duelStatus={duelStatus} setView={setView} />
				</IonContent>
			)}
			{view === "progression" && (
				<IonContent>
					<VillainGroupProgression />
				</IonContent>
			)}
			{view === "history" && (
				<DuelHistory duelStatus={duelStatus} />
			)}
			{view === "open" && (
				<PracticeGym />
			)}
			{view === "leaderboard" && (
				<Leaderboard />
			)}
		</IonPage>
	);
};

export default DuelsHome;




