import React from 'react'

export const GenericModal = (props: { title: string, body: string[] }) => {
    return (
        <div className="villain-difficulty-modal-div">
            <div>
                <span className="villian-header-2" style={{ fontSize: "30px" }}>{props.title}</span><br />
                {props.body.map((body, index) => (
                    <p key={index}>{body}</p>
                ))}
            </div>
        </div>
    )
}