import React, { useContext, useEffect, useState } from 'react'
import { CardioSpecializations, Skill, Specialization, StrengthSpecializations } from "../../models/skills";
import { IonAlert, IonButton, IonCol, IonRow } from '@ionic/react';
import { updateHeroSpecialization } from '../../services/UserService';
import { capitalizeFirstLetter } from '../../services/HelperService';
import GlobalState from '../../contexts/GlobalState';
import { logGenericEventWithObject } from '../../services/AnalyticsService';
import log from 'loglevel';

const SelectSpecializationModal = (props: {
    attribute: string, specializations: Specialization[],
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    type SpecializationSelection = {
        specialization: Specialization | null,
        show: boolean
    }
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { setHeroObject } = globalState;
    const [showAlert, setShowAlert] = useState<SpecializationSelection>({ show: false, specialization: null });
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        logGenericEventWithObject("view select specialization modal", { attribute: props.attribute })
    }, [props.attribute]);

    async function setHeroSpecialization() {
        setLoading(true)
        log.debug(showAlert.specialization)
        let updatedHero = await updateHeroSpecialization(props.attribute, showAlert.specialization?.name ?? "")
        if (updatedHero) { setHeroObject(updatedHero) }
        setLoading(false)
        props.setShowModal(false)
        setShowAlert({ show: false, specialization: null })
    }
    return (
        <>
            <IonAlert
                isOpen={loading}
                message={"Setting specialization..."}
            />
            <IonAlert
                isOpen={showAlert.show}
                header={"Confirm Selection"}
                message={`Are you sure you want to select ${showAlert.specialization?.name} as your specialization?`}
                buttons={[
                    { text: "No", role: "cancel" },
                    { text: "Yes", handler: () => setHeroSpecialization() },
                ]}
            />
            <div style={{ margin: "20px" }}>
                <h1>Select Your {capitalizeFirstLetter(props.attribute)} Specialization</h1>
                <img src={`assets/images/${props.attribute}-specialization-selection-2.jpg`} />
                <p>Now that you're level 20 in {props.attribute}, you can select a specialization</p>
                {props.specializations.map((specialization: Specialization, index: number) => {
                    return (
                        <>
                            <IonRow key={index} style={{ marginBottom: "20px" }}>
                                <IonCol size='5' style={{ display: 'flex', alignItems: "center" }}>
                                    <IonButton style={{ "--background": `var(--${props.attribute}-color)` }} onClick={() => setShowAlert({ show: true, specialization: specialization })}>
                                        {specialization.name}
                                    </IonButton>
                                </IonCol>
                                <IonCol size='7' style={{ fontSize: "12px" }}>
                                    {specialization.description}
                                </IonCol>
                            </IonRow>
                        </>

                    )
                })}
            </div>
        </>
    )
}

export default SelectSpecializationModal