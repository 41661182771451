/* eslint-disable react-hooks/exhaustive-deps */
import { IonAlert, IonAvatar, IonButton, IonButtons, IonFooter, IonHeader, IonIcon, IonLoading, IonPage, IonToolbar } from "@ionic/react";
import "../../../css/App.css";
import "../../../css/HeroChat.css";

import { refreshCircleOutline } from "ionicons/icons";
import log from "loglevel";
import { useContext, useEffect, useState } from "react";
import GlobalState from "../../../contexts/GlobalState";
import { HeroMessage, heroDBObjectKey, heroDisplayMessagesKey, lastHeroPromptKey } from "../../../models/herochatModel";
import { HeroMessagesResponse, HeroPrompt, getHeroMessages, getHeroMessagesFromDB, getHeroPrompts, processHeroSystemResponse, sendHeroMessage, sendHeroSystemMessage, shouldSendHeroPrompt } from "../../../services/HerochatService";
import { disableSidekick } from "../../../services/SideKickService";
import { StorageService } from "../../../services/StorageService";
import HeroChatInput from "../components/HeroChatInput";
import HeroChatComponent from "../components/HeroChatRenderComponent";
import { insertNewLines } from "../../../services/HelperService";
import moment from "moment";
const storageService = new StorageService();

const HeroChat = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, heroMessagesLoadingFinished, setHeroNewMessages } = globalState;
    const [messagesForDisplay, setMessagesForDisplay] = useState<HeroMessage[]>([]);
    const [processing, setProcessing] = useState<boolean>(false);
    const [scroller, setScroller] = useState<boolean>(true); // used to simply trigger a rerender without more info
    const [showClearChatConfirmation, setShowClearChatConfirmation] = useState<boolean>(false);
    const [refreshing, setRefreshing] = useState<boolean>(false);

    useEffect(() => {
        async function getMessages() {
            setRefreshing(true);
            let firstChat = false;
            await getHeroMessagesFromDB().then((heroMessageObject: HeroMessagesResponse) => {
                if (heroMessageObject.messages.length === 0) {
                    firstChat = true;
                }
                setMessagesForDisplay(heroMessageObject.messages);
            }).catch((error) => {
                log.error(error);
                alert("Error: " + error);
            });
            // log.debug("[getMessages] messages: ", messages);            
            setRefreshing(false);
            sendHeroPromptMessage(firstChat);
        }
        // log.debug("HeroChat useEffect Triggered");
        getMessages();
        setHeroNewMessages(0); // reset the new messages flag since we're now displaying them
    }, [heroObject, heroMessagesLoadingFinished]);

    async function sendHeroPromptMessage(firstChat: boolean) {
        let shouldSendPrompt = false;
        if (firstChat === false) {
            shouldSendPrompt = await shouldSendHeroPrompt();
            log.debug("shouldSendPrompt: ", shouldSendPrompt);
        }
        if (shouldSendPrompt || firstChat) {
            let heroResponse: HeroMessage;
            if (firstChat === false) {
                let prompts: HeroPrompt[] = await getHeroPrompts();
                // get a random prompt
                let randomPrompt = prompts[Math.floor(Math.random() * prompts.length)];
                setProcessing(true);
                heroResponse = await sendHeroSystemMessage(randomPrompt.primaryResponseType, randomPrompt.secondaryResponseType, randomPrompt.prompt);
            } else {
                setProcessing(true);
                let greetingPrompt = "Since we already know each other, congradulate me on getting this far, let me know you can answer any questions I have, and ask me how I'm doing today"
                heroResponse = await sendHeroSystemMessage("<connect>", "<initialGreeting>", greetingPrompt);
            }
            log.debug("heroResponse: ", heroResponse);
            await storageService.setItem(lastHeroPromptKey, moment().format());
            let displayMessages = await processHeroSystemResponse(heroResponse);
            let lastHeroMessage: HeroMessage = displayMessages[displayMessages.length - 1];
            let lastHeroMessageArray = insertNewLines(lastHeroMessage.message).split("\n");
            lastHeroMessageArray.forEach((message, index) => {
                // let randomMessageDelay = Math.floor(Math.random() * 3000) + 2000;
                let randomMessageDelay = 4000;
                setTimeout(() => {
                    let heroMessage: HeroMessage = {
                        ...lastHeroMessage,
                        message: message
                    };
                    // Using functional update to ensure we have the latest state
                    setMessagesForDisplay((prevMessages: HeroMessage[]) => [...prevMessages, heroMessage]);

                    if (index === lastHeroMessageArray.length - 1) {
                        setProcessing(false);
                    } else {
                        setProcessing(true);
                    }
                }, randomMessageDelay * (index + 1));
            });
        }
    }

    async function clearChatHistory() {
        setRefreshing(true);
        setMessagesForDisplay([]);
        await storageService.removeItem(heroDisplayMessagesKey);
        getHeroMessagesFromDB().then((result: HeroMessagesResponse) => {
            setMessagesForDisplay(result.messages);
            setRefreshing(false);
        });
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar className="background">
                    <IonButtons slot="start" className="toolbar-left-buttons">
                        <IonButton onClick={() => clearChatHistory()}>
                            <IonIcon style={{ color: "white" }} icon={refreshCircleOutline} />
                        </IonButton>
                    </IonButtons>
                    <div className="toolbar-hero-chat">
                        <IonAvatar className="hero-chat-avatar">
                            <img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={heroObject?.heroImageURL ? `${heroObject?.heroImageURL}` : `https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/37a94d6b6e738e8e1bd93182d397e327_snarf.jpg`} alt="" />
                        </IonAvatar>
                        <div className="toolbar-hero-chat-name">
                            {heroObject?.heroName}
                        </div>
                    </div>
                </IonToolbar>
            </IonHeader>
            <IonLoading isOpen={refreshing} message={"Refreshing messages..."} backdropDismiss />

            <HeroChatComponent
                scroller={scroller}
                setScroller={setScroller}
                messages={messagesForDisplay}
                setMessages={setMessagesForDisplay}
                processing={processing}
                setProcessing={setProcessing}
            />
            <IonFooter>
                <HeroChatInput setMessages={setMessagesForDisplay} messages={messagesForDisplay} setProcessing={setProcessing} setScroller={setScroller} />
            </IonFooter>
        </IonPage>
    );
};

export default HeroChat;
