import React from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement } from "chart.js";
import { DuelLedger } from '../../../models/duelsModels';
import moment from 'moment';

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const HeroOddsChart = (props: { duelLedger: DuelLedger[] }) => {
    const reversedLedger = [...props.duelLedger].reverse();
    const chartData = {
        labels: reversedLedger.map(item => moment(item.createdAt).format("MMM D, h:mm a")), // use createdAt for labels
        datasets: [
            {
                label: 'Hero Odds',
                data: reversedLedger.map(item => item.heroOdds ?? 0),
                fill: true,
                backgroundColor: 'rgba(75,192,192,0.4)',
                borderColor: 'rgba(75,192,192,1)',
            },
        ],
    };

    const options = {
        scales: {
            y: {
                ticks: {
                    callback: function (value: any) {
                        return (value * 100).toFixed(0) + '%'; // convert decimal to percentage
                    },
                    color: 'white', // set color of y-axis labels to white
                },
                beginAtZero: true,
            },
            x: {
                grid: {
                    drawOnChartArea: false, // only want the grid lines for one axis to show up
                },
                ticks: {
                    color: 'white', // set color of x-axis labels to white
                },
            },
        },
    };

    return <Line data={chartData} options={options} />;
};

export default HeroOddsChart;
