import { IonAvatar, IonCol, IonRow } from '@ionic/react'
import React, { useContext } from 'react'
import { DuelStatus } from '../../../models/duelsModels'
import GlobalState from '../../../contexts/GlobalState';
import HealthIndicator from './HealthIndicator';

const DuelStatusInfoCelebrationModal = (props: { duelStatus: DuelStatus }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject } = globalState;
    return (
        <>
            <IonRow>
                <IonCol size="5" style={{ textAlign: "center" }}>
                    <IonAvatar className="member-image" style={{ margin: "auto" }}>
                        <img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={heroObject?.heroImageURL ?? "/assets/images/mystery-avatar.png"} alt={heroObject?.heroName} />
                    </IonAvatar>
                </IonCol>
                <IonCol size="2" className="vs">
                    VS.
                </IonCol>
                <IonCol size="5" style={{ textAlign: "center" }}>
                    <IonAvatar className="member-image" style={{ margin: "auto" }}>
                        <img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.duelStatus.villainImageURL ?? "/assets/images/mystery-avatar.png"} alt={props.duelStatus.villainName} />
                    </IonAvatar>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="5" style={{ textAlign: "center" }}>
                    <div >
                        {heroObject?.heroName}
                    </div>
                </IonCol>
                <IonCol size="2" className="vs">
                </IonCol>
                <IonCol size="5" style={{ textAlign: "center" }}>
                    <div >
                        {props.duelStatus.villainName}
                    </div>
                </IonCol>
            </IonRow>
            <IonRow>
                <IonCol size="5" style={{ textAlign: "center" }}>
                    <HealthIndicator health={props.duelStatus.heroHealth} maxHealth={props.duelStatus.heroMaxHealth} />
                </IonCol>

                <IonCol size="2" className="vs">
                </IonCol>
                <IonCol size="5" style={{ textAlign: "center" }}>
                    <HealthIndicator health={props.duelStatus.villainHealth} maxHealth={props.duelStatus.villainMaxHealth} />
                </IonCol>
            </IonRow>
        </>
    )
}

export default DuelStatusInfoCelebrationModal