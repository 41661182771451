import { IonCol, IonRow } from "@ionic/react";
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from "chart.js";
import log from "loglevel";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { cardioColor, mindfulnessColor, mobilityColor, strengthColor } from "../../constants/theme";
import GlobalState from "../../contexts/GlobalState";
import "../../css/Stats.css";
import { ActivityLedger, cardioIcon, mindfulnessIcon, mobilityIcon, strengthIcon } from "../../models/exerciseModels";
import { Goal } from "../../models/goalsModels";
import { getSumPoints } from "../../services/ExerciseService";
import { findGoalByDates } from "../../services/GoalsService";
import { calculatePoints } from "../../services/HelperService";
// log.enableAll();
log.disableAll();

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Stats = (props: {
	startDate: string;
	endDate: string;
	setStartDate: React.Dispatch<React.SetStateAction<string>>;
	setEndDate: React.Dispatch<React.SetStateAction<string>>;
	activityLedger: ActivityLedger[];
}) => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const {
		//activityLedger,
		currentGoal,
		heroObject
	} = globalState;
	const [barChartData, setBarChartData] = useState<any>({ datasets: [], options: [] });
	const [strengthPoints, setStrengthPoints] = useState<Number[]>([]);
	const [cardioPoints, setCardioPoints] = useState<Number[]>([]);
	const [mobilityPoints, setMobilityPoints] = useState<Number[]>([]);
	const [mindfulnessPoints, setMindfulnessPoints] = useState<Number[]>([]);
	const [weeklyGoals, setWeeklyGoals] = useState<Goal | null>(currentGoal);

	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: "bottom" as const,
			},
		},
	};
	const labels = ["Mon", "Tues", "Weds", "Thurs", "Fri", "Sat", "Sun"];
	const data = {
		labels,
		datasets: [
			{
				label: strengthIcon,
				data: strengthPoints,
				backgroundColor: `${strengthColor}`,
			},
			{
				label: cardioIcon,
				data: cardioPoints,
				backgroundColor: `${cardioColor}`,
			},
			{
				label: mobilityIcon,
				data: mobilityPoints,
				backgroundColor: `${mobilityColor}`,
			},
			{
				label: mindfulnessIcon,
				data: mindfulnessPoints,
				backgroundColor: `${mindfulnessColor}`,
			},
		],
	};

	useEffect(() => { }, [strengthPoints, cardioPoints, mobilityPoints, mindfulnessPoints, props.activityLedger]);

	useEffect(() => {
		// log.debug("Finding goal for dates: ", props.startDate, props.endDate);
		findGoalByDates(props.startDate, props.endDate).then((goal) => {
			// log.debug("Goal found: ", goal);
			setWeeklyGoals(goal)
		})
	}, [props.startDate, props.endDate, props.activityLedger])

	useEffect(() => {
		// log.debug("Gettin sum of points for charts");
		getSumPoints(props.startDate, props.endDate, "strength", props.activityLedger).then((array) => {
			setStrengthPoints(array);
		});
		getSumPoints(props.startDate, props.endDate, "cardio", props.activityLedger).then((array) => {
			setCardioPoints(array);
		});
		getSumPoints(props.startDate, props.endDate, "mobility", props.activityLedger).then((array) => {
			setMobilityPoints(array);
		});
		getSumPoints(props.startDate, props.endDate, "mindfulness", props.activityLedger).then((array) => {
			setMindfulnessPoints(array);
		});
	}, [props.startDate, props.activityLedger, heroObject]);

	const backOneWeek = () => {
		props.setStartDate(moment(props.startDate).subtract(1, "week").format("YYYY-MM-DD"));
		props.setEndDate(moment(props.endDate).subtract(1, "week").format("YYYY-MM-DD"));
	};

	const forwardOneWeek = () => {
		props.setStartDate(moment(props.startDate).add(1, "week").format("YYYY-MM-DD"));
		props.setEndDate(moment(props.endDate).add(1, "week").format("YYYY-MM-DD"));
	};

	const weeklyPoints = calculatePoints(props.activityLedger, "weekly");

	const getPercentageChange = (currWeekValue: number, prevWeekValue: number) => {
		if (prevWeekValue === 0 && currWeekValue === 0) return "-";
		if (prevWeekValue === 0) return "+∞";
		if (currWeekValue === 0) return "-∞";

		let change = ((currWeekValue - prevWeekValue) / prevWeekValue) * 100;
		return `${change > 0 ? "+" : ""}${change.toFixed(0)}%`;
	};

	function calculateGoalCompletePercentage(weeklyPoints: number, goalPoints: number) {
		if (weeklyPoints === 0 || goalPoints === 0) return "-"
		let percentage = Math.floor((weeklyPoints / goalPoints) * 100);

		// Round the percentage to two decimal places
		// percentage = Math.floor(percentage * 100) / 100;

		// Add star emoji if the goal has been met or exceeded
		if (percentage >= 100) {
			return `${percentage.toFixed(0)}% 🌟`;
		}

		return `${percentage.toFixed(0)}%`;
	}

	const currentWeekFormat = moment(props.startDate).format("YYYY-[W]WW");
	const previousWeekFormat = moment(props.startDate).subtract(1, "weeks").format("YYYY-[W]WW");

	return (
		<>
			<div className="stats-date-selector-div">
				<span onClick={backOneWeek}>◀</span> {moment(props.startDate).format("MMM D")} - {moment(props.endDate).format("MMM D")}{" "}
				<span onClick={forwardOneWeek}>▶</span>
			</div>
			<Bar options={options} data={data} />
			<div className="stats-weekly-summary-div">
				<IonRow>
					<IonCol size="3">
						{strengthIcon} {weeklyPoints.strengthPoints[currentWeekFormat] || 0}
						{/* <br />
						<span className="stats-percentage-change">
							{weeklyGoals?.strengthGoalPoints ? <span><Emoji text=":dart:" />{weeklyGoals?.strengthGoalPoints}</span> : 0}
						</span>
						<br />
						<span className="stats-percentage-change">
							{calculateGoalCompletePercentage(weeklyPoints.strengthPoints[currentWeekFormat] || 0, weeklyGoals?.strengthGoalPoints || 0)}

						</span> */}
					</IonCol>
					<IonCol size="3">
						{cardioIcon} {weeklyPoints.cardioPoints[currentWeekFormat] || 0}
						{/* <br />
						<span className="stats-percentage-change">
							{weeklyGoals?.cardioGoalPoints ? <span><Emoji text=":dart:" />{weeklyGoals?.cardioGoalPoints}</span> : 0}
						</span>
						<br />
						<span className="stats-percentage-change">
							{calculateGoalCompletePercentage(weeklyPoints.cardioPoints[currentWeekFormat] || 0, weeklyGoals?.cardioGoalPoints || 0)}

						</span> */}
					</IonCol>
					<IonCol size="3">
						{mobilityIcon} {weeklyPoints.mobilityPoints[currentWeekFormat] || 0}
						{/* <br />
						<span className="stats-percentage-change">
							{weeklyGoals?.mobilityGoalPoints ? <span><Emoji text=":dart:" />{weeklyGoals?.mobilityGoalPoints}</span> : 0}
						</span>
						<br />
						<span className="stats-percentage-change">
							{calculateGoalCompletePercentage(weeklyPoints.mobilityPoints[currentWeekFormat] || 0, weeklyGoals?.mobilityGoalPoints || 0)}

						</span> */}
					</IonCol>
					<IonCol size="3">
						{mindfulnessIcon} {weeklyPoints.mindfulnessPoints[currentWeekFormat] || 0}
						{/* <br />
						<span className="stats-percentage-change">
							{weeklyGoals?.mindfulnessGoalPoints ? <span><Emoji text=":dart:" />{weeklyGoals?.mindfulnessGoalPoints}</span> : 0}
						</span>
						<br />
						<span className="stats-percentage-change">
							{calculateGoalCompletePercentage(weeklyPoints.mindfulnessPoints[currentWeekFormat] || 0, weeklyGoals?.mindfulnessGoalPoints || 0)}

						</span> */}
					</IonCol>
				</IonRow>
			</div>
		</>
	);
};

export default Stats;
