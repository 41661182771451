import { IonAlert, IonButton, IonContent, IonInput, IonItem, IonItemDivider, IonItemGroup, IonLabel, IonPage, IonSelect, IonSelectOption, IonTextarea } from "@ionic/react";
import log from "loglevel";
import Parse from 'parse';
import { useEffect, useRef, useState } from "react";
import { SIDEKICK_TABLE } from "../models/databaseModels";
import { SidekickDBObject } from "../models/sideKickModels";
import { sendSidekickMessage, updateSidekick } from "../services/SideKickService";

export const SidekickAdmin = () => {
    const [numberOfSidekicks, setNumberOfSidekicks] = useState(0)
    const [confirmUpdateInstructions, setConfirmUpdateInstructions] = useState(false)
    const [sidekicks, setSidekicks] = useState<any>([])
    const [instructions, setInstructions] = useState<string>('');
    const [selectedUserForMessage, setSelectedUserForMessage] = useState<SidekickDBObject>()
    const messageRef = useRef<HTMLIonInputElement | null>(null);

    useEffect(() => {
        getSidekicks()
    }, [])

    async function getSidekicks() {
        let query = new Parse.Query(SIDEKICK_TABLE);
        query.equalTo('active', true);
        query.notEqualTo('isAssistant', true);
        let result = (await query.find())
        if (!result) {
            log.debug(`[getNumberOfSidekicks] No sidekicks found`);
            alert(`No sidekicks found`);
            throw new Error(`No sidekicks found`);
        }
        let numberOfSidekicks = result.length
        let sks: SidekickDBObject[] = []
        result.forEach((sidekick: any) => {
            let sk: SidekickDBObject = sidekick.toJSON() as SidekickDBObject
            sks.push(sidekick.toJSON())
        })
        log.debug(`[getSidekicks] Sidekicks: `, JSON.stringify(sks))
        setSidekicks(sks)
        setNumberOfSidekicks(numberOfSidekicks)
    }

    async function updateInstructions(update: boolean) {
        if (update === false) {
            setConfirmUpdateInstructions(true);
        } else {
            sidekicks.forEach(async (sidekick: SidekickDBObject) => {
                let updatedSidekick = await updateSidekick(sidekick.sideKickId, instructions)
                log.debug(`[updateInstructions] updatedSidekick: `, updatedSidekick)
            })
        }
    }

    const handleTextAreaChange = (event: any) => {
        setInstructions(event.detail.value);
    };

    const handleSelectUserChange = (event: any) => {
        setSelectedUserForMessage(event.detail.value);
    };

    function handleKeyUpMessage(event: { keyCode: number }) {
        if (event.keyCode === 13) {
            if (messageRef.current) {
                sendTestMessage()
            }
        }
    }

    function sendTestMessage() {
        if (messageRef.current && messageRef.current.value && selectedUserForMessage) {
            console.log(`message: ${messageRef.current.value}`)
            sendSidekickMessage(messageRef.current!.value as string)
        }
    }

    return (
        <IonPage>
            <IonContent>
                <IonAlert isOpen={confirmUpdateInstructions} onDidDismiss={() => setConfirmUpdateInstructions(false)} header={'Confirm Update'} message={'Are you sure you want to update the instructions for all sidekicks?'} buttons={[{ text: 'Cancel', role: 'cancel', cssClass: 'secondary', handler: () => { } }, { text: 'Yes', handler: () => { updateInstructions(true) } }]} />
                <IonItemGroup>
                    <IonItemDivider className="settings-divider">
                        <IonLabel>Send Message to User</IonLabel>
                    </IonItemDivider>
                    <IonItem>
                        <div style={{ width: "100%" }}>Select User:</div>
                        <div>
                            <IonSelect placeholder='Select user' interface='action-sheet' name="selectUser" value={selectedUserForMessage} onIonChange={handleSelectUserChange} className="avatar-option-select">
                                {sidekicks.map((option: SidekickDBObject, index: number) => <IonSelectOption key={index} value={option}>{option.heroName}</IonSelectOption>)}
                            </IonSelect>
                        </div>
                    </IonItem>
                    <IonItem>
                        <div>Message to Send:</div>
                        <div style={{ display: "flex", alignItems: "center", width: "100%" }}><IonInput
                            style={{ background: 'lightgray', color: "black" }}
                            ref={messageRef}
                            onKeyUp={handleKeyUpMessage}
                            name="message"
                            type={"text"}
                            placeholder="text"
                            enterkeyhint="enter"
                        ></IonInput><IonButton onClick={() => sendTestMessage()}>▶</IonButton></div>
                    </IonItem>
                </IonItemGroup>
                <IonItemGroup>
                    <IonItemDivider className="settings-divider">
                        <IonLabel>Instructions Update</IonLabel>
                    </IonItemDivider>
                    <IonItem>
                        <div style={{ width: "100%" }}>Number of Sidekicks: {numberOfSidekicks}</div>
                        <div>
                            <IonButton onClick={() => updateInstructions(false)}>Update Instructions</IonButton>
                        </div>
                    </IonItem>
                    <IonItem>
                        <IonTextarea onIonChange={handleTextAreaChange}
                            value={instructions} style={{ background: 'lightgray', color: "black" }} placeholder="Instructions" rows={80} cols={20}>

                        </IonTextarea>
                    </IonItem>
                </IonItemGroup>
            </IonContent>
        </IonPage>
    )
}
