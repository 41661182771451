import {
    IonButton
} from "@ionic/react";
import log from 'loglevel';
import moment from 'moment';
import Parse from "parse";
import { useContext, useEffect, useState } from "react";
import { useHistory } from 'react-router';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import DuelStatusInfoCelebrationModal from '../Features/Duels/components/DuelStatusInfoCelebrationModal';
import GlobalState from "../contexts/GlobalState";
import "../css/Celebrations.css";
import { DUEL_STATUS_TABLE } from '../models/databaseModels';
import { DuelStatus, VillainGroupInstance, VillainGroupInstanceNode } from '../models/duelsModels';
import { Villain } from '../models/villainModels';
import { logGenericEventWithObject } from '../services/AnalyticsService';
import { calcDuelPointsFromDifficulty, getVillainGroupInstanceByInstanceId } from '../services/DuelsServics';
import { getVillainFromGroupInstance } from '../services/VillainServices';
import DefeatAnimation from './DefeatAnimation';
import DefeatAnimationGroup from './DefeatAnimationGroup';
import LastMessage from './LastMessage';
import MiniLeaderboard from "./MiniLeaderBoard";
import { getPersonalPronoun, getThirdPersonPronoun } from "../services/HelperService";


const DuelResultCelebration = (props: { duelStatus: DuelStatus, dismissFunction: any }) => {
    const history = useHistory();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, isMobile } = globalState;
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass>();
    const [currentGroup, setCurrentGroup] = useState<VillainGroupInstance>();
    const [groupDefeated, setGroupDefeated] = useState<VillainGroupInstance>();
    const [defeatedVillains, setDefeatedVillains] = useState<Villain[]>([]);
    const [villainGroupInstanceId, setVillainGroupInstanceId] = useState<string>("");
    const [showModal, setShowModal] = useState({ show: false, villain: {} as Villain, available: false, villainGroupInstanceId: "" });

    useEffect(() => {
        setDuelCelebratedToTrue();
        logGenericEventWithObject("duel result celebration", props.duelStatus);
    }, []);

    async function setDuelCelebratedToTrue() {
        let query = new Parse.Query(DUEL_STATUS_TABLE);
        query.equalTo('statusId', props.duelStatus.statusId);
        let result = await query.first().catch((error: any) => {
            log.debug("[setDuelCelebratedToTrue] Error: " + error.code + " " + error.message);
            throw error;
        })
        if (!result) {
            log.debug(`[setDuelCelebratedToTrue] No duel found for statusId ${props.duelStatus.statusId}`);
            return null;
        }
        let resultJSON = result.toJSON();
        log.debug(`[setDuelCelebratedToTrue] resultJSON: `, resultJSON);
        if (resultJSON.villainGroupInstanceId !== "" && resultJSON.villainGroupInstanceId !== undefined) {
            let villainGroupInstanceId = resultJSON.villainGroupInstanceId
            setVillainGroupInstanceId(villainGroupInstanceId);
            log.debug(`[setDuelCelebratedToTrue] villainGroupInstanceId: ${villainGroupInstanceId}`);
            showNextVillain(resultJSON as unknown as DuelStatus, villainGroupInstanceId!)
        }
        result.set({ celebrated: true });
        result = await result.save();
        if (!result) {
            log.debug(`[setDuelCelebratedToTrue] Error updating duel ID for statusId ${props.duelStatus.statusId}`);
            return null;
        }

        log.debug('[setDuelCelebratedToTrue] DB result: ', resultJSON);
    }

    async function showNextVillain(duelStatus: DuelStatus, villainGroupInstanceId: string) {
        // if so, is there another villain in the group?
        // get the villainId from the recent duel
        let villainId = duelStatus.villainID
        // log.debug(`villainId: `, villainId);
        // get the villainGroupInstance
        let villainGroupInstance: VillainGroupInstance = await getVillainGroupInstanceByInstanceId(villainGroupInstanceId)
        // log.debug(`villainGroupInstance: `, villainGroupInstance);
        // get the nodeId of the villain within the group
        let villainNode: VillainGroupInstanceNode | undefined = villainGroupInstance.villains.find(villain => villain.villainId === villainId)
        // if (villainNode) villainNode.difficulty = "boss"
        if (villainNode && villainNode.difficulty !== "boss") {
            setCurrentGroup(villainGroupInstance)
        } else {
            if (villainNode?.difficulty === "boss") {
                setGroupDefeated(villainGroupInstance)
                const villainPromises = villainGroupInstance.villains.map(async villain => {
                    if (villain) {
                        return await getVillainFromGroupInstance(villainGroupInstanceId, villain.villainId);
                    }
                    return null;
                });
                // Wait for all promises to resolve
                const villains = await Promise.all(villainPromises);
                if (villains.length > 0) {
                    setDefeatedVillains(villains.filter(villain => villain !== null) as Villain[]);
                }
            }
        }
    }

    async function navToGroup() {
        history.push(`/duels/duelprogression/${currentGroup?.villainGroupId}`)
        props.dismissFunction()
    }

    return (
        <>
            <div className="celebration-container">
                <div className="celebration-swiper">
                    <Swiper
                        spaceBetween={50}
                        modules={[Navigation]}
                        slidesPerView={1}
                        navigation
                        pagination={{ type: "bullets" }}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        autoHeight={true}
                    >
                        {props.duelStatus.winner === "hero" && (
                            <>
                                {/* Defeat celebration slide */}
                                <SwiperSlide>
                                    <div className="celebration-top-area">
                                        {props.duelStatus.villainImageURL && (
                                            <DefeatAnimation bossImage={props.duelStatus.villainImageURL} />
                                        )}
                                    </div>
                                    <div className="celebration-middle-area">
                                        <div className="celebration-middle-header">{heroObject?.heroName} has defeated {props.duelStatus.villainName}</div>
                                    </div>

                                    <div className="celebration-bottom-area">
                                        Swipe ➡
                                    </div>
                                </SwiperSlide>

                                {/* Update ranking slide */}
                                <SwiperSlide>
                                    <UpdateRanking duelStatus={props.duelStatus} dismissFunction={props.dismissFunction} />
                                </SwiperSlide>

                                {/* Group defeat celebration slide */}
                                {groupDefeated && defeatedVillains && (
                                    <SwiperSlide>
                                        {/* <Confetti height={window.innerHeight} numberOfPieces={1500} recycle={false} /> */}
                                        <div className="celebration-top-area">
                                            <DefeatAnimationGroup defeatedVillains={defeatedVillains} />
                                        </div>
                                        <div className="celebration-middle-area">
                                            <div className="celebration-middle-header">{heroObject?.heroName} has defeated {groupDefeated.groupName}</div>
                                        </div>
                                        <div className="celebration-bottom-area">
                                            <div>
                                                Congrats on helping {getThirdPersonPronoun(heroObject?.gender)} taking down the whole group!
                                            </div>
                                            <IonButton routerLink={`/duels/progression`} onClick={() => props.dismissFunction()}>See the next group</IonButton>
                                            Swipe ➡
                                        </div>
                                    </SwiperSlide>
                                )}

                                {/* Last message slide */}
                                <SwiperSlide>
                                    <div className="celebration-top-area">
                                        <LastMessage villainImage={props.duelStatus.villainImageURL} duelId={props.duelStatus.statusId} />
                                    </div>
                                    <div className="celebration-middle-area">
                                        <div className="celebration-middle-header">Last Message</div>
                                    </div>
                                    <div className="celebration-bottom-area">
                                        Swipe ➡
                                    </div>
                                </SwiperSlide>

                                {/* Final results slide */}
                                <SwiperSlide>
                                    <div className="celebration-top-area">
                                        <DuelStatusInfoCelebrationModal duelStatus={props.duelStatus} />
                                    </div>
                                    <div className="celebration-middle-area">
                                        <div className="celebration-middle-header">Final Results</div>
                                        <IonButton routerLink={`/duelreport/${props.duelStatus.statusId}`} onClick={() => props.dismissFunction()}>See Full Stats</IonButton>
                                    </div>
                                    <div className="celebration-bottom-area">
                                        Swipe ➡
                                    </div>
                                </SwiperSlide>

                                {/* Current group slide */}
                                {currentGroup && (
                                    <SwiperSlide>
                                        <div className="celebration-top-area">
                                            <div className='celebration-next-villains'>
                                                <div
                                                    className={`group-node-celebration`}
                                                    onClick={() => navToGroup()}
                                                >
                                                    <div className='group-name'>{currentGroup.groupName}</div>
                                                    <img src={currentGroup.groupImage} className={`villainGroup-image-node`} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="celebration-middle-area">
                                            <div className="celebration-middle-header">See who's up next</div>
                                        </div>

                                        <div className="celebration-bottom-area">
                                            <div>
                                                Tap to start your next duel
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </>
                        )}
                        {props.duelStatus.winner === "villain" && heroObject && heroObject.heroImageURL && (
                            <>
                                <SwiperSlide>
                                    <div className="celebration-top-area">
                                        {props.duelStatus.villainImageURL && (
                                            <DefeatAnimation bossImage={heroObject?.heroImageURL} />
                                        )}
                                    </div>
                                    <div className="celebration-middle-area">
                                        <div className="celebration-middle-header">You were defeated by {props.duelStatus.villainName}</div>
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="celebration-top-area">
                                        <LastMessage villainImage={props.duelStatus.villainImageURL} duelId={props.duelStatus.statusId} />
                                    </div>
                                    <div className="celebration-middle-area">
                                        <div className="celebration-middle-header">Last Message</div>
                                    </div>
                                    <div className="celebration-bottom-area">
                                        Swipe ➡
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="celebration-top-area">
                                        <DuelStatusInfoCelebrationModal duelStatus={props.duelStatus} />
                                    </div>
                                    <div className="celebration-middle-area">
                                        <div className="celebration-middle-header">Final Results</div>
                                    </div>
                                    <div className="celebration-bottom-area">
                                        <IonButton routerLink={`/duelreport/${props.duelStatus.statusId}`} onClick={() => props.dismissFunction()}>See Full Stats</IonButton>
                                    </div>
                                </SwiperSlide>
                                {currentGroup && (
                                    <SwiperSlide>
                                        <div className="celebration-top-area">
                                            <div className='celebration-next-villains'>
                                                <div
                                                    className={`group-node-celebration`}
                                                    onClick={() => navToGroup()}
                                                >
                                                    <div className='group-name'>{currentGroup.groupName}</div>
                                                    <img src={currentGroup.groupImage} className={`villainGroup-image-node`} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="celebration-middle-area">
                                            <div className="celebration-middle-header">Don't go out like that!</div>
                                        </div>

                                        <div className="celebration-bottom-area">
                                            <div>
                                                Tap to battle {props.duelStatus.villainName} again
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )}
                            </>

                        )}
                    </Swiper>

                </div>

            </div>
        </>

    )
}

export default DuelResultCelebration

const UpdateRanking = (props: { duelStatus: DuelStatus, dismissFunction: any }) => {
    const [points, setPoints] = useState<number>(0);

    useEffect(() => {
        calcDuelPointsFromDifficulty(props.duelStatus.difficulty!).then((points) => {
            setPoints(points);
        });
    }, []);

    return (
        <>
            {points && (
                <>
                    <div className="celebration-top-area">
                        Earned by defeating {props.duelStatus.villainName} on {props.duelStatus.difficulty} difficulty
                    </div>
                    <div className="celebration-middle-area">
                        <div className="celebration-middle-header duel-points-display">+{points} duel points</div>
                    </div>

                    <div className="celebration-bottom-area" style={{ marginTop: "40px" }}>
                        Current FitHero Leaderboard
                        <div style={{ width: "70%", margin: "auto" }}>
                            <MiniLeaderboard />
                        </div>
                        <IonButton style={{ marginTop: "40px" }} routerLink={`/duels/leaderboard`} onClick={() => props.dismissFunction()} color={"success"}>View Leaderboard</IonButton>
                    </div>
                </>
            )
            }

        </>
    )

}