import { SidekickMessage } from "./messageModels"

export const sidekickDisplayMessagesKey = "sideKickDisplayMessages";
export const sidekickThreadObjectKey = "sideKickThreadObject";
export const sidekickDBObjectKey = "sideKickDBObject";
export const assistantDisplayMessagesKey = "assistantDisplayMessages";
export const assistantThreadObjectKey = "assistantThreadObject";
export const assistantDBObjectKey = "assistantDBObject";

export const excludedText = [
    "###",
    "Please introduce yourself and tell me about what you can do for me in the next 2-3 sentences.",
]

export const sidekickIntroMessage = "My name is ${hero.heroName} and I am your FitHero. Please introduce yourself and tell me about what you can do for me in the next 2-3 sentences.";
export const assistantIntroMessage = "Hello SportsScience AI. My name is Skylar. Please greet me and tell me what you can do for me.";

export interface RunObject {
    id: string;
    object: string;
    created_at: number;
    assistant_id: string;
    thread_id: string;
    status: string;
    started_at: null | number;
    expires_at: null | number;
    cancelled_at: null | number;
    failed_at: null | number;
    completed_at: null | number;
    required_action: null | string;
    last_error: null | string;
    model: string;
    instructions: string;
    tools?: Array<{
        type: string;
    }>;
    file_ids?: string[];
    metadata?: {};
    temperature: number;
    usage: null | any; // You might want to specify a more detailed type based on the structure of the usage object, if available.
}

export type SidekickDBObject = {
    objectId?: string;
    updatedAt?: string;
    createdAt?: string;
    userId: string;
    heroName?: string;
    sideKickName: string;
    sideKickId: string;
    sideKickThreadId: string;
    sideKickImage: string;
    active?: boolean;
    isAssistant?: boolean;
}

export type SidekickThreadObject = {
    id: string,
    object: string,
    created_at: number,
    metadata: {
        userId: string,
        sideKickId: string,
        sideKickName: string,
        imageUrl?: string,
    },
}

export type SidekickMessageFullObject = {
    object: string,
    first_id: string,
    last_id: string,
    has_more: boolean,
    data: SidekickMessage[],
    file_ids: string[];
    assistant_id: string;
    run_id: string;
    metadata: {};
}

export type SidekickObject = {
    threadObject: SidekickThreadObject,
    message: SidekickMessage,
}

export type SidekickTypeObject = {
    sidekickTypeId: number,
    sidekickTypeName: string,
    sidekickDescription: string,
    available: boolean,
    premium: boolean,
    sidekickName: string,
    baseImage: string,
    sidekickPersonality: string,
}

export const drillSergeantNames = [
    "Sergeant Steel",
    "Major Motivator",
    "Captain Commando",
    "Lieutenant Lightning",
    "Drill Master Dash",
    "Sergeant Surge",
    "Commander Courage",
    "Boot Camp Boss",
    "Private Pusher",
    "Iron Will Instructor",
    "Colonel Courage",
    "General Grit",
    "Tough Love Leader",
    "Sergeant Spark",
    "Captain Crunch",
    "Major Muscle",
    "Admiral Action",
    "Taskmaster Titan",
    "Drill Dynamo",
    "Ironclad Ivan",
    "Sergeant Stamina",
    "Captain Core",
    "Lieutenant Lift",
    "Commander Challenge",
    "Major Momentum",
    "Drill Director",
    "Sergeant Stamina",
    "Corporal Climb",
    "Boot Boss",
    "Fitness Frontman",
    "Gunnery Grit",
    "Captain Charge",
    "Brigadier Burn",
    "Colonel Command",
    "Master Motive",
    "Sarge Spark",
    "Drill Demon",
    "Private Power",
    "Cadence Commander",
    "Captain Combat",
    "Force Field Frank",
    "Gunnery Grind",
    "Major Max",
    "Lieutenant Leap",
    "Commando Creed",
    "Sergeant Stride",
    "Drill Devotee",
    "Captain Courageous",
    "Private Progress",
    "Marshal Might"
];


