// ****************************************************************************
// COMMENT OUT THIS SECTION AND THE LAST SECTION WHEN DEPLOYING
import { useEffect } from 'react';
import {
    HeroObject
} from "../models/userModel";
import { Villain } from '../models/villainModels';
import { getUser_id } from '../services/UserService';
import log from 'loglevel';
const Parse = require('parse');


export const TestParse = () => {

    useEffect(() => {
        // getVillains()
        getActivities()
        // createVillain()
        // getHero()
    }, [])
    async function getVillains() {
        const query = new Parse.Query('villains');
        const parseObjects = (await query.find())
        let villains: Villain[] = []
        parseObjects.forEach((parseObject: any) => {
            // if parseObject.get('name') does not contain ** then push it to the villains array
            if (!parseObject.get('name').includes('**')) {
                villains.push(parseObject.toJSON())
            }
        })
        log.debug(`[getVillain] villains: `, villains);
    }
    async function getActivities() {
        const query = new Parse.Query('activities');
        query.limit(100);
        const parseObjects = (await query.find())
        let activities: any[] = []
        parseObjects.forEach((parseObject: any) => {
            activities.push(parseObject)
        })
        log.debug(`[getActivities] activities: `, activities);
    }
    async function getHero() {
        try {
            // create a new Parse Object instance
            const query = new Parse.Query('heroes');
            // define the attributes you want for your Object
            query.equalTo('heroID', '649dbe5459a3f9541569c6a0');
            const villain: HeroObject = (await query.find())[0].toJSON();
            log.debug('Hero found', villain);
        } catch (error) {
            log.debug('Error saving new person: ', error);
        }
    }

    async function createVillain() {
        const params1 = {
            name: '** TEST FROM CLIENT*** Atlas Stoneheart',
            imageURL: 'https://c5-na.altogic.com/_storage/645ad37d7ea57f56fe00c007/64b23300c84a89138270d3df/65120b984877e503a62b4771',
            description: "Atlas Stonehart stands as a colossal figure, carved from the oldest and most resilient rock known to mankind. Each contour and chisel mark on his form speaks of ages gone by. Standing at an impressive ten feet, his appearance is reminiscent of the titanic statues of ancient civilizations. His eyes, though stone, gleam with an intelligence and intensity that belies his rigid form. When he moves, the ground quivers, yet there's an elegance to his motion, a dance of millennia in every step.",
            language_level: 1,
            strengthLevel: 40,
            cardioLevel: 1,
            mobilityLevel: 1,
            mindfulnessLevel: 32,
            distraction_method: "Although his exterior is impenetrable and cold, deep within the layers of his rocky form lies a core of molten passion, memories of a time when he was part of the earth's fiery heart. This magma core not only fuels his strength but provides him warmth in the coldest of battles. Surprisingly, Atlas possesses an intricate understanding of emotions, having observed humanity for countless generations. His strength isn't just physical; his wisdom and patience are unparalleled. However, his greatest fear is erosion, the slow decay of time which even he isn't immune to. While water might seem a trivial threat to others, for Atlas, it represents time's relentless pursuit, a reminder of the impermanence of even the mightiest.",
            example_response: "You may chip at my exterior, but you'll never break my resolve, for I've withstood the tests of time itself.",
        }
        const villain = await Parse.Cloud.run('createVillain', params1);
        log.debug('Villain created', villain);
        log.debug('Villain attributes', villain.attributes);
    }

    return (
        <div>testServerCode</div>
    )
}

const updateUser = async (updateData: Object) => {
    let userID = await getUser_id();
    let query = new Parse.Query('User');
    query.equalTo('userID', userID);
    let result = await query.first().catch((error: any) => {
        log.debug("[updateUser] Error: " + error.code + " " + error.message);
        throw error;
    })
    if (!result) {
        log.debug(`[updateUser] No user found for userID ${userID}`);
        return null;
    }
    result.set(updateData);
    result = await result.save();
    if (!result) {
        log.debug(`[updateUser] Error updating user for userID ${userID}`);
        return null;
    }
    let resultJSON = result.toJSON();
    log.debug('[updateUser] User updated: ', resultJSON);
};


export default TestParse