import { BaselineStats, EmptyAvatarImage, HeroObject, SettingsObject, UserObject, UserPoints, allHeroesObjectKey, heroObjectKey, lastUpdatedAllHeroesKey, userObjectKey } from "../models/userModel";
import { getFullDeviceInfo } from "./HelperService";

import moment from "moment";
import Parse from 'parse';
import { v4 as uuidv4 } from "uuid";
import { ACTIVITIES_TABLE, BASELINE_STATS_TABLE, HEROES_TABLE, INVITE_CODE_TABLE, JUNE_CHALLENGE_POINT_LEDGER_TABLE, USERS_TABLE, USER_POINTS_TABLE } from "../models/databaseModels";
import { DuelStatus } from "../models/duelsModels";
import { logGenericEventWithObject, mixpanelSetHeroProperties } from "./AnalyticsService";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";
import { StorageService } from "./StorageService";
import log from "loglevel";

let storageService = new StorageService();

export const getUser_id = async (): Promise<string> => {
	let user = await storageService.getObject(userObjectKey);
	let userID = user.userID ? user.userID : user._id ? user._id : null;
	return userID;
};

export const getUser = async (): Promise<UserObject | null> => {
	let userObject = await storageService.getObject(userObjectKey);
	if (userObject === null) {
		return null;
	}
	if (userObject.deviceInfo === undefined) {
		let deviceInfo = await getFullDeviceInfo();
		userObject.deviceInfo = deviceInfo;
		storageService.setObject(userObjectKey, userObject);
	}
	return userObject;
};


export const loginUser = async (email: any, password: any) => {
	let user = await Parse.User.logIn(email, password).catch(error => {
		log.debug(`[loginUser] Error trying to login user: `, error);
		throw new Error(error.message);
	});
	// log.debug(`[loginUser] User: `, user)
	let userObject = user.toJSON();
	// userObject._id = userObject.userID;
	// log.debug(`[loginUser] User Object: `, userObject);
	return userObject
};

export async function getHero(heroID?: string) {
	if (!heroID) {
		let userObject: UserObject = await storageService.getObject(userObjectKey);
		// log.debug(`[getHero] User Object: `, JSON.stringify(userObject));
		heroID = userObject.heroID;
	}
	let query = new Parse.Query(HEROES_TABLE);
	query.equalTo('heroID', heroID);
	let result = await query.first().catch((error) => {
		sendSlackErrorNotification(`[getHero] Full Error Obj`, "getHero", `${error}`);
		log.error(`[getHero] Full Error Obj: `, error);
		throw error;
	})
	if (!result) {
		log.debug(`[getHero] No hero found for heroID ${heroID}`);
		log.debug(`[getHero] Creating temp hero`);
		let hero = await createHero("temp", EmptyAvatarImage)
		if (!hero) {
			log.debug(`[getHero] Error creating temp hero`);
			throw new Error(`Error creating temp hero`);
		} else {
			return hero
		}
	}
	let resultJSON = result.toJSON();
	// log.debug('[getHero] Hero found: ', resultJSON);
	await storageService.setObject(heroObjectKey, resultJSON);
	return resultJSON as unknown as HeroObject;
}

export async function getUserByHeroID(heroID: string) {
	let query = new Parse.Query(USERS_TABLE);
	query.equalTo('heroID', heroID);
	let result = await query.first().catch((error) => {
		log.debug("[getUserByHeroID] Error: " + error.code + " " + error.message);
		throw error;
	})
	if (!result) {
		log.debug(`[getUserByHeroID] No user found for heroID ${heroID}`);
		throw new Error(`No user found for heroID ${heroID}`);
	}
	let resultJSON = result.toJSON();
	log.debug('[getUserByHeroID] User found: ', resultJSON);
	return resultJSON as unknown as UserObject;
}

export async function getAllUsers(refresh: boolean = false): Promise<UserObject[]> {
	const query = new Parse.Query(USERS_TABLE);
	const userParseObjects = (await query.find())
	let users: UserObject[] = []
	userParseObjects.forEach((parseObject: any) => {
		let user = parseObject.toJSON();
		if (user.email && !user.email.includes("@example")) {
			users.push(user)
		}
	})
	return users
}

export async function getAllPointTotals(): Promise<UserPoints[]> {
	const query = new Parse.Query(USER_POINTS_TABLE);
	const parseObjects = (await query.find())
	let userPoints: UserPoints[] = []
	parseObjects.forEach((parseObject: any) => {
		userPoints.push(parseObject.toJSON())
	})
	return userPoints
}

export async function getAllHeroes(refresh: boolean = false): Promise<HeroObject[]> {
	let lastUpdated = await storageService.getItem(lastUpdatedAllHeroesKey)
	if (lastUpdated && moment(lastUpdated).isAfter(moment().subtract(3, "days")) && refresh !== true) {
		// Return cached heroes unless refresh is true or last updated is more than 3 days ago
		log.debug(`[getAllHeroes] Returning cached heroes`);
		let heroes = await storageService.getObject(allHeroesObjectKey);
		return heroes
	}
	// Get all the heroes from the database
	log.debug(`[getAllHeroes] Refreshing heroes`);
	const query = new Parse.Query(HEROES_TABLE);
	query.notEqualTo('heroName', "temp");
	query.greaterThanOrEqualTo('lastUpdated', moment().subtract(30, "days").format());
	query.greaterThan('totalPoints', 0);
	const heroParseObjects = (await query.find())
	let heroes: HeroObject[] = []

	// Get all the users from the database
	let allUsers: UserObject[] = await getAllUsers();
	// log.debug(`[getAllHeroes] All Users: `, allUsers);

	// Get all point totals for each user
	let allUserPoints: UserPoints[] = await getAllPointTotals();
	// log.debug(`[getAllHeroes] All User Points: `, allUserPoints);

	heroParseObjects.forEach((parseObject: any) => {
		let hero = parseObject.toJSON();
		hero.userId = allUsers.find(user => user.heroID === hero.heroID)?.userID;
		// log.debug(`[getAllHeroes] Hero userid: `, hero.userId);
		hero.totalDuelPoints = allUserPoints.find(user => user.userId === hero.userId)?.points;
		// log.debug(`[getAllHeroes] Hero totalDuelPoints: `, hero.totalDuelPoints);
		heroes.push(parseObject.toJSON())
	})
	await storageService.setObject(allHeroesObjectKey, heroes);
	await storageService.setItem(lastUpdatedAllHeroesKey, moment().format());
	return heroes
}

export interface ChallengeLedgerObject {
	awardedAt: string;
	event: string;
	villainName?: string;
	userId: string;
	points: number;
	referredUserId?: string;
}

export async function getChallengeLedger(userId: string): Promise<ChallengeLedgerObject[]> {
	const query = new Parse.Query(JUNE_CHALLENGE_POINT_LEDGER_TABLE);
	query.limit(10000);
	query.descending('awardedAt');
	query.equalTo('userId', userId);
	const parseObjects = (await query.find())
	let ledger: ChallengeLedgerObject[] = []
	parseObjects.forEach((parseObject: any) => {
		ledger.push(parseObject.toJSON())
	})
	return ledger
}

export interface ChallengeLeaderboardObject {
	userId: string;
	heroID: string;
	heroName: string;
	challengePoints: number;
	heroImageURL: string;
}

export async function getChallengeLeaderboard(): Promise<ChallengeLeaderboardObject[]> {
	const leaderboard: ChallengeLeaderboardObject[] = await Parse.Cloud.run("getChallengeLeaderboard").catch((error) => {
		log.debug(`[getLeaderboard] Error: `, error);
		throw error;
	});
	log.debug(`[getLeaderboard] Leaderboard: `, leaderboard);
	return leaderboard
}

export async function getLeaderboard(): Promise<HeroObject[]> {
	const leaderboard: HeroObject[] = await Parse.Cloud.run("createLeaderboard").catch((error) => {
		log.debug(`[getLeaderboard] Error: `, error);
		throw error;
	});
	// log.debug(`[getLeaderboard] Leaderboard: `, leaderboard);
	return leaderboard
}

export async function getHeroFromDuelStatus(duelStatus: DuelStatus) {
	let userObject: UserObject = await storageService.getObject(userObjectKey);
	// log.debug(`[getHeroFromDuelStatus] User Object: `, JSON.stringify(userObject));
	let heroObject: HeroObject = await getHero()
	// log.debug(`[getHeroFromDuelStatus] Hero Object: `, heroObject);
	heroObject = {
		...heroObject,
		heroToHit: duelStatus.heroToHit,
		heroDamage: duelStatus.heroDamage,
		heroHealth: duelStatus.heroHealth,
		heroDodge: duelStatus.heroDodge,
	};
	return heroObject;
}

export const createUser = async (email: string, password: string): Promise<UserObject> => {
	let userObj = new Parse.User();
	let userID = uuidv4()
	let settings: SettingsObject = {
		sendChatNotifications: true,
		sendDuelNotifications: true,
		sendWeeklyEmails: true,
		promptToDuelInApp: true,
		showInAppNotifications: true,
	}
	let timezone = moment.tz.guess()
	userObj.set("username", email);
	userObj.set("password", password);
	userObj.set("email", email);
	userObj.set("userID", userID);
	userObj.set("settings", settings)
	userObj.set("timezone", timezone)

	let user: UserObject = {} as UserObject;

	await userObj.signUp().then((parseUser: any) => {
		parseUser = parseUser.toJSON();
		log.debug('User created: ', parseUser);
		user = {
			userID: parseUser.userID,
			email: parseUser.email,
			password: password,
			createdAt: parseUser.createdAt,
		}
	}).catch(function (error) {
		log.debug("Error: " + error.code + " " + error.message);
		log.debug("Error: " + error);
		throw error;
	});
	if (user === null) {
		log.debug("Error: user is null");
		throw Error;
	}
	return user
};

export const createUserNoEmailNoPass = async (heroID: string): Promise<UserObject> => {
	let userObj = new Parse.User();
	let userID = uuidv4()
	let settings: SettingsObject = {
		sendChatNotifications: true,
		sendDuelNotifications: true,
		sendWeeklyEmails: true,
		promptToDuelInApp: true,
		showInAppNotifications: true,
	}
	let timezone = moment.tz.guess()
	if (timezone === "America/Toronto") throw Error("No people from Toronto allowed")
	userObj.set("userID", userID);
	userObj.set("username", userID);
	userObj.set("password", userID);
	userObj.set("settings", settings)
	userObj.set("timezone", timezone)
	userObj.set("heroID", heroID)


	let user: UserObject = {} as UserObject;

	await userObj.signUp().then((parseUser: any) => {
		parseUser = parseUser.toJSON();
		log.debug('User created: ', parseUser);
		user = parseUser
	}).catch(function (error) {
		log.debug("Error: " + error.code + " " + error.message);
		log.debug("Error: " + error);
		throw error;
	});
	if (user === null) {
		log.debug("Error: user is null");
		throw Error;
	}
	return user
};



export const updateUser = async (updateData: Object): Promise<UserObject | null> => {
	let userID = await getUser_id();
	let query = new Parse.Query(USERS_TABLE);
	query.equalTo('userID', userID);
	let result = await query.first().catch((error) => {
		log.debug("[updateUser] Error: " + error.code + " " + error.message);
		sendSlackErrorNotification(`[updateUser] Error in query`, "updateUser", `${error}`);
		throw error;
	})
	if (!result) {
		log.debug(`[updateUser] No user found for userID ${userID}`);
		sendSlackErrorNotification(`[updateUser] No user found for userID`, "updateUser", `${userID}`);
		return null;
	}
	result.set(updateData);
	result = await result.save();
	if (!result) {
		log.debug(`[updateUser] Error updating user for userID ${userID}`);
		sendSlackErrorNotification(`[updateUser] Error updating user for userID`, "updateUser", `${userID}`);
		return null;
	}
	let resultJSON = result.toJSON();
	// log.debug('[updateUser] User updated: ', resultJSON);
	await storageService.setObject(userObjectKey, resultJSON);
	return resultJSON as unknown as UserObject;
};

export const refreshUserObject = async (): Promise<UserObject | null> => {
	// log.debug(`[refreshUserObject] Refreshing user object`);
	let userID = await getUser_id();
	if (!userID) {
		log.debug(`[refreshUserObject] No userID found`);
		return null;
	}
	await updateUserTimezone()
	const query = new Parse.Query(USERS_TABLE);
	query.equalTo('userID', userID);
	let result = await query.find();
	if (result.length === 0) {
		log.debug(`[refreshUserObject] No user found for userID ${userID}`);
		return null;
	}
	let resultJSON = result[0].toJSON();
	// resultJSON._id = resultJSON.userID;
	await storageService.setObject(userObjectKey, resultJSON);
	return resultJSON as unknown as UserObject;
};

export const getBaselineStats = async (): Promise<BaselineStats> => {
	const query = new Parse.Query(BASELINE_STATS_TABLE);
	query.equalTo('current', true);
	let result = await query.find();
	if (result.length === 0) {
		log.debug(`[getBaselineStats] No active baseline stats found`);
		throw new Error(`No active baseline stats found`);
	}
	let resultJSON = result[0].toJSON();
	return resultJSON as unknown as BaselineStats;
};

export const createHero = async (heroName: string, heroImageURL: string): Promise<HeroObject | null> => {
	let baseline_stats = await getBaselineStats().catch((error) => {
		log.debug(`[createHero] Error trying to get baseline stats: `, error);
		throw error;
	});
	let heroData: HeroObject = {
		heroID: uuidv4(),
		heroName: heroName,
		heroToHit: baseline_stats?.accuracy,
		heroDamage: baseline_stats?.damage,
		heroDodge: baseline_stats?.dodge,
		heroHealth: baseline_stats?.health,
		heroImageURL: heroImageURL,
		cardioLevel: 1,
		cardioPoints: 0,
		mindfulnessLevel: 1,
		mindfulnessPoints: 0,
		mobilityLevel: 1,
		mobilityPoints: 0,
		strengthLevel: 1,
		strengthPoints: 0,
		overallLevel: 1,
		totalPoints: 0,
		lastUpdated: moment().format(),
	};

	let userObj = new Parse.Object(HEROES_TABLE);
	userObj.set(heroData)
	let result = await userObj.save().catch(function (error) {
		log.debug("Error: " + error.code + " " + error.message);
		log.debug("Error: " + error);
		throw error;
	});
	let resultJSON = result.toJSON();
	// log.debug('Hero created: ', resultJSON);
	// await updateUser({ heroID: heroData.heroID })
	await storageService.setObject(heroObjectKey, resultJSON);
	return resultJSON as unknown as HeroObject;
};

export const getRandomUser = async (results = 1) => {
	const url = "https://randomuser.me/api/?results=" + results;
	const response = await Promise.resolve(
		fetch(url, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		})
	).catch((error) => {
		log.debug("[getRandomUser] Error in API call: " + error);
		throw Error(error);
	});
	return response
		.json()
		.then((data) => {
			return data;
		})
		.catch((error) => {
			log.debug("[getRandomUser] Error in JSON conversion: " + error);
			throw Error(error);
		});
};

export async function awardInvitePoints(userId: string, referredUserId: string) {
	const pointsForInvite = 50;
	let ledgerRow = new Parse.Object(JUNE_CHALLENGE_POINT_LEDGER_TABLE);
	ledgerRow.set("userId", userId);
	ledgerRow.set("points", pointsForInvite);
	ledgerRow.set("event", "invite");
	ledgerRow.set("awardedAt", moment().format());
	ledgerRow.set("referredUserId", referredUserId);
	let result = await ledgerRow.save().catch((error) => {
		log.debug(`[awardInvitePoints] Error saving ledgerRow: `, error);
		throw error;
	});
	let resultJSON = result.toJSON();
	logGenericEventWithObject("invite points awarded", resultJSON);
	sendSlackNotification(`*[Invite Points]*\n${pointsForInvite} points awarded to user ${userId} for inviting user ${referredUserId}`, 'creation');
	log.debug('Points added: ', resultJSON);
}

export async function validateInviteCode(code: string, newUserId: string) {
	let validCode = false;
	let query = new Parse.Query(INVITE_CODE_TABLE);
	query.equalTo('code', code);
	let result = await query.first().catch((error) => {
		log.debug("[validateInviteCode] Error: " + error.code + " " + error.message);
		throw error;
	})
	// log.debug(`[validateInviteCode] Result: `, result);
	if (!result) {
		log.debug(`[validateInviteCode] No invite code found for code ${code}`);
		return validCode;
	}
	validCode = true;
	// Give credit to the user in the points table
	awardInvitePoints(result.get("userId"), newUserId);
	return validCode;
}

export async function validateEmail(email: string) {
	let validEmail = false;
	let query = new Parse.Query(USERS_TABLE);
	query.equalTo('email', email);
	let result = await query.first().catch((error) => {
		log.debug("[validateEmail] Error: " + error.code + " " + error.message);
		throw error;
	})
	if (!result) {
		log.debug(`[validateEmail] No email found for email ${email}`);
		return validEmail;
	}
	validEmail = true;
	return validEmail;
}

export async function calculateAveragePoints(selectedHero?: HeroObject) {
	if (!selectedHero) {
		selectedHero = await getHero();
	}
	let totalStrengthPoints = 0;
	let totalCardioPoints = 0;
	let totalMobilityPoints = 0;
	let totalMindfulnessPoints = 0;

	let user = await getUserByHeroID(selectedHero.heroID!);
	const query = new Parse.Query(ACTIVITIES_TABLE);
	query.limit(10000);
	query.equalTo('user_id', user.userID);
	query.greaterThanOrEqualTo('startDate', moment().subtract(14, "days").format());
	const parseObjects = (await query.find())
	let activities: any[] = []
	parseObjects.forEach((parseObject: any) => {
		activities.push(parseObject)
	})

	// Sum up all points
	activities.forEach(activity => {
		totalStrengthPoints += activity.strengthPoints || 0;
		totalCardioPoints += activity.cardioPoints || 0;
		totalMobilityPoints += activity.mobilityPoints || 0;
		totalMindfulnessPoints += activity.mindfulnessPoints || 0;
	});

	// Get unique days from the workouts
	const uniqueDaysSet = new Set(activities.map(activity => new Date(activity.startDate).toDateString()));
	// const uniqueDaysCount = uniqueDaysSet.size;
	const uniqueDaysCount = 14;

	// Calculate average points per unique day
	const averageStrengthPoints = Math.round(totalStrengthPoints / uniqueDaysCount);
	const averageCardioPoints = Math.round(totalCardioPoints / uniqueDaysCount);
	const averageMobilityPoints = Math.round(totalMobilityPoints / uniqueDaysCount);
	const averageMindfulnessPoints = Math.round(totalMindfulnessPoints / uniqueDaysCount);
	const averageNumberOfWorkouts = Math.round(activities.length / uniqueDaysCount);


	return {
		averageStrengthPoints,
		averageCardioPoints,
		averageMobilityPoints,
		averageMindfulnessPoints,
		averageNumberOfWorkouts
	};
}

interface AvatarParams {
	imageURL: string;
	dramatic?: boolean;
	style?: string[];
	prompt?: string;
}

export async function generateAvatarFromImage(imageURL: string, dramaticSetting: boolean, elements?: string[], prompt?: string) {
	let params: AvatarParams = {
		imageURL: imageURL,
		dramatic: dramaticSetting,
	};
	if (elements) {
		params.style = elements;
	}
	if (prompt) {
		params.prompt = prompt;
	}
	// log.debug(`[generateAvatarFromImage] params: `, params);
	let images = await Parse.Cloud.run('generateImages', params).catch((error) => {
		sendSlackErrorNotification(`[generateAvatarFromImage] Full Error Obj`, "generateAvatarFromImage", `${error}`);
		log.debug(`[generateAvatarFromImage] Full Error Obj: `, error);
		throw error;
	});
	// log.debug(`[generateAvatarFromImage] images: `, images);
	if (images.length > 0) {
		return images;
	} else {
		throw new Error("No images generated");
	}
};

export type ImageAnalysisResponse = {
	gender: string;
	hairstyle: string;
	ethnicity: string;
	hairColor: string;
	eyeColor: string;
	age: string;
	facialExpression: string;
	facialHair?: string;
	background?: string;
}

export async function analyzeImage(imageURL: string) {
	let params: AvatarParams = {
		imageURL: imageURL,
	};
	// log.debug(`[analyzeImage] params: `, params);
	let responseString = await Parse.Cloud.run('analyzeImage', params).catch((error) => {
		sendSlackErrorNotification(`[analyzeImage] Full Error Obj`, "analyzeImage", `${error}`);
		log.debug(`[analyzeImage] Full Error Obj: `, error);
		throw error;
	});
	// log.debug(`[analyzeImage] response: `, responseString);
	// convert response string to array of strings
	let responseArray = responseString.split(",");
	if (responseArray.length > 1) {
		// Response order: Gender, Hair style, Ethinicity, hair color, eye color, age, facial expression, facial hair style
		console.log(JSON.stringify(responseArray));
		let response: ImageAnalysisResponse = {
			gender: responseArray[0].trimStart(),
			hairstyle: responseArray[1].trimStart(),
			ethnicity: responseArray[2].trimStart(),
			hairColor: responseArray[3].trimStart(),
			eyeColor: responseArray[4].trimStart(),
			age: responseArray[5].trimStart(),
			facialExpression: responseArray[6].trimStart(),
		}
		if (responseArray[7]) {
			response.facialHair = responseArray[7].trimStart();
		}
		return response;
	} else {
		throw new Error("Error analyzing image. GPT Response: " + responseString);
	}
};

export async function updateHeroSpecialization(attribute: string, specialization: string): Promise<HeroObject | null> {
	let heroObject: HeroObject = await getHero();
	switch (attribute) {
		case "strength":
			heroObject.strengthTreeSpecialization = specialization;
			break;
		case "cardio":
			heroObject.cardioTreeSpecialization = specialization;
			break;
		case "mobility":
			heroObject.mobilityTreeSpecialization = specialization;
			break;
		case "mindfulness":
			heroObject.mindfulnessTreeSpecialization = specialization;
			break;
		default:
			break;
	}
	let hero = await updateHero(heroObject);
	return hero
}

export async function updateHero(heroObject: HeroObject) {
	let query = new Parse.Query(HEROES_TABLE);
	query.equalTo('heroID', heroObject.heroID);
	let result = await query.first().catch((error) => {
		log.debug("[updateHero] Error: " + error.code + " " + error.message);
		throw error;
	})
	if (!result) {
		log.debug(`[updateHero] No hero found for heroID ${heroObject.heroID}`);
		return null;
	}
	result.set(heroObject);
	result = await result.save();
	if (!result) {
		log.debug(`[updateHero] Error updating hero for heroID ${heroObject.heroID}`);
		return null;
	}
	let resultJSON = result.toJSON();
	mixpanelSetHeroProperties(resultJSON as unknown as HeroObject)
	// log.debug('[updateHero] Hero updated: ', resultJSON);
	await storageService.setObject(heroObjectKey, resultJSON);
	return resultJSON as unknown as HeroObject;
}

export async function updateUserSettings(settings: SettingsObject) {
	let user = await getUser();
	// update user.settings with any settings that are different
	let newSettings = { ...user!.settings, ...settings };
	await updateUser({ settings: newSettings })
}

export async function getUserSettings(): Promise<SettingsObject | undefined> {
	const user = await getUser();
	return user?.settings
}


export async function updateUserTimezone(timezone?: string) {
	if (!timezone) timezone = moment.tz.guess();
	await updateUser({ timezone: timezone })
}

export function chooseSpecialization(hero: HeroObject): string[] {
	let arrayOfSpecializations = [];
	if (hero.strengthLevel >= 20 && hero.strengthTreeSpecialization === undefined) {
		arrayOfSpecializations.push("strength");
	}
	if (hero.cardioLevel >= 20 && hero.cardioTreeSpecialization === undefined) {
		arrayOfSpecializations.push("cardio");
	}
	return arrayOfSpecializations;
}

export function addUserToEmailList(email: string, firstName: string | undefined) {
	log.debug(`[addUserToEmailList] Email: ${email}, First Name: ${firstName}`);
	const params = {
		email: email,
		firstName: firstName,
	}
	Parse.Cloud.run("addContactToEmailList", params).then((result) => {
		log.debug(`[addUserToEmailList] Result: `, result);
	}).catch((error) => {
		log.debug(`[addUserToEmailList] Error: `, error);
		throw error;
	});
}
