import { IonContent, IonPage } from '@ionic/react'
import { useHistory } from 'react-router-dom'
import HeaderLogoNonOnboarding from '../../components/HeaderLogoNonOnboarding'
import '../../css/Dashboard.css'
import CurrentDuel from '../Duels/components/CurrentDuel'
import { CurrentGoal } from '../Profile/components/CurrentGoal'
import { DashboardSteps } from '../Tour/Steps'
import { TourComponent } from '../Tour/StepsComponent'
export const Dashboard = () => {
    const history = useHistory();
    function navigate(page: string) {
        history.push(`/${page}`);
    }

    return (
        <>
            <IonPage>
                <HeaderLogoNonOnboarding />
                <IonContent>
                    <div className='goals-tour' style={{ height: "100%", textAlign: "center" }}>
                        <h2>Current Goals</h2>
                        <div onClick={() => navigate('profile/goals')}>
                            <CurrentGoal />
                        </div>
                        <CurrentDuel />
                    </div>
                </IonContent>
                <TourComponent modalShowing={false} coachMarkName="dashboard" steps={DashboardSteps} link='/profile/goals' />
            </IonPage>

        </>
    )
}



