import { IonContent, IonSkeletonText, IonSpinner } from '@ionic/react';
import log from 'loglevel';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import GlobalState from '../../contexts/GlobalState';
import { Goal } from '../../models/goalsModels';
import { getAllGoals } from '../../services/GoalsService';
import { GoalSteps } from '../Tour/Steps';
import { TourComponent } from '../Tour/StepsComponent';
import { CurrentGoal } from './components/CurrentGoal';
import { GoalCard } from './components/GoalCard';

export const ProfileGoals = () => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { currentGoal, heroObject } = globalState;
    const [goalHistory, setGoalHistory] = useState<Goal[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadGoals();
    }, []);



    async function loadGoals() {
        setLoading(true);
        log.debug("Loading Goals");
        let goals = await getAllGoals();
        setGoalHistory(goals.filter(goal => goal.evaluated === true)
            .sort((a, b) => moment(b.endDate).unix() - moment(a.endDate).unix()))
        setLoading(false);
    }

    return (
        <>
            {heroObject && goalHistory && (
                <>
                    <IonContent fullscreen>
                        {loading && (<IonSpinner name="crescent" />)}
                        <h2 style={{ textAlign: "center" }}>Current Goals</h2>
                        {!currentGoal && (
                            <>
                                <IonSkeletonText animated style={{ width: "80%", margin: "auto", height: "200px" }} />
                            </>
                        )}
                        <div className='current-goal-hint'>{currentGoal && <CurrentGoal />}</div>
                        {/* <GoalRecord goals={goalHistory} /> */}
                        <h2 style={{ textAlign: "center" }}>Past Goals</h2>
                        {goalHistory &&
                            goalHistory
                                .map((goal, index) => (
                                    <GoalCard key={index} goal={goal} />
                                ))
                        }
                        <TourComponent coachMarkName="profileGoals" steps={GoalSteps} modalShowing={false} />
                    </IonContent>
                </>
            )}
        </>
    )
}
