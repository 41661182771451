import { IonAvatar, IonButton, IonContent, IonFooter, IonSpinner } from "@ionic/react";
import { useContext, useEffect, useRef, useState } from "react";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/HeroChatComponent.css";
import { DuelStatus } from "../../../models/duelsModels";
import { DuelDisplayMessage, DuelMessageObject, DuelMessageTransformedResponse, HERO_REPSPONSE_TYPE_ARRAY } from "../../../models/messageModels";
import { formatMomentDate, insertNewLines, toCamelCase } from "../../../services/HelperService";
import { fetchDuelMessages, processVillainResponse, sendDuelMessage, sendHeroMessageFromButton } from "../../../services/MessageService";
import log from "loglevel";

const DuelsRenderComponent = (props: {
	duelStatus: DuelStatus;
	messages: DuelDisplayMessage[];
	setMessages: React.Dispatch<React.SetStateAction<DuelDisplayMessage[]>>;
	processing: any;
	setProcessing: React.Dispatch<React.SetStateAction<boolean>>;
	scroller: number;
	setScroller: React.Dispatch<React.SetStateAction<number>>;
}) => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { heroObject } = globalState;

	const [responseOptions, setResponseOptions] = useState<string[]>([]);
	const [showFooter, setShowFooter] = useState<boolean>(false);
	const [heroProcessing, setHeroProcessing] = useState<boolean>(false);
	const feedEndRef = useRef<HTMLDivElement | null>(null);
	const baseMessageWaitTime = 1000;
	const variableMessageWaitTime = 500;
	let waitTime = 1000; // just adding 1 incase it's nil

	useEffect(() => {
		updateDuelMessages();
		establishResponseOptions();
	}, []);

	useEffect(() => {
		if (props.scroller >= 0) {
			if (feedEndRef.current !== null) {
				setTimeout(() => {
					try {
						feedEndRef.current?.scrollIntoView({ behavior: "auto" });
					} catch (error) {
						console.error("ERROR: " + error);
					}
				}, waitTime);
				// feedEndRef.current.scrollIntoView({ behavior: "auto" });
				// log.debug("Scrolling to bottom (useEffect)");
			} else {
				// log.debug("Condition not met to scroll");
			}
		}

		//log.debug("Current Messages Array (useEffect): "+JSON.stringify(messages));
	}, [props.messages, props.scroller]);

	async function establishResponseOptions() {
		setShowFooter(false)
		// Add GPT at some point, for now just randomize
		const array = HERO_REPSPONSE_TYPE_ARRAY;
		const result = [];
		const usedIndices = new Set();
		const arrayLength = array.length;
		if (arrayLength <= 3) {
			return array.slice();
		}
		while (result.length < 3) {
			const randomIndex = Math.floor(Math.random() * arrayLength);
			if (!usedIndices.has(randomIndex)) {
				usedIndices.add(randomIndex);
				result.push(array[randomIndex]);
			}
		}
		setResponseOptions(result);
		setShowFooter(true)
	};

	async function sendHeroMessage(response: string) {
		setHeroProcessing(true);
		response = toCamelCase(response);
		setShowFooter(false);

		try {
			const heroMessage = await sendHeroMessageFromButton(response, props.duelStatus.statusId);
			log.debug(`heroMessage: `, heroMessage);
			await handleHeroMessages(heroMessage);
			const villainResponse = await sendDuelMessage(heroMessage, "user", props.duelStatus.statusId!, response);
			log.debug(`[sendHeroMessageFromButton] villainReponse: `, villainResponse);
			await handleVillainResponse(villainResponse, heroMessage);
		} catch (error) {
			alert("There was an error sending your message. Please try again.");
			setHeroProcessing(false);
			setShowFooter(true);
			throw new Error("[sendHeroMessageFromButton] Error: " + error);
		}
	}

	async function handleHeroMessages(heroMessage: string) {
		let heroMessageArray = insertNewLines(heroMessage).split("\n");

		for (let index = 0; index < heroMessageArray.length; index++) {
			let message = heroMessageArray[index];
			let randomMessageDelay = Math.floor(Math.random() * variableMessageWaitTime) + baseMessageWaitTime;
			let lastMessage = index === heroMessageArray.length - 1;

			await new Promise(resolve => setTimeout(resolve, randomMessageDelay * (index + 1)));

			let heroMsg = {
				role: "user",
				duel_id: props.duelStatus.statusId,
				imageUrl: undefined,
				content: message
			};

			// Using functional update to ensure we have the latest state
			props.setMessages(prevMessages => [...prevMessages, heroMsg]);

			if (lastMessage) {
				setHeroProcessing(false);
			} else {
				setHeroProcessing(true);
			}
		}
	}

	async function handleVillainResponse(villainResponse: DuelMessageObject, heroMessage: string) {
		let displayMessages = await processVillainResponse(villainResponse, heroMessage, heroObject?.userId!, props.duelStatus);
		let lastVillainMessage = displayMessages[displayMessages.length - 1];
		let lastVillainMessageArray = insertNewLines(lastVillainMessage.content).split("\n");

		for (let index = 0; index < lastVillainMessageArray.length; index++) {
			let message = lastVillainMessageArray[index];
			let randomMessageDelay = Math.floor(Math.random() * variableMessageWaitTime) + baseMessageWaitTime;
			let lastMessage = index === lastVillainMessageArray.length - 1;

			await new Promise(resolve => setTimeout(resolve, randomMessageDelay * (index + 1)));

			let villainMessage: DuelDisplayMessage = {
				...lastVillainMessage,
				imageUrl: undefined,
				content: message
			};
			if (lastMessage) {
				villainMessage.imageUrl = lastVillainMessage.imageUrl;
			}

			// Using functional update to ensure we have the latest state
			props.setMessages(prevMessages => [...prevMessages, villainMessage]);

			if (lastMessage) {
				props.setProcessing(false);
				establishResponseOptions();
			} else {
				props.setProcessing(true);
			}
		}
	}
	async function updateDuelMessages() {
		// log.debug("updateDuelMessages Triggered");
		props.setProcessing(true);
		let duelMessages: DuelMessageTransformedResponse = await fetchDuelMessages(props.duelStatus.statusId);
		// log.debug("Duel Messages: ", duelMessages);

		props.setMessages(duelMessages.displayMessages);
		props.setProcessing(false);
	}

	return (
		<>
			<IonContent>
				{props.messages &&
					props.messages.length > 0 &&
					props.messages.map((message: DuelDisplayMessage, index: number) => {
						if (message.content.indexOf("###") === -1) {
							// const cleanedContent = message.content.replace(/\n+/g, "\n"); // Replace consecutive newline characters with a single newline
							// const splitContent = cleanedContent.split("\n");
							let cleanedContent = insertNewLines(message.content); // this is still needed for re-renders
							const splitContent = cleanedContent.split("\n");
							if (message.role === "assistant" || message.role === "villain") {
								return (
									<>
										<div key={Math.random()} className="assistant-message-div">
											<IonAvatar className="assistant-message-avatar">
												<img
													className="user-avatar"
													style={{ width: "100%", height: "100%", objectFit: "cover" }}
													src={props.duelStatus.villainImageURL}
													alt=""
												/>
											</IonAvatar>
											<div className={"assistant-message-container"}>
												{splitContent.map((content: string, contentIndex: number) => (
													<div key={Math.random()} className={`assistant-message-bubble ${contentIndex > 0 ? "bubble-pad" : ''}`}>
														{content}
													</div>
												))}
											</div>
										</div>
										{message.timestamp && (
											<div key={Math.random()} className="message-timestamp bot">
												<span>
													<>{formatMomentDate(message.timestamp)}</>
												</span>
											</div>
										)}
										{message.imageUrl && message.imageUrl !== "" && (
											<>
												<div key={Math.random()} className="assistant-message-div">
													<IonAvatar className="assistant-message-avatar">
														<img
															className="user-avatar"
															style={{ width: "100%", height: "100%", objectFit: "cover" }}
															src={props.duelStatus.villainImageURL}
															alt=""
														/>
													</IonAvatar>
													<div className={"assistant-message-container"}>
														<div className={"assistant-message-bubble"}>
															<img src={message.imageUrl} alt="" />
														</div>
													</div>
												</div>
											</>
										)}
									</>
								);
							} else {
								return (
									<>
										<div key={Math.random()} className="hero-message-div">
											<div className={"hero-message-container"}>
												{splitContent.map((content: string, contentIndex: number) => (
													<div key={Math.random()} className={`hero-message-bubble ${contentIndex > 0 ? "bubble-pad" : ''}`}>
														{content}
													</div>
												))}
											</div>
											<IonAvatar className="hero-message-avatar">
												<img
													className="user-avatar"
													style={{ width: "100%", height: "100%", objectFit: "cover" }}
													src={heroObject?.heroImageURL ? `${heroObject?.heroImageURL}` : `assets/images/onboarding/unknown-person.jpg`}
													alt=""
												/>
											</IonAvatar>
										</div>
										{message.timestamp && (
											<div key={Math.random()} className="message-timestamp hero">
												<span>
													<>{formatMomentDate(message.timestamp)}</>
												</span>
											</div>
										)}
										{message.imageUrl && message.imageUrl !== "" && (
											<>
												<div key={Math.random()} className="hero-message-div">
													<IonAvatar className="hero-message-avatar">
														<img
															className="user-avatar"
															style={{ width: "100%", height: "100%", objectFit: "cover" }}
															src={heroObject?.heroImageURL ? `${heroObject?.heroImageURL}` : `assets/images/onboarding/unknown-person.jpg`}
															alt=""
														/>
													</IonAvatar>
													<div className={"hero-message-container"}>
														<div className={"hero-message-bubble"}>
															<img src={message.imageUrl} alt="" />
														</div>
													</div>
												</div>
											</>
										)}
									</>
								);
							}
						}
					})}
				{props.processing === true && (
					<div className="assistant-message-div">
						<IonAvatar className="assistant-message-avatar">
							<img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={props.duelStatus.villainImageURL} alt="" />
						</IonAvatar>
						<div className={"assistant-message-container-typing"}><IonSpinner name="dots" /></div>
					</div>
				)}
				{heroProcessing === true && (
					<div className="hero-message-div">
						<div className={"hero-message-container-typing"}><IonSpinner name="dots" /></div>
						<IonAvatar className="hero-message-avatar">
							<img className="user-avatar" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={heroObject?.heroImageURL ? `${heroObject?.heroImageURL}` : `assets/images/onboarding/unknown-person.jpg`} alt="" />
						</IonAvatar>
					</div>
				)}
				<div ref={feedEndRef} />
			</IonContent>
			{showFooter && props.duelStatus.complete !== true && (
				<IonFooter>
					<div className="chat-reply-options">
						{responseOptions.map((response: string, index: number) => (
							<IonButton key={index} className="chat-reply-button" color="primary" fill="solid" size="small" onClick={() => sendHeroMessage(response)}>
								{response}
							</IonButton>
						))}
					</div>
				</IonFooter>
			)}

		</>
	);
};

export default DuelsRenderComponent;
