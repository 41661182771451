import axios from "axios";
import { ALTOGIC_CLIENT_KEY, ALTOGIC_ENVURL, FITBIT_ACTIVITY_ENDPOINT, FITBIT_HISTORY_ENDPOINT } from "../models/endPoints";
import { getUser, getUser_id } from "./UserService";
import { sendSlackErrorNotification } from "./NotificationService";
import { createClient } from "altogic";
import { FITBIT_TOKENS_TABLE } from "../models/databaseModels";
import { FitBitStatus } from "../models/userModel";
import Parse from 'parse';
import log from "loglevel";

export async function getFitbitData() {
    let user = await getUser();
    if (!user) {
        throw new Error("User not found");
    }
    // TODO: Transition to Parse
    await axios
        .post(FITBIT_HISTORY_ENDPOINT, {
            accessToken: user.fitbitAccessToken,
            userId: user.userID,
        })
        .then(async (result) => {
            log.debug(`Full API Response: `, result);
            return true
        })
        .catch((error) => {
            sendSlackErrorNotification(`[getFitbitData] Full Error Obj`, "startDuel", `${error}`);
            log.debug(`[getFitbitData] Error Message: ${error.response.data.message}`);
            log.debug(`[getFitbitData] Full Error Obj: `, error);
            throw error;
        });
    return true
}

export async function getFitbitActivities() { // defaults to 7 days at API level
    let user = await getUser();
    if (!user) {
        throw new Error("User not found");
    }
    // TODO: Transition to Parse
    let url = FITBIT_ACTIVITY_ENDPOINT + `?userID=${user.userID}`
    await axios
        .get(url)
        .then(async (result) => {
            log.debug(`Full API Response: `, result);
            return true
        })
        .catch((error) => {
            sendSlackErrorNotification(`[getFitbitActivities] Full Error Obj`, "startDuel", `${error}`);
            log.debug(`[getFitbitActivities] Error Message: ${error.response.data.message}`);
            log.debug(`[getFitbitActivities] Full Error Obj: `, error);
            throw error;
        });
    return true
}

export async function deleteAllFitbitData() {
    const altogic = createClient(ALTOGIC_ENVURL, ALTOGIC_CLIENT_KEY);// TODO: Transition to Parse
    try {
        const result = await altogic.db.model("activities").filter('sourceName == "fitbit-history"').delete()
        log.debug(`[deleteAllFitbitData] result: `, result);
    } catch (error) {
        log.debug(`[deleteAllFitbitData] error: `, error);
        throw error;
    }
}


export async function isFitBitConnected() {
    let userId = await getUser_id();
    let query = new Parse.Query(FITBIT_TOKENS_TABLE);
    query.equalTo('userId', userId);
    let result = await query.first().catch((error) => {
        log.debug("[isFitBitConnected] Error: " + error.code + " " + error.message);
        throw error;
    })
    if (!result) {
        // log.debug(`[isFitBitConnected] No FitBit token found for user_id ${userId}`);
        return false;
    }
    let fitBitStatus: FitBitStatus = result.toJSON() as FitBitStatus
    if (fitBitStatus.connected === true) {
        return true;
    } else {
        return false;
    }
}

export async function disableFitBit() {
    let userId = await getUser_id();
    let query = new Parse.Query(FITBIT_TOKENS_TABLE);
    query.equalTo('userId', userId);
    let result = await query.first().catch((error) => {
        console.error("[disableFitBit] Error: " + error.code + " " + error.message);
        throw error;
    })
    if (!result) {
        console.error(`[disableFitBit] No user found for userId ${userId}`);
        return null;
    }
    result.set({
        connected: false,
        accessToken: null,
        refreshToken: null,
    });
    result = await result.save();
    if (!result) {
        console.error(`[disableFitBit] Error updating user for userID ${userId}`);
        return null;
    }
    let resultJSON = result.toJSON();
    return resultJSON as unknown as FitBitStatus;
}