import { Preferences } from '@capacitor/preferences';

export class StorageService {

	async configure() {
		await Preferences.configure({
			group: 'NativeStorage',
		})
	}

	async setObject(key, value) {
		await this.configure()
		await Preferences.set({
			key: key,
			value: JSON.stringify(value),
		});
	}

	async getObject(key) {
		await this.configure()
		// This hangs a lot
		let ret;
		try {
			ret = await Preferences.get({ key: key });

			if (ret === null || ret === undefined || JSON.stringify(ret) === '{"value":null}') {
				return null;
			} else {
				return JSON.parse(ret.value || "");
			}
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	async setItem(key, value) {
		await this.configure()
		await Preferences.set({
			key: key,
			value: value,
		});
	}

	async getItem(key) {
		await this.configure()
		const { value } = await Preferences.get({ key: key });
		//log.debug('Looking for item '+key+': ', value);
		return value || "";
	}

	async removeItem(key) {
		await this.configure()
		await Preferences.remove({ key: key });
	}

	async keys() {
		await this.configure()
		const { keys } = await Preferences.keys();
		// log.debug('Got keys: ', keys);
		return keys
	}

	async clear() {
		await Preferences.clear();
	}
}
