import { IonAvatar, IonButton } from '@ionic/react';
import React, { useContext, useEffect } from 'react'
import JSConfetti from 'js-confetti'
import { UserAvatar } from '../../components/UserAvatar';
import { HeroObject } from '../../models/userModel';
import GlobalState from '../../contexts/GlobalState';
import { UserAvatarAnimated } from './UserAvatarAnimated';
const jsConfetti = new JSConfetti()


export const PayWallCelebrationComponent = (props: { setShowCelebration: React.Dispatch<React.SetStateAction<any>> }) => {

  const globalState = useContext(GlobalState);
  if (!globalState) throw new Error("Error: GlobalState is undefined");
  const { heroObject } = globalState;

  useEffect(() => {
    // jsConfetti.addConfetti({
    //   emojis: ['💥'],
    // })
  }, []);

  return (
    <>
      <div className="paywall-celebration">

        <div>
          <IonAvatar style={{ margin: "auto", height: "200px", width: "200px" }} >
            <UserAvatarAnimated avatarImage={heroObject?.heroImageURL!} />
          </IonAvatar>
        </div>
        <div className="paywall-celebration-text">
          Congratulations! You've unlocked the full FitHero.ai experience!
        </div>
        <div className="paywall-celebration-button">
          <IonButton color="tertiary" onClick={() => props.setShowCelebration(false)} expand="block">
            Awesome! Let's go! 🚀
          </IonButton>
        </div>
      </div>
    </>
  )
}
