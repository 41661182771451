import React, { useEffect, useState } from 'react'
import { Villain } from '../../../models/villainModels'
import { IonIcon, IonModal } from '@ionic/react';
import { informationCircleOutline } from 'ionicons/icons';

export const VillainHoursComponent = (props: { villain: Villain }) => {
    const [startHour, setStartHour] = useState<number>(7);
    const [endHour, setEndHour] = useState<number>(22);
    const [showHoursModal, setShowHoursModal] = useState(false);

    useEffect(() => {
        setStartHour(props.villain.startHour || 7);
        setEndHour(props.villain.endHour || 22);
    }, [props.villain])
    return (
        <>
            <IonModal className="villain-difficulty-modal" isOpen={showHoursModal} onDidDismiss={() => setShowHoursModal(false)}>
                <div className="villain-difficulty-modal-div">
                    <div className="villian-difficulty-box">
                        <span className="villian-header-2">hours of activity</span><br />
                    </div>
                    The villain will attempt to defeat you during these hours. Outside of these hours, you are safe, though you may still attack the villain.
                </div>
            </IonModal>
            {props.villain && startHour && endHour && (
                <div className='villain-hours-div' onClick={() => setShowHoursModal(true)} >
                    active hours: {startHour > 12 ? startHour - 12 : startHour} {startHour > 12 ? "PM" : "AM"} - {endHour > 12 ? endHour - 12 : endHour} {endHour > 12 ? "PM" : "AM"}<IonIcon icon={informationCircleOutline} style={{ fontSize: "12px" }} />
                </div>
            )}
        </>
    )
}
