import { IonAvatar, IonCol, IonContent, IonGrid, IonRow, IonSpinner } from '@ionic/react'
import log from 'loglevel'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import "../../css/Feed.css"
import { HeroObject } from '../../models/userModel'
import { getHero, getLeaderboard } from '../../services/UserService'

const Leaderboard = () => {
    const history = useHistory();
    const [allHeroes, setAllHeroes] = useState<HeroObject[]>([])
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [refreshAlert, setRefreshAlert] = useState<boolean>(false);
    const [heroId, setHeroId] = useState<string>("")


    useEffect(() => {
        refreshHeroData()
    }, [])

    async function refreshHeroData(refresh: boolean = false) {
        setRefreshing(true)
        setHeroId((await getHero()).heroID!)
        let heroes = await getLeaderboard()
        // console.log(heroes)
        setAllHeroes(heroes)
        setRefreshing(false)
    }

    async function tapAvatar(heroId: string) {
        history.push(`/otherprofile/${heroId}`)
    }

    async function sortHeroes(sortType: string) {
        log.debug(`sortType: ${sortType}`)
        let heroes = [...allHeroes];  // Create a new array
        if (sortType === "duelPoints") {
            heroes.sort((a, b) => b.totalDuelPoints! - a.totalDuelPoints!);
        } else if (sortType === "wins") {
            heroes.sort((a, b) => b.record!.wins - a.record!.wins);
        } else if (sortType === "losses") {
            heroes.sort((a, b) => b.record!.losses - a.record!.losses);
        } else if (sortType === "winPercent") {
            heroes.sort((a, b) => b.record!.winPercentage - a.record!.winPercentage);
        } else if (sortType === "streak") {
            heroes.sort((a, b) => b.record!.streak - a.record!.streak);
        } else if (sortType === "name") {
            heroes.sort((a, b) => a.heroName!.localeCompare(b.heroName!));
        }
        setAllHeroes(heroes);
    }

    return (
        // <IonPage>
        //     <IonHeader>
        //         <IonToolbar style={{ textAlign: "center" }}>
        //             <IonButtons slot="start" className="toolbar-left-buttons">
        //                 <IonButton onClick={() => refreshHeroData(true)}>
        //                     <IonIcon style={{ color: "white" }} icon={refreshCircleOutline} />
        //                 </IonButton>
        //             </IonButtons>
        //             <IonTitle>All FitHeroes</IonTitle>
        //         </IonToolbar>
        //     </IonHeader>
        <IonContent>
            {refreshing && (
                <>
                    <IonSpinner name="crescent" />
                </>
            )}
            {/* <IonLoading isOpen={refreshing} message={"Refreshing data ..."} backdropDismiss /> */}
            <IonGrid>
                <IonRow>
                    <IonCol size="6" className='leaderboard-column-header' onClick={() => sortHeroes("name")}>
                        Hero
                    </IonCol>
                    <IonCol size="2" className='leaderboard-column-header' style={{ textAlign: "center" }} onClick={() => sortHeroes("duelPoints")}>
                        Duel<br />Points
                    </IonCol>
                    <IonCol size="1" className='leaderboard-column-header' style={{ textAlign: "center" }} onClick={() => sortHeroes("wins")}>
                        W
                    </IonCol>
                    <IonCol size="1" className='leaderboard-column-header' style={{ textAlign: "center" }} onClick={() => sortHeroes("losses")}>
                        L
                    </IonCol>
                    <IonCol size="2" className='leaderboard-column-header' style={{ textAlign: "center" }} onClick={() => sortHeroes("winPercent")}>
                        %
                    </IonCol>
                    {/* <IonCol size="2" className='leaderboard-column-header' style={{ textAlign: "center" }} onClick={() => sortHeroes("streak")}>
                            Best<br />Streak
                        </IonCol> */}
                </IonRow>
                {allHeroes.map((hero: HeroObject, index) => {
                    let className = "hero-row";
                    if (index % 2 !== 0) {
                        className = "hero-row-alt";
                    }
                    if (hero.heroID === heroId) {
                        className = "hero-row-current"
                    }
                    return (
                        // alternate row colors based on index
                        <IonRow className={className}>
                            <IonCol size="1" style={{ paddingRight: "0px" }}>
                                {index + 1}.
                            </IonCol>
                            <IonCol size="2">
                                <IonAvatar className="member-image-feed" onClick={() => tapAvatar(hero.heroID!)}>
                                    <img src={hero.heroImageURL} alt='hero image' />
                                </IonAvatar>
                            </IonCol>
                            <IonCol size="3">
                                {hero.heroName}
                            </IonCol>
                            <IonCol size="2" style={{ textAlign: "center" }}>
                                {hero.totalDuelPoints}
                            </IonCol>
                            <IonCol size="1" style={{ textAlign: "center" }}>
                                {hero.record?.wins}
                            </IonCol>
                            <IonCol size="1" style={{ textAlign: "center" }}>
                                {hero.record?.losses}
                            </IonCol>
                            <IonCol size="2" style={{ textAlign: "center" }}>
                                {hero.record?.winPercentage && (
                                    <>
                                        {Math.round(hero.record?.winPercentage * 100).toFixed(0)}%
                                    </>
                                )}
                            </IonCol>
                            {/* <IonCol size="2" style={{ textAlign: "center" }}>
                                    {hero.record?.streak}
                                </IonCol> */}
                        </IonRow>
                    )
                })}
            </IonGrid>
            <div>

            </div>
        </IonContent>
        // </IonPage>
    )
}

export default Leaderboard