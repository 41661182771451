import { App } from "@capacitor/app";
import { Device } from "@capacitor/device";
import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonContent,
	IonHeader,
	IonIcon,
	IonInput,
	IonItem,
	IonItemDivider,
	IonItemGroup,
	IonLabel,
	IonLoading,
	IonModal,
	IonPage,
	IonRefresher,
	IonRefresherContent,
	IonSelect,
	IonSelectOption,
	IonTitle,
	IonToggle,
	IonToolbar,
	SelectCustomEvent,
} from "@ionic/react";
import { Deploy } from "cordova-plugin-ionic";
import { eyeOutline, logOutOutline, refreshOutline, trashOutline } from "ionicons/icons";
import moment from "moment";
import OneSignal from "onesignal-cordova-plugin";
import { DeviceState } from "onesignal-cordova-plugin/dist/Subscription";
import { useContext, useEffect, useRef, useState } from "react";
import GlobalState from "../../contexts/GlobalState";
import { ActivityLedger, WorkoutObject } from "../../models/exerciseModels";
import { activityLedgerKey, adminEmails, backends, googleFitAuthorizedKey, healthKitAuthorizedKey, lastUpdatedStepsKey, lastUpdatedWorkoutKey, liveUpdateVersions, workoutKey } from "../../models/userModel";
import { createLedgerFromWorkoutArray, deleteAllActivitiesForUser, getRecentWorkouts } from "../../services/ExerciseService";
import { disableFitBit, isFitBitConnected } from "../../services/FitbitService";
import { googleFitConnect } from "../../services/GoogleFitService";
import { authorizeHealthKit } from "../../services/HealthKitService";
import { getFullDeviceInfo } from "../../services/HelperService";
import { StorageService } from "../../services/StorageService";
import { updateUserSettings, updateUserTimezone } from "../../services/UserService";
import log from "loglevel";
import Parse from "parse";
import { PayWallClassDojoComponent } from "../../Paywall/components/PayWallClassDojoComponent";
import { Purchases } from "@revenuecat/purchases-capacitor";
import { smartLog } from "../../services/LoggingService";



const Settings = () => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { setupRevenueCat, subscriptionDates, setSubscriptionDates, isMobile, logout, serialStartupFunction, userState, updateUserState, googleFitConnected, setGoogleFitConnected, updateHeroHealth, currentlySubscribed, subscriptionInfo, setCurrentlySubscribed, setSubscriptionInfo } = globalState;
	const [loading, setLoading] = useState<boolean>(false);
	const [refreshing, setRefreshing] = useState<boolean>(false);
	const [confirmDelete, setConfirmDelete] = useState<boolean>(false);
	const [processing, setProcessing] = useState<boolean>(false);
	const [confirmReset, setConfirmReset] = useState<boolean>(false);
	const [fitBitEnabled, setFitBitEnabled] = useState<boolean>(false);
	const [healthKitEnabled, setHealthKitEnabled] = useState<boolean>(false);
	const [googleFitEnabled, setGoogleFitEnabled] = useState<boolean>(false);
	const [showPaywall, setShowPaywall] = useState<boolean>(false);

	const [confirmSync, setConfirmSync] = useState<boolean>(false);
	const [syncing, setSyncing] = useState<boolean>(false);
	const [platform, setPlatform] = useState<string>();
	const [confirmResetTutorial, setConfirmResetTutorial] = useState<boolean>(false);
	const [pushSubscribed, setPushSubscribed] = useState<boolean>();
	const [chatSubscribed, setChatSubscribed] = useState<boolean>(false);
	const [duelSubscribed, setDuelSubscribed] = useState<boolean>(false);
	const [emailSubscribed, setEmailSubscribed] = useState<boolean>(false);
	const [promptToDuelInApp, setPromptToDuelInApp] = useState<boolean>(false);
	const [showInAppNotifications, setShowInAppNotifications] = useState<boolean>(false);

	const [smartLookInfo, setSmartLookInfo] = useState<any>({ isRecording: false, smartLookStatus: "" });

	const [selectedTimeZone, setSelectedTimeZone] = useState<string>(moment.tz.guess());
	const [liveUpdateVersion, setLiveUpdateVersion] = useState<string>("");
	const [backend, setBackend] = useState<string>("");
	const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
	const [password, setPassword] = useState<string>();
	const [inputField, setInputField] = useState<string>("password");

	const [notificationsChanged, setNotificationsChanged] = useState<boolean>(false);
	const passwordRef = useRef<any>(null);


	const [resetting, setResetting] = useState<any>({
		visible: false,
		message: "",
	});
	const storageService = new StorageService();
	const timeZones = moment.tz.names();

	useEffect(() => {
		if (userState.authenticated === true) {
			refreshData();
			if (subscriptionDates.expires_date && moment(subscriptionDates.expires_date) < moment()) {
				refreshCustomerInfo();
			}
		}
	}, [userState, currentlySubscribed, subscriptionDates]);

	async function refreshData() {
		getFitBitStatus()
		getNotificationSettings()
		getConfiguration()
		Device.getInfo().then((deviceInfo) => {
			setPlatform(deviceInfo.platform);
			if (deviceInfo.platform === "ios") {
				storageService.getItem(healthKitAuthorizedKey).then((resp) => {
					if (resp === "true") {
						setHealthKitEnabled(true);
					}
				});
			}
			if (deviceInfo.platform === "android") {
				storageService.getItem(googleFitAuthorizedKey).then((resp) => {
					if (resp === "true") {
						setGoogleFitEnabled(true);
					}
				});
			}
			if (deviceInfo.platform !== "web") {
				OneSignal.getDeviceState((state: DeviceState) => {
					log.debug(`[toggleNotifications] state: `, JSON.stringify(state));
					setPushSubscribed(state.subscribed)
				})
			}
		});
	}

	async function refreshCustomerInfo() {
		console.log(`[refreshCustomerInfo] refreshing customer info`);
		await setupRevenueCat();
	}

	async function getConfiguration() {
		log.debug(`[Settings] getConfiguration`);
		let currentConfiguration = await Deploy.getConfiguration()
		setLiveUpdateVersion(currentConfiguration.channel);
		let backendVariable = await storageService.getItem("backend");
		if (backendVariable === "") backendVariable = "Production";
		setBackend(backendVariable);
		log.debug(`[Settings] currentConfiguration: `, currentConfiguration);
	}

	async function getNotificationSettings() {
		if (userState.user?.settings) {
			if (userState.user.settings.sendChatNotifications === undefined) {
				log.debug(`[getNotificationSettings] sendChatNotifications is undefined. Setting to true and updating user settings`);
				let sendChatNotifications = true; // defaults to true if it doesn't exist
				setChatSubscribed(sendChatNotifications)
				updateUserSettings({ sendChatNotifications: sendChatNotifications })				
			} else {
				setChatSubscribed(userState.user.settings.sendChatNotifications)
			}
			if (userState.user.settings.sendDuelNotifications === undefined) {
				log.debug(`[getNotificationSettings] sendDuelNotifications is undefined. Setting to true and updating user settings`);
				let sendDuelNotifications = true; // defaults to true if it doesn't exist
				setDuelSubscribed(sendDuelNotifications)
				updateUserSettings({ sendDuelNotifications: sendDuelNotifications })
			} else {
				setDuelSubscribed(userState.user.settings.sendDuelNotifications)
			}
			if (userState.user.settings.sendWeeklyEmails === undefined) {
				log.debug(`[getNotificationSettings] sendWeeklyEmails is undefined. Setting to true and updating user settings`);
				let sendWeeklyEmails = true; // defaults to true if it doesn't exist
				setEmailSubscribed(sendWeeklyEmails)
				updateUserSettings({ sendWeeklyEmails: sendWeeklyEmails })
			} else {
				setEmailSubscribed(userState.user.settings.sendWeeklyEmails)
			}
			if (userState.user.settings.promptToDuelInApp === undefined) {
				log.debug(`[getNotificationSettings] promptToDuelInApp is undefined. Setting to true and updating user settings`);
				let promptToDuelInApp = true; // defaults to true if it doesn't exist
				setPromptToDuelInApp(promptToDuelInApp)
				updateUserSettings({ promptToDuelInApp: promptToDuelInApp })
			} else {
				setPromptToDuelInApp(userState.user.settings.promptToDuelInApp)
			}
			if (userState.user.settings.showInAppNotifications === undefined) {
				log.debug(`[getNotificationSettings] showInAppNotifications is undefined. Setting to true and updating user settings`);
				let showInAppNotifications = true; // defaults to true if it doesn't exist
				setShowInAppNotifications(showInAppNotifications)
				updateUserSettings({ showInAppNotifications: showInAppNotifications })
			} else {
				setShowInAppNotifications(userState.user.settings.showInAppNotifications)
			}
		}
		if (userState.user?.timezone) {
			setSelectedTimeZone(userState.user.timezone)
		}
	}

	function lo() {
		logout();
	};

	async function getFitBitStatus() {
		setFitBitEnabled(await isFitBitConnected())
	}

	const forceUpdate = async () => {
		setLoading(true);
		let deviceInfo = await getFullDeviceInfo();

		if (deviceInfo.platform !== "web") {
			let legacy = false;
			let currentVersion = await Deploy.getCurrentVersion();
			let update = await Deploy.checkForUpdate().catch((error) => { });
			log.debug(`update object: `, update);
			if (update && update.available) {
				// We have an update!
				log.debug("Getting update");
				try {
					const resp = await Deploy.sync({ updateMethod: "auto" }, (percentDone) => {
						log.debug("Percent Done: ");
						log.debug({ percentDone });
					});
					// const resp = await Deploy.getVersionById("");
					log.debug({ resp });
					if (!currentVersion || (resp && currentVersion.versionId !== resp.versionId)) {
						log.debug("Different versions, reloading app");
						alert("Reloading app");
						await storageService.setItem("recentlyUpdated", "true");
						await Deploy.reloadApp();
					} else {
						setLoading(false);
						alert("No new version available");
						log.debug("Same versions, continuing");
					}
				} catch (err) {
					setLoading(false);
					alert("Error in updating: " + JSON.stringify(err));
					log.debug("Error in updating: " + JSON.stringify(err));
				}
			} else {
				alert("No new version available");
				log.debug(`No update available. Legacy: ${legacy}`);
			}
		}
	};

	async function deleteUser() {
		let interval = setInterval(() => {
			log.debug("Deleting account");
			setProcessing(false);
			logout();
		}, 5000);
	}

	async function deleteAccount() {
		if (confirmDelete === false) {
			setConfirmDelete(true);
		} else {
			setConfirmDelete(false);
			setProcessing(true);
			await deleteUser();
		}
	}

	async function resetHero(confirmed: boolean = false) {
		if (confirmed === false) {
			setConfirmReset(true);
		} else {
			setConfirmReset(false);
			setResetting({ visible: true, message: "Deleting all activities" });
			await deleteAllActivitiesForUser();
			// setResetting({ visible: true, message: "Deleting all steps" });
			// await deleteAllStepsForUser();
			await storageService.removeItem("hero");
			await storageService.removeItem(activityLedgerKey);
			await storageService.removeItem(workoutKey);
			await storageService.removeItem(lastUpdatedStepsKey);
			await storageService.removeItem(lastUpdatedWorkoutKey);
			await storageService.removeItem("lastUpdatedText");
			setResetting({ visible: true, message: "Processing workouts" });
			await serialStartupFunction(true); // this should grab all the info
			let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(true); // not returning something here because recursion
			log.debug(`[refreshHeroData] RecentWorkouts: `, recentWorkouts);
			if (recentWorkouts.length > 0) {
				let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
				// log.debug(`[serialStartupFunction] activityLedger: `, activityLedger);
				await updateHeroHealth(activityLedger, recentWorkouts, moment());
			}
			setResetting({ visible: false, message: "" });
		}
	}

	async function toggleHealthKit() {
		let healthKitAuthorized = await storageService.getItem(healthKitAuthorizedKey);
		if (healthKitAuthorized === "true") {
			await storageService.setItem(healthKitAuthorizedKey, "false");
			setHealthKitEnabled(false);
		} else {
			await authorizeHealthKit()
				.then(async (authResult) => {
					if (authResult === true) {
						await storageService.setItem(healthKitAuthorizedKey, "true");
						setHealthKitEnabled(true);
					} else {
						alert("Error in authorizing HealthKit");
					}
				})
				.catch((err) => {
					alert("Error in authorizing HealthKit: " + JSON.stringify(err));
				});
		}
	}

	async function toggleGoogleFit() {
		let googleFitAuthorized = await storageService.getItem(googleFitAuthorizedKey);
		if (googleFitAuthorized === "true") {
			await storageService.setItem(googleFitAuthorizedKey, "false");
			setGoogleFitConnected(false);
		} else {
			await googleFitConnect()
				.then(async (result) => {
					log.debug(`[googleFitConnect] result: `, result);
					let authorized = await storageService.getItem(googleFitAuthorizedKey);
					log.debug(`[googleFitConnect] authorized: `, authorized);
					if (result === true) {
						setGoogleFitConnected(true);
					}
				})
				.catch((errorMessage: string) => {
					log.debug(`Error setting up GoogleFit: ${errorMessage}`);
				});
		}
	}

	async function syncHealthKit(confirm: boolean = false) {
		if (confirm === false) {
			setConfirmSync(true);
		} else {
			await serialStartupFunction();
		}
	}

	async function syncGoogleFit(confirm: boolean = false) {
		if (confirm === false) {
			setConfirmSync(true);
		} else {
			await serialStartupFunction();
		}
	}

	async function toggleFitBit() {
		if (fitBitEnabled === true) {
			let result = await disableFitBit().catch((err) => {
				console.error(`[toggleFitBit] Error trying remove fitbit access: `, err);
				alert("Error trying to remove fitbit access: " + JSON.stringify(err));
				throw err;
			});
			log.debug(`[toggleFitBit] Removed fitbit access: `, result);
			alert("Fitbit access removed");
			setFitBitEnabled(false);
		} else {
			App.addListener("appStateChange", async () => {
				let uState = await updateUserState();
				let fitbitConnected = await isFitBitConnected();
				log.debug("[toggleFitBit] fitbitConnected: ", fitbitConnected);
				if (fitbitConnected === true) {
					setFitBitEnabled(true);
				}
			});
			let fbLink = "https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=22BLTK&scope=activity&state=" + userState.user?.userID;
			window.open(fbLink, "_system");
		}
	}

	async function deleteCoachmarks(confirm: boolean = false) {
		if (confirm === false) {
			setConfirmResetTutorial(true);
		} else {
			setConfirmResetTutorial(false);
			await storageService.removeItem("coachMarks");
		}
	}

	async function togglePushNotifications() {
		log.debug(`[toggleNotifications] pushSubscribed: `, pushSubscribed)
		if (pushSubscribed === true) {
			OneSignal.disablePush(true);
			OneSignal.getDeviceState((state: DeviceState) => {
				log.debug(`[toggleNotifications] state: `, JSON.stringify(state));
			})
			setNotificationsChanged(true)
			setPushSubscribed(false)
		} else {
			setPushSubscribed(true)
			OneSignal.disablePush(false);
			OneSignal.promptForPushNotificationsWithUserResponse(true)
			OneSignal.getDeviceState((state: DeviceState) => {
				log.debug(`[toggleNotifications] getDeviceState state: `, JSON.stringify(state));
				log.debug(`[toggleNotifications] getDeviceState state.subscribed: `, state.subscribed);
				setTimeout(() => {
					if (state.subscribed !== pushSubscribed) {
						setNotificationsChanged(true)
					}
					setPushSubscribed(state.subscribed)
				}, 1000)
			})
		}
	}

	async function toggleSetting(setting: string) {
		switch (setting) {
			case "chat":
				setChatSubscribed(!chatSubscribed)
				updateUserSettings({ sendChatNotifications: !chatSubscribed })
				break;
			case "duel":
				setDuelSubscribed(!duelSubscribed)
				updateUserSettings({ sendDuelNotifications: !duelSubscribed })
				break;
			case "email":
				setEmailSubscribed(!emailSubscribed)
				updateUserSettings({ sendWeeklyEmails: !emailSubscribed })
				break;
			case "duelInApp":
				setPromptToDuelInApp(!promptToDuelInApp)
				updateUserSettings({ promptToDuelInApp: !promptToDuelInApp })
				break;
			case "inAppNotifications":
				setShowInAppNotifications(!showInAppNotifications)
				updateUserSettings({ showInAppNotifications: !showInAppNotifications })
				break;
			default:
				break;
		}
	}

	async function changeLiveUpdateVersion(event: SelectCustomEvent<any>) {
		let version = event.detail.value;
		log.debug(`[changeLiveUpdateVersion] version: `, version);
		await storageService.setItem("liveUpdateVersion", version);
		await Deploy.configure({ channel: version });
		await Deploy.reloadApp();
	}

	async function changeBackend(event: SelectCustomEvent<any>) {
		let version = event.detail.value;
		log.debug(`[changeBackend] version: `, version);
		await storageService.setItem("backend", version);
		await Deploy.reloadApp();
	}

	const handleTimeZoneChange = (e: any) => {
		setSelectedTimeZone(e.detail.value);
		updateUserTimezone(e.detail.value)
	};

	async function updatePassword() {
		if (!passwordRef.current.value || passwordRef.current.value.length < 8) {
			setShowChangePassword(false)
			return
		}
		const currentUser: Parse.User | undefined = await Parse.User.current();
		log.debug("Current User: ", currentUser?.toJSON());
		if (currentUser) {
			currentUser.setPassword(passwordRef.current.value);
			currentUser.save().then((resp) => {
				log.debug("Password set: ", resp.toJSON());
			});
			setShowChangePassword(false)
			alert("Password updated")
		} else {
			alert(
				'No user is logged in',
			);
		}
	}

	async function cancelSubscription() {

	}

	async function handleRefresh() {
		console.log('Refreshing data');
		setRefreshing(true);
		refreshData();
		await refreshCustomerInfo();
		setRefreshing(false);
	}

	async function handleManage() {
		smartLog("[handleManage] handleManage: ", subscriptionInfo);
		if (subscriptionInfo && subscriptionInfo.managementURL) {
			window.open(subscriptionInfo.managementURL, "_system");
		}
	}

	return (
		<IonPage>
			<IonHeader>
				<IonToolbar className="details-toolbar">
					<IonButtons slot="start">
						<IonBackButton text="" color="primary" />
					</IonButtons>
					<IonTitle>Settings</IonTitle>
				</IonToolbar>
			</IonHeader>
			<IonContent>
				<IonLoading isOpen={refreshing} onDidDismiss={() => setLoading(false)} message={"refreshing data"} duration={7000} backdropDismiss />
				<IonModal id="set-password-modal" isOpen={showChangePassword} onDidDismiss={() => setShowChangePassword(false)}>
					<div style={{ margin: "auto 20px auto 20px" }}>
						<IonLabel position="stacked">New Password</IonLabel>
						<div style={{ display: "flex", alignItems: "center" }} >
							<IonInput
								ref={passwordRef}
								name="password"
								value={passwordRef.current?.value || ""}
								type={inputField === "password" ? "password" : "text"}
								placeholder="  (min. 8 characters)"
								minlength={8}
								enterkeyhint={"done"}
								// onFocus={() => setPasswordError({ visible: false, message: "" })}
								onIonChange={(e) => setPassword(e.detail.value!)}
								required
							></IonInput>
							<IonIcon
								style={{ fontSize: "28px" }}
								onClick={() => {
									inputField === "password" ? setInputField("text") : setInputField("password");
								}}
								icon={eyeOutline} />
						</div>
						<IonButton color={"success"} style={{ marginTop: "20px" }} onClick={() => updatePassword()}>Save</IonButton>
					</div>
				</IonModal>
				{ }
				<IonModal className="paywall-modal" isOpen={showPaywall} onDidDismiss={() => setShowPaywall(false)} style={isMobile === false ? { "--height": '80%' } : {}}>
					<PayWallClassDojoComponent modal={true} setShowPaywall={setShowPaywall} />
				</IonModal>
				<IonAlert
					isOpen={notificationsChanged}
					header="Preferences Changes"
					message="Your preferences have been updated"
					buttons={[
						{
							text: "Great",
							role: "ok",
							handler: () => {
								setNotificationsChanged(false)
							},
						},
					]}
				/>
				<IonAlert
					isOpen={confirmReset}
					header="Confirm Reset"
					message="Are you sure you'd like to reset your hero? This process is will take some time"
					buttons={[
						{
							text: "Do NOT reset",
							role: "cancel",
							handler: () => {
								setConfirmReset(false);
							},
						},
						{
							text: "Yes, RESET my hero",
							role: "ok",
							handler: () => {
								resetHero(true);
							},
						},
					]}
				/>
				<IonAlert
					isOpen={confirmDelete}
					header="Confirm Deletion"
					message="Are you sure you'd like to delete your account? This process is irreversible"
					buttons={[
						{
							text: "Do NOT delete",
							role: "cancel",
							handler: () => {
								setConfirmDelete(false);
							},
						},
						{
							text: "Yes, DELETE my account",
							role: "ok",
							handler: () => {
								deleteAccount();
							},
						},
					]}
				/>
				<IonAlert
					isOpen={confirmResetTutorial}
					header="Confirm Reset Tutprial"
					message="Are you sure you'd like to reset the tutorial?"
					buttons={[
						{
							text: "Do NOT reset",
							role: "cancel",
							handler: () => {
								setConfirmResetTutorial(false);
							},
						},
						{
							text: "Yes, reset the tutorial",
							role: "ok",
							handler: () => {
								deleteCoachmarks(true);
							},
						},
					]}
				/>
				<IonAlert
					isOpen={confirmSync}
					header="Confirm Data Sync"
					message="Are you sure you'd like to sync your data? This can take a couple minutes depending on how active you are"
					buttons={[
						{
							text: "Do NOT sync",
							role: "cancel",
							handler: () => {
								setConfirmDelete(false);
							},
						},
						{
							text: "Yes, sync my data",
							role: "ok",
							handler: () => {
								syncHealthKit(true);
							},
						},
					]}
				/>
				<IonLoading isOpen={loading} onDidDismiss={() => setLoading(false)} message={"Checking for update"} duration={10000} backdropDismiss />
				<IonLoading isOpen={processing} onDidDismiss={() => setProcessing(false)} message={"Deleting your account"} duration={5000} backdropDismiss />
				<IonLoading isOpen={syncing} onDidDismiss={() => setSyncing(false)} message={"Syncing your data"} backdropDismiss />

				<IonLoading isOpen={resetting.visible} onDidDismiss={() => setProcessing(false)} message={resetting.message} backdropDismiss />
				<div style={{ marginLeft: "10px" }}>Account Information</div>
				<IonCard style={{ marginTop: "5px" }}>
					<IonItem className='profile-background' >
						<div style={{ width: "100%" }}>Email: {userState.user?.email}</div>
					</IonItem>
					<IonItem className='profile-background' >
						<div style={{ width: "100%" }}>Password: **************</div>
						<div>
							<IonButton onClick={() => setShowChangePassword(true)}>Change</IonButton>
						</div>
					</IonItem>
					<IonItem className='profile-background' >
						{currentlySubscribed === true && subscriptionInfo ? (
							<>
								<div style={{ width: "100%" }}>
									<p>Current Membership: <span className="emphasis-text">PowerUp</span></p>
									<p>Purchased: {moment(subscriptionDates.purchase_date).format("YYYY-MM-DD h:mm a")}</p>
									{subscriptionDates.renewal_date && (
										<p>Renews: {moment(subscriptionDates.renewal_date).format("YYYY-MM-DD h:mm a")}</p>
									)}
									{subscriptionDates.expires_date && (
										<p>Expires: {moment(subscriptionDates.expires_date).format("YYYY-MM-DD h:mm a")}</p>
									)}
									{subscriptionDates.expires_date === null && subscriptionDates.purchase_date && (// lifetime
										<p>Expires: Never</p>
									)}
								</div>
								<div style={{ textAlign: "right" }}>
									<IonButton onClick={handleRefresh}>refresh</IonButton>
									{subscriptionDates.renewal_date && (
										<IonButton onClick={handleManage}>manage</IonButton>
									)}
								</div>
							</>

						) : (
							<>
								<div style={{ width: "100%" }}>
									Current Membership: Basic
								</div>
								<div style={{ textAlign: "right" }}>
									<IonButton color={"success"} onClick={() => setShowPaywall(true)}>Upgrade</IonButton>
									<IonButton onClick={handleRefresh}>Restore</IonButton>
								</div>
							</>

						)}
					</IonItem>
				</IonCard>
				<div style={{ marginLeft: "10px" }}>Tracker Connections</div>
				<IonCard style={{ marginTop: "5px" }}>
					<IonItem className='profile-background' >
						<div style={{ width: "100%" }}>FitBit Connected: </div>
						<div>
							<IonToggle checked={fitBitEnabled} onClick={toggleFitBit} color="tertiary" />
						</div>
					</IonItem>
					{platform === "ios" && (
						<>
							<IonItem className='profile-background'>
								<div style={{ width: "100%" }}>
									HealthKit Connected:{" "}
									<p style={{ fontSize: "12px", width: "80%" }}>Syncing with HealthKit will automatically pull your data in and award your FitHero points</p>
								</div>
								<div>
									<IonToggle checked={healthKitEnabled} onClick={toggleHealthKit} color="tertiary" />
								</div>
							</IonItem>
							{healthKitEnabled && healthKitEnabled === true ? (
								<IonItem className='profile-background'>
									<div style={{ width: "100%" }}>Sync data from HealthKit</div>
									<div>
										<IonButton onClick={() => syncHealthKit(false)}>sync</IonButton>
									</div>
								</IonItem>
							) : (
								""
							)}
						</>
					)}
					{platform === "android" && (
						<>
							<IonItem className='profile-background'>
								<div style={{ width: "100%" }}>
									GoogleFit Connected:{" "}
									<p style={{ fontSize: "12px", width: "80%" }}>Syncing with GoogleFit will automatically pull your data in and award your FitHero points</p>
								</div>
								<div>
									<IonToggle checked={googleFitEnabled} onClick={toggleGoogleFit} color="tertiary" />
								</div>
							</IonItem>
							{googleFitConnected && googleFitConnected === true ? (
								<IonItem className='profile-background'>
									<div style={{ width: "100%" }}>Sync data from GoogleFit</div>
									<div>
										<IonButton onClick={() => syncGoogleFit(false)}>sync</IonButton>
									</div>
								</IonItem>
							) : (
								""
							)}
						</>
					)}
				</IonCard>
				<div style={{ marginLeft: "10px" }}>Misc. Settings</div>
				<IonCard style={{ marginTop: "5px" }}>
					<IonItem className='profile-background' >
						<div style={{ width: "100%" }}>Reset Tutorial</div>
						<div>
							<IonButton onClick={() => deleteCoachmarks()}>Reset</IonButton>
						</div>
					</IonItem>
					<IonItem className='profile-background'>
						<div style={{ width: "100%" }}>Timezone</div>
						<div>
							<IonSelect value={selectedTimeZone} onIonChange={handleTimeZoneChange} placeholder="Select Time Zone">
								{timeZoneOptions.map((zone, index) => (
									<IonSelectOption key={index} value={zone.value}>
										{zone.label}
									</IonSelectOption>
								))}
							</IonSelect>
						</div>
					</IonItem>
				</IonCard>
				<div style={{ marginLeft: "10px" }}>Notification Settings</div>
				<IonCard style={{ marginTop: "5px" }}>
					<IonItem className='profile-background' >
						<div style={{ width: "100%" }}>Push Notifications</div>
						<div>
							{pushSubscribed === true ? (
								<IonButton color={"success"} onClick={() => togglePushNotifications()}>Enabled</IonButton>
							) : (
								<IonButton color={"danger"} onClick={() => togglePushNotifications()}>Disabled</IonButton>
							)}
						</div>
					</IonItem>
					{pushSubscribed === true && (
						<>
							<IonItem className='profile-background'>
								<div style={{ width: "100%" }}>Chat Notifications</div>
								<div>
									{chatSubscribed === true ? (
										<IonButton color={"success"} onClick={() => toggleSetting("chat")}>Enabled</IonButton>
									) : (
										<IonButton color={"danger"} onClick={() => toggleSetting("chat")}>Disabled</IonButton>
									)}
								</div>
							</IonItem>
							<IonItem className='profile-background'>
								<div style={{ width: "100%" }}>Duel Notifications</div>
								<div>
									{duelSubscribed === true ? (
										<IonButton color={"success"} onClick={() => toggleSetting("duel")}>Enabled</IonButton>
									) : (
										<IonButton color={"danger"} onClick={() => toggleSetting("duel")}>Disabled</IonButton>
									)}
								</div>
							</IonItem>

						</>
					)}
					<IonItem className='profile-background'>
						<div style={{ width: "100%" }}>Weekly Email Updates</div>
						<div>
							{emailSubscribed === true ? (
								<IonButton color={"success"} onClick={() => toggleSetting("email")}>Enabled</IonButton>
							) : (
								<IonButton color={"danger"} onClick={() => toggleSetting("email")}>Disabled</IonButton>
							)}
						</div>
					</IonItem>
					<IonItem className='profile-background'>
						<div style={{ width: "100%" }}>Prompt to Duel Notifications</div>
						<div>
							{promptToDuelInApp === true ? (
								<IonButton color={"success"} onClick={() => toggleSetting("duelInApp")}>Enabled</IonButton>
							) : (
								<IonButton color={"danger"} onClick={() => toggleSetting("duelInApp")}>Disabled</IonButton>
							)}
						</div>
					</IonItem>
					<IonItem className='profile-background'>
						<div style={{ width: "100%" }}>Show In-App Notifications</div>
						<div>
							{showInAppNotifications === true ? (
								<IonButton color={"success"} onClick={() => toggleSetting("inAppNotifications")}>Enabled</IonButton>
							) : (
								<IonButton color={"danger"} onClick={() => toggleSetting("inAppNotifications")}>Disabled</IonButton>
							)}
						</div>
					</IonItem>
				</IonCard>
				<div style={{ marginLeft: "10px" }}>Support & Community</div>
				<IonCard style={{ marginTop: "5px" }}>
					<IonItem className='profile-background' >
						<div style={{ width: "100%" }}>Email</div>
						<div style={{ width: "100%", textAlign: "right" }}>
							<a href="mailto:support@fithero.ai">support@fithero.ai</a>
						</div>
					</IonItem>
					<IonItem className='profile-background'>
						<div style={{ width: "100%" }}>Facebook Group</div>
						<div style={{ width: "100%", textAlign: "right" }}>
							<a href="https://www.facebook.com/groups/fithero/" target="_new">FitHero Community</a>
						</div>
					</IonItem>
				</IonCard>
				{adminEmails.includes(userState.user?.email) && (
					<><div style={{ marginLeft: "10px" }}>Admin Information</div>
						<IonCard style={{ marginTop: "5px" }}>
							<IonItem className='profile-background' >
								<div style={{ width: "100%" }}>Live Update Version</div>
								<div>
									<IonSelect value={liveUpdateVersion} interface='action-sheet' onIonChange={changeLiveUpdateVersion}>
										{liveUpdateVersions.map((option, index) => (
											<IonSelectOption key={index} value={option}>{option}</IonSelectOption>
										))}
									</IonSelect>
								</div>
							</IonItem>
							<IonItem className='profile-background' >
								<div style={{ width: "100%" }}>Backend Version</div>
								<div>
									<IonSelect value={backend} onIonChange={changeBackend}>
										{backends.map((option, index) => (
											<IonSelectOption key={index} value={option}>{option}</IonSelectOption>
										))}
									</IonSelect>
								</div>
							</IonItem>
							{/* <IonItem className='profile-background'>
								<div style={{ width: "100%" }}>Smartlook Recording</div>
									<div>
										{smartLookInfo.isRecording}
									</div>
							</IonItem>
							<IonItem className='profile-background'>
								<div style={{ width: "100%" }}>Smartlook Status</div>
									<div>
										{smartLookInfo.smartLookStatus}
									</div>
							</IonItem> */}
						</IonCard>
					</>
				)}
				<div style={{ marginLeft: "10px" }}>Danger Zone</div>
				<IonCard style={{ marginTop: "5px" }}>
					<IonItem className='profile-background' detail={false}>
						<IonButton onClick={() => resetHero(false)} expand="block">
							<IonIcon icon={refreshOutline} /> Reset hero
						</IonButton>
					</IonItem>
					<IonItem className='profile-background' detail={false}>
						<IonButton onClick={() => forceUpdate()} expand="block">
							<IonIcon icon={logOutOutline} /> Force Update
						</IonButton>
					</IonItem>
					<IonItem className='profile-background' detail={false}>
						<IonButton onClick={() => lo()} expand="block">
							<IonIcon icon={logOutOutline} /> Logout
						</IonButton>
					</IonItem>
					<IonItem className='profile-background' detail={false}>
						<IonButton onClick={() => deleteAccount()} expand="block" color={"danger"}>
							<IonIcon icon={trashOutline} /> Delete account
						</IonButton>
					</IonItem>
				</IonCard>
			</IonContent>
		</IonPage>
	);
};

export default Settings;

const timeZoneOptions: TimeZoneOption[] = [
	{ value: 'Pacific/Honolulu', label: 'Hawaii (HST)' },
	{ value: 'America/Anchorage', label: 'Alaska (AKDT)' },
	{ value: 'America/Los_Angeles', label: 'Pacific Time (PT)' },
	{ value: 'America/Denver', label: 'Mountain Time (MT)' },
	{ value: 'America/Chicago', label: 'Central Time (CT)' },
	{ value: 'America/New_York', label: 'Eastern Time (ET)' },
	{ value: 'America/Sao_Paulo', label: 'Brazil (BRT)' },
	{ value: 'Atlantic/Azores', label: 'Azores (AZOT)' },
	{ value: 'Europe/London', label: 'United Kingdom (GMT/BST)' },
	{ value: 'Europe/Paris', label: 'Central European (CET/CEST)' },
	{ value: 'Europe/Athens', label: 'Eastern European (EET/EEST)' },
	{ value: 'Africa/Johannesburg', label: 'South Africa (SAST)' },
	{ value: 'Asia/Dubai', label: 'United Arab Emirates (GST)' },
	{ value: 'Asia/Kolkata', label: 'India (IST)' },
	{ value: 'Asia/Bangkok', label: 'Thailand (ICT)' },
	{ value: 'Asia/Hong_Kong', label: 'Hong Kong (HKT)' },
	{ value: 'Asia/Tokyo', label: 'Japan (JST)' },
	{ value: 'Asia/Sydney', label: 'Australia - Sydney (AEST/AEDT)' },
	{ value: 'Pacific/Auckland', label: 'New Zealand (NZST/NZDT)' }
];

interface TimeZoneOption {
	value: string;
	label: string;
}
