/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { hasCoachMarkBeenShown, updateCoachMark } from '../../services/HelperService';
import { Step, Steps } from 'intro.js-react';
import { replaceStepHeroTextBubble } from '../../services/TourService';
import GlobalState from '../../contexts/GlobalState';
import { useHistory } from 'react-router';
import log from 'loglevel';
import moment from 'moment';
import { sendSlackNotification } from '../../services/NotificationService';
import { logGenericEvent } from '../../services/AnalyticsService';

export const TourComponent = (props: { coachMarkName: string, steps: Step[], modalShowing: boolean, link?: string, action?: any }) => {
    const history = useHistory();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { heroObject, userState, shouldShowNotificationModal } = globalState;
    const [stepsEnabled, setStepsEnabled] = useState<boolean>(false);
    const [steps, setSteps] = useState<Step[]>([]);
    const [isReady, setIsReady] = useState<boolean>(false);
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [tryToExit, setTryToExit] = useState<number>(0);
    const [readyCheck, setReadyCheck] = useState<number>(0);

    useEffect(() => {
        async function checkCoachMark() {
            log.debug(`Checking coachmark for ${props.coachMarkName}`)
            let coachMark = await hasCoachMarkBeenShown(props.coachMarkName);
            // coachMark = false; // remove this line when ready to show coach marks
            log.debug("Coach mark", coachMark);
            if (userState.user?.createdAt && moment(userState.user?.createdAt).isBefore("2024-05-01")) {
                await coachMarkComplete(true);
            } else if (!coachMark) {
                replaceStepData(props.steps);
                setStepsEnabled(true);
                log.debug(`[checkCoachMark] Current Step: ${currentStep}, Max Steps: ${steps.length}`);
            }
        }
        checkCoachMark();
        // log.debug("props: ", props);
        // setStepsEnabled(true);
    }, [props.coachMarkName, props.steps, shouldShowNotificationModal]);

    useEffect(() => {
        // Example condition: check if an element exists in the DOM
        const checkIfReady = () => {
            // iterate over props.steps and check if all the elements exists in the DOM
            let isReady = true;
            if (props.steps.length === 0) return isReady;
            props.steps.forEach((step) => {
                if (step.element) {
                    if (!document.querySelector(step.element as string)) {
                        isReady = false;
                    }
                }
            });
            return isReady;
        };

        // Polling for the condition to be true
        const intervalId = setInterval(() => {
            let ready = checkIfReady();
            log.debug("Ready", ready);
            if (checkIfReady()) {
                setIsReady(true);
                clearInterval(intervalId);
            } else {
                setReadyCheck(readyCheck + 1);
                if (readyCheck > 50) {
                    log.debug("Ready check failed");
                    clearInterval(intervalId);
                }
            }
        }, 100); // Check every 100ms

        return () => clearInterval(intervalId);
    }, []);

    async function coachMarkComplete(olderUser: boolean = false) {
        // // log.debug("Coach marks complete");
        await updateCoachMark(props.coachMarkName, true);
        if (olderUser === false) {
            sendSlackNotification(`✅ *[COACHMARK COMPLETED]*\n*Coach mark*: ${props.coachMarkName}\n*Hero Name*: ${heroObject?.heroName}\n*User First Name*: ${userState.user?.firstName} \n*UserID*:${userState.user?.userID}`, "keyEvent");
            logGenericEvent(`Coachmark Completed [${props.coachMarkName}]`);
        }
        setStepsEnabled(false);
    }

    function replaceStepData(steps: any) {
        // store a copy of the steps
        let stepsCopy = JSON.parse(JSON.stringify(steps));
        let replacedSteps = replaceStepHeroTextBubble(stepsCopy, heroObject, userState.user);
        setSteps(replacedSteps);
    }

    async function stepsOnComplete() {
        log.debug(`Steps complete for coachmark ${props.coachMarkName}`);
        // log.debug(`[stepsOnComplete] Step: ${currentStep}, Max Steps: ${steps.length}`);
        await coachMarkComplete();
        if (props.link) {
            history.push(props.link + "?tour=true")
        }
        if (props.action) {
            props.action();
        }
    }

    async function stepsOnExit() {
        log.debug(`Steps exited for coachmark ${props.coachMarkName}`);
        log.debug(`[stepsOnExit] Current Step: ${currentStep}, Max Steps: ${steps.length}`);
        if (isNaN(currentStep)) {
            log.debug("[stepsOnExit] Current Step is NaN");
            return;
        }
        if (currentStep && isNaN(currentStep) === false && currentStep >= steps.length) {
            log.debug("[stepsOnExit] All steps completed");
            await stepsOnComplete();
        } else {
            log.debug("[stepsOnExit] All steps NOT completed");
            await coachMarkComplete();
        }
    }

    // function isLastStep() {
    //     if (currentStep >= steps.length) {return true} else {return false}
    // }

    function isLastStep(stepIndex: number): false | void {
        let tryToExitLocal = tryToExit + 1;
        log.debug(`[isLastStep] Current Step: ${currentStep}, Max Steps: ${steps.length}, Try to Exit: ${tryToExitLocal}`)
        setTryToExit(tryToExitLocal);
        if (tryToExitLocal > 3) {
            log.debug("Ok, go ahead and exit");
            return;
        }
        if (currentStep < steps.length) {
            return false;
        } else {
            return;
        }
    }

    // Function to handle the step change
    function handleStepChange(newStepIndex: number) {
        setCurrentStep(newStepIndex + 1);
        log.debug(`[handleStepChange] Current Step: ${currentStep}, Max Steps: ${steps.length}`);
    };

    return (
        <>
            {stepsEnabled && steps.length > 0 && shouldShowNotificationModal === false && props.modalShowing === false && isReady === true && (
                <Steps
                    enabled={stepsEnabled}
                    steps={steps}
                    initialStep={0}
                    onExit={() => stepsOnExit()}
                    // onComplete={() => stepsOnComplete()}
                    onBeforeExit={(stepIndex) => isLastStep(stepIndex)}
                    onChange={(stepIndex) => handleStepChange(stepIndex)}
                    options={{
                        showBullets: false,
                        exitOnOverlayClick: true, // needs to be true to click through
                        exitOnEsc: true,
                        showButtons: true,
                        disableInteraction: false,
                        hidePrev: true,
                        nextLabel: "▶",
                        prevLabel: "◀",
                        doneLabel: "✔",
                    }}
                />
            )}
        </>

    )
}
