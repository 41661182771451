import React, { useRef, useState, useEffect, FunctionComponent } from 'react';
import { Share } from '@capacitor/share';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { IonButton, IonIcon } from '@ionic/react';
import { logoInstagram, shareSocial } from 'ionicons/icons';

const InstagramShare = (props: { shareCode: string }) => {
    const [imageUri, setImageUri] = useState<string>('');
    const canvasRef = useRef<HTMLCanvasElement | null>(null);

    useEffect(() => {
        const loadImage = async () => {
            const canvas = canvasRef.current;
            const context = canvas?.getContext('2d');
            if (context && canvas) {
                const image = new Image();
                image.onload = () => {
                    canvas.width = image.width;
                    canvas.height = image.height;
                    context.drawImage(image, 0, 0);
                    context.font = '80px Arial';
                    context.fillStyle = 'white';
                    context.fillText(props.shareCode, 350, 1730);
                    canvas.toBlob(async (blob) => {
                        if (blob) {
                            const fileReader = new FileReader();
                            fileReader.onload = async () => {
                                const base64 = fileReader.result as string;
                                const savedFile = await saveImageLocally(base64);
                                setImageUri(savedFile.uri);
                            };
                            fileReader.readAsDataURL(blob);
                        }
                    });
                };
                image.src = '/assets/images/promotions/share-image.jpg'; // Adjust the path as needed
            }
        };
        loadImage();
    }, [props.shareCode]);

    const saveImageLocally = async (base64String: string): Promise<{ uri: string }> => {
        const fileName = `shared_image_${new Date().getTime()}.jpeg`;
        await Filesystem.writeFile({
            path: fileName,
            data: base64String,
            directory: Directory.Cache,
        });
        return await Filesystem.getUri({
            directory: Directory.Cache,
            path: fileName,
        });
    };

    const shareImage = async () => {
        try {
            await Share.share({
                files: [imageUri],
                dialogTitle: 'Share to Instagram Story',
            });
        } catch (error) {
            console.error('Error sharing', error);
        }
    };

    return (
        <div className='share-component-div'>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
            <IonButton style={{ color: "white" }} size='large' onClick={shareImage} disabled={!imageUri}>Share invite  <IonIcon style={{ marginLeft: "10px" }} icon={shareSocial} /></IonButton>
        </div>
    );
};

export default InstagramShare;
