import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react'
import React, { useContext } from 'react'
import GlobalState from '../../../contexts/GlobalState';
import CreateAvatarComponent from './CreateAvatarComponent';

export const UpdateAvatar = (props: { upload: boolean }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const { isMobile } = globalState;
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar style={{ textAlign: "center" }}>
                    {isMobile && (
                        <IonButtons slot="start">
                            <IonBackButton text="" />
                        </IonButtons>
                    )}
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <CreateAvatarComponent onboarding={false} upload={props.upload} />
            </IonContent>
        </IonPage>
    )
}
