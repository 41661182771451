import { IonAvatar, IonButton, IonButtons, IonHeader, IonModal, IonToolbar } from '@ionic/react'
import log from 'loglevel'
import React from 'react'
import { useHistory } from 'react-router';
import { getHero } from '../../../services/UserService';
import { UserAvatar } from '../../../components/UserAvatar';


const ProfileHeader = (props: { heroName: string | undefined, heroPicture: string | undefined, heroLevel: number | undefined, setShowChangeAvatarModal?: React.Dispatch<React.SetStateAction<boolean>> }) => {
    const history = useHistory();
    const [showAvatarModal, setShowAvatarModal] = React.useState<boolean>(false)

    async function tapAvatar() {
        // log.debug("Avatar tapped")
        // let hero = await getHero()
        // if (hero.heroName === props.heroName) {
        //     // if (props.setShowChangeAvatarModal) {
        //     //     props.setShowChangeAvatarModal(true)
        //     // }
        //     history.push("/profile/updateAvatar")
        // } else {
        //     setShowAvatarModal(true)
        // }
    }

    return (
        <>
            {props.heroName && props.heroPicture && (<div className="toolbar-avatar-holder">
                <IonModal isOpen={showAvatarModal} onDidDismiss={() => setShowAvatarModal(false)}>
                    <IonHeader style={{ "--background": "var(--ion-background-color, #fff)" }}>
                        <IonToolbar>
                            <IonButtons slot="end">
                                <IonButton strong={true} onClick={() => setShowAvatarModal(false)}>
                                    X
                                </IonButton>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>

                    <img src={props.heroPicture} alt="" className="other-user-avatar" />
                </IonModal>
                <IonAvatar className="member-image" style={{ margin: "auto" }} onClick={() => tapAvatar()}>
                    <UserAvatar avatarImage={props.heroPicture} />
                </IonAvatar>
                {props.heroName}
                {props.heroLevel && (
                    <div>
                        Level {props.heroLevel < 1 ? 1 : props.heroLevel}
                    </div>
                )}
            </div>)}
        </>

    )
}

export default ProfileHeader