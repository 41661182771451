/* eslint-disable react-hooks/exhaustive-deps */
import { IonButton, IonCol, IonContent, IonFooter, IonGrid, IonModal, IonPage, IonRow, IonSpinner } from "@ionic/react";
import "intro.js/introjs.css";
import { useContext, useEffect, useState } from "react";
import VillainProfile from "../../Features/Villians/components/VillainProfile";
import HeaderLogo from "../../components/HeaderLogo";
import ProfileHeader from "../../Features/Profile/components/ProfileHeader";
import GlobalState from "../../contexts/GlobalState";
import { OnboardingObject } from "../../models/userModel";
import { Villain } from "../../models/villainModels";
import { StorageService } from "../../services/StorageService";
import { getVillains } from "../../services/VillainServices";

const DuelsExplained = () => {
	const nextPage = "/onboarding/startfirstduel";
	const storageService = new StorageService();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { heroObject, setHeroObject, isMobile } = globalState;
	const [onboardingObject, setOnboardingObject] = useState<OnboardingObject>();
	const [showModal, setShowModal] = useState({ show: false, villain: {} as Villain });
	const [villains, setVillains] = useState<Villain[]>([]);

	useEffect(() => {
		updateOnboardingObject();
	}, []);

	useEffect(() => {
		async function pullVillains() {
			setVillains(await getVillains());
		}
		pullVillains();
	}, []);

	async function updateOnboardingObject() {
		let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
		if (!heroObject && tempOnboardingObject.hero) {
			setHeroObject(tempOnboardingObject.hero);
		}
		tempOnboardingObject.url = window.location.pathname;
		tempOnboardingObject.completed = false;
		setOnboardingObject(tempOnboardingObject);
		await storageService.setObject("onboarding", tempOnboardingObject);
	}

	return (
		<IonPage>
			<HeaderLogo showBackButton={false} />
			<IonContent>
				<IonModal
					isOpen={showModal.show}
					onWillDismiss={() => {
						setShowModal({ show: false, villain: {} as Villain });
					}}
					style={isMobile ? {} : { "--height": "90%" }}
				>
					<VillainProfile villain={showModal.villain} inOnboarding={true} setShowModal={setShowModal} alreadyScaled={true} difficulty="medium" />
				</IonModal>
				<div className="onboarding-title">How FitHero Works</div>
				<ProfileHeader heroName={onboardingObject?.heroName} heroPicture={onboardingObject?.heroPicture} heroLevel={heroObject?.overallLevel} />
				<div className="onboarding-text-area">
					<p>In FitHero you <span className="onboarding-emphasis">battle AI villains.</span></p>
					<p>Villains represent unhealthly lifestyles, poor choices or just wild and weird characters.</p>
					<p>You will <span className="onboarding-emphasis">fight villains to the death</span> and the only way to beat them is <span className="onboarding-emphasis">to be active.</span></p>
					<p>Below are just some of the villains you'll face!</p>
				</div>
				<div>
					{villains.length === 0 && <div style={{ textAlign: "center", margin: "20px" }}>Loading villains... <br /><IonSpinner /></div>}
					<IonGrid>
						{villains.map((villain, index) => {
							return index % 3 === 0 ? (
								<IonRow key={index}>
									{villains.slice(index, index + 3).map((villain, i) => (
										<IonCol className={index === 0 ? "showHint" : ""} size="4" key={i} onClick={() => setShowModal({ show: true, villain: villain })}>
											<div>
												<img className="villain-avatar" src={villain.imageURL} alt={`${villain.name} avatar`} />
											</div>
											<div className="villian-name">{villain.name}</div>
										</IonCol>
									))}
								</IonRow>
							) : null;
						})}
					</IonGrid>
				</div>
			</IonContent>
			<IonFooter className="onboarding-footer">
				<IonButton className="onboarding-advance-button" routerLink={nextPage} >
					Ok
				</IonButton>
			</IonFooter>
		</IonPage>
	);
};

export default DuelsExplained;
