import { Device, DeviceInfo } from "@capacitor/device";
import { LocalNotifications } from "@capacitor/local-notifications";
import { CustomerInfo, LOG_LEVEL, PRODUCT_CATEGORY, Purchases } from "@revenuecat/purchases-capacitor";
import log from "loglevel";
import moment, { Moment } from "moment";
import OneSignal from "onesignal-cordova-plugin";
import { ReactNode, createContext, useState } from "react";
import { HeroStatsUpdate, NotificationObject, NotificationType, SampleDuelStatus, SampleUpdateObject } from "../models/celebrationModels";
import { ActivityLedger, ExercisePoints, SummaryType, WorkoutObject } from "../models/exerciseModels";
import { Goal, goalsObjectKey } from "../models/goalsModels";
import { Mission } from "../models/missionModels";
import { PaywallFeature, Product, REVENUECAT_ANDROID_KEY, REVENUECAT_IOS_KEY, SubscriptionDates, sampleFeatures } from "../models/paywallConstants";
import { SidekickDBObject } from "../models/sideKickModels";
import { Skill } from "../models/skills";
import {
	ExistingData,
	HeroObject,
	OnboardingObject,
	SettingsObject,
	UserObject,
	UserState,
	activityLedgerKey,
	googleFitAuthorizedKey,
	healthKitAuthorizedKey,
	heroObjectKey,
	lastUpdatedStepsKey,
	lastUpdatedWorkoutKey,
	userIDKey,
	userObjectKey,
	workoutKey,
} from "../models/userModel";
import { Suggestion, fetchAllUserVillainTally, getAllDuels, isUserInADuel } from "../services/DuelsServics";
import { createLedgerFromWorkoutArray, getRecentWorkouts, getSumPoints, upsertWorkouts } from "../services/ExerciseService";
import { isFitBitConnected } from "../services/FitbitService";
import { getAllGoals, getCurrentGoalFromDB, updateGoal } from "../services/GoalsService";
import { processWorkoutsFromGoogleFit } from "../services/GoogleFitService";
import { HKData, processWorkoutsFromHealthKit } from "../services/HealthKitService";
import { getUpdatedHero, timeBetweenDates } from "../services/HelperService";
import { HeroMessagesResponse, getHeroMessagesFromDB } from "../services/HerochatService";
import { smartLog } from "../services/LoggingService";
import { MissionGenerator } from "../services/MissionService";
import { getCustomerInfoWeb, getProductListForPaywall, getUserSubscriptionObjectWeb } from "../services/PaywallService";
import { activateGoalSkill } from "../services/SkillsService";
import { StorageService } from "../services/StorageService";
import { chooseSpecialization, getHero, getUser, getUserSettings, refreshUserObject } from "../services/UserService";



log.enableAll();
const storageService = new StorageService();
interface GlobalStatesProps {
	children: ReactNode;
}

interface GlobalStateContextType {
	serialStartupFunction: (value?: boolean) => Promise<UserObject | null>;
	heroObject: HeroObject | undefined;
	previousHeroObject: HeroObject | undefined;
	healthKitConnected: boolean;
	setHealthKitConnected: (value: boolean) => void;
	googleFitConnected: boolean;
	setGoogleFitConnected: (value: boolean) => void;
	fitBitConnected: boolean;
	setFitBitConnected: (value: boolean) => void;
	clearStoredHeroObject: () => void; // replace with the actual function signature
	updateHeroHealth: (ledger: ActivityLedger[], workoutArray: WorkoutObject[], lastUpdated: Moment, resetHero?: boolean) => Promise<NotificationObject>;
	summary: SummaryType[];
	activityLedger: ActivityLedger[];
	workoutData: WorkoutObject[];
	lastUpdatedText: string;
	setLastUpdatedText: (value: string) => void;
	getSavedMissions: () => Promise<Mission[] | null>;
	saveMissions: (missions: Mission[]) => Promise<void>;
	currentMissions: Mission[];
	getExistingData: () => Promise<ExistingData>;
	lastUpdated: string;
	setLastUpdated: (value: string) => void;
	updateUserState: (userObject?: UserObject | null) => Promise<UserState | null>;
	userState: UserState;
	currentGoal: Goal | null;
	setCurrentGoal: (goal: Goal | null) => void;
	subtractPointsFromHeroObject: (points: ExercisePoints) => Promise<void>;
	setActivityLedger: (ledger: ActivityLedger[]) => void;
	setIsMobile: (value: boolean) => void;
	isMobile: boolean | undefined;
	logout: () => void;
	onboardingCompleted: boolean | undefined;
	setOnboardingCompleted: (value: boolean) => void;
	setHeroObject: (value: HeroObject) => void;
	setPreviousHeroObject: (value: HeroObject) => void;
	refreshHeroData: (resetHero: boolean) => Promise<void>;
	loadingStage: string;
	addNotificationToQueue: (value: NotificationObject) => Promise<void>;
	shouldShowNotificationModal: boolean;
	setShouldShowNotificationModal: (value: boolean) => void;
	sidekick: SidekickDBObject | undefined;
	setSidekick: (value: SidekickDBObject) => void;
	sidekickLoadingFinished: boolean;
	setSidekickLoadingFinished: (value: boolean) => void;
	heroLoadingFinished: boolean;
	setHeroLoadingFinished: (value: boolean) => void;
	heroMessagesLoadingFinished: boolean;
	setHeroMessagesLoadingFinished: (value: boolean) => void;
	duelLoadingFinished: boolean;
	setDuelLoadingFinished: (value: boolean) => void;
	sidekickNewMessages: boolean;
	setSidekickNewMessages: (value: boolean) => void;
	heroNewMessages: number;
	setHeroNewMessages: (value: number) => void;
	productsForPaywall: Product[] | undefined;
	paywallFeatures: PaywallFeature[] | undefined;
	currentlySubscribed: boolean;
	setCurrentlySubscribed: (value: boolean) => void;
	subscriptionInfo: CustomerInfo | undefined;
	setSubscriptionInfo: (value: CustomerInfo) => void;
	paywallEnabled: boolean;
	setupRevenueCat: (platform?: string, user?: UserObject) => Promise<Product[]>;
	subscriptionDates: SubscriptionDates;
	setSubscriptionDates: (value: SubscriptionDates) => void;
	loadCurrentGoal: () => Promise<{
		strengthGoalJustAccomplished: boolean,
		cardioGoalJustAccomplished: boolean,
		mobilityGoalJustAccomplished: boolean,
		mindfulnessGoalJustAccomplished: boolean
	}>
	helpNeeded: Suggestion[];
	setHelpNeeded: (value: Suggestion[]) => void;
	ownedSkills: Skill[];
	setOwnedSkills: (value: Skill[]) => void;
}

export const GlobalState = createContext<GlobalStateContextType | undefined>(undefined);

export const GlobalStates = ({ children }: GlobalStatesProps) => {
	const [userState, setUserstate] = useState<UserState>({
		authenticated: false,
		user: null,
		onboarding: undefined,
	});
	const [healthKitConnected, setHealthKitConnected] = useState(false);
	const [googleFitConnected, setGoogleFitConnected] = useState(false);
	const [fitBitConnected, setFitBitConnected] = useState(false);
	const [summary, setSummary] = useState<SummaryType[]>([]);
	const [heroObject, setHeroObject] = useState<HeroObject>();
	const [activityLedger, setActivityLedger] = useState<ActivityLedger[]>([]);
	const [workoutData, setWorkoutData] = useState<WorkoutObject[]>([]);
	const [lastUpdatedText, setLastUpdatedText] = useState<string>("");
	const [lastUpdated, setLastUpdated] = useState<string>(""); // Timestamp format "YYYY-MM-DD hh:mm:ss a"
	const [previousHeroObject, setPreviousHeroObject] = useState<HeroObject>();
	const [currentMissions, setCurrentMissions] = useState<Mission[]>([]);
	const [currentGoal, setCurrentGoal] = useState<Goal | null>(null);
	const [isMobile, setIsMobile] = useState<boolean>();
	const [onboardingCompleted, setOnboardingCompleted] = useState<boolean>();
	const [loadingStage, setLoadingStage] = useState<string>(""); // This is used to keep track of how many workouts have been processed
	const [shouldShowNotificationModal, setShouldShowNotificationModal] = useState<boolean>(false);
	const [sidekick, setSidekick] = useState<SidekickDBObject>();
	const [sidekickNewMessages, setSidekickNewMessages] = useState<boolean>(false);
	const [heroNewMessages, setHeroNewMessages] = useState<number>(0);

	// Subscription-related states
	const paywallEnabled = true;
	const [productsForPaywall, setProductsForPaywall] = useState<any[]>();
	const [paywallFeatures, setPaywallFeatures] = useState<PaywallFeature[]>();
	const [currentlySubscribed, setCurrentlySubscribed] = useState<boolean>(false);	// This is used to keep track of whether the user is currently subscribed
	const [subscriptionInfo, setSubscriptionInfo] = useState<any>();
	const [subscriptionDates, setSubscriptionDates] = useState<SubscriptionDates>({
		expires_date: null,
		renewal_date: null,
		purchase_date: null,
	});

	// Loading finished states
	const [sidekickLoadingFinished, setSidekickLoadingFinished] = useState<boolean>(false);
	const [heroLoadingFinished, setHeroLoadingFinished] = useState<boolean>(false);
	const [heroMessagesLoadingFinished, setHeroMessagesLoadingFinished] = useState<boolean>(false);
	const [duelLoadingFinished, setDuelLoadingFinished] = useState<boolean>(false);

	// Misc states
	const [helpNeeded, setHelpNeeded] = useState<Suggestion[]>([]);
	const [ownedSkills, setOwnedSkills] = useState<Skill[]>([]);

	// Testing states
	const [testDuelCelebration] = useState<boolean>(false);
	const [testActivityCelebration] = useState<boolean>(false);
	const [testNoDuel] = useState<boolean>(false);


	let activateHealthLoss = false;

	async function serialStartupFunction(resetHero: boolean = false) {
		let userObject = await getUser();
		let device: DeviceInfo = await Device.getInfo();
		log.debug(`[serialStartupFunction] device: `, device);

		// Set whether the user is on mobile or not for layoput purposes
		if (device.operatingSystem === "ios" || device.operatingSystem === "android") {
			setIsMobile(true);
		} else {
			setIsMobile(false);
		}

		// Set the onboarding state
		let onboardingState = await storageService.getObject("onboarding");
		// log.debug(`[serialStartupFunction] onboardingState: `, JSON.stringify(onboardingState));

		// If the onboarding URL is entryscreen for some reason, set it to null
		if (onboardingState && onboardingState.url && onboardingState.url === "/entryscreen") {
			log.debug(`[serialStartupFunction] onboardingState.url is entryscreen, removing it`);
			onboardingState = null;
			await storageService.removeItem("onboarding");
		}
		if (userObject) {
			setUserstate({
				authenticated: true,
				user: userObject,
				onboarding: onboardingState,
			});
		}

		// If the user exists, proceed with the startup process
		let continueStartup = true;
		// Check if the user is authenticated and the onboarding is completed
		continueStartup = validateUserAndOnboarding(userObject, onboardingState);
		if (continueStartup === true) {
			setOnboardingCompleted(true);
			if (userObject?.userID !== undefined) {
				let user_id = userObject.userID;
				try {
					OneSignal.setExternalUserId(user_id, (results) => {
						// The results will contain push and email success statuses
						// log.debug("Results of setting external user id");
						// log.debug(results);
					});
				} catch (error) {
					log.debug(`[OneSignal.setExternalUserId] error: `, error);
				}

				await storageService.setItem(userIDKey, user_id);
			}
			loadHeroData(device, resetHero, userObject);
			// loadSidekick();
			loadHeroMessages();
			loadDuels();
			fetchAllUserVillainTally();
			await setupRevenueCat(device.platform, userObject!);
			return userObject;
		} else {
			setOnboardingCompleted(false);
			return null;
		}
	}

	async function loadCurrentGoal() {
		// Goals are ONLY updated when you open the app
		let goal = await getCurrentGoalFromDB();
		let activityLedger = await storageService.getObject(activityLedgerKey);
		console.log("[loadCurrentGoal] Goal: ", goal);

		// compare current values of the activity ledger to the goal values
		// if the current values do not equal the ledger, update the goal values
		let shouldUpdateGoal = false

		// variables for determining if the goal was JUST accomplished
		let strengthGoalJustAccomplished = false;
		let cardioGoalJustAccomplished = false;
		let mobilityGoalJustAccomplished = false;
		let mindfulnessGoalJustAccomplished = false;
		let previousStrengthPoints = goal.strengthPoints;
		let previousCardioPoints = goal.cardioPoints;
		let previousMobilityPoints = goal.mobilityPoints;
		let previousMindfulnessPoints = goal.mindfulnessPoints;

		// Get the sum of the points for each activity type
		let currentStrengthPoints = (await getSumPoints(goal.startDate, goal.endDate, "strength", activityLedger)).reduce((a, b) => a + b, 0);
		// If the goal was not accomplished before and now it is
		if (previousStrengthPoints < goal.strengthGoalPoints && currentStrengthPoints >= goal.strengthGoalPoints) {
			strengthGoalJustAccomplished = true;
			log.debug(`[loadCurrentGoal] strength goal just accomplished`);
			activateGoalSkill("strength")
		}
		let currentCardioPoints = (await getSumPoints(goal.startDate, goal.endDate, "cardio", activityLedger)).reduce((a, b) => a + b, 0);
		if (previousCardioPoints < goal.cardioGoalPoints && currentCardioPoints >= goal.cardioGoalPoints) {
			cardioGoalJustAccomplished = true;
			log.debug(`[loadCurrentGoal] cardio goal just accomplished`);
			activateGoalSkill("cardio")
		}
		let currentMobilityPoints = (await getSumPoints(goal.startDate, goal.endDate, "mobility", activityLedger)).reduce((a, b) => a + b, 0);
		if (previousMobilityPoints < goal.mobilityGoalPoints && currentMobilityPoints >= goal.mobilityGoalPoints) {
			mobilityGoalJustAccomplished = true;
			log.debug(`[loadCurrentGoal] mobility goal just accomplished`);
			activateGoalSkill("mobility")
		}
		let currentMindfulnessPoints = (await getSumPoints(goal.startDate, goal.endDate, "mindfulness", activityLedger)).reduce((a, b) => a + b, 0);
		if (previousMindfulnessPoints < goal.mindfulnessGoalPoints && currentMindfulnessPoints >= goal.mindfulnessGoalPoints) {
			mindfulnessGoalJustAccomplished = true;
			log.debug(`[loadCurrentGoal] mindfulness goal just accomplished`);
			activateGoalSkill("mindfulness")
		}
		if (goal.strengthPoints !== currentStrengthPoints) { goal.strengthPoints = currentStrengthPoints; shouldUpdateGoal = true; }
		if (goal.cardioPoints !== currentCardioPoints) { goal.cardioPoints = currentCardioPoints; shouldUpdateGoal = true; }
		if (goal.mobilityPoints !== currentMobilityPoints) { goal.mobilityPoints = currentMobilityPoints; shouldUpdateGoal = true; }
		if (goal.mindfulnessPoints !== currentMindfulnessPoints) { goal.mindfulnessPoints = currentMindfulnessPoints; shouldUpdateGoal = true; }
		if (shouldUpdateGoal === true) {
			log.debug(`[loadCurrentGoal] updating goal: `, goal);
			await updateGoal(goal);
		}
		setCurrentGoal(goal);
		return {
			strengthGoalJustAccomplished,
			cardioGoalJustAccomplished,
			mobilityGoalJustAccomplished,
			mindfulnessGoalJustAccomplished
		};
	}

	async function populatePaywallFeatures() {
		let features = sampleFeatures;
		setPaywallFeatures(features);
	}

	async function setupRevenueCat(platform?: string, user?: UserObject): Promise<any[]> {
		if (!platform) {
			let device: DeviceInfo = await Device.getInfo();
			platform = device.platform;
		}
		if (!user) {
			let tempUser = await getUser();
			if (!tempUser) {
				log.debug(`[setupRevenueCat] user not found`);
				return [];
			}
			user = tempUser;
		}
		await populatePaywallFeatures();
		smartLog(`[setupRevenueCat] configuring for: ${platform}`);
		// platform = "ios"; // TESTING
		// await getProductListForPaywall().then((products: any) => {
		// 	smartLog(`[setupRevenueCat] all products: ${JSON.stringify(products)}`);
		// }); // TESTING
		let products: any[] = [];
		let subscriptionDatesLocal: SubscriptionDates = {
			expires_date: null,
			renewal_date: null,
			purchase_date: null,
		};
		let subscriptionInfoLocal: any = {};
		if (platform === 'web') {
			// smartLog(`[setupRevenueCat] web platform detected`);  
			await getProductListForPaywall().then((products: any) => {
				// smartLog(`[setupRevenueCat] products: ${JSON.stringify(products)}`);
				// remove any products from the array that begin with FH because those are mobile only
				products = products.filter((product: any) => (!product.identifier.startsWith("FH") && !product.identifier.startsWith("fithero")));
				products.sort((a: any, b: any) => a.price - b.price);
				setProductsForPaywall(products);
			});
			await getCustomerInfoWeb().then(async (info) => {
				// smartLog(`[setupRevenueCat] customer info: ${JSON.stringify(info)}`);
				setSubscriptionInfo(info);
				let subscriptionInfo = await getUserSubscriptionObjectWeb(info)
				subscriptionDatesLocal.expires_date = subscriptionInfo.expires_date;
				subscriptionDatesLocal.purchase_date = subscriptionInfo.purchase_date;
				setCurrentlySubscribed(subscriptionInfo.subscribed);
				setSubscriptionDates(subscriptionDatesLocal);
			}).catch((error) => {
				smartLog(`[setupRevenueCat] customer info error: ${error}`);
			});
		} else {
			// smartLog(`[setupRevenueCat] Not web, setting log level`);
			// let mockWebResponse = await Purchases.setMockWebResults({ shouldMockWebResults: true }).catch((error) => {
			// 	smartLog(`[setupRevenueCat] error setting mock web results: ${error}`);
			// });
			// smartLog(`[setupRevenueCat] mock web results: ${JSON.stringify(mockWebResponse)}`);
			await Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG }).catch((error) => {
				smartLog(`[setupRevenueCat] error setting log level: ${error}`);
			});
			switch (platform) {
				case 'ios':
					// smartLog(`[setupRevenueCat] ios platform detected`);
					await Purchases.configure({ apiKey: REVENUECAT_IOS_KEY, appUserID: user.userID }).catch((error) => {
						smartLog(`[setupRevenueCat] iOS error: ${error}`);
					});
					break;
				case 'android':
					// smartLog(`[setupRevenueCat] android platform detected`);
					await Purchases.configure({ apiKey: REVENUECAT_ANDROID_KEY, appUserID: user.userID }).catch((error) => {
						smartLog(`[setupRevenueCat] android error: ${error}`);
					})
					break;
				default:
					break;
			}
			await Purchases.getCustomerInfo().then((info) => {
				// smartLog(`[setupRevenueCat] customer info: ${JSON.stringify(info)}`);
				subscriptionInfoLocal = info;
			}).catch((error) => {
				smartLog(`[setupRevenueCat] customer info error: ${error}`);
			});
			if (platform === 'ios') {
				//TODO: Pull product IDs from DB
				let productList = await Purchases.getProducts({ productIdentifiers: ["FH_499_1mo_7day", "FH_2999_12mo_7day", "FH_3999_lifetime"] })
				productList.products.forEach((product) => {
					products.push(product);
				});
				smartLog(`[setupRevenueCat] products:  ${JSON.stringify(products)} `);
				setProductsForPaywall(products);
			}
			if (platform === 'android') {
				//TODO: Pull product IDs from DB
				let subscriptionProductList = await Purchases.getProducts({ productIdentifiers: ["fithero_powerup_v1", "fithero_powerup_v1_lifetime"], type: PRODUCT_CATEGORY.SUBSCRIPTION })
				subscriptionProductList.products.forEach((product) => {
					products.push(product);
				});
				let onetimeProductList = await Purchases.getProducts({ productIdentifiers: ["fithero_powerup_v1", "fithero_powerup_v1_lifetime"], type: PRODUCT_CATEGORY.NON_SUBSCRIPTION })
				onetimeProductList.products.forEach((product) => {
					products.push(product);
				});
				smartLog(`[setupRevenueCat] android products w/lifetime:  ${JSON.stringify(products)} `);
				setProductsForPaywall(products);
			}
			setSubscriptionInfo(subscriptionInfoLocal.customerInfo);
			if (subscriptionInfoLocal.customerInfo?.entitlements?.active?.powerUp?.isActive && subscriptionInfoLocal.customerInfo?.entitlements?.active?.powerUp?.isActive === true) {
				setCurrentlySubscribed(true);
				if (subscriptionInfoLocal.customerInfo.entitlements.active.powerUp?.willRenew === true) {
					subscriptionDatesLocal.renewal_date = subscriptionInfoLocal.customerInfo.entitlements.active.powerUp.expirationDate;
				}
				if (subscriptionInfoLocal.customerInfo.entitlements.active.powerUp?.willRenew === false) {
					subscriptionDatesLocal.expires_date = subscriptionInfoLocal.customerInfo.entitlements.active.powerUp.expirationDate;
				}
				subscriptionDatesLocal.purchase_date = subscriptionInfoLocal.customerInfo.entitlements.active.powerUp.latestPurchaseDate;
				setSubscriptionDates(subscriptionDatesLocal);
			} else {
				setCurrentlySubscribed(false);
			}
		}
		return products;
	}
	async function loadDuels() {
		let currentStatuses = await getAllDuels();
		// If a duel has ended in the past 24 hours and it hasn't been celebrated, celebrate it
		// Why only 24 hours??
		let dayAgo = moment().subtract(1, "week");
		let duelsEndedYesterday = currentStatuses.filter(
			(duel) => moment(duel.updatedAt).isAfter(dayAgo) && duel.complete === true && duel.celebrated !== true
		);
		log.debug(`[getAllDuels] duelsEndedYesterday: `, duelsEndedYesterday);
		if (duelsEndedYesterday.length > 0) {
			// Celebrate the duel
			// Why are we celebrating all of them?
			// duelsEndedYesterday.forEach(async (duel) => {
			// 	let notification: NotificationObject = {
			// 		type: ActivityNotificationType.duelWin,
			// 		updateObject: duel,
			// 	};
			// 	await addNotificationToQueue(notification);
			// });
			let notification: NotificationObject = {
				type: NotificationType.duelWin,
				updateObject: duelsEndedYesterday[0],
			};
			await addNotificationToQueue(notification);
		}
		/* TESTING */
		if (testDuelCelebration === true && window.location.hostname === 'localhost') {
			let celebration: NotificationObject = {
				type: NotificationType.duelWin,
				updateObject: SampleDuelStatus,
			};
			await addNotificationToQueue(celebration);
		}
		// Check to see if the user has any active duels, if they don't add a notification to the queue
		// This is push users into starting a new duel if they don't have any active ones
		/* TESTING */
		// currentStatuses = [];
		const userSettings = await getUserSettings();
		const userInDuel = await isUserInADuel(currentStatuses);
		if ((
			userInDuel === false ||
			testNoDuel === true
		) && (
				userSettings?.promptToDuelInApp === true ||
				userSettings?.promptToDuelInApp === undefined
			) && (
				window.location.hostname === 'localhost'
			)
		) {

			let notification: NotificationObject = {
				type: NotificationType.noCurrentDuel,
				updateObject: null,
			};
			await addNotificationToQueue(notification);
		}
		setDuelLoadingFinished(true);
	}

	async function loadHeroMessages() {
		getHeroMessagesFromDB().then((heroMessageResponse: HeroMessagesResponse) => {
			log.debug(`[loadHeroMessages] heroMessageResponse: `, heroMessageResponse);
			if (heroMessageResponse.newMessages === true || !heroMessageResponse.messages || heroMessageResponse.messages.length === 0) {
				setHeroNewMessages(heroMessageResponse.numberOfNewMessages)
			}
			setHeroLoadingFinished(true);
		}).catch((error) => {
			log.debug(`[loadHeroMessages] error: `, error);
			setHeroLoadingFinished(true);
		});
	}

	async function loadHeroData(device: DeviceInfo, resetHero: boolean = false, userObject: UserObject | null) {
		let workoutsFetched = false;
		await getHero().then(async (hero) => {
			let arrayOfSpecializations = chooseSpecialization(hero);
			// arrayOfSpecializations = ["strength", "cardio"]
			if (arrayOfSpecializations.length > 0) {
				let notification: NotificationObject = {
					type: NotificationType.chooseSpecialization,
					updateObject: {
						specializations: arrayOfSpecializations,
					},
				};
				await addNotificationToQueue(notification);
			}
		}).catch((error) => {
			if (error.status === 401) {
				//user is not logged in
				// for now, just log them out
				setHeroLoadingFinished(true);
				logout();
			} else {
				// No hero found, likely because the user is new
				setHeroLoadingFinished(true);
				return
			}
		}); // Gets the hero data from the DB and stores it locally


		let existingData: ExistingData = await getExistingData();
		if (device.platform === "ios") {
			workoutsFetched = await healthKitStartup(existingData, resetHero);
		}
		if (device.platform === "android") {
			workoutsFetched = await googleFitStartup(existingData, resetHero);
		}
		if (await isFitBitConnected() === true) {
			workoutsFetched = await fitbitStartup(existingData, resetHero);
		}
		if (workoutsFetched === false) {
			setHealthKitConnected(false);
			setGoogleFitConnected(false);
			setFitBitConnected(false);
		}
		let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(true, userObject); // not returning something here because recursion
		// log.debug(`[pPullHealthData] RecentWorkouts: `, recentWorkouts);
		let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
		let notificationObject: NotificationObject = await updateHeroHealth(activityLedger, recentWorkouts, moment(), true);

		log.debug(`[pPullHealthData] notificationObject: `, notificationObject);
		setHeroLoadingFinished(true);
	}

	async function healthKitStartup(existingData: ExistingData, resetHero: boolean = false) {
		setGoogleFitConnected(false);
		let healthKitAuthorized = await storageService.getItem(healthKitAuthorizedKey);
		log.debug(`[serialStartupFunction] healthKitAuthorized: `, healthKitAuthorized);
		if (healthKitAuthorized) {
			setHealthKitConnected(true);
			await getDataFromHK(existingData, resetHero);
			return true;
		} else {
			log.debug(`[serialStartupFunction] healthKit is NOT Authorized`);
			setHealthKitConnected(false);
			return false;
		}
	}

	async function googleFitStartup(existingData: ExistingData, resetHero: boolean = false) {
		setHealthKitConnected(false);
		let googleFitAuthorized = await storageService.getItem(googleFitAuthorizedKey);
		log.debug(`[serialStartupFunction] googleFitAuthorized: `, googleFitAuthorized);
		if (googleFitAuthorized === "true") {
			setGoogleFitConnected(true);
			log.debug(`[serialStartupFunction] googleFit is Authorized, pulling data`);
			await getDataFromGF(existingData, resetHero);
			return true;
		} else {
			log.debug(`[serialStartupFunction] googleFit is NOT Authorized`);
			setGoogleFitConnected(false);
			return false;
		}
	}

	async function fitbitStartup(existingData: ExistingData, resetHero: boolean = false) {
		setGoogleFitConnected(false);
		await storageService.setItem(googleFitAuthorizedKey, "false");
		setHealthKitConnected(false);
		await storageService.setItem(healthKitAuthorizedKey, "false");
		setFitBitConnected(true);
		// if the hero is being reset, pull the data from fitbit
		// if (resetHero === true) {
		// 	await getFitbitData()
		// 		.then(async (result) => { })
		// 		.catch((error) => {
		// 			log.debug(`[fitbitStartup] error: `, error);
		// 			setFitBitConnected(false);
		// 		});
		// } else {
		// 	await getFitbitActivities()
		// 		.then(async (result) => { })
		// 		.catch((error) => {
		// 			log.debug(`[fitbitStartup] error: `, error);
		// 			setFitBitConnected(false);
		// 		});
		// }
		return false; // always return false to fetch data from DB
	}

	function validateUserAndOnboarding(userObject: UserObject | null, onboardingObject: OnboardingObject | null): boolean {
		// log.debug(`[validateUserAndOnboarding] onboardingObject: `, JSON.stringify(onboardingObject));
		if (userObject === null) {
			// log.debug(`[validateUserAndOnboarding] userObject is null`);
			return false;
		}
		if (userObject?.userID === undefined) {
			// log.debug(`[validateUserAndOnboarding] userObject._id is undefined`);
			return false;
		}
		if (onboardingObject?.completed === false) {
			setOnboardingCompleted(false);
			// log.debug(`[validateUserAndOnboarding] onboardingObject.completed is false`);
			return false;
		}
		return true;
	}

	async function updateUserState(userObject?: UserObject | null) {
		await refreshUserObject().catch((error) => {
			log.debug(`[updateUserState] no user found. Returning`);
			return null;
		});
		if (userObject === undefined || userObject === null) {
			userObject = await getUser();
		}
		if (userObject !== null) {
			let onboarding = await storageService.getObject("onboarding");
			log.debug(`[updateUserState] onboarding: `, JSON.stringify(onboarding));
			let uState = {
				authenticated: true,
				user: userObject,
				onboarding: onboarding,
			};
			setUserstate(uState);
			// log.debug(`[updateUserState] userState updated`);
			return uState;
		} else {
			log.debug(`[updateUserState] userObject is null`);
			return null;
		}
	}

	async function getDataFromHK(existingData: ExistingData, resetHero: boolean = false) {
		let updatedHealthInfo: HKData;
		let updatedStepsInfo: HKData;

		// Pull workouts from last updated if it exists, otherwise pull from past year
		// Doing this here instead of in processWorkoutsFromHealthKit so we can set the lastUpdatedText
		setLoadingStage("Processing recent workouts");
		if (existingData.lastUpdated && existingData.lastUpdated !== "Never") {
			log.debug("[getDataFromHK] Last Updated Workouts: ", existingData.lastUpdated);
			setLastUpdatedText(timeBetweenDates(moment(), moment(existingData.lastUpdated)));
			log.debug("[getDataFromHK] existing data: ", existingData);
			updatedHealthInfo = await processWorkoutsFromHealthKit(existingData.workouts, moment(existingData.lastUpdated).subtract(24, "hours"), moment());
		} else {
			log.debug("[getHKWorkouts] Last Updated Workouts: Never");
			log.debug("[getHKWorkouts] Workouts never been updated. Getting workouts since creation");
			setLastUpdatedText("Just now");
			let user: UserObject = await storageService.getObject(userObjectKey);

			// updatedHealthInfo = await processWorkoutsFromHealthKit(existingData.workouts, moment().subtract(12, "weeks"), moment());
			updatedHealthInfo = await processWorkoutsFromHealthKit(existingData.workouts, moment(user.createdAt), moment());

		}
		// Ignore steps for now
		// issue: if last updated is the day before, it will pull the steps from the day before
		// however the day before has already been pulled, so it will pull the same steps again
		// let stepsLastPull = moment(existingData.lastUpdatedSteps).format("YYYY-MM-DD HH:mm:00");
		// let stepsPullStartDate = moment(stepsLastPull).startOf("day").format();
		// if (resetHero === true) {
		// 	stepsPullStartDate = moment().subtract(12, "weeks").startOf("day").format();
		// }
		// log.debug("[getDataFromHK] stepsLastPull: ", stepsLastPull);
		// log.debug("[getDataFromHK] Getting steps since: ", stepsPullStartDate);
		// let stepsPullEndDate = moment().endOf("day").format();
		// setLoadingStage("Processing recent steps");
		// updatedStepsInfo = await getHKStepsv2(stepsPullStartDate, stepsPullEndDate);

		// // Merge the two HKData objects
		// updatedHealthInfo.activityLedger = updatedHealthInfo.activityLedger.concat(updatedStepsInfo.activityLedger);
		// updatedHealthInfo.workoutArray = updatedHealthInfo.workoutArray.concat(updatedStepsInfo.workoutArray);

		log.debug(`[getDataFromHK] updatedHealthInfo: `, updatedHealthInfo);
		// Update the hero health
		setLoadingStage("Updating hero health");
		let notificationObject = await updateHeroHealth(
			updatedHealthInfo.activityLedger,
			updatedHealthInfo.workoutArray,
			moment(existingData.lastUpdated),
			resetHero
		);
		log.debug(`[getDataFromHK] notificationObject: `, notificationObject);
		await storageService.setItem(lastUpdatedWorkoutKey, moment().format());
		await storageService.setItem(lastUpdatedStepsKey, moment().format());
		setLoadingStage("");
	}

	async function getDataFromGF(existingData: ExistingData, resetHero: boolean = false) {
		let updatedHealthInfo: HKData;
		let updatedStepsInfo: HKData;

		// Pull workouts from last updated if it exists, otherwise pull from past year
		// Doing this here instead of in processWorkoutsFromHealthKit so we can set the lastUpdatedText
		if (existingData.lastUpdated && existingData.lastUpdated !== "Never") {
			log.debug("[getDataFromGF] Last Updated Workouts: ", existingData.lastUpdated);
			setLastUpdatedText(timeBetweenDates(moment(), moment(existingData.lastUpdated)));

			updatedHealthInfo = await processWorkoutsFromGoogleFit(existingData.workouts, moment(existingData.lastUpdated).subtract(24, "hours"), moment());
		} else {
			log.debug("[getDataFromGF] Last Updated Workouts: Never");
			log.debug("[getDataFromGF] Workouts never been updated. Getting workouts from past year");
			setLastUpdatedText("Just now");
			let user: UserObject = await storageService.getObject(userObjectKey);
			// updatedHealthInfo = await processWorkoutsFromGoogleFit(existingData.workouts, moment().subtract(12, "weeks"), moment());
			updatedHealthInfo = await processWorkoutsFromGoogleFit(existingData.workouts, moment(user.createdAt), moment());
		}
		// Ignor steps for now
		// let stepsLastPull = moment(existingData.lastUpdatedSteps).format("YYYY-MM-DD HH:mm:00");
		// let stepsPullStartDate = moment(stepsLastPull).startOf("day").subtract("8", "hours").format();
		// if (resetHero === true) {
		// 	stepsPullStartDate = moment().subtract(12, "weeks").startOf("day").format();
		// }
		// log.debug("[getDataFromGF] stepsLastPull: ", stepsLastPull);
		// log.debug("[getDataFromGF] Getting steps since: ", stepsPullStartDate);
		// let stepsPullEndDate = moment().endOf("day").format();
		// updatedStepsInfo = await getGFSteps(stepsPullStartDate, stepsPullEndDate);

		// // Merge the two HKData objects
		// updatedHealthInfo.activityLedger = updatedHealthInfo.activityLedger.concat(updatedStepsInfo.activityLedger);
		// updatedHealthInfo.workoutArray = updatedHealthInfo.workoutArray.concat(updatedStepsInfo.workoutArray);

		// Update the hero health
		let notificationObject = await updateHeroHealth(
			updatedHealthInfo.activityLedger,
			updatedHealthInfo.workoutArray,
			moment(existingData.lastUpdated),
			resetHero
		);
		log.debug(`[getDataFromGF] notificationObject: `, notificationObject);
		await storageService.setItem(lastUpdatedWorkoutKey, moment().format());
		await storageService.setItem(lastUpdatedStepsKey, moment().format("YYYY-MM-DD HH:mm:00"));
	}

	async function deDupeLedger(activityLedger: ActivityLedger[]) {
		// Remove duplicates from activityLedger based on UUID
		let tempLedger: ActivityLedger[] = [];
		let uuids: string[] = [];
		activityLedger.forEach((ledgerItem: ActivityLedger) => {
			if (ledgerItem.uuid && !uuids.includes(ledgerItem.uuid)) {
				uuids.push(ledgerItem.uuid);
				tempLedger.push(ledgerItem);
			}
		});
		await storageService.setObject("activityLedger", tempLedger);
		return tempLedger;
	}

	async function getExistingData(): Promise<ExistingData> {
		log.debug("Pulling exiting data");
		let workouts: WorkoutObject[] = await storageService.getObject(workoutKey);
		let heroObject: HeroObject = await storageService.getObject(heroObjectKey); // this shouldn't be necessary...
		let tempActivityLedger: ActivityLedger[] = await storageService.getObject(activityLedgerKey);
		if (tempActivityLedger !== null) {
			tempActivityLedger = await deDupeLedger(tempActivityLedger); // just in case?
		}
		let tempSummary = await storageService.getObject("pointsSummaryText");
		let lastUpdated = await storageService.getItem(lastUpdatedWorkoutKey);
		let lastUpdatedSteps = await storageService.getItem(lastUpdatedStepsKey);

		if (lastUpdated === "Invalid date" || !lastUpdated) {
			// lastUpdated = moment().format();
			// await storageService.setItem(lastUpdatedWorkoutKey, lastUpdated);
			lastUpdated = "Never";
		}

		if (lastUpdatedSteps === "Invalid date" || !lastUpdatedSteps) {
			// lastUpdatedSteps = moment().format();
			// await storageService.setItem(lastUpdatedStepsKey, lastUpdatedSteps);
			lastUpdatedSteps = moment().format();
		}
		if (heroObject === null) heroObject = await getHero();
		setPreviousHeroObject(heroObject);
		setHeroObject(heroObject);
		setSummary(tempSummary);
		setActivityLedger(tempActivityLedger);
		setWorkoutData(workouts);
		if (lastUpdated === "" || lastUpdated === null || lastUpdated === "Never") {
			setLastUpdatedText("Never");
		} else {
			setLastUpdatedText(timeBetweenDates(moment(), moment(lastUpdated)));
			setLastUpdated(moment(lastUpdated).format());
		}
		let existingData = {
			lastUpdated: lastUpdated,
			lastUpdatedSteps: lastUpdatedSteps,
		};
		log.debug(`[getExistingData] existingData: `, JSON.stringify(existingData));
		return {
			previousHeroObject: heroObject,
			heroObject: heroObject,
			summary: tempSummary,
			activityLedger: tempActivityLedger,
			workouts: workouts,
			lastUpdated: lastUpdated,
			lastUpdatedSteps: lastUpdatedSteps,
		};
	}

	async function updateHeroObject(currentHeroObject: HeroObject, newHeroObject: HeroObject) {
		setPreviousHeroObject(currentHeroObject);
		setHeroObject(newHeroObject);
		return {
			previousHeroObject: currentHeroObject,
			currentHeroObject: newHeroObject,
		};
	}

	async function subtractPointsFromHeroObject(pointsToSubtract: ExercisePoints) {
		let tempHeroObject: HeroObject;

		// Update levels, progress bars & summary
		let heroObjectLocal = await storageService.getObject(heroObjectKey);
		if (heroObjectLocal) {
			tempHeroObject = heroObjectLocal;
		} else {
			tempHeroObject = await getHero();
		}
		// let levelsWithSummary = await convertPointsToLevelsWithSummary({ ...tempHeroObject }, pointsToSubtract, moment());
		let levelsWithSummary: any = await getUpdatedHero(true); //TODO: Update to not require reset
		log.debug(`[subtractPointsFromHeroObject] levelsWithSummary: `, levelsWithSummary);
		if (levelsWithSummary && levelsWithSummary.updatedHeroObject) {
			let stats: any = await updateHeroObject(tempHeroObject, levelsWithSummary.updatedHeroObject);
			log.debug(`[subtractPointsFromHeroObject] updated stats: `, stats);
			await storageService.setObject(heroObjectKey, levelsWithSummary.updatedHeroObject);
		}
	}

	async function refreshHeroData(resetHero: boolean = false) {
		await storageService.removeItem(activityLedgerKey);
		await storageService.removeItem(workoutKey);
		await storageService.removeItem(lastUpdatedStepsKey);
		await storageService.removeItem(lastUpdatedWorkoutKey);
		await storageService.removeItem(goalsObjectKey);
		let recentWorkouts: WorkoutObject[] = await getRecentWorkouts(true); // not returning something here because recursion
		log.debug(`[refreshHeroData] RecentWorkouts: `, recentWorkouts);
		if (recentWorkouts.length > 0) {
			let activityLedger: ActivityLedger[] = await createLedgerFromWorkoutArray(recentWorkouts);
			// log.debug(`[serialStartupFunction] activityLedger: `, activityLedger);
			await updateHeroHealth(activityLedger, recentWorkouts, moment(), resetHero);
		}
		await getAllGoals(); // wait for the above so the activityLedger is present
	}

	async function updateHeroHealth(
		ledger: ActivityLedger[],
		workoutArray: WorkoutObject[],
		lastUpdated: Moment,
		resetHero: boolean = false
	): Promise<NotificationObject> {
		// Setup foundation
		let tempHeroObject: HeroObject;
		let tempLedger: ActivityLedger[] = [];
		let tempSummary = [];
		let updateObject: HeroStatsUpdate = {
			summary: {},
			date: "",
			reset: false,
		};

		// Setup Ledger
		let activityLedgerLocal = await storageService.getObject(activityLedgerKey);
		if (activityLedgerLocal) {
			tempLedger = [...ledger, ...activityLedgerLocal];
		} else {
			tempLedger = ledger;
		}
		if (tempLedger) {
			tempLedger.sort((a: ActivityLedger, b: ActivityLedger) => {
				// Convert the activityDate strings to Date objects
				let dateA = new Date(a.activityDate);
				let dateB = new Date(b.activityDate);

				// Subtract one from the other to sort in descending order
				return dateB.getTime() - dateA.getTime();
			});
		}
		setActivityLedger(tempLedger);
		await storageService.setObject(activityLedgerKey, tempLedger);

		// Update levels, progress bars & summary
		let heroObjectLocal = await storageService.getObject(heroObjectKey);
		if (heroObjectLocal) {
			tempHeroObject = heroObjectLocal;
			// log.debug(`[updateHeroHealth] tempHeroObject: `, tempHeroObject);
		} else {
			tempHeroObject = await getHero();
		}
		try {
			let levelsWithSummary: any = await getUpdatedHero(resetHero);
			// log.debug(`[updateHeroHealth] levelsWithSummary: `, levelsWithSummary);
			updateObject = {
				summary: levelsWithSummary.summary.attributes,
				date: levelsWithSummary.summary.updated,
				reset: levelsWithSummary.summary.reset,
			};
			// log.debug(`[updateHeroHealth] updateObject: `, updateObject);
			// Update the summary
			let summaryLocal = await storageService.getObject("pointsSummaryText");
			if (summaryLocal) {
				tempSummary = summaryLocal;
				tempSummary.push(updateObject);
			} else {
				tempSummary = [updateObject];
			}
			let heroObject: any = await updateHeroObject(tempHeroObject, levelsWithSummary.heroObject);
			// log.debug(`[updateHeroHealth] heroObject: `, heroObject);

			setLastUpdatedText(timeBetweenDates(moment(), moment(lastUpdated)));
			setSummary(tempSummary);
			await storageService.setObject(heroObjectKey, levelsWithSummary.heroObject);
		} catch {
			log.debug(`[updateHeroHealth] Error updating hero health`);
		}
		await storageService.setObject("pointsSummaryText", tempSummary);

		if (workoutArray) {
			await upsertWorkouts(workoutArray);
		}
		let workoutData = await storageService.getObject("workouts");
		setWorkoutData(workoutData);
		let notificationObject = {
			type: NotificationType.heroStatsUpdate,
			updateObject: updateObject,
		};
		/* TESTING */
		if (testActivityCelebration === true && window.location.hostname === 'localhost') {
			notificationObject = {
				type: NotificationType.heroStatsUpdate,
				updateObject: SampleUpdateObject,
			};
		}
		let goalsAccomplished = await loadCurrentGoal();
		if (
			notificationObject.updateObject &&
			notificationObject.updateObject.summary &&
			Object.keys(notificationObject.updateObject.summary).length > 0 &&
			(notificationObject.updateObject.summary.level?.change === 'increase' ||
				notificationObject.updateObject.summary.cardio?.points?.change! === 'increase' ||
				notificationObject.updateObject.summary.strength?.points?.change! === 'increase' ||
				notificationObject.updateObject.summary.mobility?.points?.change! === 'increase' ||
				notificationObject.updateObject.summary.mindfulness?.points?.change! === 'increase')) // it's gotta have a level up of some sort...
		{
			log.debug(`[updateHeroHealth] adding notificationObject to queue: `, notificationObject);
			// Check for goal accomplishment here, so that it can be added to the notification queue at the same time as the points update

			if (goalsAccomplished.strengthGoalJustAccomplished && notificationObject.updateObject.summary.strength?.points?.change === 'increase') { notificationObject.updateObject.summary.strength.goalJustAccomplished = true; }
			if (goalsAccomplished.cardioGoalJustAccomplished && notificationObject.updateObject.summary.cardio?.points?.change === 'increase') { notificationObject.updateObject.summary.cardio.goalJustAccomplished = true; }
			if (goalsAccomplished.mobilityGoalJustAccomplished && notificationObject.updateObject.summary.mobility?.points?.change === 'increase') { notificationObject.updateObject.summary.mobility.goalJustAccomplished = true; }
			if (goalsAccomplished.mindfulnessGoalJustAccomplished && notificationObject.updateObject.summary.mindfulness?.points?.change === 'increase') { notificationObject.updateObject.summary.mindfulness.goalJustAccomplished = true; }
			await addNotificationToQueue(notificationObject);
		} else {
			log.debug(`[updateHeroHealth] notificationObject is empty, not adding to queue`);
		}
		return {
			type: NotificationType.heroStatsUpdate,
			updateObject: updateObject,
		};
	}

	async function updateHeroHealthv2(
		ledger: ActivityLedger[],
		workoutArray: WorkoutObject[],
		lastUpdated: Moment,
		resetHero: boolean = false
	): Promise<NotificationObject> {
		// Concurrently retrieve multiple objects from storage
		const [activityLedgerLocal, heroObjectLocal, summaryLocal] = await Promise.all([
			storageService.getObject(activityLedgerKey),
			storageService.getObject(heroObjectKey),
			storageService.getObject("pointsSummaryText")
		]);

		// Process ledger and add new entries
		const tempLedger = activityLedgerLocal ? [...ledger, ...activityLedgerLocal] : ledger; // if activityLedgerLocal is null, set to ledger otherwise append
		tempLedger.sort((a, b) => new Date(b.activityDate).getTime() - new Date(a.activityDate).getTime());
		await storageService.setObject(activityLedgerKey, tempLedger);

		// Retrieve or default Hero object
		const tempHeroObject = heroObjectLocal || await getHero();

		// Update Hero object and calculate summary
		const levelsWithSummary = await getUpdatedHero(resetHero);
		const updateObject: HeroStatsUpdate = {
			summary: levelsWithSummary.summary.attributes,
			date: levelsWithSummary.summary.updated,
			reset: levelsWithSummary.summary.reset
		};

		// Append or create new summary
		const tempSummary = summaryLocal ? [...summaryLocal, updateObject] : [updateObject];
		const updatedHeroObject = await updateHeroObject(tempHeroObject, levelsWithSummary.heroObject);
		await storageService.setObject(heroObjectKey, levelsWithSummary.heroObject); // why dont i use updatedHeroObject here?
		await storageService.setObject("pointsSummaryText", tempSummary);

		// Update workout related data
		const workoutTasks = workoutArray ? [upsertWorkouts(workoutArray), storageService.getObject("workouts")] : [storageService.getObject("workouts")];
		const [workoutData] = await Promise.all(workoutTasks);
		setWorkoutData(workoutData);

		// Prepare notification object
		let notificationObject: NotificationObject = {
			type: NotificationType.heroStatsUpdate,
			updateObject: updateObject
		};

		// Localhost special handling for testing
		if (testActivityCelebration && window.location.hostname === 'localhost') {
			notificationObject.updateObject = SampleUpdateObject;
		}

		// Check for goals accomplishment
		const goalsAccomplished = await loadCurrentGoal();
		if (notificationObject.updateObject && Object.keys(notificationObject.updateObject.summary).length > 0) {
			// Aggregate and check for goal accomplishments
			const { summary } = notificationObject.updateObject;
			const conditions = [
				'strength', 'cardio', 'mobility', 'mindfulness'
			].filter(key => summary[key]?.points?.change === 'increase');

			conditions.forEach(condition => {
				if ((goalsAccomplished as { [key: string]: boolean })[`${condition}GoalJustAccomplished`]) {
					summary[condition].goalJustAccomplished = true;
				}
			});

			if (conditions.length > 0) {
				await addNotificationToQueue(notificationObject);
			}
		}

		return notificationObject;
	}

	async function getSavedMissions(): Promise<Mission[] | null> {
		log.debug("Getting saved missions");
		let missions: Mission[] = await storageService.getObject("currentMissionsX");
		if (missions === null) missions = [];
		while (missions.length < 3) {
			log.debug("Not enough missions stored, generating");
			let activityHistory: WorkoutObject[] = await storageService.getObject("workouts");
			let tempMission = await MissionGenerator(activityHistory);
			missions.push(tempMission);
		}
		await saveMissions(missions);
		return missions;
	}

	async function saveMissions(missions: Mission[]) {
		await storageService.setObject("currentMissionsX", missions);
		setCurrentMissions(missions);
	}

	async function clearStoredHeroObject() {
		await storageService.removeItem(heroObjectKey);
		await storageService.removeItem("lastUpdated");
		await storageService.removeItem("activityLedger");
		await storageService.removeItem("workouts");
		await storageService.removeItem("pointsSummaryText");
		await storageService.removeItem("apiMessagesPet");
		await storageService.removeItem("displayMessagesPet");
		await storageService.removeItem("apiMessagesHero");
		await storageService.removeItem("displayMessagesHero");
		setSummary([]);
		setHeroObject(undefined);
		setActivityLedger([]);
		setWorkoutData([]);
		await getExistingData();

		log.debug(`Hero Object: `, heroObject);
	}

	const logout = async () => {
		const storageService = new StorageService();
		await LocalNotifications.removeAllListeners();
		await storageService.clear();
		await localStorage.clear();
		setUserstate({
			authenticated: false,
			user: null,
		});
		return true;
	};

	async function addNotificationToQueue(notificationObject: NotificationObject | undefined) {
		// get the users created date compare it to todays date and if they are the same dont show
		// if they are different show the notification
		let user = await storageService.getObject(userObjectKey);
		if (user) {
			let userCreatedDate = moment(user.createdAt).format("YYYY-MM-DD");
			let todaysDate = moment().format("YYYY-MM-DD");
			if (userCreatedDate === todaysDate) {
				log.debug(`[addNotificationToQueue] User was created today, not showing notification`);
				return;
			}
		}
		log.debug(`[addNotificationToQueue] notificationObject: `, notificationObject);
		if (!notificationObject) {
			return;
		}
		let settings: SettingsObject | undefined = await getUserSettings();
		if ((settings && settings.showInAppNotifications === true) || (settings && settings.showInAppNotifications === undefined) || !settings) { // if settings are undefined, or if they are defined and showInAppNotifications is true then show the notification
			let existingNotifications = await storageService.getObject("celebrationsInStorage");
			if (existingNotifications) {
				existingNotifications.push(notificationObject);
			} else {
				existingNotifications = [notificationObject];
			}
			await storageService.setObject("celebrationsInStorage", existingNotifications);
			setShouldShowNotificationModal(true);
		} else {
			log.debug(`[addNotificationToQueue] Notifications are disabled: `, settings);
			await storageService.removeItem("celebrationsInStorage");
		}
	}

	let state = {
		serialStartupFunction,
		healthKitConnected,
		setHealthKitConnected,
		summary,
		activityLedger,
		workoutData,
		lastUpdatedText,
		setLastUpdatedText,
		heroObject,
		clearStoredHeroObject,
		updateHeroHealth,
		previousHeroObject,
		updateHeroObject,
		getSavedMissions,
		saveMissions,
		currentMissions,
		getExistingData,
		lastUpdated,
		setLastUpdated,
		updateUserState,
		userState,
		currentGoal,
		setCurrentGoal,
		subtractPointsFromHeroObject,
		setActivityLedger,
		setIsMobile,
		isMobile,
		logout,
		onboardingCompleted,
		setOnboardingCompleted,
		setHeroObject,
		setPreviousHeroObject,
		googleFitConnected,
		setGoogleFitConnected,
		fitBitConnected,
		setFitBitConnected,
		refreshHeroData,
		loadingStage,
		addNotificationToQueue,
		shouldShowNotificationModal,
		setShouldShowNotificationModal,
		sidekick,
		setSidekick,
		sidekickLoadingFinished,
		setSidekickLoadingFinished,
		heroMessagesLoadingFinished,
		setHeroMessagesLoadingFinished,
		heroLoadingFinished,
		setHeroLoadingFinished,
		duelLoadingFinished,
		setDuelLoadingFinished,
		sidekickNewMessages,
		setSidekickNewMessages,
		heroNewMessages,
		setHeroNewMessages,
		productsForPaywall,
		paywallFeatures,
		currentlySubscribed,
		setCurrentlySubscribed,
		subscriptionInfo,
		setSubscriptionInfo,
		paywallEnabled,
		setupRevenueCat,
		subscriptionDates,
		setSubscriptionDates,
		loadCurrentGoal,
		helpNeeded,
		setHelpNeeded,
		ownedSkills,
		setOwnedSkills,
	};
	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default GlobalState;
