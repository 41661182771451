import { BaselineGoalValues, Goal, goalsObjectKey } from "../models/goalsModels";
import { getUser_id } from "./UserService";
import { StorageService } from "./StorageService";
// import moment, { Moment } from "moment";
import moment from 'moment-timezone';
import Parse from "parse";

import log from "loglevel";
import { GOALS_TABLE } from "../models/databaseModels";
import { sendSlackErrorNotification } from "./NotificationService";
import { getSumPoints } from "./ExerciseService";
import { v4 } from "uuid";
let storageService = new StorageService();

export async function getAllGoals(activeOnly: boolean = false): Promise<Goal[]> {
	let goalsStored = await storageService.getObject(goalsObjectKey);
	if (goalsStored) {
		getAllGoalsFromDB();
		return goalsStored;
	} else {
		return await getAllGoalsFromDB();
	}
}

export async function getAllGoalsFromDB(): Promise<Goal[]> {
	let user_id = await getUser_id();
	log.debug(`[getAllGoalsFromDB] Fetching goals for user ${user_id}`);
	let goals: Goal[] = []
	const query = new Parse.Query(GOALS_TABLE);
	query.equalTo('user_id', user_id);
	const parseObjects = (await query.find())
	if (!parseObjects) return goals
	parseObjects.forEach((parseObject: any) => {
		goals.push(parseObject.toJSON() as Goal)
	})
	log.debug(`[getAllGoalsFromDB] goals: `, goals);
	await storageService.setObject(goalsObjectKey, goals);
	return goals;
}

export async function getCurrentGoalFromDB(): Promise<Goal> {
	let userId = await getUser_id();
	log.debug(`[getGoals] Fetching goals for user ${userId}`);
	let goal: Goal
	const query = new Parse.Query(GOALS_TABLE);
	query.equalTo('user_id', userId);
	query.equalTo('active', true);
	const parseObject = (await query.first())
	if (!parseObject) {
		log.debug(`[getCurrentGoalFromDB] No active goal found for user ${userId}, creating generic goal`);
		goal = await createGenericGoal(userId)
	} else {
		log.debug(`[getCurrentGoalFromDB] Active goal found for user ${userId}`);
		goal = parseObject.toJSON() as unknown as Goal
	}
	log.debug(`[getCurrentGoalFromDB] goal: `, goal);
	getAllGoalsFromDB(); // just ensures its always up to date
	return goal;
}

export async function createGenericGoal(userId: string): Promise<Goal> {
	let dayOfTheWeek = moment().day();

	// Set baseline values to the general guidelines, poins from previous week or 10% more than what the user has in their current week
	let startDate = moment().startOf('isoWeek').format("YYYY-MM-DD");
	let endDate = moment().endOf('isoWeek').format("YYYY-MM-DD");

	// Get points from last week for goal setting

	let activityLedger = await storageService.getObject("activityLedger"); // this assumes it is up to date
	let strengthPoints = (await getSumPoints(startDate, endDate, "strength", activityLedger)).reduce((a, b) => a + b, 0);
	let cardioPoints = (await getSumPoints(startDate, endDate, "cardio", activityLedger)).reduce((a, b) => a + b, 0);
	let mobilityPoints = (await getSumPoints(startDate, endDate, "mobility", activityLedger)).reduce((a, b) => a + b, 0);
	let mindfulnessPoints = (await getSumPoints(startDate, endDate, "mindfulness", activityLedger)).reduce((a, b) => a + b, 0);
	let strengthGoalPoints = Math.max(Math.round(strengthPoints * 1.1), BaselineGoalValues.strengthGoalPoints);
	let cardioGoalPoints = Math.max(Math.round(cardioPoints * 1.1), BaselineGoalValues.cardioGoalPoints);
	let mobilityGoalPoints = Math.max(Math.round(mobilityPoints * 1.1), BaselineGoalValues.mobilityGoalPoints);
	let mindfulnessGoalPoints = Math.max(Math.round(mindfulnessPoints * 1.1), BaselineGoalValues.mindfulnessGoalPoints);
	let goal_type = "generic";

	// If it is Monday, get the points from the previous week for goal setting
	if (dayOfTheWeek === 1) {
		let startDateLastWeek = moment().subtract(1, 'week').startOf('isoWeek').format("YYYY-MM-DD");
		let endDateLastWeek = moment().subtract(1, 'week').endOf('isoWeek').format("YYYY-MM-DD");
		let previousStrengthPoints = (await getSumPoints(startDateLastWeek, endDateLastWeek, "strength", activityLedger)).reduce((a, b) => a + b, 0);
		let previousCardioPoints = (await getSumPoints(startDateLastWeek, endDateLastWeek, "cardio", activityLedger)).reduce((a, b) => a + b, 0);
		let previousMobilityPoints = (await getSumPoints(startDateLastWeek, endDateLastWeek, "mobility", activityLedger)).reduce((a, b) => a + b, 0);
		let previousMindfulnessPoints = (await getSumPoints(startDateLastWeek, endDateLastWeek, "mindfulness", activityLedger)).reduce((a, b) => a + b, 0);
		strengthGoalPoints = Math.max(Math.round(previousStrengthPoints), BaselineGoalValues.strengthGoalPoints);
		cardioGoalPoints = Math.max(Math.round(previousCardioPoints), BaselineGoalValues.cardioGoalPoints);
		mobilityGoalPoints = Math.max(Math.round(previousMobilityPoints), BaselineGoalValues.mobilityGoalPoints);
		mindfulnessGoalPoints = Math.max(Math.round(previousMindfulnessPoints), BaselineGoalValues.mindfulnessGoalPoints);
		goal_type = "maintain";
	}
	let goalId = v4();
	let goal = new Parse.Object(GOALS_TABLE);
	goal.set('user_id', userId);
	goal.set('active', true);
	goal.set('evaluated', false);
	goal.set('notified', false);
	goal.set('completeSuccess', false);
	goal.set('partialSuccess', false);
	goal.set('strengthPoints', strengthPoints);
	goal.set('cardioPoints', cardioPoints);
	goal.set('mobilityPoints', mobilityPoints);
	goal.set('mindfulnessPoints', mindfulnessPoints);
	goal.set('strengthGoalPoints', strengthGoalPoints);
	goal.set('cardioGoalPoints', cardioGoalPoints);
	goal.set('mobilityGoalPoints', mobilityGoalPoints);
	goal.set('mindfulnessGoalPoints', mindfulnessGoalPoints);
	goal.set('startDate', startDate);
	goal.set('endDate', endDate);
	goal.set('goal_type', goal_type);
	goal.set('goalId', goalId);
	goal = await goal.save();
	return goal.toJSON() as unknown as Goal;
}


export async function getCurrentGoalFromStorage(): Promise<Goal | null> {
	let goals = await storageService.getObject(goalsObjectKey);
	if (goals === null) {
		return null;
	}
	// find the first active goal
	let goal = goals.find((goal: Goal) => goal.active === true);
	return goal;
}

export async function findGoalByDates(startDate: string, endDate: string): Promise<Goal | null> {
	let goals = await storageService.getObject(goalsObjectKey);
	// Ensure input dates are in Moment format for comparison
	const start = moment(startDate).format("YYYY-MM-DD");
	const end = moment(endDate).format("YYYY-MM-DD");
	let goal = null
	// log.debug(`[findGoalByDates] Searching for goal with start date ${start} and end date ${end}`);
	// Find the first goal that matches the start and end dates
	if (goals) {
		goal = goals.find((goal: Goal) => {
			const goalStart = moment(goal.startDate).format("YYYY-MM-DD");
			const goalEnd = moment(goal.endDate).format("YYYY-MM-DD");
			// log.debug(`[findGoalByDates] Comparing goal with start date ${goalStart} and end date ${goalEnd}`);
			return goalStart === start && goalEnd === end;
		});
	}
	// If no matching goal was found, return null
	return goal ? goal : null;
}

export async function updateGoal(goal: Goal): Promise<Goal> {
	log.debug(`[updateGoal] Updating goal for goalId ${goal.goalId}`);
	const query = new Parse.Query(GOALS_TABLE);
	query.equalTo('goalId', goal.goalId);
	let result = await query.first().catch((error: any) => {
		log.debug("[updateGoal] Error: " + error.code + " " + error.message);
		sendSlackErrorNotification("Error no goal found", "updateGoal", `Goal Object: ${JSON.stringify(goal)}`);
		throw error;
	})
	if (!result) {
		log.debug(`[updateGoal] No goal found for goalId ${goal.goalId}`);
		// just create the goal here?
		sendSlackErrorNotification("Error - no goal found", "updateGoal", `Goal Object: ${JSON.stringify(goal)}`);
		throw new Error("No goal found");
	}
	result.set(goal);
	result = await result.save();
	if (!result) {
		log.debug(`[updateGoal] Error updating goal for goalId ${goal.goalId}`);
		throw new Error("Error updating goal");
	}
	// find the goal in local storage and update it as well
	let goals = await storageService.getObject(goalsObjectKey);
	if (goals === null) {
		goals = await getAllGoals();
	}
	let index = goals.findIndex((g: Goal) => g.goalId === goal.goalId);
	goals[index] = result.toJSON() as unknown as Goal;
	return result.toJSON() as unknown as Goal;
}
