import log from "loglevel";
import moment from "moment";
import { SKILLS_IN_USE_TABLE, SKILLS_TABLE, SPECIALIZATIONS_TABLE } from "../models/databaseModels";
import { DuelLedger } from "../models/duelsModels";
import { CardioAccomplishmentSkillId, MindfulnessAccomplishmentSkillId, MobilityAccomplishmentSkillId, Skill, SkillInUse, Specialization, StrengthAccomplishmentSkillId, allSkillsKey, genericIcon } from "../models/skills";
import { logGenericEvent, logGenericEventWithObject } from "./AnalyticsService";
import { fetchDuelsLedger } from "./DuelsServics";
import { sendSlackErrorNotification } from "./NotificationService";
import { StorageService } from "./StorageService";
import { getHero, getUser_id } from "./UserService";
const storageService = new StorageService();
const Parse = require('parse');

/**
 * Retrieves skills based on the specified attribute.
 * @param attribute - The attribute to filter the skills by.
 * @param includeSpecializations - Indicates whether to include specializations. Defaults to true.
 * @returns A promise that resolves to an array of skills.
 */
export async function getSkillsByAttribute(attribute: string, includeSpecializations: boolean = true): Promise<Skill[]> {
    const query = new Parse.Query(SKILLS_TABLE);
    query.equalTo('visible', true);
    query.equalTo('tree', attribute);
    if (!includeSpecializations) {
        query.equalTo('subTree', 'All');
    }
    const parseObjects = (await query.find())
    let skills: Skill[] = []
    parseObjects.forEach((parseObject: any) => {
        let skill: Skill = parseObject.toJSON() as Skill
        if (!skill.icon) {
            skill.icon = genericIcon
        }
        skills.push(skill)
    })
    // log.debug(`[getSkillsByAttribute] skills: `, skills);
    // await storageService.setObject(villainsObjectKey, villains);
    return skills.sort((a, b) => {
        if (a.level < b.level) { return -1; }
        if (a.level > b.level) { return 1; }
        return 0;
    });
}

/**
 * Retrieves all skills from the database.
 * @param includeSpecializations - Whether to include specializations or not. Defaults to true.
 * @returns A promise that resolves to an array of Skill objects.
 */
export async function getAllSkills(includeSpecializations: boolean = true): Promise<Skill[]> {
    let allSkills: Skill[] | null = await storageService.getObject(allSkillsKey);
    if (allSkills) {
        return allSkills;
    }
    let attributeArray = ['strength', 'cardio', 'mobility', 'mindfulness'];
    let skills = {
        strength: [] as Skill[],
        cardio: [] as Skill[],
        mobility: [] as Skill[],
        mindfulness: [] as Skill[]
    }
    for (let attribute of attributeArray) {
        const query = new Parse.Query(SKILLS_TABLE);
        query.equalTo('visible', true);
        query.equalTo('tree', attribute);
        if (includeSpecializations === false) {
            query.equalTo('subTree', 'All');
        }
        const parseObjects = (await query.find())

        parseObjects.forEach((parseObject: any) => {
            let skill: Skill = parseObject.toJSON() as Skill
            if (!skill.icon) {
                skill.icon = genericIcon
            }
            if (attribute === 'strength') {
                skills.strength.push(skill)
            } else if (attribute === 'cardio') {
                skills.cardio.push(skill)
            } else if (attribute === 'mobility') {
                skills.mobility.push(skill)
            } else if (attribute === 'mindfulness') {
                skills.mindfulness.push(skill)
            }
        })
        if (attribute === 'strength') {
            skills.strength.sort((a, b) => {
                if (a.level < b.level) { return -1; }
                if (a.level > b.level) { return 1; }
                return 0;
            });
        } else if (attribute === 'cardio') {
            skills.cardio.sort((a, b) => {
                if (a.level < b.level) { return -1; }
                if (a.level > b.level) { return 1; }
                return 0;
            });
        } else if (attribute === 'mobility') {
            skills.mobility.sort((a, b) => {
                if (a.level < b.level) { return -1; }
                if (a.level > b.level) { return 1; }
                return 0;
            });
        } else if (attribute === 'mindfulness') {
            skills.mindfulness.sort((a, b) => {
                if (a.level < b.level) { return -1; }
                if (a.level > b.level) { return 1; }
                return 0;
            });
        }
    }
    allSkills = skills.strength.concat(skills.cardio).concat(skills.mobility).concat(skills.mindfulness);
    await storageService.setObject(allSkillsKey, allSkills);
    return allSkills;
}

/**
 * Retrieves the list of specializations from the database.
 * @returns A promise that resolves to an array of Specialization objects.
 */
export async function getSpecializations(): Promise<Specialization[]> {
    const query = new Parse.Query(SPECIALIZATIONS_TABLE);
    const parseObjects = (await query.find())
    let specializations: Specialization[] = []
    parseObjects.forEach((parseObject: any) => {
        let specialization: Specialization = parseObject.toJSON() as Specialization
        specializations.push(specialization)
    })
    return specializations;
}

/**
 * Retrieves the owned skills based on the specified attribute.
 * @param attribute - The attribute to filter the skills by (e.g., 'strength', 'cardio', 'mobility', 'mindfulness').
 * @returns A promise that resolves to an array of Skill objects.
 */
export async function getOwnedSkillsByAttribute(attribute: string): Promise<Skill[]> {
    let hero = await getHero();
    let ownedSkills: Skill[] = [];
    let skillLevel = 1;
    let specialization = 'All';
    switch (attribute) {
        case 'strength':
            skillLevel = hero.strengthLevel;
            specialization = hero.strengthTreeSpecialization || 'All';
            break;
        case 'cardio':
            skillLevel = hero.cardioLevel;
            specialization = hero.cardioTreeSpecialization || 'All';
            break;
        case 'mobility':
            skillLevel = hero.mobilityLevel;
            specialization = hero.mobilityTreeSpecialization || 'All';
            break;
        case 'mindfulness':
            skillLevel = hero.mindfulnessLevel;
            specialization = hero.mindfulnessTreeSpecialization || 'All';
            break;
        default:
            break;
    }
    if (hero) {
        const query = new Parse.Query(SKILLS_TABLE);
        query.equalTo('tree', attribute);
        query.equalTo('visible', true);
        query.lessThanOrEqualTo('level', skillLevel);
        const parseObjects = (await query.find())
        parseObjects.forEach((parseObject: any) => {
            let skill: Skill = parseObject.toJSON() as Skill
            if (skill.subTree === 'All' || skill.subTree === specialization) {
                ownedSkills.push(skill)
            }
        })
    }
    // log.debug(`[getOwnedSkillsByAttribute] ownedSkills: `, ownedSkills);
    return ownedSkills;
}

/**
 * Retrieves all owned skills of the hero.
 * @returns A promise that resolves to an array of Skill objects representing the owned skills.
 */
export async function getAllOwnedSkills(): Promise<Skill[]> {
    let hero = await getHero();
    let ownedSkills: Skill[] = [];
    let skillLevel = 1;
    let specialization = 'All';

    if (hero) {
        let attributeArray = ['strength', 'cardio', 'mobility', 'mindfulness'];
        for (let attribute of attributeArray) {
            switch (attribute) {
                case 'strength':
                    skillLevel = hero.strengthLevel;
                    specialization = hero.strengthTreeSpecialization || 'All';
                    break;
                case 'cardio':
                    skillLevel = hero.cardioLevel;
                    specialization = hero.cardioTreeSpecialization || 'All';
                    break;
                case 'mobility':
                    skillLevel = hero.mobilityLevel;
                    specialization = hero.mobilityTreeSpecialization || 'All';
                    break;
                case 'mindfulness':
                    skillLevel = hero.mindfulnessLevel;
                    specialization = hero.mindfulnessTreeSpecialization || 'All';
                    break;
                default:
                    break;
            }
            const query = new Parse.Query(SKILLS_TABLE);
            query.equalTo('tree', attribute);
            query.equalTo('visible', true);
            query.lessThanOrEqualTo('level', skillLevel);
            const parseObjects = (await query.find())
            parseObjects.forEach((parseObject: any) => {
                let skill: Skill = parseObject.toJSON() as Skill
                if (skill.subTree === 'All' || skill.subTree === specialization) {
                    ownedSkills.push(skill)
                }
            })
        }
    }
    // log.debug(`[getOwnedSkillsByAttribute] ownedSkills: `, ownedSkills);
    return ownedSkills;
}

/**
 * Activates a skill for a user.
 * @param skill - The skill to activate.
 * @returns A boolean indicating whether the skill was activated successfully.
 */
export async function activateSkill(skill: Skill) {
    let user_id = await getUser_id();
    let params = {
        userId: user_id,
        skillId: skill.objectId,
    }
    let responseString = await Parse.Cloud.run('useSkill', params).catch((error: any) => {
        sendSlackErrorNotification(`[useSkill] Full Error Obj`, "useSkill", `${error}`);
        console.error(`[useSkill] Full Error Obj: `, error);
        throw error;
    });
    logGenericEventWithObject(`useSkill - ${skill.name}`, skill);
    // log.debug(`[useSkill] responseString: `, responseString.toJSON());
    if (skill.complimentarySkillId) {
        let complementarySkill = await getSkillById(skill.complimentarySkillId!);
        activateSkill(complementarySkill);
    }
    return true;
}

/**
 * Retrieves a skill by its ID.
 * @param skillId - The ID of the skill to retrieve.
 * @returns A Promise that resolves to the retrieved skill.
 */
export async function getSkillById(skillId: string): Promise<Skill> {
    const query = new Parse.Query(SKILLS_TABLE);
    query.equalTo('objectId', skillId);
    const parseObject = (await query.first())
    let skill: Skill = parseObject.toJSON() as Skill
    return skill;
}

/**
 * Retrieves the skills currently in use by the user.
 * @returns A promise that resolves to an array of SkillInUse objects.
 */
export async function getSkillsInUse(): Promise<SkillInUse[]> {
    let user_id = await getUser_id();
    let skillsInUse: SkillInUse[] = []
    const query = new Parse.Query(SKILLS_IN_USE_TABLE);
    query.equalTo('userId', user_id);
    const parseObjects = (await query.find())
    for (const parseObject of parseObjects) {
        let skillInUse: SkillInUse = parseObject.toJSON() as SkillInUse
        let skillInfo: Skill = await getSkillById(skillInUse.skillId);
        skillInUse.effectDescription = skillInfo.effectDescription;
        skillInUse.skillName = skillInfo.name;
        skillInUse.skillIcon = skillInfo.icon;
        skillsInUse.push(skillInUse)
    }
    log.debug(`[getSkillsInUse] skillsInUse: `, skillsInUse);
    return skillsInUse;
}

/**
 * Retrieves the information of a skill based on the provided attribute, level, and specialization (optional).
 * @param attribute - The attribute of the skill.
 * @param level - The level of the skill.
 * @param specialization - The specialization of the skill (optional).
 * @returns A Promise that resolves to the Skill object.
 */
export async function getSkillInfo(attribute: string, level: number, specialization?: string): Promise<Skill> {
    const query = new Parse.Query(SKILLS_TABLE);
    query.equalTo('tree', attribute);
    query.equalTo('level', level);
    if (specialization) {
        query.equalTo('subTree', specialization);
    }
    const parseObject = (await query.first())
    let skill: Skill = parseObject.toJSON() as Skill
    return skill;
}

export async function activateGoalSkill(attribute: string) {
    let user_id = await getUser_id();
    let skillId = '';
    switch (attribute) {
        case "strength":
            skillId = StrengthAccomplishmentSkillId;
            break;
        case "cardio":
            skillId = CardioAccomplishmentSkillId;
            break;
        case "mobility":
            skillId = MobilityAccomplishmentSkillId;
            break;
        case "mindfulness":
            skillId = MindfulnessAccomplishmentSkillId;
            break;
        default:
            throw new Error(`[activateGoalSkill] Invalid attribute: ${attribute}`);
    }
    let params = {
        userId: user_id,
        skillId: skillId,
    }
    let responseString = await Parse.Cloud.run('useSkill', params).catch((error: any) => {
        sendSlackErrorNotification(`[activateGoalSkill] Full Error Obj`, "useSkill", `${error}`);
        console.error(`[activateGoalSkill] Full Error Obj: `, error);
        throw error;
    });
    // log.debug(`[activateGoalSkill] responseString: `, responseString.toJSON());
    log.debug(`[activateGoalSkill] responseString: `, responseString);
    logGenericEvent(`goal accomplished skill triggered - ${attribute}`);
    return true;
}

/**
 * Loads the skills in use and the skills recently used for a given duel.
 * @param duelId - The ID of the duel.
 * @param duelLedger - Optional. The duel ledger containing information about the duels. If not provided, it will be fetched.
 * @returns A promise that resolves to an object containing the skills in use and the skills recently used.
 */
export async function loadSkillsInUse(duelId: string, duelLedger?: DuelLedger[]): Promise<{ skillsInUse: SkillInUse[], skillsRecentlyUsed: SkillInUse[] }> {
    let skillsInUse = await getSkillsInUse();
    let skillsRecentlyUsed: SkillInUse[] = []
    // Find the skill in skillsInUse with the most recent createdAt date
    if (skillsInUse.length > 0) {
        if (duelLedger === undefined) {
            duelLedger = await fetchDuelsLedger(duelId)
        }
        let lastDuelTickTime = moment().startOf('hour').format()
        // log.debug("Duel Ledger: ",duelLedger)
        if (duelLedger.length > 0) {
            lastDuelTickTime = duelLedger[0].createdAt
        }
        skillsInUse.forEach((skillInUse: SkillInUse) => {
            if (moment(skillInUse.createdAt).isAfter(lastDuelTickTime)) {
                skillsRecentlyUsed.push(skillInUse)
            }
        });
    }
    return {
        skillsInUse,
        skillsRecentlyUsed,
    }
}