import axios from "axios";
import log from "loglevel";
import Parse from "parse";

import { Product, ProductList, RevenueCatItem, WebCustomerInfo } from "../models/paywallConstants";
import { SUBSCRIPTION_PRODUCTS_TABLE } from "../models/databaseModels";
import { getUser_id } from "./UserService";
import { CustomerInfo, MakePurchaseResult, Purchases } from "@revenuecat/purchases-capacitor";
import { logGenericEvent } from "./AnalyticsService";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";
import { UserObject } from "../models/userModel";

const BASIC_KEY = "sk_rqPauREiCFJIZwkfDHGLFzaYWEaea";
const v1Key = "sk_njFGttpgVlCNvAGyiKIZvsIfzasvY";
const PROJECT_ID = "7e60f06e";

export async function getProductById(product_id: string): Promise<Product | null> {
    const HEADERS = {
        accept: "application/json",
        "content-type": "application/json",
        authorization: `Bearer ${BASIC_KEY}`,
    };
    const response = await axios.get(`https://api.revenuecat.com/v2/projects/${PROJECT_ID}/products/${product_id}`, { headers: HEADERS });
    const data = response.data;
    log.debug("[getProductById]: ", data);
    if (data) {
        return data;
    } else {
        return null;
    }
}

export async function getPriceFromServer(store_identifier: string): Promise<string> {
    let query = new Parse.Query(SUBSCRIPTION_PRODUCTS_TABLE);
    query.equalTo("store_identifier", store_identifier);
    let result = await query.first();
    if (result) {
        let paywallProduct = result.toJSON();
        return paywallProduct.price;
    } else {
        return "";
    }
}

export async function purchaseProduct(store_identifier: string): Promise<RevenueCatItem | null> {
    const userId = await getUser_id();
    const url = `https://api.revenuecat.com/v1/receipts`;
    const payload = {
        "app_user_id": userId,
        "fetch_token": store_identifier,
        "product_id": "com.my.product.iap",
        "price": 1.99,
        "currency": "USD",
        "payment_mode": "string",
        "introductory_price": 0.1,
        "is_restore": false,
        "presented_offering_identifier": "string",
        "attributes": {
            "property1": {
                "value": "string",
                "updated_at_ms": 1709195668093
            },
            "property2": {
                "value": "string",
                "updated_at_ms": 1709195668093
            }
        }
    }
    const HEADERS = {
        accept: "application/json",
        "content-type": "application/json",
        authorization: `Bearer ${BASIC_KEY}`,
    };
    const response = await axios.post(url, payload, { headers: HEADERS });

    const data = response.data;
    log.debug("[purchaseProduct]: ", data);
    if (data) {
        return data;
    } else {
        return null;
    }

}

export async function getCustomerInfoWeb(): Promise<WebCustomerInfo> {
    const HEADERS = {
        accept: "application/json",
        "content-type": "application/json",
        authorization: `Bearer ${v1Key}`,
    };
    let userId = await getUser_id();
    try {
        const response = await axios.get(`https://api.revenuecat.com/v1/subscribers/${userId}`, { headers: HEADERS });
        // const response = await axios.get(`https://api.revenuecat.com/v2/projects/${PROJECT_ID}/customers/${userId}/subscriptions`, { headers: HEADERS });
        const data = response.data;
        log.debug("[getCustomerInfoWeb]: ", data);
        return data;
    } catch (error) {
        log.error("[getCustomerInfoWeb] Error customer info: ", error);
        throw error;
    }
}

export async function getUserSubscriptionObjectWeb(webCustomerInfo: WebCustomerInfo): Promise<any> {
    let subscriptionObject = {
        subscribed: false as boolean,
        expires_date: null as string | null,
        purchase_date: null as string | null,
    }
    // check for active subscriptions
    if (webCustomerInfo.subscriber?.entitlements?.powerUp?.expires_date && webCustomerInfo.subscriber?.entitlements?.powerUp?.expires_date > webCustomerInfo.request_date) {
        // user is subscribed
        log.debug("Expires Date is > request date. User is subscribed");
        subscriptionObject.subscribed = true;
        subscriptionObject.expires_date = webCustomerInfo.subscriber?.entitlements?.powerUp?.expires_date;
        subscriptionObject.purchase_date = webCustomerInfo.subscriber?.entitlements?.powerUp?.purchase_date;
    }
    // check if the non-subscriptions object is not empty
    if (webCustomerInfo.subscriber?.entitlements?.powerUp?.expires_date === null) {
        // user is subscribed
        log.debug("Expires Date is null. User has purchased lifetime");
        subscriptionObject.subscribed = true;
        subscriptionObject.expires_date = null;
        subscriptionObject.purchase_date = webCustomerInfo.subscriber?.entitlements?.powerUp?.purchase_date;
    }
    return subscriptionObject
}

export async function getProductListForPaywall(): Promise<Product[]> {
    const HEADERS = {
        accept: "application/json",
        "content-type": "application/json",
        authorization: `Bearer ${BASIC_KEY}`,
    };
    const response = await axios.get(`https://api.revenuecat.com/v2/projects/${PROJECT_ID}/products`, { headers: HEADERS });
    const data: ProductList = response.data;
    let productArray: Product[] = [];
    if (data.items && data.items.length > 0) {
        for (const item of data.items) {
            // log.debug("item: ", item);
            let price = await getPriceFromServer(item.store_identifier);
            // log.debug("price: ", price);
            const product: Product = {
                identifier: item.store_identifier,
                description: item.display_name,
                title: item.display_name,
                price: parseFloat(price),
                priceString: price,
                currencyCode: "USD",
                introPrice: null,
                discounts: null,
                productCategory: null,
                productType: null,
                subscriptionPeriod: null,
                defaultOption: null,
                subscriptionOptions: null,
                presentedOfferingIdentifier: null,
            };
            productArray.push(product);
        }
        return productArray;
    } else {
        return []
    }
}

export async function purchaseProductIOS(product: any, pricePoint: any, user: UserObject): Promise<MakePurchaseResult | null> {
    try {
        const purchase: MakePurchaseResult = await Purchases.purchaseStoreProduct({ product: product });
        console.log("[purchaseProductIOS] purchase: ", JSON.stringify(purchase));
        logGenericEvent(`successfully purchased: ${pricePoint}`);
        sendSlackNotification(`💰 Purchase Complete on iOS for UserID: ${user?.userID}`, "purchase", true);
        return purchase;
    } catch (error) {
        console.log("[purchaseProductIOS] Error purchasing: ", JSON.stringify(error));
        sendSlackNotification(`❌ Error purchasing on iOS for UserID: ${user?.userID}\n*Error*: ${error}`, "purchase", true);
        logGenericEvent(`Error purchasing: ${error}`);
        return null;
    }
}

export async function purchaseProductAndroid(product: any, pricePoint: any, user: UserObject): Promise<MakePurchaseResult | null> {
    try {
        const purchase: MakePurchaseResult = await Purchases.purchaseStoreProduct({ product: product });
        console.log("[purchaseProductAndroid] purchase: ", JSON.stringify(purchase));
        logGenericEvent(`successfully purchased: ${pricePoint}`);
        sendSlackNotification(`💰 Purchase Complete on Android for UserID: ${user?.userID}`, "purchase", true);
        return purchase;
    } catch (error) {
        console.log("[purchaseProductAndroid] purchase error: ", JSON.stringify(error));
        sendSlackNotification(`❌ Error purchasing on Android for UserID: ${user?.userID}\n*Error*: ${error}`, "purchase", true);
        logGenericEvent(`Error purchasing: ${error}`);
        throw error;
    }
}

export async function checkPromoCode(promoCode: string, entitlementIdentifier: string = "powerUp"): Promise<boolean> {
    let userId = await getUser_id();
    const params = {
        userId: userId,
        promoCode: promoCode,
        entitlementIdentifier: entitlementIdentifier,
    }
    const result = await Parse.Cloud.run('processPromoCode', params).catch((error) => {
        sendSlackErrorNotification(`[checkPromoCode]`, "checkPromoCode", error);
        log.debug(`[checkPromoCode] Error Message: ${error}`);
        log.debug(`[checkPromoCode] Full Error Obj: `, error);
        throw error;
    });
    log.debug("[checkPromoCode] result: ", result);
    return true;
}