import {
	IonAlert,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonIcon,
	IonLabel,
	IonList,
	IonLoading,
	IonModal,
	IonPage,
	IonSegment,
	IonSegmentButton,
	IonToolbar,
	useIonViewWillEnter
} from "@ionic/react";
import { barChart, document, flagOutline, medal, medalOutline, medalSharp, newspaperOutline, personOutline, refreshCircleOutline, settingsOutline, shareSocial, statsChartOutline } from "ionicons/icons";
import log from "loglevel";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Profile.css";
import { SummaryType } from "../../../models/exerciseModels";
import { logGenericEvent } from "../../../services/AnalyticsService";
import ProfileHeader from "../../Profile/components/ProfileHeader";
import { ChallengeHeader } from "../components/ChallengeHeader";
import { ChallengeLeaderboard } from "../components/ChallengeLeaderboard";
import { ChallengeExplanation } from "../components/ChallengeExplanation";
import { ChallengeLedger } from "../components/ChallengeLedger";
import { ChallengeInvite } from "../components/ChallengeInvite";

export const Challenges = () => {
	const history = useHistory();
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { userState, heroObject, refreshHeroData, isMobile, heroLoadingFinished } = globalState;
	const { viewParam } = useParams<{ viewParam: string }>();
	const [view, setView] = useState<string | undefined>(viewParam);
	const [refreshing, setRefreshing] = useState<boolean>(false);
	const [refreshAlert, setRefreshAlert] = useState<boolean>(false);


	function navigate(view: string) {
		history.push(`/challenges/${view}`);
	}

	async function confirmRefresh(confirmed: boolean = false) {
		if (confirmed) {
			setRefreshing(true);
			logGenericEvent("refreshing profile data");
			await refreshHeroData(true);
			setRefreshing(false);
		} else {
			setRefreshAlert(true);
		}
	}
	return (
		<>
			<IonPage>
				<IonHeader>
					<IonToolbar className="background">
						<ChallengeHeader />
						{/* <IonButtons slot="start" className="toolbar-left-buttons">
							<IonButton onClick={() => confirmRefresh(false)}>
								<IonIcon style={{ color: "white" }} icon={refreshCircleOutline} />
							</IonButton>
						</IonButtons>
						<IonButtons slot="end" className="toolbar-right-buttons">
							<IonButton routerLink="/settings">
								<IonIcon style={{ color: "white" }} icon={settingsOutline} />
							</IonButton>
						</IonButtons> */}
					</IonToolbar>
				</IonHeader>
				<IonAlert
					isOpen={!!refreshAlert}
					header="Refresh Data?"
					message="Are you sure you want to refresh your data? This will take a few minutes."
					buttons={[
						{
							text: "Cancel",
							role: "cancel",
							handler: () => {
								setRefreshAlert(false);
							},
						},
						{
							text: "Ok",
							handler: () => {
								setRefreshAlert(false);
								confirmRefresh(true);
							},
						},
					]}
				/>
				<IonLoading isOpen={refreshing} message={"Refreshing data ..."} backdropDismiss />
				<IonLoading isOpen={heroLoadingFinished === false} message={"Loading ..."} backdropDismiss duration={5000} />
				<div style={{ minHeight: "60px", width: "95%", margin: "auto", marginTop: "20px" }}>
					<IonSegment className="duels-segment" mode="ios" color="tertiary" value={view} onIonChange={(e) => setView(e.detail.value)}>
						<IonSegmentButton onClick={() => navigate("leaderboard")} value="leaderboard" layout="icon-top" className="duels-segment-button">
							<div className="challenge-segment-icon-special"><IonIcon icon={medalOutline} /></div>
						</IonSegmentButton>
						<IonSegmentButton onClick={() => navigate("progress")} value="progress" layout="icon-top" className="duels-segment-button">
							<div className="challenge-segment-icon-special"><IonIcon icon={barChart} /></div>
						</IonSegmentButton>
						<IonSegmentButton onClick={() => navigate("share")} value="share" layout="icon-top" className="duels-segment-button">
							<div className="challenge-segment-icon-special"><IonIcon icon={shareSocial} /></div>
						</IonSegmentButton>
						<IonSegmentButton onClick={() => navigate("explanation")} value="explanation" layout="icon-top" className="duels-segment-button">
							<div className="challenge-segment-icon-special"><IonIcon icon={document} /></div>
						</IonSegmentButton>
					</IonSegment>
				</div>
				{(view === "leaderboard") && (
					<ChallengeLeaderboard hero={heroObject!} />
				)}
				{view === "explanation" && (
					<ChallengeExplanation />
				)}
				{view === "share" && (
					<ChallengeInvite />
				)}
				{view === "progress" && (
					<ChallengeLedger user={userState.user!} />
				)}

				{/* <div style={{ minHeight: "10%" }}>
						<IonSegment mode="ios" color="tertiary" value={view} onIonChange={(e) => setView(e.detail.value)} style={{ position: "fixed", zIndex: "20000" }}>
							<IonSegmentButton className="squad-home-segement-button" value="history" layout="icon-top">
								<IonIcon icon={newspaperOutline} />
								<IonLabel className="squad-home-label">History</IonLabel>
							</IonSegmentButton>
							<IonSegmentButton className="squad-home-segement-button" value="stats" layout="icon-top">
								<IonIcon icon={analyticsOutline} />
								<IonLabel className="squad-home-label">Stats</IonLabel>
							</IonSegmentButton>
						</IonSegment>
					</div>
					{view === "history" ? (
						<>
							<ActivityHistory />
						</>
					) : (
						""
					)}
					{view === "stats" ? (
						<>
							<IonContent></IonContent>
						</>
					) : (
						""
					)} */}
			</IonPage>
		</>
	);
}
