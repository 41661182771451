import { IonButton } from '@ionic/react';
import { useState } from 'react';
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "../css/Celebrations.css";

export const ChooseSpecialization = (props: { specializations: string[], dismissFunction: any }) => {
    const [swiperInstance, setSwiperInstance] = useState<SwiperClass>();

    return (
        <>
            <div className="celebration-container">
                <div className="celebration-swiper">
                    <Swiper
                        spaceBetween={50}
                        modules={[Navigation]}
                        slidesPerView={1}
                        navigation
                        pagination={{ type: "bullets" }}
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        autoHeight={true}
                    >
                        {props.specializations.map((specialization, index) => {
                            if (specialization === "strength") {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className="celebration-top-area">
                                            <div className={`image-container`}>
                                                <img src={`assets/images/strength-specialization-selection.jpg`} alt="new strength skill icon" className="skill-icon-celebration" />
                                            </div>
                                        </div>
                                        <div className="celebration-subtitle">You've hit level 20 in Strength!<br />Time to pick a specialization</div>
                                        <div className='celebration-middle-area'>
                                            Specializations give you access to new skills, letting you defeat more challenging villains
                                        </div>
                                        <div className='celebration-bottom-area'>
                                            <IonButton style={{ margin: "20px", "--background": "var(--strength-color)" }} routerLink={`/skills/strength`} onClick={() => props.dismissFunction()}>Pick a Strength Specialization</IonButton>
                                        </div>

                                    </SwiperSlide>
                                )
                            }
                            if (specialization === "cardio") {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className="celebration-top-area">
                                            <div className={`image-container`}>
                                                <img src={`assets/images/cardio-specialization-selection-2.jpg`} alt="new cardio skill icon" className="skill-icon-celebration" />
                                            </div>
                                        </div>
                                        <div className="celebration-subtitle">You've hit level 20 in Cardio!<br />Time to pick a specialization</div>
                                        <div className='celebration-middle-area'>
                                            Specializations give you access to new skills, letting you defeat more challenging villains
                                        </div>
                                        <div className='celebration-bottom-area'>
                                            <IonButton style={{ margin: "20px", "--background": "var(--cardio-color)" }} routerLink={`/skills/cardio`} onClick={() => props.dismissFunction()}>Pick a Cardio Specialization</IonButton>
                                        </div>
                                    </SwiperSlide>
                                )
                            }
                        })}
                    </Swiper>
                </div>
            </div >
        </>
    )
}