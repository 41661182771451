import { IonButton, IonContent, IonFooter, IonPage } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import HeaderLogo from '../../components/HeaderLogo';
import GlobalState from '../../contexts/GlobalState';
import { OnboardingObject } from '../../models/userModel';
import { StorageService } from '../../services/StorageService';
import AthleteCircularComponent from '../AthleteCircularComponent';
import { FamousAthletes } from '../famousAthletes';
import { PayWallClassDojoComponent } from '../../Paywall/components/PayWallClassDojoComponent';

const Upgrade = () => {
    const nextPage = "/onboarding/onboardingComplete";
    const storageService = new StorageService();
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    useEffect(() => {
        updateOnboardingObject();
    }, []);

    async function updateOnboardingObject() {
        let tempOnboardingObject: OnboardingObject = await storageService.getObject("onboarding");
        tempOnboardingObject.url = window.location.pathname;
        tempOnboardingObject.completed = false;
        await storageService.setObject("onboarding", tempOnboardingObject);
    }
    return (
        <IonPage>
            <HeaderLogo checkforUser={false} />
            <PayWallClassDojoComponent modal={false} skipable={false} link={nextPage} />
        </IonPage>
    )
}

export default Upgrade