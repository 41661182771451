import { IonCol, IonRow, IonSpinner } from "@ionic/react";
import log from "loglevel";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import Emoji from "react-emoji-render";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Duels.css";
import { DuelLedger, DuelStatus } from "../../../models/duelsModels";
import { ActivityLedger } from "../../../models/exerciseModels";
import { DuelDisplayMessage } from "../../../models/messageModels";
import { SkillInUse, genericIcon } from "../../../models/skills";
import { convertOddsToText, fetchDuelsLedger } from "../../../services/DuelsServics";
import { getIconFromExerciseActivity } from "../../../services/ExerciseService";
import { formatMomentDate } from "../../../services/HelperService";
import { loadSkillsInUse } from "../../../services/SkillsService";
import DuelsRenderComponentCombined from "./DuelsRenderComponentCombined";
import { TourComponent } from "../../Tour/StepsComponent";
import { FirstHitTour } from "../../Tour/Steps";

const DuelUpdateComponent = (props: { duelID: string; duelStatus: DuelStatus; showTimer?: boolean; duelLedger?: DuelLedger[] }) => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const {
		activityLedger, // we do this instead of passing a prop so it can trigger a useEffect
	} = globalState;
	const [duelLegder, setDuelLedger] = useState<DuelLedger[]>([]);
	const [showDetails, setShowDetails] = useState<number | null>(null);
	const [expanded, setExpanded] = useState<number>(-1);
	const [pendingActivities, setPendingActivities] = useState<ActivityLedger[]>([]);
	const [messages, setMessages] = useState<DuelDisplayMessage[]>([]);
	const [pendingSkills, setPendingSkills] = useState<SkillInUse[]>([]);
	const [skillsInEffect, setSkillsInEffect] = useState<SkillInUse[]>([]);
	const [loadingSkills, setLoadingSkills] = useState<boolean>(false);
	const [loadingLedger, setLoadingLedger] = useState<boolean>(false);



	useEffect(() => {
		updateDuelLedger();
	}, [activityLedger]);

	// async function updateDuelMessages() {
	// 	// log.debug("updateDuelMessages Triggered");
	// 	let duelMessages: DuelMessageTransformedResponse = await fetchDuelMessages(props.duelStatus.statusId);
	// 	// log.debug("Duel Messages: ", duelMessages);
	// 	setMessages(duelMessages.displayMessages);
	// }

	async function getPendingSkills(ledger: DuelLedger[]) {
		setLoadingSkills(true)
		let tempSkillsInEffect: SkillInUse[] = [];
		let tempSkillsPending: SkillInUse[] = [];
		let skillsInUseObject = await loadSkillsInUse(props.duelID, ledger);
		log.debug("Skills In Use Object: ", skillsInUseObject)
		if (skillsInUseObject.skillsRecentlyUsed.length > 0) {
			tempSkillsPending = skillsInUseObject.skillsRecentlyUsed
			setPendingSkills(tempSkillsPending)
		}
		for (const skill of skillsInUseObject.skillsInUse) {
			if (skill.repeat > -1 && tempSkillsPending.filter((pendingSkill) => pendingSkill.skillName === skill.skillName).length === 0) {
				tempSkillsInEffect.push(skill)
			}
		}
		setSkillsInEffect(tempSkillsInEffect)
		setLoadingSkills(false)
	}



	async function updateDuelLedger() {
		log.debug("updateDuelLedger Triggered");
		let tempLedger: DuelLedger[] = [];
		if (props.duelLedger) {
			tempLedger = props.duelLedger;
			setDuelLedger(tempLedger);
			getPendingActivities(tempLedger);
			getPendingSkills(tempLedger)
		}
		setLoadingLedger(true)
		log.debug("Fetching Duel Ledger")
		await fetchDuelsLedger(props.duelID).then((ledger) => {
			tempLedger = ledger;
			setDuelLedger(tempLedger);
			getPendingActivities(tempLedger);
			getPendingSkills(tempLedger)
			setLoadingLedger(false)
			log.debug("Duel Ledger fetched");
		});
	}

	async function getPendingActivities(ledger: DuelLedger[]) {
		// log.debug("getPendingActivities Triggered");
		// sort ledger by date
		const sortedLedger = [...ledger].sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)));
		// get last ledger entry time
		const lastLedger = sortedLedger[sortedLedger.length - 1];
		if (lastLedger) {
			const lastLedgerTime = lastLedger.createdAt;
			// get all activities from activityLedger since lastLedgerTime
			const activities = activityLedger.filter((activity) => moment(activity.activityDate).isAfter(moment(lastLedgerTime)) && activity.deleted !== true);
			setPendingActivities(activities);
		}
	}

	return (
		<>
			{pendingActivities && pendingActivities.length > 0 && props.duelStatus.complete !== true && (
				<>
					<div className="upcoming-activities-header">Upcoming Activities</div>
					{pendingActivities.map((activity, index) => {
						return (
							<div>
								<PendingActivity key={index} {...activity} />
							</div>
						)
					})}
				</>
			)}
			{loadingSkills && (
				<IonSpinner name="crescent" />
			)}
			{pendingSkills && pendingSkills.length > 0 && props.duelStatus.complete !== true && (
				<>
					<div className="upcoming-activities-header">Skills Pending</div>
					{pendingSkills.map((skill, index) => {
						if (skill.skillName) {
							return (
								<div>
									<SkillInUseComponent key={index} {...skill} />
								</div>
							)
						}
					})}
				</>
			)}

			{skillsInEffect && skillsInEffect.length > 0 && props.duelStatus.complete !== true && (
				<>
					<div className="upcoming-activities-header">Skills in Effect</div>
					{skillsInEffect.map((skill, index) => {
						if (skill.skillName) {
							return (
								<div>
									<SkillInUseComponent key={index} {...skill} />
								</div>
							)
						}
					})}
				</>
			)}
			{loadingLedger && (<IonSpinner name="crescent" />)}
			{duelLegder && messages &&
				duelLegder.map((ledger, index, array) => {
					const activityDate = moment(ledger.createdAt).format("YYYY-MM-DD");
					const prevActivityDate = index > 0 ? moment(array[index - 1].createdAt).format("YYYY-MM-DD") : null;
					let ho: string = ""
					// log.debug(`ledger.updateResult: ` + JSON.stringify(ledger.updateResult));
					if (ledger.heroOdds || ledger.heroOdds === 0) {
						ho = convertOddsToText(ledger.heroOdds)
					}
					let commentary = false
					if (ledger.commentaryTitle) {
						commentary = true
					} else {
						commentary = false
					}
					let messagesForDisplay: DuelDisplayMessage[] = messages.filter((message) => (moment(message.timestamp).isSameOrBefore(moment(ledger.createdAt).add(59, 'minutes')) && moment(message.timestamp).isAfter(ledger.createdAt)));
					if (index === array.length - 1) {
						log.debug("first Ledger Entry")
						messagesForDisplay = messages.filter((message) => (moment(message.timestamp).isSameOrBefore(moment(ledger.createdAt))));
					}
					return (
						<div key={index}>
							{(!prevActivityDate || prevActivityDate !== activityDate) && (
								<div className="ledger-day-break" onClick={() => setShowDetails(showDetails === index ? null : index)}>
									{moment(ledger.createdAt).format("dddd MMMM Do, YYYY")}
								</div>
							)}
							<div onClick={() => setExpanded(index === expanded ? -1 : index)} className={`duels-ledger-item ${index === 0 ? "first-ledger-item-tour" : ""} ${index === expanded ? "expanded" : ""} `}>
								<IonRow style={{ display: "block" }}>
									{commentary && (
										<>
											<div className="ledger-commentary-background">
												<div className="ledger-commentary-title">{ledger.commentaryTitle}</div>
												<p style={{ margin: "20px" }}>{ledger.commentaryMessage}</p>
											</div>
										</>
									)}
									<div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
										{ledger.updateString.split("\n").map((line, index) => (
											<div
												style={{ marginLeft: "10px" }}
												key={index}
												dangerouslySetInnerHTML={{ __html: line }}
											/>
										))}
									</div>
								</IonRow>

								{index === expanded && (
									<div className="expanded-section">
										<p>👑 Hero hit chance: {Math.round(ledger.heroHitChance).toString() + "%"}</p>
										<p>⚔ Hero swing: {ledger.heroSwing}</p>
										<p>🩸 Hero damage: {ledger.heroDamageDealt}</p>
										<p>❔ Hero hit villain? {ledger.heroSwing <= ledger.heroHitChance ? ("Yes") : ("No")}</p>
										<p>👿 Villain hit chance: {Math.round(ledger.villainHitChance).toString() + "%"}</p>
										<p>⚔ Villain swing: {ledger.villainSwing}</p>
										<p>🩸 Villain damage: {ledger.villainDamageDealt}</p>
										<p>❓ Villain hit hero? {ledger.villainSwing <= ledger.villainHitChance ? ("Yes") : ("No")}</p>
										<p>🔮 Hero odds? {ho}</p>
										<p>🔮 Current Hero Health: {ledger.currentHeroHealth}</p>
										<p>🔮 Current Villain Health: {ledger.currentVillainHealth}</p>
										<p>📝 Activities detected: {ledger.activitiesRecorded}</p>
									</div>
								)}
							</div>
							<div style={{ width: "85%", margin: "auto" }}>
								<DuelsRenderComponentCombined
									duelStatus={props.duelStatus}
									messages={messagesForDisplay}
								/>
							</div>
							<div className="duels-timestamp">
								<span>
									<>{formatMomentDate(moment(ledger.createdAt))}</>
								</span>
							</div>
						</div>
					);
				})}
			{duelLegder && duelLegder.length > 0 && loadingSkills === false && loadingLedger === false && (
				<TourComponent steps={FirstHitTour} coachMarkName="duelUpdates" modalShowing={false} link={`/dueldetails/${props.duelID}/skills`} />
			)}
			{(!duelLegder || duelLegder.length === 0) && (
				<div style={{ width: "80%", textAlign: "center", margin: "auto" }}>
					<h3>Updates will be shown after the first turn of the duel</h3>
				</div>
			)}
		</>
	);
};

export default DuelUpdateComponent;

const PendingActivity = (activity: ActivityLedger) => {
	return (
		<IonRow className="upcoming-activities">
			<IonCol className="activity-history-icon-col" size="1">
				<div className={`activity-history-icon`}>
					<Emoji text={getIconFromExerciseActivity(activity.activityName)} />
				</div>
			</IonCol>
			<IonCol size="7">

				<div className={`activity-history-activityname ${activity.activityName.length > 14 ? "smaller" : ""}`}>
					{activity.activityName}
				</div>
				<div className="activity-history-duration">{activity.durationMinutes} min</div>

			</IonCol>
			<IonCol size="4" style={{ textAlign: "center" }}>
				{activity.activityName === "Steps" ? (
					<>
						{/* For steps the activity date is the end date because it looks better that way
																	<div className="activity-history-date" style={{ fontSize: "8px" }}>
																		{moment(activity.activityDate).subtract(activity.durationMinutes, "minutes").tz(userTimezone).format("h:mm a")} -{" "}
																		{moment(activity.activityDate).tz(userTimezone).format("h:mm a")}
																	</div> */}
					</>
				) : (
					<div className="activity-history-date">{moment(activity.activityDate).format("h:mm a")}</div>
				)}
			</IonCol>
		</IonRow>
	)
}

const SkillInUseComponent = (skill: SkillInUse) => {
	let currentDate = moment().date();
	log.debug("Current Day: ", currentDate);
	let endDate = moment().add(skill.repeat, "hours").startOf("hour")
	let endDateString = moment(endDate).format("dddd, ha")
	if (currentDate === moment(endDate).date()) {
		endDateString = moment().add(skill.repeat, "hours").startOf("hour").format("ha")
	}
	return (
		<IonRow className="upcoming-activities">
			<IonCol className="activity-history-icon-col" size="2">
				<div className="skill-in-use-icon">
					<img src={skill.skillIcon?.url ?? genericIcon.url} alt={skill.skillName} />
				</div>
			</IonCol>
			<IonCol size="6">
				<div className={`activity-history-activityname ${skill.skillName!.length > 14 ? "smaller" : ""}`}>
					{skill.skillName!}
				</div>
				<div className="activity-history-duration">{skill.effectDescription!}</div>
			</IonCol>
			<IonCol size="4" style={{ textAlign: "center" }}>
				<div className={`activity-history-activityname ${skill.skillName!.length > 14 ? "smaller" : ""}`}>
					Ends
				</div>
				<div className="activity-history-duration">{endDateString}</div>
				{/* <div className="skill-end-time">Ends<br /></div> */}
			</IonCol>
		</IonRow>
	)
}