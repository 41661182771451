import { IonAlert, IonButton, IonCol, IonContent, IonIcon, IonList, IonLoading, IonRow } from "@ionic/react";
import { trash } from "ionicons/icons";
import moment from "moment-timezone";
import Parse from "parse";
import { useContext, useEffect, useState } from "react";
import Emoji from "react-emoji-render";
import GlobalState from "../../contexts/GlobalState";
import "../../css/Profile.css";
import { ACTIVITIES_TABLE } from "../../models/databaseModels";
import { ActivityLedger, ExercisePoints, WorkoutObject, cardioIcon, mindfulnessIcon, mobilityIcon, sourceIcon, strengthIcon } from "../../models/exerciseModels";
import { getIconFromExerciseActivity } from "../../services/ExerciseService";
import { calculatePoints, convertSecondsToSteps } from "../../services/HelperService";
import { StorageService } from "../../services/StorageService";
import Stats from "./Stats";
import log from "loglevel";
import { activityLedgerKey, workoutKey } from "../../models/userModel";
import { TourComponent } from "../Tour/StepsComponent";
import { ActivitiesTour } from "../Tour/Steps";
let storageService = new StorageService();

const ActivityHistory = () => {
	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const {
		activityLedger, // we do this instead of passing a prop so it can trigger a useEffect
		setActivityLedger,
		subtractPointsFromHeroObject,
		getExistingData,
		loadCurrentGoal,

	} = globalState;
	interface ConfirmDeletion {
		show: boolean;
		uuid: string | undefined;
	}
	const [expanded, setExpanded] = useState<number>(-1);
	const [showDetails, setShowDetails] = useState<number | null>(null);
	const dailyPoints = calculatePoints(activityLedger, "daily");
	const [startDate, setStartDate] = useState<string>(moment().startOf("isoWeek").format("YYYY-MM-DD"));
	const [endDate, setEndDate] = useState<string>(moment().endOf("isoWeek").format("YYYY-MM-DD"));
	const [confirmDeletion, setConfirmDeletion] = useState<ConfirmDeletion>({ show: false, uuid: "" });
	const [deleting, setDeleting] = useState<boolean>(false);
	type AggregatedData = {
		steps: number;
		cardioPoints: number;
	};
	const [aggregatedStepsByDate, setAggregatedStepsByDate] = useState<{ [key: string]: ActivityLedger }>({});

	let userTimezone = moment.tz.guess();
	let renderedStepsDates: string[] = [];
	// log.debug(`userTimezone: ${userTimezone}`);
	useEffect(() => {
		async function getActivities() {
			// log.debug("[ActivityHistory] activityLedger: ", activityLedger);
			if (!activityLedger || activityLedger.length === 0) {
				log.debug("[ActivityHistory] activityLedger is undefined");
				// await getExistingData();
			}
		}
		getActivities();
	}, [activityLedger]);

	useEffect(() => {
		const tempAggregatedSteps: { [key: string]: ActivityLedger } = {};
		if (!activityLedger) {
			getExistingData()
		};
		activityLedger.forEach((activity) => {
			if (activity.activityName === "Steps") {
				const activityDateStr = moment(activity.activityDate).format("YYYY-MM-DD");
				if (!tempAggregatedSteps[activityDateStr]) {
					tempAggregatedSteps[activityDateStr] = {
						activityName: "",
						activityDate: "",
						durationMinutes: 0,
						strengthPoints: 0,
						steps: 0,
						cardioPoints: 0,
						mobilityPoints: 0,
						mindfulnessPoints: 0,
						intensityLevel: "",
						intensityMultiple: 0,
						caloriesBurned: 0,
					};
				}
				tempAggregatedSteps[activityDateStr].steps! += convertSecondsToSteps(activity.durationMinutes * 60) || 0;
				tempAggregatedSteps[activityDateStr].cardioPoints += activity.cardioPoints;
				tempAggregatedSteps[activityDateStr].caloriesBurned! += activity.caloriesBurned || 0;
				tempAggregatedSteps[activityDateStr].durationMinutes += activity.durationMinutes || 0;
			}
		});
		log.debug("[ActivityHistory] tempAggregatedSteps: ", tempAggregatedSteps);
		setAggregatedStepsByDate(tempAggregatedSteps);
	}, [activityLedger]);

	// Keeping this function here because it uses GlobalState variables
	async function deleteActivity(UUID: string | undefined) {
		// note we use UUID here because we need to update ledger and workouts
		log.debug("[deleteActivity] Deleting activity with UUID: ", UUID);
		if (!UUID) {
			log.debug("[deleteActivity] UUID is undefined");
			return;
		}
		setDeleting(true);

		// Update the activity in local storage
		let ledger: ActivityLedger[] = await storageService.getObject(activityLedgerKey);
		if (!ledger) {
			// If there are no activities in local storage
			log.debug("[deleteActivity] No activities found in local storage");
			setDeleting(false);
			return;
		}
		const activityIndex = ledger.findIndex((activity: ActivityLedger) => activity.uuid === UUID);
		if (activityIndex === -1) {
			// If the activity isn't found
			log.debug(`[deleteActivity] Activity with UUID ${UUID} not found`);
			setDeleting(false);
			return;
		}
		ledger[activityIndex].deleted = true;
		await storageService.setObject("activityLedger", ledger);
		setActivityLedger(ledger);

		// Update the workout in local storage
		let workouts: WorkoutObject[] = await storageService.getObject(workoutKey);
		if (!workouts) {
			// If there are no workouts in local storage
			log.debug("[deleteActivity] No workouts found in local storage");
		}
		const workoutIndex = workouts.findIndex((workout: WorkoutObject) => workout.UUID === UUID);
		if (workoutIndex === -1) {
			// If the workout isn't found
			log.debug(`[deleteActivity] Workout with uuid ${UUID} not found`);
		} else {
			workouts[workoutIndex].deleted = true;
			await storageService.setObject("workouts", workouts);
		}

		// Update the points
		let subtractedPoints: ExercisePoints = {
			cardioPoints: -ledger[activityIndex].cardioPoints || 0,
			mindfulnessPoints: -ledger[activityIndex].mindfulnessPoints || 0,
			mobilityPoints: -ledger[activityIndex].mobilityPoints || 0,
			strengthPoints: -ledger[activityIndex].strengthPoints || 0,
		};
		log.debug("[deleteActivity] subtractedPoints: ", subtractedPoints);
		await subtractPointsFromHeroObject(subtractedPoints);
		await loadCurrentGoal();

		// Update the activity in the database
		//TODO: Move this to a function
		let query = new Parse.Query(ACTIVITIES_TABLE);
		query.equalTo("UUID", UUID);
		let result = await query.first().catch((error: any) => {
			log.debug("[deleteActivity] Error: " + error.code + " " + error.message);
			throw error;
		});
		if (!result) {
			log.debug(`[deleteActivity] No activity found for UUID ${UUID}`);
			return null;
		}
		// log.debug("[deleteActivity] DB result: ", result.toJSON());
		result.set({ deleted: true });
		result = await result.save().catch((error) => {
			log.debug("[deleteActivity] Error updating activity: ", error);
			throw error;
		});
		if (!result) {
			log.debug(`[deleteActivity] Error updating activity for UUID ${UUID}`);
			return null;
		}
		let resultJSON = result.toJSON();
		log.debug("[deleteActivity] DB result: ", resultJSON);
		setDeleting(false);
	}

	return (
		<>
			{activityLedger ? (
				<>
					<IonLoading isOpen={deleting} backdropDismiss={true} message={"Deleting Activity..."} />
					<IonAlert
						isOpen={confirmDeletion.show}
						onDidDismiss={() => setConfirmDeletion({ show: false, uuid: "" })}
						header={"Delete Activity?"}
						message={"Are you sure you want to delete this activity?"}
						buttons={[
							{ text: "Cancel", role: "cancel" },
							{ text: "Delete", handler: () => deleteActivity(confirmDeletion.uuid) },
						]}
					/>
					<IonContent>
						{!activityLedger || activityLedger.length === 0 ? (
							<>
								<div className="ledger-day-break">No activities found</div>
							</>
						) : (
							<>
								<Stats activityLedger={activityLedger} startDate={startDate} endDate={endDate} setStartDate={setStartDate} setEndDate={setEndDate} />
								<IonList className="list-tour-hint">
									{activityLedger
										.filter((activity) => {
											const activityDate = moment(activity.activityDate);
											const start = moment(startDate);
											const end = moment(endDate).add(1, "day");
											return activityDate.isSameOrAfter(start) && activityDate.isSameOrBefore(end);
										})
										.map((activity, index, array) => {
											const activityDate = moment(activity.activityDate).format("YYYY-MM-DD");
											const prevActivityDate = index > 0 ? moment(array[index - 1].activityDate).format("YYYY-MM-DD") : null;
											// Avoid rendering "Steps" multiple times for the same date
											if (activity.activityName === "Steps" && renderedStepsDates.includes(activityDate)) {
												return null;
											}
											if (activity.activityName === "Steps") {
												renderedStepsDates.push(activityDate);
											}
											if (activity.activityName === undefined) {
												activity.activityName = "Workout";
											}
											let className = "";
											let pointsDisplay = "";
											if (activity.deleted !== true) {
												if (activity.activityName === "Steps") {
													if (aggregatedStepsByDate[activityDate]?.cardioPoints) {
														className += " cardio";
														pointsDisplay += `${cardioIcon} ${aggregatedStepsByDate[activityDate].cardioPoints?.toLocaleString()}`;
													}
												} else {
													if (activity.strengthPoints > 0) {
														className += " strength";
														pointsDisplay += `${strengthIcon} ${activity.strengthPoints}`;
													}
													if (activity.cardioPoints > 0) {
														className += " cardio";
														pointsDisplay += `${cardioIcon} ${activity.cardioPoints}`;
													}
													if (activity.mobilityPoints > 0) {
														className += " mobility";
														pointsDisplay += `${mobilityIcon} ${activity.mobilityPoints}`;
													}
													if (activity.mindfulnessPoints > 0) {
														className += " mindfulness";
														pointsDisplay += `${mindfulnessIcon} ${activity.mindfulnessPoints}`;
													}
													// }
													// if (activity.strengthPoints === 0 && activity.cardioPoints === 0 && activity.mobilityPoints === 0 && activity.mindfulnessPoints === 0) {
													// 	let workouts
													// 	updateWorkoutObjects(workoutArray,)
													// }
												}
											}
											// log.debug(`[ActivityHistory] activity: `, activity);
											let icon = getIconFromExerciseActivity(activity.activityName);
											return (
												<div className={index === 0 ? "first-activity-hint" : ""} key={index}>
													{(!prevActivityDate || prevActivityDate !== activityDate) && (
														<div className="ledger-day-break" onClick={() => setShowDetails(showDetails === index ? null : index)}>
															{moment(activity.activityDate).format("dddd MMMM Do, YYYY")} <span className="caret">{showDetails === index ? "▼" : "▶"}</span>
															{/* Displaying daily points total */}
															{showDetails === index && (
																<>
																	{" "}
																	<IonRow>
																		<IonCol size="3">
																			{strengthIcon} {dailyPoints.strengthPoints[activityDate] || 0}
																		</IonCol>
																		<IonCol size="3">
																			{cardioIcon} {dailyPoints.cardioPoints[activityDate] || 0}
																		</IonCol>
																		<IonCol size="3">
																			{mobilityIcon} {dailyPoints.mobilityPoints[activityDate] || 0}
																		</IonCol>
																		<IonCol size="3">
																			{mindfulnessIcon} {dailyPoints.mindfulnessPoints[activityDate] || 0}
																		</IonCol>
																	</IonRow>
																</>
															)}
														</div>
													)}
													{activity.deleted ? (
														""
													) : (
														<div
															onClick={() => setExpanded(index === expanded ? -1 : index)}
															className={`activity-history-item ${index === expanded ? "expanded" : ""} ${className}`}
														>
															<IonRow>
																<IonCol className="activity-history-icon-col" size="1">
																	<div className={`activity-history-icon ${className}`}>
																		<Emoji text={icon} />
																	</div>
																</IonCol>
																<IonCol size="7">
																	{activity.activityName === "Steps" && aggregatedStepsByDate[activityDate] ? (
																		// {activity.activityName === "Steps" ? (
																		<>
																			<div className={`activity-history-activityname ${activity.activityName.length > 14 ? "smaller" : ""}`}>
																				Daily {activity.activityName}
																			</div>
																			<div className="activity-history-duration">{aggregatedStepsByDate[activityDate].steps?.toLocaleString()} steps</div>
																			{/* <div className={`activity-history-activityname ${activity.activityName.length > 14 ? "smaller" : ""}`}>
																				Daily {activity.activityName}
																			</div>
																			<div className="activity-history-duration">{convertSecondsToSteps(activity.durationMinutes * 60).toLocaleString()} steps</div> */}
																		</>
																	) : (
																		<>
																			<div className={`activity-history-activityname ${activity.activityName.length > 14 ? "smaller" : ""}`}>
																				{activity.activityName}
																			</div>
																			<div className="activity-history-duration">{activity.durationMinutes} min</div>
																		</>
																	)}
																</IonCol>
																<IonCol size="4" style={{ textAlign: "center" }}>
																	{activity.activityName === "Steps" ? (
																		<>
																			{/* For steps the activity date is the end date because it looks better that way
																	<div className="activity-history-date" style={{ fontSize: "8px" }}>
																		{moment(activity.activityDate).subtract(activity.durationMinutes, "minutes").tz(userTimezone).format("h:mm a")} -{" "}
																		{moment(activity.activityDate).tz(userTimezone).format("h:mm a")}
																	</div> */}
																		</>
																	) : (
																		<div className="activity-history-date">{moment(activity.activityDate).tz(userTimezone).format("h:mm a")}</div>
																	)}

																	<div className={`activity-history-points ${pointsDisplay.length > 7 ? "multi-point" : ""}`}>{pointsDisplay}</div>
																</IonCol>
															</IonRow>

															{index === expanded && (
																<div className="expanded-section ">
																	{activity.activityName === "Steps" ? (
																		<>
																			<p>🔥 Calories Burned: {aggregatedStepsByDate[activityDate].caloriesBurned?.toLocaleString()}</p>
																			<p>🧮 Calories/minute: {(aggregatedStepsByDate[activityDate].caloriesBurned! / aggregatedStepsByDate[activityDate].durationMinutes).toFixed(1)}</p>
																			<p>😤 Intensity level: {activity.intensityLevel}</p>
																			<p>📈 Intensity mulitple: {activity.intensityMultiple}</p>
																			{aggregatedStepsByDate[activityDate].strengthPoints > 0 && (
																				<p>{strengthIcon} Strength Points: {aggregatedStepsByDate[activityDate].strengthPoints}</p>
																			)}
																			{aggregatedStepsByDate[activityDate].cardioPoints > 0 && (
																				<p>{cardioIcon} Cardio Points: {aggregatedStepsByDate[activityDate].cardioPoints}</p>
																			)}
																			{aggregatedStepsByDate[activityDate].mobilityPoints > 0 && (
																				<p>{mobilityIcon} Mobility Points: {aggregatedStepsByDate[activityDate].mobilityPoints}</p>
																			)}
																			{aggregatedStepsByDate[activityDate].mindfulnessPoints > 0 && (
																				<p>{mindfulnessIcon} Mindfulness Points: {aggregatedStepsByDate[activityDate].mindfulnessPoints}</p>
																			)}
																			{activity.sourceName && (
																				<p>{sourceIcon} Source of activity: {activity.sourceName}</p>
																			)}
																			{/* {activity.uuid !== undefined && (
																				<div style={{ textAlign: "center", marginTop: "15px" }}>
																					<IonButton size="small" color={"danger"} onClick={() => setConfirmDeletion({ show: true, uuid: activity.uuid })}>
																						Delete Activity
																						<IonIcon icon={trash} />
																					</IonButton>
																				</div>
																			)} */}
																		</>
																	) : (
																		<>
																			<p>🔥 Calories Burned: {activity.caloriesBurned?.toFixed(0)}</p>
																			<p>🧮 Calories/minute: {(activity.caloriesBurned! / activity.durationMinutes).toFixed(1)}</p>
																			<p>😤 Intensity level: {activity.intensityLevel}</p>
																			{/* <p>📈 Intensity mulitple: {activity.intensityMultiple}</p> */}
																			{activity.strengthPoints > 0 && (
																				<p>{strengthIcon} Strength Points: {activity.strengthPoints}</p>
																			)}
																			{activity.cardioPoints > 0 && (
																				<p>{cardioIcon} Cardio Points: {activity.cardioPoints}</p>
																			)}
																			{activity.mobilityPoints > 0 && (
																				<p>{mobilityIcon} Mobility Points: {activity.mobilityPoints}</p>
																			)}
																			{activity.mindfulnessPoints > 0 && (
																				<p>{mindfulnessIcon} Mindfulness Points: {activity.mindfulnessPoints}</p>
																			)}
																			{activity.sourceName && (
																				<p>{sourceIcon} Source of activity: {activity.sourceName}</p>
																			)}
																			{activity.uuid !== undefined && (
																				<div style={{ textAlign: "center", marginTop: "15px" }}>
																					<IonButton size="small" color={"danger"} onClick={() => setConfirmDeletion({ show: true, uuid: activity.uuid })}>
																						Delete Activity
																						<IonIcon icon={trash} />
																					</IonButton>
																				</div>
																			)}
																		</>
																	)}

																</div>
															)}
														</div>
													)}
												</div>
											);
										})}
								</IonList>
							</>
						)}
						<TourComponent modalShowing={false} coachMarkName="profileActivities" steps={ActivitiesTour} action={() => setExpanded(0)} />
					</IonContent>
				</>
			) : (
				""
			)}
		</>
	);
};

export default ActivityHistory;
