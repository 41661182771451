export const LAST_SUGGESTION_COMPLETED_KEY = "lastSuggestionCompleted";

export const cardioActivities = [
    "Do <reps> jumping jacks",
    "Go for a <time> minute brisk walk",
    "March in place for <time> minutes",
    "Run up and down the stairs for <time> minutes",
    "Do <reps> high knees",
    "Perform <reps> burpees",
    "Jump rope for <time> minutes",
    "Do <time> minutes of mountain climbers",
    "Perform <reps> squat jumps",
    "Jog in place for <time> minutes",
    "Dance to your favorite song for <time> minutes",
    "Shadow box for <time> minutes",
    "Do <reps> skaters",
    "Do <reps> reps of arm circles",
    "Perform <time> minutes of chair step-ups",
    "Do <reps> inchworms",
    "Perform <time> minutes of speed skaters",
    "Do <time> minutes of plank jacks",
    "Perform <time> minutes of fast feet",
    "Do <reps> star jumps",
    "Perform <time> minutes of butt kickers",
    "Do <reps> side lunges",
    // "Perform <time> minutes of bear crawls",
    "Do <time> of walking lunges",
    // "Perform <time> minutes of diagonal lunges",
    "Sprint in place for <time> minutes",
    "Perform <time> minutes of high-speed shadow boxing",
    "Do <time> minutes of high knee marching",
    "Perform <time> minutes of dynamic side steps",
    "Do a <time> minute power walk",
    "Perform some chores for <time> minutes",
];

export const strengthActivities = [
    "Do <reps> push-ups",
    "Hold a plank for <time> minutes",
    "Perform <reps> air squats",
    "Do <reps> lunges per leg",
    // "Perform <reps> tricep dips",
    "Do <reps> glute bridges",
    "Hold a side plank for <time> minutes on each side",
    "Perform <reps> bicycle crunches",
    // "Do <reps> leg raises",
    "Perform <reps> inchworms with a push-up",
    "Do <reps> mountain climbers",
    "Hold a plank for <time> minutes",
    "Perform <reps> single-leg deadlifts",
    "Do <reps> Russian twists",
    "Perform a wall sit for <time> minutes",
    "Do <reps> calf raises",
    "Perform <reps> reverse lunges per leg",
    "Do <reps> plank jacks",
    // "Perform <reps> supermans",
    // "Do <time> minutes of bear crawl",
    "Perform <reps> V-ups",
    "Do <time> minutes of high knee marching",
    "Perform <reps> Bulgarian split squats per leg",
    "Do <reps> plank to push-up",
    "Perform <reps> side lunges per leg",
    "Do <reps> pike push-ups",
    "Perform <reps> bird dogs per side",
    "Do <reps> tuck jumps",
    "Perform <reps> broad jumps",
    "Do <time> minutes of shadow boxing"
];

export const mobilityActivities = [
    "Hold a hamstring stretch for <time> minutes on each side",
    "Perform <reps> cat-cow stretches",
    "Do <reps> neck rolls",
    "Hold a quadriceps stretch for <time> minutes on each side",
    "Perform <reps> shoulder rolls forwards and backwards",
    "Do <time> minutes of ankle circles on each side",
    "Perform <reps> wrist stretches",
    "Hold a butterfly stretch for <time> minutes",
    "Do <reps> spinal twists on each side",
    "Hold a pigeon pose for <time> minutes on each side",
    "Perform <reps> knee to chest stretches",
    "Do <time> minutes of side bends on each side",
    "Perform <reps> hip circles in each direction",
    "Hold a calf stretch for <time> minutes on each side",
    "Do <reps> standing forward bends",
    "Hold a lunge with a twist for <time> minutes on each side",
    "Perform <reps> arm overhead stretches with a side bend",
    "Do <time> minutes of wrist flexor and extensor stretches",
    "Hold a seated forward bend for <time> minutes",
    "Perform <reps> cobra stretches",
    "Do <time> minutes of child’s pose",
    "Hold a tricep stretch for <time> minutes on each side",
    // "Perform <reps> prone quadriceps stretches",
    // "Do <time> minutes of supine twist stretches",
    "Hold a hip flexor stretch for <time> minutes on each side",
    // "Perform <reps> glute stretches on each side",
    "Do <time> minutes of toe touches",
    // "Hold a chest opener stretch for <time> minutes",
    "Perform <reps> dynamic leg swings on each side",
    // "Do <time> minutes of scalene stretches"
];

export const mindfulnessExercises = [
    "Close your eyes and spend <time> minutes in silent meditation",
    "Write in a journal for <time> minutes about what you're grateful for",
    "Practice <time> minutes of deep breathing exercises",
    // "Take a <time> minute mindful walk, paying attention to the sensation of walking",
    // "Do <time> minutes of progressive muscle relaxation",
    "Close your eyes and spend <time> minutes doing a body scan meditation to notice each part of your body",
    "Close your eyes and practice loving-kindness meditation for <time> minutes, sending positive thoughts to yourself and others",
    "Engage in <time> minutes of mindful listening to calming music or nature sounds",
    "Close your eyes and spend <time> minutes observing your thoughts without judgment in a mindfulness meditation",
    "Take <time> minutes to do a visualization exercise, imagining a peaceful place and focusing on the details",
    "Write in a journal for <time> minutes about a challenge you overcame recently",
    "Spend <time> minutes journaling about your dreams and aspirations",
    // "Practice <time> minutes of yoga with a focus on mindfulness of movement",
    "Engage in <time> minutes of art therapy, drawing or painting your emotions",
    "Spend <time> minutes in nature, observing the environment with all your senses",
    "Write in a journal for <time> minutes reflecting some great that happened today",
    // "Close your eyes and engage in <time> minutes of guided imagery meditation",
    "Write in a journal for <time> minutes listing the things that made you smile today",
    "Write in a journal for <time> minutes about how you've helped someone recently and how it made you feel"
];

