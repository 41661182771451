import { IonContent, IonGrid, IonSpinner } from '@ionic/react';
import log from 'loglevel';
import { useContext, useEffect, useState } from 'react';
import GlobalState from '../../../contexts/GlobalState';
import "../../../css/Duels.css";
import { DuelStatus, duelStatusKey } from '../../../models/duelsModels';
import { HeroObject } from '../../../models/userModel';
import { getAllDuelsForOtherUser } from '../../../services/DuelsServics';
import { getUserByHeroID } from '../../../services/UserService';
import DuelRecord from './DuelRecord';
import DuelStatusTable from './DuelStatusTable';
import { StorageService } from "../../../services/StorageService";
let storageService = new StorageService();

const DuelHistory = (props: { duelStatus?: DuelStatus[] | undefined, hero?: HeroObject }) => {
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const {
        heroObject
    } = globalState;
    const [hero, setHero] = useState<HeroObject>();
    const [duelStatus, setDuelStatus] = useState<DuelStatus[]>([])
    const [loading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setupVariables()
    }, [])

    async function setupVariables() {
        setLoading(true)
        if (!props.duelStatus || props.duelStatus.length === 0) {
            setDuelStatus(await storageService.getObject(duelStatusKey));
        } else {
            setDuelStatus(props.duelStatus!)
        }
        if (props.hero) {
            setHero(props.hero)
            let otherUser = await getUserByHeroID(props.hero.heroID!)
            log.debug(`otherUser: ${JSON.stringify(otherUser)}`)
            let otherDuelStatus = await getAllDuelsForOtherUser(otherUser.userID)
            log.debug(`otherDuelStatus: ${JSON.stringify(otherDuelStatus)}`)
            setDuelStatus(otherDuelStatus)
        } else {
            setHero(heroObject)
        }
        setLoading(false)
    }
    return (
        <>

            {loading && (<IonSpinner name="crescent" />)}
            {hero && duelStatus && (
                <>
                    <DuelRecord duelStatus={duelStatus} />
                    <IonContent fullscreen>
                        <IonGrid>
                            {duelStatus &&
                                duelStatus
                                    .filter(duel => duel.complete)
                                    .map((duel, index) => (
                                        <DuelStatusTable key={index} duel={duel} index={index} completed={true} hero={hero} />
                                    ))
                            }
                        </IonGrid>
                    </IonContent>
                </>
            )}
            {(!duelStatus || duelStatus.length === 0) && !loading && (
                <div style={{ width: "80%", textAlign: "center", margin: "auto", color: "white" }}>
                    <h3>Your duel history will be available after {heroObject?.heroName ?? 'your hero'}'s first duel</h3>
                </div>
            )}

        </>
    )
}

export default DuelHistory