import { IonContent, IonPage, IonText } from '@ionic/react'
import { useState } from 'react'
import "../../../css/Feed.css"
import { FeedItem, sampleFeedItems } from '../../../models/feedModels'
import FeedItemCard from '../components/FeedItemCard'
import moment from 'moment'

const Feed = () => {
    const [feedItems, setFeedItems] = useState<FeedItem[]>(sampleFeedItems);

    const groupFeedItemsByDay = (items: FeedItem[]) => {
        return items.reduce((groupedItems, item) => {
            const date = moment(item.timestamp).format('YYYY-MM-DD');
            if (!groupedItems[date]) {
                groupedItems[date] = [];
            }
            groupedItems[date].push(item);
            return groupedItems;
        }, {} as { [key: string]: FeedItem[] });
    };

    const groupedFeedItems = groupFeedItemsByDay(feedItems);

    return (
        <IonPage>
            <IonContent>
                {Object.keys(groupedFeedItems).map(date => (
                    <div key={date}>
                        <IonText className="date-separator">
                            {moment(date).format('MMMM Do, YYYY')}
                        </IonText>
                        {groupedFeedItems[date].map((item, index) => (
                            <div key={index} className="feed-item-wrapper">
                                <IonText className="time-separator">
                                    {moment(item.timestamp).format('hh:mm A')}
                                </IonText>
                                <FeedItemCard feedItem={item} />
                            </div>
                        ))}
                    </div>
                ))}
            </IonContent>
        </IonPage>
    );
}

export default Feed;