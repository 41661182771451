import {
	IonBackButton,
	IonButtons,
	IonCol,
	IonContent,
	IonGrid,
	IonHeader,
	IonLabel,
	IonLoading,
	IonPage,
	IonRow,
	IonToolbar
} from "@ionic/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Duels.css";
import { DuelLedger, DuelStatus, duelStatusKey } from "../../../models/duelsModels";
import { HeroObject } from "../../../models/userModel";
import { Villain } from "../../../models/villainModels";
import { fetchDuelsLedger, getAllDuels } from "../../../services/DuelsServics";
import { StorageService } from "../../../services/StorageService";
import { getHeroFromDuelStatus } from "../../../services/UserService";
import { getVillainFromDuelStatus } from "../../../services/VillainServices";
import HeroOddsChart from "./OddsChart";
import log from "loglevel";
const storageService = new StorageService();

const DuelReportComponent = (props: { duelStatus: DuelStatus, duelLedger: DuelLedger[] }) => {
	interface ReportStats {
		finalHealth: number;
		finalHealthPercent: string; // use string here just in case we need to display "N/A" or something
		totalDamageReceived: number | string;
		expectedHitRate: string;
		actualHitRate: string;
		expectedAvgDamageDealt: number | string;
		actualAvgDamageDealt: number | string;
		biggestDamageDealt: number | string;
		luckFactor: string;
		luckFactorStyle?: string;
	}

	const [loading, setLoading] = useState(false);
	const [hero, setHero] = useState<HeroObject>();
	const [villain, setVillain] = useState<Villain>();
	const [heroReportStats, setHeroReportStats] = useState<ReportStats>();
	const [villainReportStats, setVillainReportStats] = useState<ReportStats>();

	useEffect(() => {
		async function loadDuelStatus() {
			setLoading(true);
			setHero(await getHeroFromDuelStatus(props.duelStatus));
			setVillain(await getVillainFromDuelStatus(props.duelStatus));
			await createHeroReportStats(props.duelLedger, props.duelStatus);
			await createVillainReportStats(props.duelLedger, props.duelStatus);
			setLoading(false);
		}
		loadDuelStatus();
	}, [props.duelStatus, props.duelLedger]);

	const createHeroReportStats = async (duelLedger: DuelLedger[], duelStatus: DuelStatus) => {
		let expectedHitRate = calcExpectedHitRate(duelStatus, duelLedger, "hero");
		let actualHitRate = calcActualHitRate(duelLedger, "hero");
		let expectedAvgDamageDealt = calcExpectedAvgDamageDealt(duelStatus, "hero");
		let actualAvgDamageDealt = calcActualAvgDamageDealt(duelLedger, "hero");

		let tempReportStats: ReportStats = {
			finalHealth: duelStatus.heroHealth,
			finalHealthPercent: duelStatus.heroMaxHealth ? Math.round((duelStatus.heroHealth / duelStatus.heroMaxHealth) * 100).toFixed(0) + "%" : "n/a",
			totalDamageReceived: calcTotalDamageReceived(duelLedger, "hero") || "-",
			expectedHitRate: calcExpectedHitRate(duelStatus, duelLedger, "hero") || "-",
			actualHitRate: calcActualHitRate(duelLedger, "hero"),
			expectedAvgDamageDealt: calcExpectedAvgDamageDealt(duelStatus, "hero") || "-",
			actualAvgDamageDealt: calcActualAvgDamageDealt(duelLedger, "hero") || "-",
			biggestDamageDealt: calcBiggestDamageDealt(duelLedger, "hero") || "-",
			luckFactor: calcLuckFactor(expectedAvgDamageDealt, actualAvgDamageDealt, expectedHitRate, actualHitRate),
		};
		tempReportStats.luckFactorStyle = calcLuckFactorStyle(tempReportStats.luckFactor);
		log.debug("tempReportStats: ", tempReportStats);
		setHeroReportStats(tempReportStats);
	};

	const createVillainReportStats = async (duelLedger: DuelLedger[], duelStatus: DuelStatus) => {
		let expectedHitRate = calcExpectedHitRate(duelStatus, duelLedger, "villain");
		let actualHitRate = calcActualHitRate(duelLedger, "villain");
		let expectedAvgDamageDealt = calcExpectedAvgDamageDealt(duelStatus, "villain");
		let actualAvgDamageDealt = calcActualAvgDamageDealt(duelLedger, "villain");

		let tempReportStats: ReportStats = {
			finalHealth: duelStatus.villainHealth,
			finalHealthPercent: duelStatus.villainMaxHealth ? Math.round((duelStatus.villainHealth / duelStatus.villainMaxHealth) * 100).toFixed(0) + "%" : "n/a",
			totalDamageReceived: calcTotalDamageReceived(duelLedger, "villain") || "-",
			expectedHitRate: calcExpectedHitRate(duelStatus, duelLedger, "villain") || "-",
			actualHitRate: calcActualHitRate(duelLedger, "villain") || "-",
			expectedAvgDamageDealt: calcExpectedAvgDamageDealt(duelStatus, "villain") || "-",
			actualAvgDamageDealt: calcActualAvgDamageDealt(duelLedger, "villain") || "-",
			biggestDamageDealt: calcBiggestDamageDealt(duelLedger, "villain") || "-",
			luckFactor: calcLuckFactor(expectedAvgDamageDealt, actualAvgDamageDealt, expectedHitRate, actualHitRate),
		};
		tempReportStats.luckFactorStyle = calcLuckFactorStyle(tempReportStats.luckFactor);
		setVillainReportStats(tempReportStats);
	};

	const calcLuckFactorStyle = (luckFactor: string) => {
		if (luckFactor === "Extremely Unlucky") {
			return "darkred";
		} else if (luckFactor === "Very Unlucky") {
			return "red";
		} else if (luckFactor === "Unlucky") {
			return "pink";
		} else if (luckFactor === "As Expected") {
			return "";
		} else if (luckFactor === "Lucky") {
			return "lightgreen";
		} else if (luckFactor === "Very Lucky") {
			return "green";
		} else if (luckFactor === "Extremely Lucky") {
			return "darkgreen";
		}
	};

	const calcLuckFactor = (expectedAvgDamage: number, actualAvgDamage: number, expectedHitRate: string, actualHitRate: string): string => {
		const expectedHitRateNumber = parseFloat(expectedHitRate.slice(0, -1)) / 100;
		const actualHitRateNumber = parseFloat(actualHitRate.slice(0, -1)) / 100;

		const damageLuckRatio = actualAvgDamage / expectedAvgDamage;
		const hitRateLuckRatio = actualHitRateNumber / expectedHitRateNumber;

		// Average luck ratio across damage and hit rate
		const luckRatio = (damageLuckRatio + hitRateLuckRatio) / 2;

		if (luckRatio < 0.7) return "Extremely Unlucky";
		else if (luckRatio < 0.8) return "Very Unlucky";
		else if (luckRatio < 0.9) return "Unlucky";
		else if (luckRatio <= 1.1) return "As Expected";
		else if (luckRatio <= 1.2) return "Lucky";
		else if (luckRatio <= 1.3) return "Very Lucky";
		else return "Extremely Lucky";
	};

	const calcBiggestDamageDealt = (duelLedger: DuelLedger[], character: "hero" | "villain"): number => {
		let biggestDamageDealt = 0;
		duelLedger.forEach((ledgerEntry) => {
			character === "hero"
				? (biggestDamageDealt = ledgerEntry.heroDamageDealt > biggestDamageDealt ? ledgerEntry.heroDamageDealt : biggestDamageDealt)
				: (biggestDamageDealt =
					ledgerEntry.villainDamageDealt > biggestDamageDealt ? ledgerEntry.villainDamageDealt : biggestDamageDealt);
		});
		return biggestDamageDealt;
	};

	const calcActualAvgDamageDealt = (duelLedger: DuelLedger[], character: "hero" | "villain"): number => {
		let totalDamageDealt = 0;
		let ledgerLength = 0
		duelLedger.forEach((ledgerEntry) => {
			if (character === "hero" && ledgerEntry.heroDamageDealt > 0) {
				totalDamageDealt += ledgerEntry.heroDamageDealt
				ledgerLength++
			}
			if (character === "villain" && ledgerEntry.villainDamageDealt > 0) {
				totalDamageDealt += ledgerEntry.villainDamageDealt
				ledgerLength++
			}
			// character === "hero" ? (totalDamageDealt += ledgerEntry.heroDamageDealt) : (totalDamageDealt += ledgerEntry.villainDamageDealt);
		});
		let avgDamageDealt = Math.round((totalDamageDealt / ledgerLength) * 10) / 10
		if (isNaN(avgDamageDealt)) {
			avgDamageDealt = 0
		}
		return avgDamageDealt;
	};

	const calcExpectedAvgDamageDealt = (duelStatus: DuelStatus, character: "hero" | "villain"): number => {
		let expectedAvgDamageDealt = 0;
		character === "hero"
			// ? (expectedAvgDamageDealt = ((duelStatus.heroToHit - duelStatus.villainDodge) / 100) * (duelStatus.heroDamage / 2))
			// : (expectedAvgDamageDealt = ((duelStatus.villainToHit - duelStatus.heroDodge) / 100) * (duelStatus.villainDamage / 2));
			? (expectedAvgDamageDealt = (duelStatus.heroDamage / 2))
			: (expectedAvgDamageDealt = (duelStatus.villainDamage / 2));
		return Math.round(expectedAvgDamageDealt * 10) / 10;
	};

	const calcTotalDamageReceived = (duelLedger: DuelLedger[], character: "hero" | "villain") => {
		let totalDamageReceived = 0;
		duelLedger.forEach((ledgerEntry) => {
			totalDamageReceived += character === "hero" ? ledgerEntry.villainDamageDealt : ledgerEntry.heroDamageDealt;
		});
		return totalDamageReceived;
	};

	const calcExpectedHitRate = (duelStatus: DuelStatus, duelLedger: DuelLedger[], character: "hero" | "villain"): string => {
		let expectedHitRate: any = "";
		character === "hero"
			? (expectedHitRate = duelStatus.heroToHit - duelStatus.villainDodge)
			: (expectedHitRate = duelStatus.villainToHit - duelStatus.heroDodge);

		if (character === "hero") {
			duelLedger.forEach((ledgerEntry) => {
				if (ledgerEntry.heroHitChance === 100) {
					expectedHitRate++;
				}
			});
		}
		if (expectedHitRate > 99) {
			expectedHitRate = 99;
		}
		if (expectedHitRate < 1) {
			expectedHitRate = 1;
		}
		expectedHitRate = expectedHitRate.toFixed(0) + "%";
		return expectedHitRate as string;
	};

	const calcActualHitRate = (duelLedger: DuelLedger[], character: "hero" | "villain"): string => {
		let actualHitRate: any = 0;
		let totalHits = 0;
		duelLedger.forEach((ledgerEntry) => {
			if (character === "hero") {
				if (ledgerEntry.heroSwing <= ledgerEntry.heroHitChance) {
					totalHits++;
				}
			} else {
				if (ledgerEntry.villainSwing <= ledgerEntry.villainHitChance) {
					totalHits++;
				}
			}
		});
		actualHitRate = ((totalHits / duelLedger.length) * 100).toFixed(0) + "%";
		if (actualHitRate === "NaN%") {
			actualHitRate = "-";
		}
		return actualHitRate as string;
	};

	const outsideColumnWidth = "4";
	const innerColumnWidth = "4";

	return (
		<>
			<HeroOddsChart duelLedger={props.duelLedger} />
			<IonGrid>
				<IonRow>
					<IonCol size="5">
						<IonLabel className="report-level">Level {hero?.overallLevel}</IonLabel>
					</IonCol>
					<IonCol size="2">
						<IonLabel></IonLabel>
					</IonCol>
					<IonCol size="5">
						<IonLabel className="report-level">Level {villain?.level}</IonLabel>
					</IonCol>
				</IonRow>
			</IonGrid>
			<IonGrid className="duel-report-grid">
				<IonRow className="duel-report-odd-row">
					<IonCol size={outsideColumnWidth}>
						<IonLabel>
							{heroReportStats?.finalHealth} ({heroReportStats?.finalHealthPercent})
						</IonLabel>
					</IonCol>
					<IonCol className="duel-report-inner-column" size={innerColumnWidth}>
						<IonLabel>❤️ Health ❤️</IonLabel>
					</IonCol>
					<IonCol size={outsideColumnWidth}>
						<IonLabel>
							{villainReportStats?.finalHealth} ({villainReportStats?.finalHealthPercent})
						</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow className="duel-report-even-row">
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{heroReportStats?.totalDamageReceived}</IonLabel>
					</IonCol>
					<IonCol className="duel-report-inner-column" size={innerColumnWidth}>
						<IonLabel>Total <br />💥 Damage 💥<br /> Received</IonLabel>
					</IonCol>
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{villainReportStats?.totalDamageReceived}</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow className="duel-report-odd-row">
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{heroReportStats?.expectedHitRate}</IonLabel>
					</IonCol>
					<IonCol className="duel-report-inner-column" size={innerColumnWidth}>
						<IonLabel>Expected <br />🎯 Hit 🎯<br />Rate</IonLabel>
					</IonCol>
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{villainReportStats?.expectedHitRate}</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow className="duel-report-even-row">
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{heroReportStats?.actualHitRate}</IonLabel>
					</IonCol>
					<IonCol className="duel-report-inner-column" size={innerColumnWidth}>
						<IonLabel>Actual <br />🎯 Hit 🎯<br />Rate</IonLabel>
					</IonCol>
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{villainReportStats?.actualHitRate}</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow className="duel-report-odd-row">
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{heroReportStats?.expectedAvgDamageDealt}</IonLabel>
					</IonCol>
					<IonCol className="duel-report-inner-column" size={innerColumnWidth}>
						<IonLabel>Expected Avg <br />💥 Damage 💥 Dealt</IonLabel>
					</IonCol>
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{villainReportStats?.expectedAvgDamageDealt}</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow className="duel-report-even-row">
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{heroReportStats?.actualAvgDamageDealt}</IonLabel>
					</IonCol>
					<IonCol className="duel-report-inner-column" size={innerColumnWidth}>
						<IonLabel>Actual Avg <br />💥 Damage 💥 Dealt</IonLabel>
					</IonCol>
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{villainReportStats?.actualAvgDamageDealt}</IonLabel>
					</IonCol>
				</IonRow>
				<IonRow className="duel-report-odd-row">
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{heroReportStats?.biggestDamageDealt}</IonLabel>
					</IonCol>
					<IonCol className="duel-report-inner-column" size={innerColumnWidth}>
						<IonLabel>Biggest <br />💥 Damage 💥<br /> Dealt</IonLabel>
					</IonCol>
					<IonCol size={outsideColumnWidth}>
						<IonLabel>{villainReportStats?.biggestDamageDealt}</IonLabel>
					</IonCol>
				</IonRow>
				{/* <IonRow className="duel-report-even-row">
					<IonCol size={outsideColumnWidth}>
						<IonLabel className="duel-report-luckFactor" style={{ background: `${heroReportStats?.luckFactorStyle}` }}>
							{heroReportStats?.luckFactor}
						</IonLabel>
					</IonCol>
					<IonCol className="duel-report-inner-column" size={innerColumnWidth}>
						<IonLabel>🍀 Luck 🍀<br />Factor</IonLabel>
					</IonCol>
					<IonCol size={outsideColumnWidth}>
						<IonLabel className="duel-report-luckFactor" style={{ background: `${villainReportStats?.luckFactorStyle}` }}>
							{villainReportStats?.luckFactor}
						</IonLabel>
					</IonCol>
				</IonRow> */}
			</IonGrid>
		</>
	);
};

export default DuelReportComponent;
