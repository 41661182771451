import { IonButton, IonContent, IonModal, IonSpinner } from '@ionic/react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import GlobalState from '../../../contexts/GlobalState';
import "../../../css/Duels.css";
import { DuelStatus, VillainGroupInstance, VillainGroups } from '../../../models/duelsModels';
import { getAllVillainGroupInstances, getCurrentDuel } from '../../../services/DuelsServics';
import { getVillainGroupByGroupId } from '../../../services/VillainServices';
import DuelStatusCard from './DuelStatusCard';
import GroupProfile from './GroupProfile';

const CurrentDuel = (props: { duelStatus?: DuelStatus[] | undefined, setView?: any }) => {
    const history = useHistory();
    const firstVillainGroupId = 1
    const globalState = useContext(GlobalState);
    if (!globalState) throw new Error("Error: GlobalState is undefined");
    const {
        heroObject
    } = globalState;
    const [currentDuel, setCurrentDuel] = useState<DuelStatus | undefined>(undefined);
    const [currentVillainGroups, setCurrentVillainGroups] = useState<VillainGroupInstance[] | undefined>(undefined);
    const [firstVillainGroup, setFirstVillainGroup] = useState<VillainGroups | undefined>(undefined);
    const [showUnlockModal, setShowUnlockModal] = useState({ show: false, villainGroup: {} as VillainGroups, unlockable: false, reason: "" });
    const [loadingDuelInfo, setLoadingDuelInfo] = useState(false);

    useEffect(() => {
        async function pullDuelInfo() {
            setLoadingDuelInfo(true);
            let currentDuel = await getCurrentDuel();
            setCurrentDuel(currentDuel);
            if (currentDuel === undefined) {
                const villainGroupInstances: VillainGroupInstance[] = await getAllVillainGroupInstances();
                if (villainGroupInstances.length === 0) {
                    // has NEVER been in a group before
                    let firstVillainGroup = await getVillainGroupByGroupId(firstVillainGroupId);
                    setFirstVillainGroup(firstVillainGroup);
                } else {
                    let currentVillainGroups: VillainGroupInstance[] | undefined = villainGroupInstances.filter(villainGroupInstance => villainGroupInstance.completed === false);
                    // remove duplicates from the array
                    let seen: string[] = [];
                    currentVillainGroups = currentVillainGroups.filter((villainGroupInstance) => {
                        if (seen.includes(villainGroupInstance.villainGroupId)) {
                            return false;
                        } else {
                            seen.push(villainGroupInstance.villainGroupId);
                            return true;
                        }
                    });
                    if (currentVillainGroups) {
                        setCurrentVillainGroups(currentVillainGroups);
                    }
                }
            }
            setLoadingDuelInfo(false);
        }
        pullDuelInfo();
    }, [props.duelStatus])

    function handleNodeClick(villainGroupId: string) {
        history.push(`/duels/duelprogression/${villainGroupId}`);
    }

    function unlockGroup(villainGroupId: string) {
        setShowUnlockModal({ show: true, villainGroup: firstVillainGroup!, unlockable: true, reason: "" });
    }

    function navToProgression() {
        if (props.setView) {
            props.setView("progression");
        } else {
            history.push(`/duels/progression`);
        }
    }

    return (
        <>
            <IonModal isOpen={showUnlockModal.show} className='my-custom-class' onWillDismiss={() => {
                setShowUnlockModal({ show: false, villainGroup: {} as VillainGroups, unlockable: false, reason: "" });;
            }}>
                <GroupProfile setShowModal={setShowUnlockModal} villainGroup={showUnlockModal.villainGroup} unlockable={showUnlockModal.unlockable} reason={showUnlockModal.reason} />
            </IonModal>

            <div style={{ margin: "auto", textAlign: "center" }}>
                {firstVillainGroup ? (
                    <>
                        <h2>Start Your First Duel</h2>
                        <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                            <div
                                className={`group-node-current`}
                                onClick={() => unlockGroup(firstVillainGroup.villainGroupId)}
                            >
                                <div className='group-name'>{firstVillainGroup.groupName}</div>
                                <img src={firstVillainGroup.groupImage} className={`villainGroup-image-node`} alt="villain group" />
                                {/* Display level content here */}
                            </div>
                        </div>
                    </>

                ) : (
                    <>
                        <h2>Current Duel</h2>
                        {loadingDuelInfo ? (
                            <>
                                <IonSpinner />
                            </>
                        ) : (
                            <>
                                {currentDuel ? (
                                    <DuelStatusCard key={1} duel={currentDuel} index={1} hero={heroObject} />
                                ) : (
                                    <>
                                        You're not currently in a duel!
                                        <br />
                                        <div style={{ width: "100%", display: "flex", justifyContent: "center", marginTop: "20px" }}>
                                            <IonButton expand='block' color={'primary'} onClick={() => navToProgression()}>Start a new duel</IonButton>
                                        </div>
                                        {/* {currentVillainGroups && (
                                            <>
                                                <h2 style={{ marginTop: "30px" }}>Current Villain Groups</h2>
                                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                                                    {currentVillainGroups.map((currentVillainGroup, index) => {
                                                        return (
                                                            <>
                                                                <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                                                                    <div
                                                                        className={`group-node-current`}
                                                                        onClick={() => handleNodeClick(currentVillainGroup.villainGroupId)}
                                                                    >
                                                                        <div className='group-name'>{currentVillainGroup.groupName}</div>
                                                                        <img src={currentVillainGroup.groupImage} className={`villainGroup-image-node`} alt="villain group" />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )
                                                    })}
                                                </div>
                                            </>
                                        )} */}
                                    </>
                                )}
                            </>
                        )}

                    </>
                )}

            </div>
        </>
    )
}

export default CurrentDuel