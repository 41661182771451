/* eslint-disable no-template-curly-in-string */
import log from "loglevel";
import Parse from 'parse';
import { SIDEKICK_MESSAGES_TABLE, SIDEKICK_TABLE, SIDEKICK_TYPES_TABLE } from "../models/databaseModels";
import { SidekickMessage } from "../models/messageModels";
import { SidekickDBObject, SidekickObject, SidekickThreadObject, SidekickTypeObject, assistantDBObjectKey, assistantDisplayMessagesKey, assistantIntroMessage, sidekickDBObjectKey, sidekickDisplayMessagesKey, sidekickIntroMessage } from "../models/sideKickModels";
import { sendSlackErrorNotification, sendSlackNotification } from "./NotificationService";
import { StorageService } from "./StorageService";
import { getHero, getUser_id } from "./UserService";
const storageService = new StorageService();

export async function disableSidekick(): Promise<void> {
    log.debug(`[disableSidekick] running function`)
    let userId = await getUser_id();
    let query = new Parse.Query(SIDEKICK_TABLE);
    query.equalTo('userId', userId);
    let parseObject = await query.first().catch((error) => {
        sendSlackErrorNotification(`[disableSidekick] query Full Error Obj`, "disableSidekick", `${error}`);
        log.error(`[disableSidekick] Error Message: ${error}`);
        log.error(`[disableSidekick] Full Error Obj: `, error);
    });
    if (!parseObject) {
        log.debug(`[disableSidekick] No sidekick found for userId ${userId}`);
        return
    };
    parseObject.set({ active: false });
    let result = await parseObject.save().catch((error) => {
        sendSlackErrorNotification(`[disableSidekick] save Full Error Obj`, "disableSidekick", `${error}`);
        console.error(`[disableSidekick] Error Message: ${error}`);
        console.error(`[disableSidekick] Full Error Obj: `, error);
        throw error;
    });
    if (!result) {
        log.error(`[disableSidekick] Error deleting sidekick for userId ${userId}`);
        throw new Error(`[disableSidekick] Error deleting sidekick for userId ${userId}`);
    }
    let resultJSON = result.toJSON();
    log.debug("[disableSidekick] DB result: ", resultJSON);
    log.debug(`[disableSidekick] Sidekick disabled`);
}

export async function createSidekickInDB(sidekickObject: SidekickThreadObject): Promise<SidekickDBObject> {
    let userId = await getUser_id();
    let hero = await getHero();
    let sidekickObj = new Parse.Object(SIDEKICK_TABLE);
    // Capital K's used because of the DB
    let updateSidekickObject: SidekickDBObject = {
        userId: userId,
        heroName: hero.heroName,
        sideKickName: sidekickObject.metadata.sideKickName,
        sideKickId: sidekickObject.metadata.sideKickId,
        sideKickThreadId: sidekickObject.id,
        sideKickImage: sidekickObject.metadata.imageUrl!,
        active: true,
        isAssistant: false,
    }
    sidekickObj.set(updateSidekickObject)
    let result = await sidekickObj.save().catch(function (error) {
        log.debug("Error: " + error.code + " " + error.message);
        log.debug("Error: " + error);
        sendSlackErrorNotification(`[storeSidekick] Full Error Obj`, "storeSidekick", `${error}`);
        throw error;
    });
    return result.toJSON() as unknown as SidekickDBObject;
}

export async function intiateSidekick(): Promise<SidekickObject> {
    let userId = await getUser_id();
    let hero = await getHero();
    let introMessage = sidekickIntroMessage.replace("${hero.heroName}", hero.heroName!);
    const params = {
        userId: userId,
        sideKickName: 'Snarf',
        message: introMessage,
        imageUrl: 'https://parsefiles.back4app.com/2AASjEVBFSO8NGxjpsydiaK3rmD49NDQnSKr8Y91/37a94d6b6e738e8e1bd93182d397e327_snarf.jpg',
    }
    log.debug(`[intiateSidekick] running function`)
    let sidekickObject: SidekickObject = await Parse.Cloud.run('initiateSidekick', params).catch((error) => {
        sendSlackErrorNotification(`[intiateSidekick] Full Error Obj`, "intiateSidekick", `${error}`);
        console.error(`[intiateSidekick] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[intiateSidekick] sideKickObject: `, sidekickObject)
    if (sidekickObject) {
        let newSidekickDBObject = await createSidekickInDB(sidekickObject.threadObject).catch((error) => {
            sendSlackErrorNotification(`[intiateSidekick] createSidekickInDB`, "intiateSidekick", `${error}`);
            console.error(`[intiateSidekick] Full Error Obj: `, error);
            throw error;
        });
        sendSlackNotification(`*New Sidekick Created*\nHero: ${hero.heroName}\nSidekick: ${newSidekickDBObject.sideKickName}\nSidekickId: ${newSidekickDBObject.sideKickId}\nSidekickThreadId: ${newSidekickDBObject.sideKickThreadId}`, "sidekickMessages");
        // await updateUser({ sideKickId: sideKickObject.threadObject.metadata.sideKickId, sideKickThreadId: sideKickObject.threadObject.id }).catch((error) => {
        //     sendSlackErrorNotification(`[intiateSidekick] Full Error Obj`, "intiateSidekick", `${error}`);
        //     console.error(`[intiateSidekick] Full Error Obj: `, error);
        //     throw error;
        // });
        await storageService.setObject(sidekickDisplayMessagesKey, [sidekickObject.message]);
        await storageService.setObject(sidekickDBObjectKey, newSidekickDBObject);
        // log.info(`[intiateSidekick] sidekickObject: `, sidekickObject);
    }
    return sidekickObject;
}

export interface SidekickMessagesResponse {
    messages: SidekickMessage[];
    newMessages: boolean;
    numberOfNewMessages: number;
}

export async function getSidekickMessagesFromDB(threadId: string): Promise<SidekickMessagesResponse> {
    let displayMessagesKey = assistantDisplayMessagesKey;
    // Get all sidekick messages
    // log.debug("[getSidekickMessagesFromDB] Fetching sidekick messages for threadId: ", threadId);
    let existingMessages = await storageService.getObject(displayMessagesKey);
    let allMessages: SidekickMessage[] = [];
    let query = new Parse.Query(SIDEKICK_MESSAGES_TABLE);
    query.equalTo("threadId", threadId);
    query.limit(1000);
    query.descending("createdAt");
    let results = await query.find();
    results.forEach((result) => {
        allMessages.push(result.toJSON() as unknown as SidekickMessage);
    });
    // log.debug("[getSidekickMessagesFromDB] Messages: ", allMessages);
    let newMessages = false;
    let numberOfNewMessages = 0;
    if (existingMessages && existingMessages.length < allMessages.length) {
        newMessages = true;
        numberOfNewMessages = allMessages.length - existingMessages.length;
    }

    await storageService.setObject(displayMessagesKey, allMessages.reverse());
    return {
        messages: allMessages,
        newMessages: newMessages,
        numberOfNewMessages: numberOfNewMessages,
    }
}

export async function getSidekickMessages(threadId: string): Promise<SidekickMessage[]> {
    let messages: SidekickMessage[] = await storageService.getObject(sidekickDisplayMessagesKey);
    getSidekickMessagesFromDB(threadId).then((dbMessages: SidekickMessagesResponse) => {
        // if dbMessages is different than messages, update messages
        if (dbMessages.messages.length !== messages.length) {
            return dbMessages.messages;
        }
    });
    if (messages) {
        return messages;
    } else {
        return [] as SidekickMessage[]
    }
}

export async function getSidekick(): Promise<SidekickDBObject | undefined> {
    let sidekickObject = await storageService.getObject(sidekickDBObjectKey);
    if (sidekickObject) {
        return sidekickObject;
    }
    let userId = await getUser_id();
    let query = new Parse.Query(SIDEKICK_TABLE);
    query.equalTo("userId", userId);
    query.equalTo("active", true);
    let result = await query.first();
    if (result) {
        let sidekickObject = result.toJSON() as unknown as SidekickDBObject;
        return sidekickObject;
    } else {
        // sendSlackErrorNotification(`[getSidekick] Full Error Obj`, "getSidekick", `No sidekick found for userId: ${userId}`);
        return undefined;
    }
}

export async function sendSidekickMessage(message: string, userId?: string): Promise<SidekickMessage> {
    if (!userId) {
        userId = await getUser_id();
    }
    // let user = await getUser();
    let hero = await getHero();
    let sidekick: SidekickDBObject | undefined = await getSidekick()
    if (!userId || !sidekick) {
        throw new Error(`[sendSidekickMessage] user is null`);
    }
    const params = {
        userId: userId,
        sideKickId: sidekick.sideKickId,
        threadId: sidekick.sideKickThreadId,
        message: message,
        name: sidekick.sideKickName,
        sideKickImageUrl: sidekick.sideKickImage,
    }
    sendSlackNotification(`*New Sidekick Message*\nHero: ${hero.heroName}\nSource: Hero\nMessage: ${message}`, "sidekickMessages");
    log.debug(`[sendSidekickMessage] running function`)
    let sidekickMessage: SidekickMessage = await Parse.Cloud.run('createSideKickMessage', params).catch((error) => {
        sendSlackErrorNotification(`[sendSidekickMessage] Full Error Obj`, "sendSidekickMessage", `${error}`);
        console.error(`[sendSidekickMessage] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[sendSidekickMessage] sidekickMessage: `, sidekickMessage)
    if (!sidekickMessage) {
        throw new Error(`[sendSidekickMessage] sidekickMessage is null`);
    }
    return sidekickMessage;
}

export async function processSidekickResponse(sidekickMessage: SidekickMessage, userInput: string): Promise<SidekickMessage[]> {
    // Initialize variables
    let existingDisplayMessages: SidekickMessage[] = [];

    existingDisplayMessages = await storageService.getObject(sidekickDisplayMessagesKey);
    log.debug(`[processSidekickResponse] existingDisplayMessages: `, existingDisplayMessages);
    if (existingDisplayMessages === null) existingDisplayMessages = [];

    let userMessage: SidekickMessage = {
        source: "user",
        message: userInput,
    };
    let updatedDisplayResponse: SidekickMessage[] = [];
    updatedDisplayResponse = [...existingDisplayMessages, userMessage];
    updatedDisplayResponse = [...updatedDisplayResponse, sidekickMessage];
    log.debug(`[processSidekickResponse] updatedDisplayResponse: `, updatedDisplayResponse);
    await storageService.setObject(sidekickDisplayMessagesKey, updatedDisplayResponse);
    return updatedDisplayResponse;
}

export async function updateSidekick(assistantId: string, instructions?: string, name?: string, file_ids?: any[], metadata?: {}) {
    let params: any = {};
    params.assistantId = assistantId;
    params.file_ids = ['file-4TTtqw3pUffXHdA8RI9zt4n1'];
    if (instructions) {
        params.instructions = instructions;
    }
    if (name) {
        params.name = name;
    }
    if (file_ids) {

    }
    if (metadata) {
        params.metadata = metadata;
    }
    console.log(`[updateSidekick] updating assistantId: ${assistantId}`);
    let updatedSidekick = await Parse.Cloud.run('updateSideKick', params).catch((error) => {
        sendSlackErrorNotification(`[updateSidekick] Full Error Obj`, "sendSidekickMessage", `${error}`);
        console.error(`[updateSidekick] Full Error Obj: `, error);
        throw error;
    });
    log.debug(`[updateSidekick] sidekick updated`)
    return updatedSidekick;
}

export async function loadSidekicks(): Promise<SidekickTypeObject[]> {
    let query = new Parse.Query(SIDEKICK_TYPES_TABLE);
    let results = await query.find();
    let sidekicks: SidekickTypeObject[] = [];
    results.forEach((result) => {
        sidekicks.push(result.toJSON() as unknown as SidekickTypeObject);
    });
    return sidekicks;
}