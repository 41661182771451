import {
	IonBackButton,
	IonButton,
	IonButtons,
	IonCol,
	IonContent,
	IonHeader,
	IonLabel,
	IonLoading,
	IonModal,
	IonPage,
	IonRow,
	IonSegment,
	IonSegmentButton,
	IonToolbar
} from "@ionic/react";
import { Steps } from "intro.js-react";
import log from "loglevel";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { cardioColor, strengthColor } from "../../constants/theme";
import GlobalState from "../../contexts/GlobalState";
import "../../css/Skills.css";
import { Skill, SkillModalType, Specialization } from "../../models/skills";
import { shouldShowCoachmark, updateCoachMark } from "../../services/HelperService";
import { getSkillsByAttribute, getSpecializations } from "../../services/SkillsService";
import SelectSpecializationModal from "../components/SelectSpecializationModal";
import SkillModal from "../components/SkillModal";
import { TourComponent } from "../../Features/Tour/StepsComponent";
import { SkillsTour } from "../../Features/Tour/Steps";

type SkillsGroupedByLevel = Record<string, Skill[]>; // Define the type outside of the component

const SkillTree = () => {

	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { isMobile, heroObject } = globalState;
	const { attribute } = useParams<{ attribute: string }>();
	const [showLoading, setShowLoading] = useState(false);
	const [skills, setSkills] = useState<Skill[]>([]);
	const [skillsGroupedByLevel, setSkillsGroupedByLevel] = useState<SkillsGroupedByLevel>({}); // Initialize with an empty object
	const [skillLevel, setSkillLevel] = useState<number>();
	const [specializations, setSpecializations] = useState<Specialization[]>([]);
	const [specializationView, setSpecializationView] = useState<string>()
	const [heroSpecialization, setHeroSpecialization] = useState<string>()
	const [backgroundSegment, setBackgroundSegment] = useState<string>()
	const [showSpecializationSelectionModal, setShowSpecializationSelectionModal] = useState<boolean>(false);
	const [skillModal, setSkillModal] = useState<SkillModalType>({ show: false, skill: null })
	const [showCoachMark, setShowCheckMark] = useState<boolean>(false)



	useEffect(() => {
		log.debug("Refreshing Hero")
		if (attribute && heroObject) {
			loadSkills();
			establishSkillLevel();
		}
	}, [attribute, heroObject, showSpecializationSelectionModal]);

	useEffect(() => {
		shouldShowCoachmark("skills").then((result: boolean) => {
			setShowCheckMark(result);
		});
	}, []);

	async function loadSkills() {
		setShowLoading(true);
		let tempSkills = await getSkillsByAttribute(attribute);
		if (tempSkills) {
			setSkills(tempSkills);
			setSkillsGroupedByLevel(groupSkillsByLevel(tempSkills));
		}
		setShowLoading(false);
	}

	async function establishSkillLevel() {
		let specializations = await getSpecializations();
		let spec = specializations.filter(specialization => specialization.tree === attribute)
		setSpecializations(spec)
		switch (attribute) {
			case "strength":
				setSkillLevel(heroObject!.strengthLevel)
				setBackgroundSegment(strengthColor)
				setSpecializationView(heroObject!.strengthTreeSpecialization)
				setHeroSpecialization(heroObject!.strengthTreeSpecialization)
				if (heroObject!.strengthLevel >= 20 && heroObject!.strengthTreeSpecialization === undefined) setShowSpecializationSelectionModal(true)
				break;
			case "cardio":
				setSkillLevel(heroObject!.cardioLevel)
				setBackgroundSegment(cardioColor)
				setSpecializationView(heroObject!.cardioTreeSpecialization)
				setHeroSpecialization(heroObject!.cardioTreeSpecialization)
				if (heroObject!.cardioLevel >= 20 && heroObject!.cardioTreeSpecialization === undefined) setShowSpecializationSelectionModal(true)
				break;
			case "mobility":
				setSkillLevel(heroObject!.mobilityLevel)
				break;
			case "mindfulness":
				setSkillLevel(heroObject!.mindfulnessLevel)
				break;
		}
	}

	const groupSkillsByLevel = (skills: Skill[]): SkillsGroupedByLevel => {
		return skills.reduce((acc: SkillsGroupedByLevel, skill: Skill) => {
			if (skill.level >= 20) {
				const levelKey = skill.level.toString();
				acc[levelKey] = acc[levelKey] || [];
				acc[levelKey].push(skill);
			}
			return acc;
		}, {});
	};

	function selectSpecialization() {
		let requiredLevel = 20
		let specializationAvailable = false
		switch (attribute) {
			case "strength":
				if (heroObject!.strengthLevel >= requiredLevel) {
					specializationAvailable = true
				}
				break;
			case "cardio":
				if (heroObject!.cardioLevel >= requiredLevel) {
					specializationAvailable = true
				}
				break;
			case "mobility":
				if (heroObject!.mobilityLevel >= requiredLevel) {
					specializationAvailable = true
				}
				break;
			case "mindfulness":
				if (heroObject!.mindfulnessLevel >= requiredLevel) {
					specializationAvailable = true
				}
				break;
		}
		if (specializationAvailable && !heroSpecialization) {
			return (
				<div style={{ textAlign: "center" }}>
					<IonButton onClick={() => setShowSpecializationSelectionModal(true)}>
						Select Specialization
					</IonButton>
				</div>
			)
		} else {
			return (
				<div style={{ textAlign: "center" }} className="select-specialization">
					<IonButton onClick={() => setShowSpecializationSelectionModal(true)} disabled>
						Level 20 you can select a specialization
					</IonButton>
				</div>
			)

		}
	}

	async function refreshHero() {
		setShowSpecializationSelectionModal(false);
		await establishSkillLevel();
	}


	return (
		<IonPage style={{ backgroundImage: `url('assets/images/${attribute}-tree-background.jpg')`, backgroundPosition: "top" }}>
			<div className="skill-overlay"></div>

			<IonHeader>
				<IonToolbar style={{ textAlign: "center" }}>
					{isMobile && (
						<IonButtons slot="start">
							<IonBackButton text="" />
						</IonButtons>
					)}
				</IonToolbar>
			</IonHeader>
			<div className="skill-tree-title">
				{attribute.charAt(0).toUpperCase() + attribute.slice(1)} Skills
			</div>
			<IonModal isOpen={showSpecializationSelectionModal} id="select-specialization-modal" onWillDismiss={() => setShowSpecializationSelectionModal(false)}>
				<SelectSpecializationModal attribute={attribute} specializations={specializations} setShowModal={setShowSpecializationSelectionModal} />
			</IonModal>
			<IonModal isOpen={skillModal.show} id="select-specialization-modal" onWillDismiss={() => setSkillModal({ show: false, skill: null })}>
				{skillModal.skill && (
					<SkillModal skill={skillModal.skill} />
				)}
			</IonModal>
			<IonContent>
				<div style={{ position: "relative" }}>
					<IonLoading isOpen={showLoading} onDidDismiss={() => setShowLoading(false)} backdropDismiss />
					{skillLevel && skills && (
						<>
							{skillLevel && skills.map((skill, i) => {
								if (skill.level < 20) {
									return (
										<IonRow
											key={skill.objectId}
											className={`skill-row ${skillLevel >= skill.level ? "skill-owned" : ""} ${i === 0 ? "first-skill" : ""}`}
											onClick={() => setSkillModal({ show: true, skill: skill })}
										>
											<IonCol size="2" className="skill-column-level">
												Level<br />
												{skill.level}
											</IonCol>
											<IonCol size="2" className="skill-column-icon">
												<img src={skill.icon?.url} alt={skill.name} className="skill-icon" />
											</IonCol>
											<IonCol size="8" className="skill-column-name">
												<IonLabel>{skill.name}</IonLabel>
											</IonCol>
										</IonRow>
									)
								}
							})}
							{selectSpecialization()}

							<div style={{ backgroundColor: `${backgroundSegment}` }}>
								<IonSegment mode="ios" color="tertiary" value={specializationView} onIonChange={(e) => setSpecializationView(e.detail.value)}>
									{specializations.map((specialization) => {
										return (
											<IonSegmentButton value={specialization.name} layout="icon-top" className={`${heroSpecialization === specialization.name ? "skills-specialization-segment-button-owned" : "skills-specialization-segment-button"} `}>
												<IonLabel>{specialization.name}</IonLabel>
											</IonSegmentButton>
										)
									})}
								</IonSegment>
							</div>

							{skillLevel && skills.map((skill) => {
								if (skill.level >= 20 && skill.subTree === specializationView) {
									return (
										<IonRow
											onClick={() => setSkillModal({ show: true, skill: skill })}
											key={skill.objectId}
											className={`skill-row ${skillLevel >= skill.level && heroSpecialization === skill.subTree ? "skill-owned" : ""} ${heroSpecialization !== skill.subTree ? "skill-wrong-specialization" : ""}`}
										>

											<IonCol size="2" className="skill-column-level">
												Level<br />
												{skill.level}
											</IonCol>
											<IonCol size="2" className="skill-column-icon">
												<img src={skill.icon?.url} alt={skill.name} className="skill-icon" />
											</IonCol>
											<IonCol size="8" className="skill-column-name">
												<IonLabel>{skill.name}</IonLabel>
											</IonCol>
										</IonRow>
									)
								}
							})}
						</>
					)}

				</div>
				<TourComponent coachMarkName='skillExplanation' steps={SkillsTour} modalShowing={false} />

			</IonContent>
		</IonPage>
	);
};

export default SkillTree;
