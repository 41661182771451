import {
	IonAlert,
	IonBackButton,
	IonButton,
	IonButtons,
	IonCard,
	IonCardContent,
	IonCardHeader,
	IonCardTitle,
	IonContent,
	IonHeader,
	IonIcon,
	IonList,
	IonLoading,
	IonModal,
	IonPage,
	IonToolbar,
	useIonViewWillEnter
} from "@ionic/react";
import { refreshCircleOutline, settingsOutline } from "ionicons/icons";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import ProfileHeader from "../components/ProfileHeader";
import GlobalState from "../../../contexts/GlobalState";
import "../../../css/Profile.css";
import { SummaryType } from "../../../models/exerciseModels";
import { logGenericEvent } from "../../../services/AnalyticsService";
import { StorageService } from "../../../services/StorageService";
import ActivityHistory from "../ActivityHistory";
import HeroStatsCircularComponent from "../HeroStatsCircularComponent";
import ChangeAvatar from "../updateAvatar/ChangeAvatar";
import { HeroObject } from "../../../models/userModel";
import OtherHeroCircularComponent from "./OtherHeroCircularComponent";
import OtherActivityHistory from "./OtherActivityHistory";
import { useParams } from "react-router-dom";
import { getHero } from "../../../services/UserService";
import DuelHistory from "../../Duels/components/DuelHistory";
const storageService = new StorageService();

export default function OtherProfile() {
	const { heroId } = useParams<{ heroId: string }>();

	const globalState = useContext(GlobalState);
	if (!globalState) throw new Error("Error: GlobalState is undefined");
	const { isMobile } = globalState;

	const [errorAlert, setErrorAlert] = useState<any>({
		visible: false,
		message: null,
	});
	const [refreshAlert, setRefreshAlert] = useState<boolean>(false);
	const [refreshing, setRefreshing] = useState<boolean>(false);
	const [view, setView] = useState<string | undefined>("history");
	const [showChangeAvatarModal, setShowChangeAvatarModal] = useState<boolean>(false);
	const [heroObject, setHeroObject] = useState<HeroObject>();

	let heroName = heroObject?.heroName ?? "User Name";

	useIonViewWillEnter(() => {
		showTab();
		// log.debug(`Activity Ledger: ${activityLedger}`);
	});

	useEffect(() => {
		getOtherHero()
	}, [heroId])

	async function getOtherHero() {
		let hero = await getHero(heroId)
		setHeroObject(hero)
	}

	function showTab() {
		const tabBar = document.getElementById("tabBar");
		if (tabBar !== null) {
			tabBar.style.display = "flex";
		}
	}

	return (
		<>
			{heroObject && (
				<IonPage>
					<IonHeader>
						<IonToolbar style={{ textAlign: "center" }}>
							{isMobile && (
								<IonButtons slot="start">
									<IonBackButton text="" />
								</IonButtons>
							)}
							<ProfileHeader heroName={heroObject.heroName!} heroPicture={heroObject?.heroImageURL} heroLevel={heroObject?.overallLevel} />
						</IonToolbar>
					</IonHeader>
					<OtherHeroCircularComponent hero={heroObject} showLoading={false} />
					<DuelHistory hero={heroObject} />
				</IonPage>
			)}
		</>
	);
}

const ActivitySummary = (props: { summary: SummaryType[] }) => {
	return (
		<>
			{props.summary && (
				<IonContent>
					<IonCard>
						<IonCardHeader>
							<IonCardTitle>Summary</IonCardTitle>
						</IonCardHeader>
						<IonCardContent>
							<IonList>
								{props.summary.map((data: SummaryType, index: number) => {
									return (
										<div key={index}>
											{data.updated && <div style={{ fontWeight: "bold" }}>Last updated: {moment(data.updated).format("dddd MMMM Do, YYYY")}</div>}
											{data.attributes.map((text: string, textIndex: number) => (
												<div key={textIndex} style={{ whiteSpace: "unset" }}>
													{text}
												</div>
											))}
										</div>
									);
								})}
							</IonList>
						</IonCardContent>
					</IonCard>
				</IonContent>
			)}
		</>
	);
};
