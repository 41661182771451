import { IonAvatar, IonCol, IonContent, IonGrid, IonRow, IonSpinner } from '@ionic/react'
import React, { useEffect, useState } from 'react'
import { ChallengeLedgerObject, getChallengeLedger, getHero } from '../../../services/UserService';
import { UserObject } from '../../../models/userModel';

export const ChallengeLedger = (props: { user: UserObject }) => {
    const [challengeLedgerEntries, setChallengeLedgerEntries] = useState<ChallengeLedgerObject[]>([])
    const [refreshing, setRefreshing] = useState<boolean>(false);
    const [heroId, setHeroId] = useState<string>("")

    useEffect(() => {
        refreshLedgerData()
    }, [])

    async function refreshLedgerData(refresh: boolean = false) {
        setRefreshing(true)
        let ledger = await getChallengeLedger(props.user.userID!)
        // console.log(heroes)
        setChallengeLedgerEntries(ledger)
        setRefreshing(false)
    }
    return (
        <>
            <IonContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size="2" className='challenge-leaderboard-column-header' >
                            Date
                        </IonCol>
                        <IonCol size="2" className='challenge-leaderboard-column-header' style={{ textAlign: "center" }}>
                            Event
                        </IonCol>
                        <IonCol size="2" className='challenge-leaderboard-column-header' style={{ textAlign: "center" }}>
                            Points
                        </IonCol>
                        <IonCol size="3" className='challenge-leaderboard-column-header' style={{ textAlign: "center" }}>
                            Villain
                        </IonCol>
                        <IonCol size="3" className='challenge-leaderboard-column-header' style={{ textAlign: "center" }}>
                            Friend
                        </IonCol>
                    </IonRow>
                    {/* {challengeLedgerEntries.map((ledger: ChallengeLedgerObject, index) => {
                        let className = "hero-row";
                        if (index % 2 !== 0) {
                            className = "hero-row-alt";
                        }
                        return (
                            // alternate row colors based on index
                            <IonRow className={className}>
                                <IonCol size="1" style={{ paddingRight: "0px" }}>
                                    {index + 1}.
                                </IonCol>
                                <IonCol size="2">
                                    <IonAvatar className="member-image-feed" onClick={() => tapAvatar(hero.heroID!)}>
                                        <img src={hero.heroImageURL} alt='hero image' />
                                    </IonAvatar>
                                </IonCol>
                                <IonCol size="3">
                                    {hero.heroName}
                                </IonCol>
                                <IonCol size="6" style={{ textAlign: "center" }}>
                                    {hero.challengePoints}
                                </IonCol>
                            </IonRow>
                        )
                    })} */}
                </IonGrid>
            </IonContent>
        </>
    )
}
