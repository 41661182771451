import { IonCard, IonCardContent, IonCardHeader, IonCardTitle } from '@ionic/react';
import "../../css/Skills.css";
import { Skill, SkillModalType, genericIcon } from "../../models/skills";

const SkillActivatedModal = (props: { skill: Skill, setShowSkillActivatedModal: React.Dispatch<React.SetStateAction<SkillModalType>> }) => {
    return (
        <>
            {props.skill && (
                <div style={{ margin: "20px" }}>
                    <IonCard>
                        <div className='skill-card-image-div'>
                            {props.skill.icon ? (
                                <img src={props.skill.icon.url} />

                            ) : (
                                <img src={genericIcon.url} />

                            )}
                        </div>
                        <IonCardHeader>
                            <IonCardTitle>
                                Skill activated
                            </IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            You have activated {props.skill.name}! It will be active for {props.skill.repeat === 0 ? "one hour" : props.skill.repeat + " hours"}
                        </IonCardContent>
                    </IonCard>
                </div>
            )}

        </>
    )
}

export default SkillActivatedModal